import { Text, Title, Image, List, ActionIcon, Anchor } from '@mantine/core';
import {
  IconDots,
  IconEye,
  IconTrash,
  IconArrowRight,
  IconEdit,
  IconX,
  IconCheck,
  IconMathFunction,
  IconChartArrows,
  IconWorld,
  IconCompass,
} from '@tabler/icons';

import { BaseHelpSectionProps } from './BaseHelpSection';
import SelectFocalTopicExampleImg from './SelectFocalTopicExample.png';
import {
  IconArrowsDiff,
  IconProgress,
  IconTrendingUp,
} from '@tabler/icons-react';
import { Link } from 'react-router-dom';
// import { IconCheck } from "@tabler/icons-react";

export const ListAnalysesHelpSection: React.FC<BaseHelpSectionProps> = ({
  linkedContext,
}) => {
  return (
    <>
      <Text color="dimmed">See your data in different lights</Text>

      <Title order={4}>Analyses</Title>
      <Text>
        Each item in this list represents an analysis in your project. By
        switching the active analysis, you can modify its definition.{' '}
      </Text>
      <Text>
        The three dots <b>...</b> to the side of the list item allows you to
        edit the name of the analysis or delete it.
      </Text>
      <Text>
        To create a new Analysis, click the <i>New Analysis</i> button under the
        list
      </Text>
      <Title order={5}>Analysis Types</Title>
      <Text>
        There are various different types of analyses. Each type allows you to
        focus on a different aspect of your data.
      </Text>

      <List spacing={'xl'} size="sm">
        <List.Item icon={<IconMathFunction size={18} />}>
          <b>Key Drivers of Outcome in Population</b>: Predict an outcome of
          interest within a population of your choosing.
        </List.Item>

        <List.Item icon={<IconTrendingUp size={18} />}>
          <b>Progress Over Time</b>: See how the culture and employee experience
          has changed over time. Can be across multiple survers or dates within
          surveys.
        </List.Item>

        <List.Item icon={<IconArrowsDiff size={18} />}>
          <b>Compare Two Populations</b>: Compare two specific populations.
          Often used for mergers and acquisitions.
        </List.Item>

        <List.Item icon={<IconChartArrows size={18} />}>
          <b>Internal Benchmark</b>: See how the population compares to
          comparable segments within the organization. I.e. comparing all
          business units to each other.
        </List.Item>

        <List.Item icon={<IconWorld size={18} />}>
          <b>External Benchmark</b>: Compare against other organizations using
          Glassdoor data.
        </List.Item>

        <List.Item icon={<IconCompass size={18} />}>
          <b>Synthesize Everything</b>: The CultureX special. Incorporating
          multiple inputs, learn what to do.
        </List.Item>
      </List>
    </>
  );
};
