import ProjectAPI from '@apis/ProjectAPI';
import {
  Divider,
  Modal,
  Stack,
  Title,
  Text,
  TextInput,
  Group,
  Button,
} from '@mantine/core';
import ProjectModel from '@models/ProjectModel';
import { useCallback, useState } from 'react';
import APIErrorMessage from './APIErrorMessage';
import { Analysis } from '@hooks/useProjectAnalyses';
import { AnalysisType } from '@stores/AnalysisEditionStore';
import { InternalBenchmarkAnalysisModel } from '@models/ProjectAnalysisModel/InternalBenchmarkAnalysisModel';
import { DriversOfOutcomeAnalysisModel } from '@models/ProjectAnalysisModel/DriversOfOutcomeAnalysisModel';
import { useQueryClient } from '@tanstack/react-query';
import { IProjectAnalysisModel } from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';

interface DeleteAnalysisModalProps {
  project: ProjectModel;
  analysis: IProjectAnalysisModel;
  opened: boolean;
  onClose: () => void;
  onAnalysisDeleted: () => void;
}

export const DeleteAnalysisModal = ({
  project,
  opened,
  onClose,
  onAnalysisDeleted,
  analysis,
}: DeleteAnalysisModalProps) => {
  const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
  const [deleteError, setDeleteError] = useState<React.ReactNode | undefined>(
    undefined
  );
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const queryClient = useQueryClient();

  const deleteAnalysis = useCallback(() => {
    setDeleteInProgress(true);

    const updatedProject = {
      ...project,
      updatedAnalyses: [],
      newAnalyses: [],
      deletedAnalyses: [analysis],
    };

    ProjectAPI.updateProject(updatedProject)
      .then((data) => {
        queryClient.setQueryData(['project', data.projectId], data);
        setDeleteError(undefined);
        setDeleteInProgress(false);
        setDeleteConfirmationText('');
        onAnalysisDeleted();
      })
      .catch((error) => {
        console.log(error);
        setDeleteInProgress(false);
        setDeleteError(
          <APIErrorMessage
            response={error}
            dismissable={true}
            simple
            onDismiss={() => setDeleteError(undefined)}
          />
        );
      });
  }, [project, analysis, onAnalysisDeleted, queryClient]);

  return (
    <Modal
      opened={opened}
      onClose={() => {
        setDeleteConfirmationText('');
        onClose();
      }}
      withCloseButton={false}
      size="lg"
    >
      <Stack>
        <Title order={3}>Delete Analysis</Title>
        <Divider />
      </Stack>
      <Stack style={{ marginTop: '10px' }}>
        <Text>
          You are about to delete the analysis <b>{analysis.name}</b>
        </Text>
        <TextInput
          label={
            <Text>
              To confirm the deletion of this analysis, type <i>delete</i> in
              the box below
            </Text>
          }
          description={<Text color="orange">This action cannot be undone</Text>}
          placeholder="delete"
          inputWrapperOrder={['label', 'input', 'description', 'error']}
          onChange={(event) =>
            setDeleteConfirmationText(event.currentTarget.value)
          }
        />
        {deleteError}
        <Group position="right">
          <Button
            variant="subtle"
            color="dark"
            onClick={() => {
              setDeleteConfirmationText('');
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            color="red"
            disabled={deleteConfirmationText !== 'delete'}
            loading={deleteInProgress}
            onClick={deleteAnalysis}
          >
            Delete
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
