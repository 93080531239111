import { Center, Checkbox, Group, Radio, Stack, Text } from '@mantine/core';
import React, { forwardRef } from 'react';

export interface OutcomeSelectItemProps
  extends Omit<React.ComponentPropsWithoutRef<'tr'>, 'value'> {
  label: React.ReactNode;
  outcome: string;
  subTitle: string;
  value: string;
  section: string;
  selected: boolean;
  disabled: boolean;
}

export const DefaultItem = forwardRef<HTMLDivElement, OutcomeSelectItemProps>(
  (
    {
      label,
      value,
      selected,
      disabled,
      outcome,
      subTitle,
      ...others
    }: OutcomeSelectItemProps,
    ref
  ) => {
    // Remove the data-selected attribute from others to avoid a blue selected background
    delete others['data-selected'];
    return (
      <tr key={value} ref={ref} {...others}>
        <td>
          <Center>
            <Radio checked={selected} disabled={disabled} readOnly />
          </Center>
        </td>
        <td>
          <Stack spacing={'10'}>
            <Text>{label}</Text>
            <Text color="dimmed">{subTitle}</Text>
          </Stack>
        </td>
        <td>{outcome}</td>
      </tr>
    );
  }
);

DefaultItem.displayName = '@mantine/core/DefaultItem';
