import React from 'react';

import {
  Text,
  Title,
  Modal,
  Select,
  Chip,
  Textarea,
  Input,
  Checkbox,
  Button,
} from '@mantine/core';

interface QuoteReaderErrorReportModalProps {
  availableTopics?: string[];

  // MARK: - Delegate callback methods...
  onCancel: () => void;
  onSubmit: () => void;
}

interface QuoteReaderErrorReportModalState {
  selectedFeedbackType?: string;
  incorrectlyClassifiedTopics?: string[];

  feedbackMessage?: string;
  email?: string;
  followUp: boolean;

  // MARK: - Error states for the various inputs...
  incorrectlyClassifiedTopicsError?: string;
  feedbackMessageError?: string;
  emailError?: string;
}

class QuoteReaderErrorReportModal extends React.Component<QuoteReaderErrorReportModalProps> {
  constructor(props: QuoteReaderErrorReportModalProps) {
    super(props);
  }

  state: QuoteReaderErrorReportModalState = {
    incorrectlyClassifiedTopics: [],
    selectedFeedbackType: 'classification_report',
    followUp: false,
  };

  handleChipClick = (checked: boolean, topic: string) => {
    if (checked) {
      this.setState({
        incorrectlyClassifiedTopics: [
          ...this.state.incorrectlyClassifiedTopics!,
          topic,
        ],
      });
    } else {
      this.setState({
        incorrectlyClassifiedTopics:
          this.state.incorrectlyClassifiedTopics!.filter((t) => t !== topic),
      });
    }
  };

  formatConditionalInputs = () => {
    // Based on the this.state.selectedFeedbackType, format the conditional inputs.

    if (this.state.selectedFeedbackType === 'classification_report') {
      var chips: JSX.Element[] = [];
      if (this.props.availableTopics !== undefined) {
        this.props.availableTopics.forEach((topic) => {
          chips.push(
            <Chip
              style={{ padding: '5px' }}
              variant="filled"
              color="red"
              onChange={(checked) => this.handleChipClick(checked, topic)}
              key={topic}
            >
              {topic}
            </Chip>
          );
        });
      }
      return (
        <>
          <Text size={14} weight={500}>
            Select incorrectly classified topics{' '}
            <span style={{ color: 'red' }}>*</span>
          </Text>
          <Text size={12} color="#868e96">
            Choose the topics you believe to be mis-classified
          </Text>
          <br />
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>{chips}</div>
          {this.state.incorrectlyClassifiedTopicsError}
        </>
      );
    }
  };

  validateFeedback = (): boolean => {
    var hasError = false;
    if (this.state.selectedFeedbackType === 'classification_report') {
      if (
        this.state.incorrectlyClassifiedTopics === undefined ||
        this.state.incorrectlyClassifiedTopics.length === 0
      ) {
        hasError = true;
        this.setState({
          incorrectlyClassifiedTopicsError: (
            <>
              <br />
              <Text size={12} color="red">
                Please select at least one topic
              </Text>
            </>
          ),
        });
      } else {
        this.setState({
          incorrectlyClassifiedTopicsError: undefined,
        });
      }
    } else {
      this.setState({
        incorrectlyClassifiedTopicsError: undefined,
      });
    }

    if (
      this.state.feedbackMessage === undefined ||
      this.state.feedbackMessage.length === 0
    ) {
      hasError = true;
      this.setState({
        feedbackMessageError: 'Please provided some detailed feedback',
      });
    } else {
      this.setState({
        feedbackMessageError: undefined,
      });
    }

    if (this.state.email === undefined || this.state.email.length === 0) {
      hasError = true;
      this.setState({
        emailError: 'Please enter an email address',
      });
    } else {
      this.setState({
        emailError: undefined,
      });
    }

    return !hasError;
  };

  submitFeedback = () => {
    // Submit the feedback to the server.
    // Make sure that the user filled everything out
    if (this.validateFeedback()) {
      // In the future, send the feedback to the server.
      this.props.onSubmit();
    }
  };

  render = () => {
    return (
      <>
        <Modal
          centered
          opened
          onClose={this.props.onCancel}
          closeOnClickOutside={false}
          closeOnEscape={false}
          title={<Title order={4}>Provide Feedback</Title>}
        >
          <Text size={14} color="dimmed">
            Please provide any feedback you have about our service.
          </Text>
          <br />
          <Select
            required
            width="100%"
            label="Feedback Type"
            placeholder="Select an option"
            data={[
              {
                value: 'classification_report',
                label: 'Report a Mis-Classification',
              },
              { value: 'bug_report', label: 'Report a Bug' },
              { value: 'general_feedback', label: 'General Feedback' },
              { value: 'feature_request', label: 'Feature Request' },
            ]}
            defaultValue={'classification_report'}
            onChange={(value) => this.setState({ selectedFeedbackType: value })}
          />
          <br />
          {this.formatConditionalInputs()}
          <br />
          <Textarea
            error={this.state.feedbackMessageError}
            onChange={(event: any) =>
              this.setState({ feedbackMessage: event.currentTarget.value })
            }
            description="Let us know what's going on in more detail..."
            minRows={6}
            label="Message"
            required
            placeholder="Please provide feedback here"
          />
          <br />
          <Input.Wrapper
            id="input-demo"
            required
            label="Email"
            description="Provide your email in case we need to contact you"
            error={this.state.emailError}
          >
            <Input
              id="input-demo"
              placeholder="Your email"
              onChange={(event: any) =>
                this.setState({ email: event.currentTarget.value })
              }
            />
          </Input.Wrapper>
          <br />
          <Checkbox
            label="I want a follow-up afterwards"
            defaultChecked={false}
            onChange={(event) =>
              this.setState({ followUp: event.currentTarget.checked })
            }
          />
          <br />
          <Button fullWidth onClick={this.submitFeedback}>
            Submit
          </Button>
        </Modal>
      </>
    );
  };
}

export default QuoteReaderErrorReportModal;
