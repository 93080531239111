import React, { useEffect, useMemo, useState } from 'react';
import {
  Modal,
  Text,
  Title,
  TextInput,
  Group,
  Button,
  Space,
  Alert,
  Tooltip,
  Anchor,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { InfoHelperButton } from '@components/InfoHelper/InfoHelperButton';
import QueryBuilder from '@components/QueryBuilder';
import ProjectAPI from '@apis/ProjectAPI';
import { v4 as uuidv4 } from 'uuid';
import { IconAlertCircle } from '@tabler/icons';

interface FocalPopulationViewProps {
  // Callbacks...
  onComplete: (populationName: string, definition: any) => void;
  onClose: () => void;

  // Initial values...
  existingName?: string;
  existingDefinition?: any;

  // Passing context
  existingPopulations: string[];

  datasetMetadata: any; // Should be {[key: string]: any}
  datasets: any[];
  availableFields: object;

  isExistingPopulation?: boolean;
}

export const FocalPopulationView: React.FC<FocalPopulationViewProps> = (
  props
) => {
  const [populationName, setPopulationName] = useState<string | undefined>(
    props.existingName
  );
  const [populationNameError, setPopulationNameError] = useState<
    string | undefined
  >(undefined);

  const [populationDefinition, setPopulationDefinition] = useState<
    any | undefined
  >(props.existingDefinition || undefined);
  const [populationDefinitionError, setPopulationDefinitionError] = useState<
    JSX.Element | undefined
  >(undefined);

  const [populationCount, setPopulationCount] = useState<number | undefined>(
    undefined
  );

  // State for tracking definition # responses...
  const [bouncedNewPopulationDefinition] = useDebouncedValue(
    populationDefinition,
    300
  );
  const [lastRequestId, setLastRequestId] = useState<string | undefined>(
    undefined
  );

  const onDefinitionUpdate = (populationName: string, definition: any) => {
    setPopulationDefinition(definition);
  };

  // MARK: - Effects...
  useEffect(() => {
    // Check to see if the existing populations already have this name...
    if (
      props.existingPopulations.includes(populationName || '') &&
      populationName !== props.existingName
    ) {
      setPopulationNameError('Population name already exists');
    } else if (
      populationName !== undefined &&
      populationName.startsWith('<SEGMENTS::')
    ) {
      setPopulationNameError('Population name cannot start with <SEGMENTS::');
    } else if (populationName !== undefined && populationName.length === 0) {
      setPopulationNameError('Population name cannot be empty');
    } else {
      setPopulationNameError(undefined);
    }
  }, [populationName]);

  const getLastRequestId = async () => {
    return new Promise((resolve) => {
      setLastRequestId((currentRequestId) => {
        resolve(currentRequestId);
        return currentRequestId;
      });
    });
  };

  const getResponseCount = async () => {
    // Format the datasets for use...
    const formattedDatasets = {};
    if (props.datasets && populationDefinition) {
      props.datasets[0].datasetsArray.forEach((dataset: any) => {
        const datasetCRNString = dataset.crn;
        const datasetName = `DS_${dataset.datasetName}`;

        formattedDatasets[datasetName] = {
          DatasetCRN: datasetCRNString,
        };
      });

      // Now let's make our request

      const requestId = uuidv4();
      setLastRequestId(requestId);
      ProjectAPI.calculateSampleSizeOfPopulation(
        formattedDatasets,
        populationDefinition
      ).then((sampleSize: number) => {
        getLastRequestId().then((currentRequestId) => {
          if (requestId === currentRequestId) {
            setPopulationCount(sampleSize);
            setLastRequestId(undefined);
          }
        });
      });
    }
  };

  useEffect(() => {
    if (props.existingDefinition !== populationDefinition) {
      getResponseCount();
    }
  }, [populationDefinition]);

  const validateDefinition = (definition: any | undefined) => {
    if (definition === undefined) {
      setPopulationDefinitionError(
        <Alert icon={<IconAlertCircle size="1rem" />} title="Error" color="red">
          Please provide a definition for your population.
        </Alert>
      );
      return false;
    }
    setPopulationDefinitionError(undefined);
    return true;
  };

  // <Group spacing={"xxs"}>
  //     <Title size="h2">
  //         {props.isExistingPopulation === true ? "Edit" : "New"} Population
  //     </Title>
  //     {/* <InfoHelperButton /> */}
  // </Group>

  const itemsHaveChanged = useMemo(() => {
    if (!props.isExistingPopulation) {
      return true;
    }
    // If the name has changed, then we know that the items have changed...
    if (populationName !== props.existingName) {
      return true;
    }

    // If the definition has changed, then we know that the items have changed...
    if (populationDefinition !== props.existingDefinition) {
      return true;
    }

    return false;
  }, [
    props.existingName,
    props.existingDefinition,
    populationName,
    populationDefinition,
    props.isExistingPopulation,
  ]);

  return (
    <>
      <TextInput
        style={{
          marginTop: '20px',
        }}
        withAsterisk
        onChange={(event) => setPopulationName(event.currentTarget.value)}
        placeholder="Provide a name for your population"
        label="Name"
        defaultValue={props.existingName}
        error={populationNameError}
      />

      <Space h="xl" />
      <Title size="h6">Definition</Title>
      <QueryBuilder
        focalPopulation={populationName}
        initialDefinition={props.existingDefinition}
        onDefinitionUpdate={onDefinitionUpdate}
        metadata={props.datasetMetadata}
        datasets={props.datasets}
        availableFields={props.availableFields}
      />

      <Anchor onClick={getResponseCount}>
        <Tooltip
          label="Click to refresh count"
          openDelay={400}
          position="top-start"
        >
          {populationCount ? (
            <Text
              style={{ marginTop: '8px' }}
              size="sm"
              weight={'500'}
              color="dimmed"
            >
              # Responses:{' '}
              <Text span italic color="dimmed">
                {' '}
                {lastRequestId
                  ? 'Calculating...'
                  : populationCount
                  ? populationCount.toLocaleString()
                  : '-'}{' '}
              </Text>
            </Text>
          ) : (
            <Text
              style={{ marginTop: '8px' }}
              size="sm"
              weight={'500'}
              color="dimmed"
            >
              # Responses: {lastRequestId ? 'Calculating...' : '-'}
            </Text>
          )}
        </Tooltip>
      </Anchor>
      <div
        style={{
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'right',
          marginTop: '20px',
        }}
      >
        <Button
          variant="default"
          onClick={props.onClose}
          style={{ marginRight: '10px' }}
        >
          Cancel
        </Button>
        <Button
          variant="filled"
          onClick={() => {
            if (validateDefinition(populationDefinition) === true) {
              props.onComplete(populationName, populationDefinition);
            }
          }}
          disabled={
            populationNameError !== undefined ||
            populationDefinitionError !== undefined ||
            populationDefinition === undefined ||
            itemsHaveChanged === false
          }
        >
          {props.isExistingPopulation === true ? 'Save' : 'Create'}
        </Button>
      </div>
    </>
  );
};
