import QuoteModel from '../models/QuoteModel';
import APIRequesterBase from './APIBase';
import axios from 'axios';
import CRN from '../models/Crn';
import QuoteReaderFilterObject from '../models/ViewFilterMappingModel';

class QuoteAPI extends APIRequesterBase {
  static async getQuotes(
    crn: CRN,
    filters: QuoteReaderFilterObject
  ): Promise<QuoteModel[]> {
    const url = `${this.getAPIUrl()}/resources/quotes/?ResourceCRN=${
      crn.identifier
    }`;
    const headers = await this.getHeaders();

    // Construct the body of the request from the filters provided...
    var body: any = (body = {
      Sentiment: filters.sentiment,
      Demographic: filters.demographics,
      Vopics: filters.vopics,
      Metric: filters.metric,
    });

    // Check to see if the requestedDemographics is set, if so, add it to the body...
    if (filters.requestedDemographics) {
      body.RequestedDemographics = Object.keys(filters.requestedDemographics);
    }

    // Make a post url to the endpoint and handle the response from the server...
    return axios.post(url, body, { headers: headers }).then((response) => {
      const quotes: QuoteModel[] = response.data.map((quote: any) => {
        // For the quote map the demographics to the alias provided in filters..

        if (filters.requestedDemographics) {
          var newRetrievedDemographics: {
            [key: string]:
              | string
              | number
              | boolean
              | undefined
              | Date
              | unknown;
          } = {};

          for (const [demographicName, demographicValue] of Object.entries(
            quote.Demographics
          )) {
            newRetrievedDemographics[
              filters.requestedDemographics[demographicName]
            ] = demographicValue;
          }

          quote.Demographics = newRetrievedDemographics;
        }

        return QuoteModel.fromAPIResponse(quote);
      });
      return quotes;
    });
  }

  static async reportQuote(quote: QuoteModel): Promise<void> {
    const url = `${this.getAPIUrl()}/resources/quotes/report/`;
    const headers = await this.getHeaders();

    // Make a post url to the endpoint and handle the response from the server...
    const body = {
      DatasetCrn: quote.datasetCRN.identifier,
      RowId: quote.rowId,
      TextId: quote.textId,
      SentenceNumber: quote.sentenceNumber,
      Sentence: quote.sentence,
      Topic: quote.vopic,
      Text: quote.textInstance,
    };

    return axios.post(url, body, { headers: headers }).then((response) => {
      return;
    });
  }

  static async flagQuote(quote: QuoteModel): Promise<QuoteModel> {
    const url = `${this.getAPIUrl()}/resources/quotes/flag/?ResourceCRN=${
      quote.datasetCRN.identifier
    }`;
    const headers = await this.getHeaders();

    // Make a post url to the endpoint and handle the response from the server...
    const body = {
      DatasetCrn: quote.datasetCRN.identifier,
      RowId: quote.rowId,
      TextId: quote.textId,
      SentenceNumber: quote.sentenceNumber,
      Topic: quote.vopic,
    };

    return axios.post(url, body, { headers: headers }).then((response) => {
      quote.isFlagged = true; // Set to true
      return quote;
    });
  }
}

export default QuoteAPI;
