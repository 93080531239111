import { IAnalysisConfigBase } from '@redux/analysesSlice';

export enum AnalysisStatus {
  ERROR = 'ERROR',
  PROCESSING = 'PROCESSING',
  READY_WITH_RESTRICTIONS = 'READY_WITH_RESTRICTIONS',
  READY = 'READY',
}

export enum AnalysisType {
  DRIVERS_OF_OUTCOME = 'CultureX::API::Analysis::DriversOfOutcomeInPopulation',
  PROGRESS_OVER_TIME = 'CultureX::API::Analysis::ProgressOvertime',
  INTERNAL_BENCHMARK = 'CultureX::API::Analysis::InternalBenchmark',
  RAW_VOICE = 'CultureX::API::Analysis::RawVoice',
  EXTERNAL_BENCHMARK = 'CultureX::API::Analysis::ExternalBenchmark',
  COMPARE_TWO_POPULATIONS = 'CultureX::API::Analysis::CompareTwoPopulations',
  SYNTHESIS = 'CultureX::API::Analysis::Synthesis',
}

export function isAnalysisType(value: string): boolean {
  return Object.values(AnalysisType).includes(value as AnalysisType);
}

export function getAnalysisType(value: string): AnalysisType | undefined {
  return Object.values(AnalysisType).find((type) => type === value);
}

// Create a base model class called ProjectAnalysisModel
export interface IProjectAnalysisModel {
  id: string;
  name: string;
  type: AnalysisType;
  version: string;
  status: AnalysisStatus;
  created_at: Date;
  updated_at: Date;

  toJSON: () => object;

  toConfig: () => IAnalysisConfigBase;
}

export interface IProjectAnalysisRequestModel {
  name: string;
  type: AnalysisType;
  version: string;

  toJSON: () => object;
}
