import ProjectAPI from '@apis/ProjectAPI';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { Button, Tooltip } from '@mantine/core';
import QuoteModel from '@models/QuoteModel';
import { Quote } from '@stores/AnalysisStore';
import { IconExclamationCircle } from '@tabler/icons-react';
import React from 'react';

interface QuoteReportButtonProps {
  quote?: Quote;
}

export const QuoteReportButton: React.FC<QuoteReportButtonProps> = ({
  quote,
}: QuoteReportButtonProps) => {
  const project = useCurrentProject();

  const [submittingReport, setSubmittingReport] =
    React.useState<boolean>(false);
  const [showSuccessButton, setShowSuccessButton] =
    React.useState<boolean>(false);

  const reportQuote = () => {
    if (project && quote) {
      setSubmittingReport(true);
      ProjectAPI.reportQuote(project.projectId, project.organizationId, quote)
        .then((response) => {
          setShowSuccessButton(true);
          setTimeout(() => {
            setShowSuccessButton(false);
          }, 1000);
        })
        .catch((error) => {
          setShowSuccessButton(true);
          setTimeout(() => {
            setShowSuccessButton(false);
          }, 1000);
        })
        .finally(() => {
          setSubmittingReport(false);
        });
    }
  };

  return (
    <>
      {showSuccessButton && (
        <Button
          size="xs"
          color="green"
          variant="light"
          leftIcon={<IconExclamationCircle size={14} />}
        >
          Reported!
        </Button>
      )}
      {!showSuccessButton && (
        <Tooltip label="Click to report quote as inaccurate" openDelay={400}>
          <Button
            size="xs"
            color="red"
            variant="light"
            leftIcon={<IconExclamationCircle size={14} />}
            loading={submittingReport}
            onClick={reportQuote}
          >
            Inaccurate?
          </Button>
        </Tooltip>
      )}
    </>
  );
};
