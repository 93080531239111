import { FC, useCallback, useEffect } from 'react';
import { useAppStore } from '@stores/AppStore';
import ProjectAPI from '@apis/ProjectAPI';
import { AnalysisStatus } from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Anchor } from '@mantine/core';
import { useNavigate } from '@hooks/useNavigate';

const AnalysisNotifications: FC = () => {
  const analysesInProcess = useAppStore((s) => s.analysesInProcess);
  const setAnalysesInProcess = useAppStore((s) => s.setAnalysesInProcess);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const checkAnalysisStatus = useCallback(() => {
    analysesInProcess.forEach(async ({ projectId, analysisId }) => {
      const project = await ProjectAPI.getProject(projectId);
      const analysis = project.analyses.find((a) => a.id === analysisId);
      if (analysis.status === AnalysisStatus.READY) {
        setAnalysesInProcess(
          analysesInProcess.filter(
            (ap) =>
              !(ap.projectId === projectId && ap.analysisId === analysisId)
          )
        );
        queryClient.refetchQueries({ queryKey: ['project', projectId] });
        notifications.hide(`analysis-created-${projectId}-${analysis.id}`);
        notifications.show({
          id: `analysis-ready-${projectId}-${analysis.id}`,
          color: 'green',
          title: 'Analysis ready',
          message: (
            <>
              {`The analysis ${analysis.name} is ready to render.`}
              <Anchor
                onClick={() => navigate(`/projects/${projectId}/analyses/${analysisId}`)}
              >
                {' '}
                Click here to see it.
              </Anchor>
            </>
          ),
          icon: <IconCheck size="1rem" />,
          autoClose: 15000,
        });
      }
    });
  }, [analysesInProcess, queryClient]);

  useEffect(() => {
    const interval = setInterval(() => checkAnalysisStatus(), 10000);
    return () => {
      clearInterval(interval);
    };
  }, [checkAnalysisStatus]);

  return null;
};

export default AnalysisNotifications;
