import { MantineFieldSelect as MantineFieldSelectWidget } from './core/MantineFieldSelect';
import { MantineSelect as MantineSelectWidget } from './value/MantineSelect';
import { MantineMultiselect as MantineMultiSelectWidget } from './value/MantineMultiselect';
import { MantineDate as MantineDateWidget } from './value/MantineDate';
import { MantineNumberInput as MantineNumberInputWidget } from './value/MantineNumberInput';
import { MantineTextInput as MantineTextInputWidget } from './value/MantineTextInput';
import { MantineConjunctions as MantineConjunctionsWidget } from './core/MantineConjunctions';
import { MantineButton as MantineButtonWidget } from './core/MantineButton';
import { MantineProvider } from '@mantine/core';
// provider

const MantineProviderWrapper = ({ config, children }) => {
  const withProviders = { children };

  return withProviders;
};

export default {
  MantineSelectWidget,
  MantineFieldSelectWidget,
  MantineMultiSelectWidget,
  MantineDateWidget,
  MantineNumberInputWidget,
  MantineTextInputWidget,
  MantineConjunctionsWidget,
  MantineButtonWidget,
  MantineProviderWrapper,
};
