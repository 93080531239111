import {
  SimpleDataType,
  getUsableDataType,
} from '@components/mantineQueryBuilder/widgets/core/MantineFieldSelect';
import { getAliasFromColumn, getLabelFromColumn } from '@utils/MetadataUtils';
import { useState, useEffect, useMemo } from 'react';

export enum FieldSection {
  DEMOGRAPHIC = 'Demographic',
  RESPONSE = 'Response',
  OTHER = 'Other',
}

export interface DatasetField {
  id: string;
  dataset: string;
  field: string;
  alias?: string;
  description: string;
  section?: FieldSection;
  dataType: SimpleDataType;
  values: any[];
}

export const useDatasetFields = (
  datasets?: Map<string, any>,
  currentDataset?: string
) => {
  return useMemo(() => {
    var fields: DatasetField[] = [];

    if (datasets && datasets.size > 0) {
      const datasetAlias = currentDataset ?? datasets.keys().next().value;
      const metadata = datasets.get(datasetAlias);

      if (metadata === undefined) return fields;

      // Go through the immutable columns...
      if (metadata.Content.Properties.ImmutableAttributes) {
        if (metadata.Content.Properties.ImmutableAttributes.Columns) {
          for (const [key, columnEntry] of Object.entries(
            metadata.Content.Properties.ImmutableAttributes.Columns
          )) {
            const columnName = Object.keys(columnEntry)[0];

            if (columnName.startsWith("CX_")) continue; // Skip this entry. CX_ is a special prefix for internal columns. The user should never have access to these.

            const columnInfo = columnEntry[columnName];

            const alias = getAliasFromColumn(columnName, metadata);
            const type = getLabelFromColumn(
              columnName,
              'Variable Type',
              metadata
            );
            const role = getLabelFromColumn(
              columnName,
              'Variable Role',
              metadata
            );

            var section = FieldSection.OTHER;
            if (role === 'Respondent Input') {
              section = FieldSection.RESPONSE;
            } else if (role === 'Describe Respondent') {
              section = FieldSection.DEMOGRAPHIC;
            }

            let description = '';
            if (
              columnInfo.Values &&
              columnInfo.Values.length > 0 &&
              columnInfo.DataType === 'StringType'
            ) {
              description = `Categorical (${columnInfo.Values.length})`;
            } else if (columnInfo.DataType === 'StringType') {
              description = 'Categorical';
            } else if (
              columnInfo.DataType === 'IntegerType' &&
              role === 'Respondent Input'
            ) {
              description = 'Ordinal Outcome';
              if (columnInfo.Values && columnInfo.Values.length > 0) {
                description = `Ordinal Outcome (${columnInfo.Values.length})`;
              }
            } else if (
              Array(
                'IntegerType',
                'FloatType',
                'DoubleType',
                'ShortType'
              ).includes(columnInfo.DataType)
            ) {
              description = 'Numerical';
              if (columnInfo.Values && columnInfo.Values.length > 0) {
                description = `Numerical (${columnInfo.Values.length})`;
              }
            } else {
              description = columnInfo.DataType;
            }

            if (type !== 'Text') {
              const field: DatasetField = {
                id: `${datasetAlias}.${columnName}`,
                dataset: datasetAlias,
                field: columnName,
                alias: alias,
                description,
                section: section,
                dataType: getUsableDataType(columnInfo.DataType),
                values: columnInfo.Values ?? [],
              };

              //fields.set(field.dataset, [...(fields.get(field.dataset) || []), field]);
              fields.push(field);
            }
          }
        }
      }

      return fields;
    }

    return fields;
  }, [datasets, currentDataset]);
};
