import { useRouteStore } from '@stores/RouteStore';
import { Link as OriginalLink } from 'react-router-dom';

const Link = ({ to, ...props }) => {
  const organizationId = useRouteStore((s) => s.organizationId);
  const rtId = useRouteStore((s) => s.rtId);

  // Append query parameters
  // to = `${to}?organizationId=${organizationId}&rtId=${rtId}`;

  // Check to make sure organizationId and rtId are defined
  if (organizationId && rtId) {
    to = `${to}?organizationId=${organizationId}&rtId=${rtId}`;
  } else if (organizationId) {
    to = `${to}?organizationId=${organizationId}`;
  } else if (rtId) {
    to = `${to}?rtId=${rtId}`;
  }

  return <OriginalLink {...props} to={to} />;
};

export default Link;
