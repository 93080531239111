import ProjectModel from '@models/ProjectModel';
import { useMemo } from 'react';
import { Analysis, useProjectAnalyses } from './useProjectAnalyses';
import { AnalysisType, Population } from '@stores/AnalysisEditionStore';

export interface SummaryAnalysis {
  id: string;
  name: string;
  type: AnalysisType;
  populations: string[];
  focalPopulation: string;
  focalPopulationDefinition: Population;
  benchmark: string;
}

export interface Summary {
  id: string;
  name: string;
  analyses: SummaryAnalysis[];
  projectId: string;
  organizationId: number;
  createdAt: Date;
  updatedAt: Date;
}

export const useProjectSummaries = (project: ProjectModel | undefined): Summary[] => {
  const analyses = useProjectAnalyses(project)

  return useMemo(() => {
    if(!project) {
      return [];
    }

    return project.summaries.map(sm => ({
      id: sm.Id,
      name: sm.Name,
      analyses: sm.Analyses.map(sma => {
        const analysis = analyses.find(a => a.id === sma.AnalysisId)
        if(!analysis) {
          return null;
        }
        return {
          id: sma.AnalysisId,
          name: analysis.name,
          populations: analysis.populations,
          type: analysis.type as AnalysisType,
          focalPopulation: sma.FocalPopulation,
          focalPopulationDefinition: analysis.populationDefinitions[sma.FocalPopulation],
          benchmark: analysis.type === AnalysisType.INTERNAL_BENCHMARK ? (analysis as any).internalBenchmark :  analysis.type === AnalysisType.RAW_VOICE ? (analysis as any).populationBenchmark : analysis.type === AnalysisType.PROGRESS_OVER_TIME ? (analysis as any).overtimeBenchmark : '',
        }
      }),
      projectId: project.projectId,
      organizationId: project.organizationId,
      createdAt: new Date(sm.CreatedAt),
      updatedAt: new Date(sm.UpdatedAt),
    }))
  }, [ project?.summaries, analyses ]);
};
