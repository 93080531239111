import {
  IOvertimeComparisonAnalysisConfig,
} from '@redux/analysesSlice';
import {
  IProjectAnalysisModel,
  IProjectAnalysisRequestModel,
  AnalysisType,
  AnalysisStatus,
} from './ProjectAnalysisModelBase';

export class OvertimeComparisonAnalysisModel implements IProjectAnalysisModel {
  id: string;
  name: string;
  type: AnalysisType;
  version: string;
  status: AnalysisStatus;
  created_at: Date;
  updated_at: Date;

  // MARK: - Drivers Specific Properties / Options
  populationOrder: string[];
  overtimeBenchmark: string;

  constructor(
    id: string,
    name: string,
    type: AnalysisType,
    version: string,
    status: AnalysisStatus,
    created_at: Date,
    updated_at: Date,
    populationOrder: string[],
    overtimeBenchmark: string
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.version = version;
    this.status = status;
    this.created_at = created_at;
    this.updated_at = updated_at;

    this.populationOrder = populationOrder;
    this.overtimeBenchmark = overtimeBenchmark;
  }

  static fromAPIResponse(response: object): OvertimeComparisonAnalysisModel {
    // Our response will be an object that will conform to our class structure
    const analysisId = response['Id'];
    const name = response['Name'];
    const type: AnalysisType = AnalysisType.PROGRESS_OVER_TIME;
    const version: string = response['Version'];
    const status: AnalysisStatus = AnalysisStatus[response['Status']]; // TODO: - Fix this issue. Status isn't returned from the server. We need to compute...
    const created_at = new Date(response['CreatedAt'] + "Z");
    const updated_at = new Date(response['UpdatedAt'] + "Z");

    const populationOrder = response['PopulationOrder'];

    const overtimeBenchmark = response['OvertimeBenchmark'];

    return new OvertimeComparisonAnalysisModel(
      analysisId,
      name,
      type,
      version,
      status,
      created_at,
      updated_at,
      populationOrder,
      overtimeBenchmark
    );
  }

  toJSON = (): object => {
    return {
      Id: this.id,
      Name: this.name,
      Type: this.type,
      Version: this.version,
      Status: this.status,
      DateAdded: this.created_at,
      DateUpdated: this.updated_at,
      populationOrder: this.populationOrder,
      overtimeBenchmark: this.overtimeBenchmark,
    };
  };

  toConfig = (): IOvertimeComparisonAnalysisConfig => {
    return {
      id: this.id,
      title: this.name,
      analysisType: this.type,
      populationOrder: this.populationOrder,
      overtimeBenchmark: this.overtimeBenchmark
    };
  };
}

// MARK: - Interface to make request to API with...
export class OvertimeComparisonAnalysisRequestModel
  implements IProjectAnalysisRequestModel
{
  name: string;
  type: AnalysisType;
  version: string;

  populationOrder: string[];
  overtimeBenchmark: string;

  constructor(
    name: string,
    version: string,
    populationOrder: string[],
    overtimeBenchmark: string
  ) {
    this.name = name;
    this.type = AnalysisType.PROGRESS_OVER_TIME;
    this.version = version;
    this.populationOrder = populationOrder;
    this.overtimeBenchmark = overtimeBenchmark;
  }

  toJSON(): object {
    return {
      Name: this.name,
      Type: this.type,
      Version: this.version,
      PopulationOrder: this.populationOrder,
      OvertimeBenchmark: this.overtimeBenchmark,
    };
  }
}
