import { useQuery } from '@tanstack/react-query';
import ProjectAPI, { ProjectMetricType } from '@apis/ProjectAPI';
import ProjectModel from '@models/ProjectModel';
import {
  AnalysisType,
  IProjectAnalysisModel,
} from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import { InternalBenchmarkAnalysisModel } from '@models/ProjectAnalysisModel/InternalBenchmarkAnalysisModel';
import { BenchmarkDataPoint } from '@components/charts/datamodels/BenchmarkDataPoint';
import { DriversOfOutcomeAnalysisModel } from '@models/ProjectAnalysisModel/DriversOfOutcomeAnalysisModel';
import { Population } from '@redux/benchmarksSlice';
import { RawVoiceAnalysisModel } from '@models/ProjectAnalysisModel/RawVoiceAnalysisModel';
import { OvertimeComparisonAnalysisModel } from '@models/ProjectAnalysisModel/OvertimeComparisonAnalysisModel';

export const useCoMentionData = (
  project?: ProjectModel,
  currentAnalysis?: IProjectAnalysisModel,
  selectedPopulation?: string,
  focalTopic?: string
) => {
  return useQuery({
    queryKey: [
      'co-mention-data',
      project?.projectId,
      project?.organizationId,
      currentAnalysis?.id,
      selectedPopulation,
      focalTopic,
    ],
    queryFn: async () => {
      let analysis:
        | InternalBenchmarkAnalysisModel
        | DriversOfOutcomeAnalysisModel
        | RawVoiceAnalysisModel
        | OvertimeComparisonAnalysisModel;
      let benchmark: string | null = null;
      let focalPopulation: string | Population = selectedPopulation;

      if (currentAnalysis.type === AnalysisType.DRIVERS_OF_OUTCOME) {
        analysis = currentAnalysis as DriversOfOutcomeAnalysisModel;
        focalPopulation = analysis.focalPopulation;
      } else if (currentAnalysis.type === AnalysisType.RAW_VOICE) {
        analysis = currentAnalysis as RawVoiceAnalysisModel;
        benchmark = analysis.populationBenchmark;
        focalPopulation = selectedPopulation;
      } else if (currentAnalysis.type === AnalysisType.PROGRESS_OVER_TIME) {
        analysis = currentAnalysis as OvertimeComparisonAnalysisModel;
        benchmark = analysis.overtimeBenchmark;
        focalPopulation = selectedPopulation;
      } else {
        benchmark = (currentAnalysis as InternalBenchmarkAnalysisModel)
          .internalBenchmark;
        focalPopulation = selectedPopulation;
      }

      // Run the queries in parallel and wait for all of them to finish....
      const negativeCoMentionDataPromise = ProjectAPI.getBenchmarkData(
        project.projectId,
        benchmark,
        focalPopulation,
        '*',
        project.organizationId,
        ProjectMetricType.NegativeCoMention,
        focalTopic
      );
      const positiveCoMentionDataPromise = ProjectAPI.getBenchmarkData(
        project.projectId,
        benchmark,
        focalPopulation,
        '*',
        project.organizationId,
        ProjectMetricType.PositiveCoMention,
        focalTopic
      );
      const notMentionedCoMentionDataPromise = ProjectAPI.getBenchmarkData(
        project.projectId,
        benchmark,
        focalPopulation,
        '*',
        project.organizationId,
        ProjectMetricType.NotMentioned,
        focalTopic
      );

      return Promise.all([
        negativeCoMentionDataPromise,
        positiveCoMentionDataPromise,
        notMentionedCoMentionDataPromise,
      ]).then((values: BenchmarkDataPoint[][]) => {
        const negativeCoMentionData = values[0];
        const positiveCoMentionData = values[1];
        const notMentionedData = values[2];

        return {
          negativeCoMentionData,
          positiveCoMentionData,
          notMentionedData,
        };
      });
    },
    enabled:
      !!project && !!currentAnalysis && !!selectedPopulation && !!focalTopic,
    staleTime: 1000 * 60 * 10, // 10 minutes...
  });
};
