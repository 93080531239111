import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Grid, Button, Group, Title, Alert } from '@mantine/core';
import DatasetSelectionStep from './DatasetSelectionStep';
import ProjectAPI from '../../apis/ProjectAPI';
import {
  Benchmark,
  Population,
  addBenchmark,
  resetBenchmarks,
} from '../../redux/benchmarksSlice';
import {
  IAnalysisConfigBase,
  ICompareTwoPopulationsAnalysisConfig,
  IDriversOfOutcomeAnalysisConfig,
  IExternalBenchmarkAnalysisConfig,
  IInternalBenchmarkAnalysisConfig,
  IOutcomeMeasure,
  IOvertimeComparisonAnalysisConfig,
  ISynthesisAnalysisConfig,
  addAnalysis,
  resetAnalyses,
  updateAnalysis,
} from '../../redux/analysesSlice';
import ProjectModel, {
  CreateProjectRequestModel,
} from '../../models/ProjectModel';
import { useDispatch } from 'react-redux';
import { useNavigate } from '@hooks/useNavigate';

import DatasetModel from '../../models/DatasetModel';
import APIErrorMessage from '../../components/APIErrorMessage';
import {
  AnalysisType,
  IProjectAnalysisRequestModel,
} from '../../models/ProjectAnalysisModel/ProjectAnalysisModelBase';
// import { LocalActivityTwoTone } from '@material-ui/icons';
import './pageStepper.css';
import {
  DriversOfOutcomeAnalysisRequestModel,
  OutcomeConfiguration,
} from '@models/ProjectAnalysisModel/DriversOfOutcomeAnalysisModel';
import { InternalBenchmarkAnalysisRequestModel } from '@models/ProjectAnalysisModel/InternalBenchmarkAnalysisModel';
import { CompareTwoPopulationsAnalysisRequestModel } from '@models/ProjectAnalysisModel/CompareTwoPopulationsAnalysisModel';
import { SynthesisAnalysisRequestModel } from '@models/ProjectAnalysisModel/SynthesisAnalysisModel';
import { ExternalBenchmarkAnalysisRequestModel } from '@models/ProjectAnalysisModel/ExternalBenchmarkAnalysisModel';
import {
  HelpGuide,
  InfoHelperButton,
} from '@components/InfoHelper/InfoHelperButton';
import { OvertimeComparisonAnalysisRequestModel } from '@models/ProjectAnalysisModel/OvertimeComparisonAnalysisModel';
import { IconAlertCircle } from '@tabler/icons';
import { getAliasFromColumn, getLabelFromColumn } from '@utils/MetadataUtils';

interface NewProjectConfigFlowStepProps {
  datasetData: DatasetModel[];
  selectedDatasets: string[];
  setSelectedDatasets: (datasets: string[]) => void;
  datasetMetadata?: any[];
  isLoadingDatasets: boolean;
  setNewProjectTitle: (title: string) => void;
  errorFetchDatasets: object;
  isProjectLoading: boolean;
}

interface ValidationResponse {
  hasErrors: boolean;
  analyses: IAnalysisConfigBase[];
}

const createDefaultAnalyses = (datasetAliasToMetadataMapping: {
  [key: string]: any;
}): {
  analyses: IInternalBenchmarkAnalysisConfig[];
  benchmarks: Benchmark[];
} => {
  var benchmarks: Benchmark[] = [];
  var analyses: IInternalBenchmarkAnalysisConfig[] = [];

  const hasMultipleDatasets =
    Object.keys(datasetAliasToMetadataMapping).length > 1;

  // Loop through the dictionary and get the metadata for each dataset
  for (const [datasetAlias, metadata] of Object.entries(
    datasetAliasToMetadataMapping
  )) {
    // Go through each column which has less than 10 distinct values...

    const datasetId = metadata.DatasetCrn.split('/')[1];

    for (const [key, _columnInfo] of Object.entries(
      metadata.Content.Properties.ImmutableAttributes.Columns
    )) {
      const colName = Object.keys(_columnInfo)[0];
      if (colName.startsWith("CX_")) continue; // Skip this entry. CX_ is a special prefix for internal columns. The user should never have access to these.

      const columnInfo = _columnInfo[colName];

      const nValues = columnInfo.Values ? columnInfo.Values.length : 10000000;

      if (nValues <= 10) {
        // We can use this information...
        // Let's check to see if this is Ordinal. If it is, we can't use it. Because a lot of the times there are dozens of columns for Ordinal data.

        if (
          getLabelFromColumn(colName, 'Variable Type', metadata) !== 'Ordinal'
        ) {
          // Ok, we can use this...
          // Let's create an analysis name and benchmark name...

          const columnAlias = getAliasFromColumn(colName, metadata);

          var benchmarkName = `${columnAlias}`;

          if (hasMultipleDatasets) {
            benchmarkName = `${datasetAlias} - ${columnAlias}`;
          }

          // Limit the benchmark name to 100 characters max...
          benchmarkName = benchmarkName.substring(0, 100);

          const analysis: IInternalBenchmarkAnalysisConfig = {
            analysisType: AnalysisType.INTERNAL_BENCHMARK,
            title: benchmarkName,
            focalPopulation: null,
            benchmark: benchmarkName,
          };

          // Now let's fill up the benchmark object with relevant information...

          const populations: Population[] = [];

          columnInfo.Values.forEach((value) => {
            if (value !== null && value !== undefined) {
              const population: Population = {
                title:
                  value !== undefined && value !== null
                    ? String(value).substring(0, 256)
                    : 'BLANK',
                definition: {
                  '==': [
                    {
                      Column: colName,
                      Dataset: `DS_${datasetId}`,
                    },
                    value,
                  ],
                },
              };

              populations.push(population);
            }
          });

          const benchmark: Benchmark = {
            title: benchmarkName,
            populations: populations,
          };

          benchmarks.push(benchmark);
          analyses.push(analysis);
        }
      }
    }
  }

  return {
    analyses,
    benchmarks,
  };
};

const NewProjectConfigFlowStep: React.FC<NewProjectConfigFlowStepProps> = (
  props
) => {
  const [active, setActive] = useState<number>(0);
  const [valueTitle, setValueTitle] = useState<string>('');
  const [valueDescription, setValueDescription] = useState<string>('');
  const [selectedDatasets, setValueDataset] = useState<string[]>([]);
  const [selectedBenchmark, setSelectedBenchmark] = useState<string>('');
  const [activeStep2, setActiveStep2] = useState<boolean>(false);
  const [isProjectCreating, setIsProjectCreating] = useState<boolean>(false);
  const [errorSelectTitleDataset, setErrorSelectTitleDataset] =
    useState<boolean>(false);
  const [errorSelectDataset, setErrorSelectDataset] = useState<boolean>(false);
  const [errorPageBenchmarks, setErrorPageBenchmarks] =
    useState<boolean>(false);
  const [errorCreateProject, setErrorCreateProject] = useState<
    object | undefined
  >(undefined);
  const [selectedAnalysis, setSelectedAnalysis] = useState<string>('');
  const [availableDatasets, setAvailableDatasets] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const datasets = useSelector((state: RootState) => state.dataset.datasets);
  const benchmarksStore = useSelector(
    (state: RootState) => state.benchmarks.benchmarks
  );
  const analysesStore = useSelector(
    (state: RootState) => state.analyses.analyses
  );

  const [analysisError, setAnalysisError] = useState<
    React.ReactNode | undefined
  >(undefined);

  useEffect(() => {
    dispatch(resetBenchmarks());
    dispatch(resetAnalyses());
  }, []);
  useEffect(() => {
    props.setSelectedDatasets(selectedDatasets);
  }, [selectedDatasets]);

  useEffect(() => {
    if (valueTitle !== '') {
      props.setNewProjectTitle(valueTitle);
    }
  }, [valueTitle]);

  const nextStep = () => {
    if (active === 0 && (valueTitle === '' || selectedDatasets.length === 0)) {
      if (selectedDatasets.length === 0) {
        setErrorSelectDataset(true);
      } else {
        setErrorSelectDataset(false);
      }
      if (valueTitle === '') {
        setErrorSelectTitleDataset(true);
      } else {
        setErrorSelectTitleDataset(false);
      }
      return;
    }
    // if (active === 1 && (selectedBenchmark === "" && !activeStep2)) {
    //     setErrorPageBenchmarks(true);
    //     return;
    // }
    if (active === 0) {
      const formattedBenchmarks: { [key: string]: any } = {};
      benchmarksStore.forEach((benchmark: { [key: string]: any }) => {
        const title: string = benchmark.title;
        formattedBenchmarks[title] = {
          Populations: {},
          GlobalFilter: {},
        };

        const populations = benchmark.populations;
        const formattedPopulations: { [key: string]: any } = {};

        populations.forEach((population: { [key: string]: any }) => {
          const populationTitle = population.title;
          const definition = population.definition;

          formattedPopulations[populationTitle] = definition;
        });

        formattedBenchmarks[title]['Populations'] = formattedPopulations;
        formattedBenchmarks[title]['GlobalFilter'] =
          benchmark.globalFilterDefinition;

        if (formattedBenchmarks[title]['GlobalFilter'] === undefined) {
          formattedBenchmarks[title]['GlobalFilter'] = {};
        }

        if (formattedBenchmarks[title]['Populations'] === undefined) {
          formattedBenchmarks[title]['Populations'] = {};
        }
      });

      const formattedDatasets: { [key: string]: any } = {};

      // NOTE: - Datasets[0] here does not mean we're only supporting one dataset. It's just result of bad naming convention unfortunately.
      datasets[0]?.datasetsArray.forEach((dataset: { [key: string]: any }) => {
        formattedDatasets[`DS_${dataset.datasetName}`] = {
          DatasetCRN: dataset.crn,
          LongCSICRN: 'LATEST',
          QuotesCRN: 'LATEST',
          DemoTextsCRN: 'LATEST',
          TopicThemeMappingCRN: 'LATEST',
        };
      });

      // var allFocalPopulations = [];
      // if ("<FOCAL_POPULATIONS>" in formattedBenchmarks) {
      //     allFocalPopulations = Object.keys(formattedBenchmarks["<FOCAL_POPULATIONS>"]["Populations"]);
      // }

      // const validationResults = validateAnalyses(allFocalPopulations, Object.keys(formattedBenchmarks), analysesStore)

      // const affectedAnalyses = validationResults.analyses;
      // affectedAnalyses.forEach((analysis: IAnalysisConfigBase) => {
      //     dispatch(updateAnalysis({ newAnalysis: analysis }));
      // });

      // if (validationResults.hasErrors) {
      //     setAnalysisError(<Alert icon={<IconAlertCircle size="1rem" />} title="One or more analyses invalid" color="red">
      //         You have one or more analyses with errors. Please resolve all errors before creating.
      //     </Alert>);
      //     return;
      // } else {
      //     setAnalysisError(undefined);
      // }

      console.log(analysesStore);

      const formattedAnalyses: IProjectAnalysisRequestModel[] =
        analysesStore.map((analysis: IAnalysisConfigBase) => {
          switch (analysis.analysisType) {
            case AnalysisType.DRIVERS_OF_OUTCOME:
              const driversOfOutcomeAnalysisConfig =
                analysis as IDriversOfOutcomeAnalysisConfig;
              return new DriversOfOutcomeAnalysisRequestModel(
                driversOfOutcomeAnalysisConfig.title, // name
                '1.1.0', // version
                driversOfOutcomeAnalysisConfig.focalPopulation, // focalPopulation
                new OutcomeConfiguration(
                  null, // standardizedOutcome // TODO: - Add this standardized outcome if applicable...
                  driversOfOutcomeAnalysisConfig.outcomes.map((outcome) => {
                    return {
                      dataset: `DS_${outcome.dataset}`,
                      column: outcome.column,
                    };
                  })
                ),
                driversOfOutcomeAnalysisConfig.features,
                null, // internalBenchmark
                null, // externalBenchmark
                null // overtimeComparison
              );
            case AnalysisType.INTERNAL_BENCHMARK:
              const internalBenchmarkAnalysisConfig =
                analysis as IInternalBenchmarkAnalysisConfig;
              return new InternalBenchmarkAnalysisRequestModel(
                internalBenchmarkAnalysisConfig.title,
                '1.1.0',
                internalBenchmarkAnalysisConfig.focalPopulation,
                internalBenchmarkAnalysisConfig.benchmark
              );
            case AnalysisType.COMPARE_TWO_POPULATIONS:
              const compareTwoPopulationsAnalysisConfig =
                analysis as ICompareTwoPopulationsAnalysisConfig;

              return new CompareTwoPopulationsAnalysisRequestModel(
                compareTwoPopulationsAnalysisConfig.title,
                '1.1.0',
                compareTwoPopulationsAnalysisConfig.focalPopulation,
                compareTwoPopulationsAnalysisConfig.comparisonPopulation
              );
            case AnalysisType.SYNTHESIS:
              const synthesisAnalysisConfig =
                analysis as ISynthesisAnalysisConfig;
              return new SynthesisAnalysisRequestModel(
                synthesisAnalysisConfig.title,
                '1.1.0',
                synthesisAnalysisConfig.focalPopulation,
                synthesisAnalysisConfig.outcomes
                  ? new OutcomeConfiguration(
                      null, // standardizedOutcome // TODO: - Add this standardized outcome if applicable...
                      synthesisAnalysisConfig.outcomes.map((outcome) => {
                        return {
                          dataset: `DS_${outcome.dataset}`,
                          column: outcome.column,
                        };
                      })
                    )
                  : undefined,
                synthesisAnalysisConfig.internalBenchmark,
                synthesisAnalysisConfig.externalBenchmark,
                synthesisAnalysisConfig.comparisonPopulation,
                synthesisAnalysisConfig.timePeriodPopulations
              );
            case AnalysisType.EXTERNAL_BENCHMARK:
              const externalBenchmarkAnalysisConfig =
                analysis as IExternalBenchmarkAnalysisConfig;
              return new ExternalBenchmarkAnalysisRequestModel(
                externalBenchmarkAnalysisConfig.title,
                '1.1.0',
                externalBenchmarkAnalysisConfig.focalPopulation,
                externalBenchmarkAnalysisConfig.benchmark
              );
              break;

            case AnalysisType.PROGRESS_OVER_TIME:
              const overtimeComparisonAnalysisConfig =
                analysis as IOvertimeComparisonAnalysisConfig;
              return new OvertimeComparisonAnalysisRequestModel(
                overtimeComparisonAnalysisConfig.title,
                '1.1.0',
                overtimeComparisonAnalysisConfig.timePeriodPopulations
              );
          }
        });

      const newProjectData: CreateProjectRequestModel = {
        title: valueTitle,
        topicThemeMapping: 'LATEST',
        version: '1.1.0',
        description: valueDescription,
        datasets: formattedDatasets,
        benchmarks: formattedBenchmarks,
        analyses: formattedAnalyses,
      };

      setIsProjectCreating(true);

      ProjectAPI.createProject(newProjectData)
        .then((createdProject: ProjectModel) => {
          setIsProjectCreating(false);
          navigate(`/projects/${createdProject.projectId}`);
        })
        .catch((error) => {
          setIsProjectCreating(false);
          // setErrorCreateProject(error.message);
          setErrorCreateProject(
            <APIErrorMessage response={error} dismissable={false} />
          );
          console.error('Error API', error); // Log any errors
        });
    }

    // setActive((current) => (current < 1 ? current + 1 : current));
  };
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const isCreateProjectButtonActive: boolean = useMemo(() => {
    return analysesStore.length > 0;
  }, [analysesStore]);

  var title = `${valueTitle !== '' ? valueTitle : 'New Project'}`;
  var subtitle =
    'Create a new project to analyze various parts of your survey.';
  if (active === 0) {
    title = `${valueTitle !== '' ? valueTitle : 'New Project'}`;
  } else if (active === 1) {
    title = `${
      valueTitle !== '' ? valueTitle : 'New Project'
    } - Create Analyses`;
    subtitle =
      'Create analyses to look at specific subsets of data to uncover insights.';
  }

  var createProjectButtonTitle = 'Create Project';
  if (isProjectCreating) {
    createProjectButtonTitle = 'Creating...';
  }

  var nextProjectButtonTitle = 'Create project';
  if (props.isProjectLoading) {
    nextProjectButtonTitle = 'Loading...';
  } else {
    nextProjectButtonTitle = 'Create project';
  }

  useEffect(() => {
    if (props.datasetMetadata.length === props.selectedDatasets.length) {
      // Now let's create our list of analyses we can use to construct a fully valid project.
      // This is meant to be an initial list of analyses to get the user started, so they don't feel like they're starting from scratch.
      // This also allows them to skip the entire tedious step of setting up the analyses manually. Idea is to get started as quickly as possible...

      var datasetAliasMapping = {};

      props.selectedDatasets.forEach((datasetId) => {
        // Go through the datasetData props...
        props.datasetData.forEach((dataset) => {
          if (dataset.datasetId === datasetId) {
            datasetAliasMapping[datasetId] = dataset.alias;
          }
        });
      });

      // Now let's go through the datasetMetadata by order of index and map the metadata to the datasetAliasMapping
      var datasetAliasToMetadataMapping = {};
      props.datasetMetadata.forEach((metadata, index) => {
        const datasetId = metadata.DatasetCrn.split('/')[1];

        datasetAliasToMetadataMapping[datasetAliasMapping[datasetId]] =
          metadata;
      });

      const { analyses, benchmarks } = createDefaultAnalyses(
        datasetAliasToMetadataMapping
      );

      // Let's add the benchmarks to the store...
      benchmarks.forEach((benchmark) => {
        dispatch(addBenchmark(benchmark));
      });

      // Let's add them to the store...
      analyses.forEach((analysis) => {
        dispatch(addAnalysis(analysis));
      });
    }
  }, [props.datasetMetadata, props.selectedDatasets]);

  return (
    <>
      <Grid>
        <Grid.Col span={12} lg={12} md={12} sm={12}>
          <div
            style={{
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'left',
            }}
          >
            <Title order={3}>{title}</Title>
            <InfoHelperButton linkedGuide={HelpGuide.CreateProject} />
          </div>

          {subtitle}
          {active === 0 && (
            <DatasetSelectionStep
              datasetData={props.datasetData}
              valueTitle={valueTitle}
              setValueTitle={setValueTitle}
              valueDescription={valueDescription}
              setValueDescription={setValueDescription}
              selectedDatasets={selectedDatasets}
              setValueDataset={setValueDataset}
              isLoadingDatasets={props.isLoadingDatasets}
              errorSelectDataset={errorSelectDataset}
              setErrorSelectDataset={setErrorSelectDataset}
              errorSelectTitleDataset={errorSelectTitleDataset}
              setErrorSelectTitleDataset={setErrorSelectTitleDataset}
              availableDatasets={availableDatasets}
              onUpdateAvailableDatasets={setAvailableDatasets}
            />
          )}
          {/* {active === 1 && <AnalysesBenchmarksTabPage
                        benchmarksStore={benchmarksStore}
                        analysesStore={analysesStore}
                        datasets={datasets}
                        selectedBenchmark={selectedBenchmark}
                        selectedAnalysis={selectedAnalysis}
                        setSelectedAnalysis={setSelectedAnalysis}
                        setSelectedBenchmark={setSelectedBenchmark}
                        datasetMetadata={props.datasetMetadata}
                        setActiveStep2={setActiveStep2}
                        errorPageBenchmarks={errorPageBenchmarks}
                        setErrorPageBenchmarks={setErrorPageBenchmarks}
                    />} */}
          {props.errorFetchDatasets !== undefined && (
            <>{props.errorFetchDatasets}</>
          )}
          {errorCreateProject !== undefined && (
            <>
              <br />
              {errorCreateProject}
            </>
          )}
          <Group position="right">{analysisError}</Group>
          <Group position="right" mt="xl">
            <Button
              variant="subtle"
              color="dark"
              onClick={() => navigate('/projects')}
            >
              Cancel
            </Button>
            {active !== 0 && (
              <Button variant="default" onClick={prevStep}>
                Previous
              </Button>
            )}
            <Button
              onClick={nextStep}
              loading={isProjectCreating || props.isProjectLoading}
              disabled={active === 1 && !isCreateProjectButtonActive}
            >
              {active === 1 ? createProjectButtonTitle : nextProjectButtonTitle}
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default NewProjectConfigFlowStep;
