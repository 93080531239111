import {
  IProjectAnalysisModel,
  IProjectAnalysisRequestModel,
  AnalysisType,
  AnalysisStatus,
  isAnalysisType,
} from './ProjectAnalysisModelBase';

export interface IExternalBenchmarkFocalPopulation {
  populationName: string;
  external: boolean;
}

export class ExternalBenchmarkAnalysisModel implements IProjectAnalysisModel {
  id: string;
  name: string;
  type: AnalysisType;
  version: string;
  status: AnalysisStatus;
  created_at: Date;
  updated_at: Date;

  // MARK: - Drivers Specific Properties / Options
  focalPopulation: IExternalBenchmarkFocalPopulation;
  externalBenchmark: string;

  constructor(
    id: string,
    name: string,
    type: AnalysisType,
    version: string,
    status: AnalysisStatus,
    created_at: Date,
    updated_at: Date,
    focalPopulation: IExternalBenchmarkFocalPopulation,
    externalBenchmark: string
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.version = version;
    this.status = status;
    this.created_at = created_at;
    this.updated_at = updated_at;

    this.focalPopulation = focalPopulation;
    this.externalBenchmark = externalBenchmark;
  }

  static fromAPIResponse(response: object): ExternalBenchmarkAnalysisModel {
    // Our response will be an object that will conform to our class structure
    const analysisId = response['Id'];
    const name = response['Name'];
    const type: AnalysisType = AnalysisType.EXTERNAL_BENCHMARK;
    const version: string = response['Version'];
    const status: AnalysisStatus = AnalysisStatus[response['Status']]; // TODO: - Fix this issue. Status isn't returned from the server. We need to compute...
    const created_at = new Date(response['CreatedAt']);
    const updated_at = new Date(response['UpdatedAt']);

    const focalPopulationResponse = response['FocalPopulation'];
    const formattedFocalPopulation: IExternalBenchmarkFocalPopulation = {
      populationName: focalPopulationResponse['PopulationName'],
      external: focalPopulationResponse['External'],
    };

    const externalBenchmark = response['ExternalBenchmark'];

    return new ExternalBenchmarkAnalysisModel(
      analysisId,
      name,
      type,
      version,
      status,
      created_at,
      updated_at,
      formattedFocalPopulation,
      externalBenchmark
    );
  }

  toJSON = (): object => {
    return {
      Id: this.id,
      Name: this.name,
      Type: this.type,
      Version: this.version,
      Status: this.status,
      DateAdded: this.created_at,
      DateUpdated: this.updated_at,
      FocalPopulation: this.focalPopulation,
      ExternalBenchmark: this.externalBenchmark,
    };
  };
}

// MARK: - Interface to make request to API with...
export class ExternalBenchmarkAnalysisRequestModel
  implements IProjectAnalysisRequestModel
{
  name: string;
  type: AnalysisType;
  version: string;

  focalPopulation: IExternalBenchmarkFocalPopulation;
  externalBenchmark: string;

  constructor(
    name: string,
    version: string,
    focalPopulation: IExternalBenchmarkFocalPopulation,
    externalBenchmark: string
  ) {
    this.name = name;
    this.type = AnalysisType.EXTERNAL_BENCHMARK;
    this.version = version;
    this.focalPopulation = focalPopulation;
    this.externalBenchmark = externalBenchmark;
  }

  toJSON(): object {
    return {
      Name: this.name,
      Type: this.type,
      Version: this.version,
      FocalPopulation: {
        PopulationName: this.focalPopulation.populationName,
        External: this.focalPopulation.external,
      },
      ExternalBenchmark: this.externalBenchmark,
    };
  }
}
