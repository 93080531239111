import React, { useEffect, useMemo, useState } from 'react';
import * as echarts from 'echarts';
import {
  BenchmarkDataPoint,
  BenchmarkDataPointChartType,
} from './datamodels/BenchmarkDataPoint';
import { ShapDriversDataPoint } from './datamodels/ShapDriversDataPoint';
import { ChartBaseProps } from './ChartBase';

import { Center, useMantineTheme } from '@mantine/core';
import darkTheme from './themes/dark-theme.json';
import { useAnalysisStore } from '@stores/AnalysisStore';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { DriversOfOutcomeAnalysisModel } from '@models/ProjectAnalysisModel/DriversOfOutcomeAnalysisModel';
import { getAliasFromColumn } from '@utils/MetadataUtils';
import { useProjectDatasets } from '@apis/hooks/useProjectDatasets';
import APIErrorMessage from '@components/APIErrorMessage';
import { useCurrentAnalysis } from '@hooks/useCurrentAnalysis';
import { useDriversData } from '@apis/hooks/useDriversData';
import { useTopicStore } from '@stores/TopicStore';
import { useCurrentSummary } from '@hooks/useCurrentSummary';
import { useSummaryData } from '@apis/hooks/useSummaryData';
import { useElementSize } from '@mantine/hooks';

echarts.registerTheme('dark', darkTheme);

interface SentimentShapDataPoint {
  sentiment: number;
  combinedShap: number;
  topic: string;
  vopicCount: number;
  topicUserFriendlyName: string;
  negativeIncidence: number;
  positiveIncidence: number;
  combinedPercentTotalShap: number;
}

function colorInterpolationWithOpacity(
  sentiment: number,
  maxSentiment: number,
  combinedShap: number,
  maxCombinedShap: number
) {
  // Scale sentiment to range between 0 and 1 based on maxSentiment
  const scaledSentiment = sentiment / maxSentiment;

  // Scale combinedShap to range between 0.1 and 1 based on maxCombinedShap
  // This ensures that all points are visible (i.e., no opacity of 0)
  const scaledOpacity = 0.1 + (0.9 * (combinedShap / maxCombinedShap) + 0.4);

  let r, g;

  if (scaledSentiment < 0.5) {
    // interpolate between red (0) and fire yellow (0.5)
    // fire yellow approximated as rgb(238, 173, 14)
    const ratio = scaledSentiment / 0.5;
    r = 238 + Math.floor((255 - 238) * (1 - ratio));
    g = 14 + Math.floor((173 - 14) * ratio);
  } else {
    // interpolate between fire yellow (0.5) and forest green (1)
    // forest green approximated as rgb(34, 139, 34)
    const ratio = (scaledSentiment - 0.5) / 0.5;
    r = Math.floor(238 * (1 - ratio));
    g = 173 + Math.floor((139 - 173) * ratio);
  }

  // Return the color with the calculated opacity
  return `rgba(${r},${g},34,${scaledOpacity})`;
}

const colorPoint = (point: number, maxPoint: number) => {
  // Scale point to range between 0 and 1 based on maxPoint
  const scaledPoint = point / maxPoint;

  let r, g;

  if (scaledPoint < 0.5) {
    // interpolate between red (0) and fire yellow (0.5)
    // fire yellow approximated as rgb(238, 173, 14)
    const ratio = scaledPoint / 0.5;
    r = 238 + Math.floor((255 - 238) * (1 - ratio));
    g = 14 + Math.floor((173 - 14) * ratio);
  } else {
    // interpolate between fire yellow (0.5) and forest green (1)
    // forest green approximated as rgb(34, 139, 34)
    const ratio = (scaledPoint - 0.5) / 0.5;
    r = Math.floor(238 * (1 - ratio));
    g = 173 + Math.floor((139 - 173) * ratio);
  }

  // Return the color with the calculated opacity
  return `rgba(${r},${g},34,1)`;
};

const DriversOverviewPlot: React.FC = () => {
  const theme = useMantineTheme();

  //analysis Store
  const benchmarkData = useAnalysisStore((s) => s.data);
  const isDataLoading = useAnalysisStore((s) => s.isLoadingData);
  const focalPopulation = useAnalysisStore((s) => s.focalPopulation);
  const aggregationLevel = useAnalysisStore((s) => s.activeModelAggregation);

  //Topic Store
  const focalTopic = useTopicStore((s) => s.focalTopic);
  const setFocalTopic = useTopicStore((s) => s.setFocalTopic);

  const [ chartInstance, setChartInstance ] = useState<echarts.EChartsType | undefined>();
  const { ref: referenceComponent, width, height } = useElementSize();
  useEffect(() => chartInstance?.resize(), [width, height])
  
  const project = useCurrentProject();
  const currentAnalysis = useCurrentAnalysis();
  const currentSummary = useCurrentSummary();
  const { data: _summaryData } = useSummaryData(currentSummary);
  const summaryData = useMemo(() => {
    const analisys = (currentSummary?.analyses ?? []).find(a => a.id === currentAnalysis.id);
    if(!currentSummary || !analisys || analisys.focalPopulation !== focalPopulation) {
      return null
    }
    return _summaryData;
  }, [currentAnalysis?.id, currentSummary, focalPopulation,  _summaryData])

  const {
    data: shapData,
    isLoading: isDriversDataLoading,
    error: driversDataError,
    isError: isDriversDataError,
  } = useDriversData(project, currentAnalysis, aggregationLevel);

  const dataIsLoading = isDataLoading || isDriversDataLoading;

  const activeTopics = useAnalysisStore((s) => s.activeTopics);
  const activeThemes = useAnalysisStore((s) => s.activeThemes);

  const numberOfMentions = useAnalysisStore((s) => s.numberOfMentions);

  const { data: datasetMetadata } = useProjectDatasets(useCurrentProject());

  // ERRORS -
  const isBenchmarkError = useAnalysisStore((s) => s.dataIsError);
  const benchmarkError = useAnalysisStore((s) => s.dataError);

  const dataIsEmpty =
    benchmarkData && shapData
      ? benchmarkData.length === 0 || shapData.length === 0
      : true;

  const maxSentiment: number = useMemo(() => {
    return Math.max(
      ...benchmarkData.map((point: BenchmarkDataPoint) => point.sentiment)
    );
  }, [benchmarkData]);

  const maxCombinedShap: number = useMemo(() => {
    if (shapData === undefined) return 0;

    return Math.max(
      ...shapData.map((point: ShapDriversDataPoint) => point.combinedShap)
    );
  }, [shapData]);

  const data: SentimentShapDataPoint[] = useMemo(() => {
    if (shapData === undefined || benchmarkData === undefined) return [];
    // Compare data points in benchmarkData and shapData
    // Look for matches between topic and feature
    // If match, create a new SentimentShapDataPoint
    // Loop through benchmarkData
    const newData: SentimentShapDataPoint[] = [];
    for (let benchmarkPoint of benchmarkData) {
      // Find matching shapData point
      const matchingShapPoint = shapData
        .filter((point) => point.featureInModel === true)
        .find(
          (shapPoint: ShapDriversDataPoint) =>
            shapPoint.feature.toLowerCase() ===
            benchmarkPoint.topic.toLowerCase()
        );

      // If a matching shapPoint was found, create new SentimentShapDataPoint
      if (matchingShapPoint) {
        newData.push({
          sentiment: benchmarkPoint.sentiment,
          combinedShap: matchingShapPoint.combinedPercentTotalShap,
          vopicCount: benchmarkPoint.vopicCount,
          topic: benchmarkPoint.topic,
          topicUserFriendlyName: benchmarkPoint.topicUserFriendlyName,
          negativeIncidence: benchmarkPoint.negativeIncidence,
          positiveIncidence: benchmarkPoint.positiveIncidence,
          combinedPercentTotalShap: matchingShapPoint.combinedPercentTotalShap,
        });
      }
    }

    return newData.filter((dataPoint: SentimentShapDataPoint) => {
      return (
        (activeTopics.includes(dataPoint.topic) ||
          activeThemes.includes(dataPoint.topic)) &&
        dataPoint.vopicCount >= numberOfMentions
      );
    });
  }, [benchmarkData, shapData, activeThemes, activeTopics, numberOfMentions]);

  const title = useMemo(() => {
    if (currentAnalysis) {
      const outcome = currentAnalysis.outcome ?? [];
      if (outcome.length > 1) {
        return `Strongest predictors within "${focalPopulation}"`;
      } else if (outcome.length === 1) {
        return `Strongest predictors of "${outcome[0].rawName}" within "${focalPopulation}"`
      }
    }
    return `Strongest predictors within "${focalPopulation}"`;
  }, [currentAnalysis, focalPopulation]);

  const xAxisTitle = useMemo(() => {
    if (currentAnalysis) {
      const outcome = currentAnalysis.outcome ?? [];
      if (outcome.length > 1) {
        return `Strength of predictor (% of Outcomes within "${focalPopulation}" explained by topic)`
      } else if (outcome.length === 1) {
        return `Strength of predictor (% of "${outcome[0].rawName}" within "${focalPopulation}" explained by topic)`
      }
    }
    return `Strength of predictor (% of Outome within "${focalPopulation}" explained by topic)`
  }, [currentAnalysis, focalPopulation]);

  const yAxisTitle = useMemo(() => `Percentage of "${focalPopulation}" that mentions topic favorably`, [focalPopulation]);
  
  const maxCombinedShapTimesAdjustNegInc = useMemo(() => {
    return Math.max(
      ...data.map(
        (point: SentimentShapDataPoint) =>
          point.combinedShap *
          (point.positiveIncidence - point.negativeIncidence)
      )
    );
  }, [data]);

  const addExcludeKeepGraphic = (chart: any, selectedPoint: any) => {
    // Create a custom graphic element to show the box and buttons
    var boxGroup = {
      type: 'group',
      left: chart.convertToPixel({ seriesIndex: 0 }, selectedPoint)[0] - 75,
      top: chart.convertToPixel({ seriesIndex: 0 }, selectedPoint)[1] - 40,
      children: [
        {
          type: 'rect',
          left: '-50',
          top: '-15',
          z: 100,
          shape: {
            width: 125,
            height: 25,
            r: 2,
          },
          style: {
            fill: '#fff',
            stroke: '#999',
            lineWidth: 1,
          },
        },
        {
          type: 'text',
          left: '-35',
          top: '-10',
          z: 100,
          style: {
            text: 'Exclude',
            textAlign: 'center',
            textFill: '#333',
            fontSize: 14,
          },
          onclick: function () {
            // Add your code here to exclude the selected point
            /*const formattedSelectedPoint: SentimentShapDataPoint = {
              sentiment: selectedPoint[1],
              combinedShap: selectedPoint[0],
              topic: selectedPoint[2],
              topicUserFriendlyName: selectedPoint[3],
              negativeIncidence: selectedPoint[4],
              positiveIncidence: selectedPoint[5],
              combinedPercentTotalShap: selectedPoint[6],
            };*/

            removeExcludeKeepGraphic(chart);
          },
        },
        {
          type: 'text',
          left: '20',
          top: '-10',
          z: 100,
          style: {
            text: 'Focus',
            textAlign: 'center',
            textFill: '#333',
            fontSize: 14,
          },
          onclick: function () {
            // Add your code here to keep only the selected point
            // Add your code here to exclude the selected point
            /*const formattedSelectedPoint: SentimentShapDataPoint = {
              sentiment: selectedPoint[1],
              combinedShap: selectedPoint[0],
              topic: selectedPoint[2],
              topicUserFriendlyName: selectedPoint[3],
              negativeIncidence: selectedPoint[4],
              positiveIncidence: selectedPoint[5],
              combinedPercentTotalShap: selectedPoint[6],
            };*/

            removeExcludeKeepGraphic(chart);
          },
        },
      ],
    };

    // Add the custom graphic element to the chart
    chart.setOption({
      graphic: selectedPoint ? boxGroup : null,
    });
  };

  const addExcludeKeepGraphicToRange = (
    chart: any,
    selectedRange: any[],
    selectedPoints: any[]
  ) => {
    // Create a custom graphic element to show the box and buttons
    const [leftMostX, rightMostX] = selectedRange[0];
    const [leftMostY, rightMostY] = selectedRange[1];
    const width = rightMostY - leftMostX;
    var boxGroup = {
      type: 'group',
      x: rightMostX - 100, // Adjusted to center the box group horizontally
      y: leftMostY - 12,
      children: [
        {
          type: 'rect',
          left: '-50',
          top: '-15',
          z: 100000,
          shape: {
            width: 150,
            height: 25,
            r: 2,
          },
          style: {
            fill: '#fff',
            stroke: '#999',
            lineWidth: 1,
          },
        },
        {
          type: 'text',
          left: '-35',
          top: '-10',
          z: 100000,
          style: {
            text: 'Exclude',
            textAlign: 'center',
            textFill: '#333',
            fontSize: 14,
            cursor: 'pointer',
          },
          onclick: function () {
            // Add your code here to exclude the selected range
            /*const formattedSelectedRange = selectedPoints.map((point: any) => {
              const formattedPoint: SentimentShapDataPoint = {
                sentiment: point[1],
                combinedShap: point[0],
                topic: point[2],
                topicUserFriendlyName: point[3],
                negativeIncidence: point[4],
                positiveIncidence: point[5],
                combinedPercentTotalShap: point[6],
              };
              return formattedPoint.topic;
            });*/

            removeExcludeKeepGraphic(chart);
          },
        },
        {
          type: 'text',
          left: '20',
          top: '-10',
          z: 1000000,
          style: {
            text: 'Keep Only',
            textAlign: 'center',
            textFill: '#333',
            fontSize: 14,
            cursor: 'pointer !important',
          },
          onclick: function () {
            // Add your code here to keep only the selected range
            /*const formattedSelectedRange = selectedPoints.map((point: any) => {
              const formattedPoint: SentimentShapDataPoint = {
                sentiment: point[1],
                combinedShap: point[0],
                topic: point[2],
                topicUserFriendlyName: point[3],
                negativeIncidence: point[4],
                positiveIncidence: point[5],
                combinedPercentTotalShap: point[6],
              };
              return formattedPoint.topic;
            });*/

            removeExcludeKeepGraphic(chart);
          },
        },
      ],
    };

    // Add the custom graphic element to the chart
    chart.setOption({
      graphic: boxGroup,
    });
  };

  const removeExcludeKeepGraphic = (chart: any) => {
    // Remove the custom graphic element from the chart
    const currentOptions = chart.getOption();

    currentOptions.graphic = null;

    chart.setOption(currentOptions, true);
  };

  useEffect(() => {
    const chartElement = document.getElementById('myChart');
    if (!chartElement) {
      return;
    }
    const chart = echarts.init(chartElement, theme.colorScheme);

    if (dataIsEmpty && !dataIsLoading) {
      chart.setOption({
        title: {
          text: 'No Data Available',
          subtext:
            'This may be due to a filter being applied, or because the data is not available yet',
          top: 'center',
          textStyle: {
            fontSize: 20,
          },
          subtextStyle: {
            fontSize: 16,
          },
        },
      });
    } else {
      const option: any = {
        xAxis: {
          name: xAxisTitle,
          nameTextStyle: {
            fontWeight: 'bold',
          },
          nameGap: 20,
          nameLocation: 'middle',
          axisLabel: {
            color: 'black',
            formatter: (value: number) => {
              return Math.floor(100 * value) + '%';
            },
          },
        },
        yAxis: {
          name: yAxisTitle,
          nameTextStyle: {
            fontWeight: 'bold',
          },
          nameGap: 40,
          nameLocation: 'middle',

          axisLabel: {
            color: 'black',
            formatter: (value: number) => {
              return Math.floor(100 * value) + '%';
            },
          },
        },
        dataZoom: [{
          type: 'inside',
          moveOnMouseWheel: false
        }],
        series: [
          {
            type: 'scatter',
            data: data.map((point: SentimentShapDataPoint) => [
              point.combinedShap,
              point.sentiment,
              point.topic,
              point.topicUserFriendlyName,
            ]),
            symbolSize: 15,
            symbol: function (data) {
              const topic = data[2];

              if (topic) {
                if (focalTopic === topic) {
                  return 'diamond';
                }
              }

              return 'circle';
            },
            label: {
              show: true,
              formatter: (values: any) => {
                const dataIndex = values.dataIndex;
                const formattedPoint: SentimentShapDataPoint = data[dataIndex];
                return formattedPoint.topicUserFriendlyName;
              },
              position: 'right',
              margin: 10,
            },
            selectedMode: 'single',
            labelLayout: {
              hideOverlap: true,
            },

            itemStyle: {
              color: (params: any) => {
                const dataIndex = params.dataIndex;
                const point: SentimentShapDataPoint = data[dataIndex];
                if (point) {
                  return colorInterpolationWithOpacity(
                    point.sentiment,
                    maxSentiment,
                    point.combinedShap,
                    maxCombinedShap
                  );
                }
              },
              borderRadius: [0, 5, 5, 0],
            },

            select: {
              itemStyle: {
                borderWidth: 3,
                borderColor: 'black',
              },
            },

            emphasis: {
              disabled: true,
              focus: 'self',
              scale: false,
            },
          },
        ],

        // brush: {
        //     toolbox: ["rect", "clear"],
        //     transformable: false,
        //     saveAsImage: {},
        //     inBrush: {
        //         opacity: 1,
        //         borderRadius: 3,
        //         borderColor: "black"
        //     },
        //     outOfBrush: {
        //         color: "gray",
        //         opacity: 0.2
        //     }
        // },

        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              pixelRatio: 4,
            },
          },
        },
        title: [
          {
            text: title ? title : 'Highest Impact Drivers',
            x: 'center',
            top: 10,
            textStyle: {
              fontSize: 16,
            },
          },
        ],

        tooltip: {
          trigger: 'item',
          formatter: (params: any) => {
            // Format the tooltip to have the following:
            // 1. The name of the population
            // 2. The percentage of employees mentioning the population
            // 3. The percentage of mentions favorable

            const formattedPoint: SentimentShapDataPoint =
              data[params.dataIndex];

            let summaryText = ""
            const summaryTopicData = (summaryData ?? []).find(d => d.topic === formattedPoint.topic.toLowerCase())
            if(summaryTopicData) {
              summaryText = `
              <div>
                  <br/>
                  <div><b>Values in component analyses</b></div>
                  ${
                    Object.keys(summaryTopicData).map(k => {
                      if(k !== "topic" && k !== "prioritize") {
                        const result = `<div>${(summaryTopicData[k] as any).name}: <b>${(summaryTopicData[k] as any).value?.toFixed(1)}</b></div>`
                        if(k === currentAnalysis?.id) {
                          return `<b>${result}</b>`
                        }
                        return result
                      }
                      return null
                    }).filter(a => a !== null).join("")
                  }
              </div>`
            }
            // Return as a HTML box with the data inside
            return `
                        <div>
                            <div><b>${
                              formattedPoint.topicUserFriendlyName
                            }</b></div>
                            <div>Sentiment: <b>${Math.floor(
                              100 * formattedPoint.sentiment
                            )}%</b></div>
                            <div>Importance in outcome: <b>${(
                              100 * formattedPoint.combinedPercentTotalShap
                            ).toFixed(2)}%</b></div>
                            <div>Mentions: <b>${formattedPoint.vopicCount}</b></div>
                        </div>
                    ` + summaryText;
          },
          borderColor: '#ffffff',
          padding: 4,
          extraCssText: `
                    box-shadow: 0 0 3px rgba(0, 0, 0, 0.10);
                    border-radius: 2px;
                `,
        },
        interactive: true,
        animationEasing: 'elasticOut',
        animationDelay: 50,
      };

      // Get the chart element
      chart.setOption(option);

      // Add an onClick event to the chart...
      chart.on('click', (params: any) => {
        if (params.componentType === 'series') {
          const selectedPoint = params.value;

          /*const formattedSelectedPoint: SentimentShapDataPoint = {
            sentiment: selectedPoint[1],
            combinedShap: selectedPoint[0],
            topic: selectedPoint[2],
            topicUserFriendlyName: selectedPoint[3],
            negativeIncidence: selectedPoint[4],
            positiveIncidence: selectedPoint[5],
            combinedPercentTotalShap: selectedPoint[6],
          };*/

          addExcludeKeepGraphic(chart, selectedPoint);

          // TODO: - Convert the data into a point object that is more useful

          // onTopicSelected && onTopicSelected(formattedSelectedPoint.topic);
          setFocalTopic(selectedPoint[2]);
        }
      });

      chart.getZr().on('click', (event: any) => {
        if (!event.target && event.componentType !== 'graphic') {
          // Call internal method to update the chart such that there are no selected points
          chart.dispatchAction({
            type: 'unselect',
            seriesIndex: 0,
            dataIndex: Array.from(
              { length: benchmarkData.length },
              (v, i) => i
            ),
          });

          // Remove any graphic from the chart
          removeExcludeKeepGraphic(chart);
        }
      });

      if (dataIsLoading === true) {
        chart.showLoading('default', {
          text: 'Loading...',
          fontSize: 16,
          color: '#4589df',
          textColor: '#000',
          zlevel: 0,
        });
      } else {
        chart.hideLoading();
      }
    }

    setChartInstance(chart);

    return () => {
      setChartInstance(undefined);
      chart.dispose();
    };
  }, [data, dataIsLoading, focalTopic, theme.colorScheme, xAxisTitle, yAxisTitle]);

  if (dataIsLoading === false && isBenchmarkError && benchmarkError) {
    return (
      <Center style={{ height: '100%' }}>
        <APIErrorMessage response={benchmarkError} />
      </Center>
    );
  } else if (
    dataIsLoading === false &&
    isDriversDataError &&
    driversDataError
  ) {
    return (
      <Center style={{ height: '100%' }}>
        <APIErrorMessage response={driversDataError} />
      </Center>
    );
  }

  return <div ref={referenceComponent} id="myChart" style={{ width: '100%', height: '100%' }}></div>;
};

export default DriversOverviewPlot;
