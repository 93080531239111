import { ActionIcon, Card, Flex, Group, Stack, Text } from '@mantine/core';
import { FC, useEffect, useMemo, useState } from 'react';
import ExplorationFilters from '@components/AnalysisEdition/ExplorationFilters';
import ExplorationTable from './ExplorationTable';
import ExplorationDroppableArea from './ExplorationDroppableArea';
import { useAnalysisEditionStore } from '@stores/AnalysisEditionStore';

const ExplorationColumn: FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const distributionData = useAnalysisEditionStore((s) => s.distributionData);
  const maxNumberOfResponses = useMemo(
    () => Math.max(...[...(distributionData ?? []).map((d) => d.responses), 0]),
    [distributionData]
  );
  const [numberOfResponses, setNumberOfResponses] = useState<[number, number]>([
    0,
    maxNumberOfResponses,
  ]);

  useEffect(
    () => setNumberOfResponses([0, maxNumberOfResponses]),
    [maxNumberOfResponses]
  );

  return (
    <Stack style={{ flexGrow: 1 }}>
      <Text style={{ minHeight: 50 }}>
        2. Explore values distribution, select and drag items to the population
        list to create a new populations.
        <br />
        You can combine multiple values in a single population or just create
        one population per value.
      </Text>
      <ExplorationFilters
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        numberOfResponses={numberOfResponses}
        setNumberOfResponses={setNumberOfResponses}
        maxNumberOfResponses={maxNumberOfResponses}
      />
      <Stack style={{ position: 'relative', flexGrow: 1 }}>
        <ExplorationTable
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          numberOfResponses={numberOfResponses}
          setNumberOfResponses={setNumberOfResponses}
          maxNumberOfResponses={maxNumberOfResponses}
        />
        <ExplorationDroppableArea />
      </Stack>
    </Stack>
  );
};

export default ExplorationColumn;
