import React, { useEffect, useRef, useState } from 'react';
import {
  ActionIcon,
  CSSObject,
  Flex,
  Input,
  MantineProvider,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { IconEdit, IconSquareArrowRight } from '@tabler/icons-react';

interface InlineEditableTextProps {
  value: string;
  onChange: (newValue: string) => void;
  textStyle?: CSSObject;
  inputStyle?: CSSObject;
  inputRootStyle?: CSSObject;
  actionIconStyle?: CSSObject;
  unallowedValues?: string[];
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  tooltipIcon?: string;
  loading?: boolean;
  editWithDoubleClick?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const calculateWidth = (text: string, style: CSSObject) => {
  const element = document.createElement('span');
  element.innerHTML = text;
  Object.assign(element.style, style);
  document.body.appendChild(element);
  const width = element.offsetWidth;
  document.body.removeChild(element);
  return width;
};

export const InlineEditableText: React.FC<InlineEditableTextProps> = ({
  value,
  size,
  textStyle,
  inputStyle,
  inputRootStyle,
  actionIconStyle,
  unallowedValues,
  onChange,
  tooltipIcon,
  loading,
  onClick,
  editWithDoubleClick = true,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => setTempValue(value), [value]);

  const inputWidth = calculateWidth(tempValue, textStyle || {});
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setIsEditing(false);
      if (
        unallowedValues !== undefined &&
        unallowedValues.includes(tempValue)
      ) {
        setTempValue(value); // revert back to the original value
      } else {
        onChange(tempValue);
      }
    } else if (event.key === 'Escape') {
      setIsEditing(false);
      setTempValue(value); // revert back to the original value
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    onChange(tempValue);
  };

  // focus the input when editing starts
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      // Select the text in the input
      inputRef.current.setSelectionRange(0, inputRef.current.value.length);
    }
  }, [isEditing]);

  return (
    <MantineProvider
      theme={{
        components: {
          Text: {
            styles: {
              root: {
                cursor: 'pointer',
                border: '1px solid transparent',
                ...textStyle,
              },
            },
          },
          TextInput: {
            styles: {
              root: {
                width: inputWidth + 40,
                border: '1px solid #ccc',
                borderRadius: 4,
                ...inputRootStyle,
              },
              input: {
                padding: 0,
                paddingRight: 25,
                height: 'auto',
                ...textStyle,
                ...inputStyle,
              },
            },
          },
          ActionIcon: {
            styles: {
              root: actionIconStyle,
            },
          },
        },
      }}
    >
      {isEditing && loading !== true ? (
        <TextInput
          variant="unstyled"
          size={size || 'md'}
          ref={inputRef}
          value={tempValue}
          onBlur={handleBlur}
          onChange={(event) => setTempValue(event.currentTarget.value)}
          onKeyDown={handleKeyDown}
          autoFocus
          rightSection={
            <ActionIcon size="sm" ml="xs" onClick={() => setIsEditing(false)}>
              <IconSquareArrowRight />
            </ActionIcon>
          }
        />
      ) : (
        <Flex align="center" style={{ width: '-webkit-fill-available' }}>
          <Tooltip
            label="Double click to edit"
            openDelay={200}
            withinPortal
            disabled={loading || !editWithDoubleClick}
          >
            <Text
              truncate={true}
              onClick={onClick}
              onDoubleClick={
                editWithDoubleClick
                  ? () => setIsEditing(loading ? false : true)
                  : undefined
              }
              size={size || 'md'}
            >
              {value}
            </Text>
          </Tooltip>
          <Tooltip
            label={tooltipIcon}
            disabled={!tooltipIcon}
            openDelay={200}
            withinPortal
          >
            <ActionIcon
              size={size || 'sm'}
              ml="xs"
              loading={loading}
              onClick={() => setIsEditing(true)}
            >
              <IconEdit />
            </ActionIcon>
          </Tooltip>
        </Flex>
      )}
    </MantineProvider>
  );
};
