import CRN from './Crn';
import APIDecodable from './APIDecodable';

class QuoteModel implements APIDecodable {
  datasetCRN: CRN;
  rowId: number;
  textId: number;
  sentenceNumber: number;
  sentence: string;
  textHeader: string;
  textInstance: string;
  textInstanceSentiment: string;
  vopic: string;
  isValue: boolean;
  isFlagged: boolean;
  demographics: {
    [key: string]: string | number | boolean | undefined | Date;
  } = {};

  constructor(
    datasetCRN: CRN,
    rowId: number,
    textId: number,
    sentenceNumber: number,
    sentence: string,
    textHeader: string,
    textInstance: string,
    textInstanceSentiment: string,
    vopic: string,
    isValue: boolean,
    isFlagged: boolean,
    demographics: {
      [key: string]: string | number | boolean | undefined | Date;
    }
  ) {
    this.datasetCRN = datasetCRN;
    this.rowId = rowId;
    this.textId = textId;
    this.sentenceNumber = sentenceNumber;
    this.sentence = sentence;
    this.textHeader = textHeader;
    this.textInstance = textInstance;
    this.textInstanceSentiment = textInstanceSentiment;
    this.vopic = vopic;
    this.isValue = isValue;
    this.isFlagged = isFlagged;
    this.demographics = demographics;
  }

  static fromAPIResponse(apiResponse: any): QuoteModel {
    const datasetCRN = CRN.fromString(apiResponse.Crn);
    const rowId = apiResponse.RowId;
    const textId = apiResponse.TextId;
    const sentenceNumber = apiResponse.SentenceNumber;
    const sentence = apiResponse.Sentence;
    const textHeader = apiResponse.TextHeader;
    const textInstance = apiResponse.TextInstance;
    const textInstanceSentiment = apiResponse.TextInstanceSentiment;
    const vopic = apiResponse.Vopic;
    const isValue = apiResponse.IsValue;

    // Get IsFlagged from response. if it is not present, set it to false.
    const isFlagged = apiResponse.IsFlagged ? apiResponse.IsFlagged : false;

    const demographics = apiResponse.Demographics
      ? apiResponse.Demographics
      : {};

    return new QuoteModel(
      datasetCRN,
      rowId,
      textId,
      sentenceNumber,
      sentence,
      textHeader,
      textInstance,
      textInstanceSentiment,
      vopic,
      isValue,
      isFlagged,
      demographics
    );
  }
}

// Class with extra properties for co-mention quotes
// Specifically: the focal topic and focal sentence
export class QuoteCoMentionModel extends QuoteModel {
  focalTopic: string;
  focalSentence: string;
  focalSentenceNumber: number;

  constructor(
    datasetCRN: CRN,
    rowId: number,
    textId: number,
    sentenceNumber: number,
    sentence: string,
    textHeader: string,
    textInstance: string,
    textInstanceSentiment: string,
    vopic: string,
    isValue: boolean,
    isFlagged: boolean,
    demographics: {
      [key: string]: string | number | boolean | undefined | Date;
    },
    focalTopic: string,
    focalSentence: string,
    focalSentenceNumber: number
  ) {
    super(
      datasetCRN,
      rowId,
      textId,
      sentenceNumber,
      sentence,
      textHeader,
      textInstance,
      textInstanceSentiment,
      vopic,
      isValue,
      isFlagged,
      demographics
    );
    this.focalTopic = focalTopic;
    this.focalSentence = focalSentence;
    this.focalSentenceNumber = focalSentenceNumber;
  }

  static fromAPIResponse(apiResponse: any): QuoteCoMentionModel {
    const datasetCRN = CRN.fromString(apiResponse.Crn);
    const rowId = apiResponse.RowId;
    const textId = apiResponse.TextId;
    const sentenceNumber = apiResponse.SentenceNumber;
    const sentence = apiResponse.Sentence;
    const textHeader = apiResponse.TextHeader;
    const textInstance = apiResponse.TextInstance;
    const textInstanceSentiment = apiResponse.TextInstanceSentiment;
    const vopic = apiResponse.Vopic;
    const isValue = apiResponse.IsValue;

    // Get IsFlagged from response. if it is not present, set it to false.
    const isFlagged = apiResponse.IsFlagged ? apiResponse.IsFlagged : false;

    const demographics = apiResponse.Demographics
      ? apiResponse.Demographics
      : {};

    const focalTopic = apiResponse.FocalVopic;
    const focalSentence = apiResponse.FocalVopicSentence;
    const focalSentenceNumber = apiResponse.FocalVopicSentenceNumber;

    return new QuoteCoMentionModel(
      datasetCRN,
      rowId,
      textId,
      sentenceNumber,
      sentence,
      textHeader,
      textInstance,
      textInstanceSentiment,
      vopic,
      isValue,
      isFlagged,
      demographics,
      focalTopic,
      focalSentence,
      focalSentenceNumber
    );
  }
}

export default QuoteModel;
