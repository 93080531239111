import React, { forwardRef, useMemo, useState } from 'react';
import { Box, Center, Group, Select, Stack, Text } from '@mantine/core';

import { IconAbc, Icon123, IconHash, IconCalendar } from '@tabler/icons-react';

interface MantineSelectFieldProps {
  items: any[];
  selectedKey: any;
  setField: (value: string) => void;
  allowCustomValues: boolean;
  readonly: boolean;
  placeholder: string;
  customProps: any;
  operator?: boolean;
  config: any;
}

export enum SimpleDataType {
  STRING = 'string',
  INTEGER = 'integer',
  DECIMAL = 'decimal',
  DATE = 'date',
  BOOLEAN = 'boolean',
  UNKNOWN = 'unknown',
}

export interface FieldSelectItemProps
  extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  value: string;
  group: string;
  dataType: SimpleDataType;
  nUniqueValues?: number;
}

export const FieldSelect = forwardRef<HTMLDivElement, FieldSelectItemProps>(
  (
    { value, label, dataType, nUniqueValues, ...others }: FieldSelectItemProps,
    ref
  ) => {
    // Select the correct icon based on the data type...
    let icon = <IconAbc size={18} />;
    if (dataType === SimpleDataType.INTEGER) {
      icon = <Icon123 size={18} />;
    } else if (dataType === SimpleDataType.DECIMAL) {
      icon = <IconHash size={18} />;
    } else if (dataType === SimpleDataType.DATE) {
      icon = <IconCalendar size={18} />;
    } else if (dataType === SimpleDataType.BOOLEAN) {
      icon = <IconHash size={18} />;
    }

    return (
      <div ref={ref} {...others}>
        <Group position="apart">
          <Group noWrap spacing={1}>
            {icon}
            <Box ml="xs">{label}</Box>
          </Group>
          <Group position="right">
            <Center>
              <Text size={8}>
                {nUniqueValues ? `${nUniqueValues.toLocaleString()}` : ''}
              </Text>
            </Center>
          </Group>
        </Group>
      </div>
    );
  }
);

export const getUsableDataType = (dataType: string) => {
  let usableDataType = SimpleDataType.UNKNOWN;

  switch (dataType) {
    case 'IntegerType':
      usableDataType = SimpleDataType.INTEGER;
      break;
    case 'LongType':
      usableDataType = SimpleDataType.INTEGER;
      break;
    case 'ShortType':
      usableDataType = SimpleDataType.INTEGER;
      break;
    case 'DoubleType':
      usableDataType = SimpleDataType.DECIMAL;
      break;
    case 'FloatType':
      usableDataType = SimpleDataType.DECIMAL;
      break;
    case 'DecimalType':
      usableDataType = SimpleDataType.DECIMAL;
      break;
    case 'StringType':
      usableDataType = SimpleDataType.STRING;
      break;
    case 'DateType':
      usableDataType = SimpleDataType.DATE;
      break;
    case 'TimestampType':
      usableDataType = SimpleDataType.DATE;
      break;
    case 'DatetimeType':
      usableDataType = SimpleDataType.DATE;
      break;
    case 'BooleanType':
      usableDataType = SimpleDataType.BOOLEAN;
      break;
    default:
      usableDataType = SimpleDataType.UNKNOWN;
      break;
  }

  return usableDataType;
};

export const MantineFieldSelect: React.FC<MantineSelectFieldProps> = (
  props: MantineSelectFieldProps
) => {
  const selectItems = useMemo(() => {
    return props.items
      .map((item: any) => {
        if (item.key.startsWith('@')) {
          return null;
        } else {
          return item;
        }
      })
      .filter((item: any) => item !== null);
  }, [props.items]);

  const metaInformation = useMemo(() => {
    const metaMapping = {};
    if (props.config.fields === undefined) {
      return metaMapping;
    }

    Object.entries(props.config.fields).forEach(([key, value]) => {
      const colName = key;
      const dataType = value['dataType'];
      const nUniqueValues = value['numberOfUniqueValues'];

      metaMapping[colName] = {
        dataType: dataType,
        nUniqueValues: nUniqueValues,
      };
    });

    return metaMapping;
  }, [props.config.fields]);

  const aliases = useMemo(() => {
    const aliasMapping = {};

    props.items
      .filter((item: any) => item.key.startsWith('@'))
      .forEach((item: any) => {
        const key = item.key.substring(1);
        const value = item.label;

        if (!value.startsWith('@')) {
          aliasMapping[key] = value;
        }
      });

    return aliasMapping;
  }, [props.items]);

  const renderOptions = () => {
    if (props.operator) {
      // This is an operator (like =, <, >, etc.)
      return selectItems.map((item: any) => ({
        value: item.key,
        label: item.label,
        disabled: item.disabled,
        group: '',
      }));
    } else {
      // This is a normal select
      return selectItems.map((item: any) => {
        const columnName = item.key.split('@').pop();

        const alias = aliases[item.key] ? aliases[item.key] : columnName;

        const metaKey = `@${item.key}`;
        const dataType = metaInformation[metaKey]
          ? metaInformation[metaKey]['dataType']
          : 'unknown';
        const nUniqueValues = metaInformation[metaKey]
          ? metaInformation[metaKey]['nUniqueValues']
          : 0;

        const usableDataType = getUsableDataType(dataType);

        return {
          value: item.key, // Changed to have dataset alias + value.
          label: alias, // Use the alias (or the column name if no alias is set)
          group: item.label,
          dataType: usableDataType,
          nUniqueValues: nUniqueValues,
          disabled: item.disabled,
        };
      });
    }
  };

  const onChange = (value: string) => {
    if (value === undefined) {
      return;
    }
    props.setField(value);
  };

  return props.operator ? (
    <div style={{ width: '100px' }}>
      <Select
        size="xs"
        hoverOnSearchChange
        value={props.selectedKey ? props.selectedKey : ''}
        placeholder={!props.readonly ? props.placeholder : ''}
        onChange={onChange}
        data={renderOptions()}
        searchable
      />
    </div>
  ) : (
    <Box>
      <Select
        size="xs"
        hoverOnSearchChange
        value={props.selectedKey ? props.selectedKey : ''}
        placeholder={!props.readonly ? props.placeholder : ''}
        onChange={onChange}
        data={renderOptions()}
        searchable
        itemComponent={FieldSelect}
        sx={{
          '.mantine-Select-dropdown': {
            width: '400px !important',
            // left: '0 !important',
            marginLeft: 50,
          },
        }}
        description={
          props.selectedKey ? props.selectedKey.split('@')[0] : undefined
        }
        inputWrapperOrder={['label', 'input', 'description', 'error']}
      />
    </Box>
  );
};
