import React from 'react';
import { Group, Box, Skeleton, Checkbox } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons';
import { useCurrentProject } from '@hooks/useCurrentProject';
import Link from '@components/Link';
import { SummaryTitle } from './SummaryTitle';
import { useSummaryStore } from '@stores/SummaryStore';
import { TopicsFilterButton } from './TopicsFilterButton';
import { useAppStore } from '@stores/AppStore';
import { TopicPanelSwitch } from './TopicPanelSwitch';
import { CoMentionTypeFilter } from './AnalysisDetailToolbar/CoMentionTypeFilter';
import { NumberOfMentionsFilter } from './AnalysisDetailToolbar/NumberOfMentionsFilter';

export const SummaryToolbar: React.FC = () => {
  const currentProject = useCurrentProject();
  const showSummaryValues = useAppStore(s => s.showSummaryValues)
  const setShowSummaryValues = useAppStore(s => s.setSummaryShowValues)

  return (
    <Box
      pb={10}
      sx={(theme) => {
        return { 
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
        };
      }}
    >
      <Group position="apart" h="100%">
        <Group spacing="xs">
          <Link
            to={`/projects/${currentProject.projectId}`}
            style={{ textDecoration: 'none', display: 'flex' }}
          >
            <IconArrowLeft size={18} color="black" />
          </Link>
          <SummaryTitle />
        </Group>
        <Group spacing="md">
          <NumberOfMentionsFilter />
          <Checkbox size="xs" styles={{ input: { cursor: 'pointer' }, label: { cursor: 'pointer', paddingLeft: 5 } }} label="Show values" checked={showSummaryValues} onChange={(e) => setShowSummaryValues(e.currentTarget.checked)} />
          <TopicsFilterButton store={useSummaryStore} />
          <TopicPanelSwitch />
        </Group>
      </Group>
    </Box>
  );
};

export const SummaryToolbarSkeleton: React.FC = () => {
  return (
    <>
      <Box
        pb={10}
        sx={(theme) => {
          return {
            borderBottom: `1px solid ${theme.colors.gray[2]}`,
          };
        }}
      >
        <Group position="apart" h="100%">
          <Group spacing="xs">
            <Link
              to={`/projects/`}
              style={{ textDecoration: 'none', display: 'flex' }}
            >
              <IconArrowLeft size={18} color="black" />
            </Link>
            <Skeleton height={30} width={150} animate />
          </Group>
          <Group spacing="xs">
            <Group>

            </Group>
          </Group>
        </Group>
      </Box>
    </>
  );
};
