import { ProjectMetricType } from '@apis/ProjectAPI';
import { BenchmarkDataPoint } from '@components/charts/datamodels/BenchmarkDataPoint';
import { ShapDriversDataPoint } from '@components/charts/datamodels/ShapDriversDataPoint';
import QuoteModel, { QuoteCoMentionModel } from '@models/QuoteModel';
import { create } from 'zustand';

export const FOCAL_POPULATIONS_RESERVED_KEYWORD = '<FOCAL_POPULATIONS>';

export enum ChartType {
  OVERVIEW_OF_IMPORTANCE = 'OVERVIEW_OF_IMPORTANCE',
  DRIVES_OUTCOME = 'DRIVES_OUTCOME',
  POPULATION_AGAINST_BENCHMARK = 'POPULATION_AGAINST_BENCHMARK',
  BREAKDOWN_BY_TOPIC = 'BREAKDOWN_BY_TOPIC',
  BREAKDOWN_BY_POPULATION = 'BREAKDOWN_BY_POPULATION',
  IN_SAME_BREATH = 'IN_SAME_BREATH',
  SUB_CULTURES = 'SUB_CULTURES',
  RAW_VOICE = 'RAW_VOICE',
  PROGRESS_OVER_TIME = "PROGRESS_OVER_TIME",
}

export type ModelAggregation = 'Topic' | 'Theme';
export type Quote = QuoteModel | QuoteCoMentionModel;

interface AnalysisStore {
  selectedChart: ChartType;
  setSelectedChart: (value: ChartType) => void;

  allPopulations: string[];
  setAllPopulations: (value: string[]) => void;

  // MARK: - Filters...
  focalPopulation: string | undefined;
  setFocalPopulation: (value: string | undefined) => void;

  numberOfMentions: number;
  setNumberOfMentions: (value: number) => void;

  activeTopics: string[];
  setActiveTopics: (value: string[]) => void;

  activeThemes: string[];
  setActiveThemes: (value: string[]) => void;

  onlyUseThemeAssociatedTopics: boolean;
  setOnlyUseThemeAssociatedTopics: (value: boolean) => void;

  activeModelAggregation: ModelAggregation;
  setActiveModelAggregation: (value: ModelAggregation) => void;

  // MARK: - Data Fetching and storage...
  negativeCoMentionData: BenchmarkDataPoint[];
  negativeCoMentionDataLoading: boolean;
  setNegativeCoMentionData: (value: BenchmarkDataPoint[]) => void;

  positiveCoMentionData: BenchmarkDataPoint[];
  positiveCoMentionDataLoading: boolean;
  setPositiveCoMentionData: (value: BenchmarkDataPoint[]) => void;

  notMentionedData: BenchmarkDataPoint[];
  notMentionedDataLoading: boolean;
  setNotMentionedData: (value: BenchmarkDataPoint[]) => void;

  isLoadingData: boolean;
  setIsDataLoading: (value: boolean) => void;
  data: BenchmarkDataPoint[];
  dataError: Error | undefined;
  setDataError: (value: Error | undefined) => void;
  dataIsError: boolean;
  setDataIsError: (value: boolean) => void;
  setBenchmarkData: (value: BenchmarkDataPoint[]) => void;

  segmentsData: BenchmarkDataPoint[],
  setSegmentsData: (value: BenchmarkDataPoint[]) => void;

  driversData: ShapDriversDataPoint[];
  driversDataLoading: boolean;
  driversDataError: Error | undefined;
  setDriversDataError: (value: Error | undefined) => void;
  driversDataIsError: boolean;
  setDriversDataIsError: (value: boolean) => void;
  setDriversDataLoading: (value: boolean) => void;
  setDriversData: (value: ShapDriversDataPoint[]) => void;
}

export const useAnalysisStore = create<AnalysisStore>()((set, get) => ({
  selectedChart: ChartType.POPULATION_AGAINST_BENCHMARK,
  setSelectedChart: (value) => set({ selectedChart: value }),

  allPopulations: [],
  setAllPopulations: (value) => set({ allPopulations: value }),

  // MARK: - Filters
  focalPopulation: undefined,
  setFocalPopulation: (value) => set({ focalPopulation: value }),

  numberOfMentions: 5,
  setNumberOfMentions: (value) => set({ numberOfMentions: value }),

  activeTopics: [],
  setActiveTopics: (value) => set({ activeTopics: value }),

  activeThemes: [],
  setActiveThemes: (value) => set({ activeThemes: value }),

  onlyUseThemeAssociatedTopics: false,
  setOnlyUseThemeAssociatedTopics: (value) =>
    set({ onlyUseThemeAssociatedTopics: value }),


  activeModelAggregation: 'Topic',
  setActiveModelAggregation: (value) => set({ activeModelAggregation: value }),

  // MARK: - Data Fetching and storage...
  negativeCoMentionData: [],
  negativeCoMentionDataLoading: false,
  setNegativeCoMentionData: (value) => set({ negativeCoMentionData: value }),

  positiveCoMentionData: [],
  positiveCoMentionDataLoading: false,
  setPositiveCoMentionData: (value) => set({ positiveCoMentionData: value }),

  notMentionedData: [],
  notMentionedDataLoading: false,
  setNotMentionedData: (value) => set({ notMentionedData: value }),

  segmentsData: [],
  setSegmentsData: (value) => set({ segmentsData: value }),

  isLoadingData: false,
  setIsDataLoading: (value) => set({ isLoadingData: value }),
  data: [],
  dataError: undefined,
  setDataError: (value) => set({ dataError: value }),
  dataIsError: false,
  setDataIsError: (value) => set({ dataIsError: value }),
  setBenchmarkData: (value) => set({ data: value }),

  driversData: [],
  setDriversData: (value) => set({ driversData: value }),

  driversDataLoading: false,
  setDriversDataLoading: (value) => set({ driversDataLoading: value }),

  driversDataError: undefined,
  setDriversDataError: (value) => set({ driversDataError: value }),

  driversDataIsError: false,
  setDriversDataIsError: (value) => set({ driversDataIsError: value }),
}));
