import CRN from './Crn';

enum CXMetricType {
  Vopic = 'Vopic',
  Topic = 'Topic',
  Value = 'Value',
}

interface QuoteReaderFilterObject {
  demographics: { [key: string]: (string | boolean | number)[] };
  vopics: string[];
  metric: CXMetricType;
  sentiment: string[];
  requestedDemographics?: { [key: string]: string };
}

export interface QuoteReaderProjectFilterObject {
  benchmark?: string;
  population?: string;
  topics?: string[];
}

enum ConnectionType {
  DemographicValue = 'DemographicValue',
  Topic = 'Topic',
  Demographic = 'Demographic',
  Sentiment = 'Sentiment',
  Value = 'Value',
  Vopic = 'Vopic',
}

enum UsableValue {
  RawValue = 'RawValue',
  FormattedValue = 'FormattedValue',
}

enum FilterMarkMappingDataType {
  String = 'String',
  Number = 'Number',
  Date = 'Date',
}

class FilterMarkMapping {
  connectionType: ConnectionType;
  associatedDemographic?: string;
  usableValue: UsableValue;
  dataType: FilterMarkMappingDataType;

  constructor(
    connectionType: ConnectionType,
    usableValue: UsableValue,
    dataType: FilterMarkMappingDataType,
    associatedDemographic?: string
  ) {
    this.connectionType = connectionType;
    this.associatedDemographic = associatedDemographic;
    this.usableValue = usableValue;
    this.dataType = dataType;
  }

  static fromAPIResponse(response: any): FilterMarkMapping {
    return new FilterMarkMapping(
      response.ConnectionType,
      response.UsableValue,
      response.DataType,
      response.AssociatedDemographic
    );
  }
}

class InitialFiltersMapping {
  sheetName: string;
  demographics: { [key: string]: (string | boolean | number)[] } = {};
  vopics: string[];
  metric: CXMetricType;
  sentiment: string[];
  requestedDemographics: string[];

  constructor(
    sheetName: string,
    demographics: { [key: string]: (string | boolean | number)[] },
    vopics: string[],
    metric: CXMetricType,
    sentiment: string[],
    requestedDemographics: string[]
  ) {
    this.sheetName = sheetName;
    this.demographics = demographics;
    this.vopics = vopics;
    this.metric = metric;
    this.sentiment = sentiment;
    this.requestedDemographics = requestedDemographics;
  }

  static fromAPIResponse(response: any): InitialFiltersMapping {
    return new InitialFiltersMapping(
      response.SheetName,
      response.Demographics,
      response.Vopics,
      response.Metric,
      response.Sentiment,
      response.RequestedDemographics
    );
  }

  get filterObject(): QuoteReaderFilterObject {
    return {
      demographics: this.demographics,
      vopics: this.vopics,
      metric: this.metric,
      sentiment: this.sentiment,
    };
  }
}

class ViewFilterMappingModel {
  version: string;

  // Filters that are ALWAYS applied in addition to what the user sets.
  underlyingFilters: { [key: string]: QuoteReaderFilterObject };

  // The default set of filters when the view is loaded
  initialFilters: InitialFiltersMapping;

  // The demographic fields to retrieve from the data source when the user makes the request to query quotes...
  demographicRetrieval: { [key: string]: { [key: string]: string } };

  // The mapping between filter name, value and the underlying name and value in the database
  filterMappings: { [key: string]: { [key: string]: FilterMarkMapping } };

  // The mapping between mark names and values, and the underlying name and value in the database
  markMappings: { [key: string]: { [key: string]: FilterMarkMapping } };

  // Which quote datasets to use based on the worksheet
  quoteDatasets: { [key: string]: CRN };

  constructor(
    version: string,
    underlyingFilters: { [key: string]: QuoteReaderFilterObject },
    initialFilters: InitialFiltersMapping,
    demographicRetrieval: { [key: string]: { [key: string]: string } },
    filterMappings: { [key: string]: { [key: string]: FilterMarkMapping } },
    markMappings: { [key: string]: { [key: string]: FilterMarkMapping } },
    quoteDatasets: { [key: string]: CRN }
  ) {
    this.version = version;
    this.underlyingFilters = underlyingFilters;
    this.initialFilters = initialFilters;
    this.demographicRetrieval = demographicRetrieval;
    this.filterMappings = filterMappings;
    this.markMappings = markMappings;
    this.quoteDatasets = quoteDatasets;
  }

  static fromAPIResponse(apiResponse: any): ViewFilterMappingModel {
    const version = apiResponse.Version;

    const coreProperties = apiResponse.Properties;

    const underlyingFilters = coreProperties.UnderlyingFilters;

    const initialFilters = InitialFiltersMapping.fromAPIResponse(
      coreProperties.InitialFilters
    );
    const demographicRetrieval = coreProperties.DemographicRetrieval;

    var filterMappings: {
      [key: string]: { [key: string]: FilterMarkMapping };
    } = {};
    for (const [key, value] of Object.entries(coreProperties.FilterMappings)) {
      filterMappings[key] = {};
      for (const [key2, value2] of Object.entries(
        value as { [key: string]: any }
      )) {
        filterMappings[key][key2] = FilterMarkMapping.fromAPIResponse(value2);
      }
    }

    var markMappings: { [key: string]: { [key: string]: FilterMarkMapping } } =
      {};
    for (const [key, value] of Object.entries(coreProperties.MarkMappings)) {
      markMappings[key] = {};
      for (const [key2, value2] of Object.entries(
        value as { [key: string]: any }
      )) {
        markMappings[key][key2] = FilterMarkMapping.fromAPIResponse(value2);
      }
    }

    var quoteDatasets: { [key: string]: CRN } = {};
    for (const [key, value] of Object.entries(coreProperties.QuoteDatasets)) {
      quoteDatasets[key] = CRN.fromString(value as string);
    }

    return new ViewFilterMappingModel(
      version,
      underlyingFilters,
      initialFilters,
      demographicRetrieval,
      filterMappings,
      markMappings,
      quoteDatasets
    );
  }
}

export { ViewFilterMappingModel, ConnectionType, UsableValue, CXMetricType };
export default QuoteReaderFilterObject;
