import Fuse from 'fuse.js';
import { useMemo } from 'react';

export const useHighlights = (
  key: string,
  matches: readonly Fuse.FuseResultMatch[]
): string[] => {
  return useMemo(() => {
    const highlights = [];
    matches
      .filter((m) => m.key === key)
      .forEach((m) => {
        m.indices.forEach((i) => {
          highlights.push(m.value.substring(i[0], i[1] + 1));
        });
      });
    return highlights;
  }, [key, matches]);
};
