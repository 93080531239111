import React, { useEffect, useMemo, useState } from 'react';
import * as echarts from 'echarts';
import { AdjustedIncidenceDataPoint } from './datamodels/AdjustedIncidenceDataPoint';
import { Center, useMantineTheme } from '@mantine/core';
import darkTheme from './themes/dark-theme.json';
import { ProjectMetricType } from '@apis/ProjectAPI';
import chroma from 'chroma-js';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { useAnalysisStore } from '@stores/AnalysisStore';
import { userFriendlyTopicThemeName } from '@utils/TopicUtils';
import { useCoMentionData } from '@apis/hooks/useCoMentionData';
import APIErrorMessage from '@components/APIErrorMessage';
import { useCurrentAnalysis } from '@hooks/useCurrentAnalysis';
import { useQuoteStore } from '@stores/QuoteStore';
import { useTopicStore } from '@stores/TopicStore';
import { useCurrentSummary } from '@hooks/useCurrentSummary';
import { useSummaryData } from '@apis/hooks/useSummaryData';
import { useElementSize } from '@mantine/hooks';

echarts.registerTheme('dark', darkTheme);

function colorInterpolationRed(value: number, maxValue: number) {
  // Scale value to range between 0 and 1 based on maxValue
  // const scaledValue = value / maxValue;

  // // interpolate between yellow and red
  // const r = 255;
  // const g = 255 - Math.floor(255 * scaledValue);

  // return `rgb(${r},${g},0)`;

  const scaledValue = value / maxValue;
  const colorScale = chroma
    .scale(['yellow', 'red'])
    .mode('lch')
    .domain([0, maxValue]);
  return colorScale(scaledValue).hex();
}

function colorInterpolationGreen(value: number, maxValue: number): string {
  const scaledValue = value / maxValue;
  const colorScale = chroma
    .scale(['yellow', 'rgb(53, 162, 78)'])
    .mode('lch')
    .domain([0, maxValue]);
  return colorScale(scaledValue).hex();
}

/**
 * Shap Drivers Power Bar Plot is a simple bar plot designed to show the breakdown of drivers by feature, with a limit on the number of values shown. Will also include a tooltip for nX times more powerful than average driver.
 * @param props
 * @returns
 */
const CoMentionsBarPlot: React.FC = () => {
  const theme = useMantineTheme();

  const currentProject = useCurrentProject();
  const currentAnalysis = useCurrentAnalysis();

  const activeTopics = useAnalysisStore((s) => s.activeTopics);
  const activeThemes = useAnalysisStore((s) => s.activeThemes);
  const focalPopulation = useAnalysisStore((s) => s.focalPopulation);
  
  //Topic Store
  const focalTopic = useTopicStore((s) => s.focalTopic);

  //Quote Store
  const activeCoMentionType = useQuoteStore((s) => s.activeCoMentionType);
  const setCoMentionedTopic = useQuoteStore((s) => s.setCoMentionedTopic);

  const negativeComentionData = useAnalysisStore(
    (s) => s.negativeCoMentionData
  );
  const positiveComentionData = useAnalysisStore(
    (s) => s.positiveCoMentionData
  );
  const notMentionedData = useAnalysisStore((s) => s.notMentionedData);

  const setNegativeCoMentionData = useAnalysisStore(
    (s) => s.setNegativeCoMentionData
  );
  const setPositiveCoMentionData = useAnalysisStore(
    (s) => s.setPositiveCoMentionData
  );
  const setNotMentionedData = useAnalysisStore((s) => s.setNotMentionedData);

  const currentSummary = useCurrentSummary();
  const { data: _summaryData } = useSummaryData(currentSummary);
  const summaryData = useMemo(() => {
    const analisys = (currentSummary?.analyses ?? []).find(a => a.id === currentAnalysis.id);
    if(!currentSummary || !analisys || analisys.focalPopulation !== focalPopulation) {
      return null
    }
    return _summaryData;
  }, [currentAnalysis?.id, currentSummary, focalPopulation,  _summaryData])

  const [ chartInstance, setChartInstance ] = useState<echarts.EChartsType | undefined>();
  const { ref: referenceComponent, width, height } = useElementSize();
  useEffect(() => chartInstance?.resize(), [width, height])
  

  const {
    data: coMentionData,
    isLoading,
    error,
    isError,
  } = useCoMentionData(
    currentProject,
    currentAnalysis,
    focalPopulation,
    focalTopic
  );

  useEffect(() => {
    if (coMentionData) {
      const { negativeCoMentionData, positiveCoMentionData, notMentionedData } =
        coMentionData;

      setNegativeCoMentionData(negativeCoMentionData);
      setPositiveCoMentionData(positiveCoMentionData);
      setNotMentionedData(notMentionedData);
    }
  }, [coMentionData]);

  const title = `When ${userFriendlyTopicThemeName(focalTopic)} is mentioned ${
    activeCoMentionType === ProjectMetricType.NegativeCoMention
      ? 'negatively'
      : 'positively'
  } within ${focalPopulation}\nwhat else is mentioned in the same breath?`;

  var adjustedIncidenceData = useMemo(() => {
    // We are going to compute the negative adjusted incidence,
    // which is slightly more complicated than the normal negative incidence.
    // This helps us take into account generally negative topics when computing in order to find disproportionately negative topics of interest...

    if (!coMentionData) {
      return [];
    }

    const { negativeCoMentionData, positiveCoMentionData, notMentionedData } =
      coMentionData;

    const relevantData =
      activeCoMentionType === ProjectMetricType.NegativeCoMention
        ? negativeCoMentionData
        : positiveCoMentionData;

    var adjustedNegativeIncidenceDataPoints: AdjustedIncidenceDataPoint[] =
      relevantData.map((d) => {
        // Find the not mentioned data point that matches the current negative comention data point...
        const notMentionedDataPoint = notMentionedData.find(
          (notMentionedDataPoint) => {
            return (
              notMentionedDataPoint.benchmarkName === d.benchmarkName &&
              notMentionedDataPoint.populationName === d.populationName &&
              notMentionedDataPoint.topic === d.topic
            );
          }
        );

        // If we can't find a not mentioned data point, then we can't compute the adjusted incidence, so we will return null...
        if (!notMentionedDataPoint) {
          return null;
        }

        return {
          benchmarkName: d.benchmarkName,
          populationName: d.populationName,
          topic: d.topic,
          isTheme: d.isTheme,
          topicUserFriendlyName: d.topicUserFriendlyName,
          rowCount: d.rowCount,
          vopicCount: d.vopicCount,
          adjustedNegativeIncidence:
            d.negativeIncidence - notMentionedDataPoint.negativeIncidence,
          adjustedPositiveIncidence:
            d.positiveIncidence - notMentionedDataPoint.positiveIncidence,
        };
      });

    // TODO: - Add positive variant..

    if (activeCoMentionType === ProjectMetricType.NegativeCoMention) {
      return adjustedNegativeIncidenceDataPoints
        .filter((d) => d !== null)
        .sort(
          (a: AdjustedIncidenceDataPoint, b: AdjustedIncidenceDataPoint) =>
            b.adjustedNegativeIncidence - a.adjustedNegativeIncidence
        ) as AdjustedIncidenceDataPoint[];
    } else {
      return adjustedNegativeIncidenceDataPoints
        .filter((d) => d !== null)
        .sort(
          (a: AdjustedIncidenceDataPoint, b: AdjustedIncidenceDataPoint) =>
            b.adjustedPositiveIncidence - a.adjustedPositiveIncidence
        ) as AdjustedIncidenceDataPoint[];
    }
  }, [coMentionData, activeCoMentionType]);

  const filteredAdjustedIncidenceData = useMemo(() => {
    // Return values where the topic is in the active topics or the theme is in the active themes...
    return adjustedIncidenceData.filter((d) => {
      return activeTopics.includes(d.topic) || activeThemes.includes(d.topic);
    });
  }, [adjustedIncidenceData, activeTopics, activeThemes]);

  const isEmptyData =
    negativeComentionData.length === 0 &&
    positiveComentionData.length === 0 &&
    notMentionedData.length === 0;

  const maxNegativeIncidence: number = useMemo(() => {
    return Math.max(
      ...filteredAdjustedIncidenceData.map((d) => d.adjustedNegativeIncidence)
    );
  }, [filteredAdjustedIncidenceData]);

  const maxPositiveIncidence: number = useMemo(() => {
    return Math.max(
      ...filteredAdjustedIncidenceData.map((d) => d.adjustedPositiveIncidence)
    );
  }, [filteredAdjustedIncidenceData]);

  const usableData: AdjustedIncidenceDataPoint[] = useMemo(() => {
    return filteredAdjustedIncidenceData.reverse();
  }, [filteredAdjustedIncidenceData]);

  useEffect(() => {
    const chartElement = document.getElementById('myChart');
    if (!chartElement) {
      return;
    }

    const chart = echarts.init(chartElement, theme.colorScheme);

    if (isLoading === true) {
      chart.showLoading('default', {
        text: 'Loading...',
        fontSize: 16,
        color: '#4589df',
        textColor: '#000',
        zlevel: 0,
      });
    } else if (isEmptyData && !isLoading) {
      chart.setOption({
        title: {
          text: 'No Data Available',
          subtext:
            'This may be due to a filter being applied, or because the data is not available yet',
          top: 'center',
          textStyle: {
            fontSize: 20,
          },
          subtextStyle: {
            fontSize: 16,
          },
        },
      });
    } else {
      const mentionType = activeCoMentionType;
      const dataToUse =
        mentionType === ProjectMetricType.PositiveCoMention
          ? usableData.map(
              (d: AdjustedIncidenceDataPoint) =>
                d.adjustedPositiveIncidence * 100
            )
          : usableData.map(
              (d: AdjustedIncidenceDataPoint) =>
                d.adjustedNegativeIncidence * 100
            );

      const option: any = {
        xAxis: {
          type: 'value',
          name:
            activeCoMentionType === ProjectMetricType.NegativeCoMention
              ? 'Adjusted Negative Incidence'
              : 'Adjusted Positive Incidence',
          nameTextStyle: {
            fontWeight: 'bold',
          },
          nameGap: 40,
          nameLocation: 'middle',
          axisLabel: {
            color: 'black',
            formatter: (value: number) => {
              return Math.floor(value) + '%';
            },
          },
        },
        yAxis: {
          type: 'category',
          data: usableData.map(
            (d: AdjustedIncidenceDataPoint) => d.topicUserFriendlyName
          ),
          nameTextStyle: {
            fontWeight: 'bold',
          },
          nameGap: 40,
          nameLocation: 'middle',
        },
        series: [
          {
            data: dataToUse.map((d: number) => {
              return {
                value: d,
                itemStyle: {
                  borderRadius: d > 0 ? [0, 5, 5, 0] : [5, 0, 0, 5],
                },
              };
            }),
            type: 'bar',
            itemStyle: {
              color: function (params: any) {
                if (
                  activeCoMentionType === ProjectMetricType.PositiveCoMention
                ) {
                  return colorInterpolationGreen(
                    params.data.value / 100,
                    maxPositiveIncidence
                  );
                } else {
                  return colorInterpolationRed(
                    params.data.value / 100,
                    maxNegativeIncidence
                  );
                }
              },
            },
            label: {
              show: true,
              position: 'right',
              formatter: (params: any) => {
                const formattedPoint: AdjustedIncidenceDataPoint = usableData[params.dataIndex as number];
                const dataPoint = mentionType === ProjectMetricType.PositiveCoMention ? formattedPoint.adjustedPositiveIncidence: formattedPoint.adjustedNegativeIncidence;
                return `${(100 * dataPoint).toFixed(1)}%`
              },
            },
          },
        ],

        tooltip: {
          trigger: 'item',
          formatter: (params: any) => {
            // Format the tooltip to have the following:
            // 1. The name of the population
            // 2. The percentage of employees mentioning the population
            // 3. The percentage of mentions favorable

            // Get the data
            const dataIndex: number = params.dataIndex;
            const formattedPoint: AdjustedIncidenceDataPoint =
              usableData[dataIndex];
            const dataPoint =
              mentionType === ProjectMetricType.PositiveCoMention
                ? formattedPoint.adjustedPositiveIncidence
                : formattedPoint.adjustedNegativeIncidence;

            let summaryText = ""
            const summaryTopicData = (summaryData ?? []).find(d => d.topic === formattedPoint.topic.toLowerCase())
            if(summaryTopicData) {
              summaryText = `
              <div>
                  <br/>
                  <div><b>Values in component analyses</b></div>
                  ${
                    Object.keys(summaryTopicData).map(k => {
                      if(k !== "topic" && k !== "prioritize") {
                        const result = `<div>${(summaryTopicData[k] as any).name}: <b>${(summaryTopicData[k] as any).value?.toFixed(1)}</b></div>`
                        if(k === currentAnalysis?.id) {
                          return `<b>${result}</b>`
                        }
                        return result
                      }
                      return null
                    }).filter(a => a !== null).join("")
                  }
              </div>`
            }
            // Return as a HTML box with the data inside
            return `
                        <div>  
                            <div><b>${
                              formattedPoint.topicUserFriendlyName
                            }</b></div>
                            <div>${
                              activeCoMentionType ===
                              ProjectMetricType.NegativeCoMention
                                ? `Adjusted Negative Incidence`
                                : `Adjusted Positive Incidence`
                            }: <b>${(100 * dataPoint).toFixed(1)}%</b></div> 
                            <div># Mentions: <b>${formattedPoint.vopicCount.toLocaleString()}</b></div>
                        </div>
                    ` + summaryText;
          },
        },

        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              pixelRatio: 4,
            },
          },
        },
        brush: {
          toolbox: ['rect', 'clear'],
          transformable: false,
          saveAsImage: {},
          inBrush: {
            opacity: 1,
            borderRadius: 3,
            borderColor: 'black',
          },
          outOfBrush: {
            color: 'gray',
            opacity: 0.2,
          },
        },

        interactive: true,
        animationEasing: 'elasticOut',
        animationDelay: 150,
        dataZoom: [
          {
            type: 'inside',
            id: 'insideY',
            yAxisIndex: 0, // This targets the yAxis for vertical scrolling
            filterMode: 'none',
            zoomLock: false,
            start: 100,
            end: 100 - (15 / adjustedIncidenceData.length) * 100,
            preventDefaultMouseMove: false,
            zoomOnMouseWheel: false,
            moveOnMouseMove: true,
            moveOnMouseWheel: true,
          },
        ],

        grid: {
          left: '15%',
        },

        title: [
          {
            text: title ? title : 'Adjusted Incidence',
            x: 'center',
            top: 10,
            textStyle: {
              fontSize: 16,
            },
          },
        ],
      };

      // Get the chart element
      chart.setOption(option);

      // Add an onClick event to the chart...
      chart.on('click', (params: any) => {
        if (params.componentType === 'series') {
          const x = params.event.offsetX;
          const y = params.event.offsetY;

          const selectedPoint = params.value;
          const dataIndex = params.dataIndex;

          const formattedSelectedPoint: AdjustedIncidenceDataPoint =
            usableData[dataIndex];

          setCoMentionedTopic(formattedSelectedPoint.topic);
        }
      });

      chart.getZr().on('click', (event: any) => {
        if (!event.target && event.componentType !== 'graphic') {
          // Call internal method to update the chart such that there are no selected points
          chart.dispatchAction({
            type: 'unselect',
            seriesIndex: 0,
            dataIndex: Array.from({ length: usableData.length }, (v, i) => i),
          });
        }
      });

      if (isLoading) {
        chart.showLoading('default', {
          text: 'Loading...',
          fontSize: 16,
          color: '#4589df',
          textColor: '#000',
          zlevel: 0,
        });
      } else {
        chart.hideLoading();
      }
    }

    setChartInstance(chart);

    return () => {
      setChartInstance(undefined);
      chart.dispose();
    };
  }, [
    usableData,
    theme.colorScheme,
    activeCoMentionType,
    isLoading,
    title,
    isEmptyData,
  ]);

  if (isLoading === false && isError && error) {
    return (
      <Center style={{ height: '100%' }}>
        <APIErrorMessage response={error} />
      </Center>
    );
  }
  return <div ref={referenceComponent} id="myChart" style={{ width: '100%', height: '100%' }}></div>;
};

export default CoMentionsBarPlot;
