import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Center,
  useMantineTheme,
  Text,
  Popover,
  Anchor,
  List,
  Tooltip,
} from '@mantine/core';
import * as echarts from 'echarts';
import {
  useAnalysisStore,
} from '@stores/AnalysisStore';
import {
  BenchmarkDataPoint,
  BenchmarkDataPointChartType,
} from './datamodels/BenchmarkDataPoint';
import darkTheme from './themes/dark-theme.json';
import APIErrorMessage from '@components/APIErrorMessage';
import { useCurrentAnalysis } from '@hooks/useCurrentAnalysis';
import { useTopicStore } from '@stores/TopicStore';
import { SumaryData, useSummaryData } from '@apis/hooks/useSummaryData';
import { useCurrentSummary } from '@hooks/useCurrentSummary';
import { useElementSize } from '@mantine/hooks';

echarts.registerTheme('dark', darkTheme);

const BAR_SIZE = 15
const getDataZoom = (dataLength: number, containerWidth: number): echarts.EChartOption.DataZoom => ({
  type: 'inside',
  id: 'insideX',
  xAxisIndex: [0, 1],
  filterMode: 'none',
  zoomLock: false,
  start: 0,
  end: Math.round(containerWidth * 100 / (dataLength * BAR_SIZE)),
  preventDefaultMouseMove: false,
  zoomOnMouseWheel: false,
  moveOnMouseMove: true,
  moveOnMouseWheel: true,
});

const RawVoiceChart: FC = () => {
  const theme = useMantineTheme();
  const rawData = useAnalysisStore((s) => s.data);
  const isLoadingData = useAnalysisStore((s) => s.isLoadingData);
  const activeTopics = useAnalysisStore((s) => s.activeTopics);
  const activeThemes = useAnalysisStore((s) => s.activeThemes);
  const allPopulations = useAnalysisStore((s) => s.allPopulations);
  const focalPopulation = useAnalysisStore((s) => s.focalPopulation);
  const numberOfMentions = useAnalysisStore((s) => s.numberOfMentions);
  const currentAnalysis = useCurrentAnalysis();
  const currentSummary = useCurrentSummary();
  const { data: _summaryData } = useSummaryData(currentSummary);
  const summaryData = useMemo(() => {
    const analisys = (currentSummary?.analyses ?? []).find(a => a.id === currentAnalysis.id);
    if (!currentSummary || !analisys || analisys.focalPopulation !== focalPopulation) {
      return null
    }
    return _summaryData;
  }, [currentAnalysis?.id, currentSummary, focalPopulation, _summaryData])


  const [chartInstance, setChartInstance] = useState<echarts.EChartsType | undefined>();
  const { ref: referenceComponent, width, height } = useElementSize();


  // ERRORS -
  const isBenchmarkError = useAnalysisStore((s) => s.dataIsError);
  const benchmarkError = useAnalysisStore((s) => s.dataError);

  const setFocalTopic = useTopicStore((s) => s.setFocalTopic);

  const data: BenchmarkDataPoint[] = useMemo(() => {
    return rawData
      .filter((dataPoint: BenchmarkDataPoint) => {
        return (
          (activeTopics.includes(dataPoint.topic) ||
            activeThemes.includes(dataPoint.topic)) &&
          dataPoint.vopicCount >= numberOfMentions &&
          allPopulations.includes(dataPoint.populationName)
        );
      })
      .filter(
        (point: BenchmarkDataPoint) => point.populationName === focalPopulation
      );
  }, [
    rawData,
    activeTopics,
    activeThemes,
    allPopulations,
    numberOfMentions,
    focalPopulation,
  ]);

  useEffect(() => {
    if (chartInstance) {
      chartInstance.resize();
      chartInstance.setOption({ dataZoom: [getDataZoom(data.length, width)] });
    }
  }, [data.length, width, height])

  const IsEmptyData = data.length === 0;
  const title = `Raw voice for ${focalPopulation}`;

  const addExcludeKeepGraphicToRange = (
    chart: any,
    selectedRange: any[],
    selectedPoints: BenchmarkDataPoint[]
  ) => {
    // Create a custom graphic element to show the box and buttons
    const [leftMostX, rightMostX] = selectedRange[0];
    const [leftMostY, rightMostY] = selectedRange[1];
    const width = rightMostY - leftMostX;
    var boxGroup = {
      type: 'group',
      x: rightMostX - 100, // Adjusted to center the box group horizontally
      y: leftMostY - 12,
      children: [
        {
          type: 'rect',
          left: '-50',
          top: '-15',
          z: 100000,
          shape: {
            width: 150,
            height: 25,
            r: 2,
          },
          style: {
            fill: '#fff',
            stroke: '#999',
            lineWidth: 1,
          },
        },
        {
          type: 'text',
          left: '-35',
          top: '-10',
          z: 100000,
          style: {
            text: 'Exclude',
            textAlign: 'center',
            textFill: '#333',
            fontSize: 14,
            cursor: 'pointer',
          },
          onclick: function () {
            // Add your code here to exclude the selected range
            const formattedSelectedRange = selectedPoints.map((point: any) => {
              return BenchmarkDataPoint.fromChartDataPoint(
                point,
                BenchmarkDataPointChartType.BenchmarkPopulationTopic
              );
            });
            // TODO add
            //props.onPointsExcluded && props.onPointsExcluded(formattedSelectedRange);
            removeExcludeKeepGraphic(chart);
          },
        },
        {
          type: 'text',
          left: '20',
          top: '-10',
          z: 1000000,
          style: {
            text: 'Keep Only',
            textAlign: 'center',
            textFill: '#333',
            fontSize: 14,
            cursor: 'pointer !important',
          },
          onclick: function () {
            // Add your code here to keep only the selected range
            const formattedSelectedRange = selectedPoints.map((point: any) => {
              return BenchmarkDataPoint.fromChartDataPoint(
                point,
                BenchmarkDataPointChartType.BenchmarkPopulationTopic
              );
            });
            // TODO add
            //props.onPointsKeepOnly && props.onPointsKeepOnly(formattedSelectedRange);
            removeExcludeKeepGraphic(chart);
          },
        },
      ],
    };

    // Add the custom graphic element to the chart
    chart.setOption({
      graphic: boxGroup,
    });
  };

  const removeExcludeKeepGraphic = (chart: any) => {
    // Remove the custom graphic element from the chart
    const currentOptions = chart.getOption();

    currentOptions.graphic = null;

    chart.setOption(currentOptions, true);
  };

  // const getColorForBenchmarkScore = (score: number, maxValue: number, minValue: number): string => {
  //   const DARK_RED = 'rgb(190,42,62)';
  //   const LIGHT_RED_ORANGE = 'rgb(244,125,66)';
  //   const YELLOW = 'rgb(244,209,102)';
  //   const LIGHT_GREEN = 'rgb(105,172,102)';
  //   const DARK_GREEN = 'rgb(35,119,63)';

  //   const range = maxValue - minValue;
  //   const tenPercent = 0.1 * range;
  //   const fivePercent = 0.05 * range;

  //   if (score > (maxValue - tenPercent)) {
  //     return DARK_GREEN;
  //   } else if (score > (maxValue - (2 * fivePercent)) && score <= (maxValue - tenPercent)) {
  //     return LIGHT_GREEN;
  //   } else if (score >= (-5 * fivePercent) && score <= (5 * fivePercent)) {
  //     return YELLOW;
  //   } else if (score >= (-10 * fivePercent) && score < (-5 * fivePercent)) {
  //     return LIGHT_RED_ORANGE;
  //   } else {
  //     return DARK_RED;
  //   }
  // };

  const getColorForBenchmarkScore = (score: number, maxValue: number, minValue: number): string => {
    const DARK_RED = 'rgb(190,42,62)';
    const ORANGE = 'rgb(239,119,74)';
    const YELLOW = 'rgb(244,209,102)';
    const LIGHT_GREEN = 'rgb(105,172,102)';
    const DARK_GREEN = 'rgb(35,119,63)';

    if(score >0.1) return DARK_GREEN
    if(score > 0.05) return LIGHT_GREEN
   if(score < 0.05 && score > -0.05) return YELLOW
    if(score < -0.05 && score > -0.1) return ORANGE
    return DARK_RED
  };

  const getChartOptions = (data: BenchmarkDataPoint[], width: number) => {
    if (data.length === 0) {
      return {};
    }

    const sortedPopulationData = data.sort(
      (a: BenchmarkDataPoint, b: BenchmarkDataPoint) => {
        return (b.positiveIncidence - b.negativeIncidence) - (a.positiveIncidence - a.negativeIncidence);
      }
    );
    const values = sortedPopulationData.map(a => a.positiveIncidence - a.negativeIncidence)
    const maxValue = values[0] ?? 0;
    const minValue = values[values.length - 1] ?? 0;

    return {
      title: [
        {
          text: title,
          left: "center",
          textStyle: {
            fontSize: 16,
          },
        },
      ],
      tooltip: {
        trigger: 'axis'
      },
      xAxis: [
        {
          data: sortedPopulationData.map(v => v.topicUserFriendlyName),
          axisLabel: {
            show: false,
          },
        },
        {
          data: sortedPopulationData.map(v => v.topicUserFriendlyName),
          gridIndex: 1,
          axisLabel: {
            rotate: 90,
            interval: 0,
          },
        }
      ],
      yAxis: [
        {
          nameGap: 40,
          name: '% Employees Mentioning',
          nameLocation: 'middle',
          axisLabel: {
            formatter: v => (v * 100).toString() + '%'
          },
        },
        {
          nameGap: 40,
          name: '% Mentioning Positively - % Negatively',
          nameLocation: 'middle',
          axisLabel: {
            formatter: v => (v * 100).toString() + '%'
          },
          gridIndex: 1,
        }
      ],
      grid: [
        {
          bottom: '55%',
          left: 55,
          right: 20,
        },
        {
          top: '50%',
          bottom: 100,
          left: 55,
          right: 20,
        }
      ],
      series: [
        {
          type: 'bar',
          showSymbol: false,
          data: sortedPopulationData.map((d) => {
            return {
              value: d.incidence,
              itemStyle: {
                color: 'gray',
              },
              basePoint: d,
            };
          }),
        },
        {
          type: 'bar',
          showSymbol: false,
          data: sortedPopulationData.map((d, ii) => {
            return {
              value: d.positiveIncidence - d.negativeIncidence,
              itemStyle: {
                color: getColorForBenchmarkScore(d.positiveIncidence - d.negativeIncidence, maxValue, minValue),
              },
              basePoint: d,
            };
          }),
          xAxisIndex: 1,
          yAxisIndex: 1
        }
      ],
      dataZoom: [getDataZoom(data.length, width)],
    };
  };

  useEffect(() => {
    const chartElement = document.getElementById('myChart');
    if (!chartElement) {
      return;
    }
    const chart = echarts.init(chartElement, theme.colorScheme);

    // TODO: - Format the data such that we are showing all of the topics + populations in the order we want...
    // TODO: - Also add coloring, etc. to the data points and make the scroll unified across the two grids...

    const { xAxis, yAxis, series, grid, title, dataZoom } =
      getChartOptions(data, chartElement.clientWidth);

    const option: any = {
      legend: {},
      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          // Format the tooltip to have the following:
          // 1. The name of the population
          // 2. The percentage of employees mentioning the population
          // 3. The percentage of mentions favorable

          // Get the data
          const data = params.data;
          const formattedPoint = BenchmarkDataPoint.fromChartDataPoint(
            data,
            BenchmarkDataPointChartType.BenchmarkPopulationTopic
          );
          // Return as a HTML box with the data inside

          // Round to 1 decimal place... But make sure there is always a decimal place...
          const roundedBenchmarkScore =
            formattedPoint.benchmarkScore.toFixed(1);

          let summaryText = ""
          const summaryTopicData: SumaryData | undefined = (summaryData ?? []).find(d => d.topic === formattedPoint.topic.toLowerCase())
          if (summaryTopicData) {
            summaryText = `
            <div>
                <br/>
                <div><b>Values in component analyses</b></div>
                ${Object.keys(summaryTopicData).map(k => {
              if (k !== "topic" && k !== "prioritize") {
                const result = `<div>${(summaryTopicData[k] as any).name}: <b>${(summaryTopicData[k] as any).value?.toFixed(1)}</b></div>`
                if (k === currentAnalysis?.id) {
                  return `<b>${result}</b>`
                }
                return result
              }
              return null
            }).filter(a => a !== null).join("")
              }
            </div>`
          }

          return `
                        <div>
                            <div><b>${formattedPoint.topicUserFriendlyName
            }</b></div>
                            <div>Benchmark: <b>${roundedBenchmarkScore}</b></div>
                            <div>Population: <b>${formattedPoint.populationName
            }</b></div>
                            <div># Responses: <b>${formattedPoint.rowCount.toLocaleString()}</b></div>
                            <div># Mentions of Topic: <b>${formattedPoint.vopicCount.toLocaleString()}</b></div>
                            <div>${params.seriesIndex === 0 ? "<b>" : ""}Incidence${params.seriesIndex === 0 ? "</b>" : ""}: <b>${formattedPoint.incidence.toLocaleString(
              undefined,
              {
                style: 'percent',
                maximumFractionDigits: 1,
              }
            )}</b></div>
                            <div>Sentiment: <b>${formattedPoint.sentiment.toLocaleString(
              undefined,
              {
                style: 'percent',
                maximumFractionDigits: 1,
              }
            )}</b></div>
                        </div>
                    ` + summaryText;
        },
        borderColor: '#ffffff',
        padding: 4,
        extraCssText: `
                    box-shadow: 0 0 3px rgba(0, 0, 0, 0.10);
                    border-radius: 2px;
                `,
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            pixelRatio: 4,
          },
        },
      },

      title: title,
      xAxis: xAxis,
      yAxis: yAxis,
      grid: grid,
      series: series,
      dataZoom: dataZoom,
      interactive: true,
      animationEasing: 'elasticOut',
      animationDelay: 50,
    };

    // Get the chart element
    chart.setOption(option);

    // // Add an onClick event to the chart...
    chart.on('click', (params: any) => {
      if (params.componentType === 'series') {
        const selectedPoint = params.data;
        const formattedSelectedPoint = BenchmarkDataPoint.fromChartDataPoint(
          selectedPoint,
          BenchmarkDataPointChartType.BenchmarkPopulationTopic
        );

        setFocalTopic(formattedSelectedPoint.topic);
        // addExcludeKeepGraphic(chart, params.seriesIndex, formattedSelectedPoint);
        // TODO add
        //props.onPointSelected && props.onPointSelected(formattedSelectedPoint);
      }
    });

    chart.on('brushEnd', (params: any) => {
      // FIXME: - There's a bug here that causes the chart not to return partially selected points even though they are technically inside the selected range
      // This is to do with the fact that the coordPoint that is returned is not the bounding box, but just a single point...
      // To fix this, a good way might be to look at the seriesIndex + dataPointIndex and figure out if the data point is within the selected range...
      if (params.areas.length === 0) {
        // removeExcludeKeepGraphic(chart);
        // TODO add
        //props.onPointsDeselected && props.onPointsDeselected();
        return;
      }

      const selectedRange = params.areas[0].range;
      const chartOptions = chart.getOption();

      if (chartOptions && chartOptions.series) {
        const selectedData: BenchmarkDataPoint[] = [];
        const selectedDataPoints: any = [];
        chartOptions.series.forEach(
          (series: echarts.EChartOption.Series, seriesIndex: number) => {
            const dataPoints: any[] | undefined = series.data;

            // Go through each data point and get it's pixel coordinate...
            // Then check if the pixel coordinate is within the selected range...
            // If it is, then add it to the selected data points...
            if (dataPoints) {
              dataPoints.forEach((dataPoint: any) => {
                const pixelCoord = chart.convertToPixel(
                  { seriesIndex: seriesIndex },
                  [dataPoint.value, dataPoint.basePoint.topicUserFriendlyName]
                );
                const xCoord = pixelCoord[0];
                const yCoord = pixelCoord[1];
                if (
                  xCoord >= selectedRange[0][0] &&
                  xCoord <= selectedRange[0][1] &&
                  yCoord >= selectedRange[1][0] &&
                  yCoord <= selectedRange[1][1]
                ) {
                  const formattedDataPoint =
                    BenchmarkDataPoint.fromChartDataPoint(
                      dataPoint,
                      BenchmarkDataPointChartType.BenchmarkPopulationTopic
                    );
                  selectedData.push(formattedDataPoint);
                  selectedDataPoints.push(dataPoint);
                }
              });
            }

            addExcludeKeepGraphicToRange(chart, selectedRange, selectedData);
          }
        );

        // Return the points to the caller
        // TODO add
        //props.onPointsSelected && props.onPointsSelected(selectedData);
      }
    });

    chart.getZr().on('click', (event: any) => {
      if (!event.target && event.componentType !== 'graphic') {
        // Call internal method to update the chart such that there are no selected points
        // Remove from ALL series, just just the first one...
        // Get the number of unique population name.s..
        const uniquePopulations = new Set(
          Object.keys(data).map((key: string) => data[key].populationName)
        );
        for (let i = 0; i < uniquePopulations.size; i++) {
          chart.dispatchAction({
            type: 'unselect',
            seriesIndex: i,
            dataIndex: Array.from({ length: data.length }, (v, k) => k),
          });
        }

        // Remove any graphic from the chart
        removeExcludeKeepGraphic(chart);

        // Call function to update that no points are selected
        // TODO add
        //props.onPointsDeselected && props.onPointsDeselected();
      }
    });

    if (isLoadingData === true) {
      chart.showLoading('default', {
        text: 'Loading...',
        fontSize: 16,
        color: '#4589df',
        textColor: '#000',
        zlevel: 0,
      });
    } else if (IsEmptyData && !isLoadingData) {
      chart.setOption({
        title: {
          text: 'No Data Available',
          subtext:
            'This may be due to a filter being applied, or because the data is not available yet',
          top: 'center',
          textStyle: {
            fontSize: 20,
          },
          subtextStyle: {
            fontSize: 16,
          },
        },
      });
    } else {
      chart.hideLoading();
    }

    setChartInstance(chart)

    return () => {
      setChartInstance(undefined)
      chart.dispose();
    };
  }, [data, summaryData, currentAnalysis?.id, theme.colorScheme, IsEmptyData, isLoadingData]);

  if (isLoadingData === false && isBenchmarkError && benchmarkError) {
    return (
      <Center style={{ height: '100%' }}>
        <APIErrorMessage response={benchmarkError} />
      </Center>
    );
  }

  return (
    <div
      ref={referenceComponent}
      id="myChart"
      style={{ width: '100%', height: '100%', overflow: 'hidden' }}
    ></div>
  );
};

export default RawVoiceChart;
