import { Text, Title, Image, List, ActionIcon, Anchor } from '@mantine/core';
import {
  IconDots,
  IconEye,
  IconTrash,
  IconArrowRight,
  IconEdit,
  IconX,
  IconCheck,
} from '@tabler/icons';

import { BaseHelpSectionProps } from './BaseHelpSection';
import SelectFocalTopicExampleImg from './SelectFocalTopicExample.png';
import { IconProgress } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
// import { IconCheck } from "@tabler/icons-react";

export const AnalysesHelpSection: React.FC<BaseHelpSectionProps> = ({
  linkedContext,
}) => {
  return (
    <>
      <Text color="dimmed">See your data in different lights</Text>

      <Title order={4}>Analyses</Title>
      <Text>
        Each item in this list represents an analysis in your project. By
        switching the active analysis, you change which data is shown according
        to what has been defined in the analysis.{' '}
      </Text>
      <Text>
        Beside each analysis, you can see its status indicating if it's ready to
        be viewed.
      </Text>
      <Title order={5}>Statuses</Title>
      <List spacing={'xl'} size="sm">
        <List.Item
          icon={
            <ActionIcon color="green" size={14} radius="xl" variant="filled">
              <IconCheck size={10} />
            </ActionIcon>
          }
        >
          <b>Ready</b>: Your analysis has processed all required data and is
          ready to view.
        </List.Item>

        <List.Item
          icon={
            <ActionIcon color="yellow" size={14} radius="xl" variant="filled">
              <IconCheck size={10} />
            </ActionIcon>
          }
        >
          <b>Core data ready</b>: Your analysis has processed the essential data
          and can be viewed, but it's still processing additional data. As a
          result, some views or features might not be available yet. This
          usually means the in same breath and segments part of the analysis are
          still processing.
        </List.Item>

        <List.Item
          icon={
            <ActionIcon color="blue" size={14} radius="xl" variant="filled">
              <IconProgress size={10} />
            </ActionIcon>
          }
        >
          <b>Processing</b>: Your analysis is still processing. This may take a
          few minutes depending on the size of your dataset.
        </List.Item>

        <List.Item
          icon={
            <ActionIcon color="red" size={14} radius="xl" variant="filled">
              <IconX size={10} />
            </ActionIcon>
          }
        >
          <b>Issues</b>: One or more critical issues occurred while processing
          your analysis, preventing its completion. If this occurs, please{' '}
          <Anchor href="mailto:ryan@culturex.com?subject=Error in Analysis">
            contact us
          </Anchor>{' '}
          for assistance.
        </List.Item>
      </List>
      <Title order={4}>Making Changes</Title>

      <Text>
        If you'd like to change your analysis or add a new one, you may do so by{' '}
        <Link to="../edit" relative="path" style={{ textDecoration: 'none' }}>
          <Anchor>editing</Anchor>
        </Link>{' '}
        your project. Once your changes have been saved, any relevant data will
        be re-processed and your new results will be available momentarily.
      </Text>

      <Text>
        <i>
          Please note: You cannot change the datasets associated with your
          project.
        </i>
      </Text>
    </>
  );
};
