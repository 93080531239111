import {
    Modal,
    Stack,
    Title,
    Text,
    TextInput,
    Group,
    Button,
    Flex,
    CopyButton,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useCallback, useEffect, useState } from 'react';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons';
import { useRouteStore } from '@stores/RouteStore';
import OrganizationAPI from '@apis/OrganizationAPI';
  
  interface NewOrganizationModalProps {
    opened: boolean;
    onClose: () => void;
    onOrganizationCreated?: () => void;
  }
  
  export const NewOrganizationModal = ({
    opened,
    onClose,
    onOrganizationCreated,
  }: NewOrganizationModalProps) => {
      const form = useForm({
          initialValues: {
            name: '',
          },
      });
  
      const organizationId = useRouteStore(s => s.organizationId);
      const [loading, setLoading] = useState(false);
  
      useEffect(() => {
          if (opened) {
              setLoading(false)
              form.reset();
          }
      }, [opened]);
      
      const createOrganization = useCallback(async ({ name }) => {
          try {
              setLoading(true);
              const organization = await OrganizationAPI.createOrganization(name)
              notifications.show({
                  id: 'organization-created',
                  color: 'green',
                  title: 'Organization created',
                  message: `Organization ${organization.name} created successfully`,
                  icon: <IconCheck size="1rem" />,
                  autoClose: 10000,
                  });
                  onClose();
                  if(onOrganizationCreated)
                    onOrganizationCreated();
          } catch (error) {
              form.setErrors({ root: error.response.data.Message })
              return;
          } finally {
              setLoading(false);
          }
    }, [ organizationId]);
  
    return (
        <Modal
            title={
            <Stack spacing={0}>
                <Title order={3}>Create organization</Title>
                <Text color="dimmed" size="sm">Create a new organization with and empty list of analyses</Text>
            </Stack>
            }
            opened={opened}
            onClose={onClose}
            withCloseButton={false}
            size="lg"
        >
            <form onSubmit={form.onSubmit(createOrganization)}>
                <TextInput
                    label="Organization name"
                    withAsterisk
                    error={form.errors.name}
                    {...form.getInputProps('name')}
                />
                <Group position="apart" mt="xl" >
                    <Text size="xs" c="red">{form.errors.root}</Text>
                    <Group position="right" mt="xl" >
                        <Button variant="subtle" color="dark" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button loading={loading} type='submit'>
                            Create
                        </Button>
                    </Group>
                </Group>
            </form>
        </Modal>
    );
  };
  