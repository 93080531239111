import { MultiSelectCheckbox } from '@components/MultiSelectCheckbox';
import SectionWrapper from '@components/SectionWrapper';

export const TestMultiSelectCheckbox = () => {
  return (
    <>
      <SectionWrapper>
        <MultiSelectCheckbox
          label="Select a Population"
          data={[
            { label: 'United States', value: 'united-states' },
            { label: 'Canada', value: 'canada' },
            { label: 'Mexico', value: 'mexico' },
            { label: 'United Kingdom', value: 'united-kingdom' },
            { label: 'France', value: 'france' },
            { label: 'Germany', value: 'germany' },
          ]}
          rightSection={<></>}
          searchable
          maxSelectedValues={3}
        />
      </SectionWrapper>
    </>
  );
};
