import { create } from 'zustand';
import { ProjectMetricType } from '@apis/ProjectAPI';
import { Population } from './AnalysisEditionStore';
import QuoteModel from '@models/QuoteModel';

interface QuoteStore {
    population: Population | undefined;
    setPopulation: (value: Population | undefined) => void;

    quoteSentiments: string[];
    setQuoteSentiments: (value: string[]) => void;
    
    activeQuoteDemographics: object;
    setActiveQuoteDemographics: (value: object) => void;

    coMentionedTopic: string | undefined;
    setCoMentionedTopic: (value: string | undefined) => void;

    activeCoMentionType: ProjectMetricType;
    setActiveCoMentionType: (value: ProjectMetricType) => void;

    currentIndex: number;
    setCurrentIndex: (value: number) => void;

    currentQuote: QuoteModel | undefined;
    setCurrentQuote: (value: QuoteModel | undefined) => void;
}

export const useQuoteStore = create<QuoteStore>()((set, get) => ({
  projectId: undefined,
  organizationId: undefined,

  population: undefined,
  setPopulation: (value) => set({ population: value }),

  quoteSentiments: ['positive', 'negative', 'indeterminate'],
  setQuoteSentiments: (value) => set({ quoteSentiments: value }),
  
  activeQuoteDemographics: {},
  setActiveQuoteDemographics: (value) =>
    set({ activeQuoteDemographics: value }),

  coMentionedTopic: undefined,
  setCoMentionedTopic: (value) => set({ coMentionedTopic: value }),

  activeCoMentionType: ProjectMetricType.NegativeCoMention,
  setActiveCoMentionType: (value) => set({ activeCoMentionType: value }),

  currentIndex: 0,
  setCurrentIndex: (value) => set({ currentIndex: value }),

  currentQuote: undefined,
  setCurrentQuote: (value) => set({ currentQuote: value }),
}));
