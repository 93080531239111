import {
  Slider,
  Stack,
  Text,
} from '@mantine/core';
import { ChartType, useAnalysisStore } from '@stores/AnalysisStore';

export const NumberOfMentionsFilter: React.FC = () => {
  const numberOfMentions = useAnalysisStore((s) => s.numberOfMentions);
  const setNumberOfMentions = useAnalysisStore((s) => s.setNumberOfMentions);
  const selectedChart = useAnalysisStore((s) => s.selectedChart);

  return (
    <Stack spacing={0}>
      <Text size="xs">{selectedChart === ChartType.PROGRESS_OVER_TIME ? "Smaller " : ""}# Mentions ({numberOfMentions.toLocaleString()})</Text>
      <Slider
        defaultValue={5}
        miw={150}
        min={0}
        size="xs"
        thumbSize={13}
        max={20}
        value={numberOfMentions}
        label={(value) => value.toLocaleString()}
        onChange={setNumberOfMentions}
        styles={{ markLabel: { display: 'none' } }}
        inverted
      />
    </Stack>
  );
};

export const NumberOfMentionsFilterSkeleton: React.FC = () => {
  return (
    <Stack spacing={0}>
      <Text size="xs"># Mentions (-)</Text>
      <Slider
        disabled
        defaultValue={5}
        miw={150}
        min={1}
        max={20}
        size="xs"
        thumbSize={13}
        label={(value) => value.toLocaleString()}
        styles={{ markLabel: { display: 'none' } }}
        inverted
      />
    </Stack>
  );
};
