import { Anchor, Card, Flex, Loader, Stack, Text } from '@mantine/core';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AnalysisType,
  useAnalysisEditionStore,
} from '@stores/AnalysisEditionStore';
import {
  DistributionRow,
  useDistributionPoints,
} from '@apis/hooks/useDistributionPoints';
import ExplorationTableItem from '@components/AnalysisEdition/ExplorationTableItem';
import Selecto, { OnDragEnd, OnSelect, OnSelectEnd } from 'react-selecto';
import { useCurrentProject } from '@hooks/useCurrentProject';
import Fuse from 'fuse.js';
import { VirtualItem } from '@tanstack/react-virtual';
import VirtualizedList from '@components/VirtualizedList';

interface ExplorationTableProps {
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  numberOfResponses: [number, number];
  setNumberOfResponses: Dispatch<SetStateAction<[number, number]>>;
  maxNumberOfResponses: number;
}

const FuseOptions = {
  includeScore: true,
  includeMatches: true,
  minMatchCharLength: 2,
  threshold: 0.2,
  keys: ['columns.value'],
};

const ExplorationTable: FC<ExplorationTableProps> = ({
  searchQuery,
  setSearchQuery,
  numberOfResponses,
  setNumberOfResponses,
  maxNumberOfResponses,
}) => {
  const dataset = useAnalysisEditionStore((s) => s.dataset);
  const dragging = useAnalysisEditionStore((s) => s.dragging);
  const activeFields = useAnalysisEditionStore((s) => s.activeFields);
  const selectedDistributionRows = useAnalysisEditionStore(
    (s) => s.selectedDistributionRows
  );
  const setSelectedDistributionRows = useAnalysisEditionStore(
    (s) => s.setSelectedDistributionRows
  );
  const distributionData = useAnalysisEditionStore((s) => s.distributionData);
  const setDistributionData = useAnalysisEditionStore(
    (s) => s.setDistributionData
  );
  const buildPopulation = useAnalysisEditionStore((s) => s.buildPopulation);
  const globalFilter = useAnalysisEditionStore((s) => s.globalFilter);
  const project = useCurrentProject();
  const { data: distributionPoints, isLoading } = useDistributionPoints(
    project,
    dataset,
    activeFields,
    globalFilter
  );
  const analysisType = useAnalysisEditionStore((s) => s.analysisType);
  const selectoRef = useRef<Selecto>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const rootStackRef = useRef<HTMLDivElement>(null);
  const scrollerRef = useRef<HTMLDivElement>(null);
  const [lastAdded, setLastAdded] = useState<string | null>(null);
  const [debounceOnSelect, setDebounceOnSelect] = useState<OnSelect>(null);
  const [debounceSelection, setDebounceSelection] = useState<string[]>([]);
  const [isMouseDraging, setIsMouseDraging] = useState<boolean>(false);
  const [fuse, setFuse] = useState<Fuse<DistributionRow>>(
    new Fuse(distributionData, FuseOptions)
  );
  // State to track if the list is active
  const [isActive, setIsActive] = useState(false);

  // Function to activate the list
  const activateList = () => setIsActive(true);

  // Function to deactivate the list
  const deactivateList = () => setIsActive(false);

  useEffect(
    () => setDistributionData(distributionPoints),
    [distributionPoints]
  );

  useEffect(
    () => setFuse(new Fuse(distributionData, FuseOptions)),
    [distributionData]
  );

  const maxCountValue = useMemo(
    () =>
      !distributionData
        ? 0
        : distributionData
            .map((distributionPointRow) => distributionPointRow.responses)
            .filter((v) => !isNaN(v))
            .reduce((a, b) => Math.max(a, b), 0),
    [distributionData]
  );
  const dataToShow = useMemo(
    () =>
      (searchQuery
        ? fuse.search(searchQuery).map((d) => d.item)
        : distributionData ?? []
      ).filter(
        (d) =>
          d.responses >= numberOfResponses[0] &&
          d.responses <= numberOfResponses[1]
      ),
    [fuse, distributionData, searchQuery, numberOfResponses]
  );

  const handleSelectAll = useCallback(() => {
    setSelectedDistributionRows(dataToShow.map((d) => d.id));
    if (selectoRef.current) {
      const elements = dataToShow.map(({ id }) => document.getElementById(id));
      selectoRef.current.setSelectedTargets(elements);
    }
  }, [dataToShow, setSelectedDistributionRows, selectoRef.current]);

  // Add a method to call handleSelectAll when the user pressed CMd+ A (meta key, so it works on mac and windows)
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isActive && e.metaKey && e.key === 'a') {
        e.preventDefault();
        handleSelectAll();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isActive, handleSelectAll]);

  // Effect to add and remove click event listeners
  useEffect(() => {
    // Add click event listener to document to track clicks outside the list
    const handleClickOutside = (event: MouseEvent) => {
      if (
        rootStackRef.current &&
        !rootStackRef.current.contains(event.target as Node)
      ) {
        deactivateList();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    // Cleanup
    return () =>
      document.removeEventListener('click', handleClickOutside, true);
  }, []);

  const handleUnselectAll = useCallback(() => {
    setSelectedDistributionRows([]);
    if (selectoRef.current) {
      selectoRef.current.setSelectedTargets([]);
    }
  }, [selectoRef.current]);

  const toggleItems = useCallback(
    (selectedValues: string[], idFrom: string, idTo: string): string[] => {
      let indexFrom = dataToShow.findIndex((i) => i.id === idFrom);
      let indexTo = dataToShow.findIndex((i) => i.id === idTo);
      if (indexFrom !== -1 && indexTo !== -1) {
        if (indexTo < indexFrom) {
          const aux = indexTo;
          indexTo = indexFrom;
          indexFrom = aux;
        }
        for (var i = indexFrom; i <= indexTo; i++) {
          if (!selectedValues.includes(dataToShow[i].id)) {
            selectedValues.push(dataToShow[i].id);
          }
        }
        return selectedValues;
      }
      return [];
    },
    [dataToShow]
  );

  const onSelect = useCallback(
    (e: OnSelect): string[] => {
      if (e.inputEvent.shiftKey && lastAdded !== null) {
        if (e.added.length > 0) {
          return toggleItems(
            e.selected.map((el) => el.id),
            lastAdded,
            e.added[e.added.length - 1].id
          );
        }
      } else if (e.added.length > 0) {
        setLastAdded(e.added[e.added.length - 1].id);
      } else {
        if (e.selected.length === 1) {
          setLastAdded(e.selected[0].id);
        } else {
          setLastAdded(null);
        }
      }
      return e.selected.map((e) => e.id);
    },
    [toggleItems, selectedDistributionRows]
  );

  const onDragEnd = useCallback(
    (e: OnDragEnd) => {
      if (!isMouseDraging) {
        const selection = onSelect(debounceOnSelect);
        setSelectedDistributionRows(selection);
        setDebounceSelection([]);
      }
      setIsMouseDraging(false);
    },
    [isMouseDraging, debounceOnSelect, debounceSelection]
  );

  if (dragging === 'column') {
    return null;
  }

  if (activeFields.length === 0) {
    return (
      <Card
        withBorder
        style={{ position: 'absolute', width: '100%', height: '100%' }}
      >
        <Stack
          justify="center"
          align="center"
          style={{ height: '100%' }}
          spacing={0}
        >
          <Text size="lg" color="gray" weight={600}>
            Exploration chart
          </Text>
          <Text size="sm" color="gray">
            Drag fields here to start
          </Text>
        </Stack>
      </Card>
    );
  }

  if (isLoading || !distributionData || !dataToShow) {
    return (
      <Flex
        m={'auto'}
        style={{
          position: 'absolute',
          right: 0,
          left: 0,
          top: 100,
          zIndex: 10,
        }}
        justify="center"
      >
        <Loader color="blue" />
      </Flex>
    );
  }

  return (
    <Stack
      style={{ position: 'absolute', inset: 0 }}
      ref={rootStackRef}
      onClick={activateList}
    >
      <Stack
        ref={containerRef}
        className="container"
        spacing="xs"
        style={{
          height: '100%',
          flexGrow: 1,
          WebkitUserSelect: 'none',
          MozUserSelect: 'none',
          msUserSelect: 'none',
          userSelect: 'none',
        }}
        onClick={activateList}
      >
        {dataToShow.length > 0 && (
          <Selecto
            ref={selectoRef}
            container={containerRef.current}
            dragContainer={containerRef.current}
            selectableTargets={['.target']}
            onScroll={({ direction }) => {
              scrollerRef.current!.scrollBy(
                direction[0] * 10,
                direction[1] * 10
              );
            }}
            scrollOptions={{
              container: scrollerRef,
              getScrollPosition: () => {
                return [
                  scrollerRef.current!.scrollLeft,
                  scrollerRef.current!.scrollTop,
                ];
              },
              throttleTime: 30,
              threshold: 0,
            }}
            hitRate={1}
            selectByClick={true}
            selectFromInside={false}
            toggleContinueSelect={[['meta'], ['ctrl']]}
            onDragEnd={onDragEnd}
            onSelect={setDebounceOnSelect}
            onSelectEnd={(e) =>
              setDebounceSelection(e.selected.map((el) => el.id))
            }
          />
        )}
        <VirtualizedList
          ref={scrollerRef}
          data={dataToShow}
          itemHeight={40}
          style={{ marginTop: 60 }}
        >
          {(vi: VirtualItem) => (
            <ExplorationTableItem
              key={vi.key}
              data={dataToShow[vi.index]}
              index={vi.index}
              maxCountValue={maxCountValue}
              prevValue={dataToShow[vi.index - 1]}
              nextValue={dataToShow[vi.index + 1]}
            />
          )}
        </VirtualizedList>
        {dataToShow.length === 0 && (
          <Stack align="center" mt="xl" spacing="xs">
            <Text color="grey">No data</Text>
            <Anchor
              c="red"
              onClick={() => {
                setSearchQuery('');
                setNumberOfResponses([0, maxNumberOfResponses]);
              }}
            >
              Remove filters
            </Anchor>
          </Stack>
        )}
      </Stack>
      <Flex justify="space-between">
        <Flex gap="md">
          {distributionData.length > 0 && (
            <Text
              size="sm"
              color="dimmed"
            >{`${distributionData.length} fields - ${ new Intl.NumberFormat().format(distributionData.reduce((p,c) => p + c.responses, 0),)} responses`}</Text>
          )}
          {selectedDistributionRows.length < distributionData.length && (
            <Anchor size="sm" onClick={handleSelectAll}>
              Select all
            </Anchor>
          )}
          {selectedDistributionRows.length >= 1 && (
            <Anchor size="sm" onClick={handleUnselectAll} color="red">
              Unselect all
            </Anchor>
          )}
        </Flex>
        {analysisType !== AnalysisType.DRIVERS_OF_OUTCOME &&
          selectedDistributionRows.length === 1 && (
            <Flex gap="xs">
              <Text size="sm">1 item selected:</Text>
              <Anchor size="sm" onClick={() => buildPopulation(false)}>
                Add population
              </Anchor>
            </Flex>
          )}
        {analysisType !== AnalysisType.DRIVERS_OF_OUTCOME &&
          selectedDistributionRows.length > 1 && (
            <Flex gap="xs">
              <Text size="sm">{`${selectedDistributionRows.length} items selected:`}</Text>
              <Anchor
                size="sm"
                onClick={() => buildPopulation(false)}
              >{`Add ${selectedDistributionRows.length} populations`}</Anchor>
              <Anchor size="sm" onClick={() => buildPopulation(true)}>
                Add 1 combined population
              </Anchor>
            </Flex>
          )}

        {analysisType === AnalysisType.DRIVERS_OF_OUTCOME &&
          selectedDistributionRows.length === 1 && (
            <Flex gap="xs">
              <Text size="sm">1 item selected:</Text>
              <Anchor size="sm" onClick={() => buildPopulation(false, true)}>
                Set population
              </Anchor>
            </Flex>
          )}
        {analysisType === AnalysisType.DRIVERS_OF_OUTCOME &&
          selectedDistributionRows.length > 1 && (
            <Flex gap="xs">
              <Text size="sm">{`${selectedDistributionRows.length} items selected:`}</Text>
              <Anchor
                size="sm"
                onClick={() => buildPopulation(true, true)}
              >{`Set combined population`}</Anchor>
            </Flex>
          )}
      </Flex>
    </Stack>
  );
};

export default ExplorationTable;
