import { create } from 'zustand';

interface TopicStore {
    focalTopic: string | undefined;
    setFocalTopic: (value: string | undefined) => void;

    allTopics: string[];
    setAllTopics: (value: string[]) => void;

    allThemes: string[];
    setAllThemes: (value: string[]) => void;

    topicDefinitions: { [key: string]: string };
    setTopicDefinitions: (value: { [key: string]: string }) => void;
}

export const useTopicStore = create<TopicStore>()((set, get) => ({
  focalTopic: undefined,
  setFocalTopic: (value) => set({ focalTopic: value }),

  allTopics: [],
  setAllTopics: (value) => set({ allTopics: value }),

  allThemes: [],
  setAllThemes: (value) => set({ allThemes: value }),

  topicDefinitions: {},
  setTopicDefinitions: (value) => set({ topicDefinitions: value }),
}));
