import { useQuery, useQueryClient } from '@tanstack/react-query';
import ProjectAPI from '@apis/ProjectAPI';
import { DatasetField } from '@hooks/useDatasetFields';
import ProjectModel from '@models/ProjectModel';

export interface DistributionRow {
  id: string;
  columns: { column: string; value: any }[];
  responses: number;
}

export const useDistributionPoints = (
  project: ProjectModel | undefined,
  dataset: string,
  fields: DatasetField[],
  definition?: object
) => {
  return useQuery({
    queryKey: [
      'distribution',
      dataset,
      project?.projectId,
      ...fields.map((f) => f.id),
      JSON.stringify(definition),
    ],
    queryFn: async () => {
      return ProjectAPI.getDistributionForColumns(
        project.projectId,
        dataset,
        fields.map((field: DatasetField) => field.field),
        definition,
        project?.organizationId
      ).then((distributionPoints) => {
        return distributionPoints.map((pointRow) => {
          var columns = [];

          const id = pointRow
            .map(
              (c) =>
                (c.Column ?? 'NULL').toString() +
                '-' +
                (c.Value ?? 'NULL').toString()
            )
            .join('_');
          let responses = 0;

          pointRow.forEach((point) => {
            if (point.Column === '<COUNT>') {
              responses = point.Value;
            } else {
              columns.push({
                value: point.Value,
                column: point.Column.replace(`${dataset}_`, `${dataset}@`),
              });
            }
          });

          return { id, columns, responses };
        });
      });
    },
    enabled: !!project && !!dataset && fields.length > 0,
    staleTime: 1000 * 60 * 10, // 10 minutes
  });
};
