import { Text, Title, Image, Accordion, Container } from '@mantine/core';
import { BaseHelpSectionProps } from './BaseHelpSection';
import SelectFocalTopicExampleImg from './SelectFocalTopicExample.png';

export const TopicExplorationHelpSection: React.FC<BaseHelpSectionProps> = ({
  linkedContext,
}) => {
  return (
    <>
      <Text color="dimmed">
        Explore how various topics compare to each other within your analysis.
      </Text>

      <Title order={4}>Tiles</Title>
      <Text>
        Each tile shows a unique aspect of your analysis, which varies depending
        on the analysis type. For instance, in a "Key Drivers of Outcome In
        Population" analysis, the first few tiles display the main factors
        affecting your outcome.
      </Text>
      <Text>
        Certain tiles require you to <i>focus</i> on a topic. This allows you to
        glean insights into that specific topic.
      </Text>
      <Text>
        In order to focus on a topic, navigate to a chart and select your topic
        of interest. A small menu will appear where you can click "focus". This
        will cause the tiles to update to reflect the topic you have selected.
      </Text>
      <Image
        maw={240}
        mx="auto"
        style={{ border: '1px dashed', borderColor: 'lightgray' }}
        radius="md"
        src={SelectFocalTopicExampleImg}
        alt="Random image"
      />

      {/* <Title order={4}>Charts</Title>
        <Text>Select the chart type below to learn more about it</Text>
        <Accordion defaultValue="benchmark" variant="filled">
            <Accordion.Item value="benchmark">
                <Accordion.Control>Benchmark</Accordion.Control>
                <Accordion.Panel>
                    <Text>
                        Axies
                    </Text>
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion> */}
    </>
  );
};
