import {
  Modal,
  Stack,
  Title,
  Text,
  Group,
  Grid,
  Loader,
  Select,
  Popover,
  Anchor,
} from '@mantine/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { QuoteReader } from '@components/QuoteReader/QuoteReader';
import { useProjectDatasets } from '@apis/hooks/useProjectDatasets';
import { formatAvailableFields } from '@utils/MetadataUtils';
import { useCurrentProject } from '@hooks/useCurrentProject';
import QueryBuilder from './QueryBuilder';
import { useQuoteStore } from '@stores/QuoteStore';
import { useProjectTopics } from '@apis/hooks/useProjectTopics';
import { useTopicStore } from '@stores/TopicStore';
import { useProjectAnalyses } from '@hooks/useProjectAnalyses';
import { Population } from '@redux/benchmarksSlice';
import { TopicSelect } from './TopicSelect';
import { ProjectMetricType } from '@apis/ProjectAPI';
import { FocalTopicFilter } from './FocalTopicFilter';

interface QuotesModalProps {
  opened: boolean;
  onClose: () => void;
}

export const QuotesModal = ({
  opened,
  onClose,
}: QuotesModalProps) => {
  const currentProject = useCurrentProject();
  const analyses = useProjectAnalyses(currentProject);
  const { data: datasetsMetadata } = useProjectDatasets(currentProject);
  const setPopulation = useQuoteStore(s => s.setPopulation)
  const coMentionedTopic = useQuoteStore(s => s.coMentionedTopic)
  const setCoMentionedTopic = useQuoteStore(s => s.setCoMentionedTopic)
  const activeCoMentionType = useQuoteStore(s => s.activeCoMentionType)
  const setActiveCoMentionType = useQuoteStore(s => s.setActiveCoMentionType)
  const { data: topicInformation, isLoading } = useProjectTopics(currentProject);
  const setAllTopics = useTopicStore((s) => s.setAllTopics);
  const setAllThemes = useTopicStore((s) => s.setAllThemes);
  const setTopicDefinitions = useTopicStore((s) => s.setTopicDefinitions);
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [datasetNames, setDatasetNames] = useState("");
  const [ queryBuilder, setQueryBuilder] = useState(null)

  useEffect(() => {
    if(opened)
      setActiveCoMentionType(ProjectMetricType.Base)
  }, [opened]);

  const selectOptions = useMemo(()=>{
    const options = []
    analyses.forEach(a => a.populations.forEach((p,i) => options.push({
      key: a.id + i,
      label: p,
      group: a.name,
      value: JSON.stringify({
        title: p,
        definition: a.populationDefinitions[p]
      })
    })))
    return options;
  }, [analyses])

  const availableFields = useMemo(
    () => (datasetsMetadata ? formatAvailableFields(datasetsMetadata) : {}),
    [datasetsMetadata]
  );

  const onSelectPopulation = useCallback((value) => {
    const newPopulation = JSON.parse(value) as Population;
    setPopulation(newPopulation)
    setPopoverOpened(false)
    setQueryBuilder(null)
    setTimeout(() => {
      setQueryBuilder(
        datasetsMetadata ?
          <QueryBuilder
            focalPopulation={newPopulation.title}
            initialDefinition={newPopulation.definition}
            onDefinitionUpdate={(
              populationName: string,
              definition: object
            ) => {
              setPopulation({
                title: populationName,
                definition
              });
            }}
            metadata={datasetsMetadata}
            datasets={datasetsMetadata}
            availableFields={availableFields}
          /> : null
      )
    }, 10);
  }, [datasetsMetadata, availableFields]);

  useEffect(() => {
    setQueryBuilder(null)
    setTimeout(() => {
      setQueryBuilder(
        datasetsMetadata ? <QueryBuilder
            focalPopulation={'Custom population'}
            initialDefinition={undefined}
            onDefinitionUpdate={(
              populationName: string,
              definition: object
            ) => {
              setPopulation({
                title: populationName,
                definition
              });
            }}
            metadata={datasetsMetadata}
            datasets={datasetsMetadata}
            availableFields={availableFields}
          /> : null
      )
    }, 10);
  }, [datasetsMetadata, availableFields])
  
  useEffect(() => {
    if(datasetsMetadata) {
      const names = []
      datasetsMetadata.forEach(v => {
        names.push(v.DatasetAlias);
      })
      setDatasetNames(names.join(" and "))
    }
  }, [datasetsMetadata])

  useEffect(() => {
    if (topicInformation) {
      const { topics, themes, topicDefinitions } = topicInformation;
      setAllTopics(topics);
      setAllThemes(themes);
      setTopicDefinitions(topicDefinitions);
    }
  }, [topicInformation]);

  return (
    <Modal
      title={
        <Stack spacing={0}>
          <Title order={3}>Quotes</Title>
          <Text color="dimmed" size="sm">
            {datasetsMetadata ? <>Search for quotes within <b>{ datasetNames }</b></> : "Loading datasets"}
          </Text>
        </Stack>
      }
      closeOnClickOutside={false}
      opened={opened}
      onClose={onClose}
      withCloseButton={true}
      size={1200}
    >
        { isLoading ? <Loader/> : 
        <Grid grow mt={0}>
            <Grid.Col span={5}>
              <Text weight={700}>Filters</Text>
              <Group mt="sm" position="apart">
                <Text size="xs" weight={600}>Population definition</Text>
                <Popover opened={popoverOpened} onChange={setPopoverOpened} withArrow>
                  <Popover.Target>
                    <Anchor size="xs" mr={"xs"} onClick={() => setPopoverOpened(true)}>
                      Import from an analysis
                    </Anchor>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <div style={{ width: '250px' }}>
                    <Select
                      placeholder="Pick a population"
                      searchable
                      nothingFound="No options"
                      data={selectOptions}
                      onChange={onSelectPopulation}
                    />
                    </div>
                  </Popover.Dropdown>
                </Popover>
              </Group>
              {queryBuilder}
              <Text mt="sm" size="xs" weight={600}>Topic</Text>
              <Group grow mr="xs">
                <FocalTopicFilter/>
              </Group>

              <Text mt="sm" size="xs" weight={600}>Co-Mentions</Text>
              <Select
                size="xs"
                mr="xs"
                value={activeCoMentionType}
                data={[
                  {
                    label: "None",
                    value: ProjectMetricType.Base
                  },
                  {
                    label: "Positive",
                    value: ProjectMetricType.PositiveCoMention
                  },
                  {
                    label: "Negative",
                    value: ProjectMetricType.NegativeCoMention
                  }]}
                onChange={setActiveCoMentionType}
              />

              {(activeCoMentionType === ProjectMetricType.NegativeCoMention || activeCoMentionType === ProjectMetricType.PositiveCoMention)  && <>
                <Text mt="sm" size="xs" weight={600}>Co-Mention Topic</Text>
                <TopicSelect
                  size="xs"
                  mr="xs"
                  value={coMentionedTopic}
                  onChange={setCoMentionedTopic}
                />
              </> }
            </Grid.Col>
            <Grid.Col span={7}>
                <QuoteReader showCoMentionTopic={(activeCoMentionType === ProjectMetricType.NegativeCoMention || activeCoMentionType === ProjectMetricType.PositiveCoMention) && coMentionedTopic}/>
            </Grid.Col>
        </Grid> }
    </Modal>
  );
};
