import React from 'react';
import { Button, Checkbox, SegmentedControl } from '@mantine/core';

interface MantineConjunctionsProps {
  id: string;
  not: boolean;
  setNot: (value: any) => void;
  conjunctionOptions: any;
  setConjunction: any;
  disabled: boolean;
  readonly: boolean;
  config: any;
  showNot: boolean;
  notLabel: string;
}

export const MantineConjunctions: React.FC<MantineConjunctionsProps> = ({
  id,
  not,
  setNot,
  conjunctionOptions,
  setConjunction,
  disabled,
  readonly,
  config,
  showNot,
  notLabel,
}: MantineConjunctionsProps) => {
  //TIP: disabled=true if only 1 rule; readonly=true if immutable mode
  const conjsCount = Object.keys(conjunctionOptions).length;
  const lessThenTwo = disabled;
  const { forceShowConj } = config.settings;
  const showConj = forceShowConj || (conjsCount > 1 && !lessThenTwo);

  const renderOptions = () =>
    Object.keys(conjunctionOptions).map((key) => {
      const { id, name, label, checked } = conjunctionOptions[key];
      let postfix = setConjunction.isDummyFn ? '__dummy' : '';
      if ((readonly || disabled) && !checked) return null;
      return (
        <Button
          key={id + postfix}
          id={id + postfix}
          size="xs"
          compact
          variant="filled"
          color={checked ? 'blue' : 'gray'}
          value={key}
          onClick={onClick.bind(null, key)}
          disabled={readonly || disabled}
        >
          {label}
        </Button>
      );
    });

  const onClick = (value: any) => setConjunction(value);
  // const onNotClick = checked => setNot(checked);

  // const renderNot = () => {
  //   if (readonly && !not)
  //     return null;
  //   return (
  //     <Checkbox
  //       size="xs"
  //       label={notLabel}
  //       value={not}
  //     />
  //   );
  // };

  const renderNot = () => {
    if (readonly && !not) return null;
    return (
      <Checkbox
        size="xs"
        label={notLabel}
        checked={not}
        onChange={() => setNot(!not)}
      />
    );
  };

  return (
    <Button.Group
    // disableElevation
    // variant="contained"
    // size="small"
    // disabled={readonly}
    >
      {showConj && renderOptions()}
    </Button.Group>
  );
};
