import APIRequesterBase from './APIBase';
import axios from 'axios';
import OrganizationModel from '../models/OrganizationModel';

class OrganizationAPI extends APIRequesterBase {
  static async listOrganizations(
    organizationId: number
  ): Promise<OrganizationModel[]> {
    const url = `${this.getAPIUrl()}/organization/${organizationId}`;
    const headers = await this.getHeaders();

    return axios.get(url, { headers: headers }).then((response) => {
      const organizations: OrganizationModel[] = response.data.map(
        (group: any) => OrganizationModel.fromAPIResponse(group)
      );
      return organizations;
    });
  }

  static async createOrganization(
    Alias: string
  ): Promise<OrganizationModel> {
    const url = `${this.getAPIUrl()}/organization`;
    const headers = await this.getHeaders();

    return axios.post(url, { Alias },  { headers: headers }).then((response) => {
      return OrganizationModel.fromAPIResponse(response.data);
    });
  }
}

export default OrganizationAPI;
