interface Permissions {
  Download: string[];
  Write: string[];
  Read: string[];
}

interface Role {
  Description: string;
  ExpirationDate: Date | null;
  OrganizationID: Number;
  RoleName: string;
}

export interface Token {
  DateAdded: Date;
  OrganizationID: number;
  RoleName: string;
  Status: 'ENABLED' | 'REVOKED';
  Token: string;
}

class SharedLinkModel {
  Permissions: Permissions;
  Expiration: Date | null;
  Role: Role;
  Tokens: Token[];

  constructor(
    Permissions: Permissions,
    Expiration: Date | null,
    Role: Role,
    Tokens: Token[]
  ) {
    this.Permissions = Permissions;
    this.Expiration = Expiration;
    this.Role = Role;
    this.Tokens = Tokens;
  }

  static fromAPIResponse(apiResponse: any): SharedLinkModel {
    return new SharedLinkModel(
      apiResponse.Permissions,
      apiResponse.Expiration,
      apiResponse.Role,
      apiResponse.Tokens
    );
  }
}

export default SharedLinkModel;
