import { AnalysisStatusIndicator } from '@components/AnalysisStatusIndicator';
import { useCurrentAnalysis } from '@hooks/useCurrentAnalysis';
import { Stack, Text } from '@mantine/core';
import { AnalysisStatus } from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import { FC, ReactNode } from 'react';

interface CheckAnalysisStatusProps {
  children: ReactNode;
}

const CheckAnalysisStatus: FC<CheckAnalysisStatusProps> = ({ children }) => {
  const analysis = useCurrentAnalysis();
  if (analysis.status === AnalysisStatus.PROCESSING) {
    return (
      <Stack align="center" justify="center" style={{ height: '100%' }}>
        <AnalysisStatusIndicator analysis={analysis} />
        <Text>
          Analysis processing. This analysis typically takes between 1 and 5
          minutes to complete
        </Text>
      </Stack>
    );
  } else if (analysis.status === AnalysisStatus.ERROR) {
    return (
      <Stack align="center" justify="center" style={{ height: '100%' }}>
        <AnalysisStatusIndicator analysis={analysis} />
        <Text>
          An error occured while processing your analysis. Please contact us for
          assistance
        </Text>
      </Stack>
    );
  }
  return <>{children}</>;
};

export default CheckAnalysisStatus;
