import React, { forwardRef, useState } from 'react';
import {
  Badge,
  Stack,
  SelectProps,
  BadgeProps,
  Menu,
  ActionIcon,
  UnstyledButton,
  SelectItem,
  Checkbox,
  MultiSelectProps,
  Indicator,
  Text,
} from '@mantine/core';

type BadgePropsOmitted = Omit<
  BadgeProps,
  'classNames' | 'styles' | 'variant'
>;
type SelectPropsOmitted = Omit<
  MultiSelectProps,
  'classNames' | 'color' | 'styles' | 'variant'
>;

interface BadgeSelectProps extends BadgePropsOmitted, SelectPropsOmitted {
  children: React.ReactNode;
  ref: any
}

function MiddleTruncatedText({ text, maxLength }) {
  if (text.length <= maxLength) {
    return <span>{text}</span>;
  }

  const start = text.substring(0, Math.floor(maxLength / 2) - 1);
  const end = text.substring(
    text.length - Math.floor(maxLength / 2) + 2,
    text.length
  );
  const displayText = `${start}...${end}`;

  return <span>{displayText}</span>;
}

export const BadgeSelect = forwardRef<
  HTMLDivElement,
  BadgeSelectProps
>(({ children, onChange, ...rest }: BadgeSelectProps, ref) => {
  const badgeProps = rest as BadgePropsOmitted;
  const selectProps = rest as SelectPropsOmitted;

  const [selectedValues, setSelectedValues] = useState<Set<string>>(
    selectProps.defaultValue ? new Set(selectProps.defaultValue) : new Set()
  );

  const onSelect = (value: string) => {
    const newSelectedValues = new Set(selectedValues);
    if (newSelectedValues.has(value)) {
      newSelectedValues.delete(value);
    } else {
      newSelectedValues.add(value);
    }
    setSelectedValues(newSelectedValues);
  };

  const onClose = () => {
    // Only call delegate if the value has changed
    if (
      selectProps.defaultValue &&
      selectProps.defaultValue.length !== selectedValues.size
    ) {
      onChange(Array.from(selectedValues));
    }
  };

  return (
    <Menu shadow="md" width="auto" withArrow onClose={onClose}>
      <Menu.Target>
        <Indicator
          variant="dot"
          color="blue"
          disabled={selectedValues.size === 3}
          style={{ cursor: 'pointer' }}
        >
          <Badge {...badgeProps}>
            <MiddleTruncatedText text={children} maxLength={20} />
          </Badge>
        </Indicator>
      </Menu.Target>

      <Menu.Dropdown>
        <Stack spacing={'xs'}>
          <Text size="xs" color="dimmed">
            {children}
          </Text>
          {selectProps.data.map((item: SelectItem) => {
            return (
              <UnstyledButton tabIndex={-1} key={item.value}>
                <Checkbox
                  size="xs"
                  label={item.label}
                  checked={selectedValues.has(item.value)}
                  onClick={() => onSelect(item.value)}
                />
              </UnstyledButton>
            );
          })}
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
});
