import React from 'react';
import {
  Card,
  Group,
  Text,
  Menu,
  ActionIcon,
  Button,
  Badge,
  Skeleton,
} from '@mantine/core';
import {
  IconDots,
  IconEye,
  IconTrash,
  IconArrowRight,
  IconEdit,
} from '@tabler/icons';
import { Link } from 'react-router-dom';
import DashboardModel from '../models/DashboardModel';
import { withTranslation, WithTranslation } from 'react-i18next';

import DatasetModel from '../models/DatasetModel';
import DatasetAPI from '../apis/DatasetAPI';

interface DashboardListItemComponentProps extends WithTranslation {
  dashboard: DashboardModel;
}

class DashboardListItemComponent extends React.Component<DashboardListItemComponentProps> {
  constructor(props: DashboardListItemComponentProps) {
    super(props);
  }

  getDisplayNameFromDashboardType(dashboardType: string): string {
    console.log(dashboardType);
    switch (dashboardType) {
      case 'summary-dashboard':
        return this.props.t(
          'dashboards-list.dashboard-card.dashboard-types.summary'
        );
      case 'report':
        return this.props.t(
          'dashboards-list.dashboard-card.dashboard-types.report'
        );
      case 'chart':
        return this.props.t(
          'dashboards-list.dashboard-card.dashboard-types.chart'
        );
      default:
        return this.props.t(
          'dashboards-list.dashboard-card.dashboard-types.unknown'
        );
    }
  }

  getDashboardTypeColor(dashboardType: string): string {
    switch (dashboardType) {
      case 'summary-dashboard':
        return 'grape';
      case 'report':
        return 'green';
      case 'chart':
        return 'red';
      default:
        return 'gray';
    }
  }

  render() {
    return (
      <Card withBorder shadow="sm" radius="md">
        <Card.Section withBorder inheritPadding py="xs">
          <Group position="apart">
            <Text weight={500}>{this.props.dashboard.title}</Text>

            <Group>
              <Badge
                color={this.getDashboardTypeColor(
                  this.props.dashboard.dashboardType
                )}
                size="sm"
                variant="light"
              >
                {this.getDisplayNameFromDashboardType(
                  this.props.dashboard.dashboardType
                )}
              </Badge>
              {/* <Menu withinPortal position="bottom-end" shadow="sm">
                            <Menu.Target>
                                <ActionIcon>
                                    <IconDots size={16} />
                                </ActionIcon>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Item icon={<IconEdit size={14} />}>{this.props.t("dashboards-list.dashboard-card.options.edit-text")}</Menu.Item>
                                <Menu.Item icon={<IconEye size={14} />}>{this.props.t("dashboards-list.dashboard-card.options.permissions-text")}</Menu.Item>
                                <Menu.Item icon={<IconTrash size={14} />} color="red">{this.props.t("dashboards-list.dashboard-card.options.delete-text")}</Menu.Item>
                            </Menu.Dropdown>
                        </Menu> */}
            </Group>
          </Group>
        </Card.Section>

        <Text mt="sm" color="dimmed" size="md">
          {this.props.dashboard.description}
        </Text>
        <br />
        <Group position="apart">
          <Group>
            {/* <Stack spacing="xs">
                        <Text mt="xs" color="dimmed" size="sm">Created</Text>
                        <Text mt="xs" size="sm">{this.props.dashboard.createdAt.toLocaleDateString()}</Text>
                    </Stack> */}
          </Group>
          <Link to={`${this.props.dashboard.dashboardId}`}>
            <Button
              size="xs"
              rightIcon={<IconArrowRight size={16} />}
              variant="light"
            >
              {this.props.t('dashboards-list.dashboard-card.view-button-text')}
            </Button>
          </Link>
        </Group>
      </Card>
    );
  }
}

class DashboardListItemComponentSkeleton extends React.Component {
  render() {
    return (
      <Card withBorder shadow="sm" radius="md">
        <Card.Section withBorder inheritPadding py="xs">
          <Group position="apart">
            {/* <Text weight={500}>{this.props.dashboard.title}</Text> */}
            <Skeleton height={8} mt={6} radius="xl" />
          </Group>
        </Card.Section>
        <br />
        <Skeleton height={8} mt={6} radius="xl" />
        <Skeleton height={8} mt={6} radius="xl" />
        <Skeleton height={8} mt={6} radius="xl" />
      </Card>
    );
  }
}

export default withTranslation('dashboards')(DashboardListItemComponent);
export { DashboardListItemComponentSkeleton };
