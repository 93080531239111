import CRN from './Crn';
import APIDecodable from './APIDecodable';

class UserInviteModel implements APIDecodable {
  organizationId: number;
  inviteId: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  targetGroups: CRN[];
  issuer: CRN;
  associatedRole: CRN;

  dateAdded: Date;

  constructor(
    organizationId: number,
    inviteId: string,
    username: string,
    email: string,
    firstName: string,
    lastName: string,
    targetGroups: CRN[],
    issuer: CRN,
    associatedRole: CRN,
    dateAdded: Date
  ) {
    this.organizationId = organizationId;
    this.inviteId = inviteId;
    this.username = username;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.targetGroups = targetGroups;
    this.issuer = issuer;
    this.associatedRole = associatedRole;

    this.dateAdded = dateAdded;
  }

  static fromAPIResponse(apiResponse: any): UserInviteModel {
    const organizationId = apiResponse.OrganizationId;
    const inviteId = apiResponse.InviteId;
    const username = apiResponse.Username;
    const email = apiResponse.Email;
    const firstName = apiResponse.FirstName;
    const lastName = apiResponse.LastName;
    const targetGroups = apiResponse.TargetGroups.forEach((group: string) => {
      return CRN.fromString(group);
    });
    const issuer = CRN.fromString(apiResponse.Issuer);
    const associatedRole = CRN.fromString(apiResponse.AssociatedRole);

    const dateAdded = apiResponse.DateAdded;

    return new UserInviteModel(
      organizationId,
      inviteId,
      username,
      email,
      firstName,
      lastName,
      targetGroups,
      issuer,
      associatedRole,
      dateAdded
    );
  }

  get crn(): CRN {
    return new CRN(
      this.organizationId,
      'governance',
      'user-invites',
      this.inviteId
    );
  }
}

export default UserInviteModel;
