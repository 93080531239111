import { useState, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';

import { useLocation } from 'react-router-dom';
import APIRequesterBase from '../apis/APIBase';

export const useAuthenticatedRoleToken = () => {
  const [hasRoleToken, setHasRoleToken] = useState(false);

  let location = useLocation();

  // NOTE: - Hotfix for HEB shared dashboards...
  // - This is a temporary solution for
  // Check if the pathname matches this pattern: /dashboards/90210/d-ml8x78l2mlnj
  // The first part is dashboards
  // The second part is a number
  // the third part is a string that starts with d-
  // If it does, then we need to redirect the user to the new path...
  if (location.pathname.match(/\/dashboards\/\d+\/d-/)) {
    // Redirect the user to the new path...
    // get the organization id
    let organizationId = location.pathname.split('/')[2];
    // get the dashboard id
    let dashboardId = location.pathname.split('/')[3];

    // redirect the user to the new path...
    // get the current domain from location...
    const origin = window.location.origin;
    window.location.href = `${origin}/dashboards/${dashboardId}${location.search}&organizationId=${organizationId}`;
  }

  useEffect(() => {
    // Check to see if we have a parameters called rtId. If we do, then we can use the rtId in APIBase to authenticate our calls.
    const urlParams = new URLSearchParams(location.search);
    const rtId = urlParams.get('rtId');
    const rtidLower = urlParams.get('rtid');
    const organizationId = urlParams.get('organizationId');

    if (rtId) {
      // We have a role token, so we can use it to authenticate our calls.
      setHasRoleToken(true);
      APIRequesterBase.configure({
        roleToken: rtId,
      });
    } else if (rtidLower) {
      // We have a role token, so we can use it to authenticate our calls.
      setHasRoleToken(true);
      APIRequesterBase.configure({
        roleToken: rtidLower,
      });
    }

    if (organizationId) {
      APIRequesterBase.configure({
        organizationId: parseInt(organizationId),
      });
    }
  }, [location]);

  return hasRoleToken;
};
