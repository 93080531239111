import React, { useEffect, useMemo, useState } from 'react';
import * as echarts from 'echarts';
import {
  BenchmarkDataPoint,
  BenchmarkDataPointChartType,
} from './datamodels/BenchmarkDataPoint';
import { ChartBaseProps } from './ChartBase';
import { Center, useMantineTheme } from '@mantine/core';
import darkTheme from './themes/dark-theme.json';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { useAnalysisStore } from '@stores/AnalysisStore';
import { userFriendlyTopicThemeName } from '@utils/TopicUtils';
import APIErrorMessage from '@components/APIErrorMessage';
import { useCurrentAnalysis } from '@hooks/useCurrentAnalysis';
import { useTopicStore } from '@stores/TopicStore';
import { useCurrentSummary } from '@hooks/useCurrentSummary';
import { useSummaryData } from '@apis/hooks/useSummaryData';
import { useElementSize } from '@mantine/hooks';

echarts.registerTheme('dark', darkTheme);

const BreakdownByPopulationPlot: React.FC = (props) => {
  const theme = useMantineTheme();
  const rawData = useAnalysisStore((s) => s.data);
  const isLoadingData = useAnalysisStore((s) => s.isLoadingData);
  const activeTopics = useAnalysisStore((s) => s.activeTopics);
  const activeThemes = useAnalysisStore((s) => s.activeThemes);
  const allPopulations = useAnalysisStore((s) => s.allPopulations);
  const focalPopulation = useAnalysisStore((s) => s.focalPopulation);
  const setFocalPopulation = useAnalysisStore((s) => s.setFocalPopulation);
  const numberOfMentions = useAnalysisStore((s) => s.numberOfMentions);
  const currentAnalysis = useCurrentAnalysis();
  const currentSummary = useCurrentSummary();
  const { data: _summaryData } = useSummaryData(currentSummary);
  const [ chartInstance, setChartInstance ] = useState<echarts.EChartsType | undefined>();
  const { ref: referenceComponent, width, height } = useElementSize();
  useEffect(() => chartInstance?.resize(), [width, height])
  
  const summaryData = useMemo(() => {
    const analisys = (currentSummary?.analyses ?? []).find(a => a.id === currentAnalysis.id);
    if(!currentSummary || !analisys || analisys.focalPopulation !== focalPopulation) {
      return null
    }
    return _summaryData;
  }, [currentAnalysis?.id, currentSummary, focalPopulation,  _summaryData])
  
  //Topic Store
  const selectedTopic = useTopicStore((s) => s.focalTopic);

  // ERRORS -
  const isBenchmarkError = useAnalysisStore((s) => s.dataIsError);
  const benchmarkError = useAnalysisStore((s) => s.dataError);

  const data: BenchmarkDataPoint[] = useMemo(() => {
    return rawData
      .filter((dataPoint: BenchmarkDataPoint) => {
        return (
          activeTopics.includes(dataPoint.topic) ||
          activeThemes.includes(dataPoint.topic)
        );
      })
      .filter((point: BenchmarkDataPoint) => point.topic === selectedTopic);
  }, [
    rawData,
    activeTopics,
    activeThemes,
    allPopulations,
    numberOfMentions,
    focalPopulation,
    selectedTopic,
  ]);

  const isEmptyData = data.length === 0;
  const title = `Breakdown for ${userFriendlyTopicThemeName(
    selectedTopic
  )} by population`;

  useEffect(() => {
    const chartElement = document.getElementById('myChart');
    if (!chartElement) {
      return;
    }
    const chart = echarts.init(chartElement, theme.colorScheme);

    if (isEmptyData && !isLoadingData) {
      chart.setOption({
        title: {
          text: 'No Data Available',
          subtext:
            'This may be due to a filter being applied, or because the data is not available yet',
          top: 'center',
          textStyle: {
            fontSize: 20,
          },
          subtextStyle: {
            fontSize: 16,
          },
        },
      });
    } else {
      const option: any = {
        xAxis: {
          name: 'Percentage of Employees Mentioning',
          nameTextStyle: {
            fontWeight: 'bold',
          },
          nameGap: 20,
          nameLocation: 'middle',
          axisLabel: {
            color: 'black',
            formatter: (value: number) => {
              return Math.floor(100 * value) + '%';
            },
          },
        },
        yAxis: {
          name: 'Percentage of Mentions Favorable',
          nameTextStyle: {
            fontWeight: 'bold',
          },
          nameGap: 40,
          nameLocation: 'middle',
          axisLabel: {
            color: 'black',
            formatter: (value: number) => {
              return Math.floor(100 * value) + '%';
            },
          },
        },
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'inside'
          }
        ],
        series: [
          {
            type: 'scatter',
            data: BenchmarkDataPoint.toChartDataPoints(
              data,
              BenchmarkDataPointChartType.IncidenceXSentimentY
            ),
            symbolSize: (value: any) => {
              const formattedPoint = BenchmarkDataPoint.fromChartDataPoint(
                value,
                BenchmarkDataPointChartType.IncidenceXSentimentY
              );
              return formattedPoint.populationName === focalPopulation
                ? 25
                : 15;
            },

            symbol: (value: any) => {
              const formattedPoint = BenchmarkDataPoint.fromChartDataPoint(
                value,
                BenchmarkDataPointChartType.IncidenceXSentimentY
              );
              return formattedPoint.populationName === focalPopulation
                ? 'diamond'
                : 'circle';
            },

            itemStyle: {
              color: (param: any) => {
                const formattedPoint = BenchmarkDataPoint.fromChartDataPoint(
                  param.value,
                  BenchmarkDataPointChartType.IncidenceXSentimentY
                );
                const value = param.data[15] ?? 0; //Benchmark value
                if(value >=2) {
                  return "#00783a";
                } else if(value >=0.5) {
                  return "#51ad5c";
                } else if(value >=-0.5) {
                  return "#fbcf4f";
                } else if(value >-2) {
                  return "#ff6e3b";
                } else if(value <=-2) {
                  return "#cf0839";
                }
              },
            },

            label: {
              show: true,
              formatter: (values: any) => {
                const formattedPoint = BenchmarkDataPoint.fromChartDataPoint(
                  values.data,
                  BenchmarkDataPointChartType.IncidenceXSentimentY
                );
                return formattedPoint.populationName;
              },
              position: 'right',
              margin: 10,
            },
            selectedMode: 'single',
            labelLayout: {
              hideOverlap: true,
            },
            labelLine: {
              show: true,
              length2: 5,
              lineStyle: {
                color: '#bbb'
              }
            },

            select: {
              itemStyle: {
                borderWidth: 3,
                borderColor: 'black',
              },
            },

            emphasis: {
              disabled: true,
              focus: 'self',
              scale: false,
            },
          },
        ],
        toolbox: {
          show: true,
          feature: {
            dataZoom: {},
            restore: {},
            saveAsImage: {
              pixelRatio: 4,
            },
          },
        },
        brush: {
          toolbox: ['rect', 'clear'],
          transformable: false,
          saveAsImage: {},
          inBrush: {
            opacity: 1,
            borderRadius: 3,
            borderColor: 'black',
          },
          outOfBrush: {
            color: 'gray',
            opacity: 0.2,
          },
        },

        tooltip: {
          trigger: 'item',
          formatter: (params: any) => {
            // Format the tooltip to have the following:
            // 1. The name of the population
            // 2. The percentage of employees mentioning the population
            // 3. The percentage of mentions favorable

            // Get the data
            const data = params.data;
            const formattedPoint = BenchmarkDataPoint.fromChartDataPoint(
              data,
              BenchmarkDataPointChartType.IncidenceXSentimentY
            );

            let summaryText = ""
            const summaryTopicData = (summaryData ?? []).find(d => d.topic === formattedPoint.topic.toLowerCase())
            if(summaryTopicData) {
              summaryText = `
              <div>
                  <br/>
                  <div><b>Values in component analyses</b></div>
                  ${
                    Object.keys(summaryTopicData).map(k => {
                      if(k !== "topic" && k !== "prioritize") {
                        const result = `<div>${(summaryTopicData[k] as any).name}: <b>${(summaryTopicData[k] as any).value?.toFixed(1)}</b></div>`
                        if(k === currentAnalysis?.id) {
                          return `<b>${result}</b>`
                        }
                        return result
                      }
                      return null
                    }).filter(a => a !== null).join("")
                  }
              </div>`
            }
            
            // Return as a HTML box with the data inside
            return `
                        <div>
                            <div><b>${
                              formattedPoint.topicUserFriendlyName
                            }</b></div>
                            <div>Benchmark: <b>${formattedPoint.benchmarkScore.toLocaleString(
                              undefined,
                              { maximumFractionDigits: 1 }
                            )}</b></div>
                            <div>Population: <b>${
                              formattedPoint.populationName
                            }</b></div>
                            <div># Responses: <b>${formattedPoint.rowCount.toLocaleString()}</b></div>
                            <div># Mentions of Topic: <b>${formattedPoint.vopicCount.toLocaleString()}</b></div>
                            <div>Incidence: <b>${formattedPoint.incidence.toLocaleString(
                              undefined,
                              {
                                style: 'percent',
                                maximumFractionDigits: 1,
                              }
                            )}</b></div>
                            <div>Sentiment: <b>${formattedPoint.sentiment.toLocaleString(
                              undefined,
                              {
                                style: 'percent',
                                maximumFractionDigits: 1,
                              }
                            )}</b></div>
                        </div>
                    ` + summaryText;
          },
          borderColor: '#ffffff',
          padding: 4,
          extraCssText: `
                    box-shadow: 0 0 3px rgba(0, 0, 0, 0.10);
                    border-radius: 2px;
                `,
        },
        interactive: true,
        animationEasing: 'elasticOut',
        animationDelay: 150,
        title: [
          {
            text: title ? title : 'Focal Topic broken down by Population',
            x: 'center',
            top: '2%',
            // textAlign: 'center',
            textStyle: {
              fontSize: 16,
            },
          },
        ],
      };

      // Get the chart element
      chart.setOption(option);

      // Add an onClick event to the chart...
      chart.on('click', (params: any) => {
        if (params.componentType === 'series') {
          const selectedPoint = params.value;
          const formattedSelectedPoint = BenchmarkDataPoint.fromChartDataPoint(
            selectedPoint,
            BenchmarkDataPointChartType.IncidenceXSentimentY
          );

          setFocalPopulation(formattedSelectedPoint.populationName);
        }
      });

      chart.on('brushEnd', (params: any) => {
        if (params.areas.length === 0) {
          // onPointsDeselected && onPointsDeselected();
          return;
        }

        const selectedRange = params.areas[0].range;
        const seriesIndex = 0;
        const chartOptions = chart.getOption();
        if (chartOptions && chartOptions.series) {
          const dataPoints: any[] | undefined =
            chartOptions.series[seriesIndex].data;
          const selectedData: BenchmarkDataPoint[] = [];

          if (dataPoints) {
            const selectedDataPoints: any[] = [];
            for (let i = 0; i < dataPoints?.length; i++) {
              const pixelCoord = chart.convertToPixel(
                { seriesIndex: seriesIndex },
                dataPoints[i]
              );
              const xCoord = pixelCoord[0];
              const yCoord = pixelCoord[1];
              const dataPoint = dataPoints[i];
              if (
                xCoord >= selectedRange[0][0] &&
                xCoord <= selectedRange[0][1] &&
                yCoord >= selectedRange[1][0] &&
                yCoord <= selectedRange[1][1]
              ) {
                const formattedDataPoint =
                  BenchmarkDataPoint.fromChartDataPoint(
                    dataPoint,
                    BenchmarkDataPointChartType.IncidenceXSentimentY
                  );
                selectedDataPoints.push(dataPoint);
                selectedData.push(formattedDataPoint);
              }
            }

            // addExcludeKeepGraphicToRange(chart, selectedRange, selectedDataPoints);
          }

          // Return the points to the caller
          // onPointsSelected && onPointsSelected(selectedData);
        }
      });

      chart.getZr().on('click', (event: any) => {
        if (!event.target && event.componentType !== 'graphic') {
          // Call internal method to update the chart such that there are no selected points
          chart.dispatchAction({
            type: 'unselect',
            seriesIndex: 0,
            dataIndex: Array.from({ length: data.length }, (v, i) => i),
          });

          // Remove any graphic from the chart
          // removeExcludeKeepGraphic(chart);

          // Call function to update that no points are selected
          // onPointsDeselected && onPointsDeselected();
        }
      });

      if (isLoadingData === true) {
        chart.showLoading('default', {
          text: 'Loading...',
          fontSize: 16,
          color: '#4589df',
          textColor: '#000',
          zlevel: 0,
        });
      } else {
        chart.hideLoading();
      }
    }

    setChartInstance(chart);

    return () => {
      setChartInstance(undefined);
      chart.dispose();
    };
  }, [data, theme.colorScheme, isEmptyData, isLoadingData]);

  if (isLoadingData === false && isBenchmarkError && benchmarkError) {
    return (
      <Center style={{ height: '100%' }}>
        <APIErrorMessage response={benchmarkError} />
      </Center>
    );
  }

  return <div ref={referenceComponent} id="myChart" style={{ width: '100%', height: '100%' }}></div>;
};

export default BreakdownByPopulationPlot;
