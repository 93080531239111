import CRN from './Crn';

interface ExternalBenchmarkSettings {
  showPopulationNames: boolean;
  showRowCounts: boolean;
}

export class ExternalBenchmarkModel {
  organizationId: number;
  benchmarkId: string;
  title: string;
  description: string;
  principal: CRN;
  datasets: any;
  populations: any;
  globalFilter: any;
  settings: ExternalBenchmarkSettings;
  dateAdded: Date;
  dateUpdated: Date;

  constructor(
    organizationId: number,
    benchmarkId: string,
    title: string,
    description: string,
    principal: CRN,
    datasets: any,
    populations: any,
    globalFilter: any,
    settings: ExternalBenchmarkSettings,
    dateAdded: Date,
    dateUpdated: Date
  ) {
    this.organizationId = organizationId;
    this.benchmarkId = benchmarkId;
    this.title = title;
    this.description = description;
    this.principal = principal;
    this.datasets = datasets;
    this.populations = populations;
    this.globalFilter = globalFilter;
    this.settings = settings;
    this.dateAdded = dateAdded;
    this.dateUpdated = dateUpdated;
  }

  static fromAPIResponse(response: any): ExternalBenchmarkModel {
    const organizationId = response['OrganizationId'];
    const benchmarkId = response['BenchmarkId'];
    const title = response['Title'];
    const description = response['Description'];
    const principal = CRN.fromString(response['PrincipalCRN']);
    const datasets = response['Datasets'];
    const populations = response['Populations'];
    const globalFilter = response['GlobalFilter'];
    const settingsObj = response['Settings'];
    const settings: ExternalBenchmarkSettings = {
      showPopulationNames: settingsObj['ShowPopulationNames'],
      showRowCounts: settingsObj['ShowRowCounts'],
    };
    const dateAdded = new Date(response['DateAdded']);
    const dateUpdated = new Date(response['DateUpdated']);
    return new ExternalBenchmarkModel(
      organizationId,
      benchmarkId,
      title,
      description,
      principal,
      datasets,
      populations,
      globalFilter,
      settings,
      dateAdded,
      dateUpdated
    );
  }
}

export class ExternalBenchmarkDescribedModel {
  organizationId: number;
  benchmarkId: string;
  title: string;
  description: string;
  populationNames: string[];
  dateAdded: Date;
  dateUpdated: Date;

  constructor(
    organizationId: number,
    benchmarkId: string,
    title: string,
    description: string,
    populationNames: string[],
    dateAdded: Date,
    dateUpdated: Date
  ) {
    this.organizationId = organizationId;
    this.benchmarkId = benchmarkId;
    this.title = title;
    this.description = description;
    this.populationNames = populationNames;
    this.dateAdded = dateAdded;
    this.dateUpdated = dateUpdated;
  }

  static fromAPIResponse(response: any): ExternalBenchmarkDescribedModel {
    const organizationId = response['OrganizationId'];
    const benchmarkId = response['BenchmarkId'];
    const title = response['Title'];
    const description = response['Description'];
    const populationNames = response['Populations'];
    const dateAdded = new Date(response['DateAdded']);
    const dateUpdated = new Date(response['DateUpdated']);
    return new ExternalBenchmarkDescribedModel(
      organizationId,
      benchmarkId,
      title,
      description,
      populationNames,
      dateAdded,
      dateUpdated
    );
  }
}
