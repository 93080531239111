import { Select, SelectItem } from '@mantine/core';
import { useMemo } from 'react';
import {
  userFriendlyTopicName,
  userFriendlyTopicThemeName,
} from '@utils/TopicUtils';
import { useTopicStore } from '@stores/TopicStore';
import { TopicSelect } from '@components/TopicSelect';

export const FocalTopicFilter: React.FC = () => {
  const focalTopic = useTopicStore((s) => s.focalTopic);
  const setFocalTopic = useTopicStore((s) => s.setFocalTopic);

  return (
    <TopicSelect
      autoFocus
      hoverOnSearchChange
      size="xs"
      placeholder="Focal Topic"
      searchable
      value={focalTopic}
      onChange={setFocalTopic}
    />
  );
};