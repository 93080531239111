import React from 'react';

import MantineWidgets from '../widgets';

import { Utils, BasicConfig } from '@react-awesome-query-builder/ui';

const {
  MantineSelectWidget,
  MantineFieldSelectWidget,
  MantineMultiSelectWidget,
  MantineNumberInputWidget,
  MantineDateWidget,
  MantineButtonWidget,
  MantineConjunctionsWidget,
  MantineTextInputWidget

} = MantineWidgets;
// const settings = {...BasicConfig.settings}

const settings: any = {
  ...BasicConfig.settings,

  // renderField: (props: any) => props?.customProps?.showSearch
  //   ? <MuiFieldAutocomplete {...props} />
  //   : <MuiFieldSelect {...props} />,
  renderField: (props: any) => {
    //console.log(props);
    return <MantineFieldSelectWidget {...props} />;
  },
  renderOperator: (props: any) => (
    <MantineFieldSelectWidget operator {...props} />
  ),
  renderFunc: (props: any) => <MantineFieldSelectWidget {...props} />,
  renderConjs: (props: any) => <MantineConjunctionsWidget {...props} />,
  // renderSwitch: (props) => <MuiSwitch {...props} />,
  renderButton: (props: any) => <MantineButtonWidget {...props} />,
  // renderButtonGroup: (props) => <MuiButtonGroup {...props} />,
  // renderValueSources: (props) => <MuiValueSources {...props} />,
  // renderProvider: (props) => <MuiProvider {...props} />,
  // renderConfirm: MuiConfirm,
  // useConfirm: MuiUseConfirm,
};

const widgets = {
  ...BasicConfig.widgets,
  select: {
    ...BasicConfig.widgets.select,
    factory: (props: any) => {
      return <MantineSelectWidget {...props} />;
    },
  },
  multiselect: {
    ...BasicConfig.widgets.multiselect,
    factory: (props: any) => {
      //console.log(props)
      return <MantineMultiSelectWidget {...props} />;
    },
  },
  date: {
    ...BasicConfig.widgets.date,
    factory: (props: any) => {
      console.log('Rendering from config');
      return <MantineDateWidget {...props} />;
    },
  },

  datetime: {
    ...BasicConfig.widgets.datetime,
    factory: (props: any) => {
      console.log('Rendering from config');
      return <MantineDateWidget {...props} />;
    },
  },

  // time: {
  //   ...BasicConfig.widgets.time,
  //   factory: (props: any) => {
  //     return (<MantineDateWidget {...props} />)
  //   }
  // },

  number: {
    ...BasicConfig.widgets.number,
    factory: (props: any) => {
      return <MantineNumberInputWidget {...props} />;
    },
  },
  text: {
    ...BasicConfig.widgets.text,
    factory: (props: any) => {
      return <MantineTextInputWidget {...props} />;
    },
  }
};

export default {
  ...BasicConfig,
  settings,
  widgets,
};
