export enum BenchmarkDataPointChartType {
  IncidenceXSentimentY = 'IncidenceYSentimentX',
  IncidenceZXSentimentZY = 'IncidenceZXSentimentZY',
  BenchmarkPopulationTopic = 'BenchmarkPopulationTopic',
}

export class BenchmarkDataPoint {
  /**
   * `benchmarkName` is a string that represents the name of the benchmark the population belongs to.
   */
  public benchmarkName: string;

  /**
   * `populationName` is a string that represents the name of the population the datapoint belongs to.
   */
  public populationName: string;

  /**
   * `topic` is the name of the topic the datapoint belongs to.
   */
  public topic: string;

  /**
   * `isTheme` is a boolean that represents whether the topic is a theme or not.
   */
  public isTheme: boolean;

  /**
   * `topicUserFriendlyName` is the user friendly name of the topic the datapoint belongs to.
   * This is used to display the topic name in the chart. Will remove special characters and replace with spaces.
   * Will also maintain the capitalization of the first letter of each word.
   * Example: "Collaboration_General" will be displayed as "Collaboration General"
   * NOTE: - Not to be used with the API or anywhere else other than the chart.
   */
  public topicUserFriendlyName: string;

  /**
   * `incidence` is the incidence of the topic within the population. Represented as a percentage in decimal form..
   */
  public incidence: number;

  /**
   * `sentiment` is the sentiment of the topic within the population. Represented as a percentage in decimal form.
   */
  public sentiment: number;

  /**
   * `rowCount` is the number of rows that the population has.
   */
  public rowCount: number;

  /**
   * `vopicCount` is the number of rows that the population has that contain the topic / value.
   */
  public vopicCount: number;

  /**
   * `positiveIncidence` is the number of rows that the population has that contain the topic / value and have a positive sentiment.
   * Calculated as (vopicCount * sentiment) / rowCount.
   */
  public positiveIncidence: number;

  /**
   * `negativeIncidence` is the number of rows that the population has that contain the topic / value and have a negative sentiment.
   * Calculated as (vopicCount * (1 - sentiment)) / rowCount.
   */
  public negativeIncidence: number;

  /**
   * `incidenceZ` is the z-score of the incidence of the topic-population against the benchmark.
   */
  public incidenceZ: number;

  /**
   * `sentimentZ` is the z-score of the sentiment of the topic-population against the benchmark.
   */
  public sentimentZ: number;

  /**
   * `positiveIncidenceZ` is the z-score of the positive incidence of the topic-population against the benchmark.
   */
  public positiveIncidenceZ: number;

  /**
   * `negativeIncidenceZ` is the z-score of the negative incidence of the topic-population against the benchmark.
   */
  public negativeIncidenceZ: number;

  /**
   * `benchmarkScore` is the composite score taking into account positive and negative incidence for a topic against the benchmark.
   * Defined as ( ( positive incidence – negative incidence ) – AVG( positive incidence – negative incidence in the benchmark) ) / Stdev.P (of positive incidence – negative incidences in the benchmark).
   */
  public benchmarkScore: number;

  constructor(
    benchmarkName: string,
    populationName: string,
    topic: string,
    isTheme: boolean,
    topicUserFriendlyName: string,
    incidence: number,
    sentiment: number,
    rowCount: number,
    vopicCount: number,
    positiveIncidence: number,
    negativeIncidence: number,
    incidenceZ: number,
    sentimentZ: number,
    positiveIncidenceZ: number,
    negativeIncidenceZ: number,
    benchmarkScore: number
  ) {
    this.benchmarkName = benchmarkName;
    this.populationName = populationName;
    this.topic = topic;
    this.isTheme = isTheme;
    this.topicUserFriendlyName = topicUserFriendlyName;
    this.incidence = incidence;
    this.sentiment = sentiment;
    this.rowCount = rowCount;
    this.vopicCount = vopicCount;
    this.positiveIncidence = positiveIncidence;
    this.negativeIncidence = negativeIncidence;
    this.incidenceZ = incidenceZ;
    this.sentimentZ = sentimentZ;
    this.positiveIncidenceZ = positiveIncidenceZ;
    this.negativeIncidenceZ = negativeIncidenceZ;
    this.benchmarkScore = benchmarkScore;
  }

  // MARK: - Static Helper Functions

  /**
   * Creates a user friendly name for the topic.
   * Replaces special characters like "_" with spaces. And ensures that the first letter of each word is capitalized.
   * @param topic string to be converted to user friendly name
   * @returns userFriendlyName
   */
  public static userFriendlyTopicName(topic: string): string {
    let userFriendlyName = topic.replace(/_/g, ' ');
    if (topic.endsWith('_T') || topic.endsWith('_V')) {
      userFriendlyName = userFriendlyName.slice(0, -2);
      userFriendlyName = userFriendlyName.toUpperCase();
    } else {
      userFriendlyName = userFriendlyName.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
    return userFriendlyName;
  }

  // MARK: - Static Initializers for common use cases
  /**
   * Creates a BenchmarkDataPoint object from a JSON object.
   * @param columns array of strings that represent the column names of the data.
   * @param data array of strings, numbers or nulls that represent the data.
   */
  public static fromResponse(
    columns: string[],
    data: any[][]
  ): BenchmarkDataPoint[] {
    let benchmarkDataPoints: BenchmarkDataPoint[] = [];
    data.forEach((dataPointList: any[]) => {
      let benchmarkName: string = '';
      let populationName: string = '';
      let topic: string = '';
      let isTheme: boolean = false;
      let topicUserFriendlyName: string = '';
      let incidence: number = 0;
      let sentiment: number = 0;
      let rowCount: number = 0;
      let vopicCount: number = 0;
      let positiveIncidence: number = 0;
      let negativeIncidence: number = 0;
      let incidenceZ: number = 0;
      let sentimentZ: number = 0;
      let positiveIncidenceZ: number = 0;
      let negativeIncidenceZ: number = 0;
      let benchmarkScore: number = 0;

      dataPointList.forEach((dataPoint: any, index: number) => {
        const column = columns[index];
        switch (column) {
          case 'CX_BENCHMARK':
            benchmarkName = dataPoint;
            break;
          case 'CX_POPULATION':
            populationName = dataPoint;
            break;
          case 'topic':
            topic = dataPoint;
            topicUserFriendlyName =
              BenchmarkDataPoint.userFriendlyTopicName(dataPoint);
            break;
          case 'CX_IS_THEME':
            isTheme = dataPoint;
            break;
          case 'incidence_percent':
            incidence = dataPoint;
            break;
          case 'sentiment_percent':
            sentiment = dataPoint;
            break;
          case 'CX_ROW_COUNT':
            rowCount = dataPoint;
            break;
          case 'vopic_count':
            vopicCount = dataPoint;
            break;
          case 'positive_incidence':
            positiveIncidence = dataPoint;
            break;
          case 'negative_incidence':
            negativeIncidence = dataPoint;
            break;
          case 'incidence_z':
            incidenceZ = dataPoint;
            break;
          case 'sentiment_z':
            sentimentZ = dataPoint;
            break;
          case 'positive_incidence_z':
            positiveIncidenceZ = dataPoint;
            break;
          case 'negative_incidence_z':
            negativeIncidenceZ = dataPoint;
            break;
          case 'benchmark':
            benchmarkScore = dataPoint;
            break;
        }
      });

      const benchmarkDataPoint = new BenchmarkDataPoint(
        benchmarkName,
        populationName,
        topic,
        isTheme,
        topicUserFriendlyName,
        incidence,
        sentiment,
        rowCount,
        vopicCount,
        positiveIncidence,
        negativeIncidence,
        incidenceZ,
        sentimentZ,
        positiveIncidenceZ,
        negativeIncidenceZ,
        benchmarkScore
      );
      benchmarkDataPoints.push(benchmarkDataPoint);
    });

    return benchmarkDataPoints;
  }

  /**
   * Creates a BenchmarkDataPoint object from a chart data point.
   * @param chartDataPoint
   * @param type
   * @returns BenchmarkDataPoint
   */
  public static fromChartDataPoint(
    chartDataPoint: any,
    type: BenchmarkDataPointChartType
  ): BenchmarkDataPoint {
    switch (type) {
      case BenchmarkDataPointChartType.IncidenceXSentimentY:
        // chartDataPoint is an array where 0 is incidence and 1 is sentiment, and the rest of the array is the other data point information...
        return new BenchmarkDataPoint(
          chartDataPoint[2],
          chartDataPoint[3],
          chartDataPoint[4],
          chartDataPoint[5],
          chartDataPoint[6],
          chartDataPoint[0],
          chartDataPoint[1],
          chartDataPoint[7],
          chartDataPoint[8],
          chartDataPoint[9],
          chartDataPoint[10],
          chartDataPoint[11],
          chartDataPoint[12],
          chartDataPoint[13],
          chartDataPoint[14],
          chartDataPoint[15]
        );
      case BenchmarkDataPointChartType.BenchmarkPopulationTopic:
        return chartDataPoint.basePoint as BenchmarkDataPoint;
      case BenchmarkDataPointChartType.IncidenceZXSentimentZY:
        // Convert the point from this:
        return new BenchmarkDataPoint(
          chartDataPoint[2],
          chartDataPoint[3],
          chartDataPoint[4],
          chartDataPoint[5],
          chartDataPoint[6],
          chartDataPoint[11],
          chartDataPoint[12],
          chartDataPoint[7],
          chartDataPoint[8],
          chartDataPoint[9],
          chartDataPoint[12],
          chartDataPoint[0],
          chartDataPoint[1],
          chartDataPoint[13],
          chartDataPoint[14],
          chartDataPoint[15]
        );
    }
  }

  /**
   * Converts a list of chart data points to a list of BenchmarkDataPoints provided with the type of the chart data points.
   * @param chartDataPoints
   * @param type
   * @returns
   */
  public static fromChartDataPoints(
    chartDataPoints: any,
    type: BenchmarkDataPointChartType
  ): BenchmarkDataPoint[] {
    let dataPoints: BenchmarkDataPoint[] = [];
    for (let i = 0; i < chartDataPoints.length; i++) {
      dataPoints.push(this.fromChartDataPoint(chartDataPoints[i], type));
    }
    return dataPoints;
  }

  /**
   * Converts a list of BenchmarkDataPoints to a list of chart data points provided with the type of the chart data points.
   */
  public static toChartDataPoints(
    dataPoints: BenchmarkDataPoint[],
    type: BenchmarkDataPointChartType
  ): any[] {
    let chartDataPoints: any[] = [];
    for (let i = 0; i < dataPoints.length; i++) {
      chartDataPoints.push(dataPoints[i].toChartDataPoint(type));
    }
    return chartDataPoints;
  }

  // MARK: - Instance Methods
  /**
   *
   * @param type the type of chart data point to return
   * @returns data point for the chart
   */
  public toChartDataPoint(type: BenchmarkDataPointChartType): any[] {
    switch (type) {
      case BenchmarkDataPointChartType.IncidenceXSentimentY:
        // Return an array where 0 is incidence and 1 is sentiment, and the rest of the array is the other data point information...
        return [
          this.incidence,
          this.sentiment,
          this.benchmarkName,
          this.populationName,
          this.topic,
          this.isTheme,
          this.topicUserFriendlyName,
          this.rowCount,
          this.vopicCount,
          this.positiveIncidence,
          this.negativeIncidence,
          this.incidenceZ,
          this.sentimentZ,
          this.positiveIncidenceZ,
          this.negativeIncidenceZ,
          this.benchmarkScore,
        ];
      case BenchmarkDataPointChartType.BenchmarkPopulationTopic:
        return [
          this.incidence,
          this.sentiment,
          this.benchmarkName,
          this.populationName,
          this.topic,
          this.isTheme,
          this.topicUserFriendlyName,
          this.rowCount,
          this.vopicCount,
          this.positiveIncidence,
          this.negativeIncidence,
          this.incidenceZ,
          this.sentimentZ,
          this.positiveIncidenceZ,
          this.negativeIncidenceZ,
          this.benchmarkScore,
        ];
      case BenchmarkDataPointChartType.IncidenceZXSentimentZY:
        return [
          this.incidenceZ,
          this.sentimentZ,
          this.benchmarkName,
          this.populationName,
          this.topic,
          this.isTheme,
          this.topicUserFriendlyName,
          this.rowCount,
          this.vopicCount,
          this.positiveIncidence,
          this.negativeIncidence,
          this.incidence,
          this.sentiment,
          this.positiveIncidenceZ,
          this.negativeIncidenceZ,
          this.benchmarkScore,
        ];
    }
  }

  public valueOf(): string {
    return `${this.benchmarkName}::${this.populationName}::${this.topic}`;
  }
}
