import ProjectAPI from '@apis/ProjectAPI';
import {
  Divider,
  Modal,
  Stack,
  Title,
  Text,
  TextInput,
  Group,
  Button,
  Radio,
  Box,
  Card,
  Grid,
  Checkbox,
  Flex,
  MantineProvider,
  CopyButton,
  Tooltip,
  ActionIcon,
  rem,
} from '@mantine/core';
import ProjectModel from '@models/ProjectModel';
import { useCallback, useEffect, useState } from 'react';
import { DatePicker } from '@mantine/dates';
import { useCurrentDatasets } from '@hooks/useCurrentDatasets';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconCopy } from '@tabler/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Token } from '@models/SharedLinkModel';

interface CreateLinkModalProps {
  opened: boolean;
  onClose: () => void;
  onLinkCreated: () => void;
  project: ProjectModel;
}

export const CreateLinkModal = ({
  opened,
  onClose,
  onLinkCreated,
  project,
}: CreateLinkModalProps) => {
  const datasets = useCurrentDatasets();
  const [permissions, setPermissions] = useState('read-write');
  const [expirationDate, setExpirationDate] = useState<Date | null>(
    new Date(Date.now() + 86400000)
  ); // now + 1 day
  const [linkDontExpire, setLinkDontExpire] = useState(true);
  const [readData, setReadData] = useState(true);
  const [readQuotes, setReadQuotes] = useState(true);
  const [writeAnalyses, setWriteAnalyses] = useState(false);
  const [downloadData, setDownloadData] = useState(false);
  const [downloadQuotes, setDownloadQuotes] = useState(false);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (opened) {
      setPermissions('read-write');
      setExpirationDate(new Date(Date.now() + 86400000));
      setLinkDontExpire(true);
      setReadData(true);
      setReadQuotes(true);
      setWriteAnalyses(false);
      setDownloadData(false);
      setDownloadQuotes(false);
    }
  }, [opened]);

  const createLink = useCallback(async () => {
    setLoading(true);
    let response: Token;
    const expiration = linkDontExpire ? null : expirationDate.toISOString();
    switch (permissions) {
      case 'read-write':
        response = await ProjectAPI.createProjectSharedLink(
          ['Data', 'Quotes'],
          ['Analyses'],
          ['Data', 'Quotes'],
          expiration,
          project.projectId
        );
        break;
      case 'read':
        response = await ProjectAPI.createProjectSharedLink(
          ['Data', 'Quotes'],
          [],
          ['Data', 'Quotes'],
          expiration,
          project.projectId
        );
        break;
      default:
        const read = [
          readData ? 'Data' : null,
          readQuotes ? 'Quotes' : null,
        ].filter((i) => i !== null);
        const write = [writeAnalyses ? 'Analyses' : null].filter(
          (i) => i !== null
        );
        const download = [
          downloadData ? 'Data' : null,
          downloadQuotes ? 'Quotes' : null,
        ].filter((i) => i !== null);
        response = await ProjectAPI.createProjectSharedLink(
          read,
          write,
          download,
          expiration,
          project.projectId
        );
        break;
    }
    await queryClient.refetchQueries({
      queryKey: ['project-shared-links', project.projectId],
    });
    const link = `https://app.culturex.com/projects/${project.projectId}?organizationId=${response.OrganizationID}&rtId=${response.Token}`;
    notifications.show({
      id: 'link-created',
      color: 'green',
      title: 'Link created',
      message: (
        <Flex style={{ overflow: 'hidden', alignItems: 'center' }}>
          <Text weight={600} size={'xs'} truncate="end" pr="md">
            {link}
          </Text>
          <CopyButton value={link} timeout={2000}>
            {({ copied, copy }) => (
              <Button size="xs" color={copied ? 'teal' : 'blue'} onClick={copy}>
                {copied ? 'Copied url' : 'Copy url'}
              </Button>
            )}
          </CopyButton>
        </Flex>
      ),
      icon: <IconCheck size="1rem" />,
      autoClose: 10000,
    });
    setLoading(false);
    onLinkCreated();
  }, [
    project,
    permissions,
    readData,
    readQuotes,
    writeAnalyses,
    downloadData,
    downloadQuotes,
    linkDontExpire,
    expirationDate,
  ]);

  return (
    <Modal
      title={
        <Stack spacing={0}>
          <Title order={3}>{`Share ${project.title}`}</Title>
          <Text color="dimmed" size="sm">
            Create a link to share project data with others outside of your
            organization
          </Text>
        </Stack>
      }
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      size="lg"
    >
      <Stack>
        <Divider />
        <Stack spacing={0}>
          <Title order={4}>Permissions</Title>
          <Text color="dimmed" size="sm">
            Choose what recipients of this link can do
          </Text>
          <Grid grow mt={0}>
            <Grid.Col span={6}>
              <Card
                withBorder
                p={5}
                pb="xs"
                style={{
                  height: '100%',
                  cursor: 'pointer',
                  borderColor:
                    permissions === 'read-write' ? '#228be6' : undefined,
                }}
                bg="#f6f6f6"
                onClick={() => setPermissions('read-write')}
              >
                <Group spacing={8}>
                  <Radio size="xs" checked={permissions === 'read-write'} />
                  <Title style={{ lineHeight: '20px' }} order={5}>
                    Read / Write
                  </Title>
                </Group>
                <Text
                  ml="xl"
                  style={{ lineHeight: 1.2 }}
                  mt={5}
                  color="dimmed"
                  size={'xs'}
                >
                  Recipients can view project data, quotes, and download data.
                  They may also edit the project to modify existing analyses,
                  create, as well as delete analyses.
                </Text>
                <Text
                  ml="xl"
                  style={{ lineHeight: 1.2 }}
                  mt={5}
                  color="dimmed"
                  size={'xs'}
                >
                  They cannot delete the project.
                </Text>
              </Card>
            </Grid.Col>
            <Grid.Col span={6}>
              <Card
                withBorder
                p={5}
                pb="xs"
                style={{
                  height: '100%',
                  cursor: 'pointer',
                  borderColor: permissions === 'read' ? '#228be6' : undefined,
                }}
                bg="#f6f6f6"
                onClick={() => setPermissions('read')}
              >
                <Group spacing={8}>
                  <Radio size="xs" checked={permissions === 'read'} />
                  <Title style={{ lineHeight: '20px' }} order={5}>
                    Read
                  </Title>
                </Group>

                <Text
                  ml="xl"
                  style={{ lineHeight: 1.2 }}
                  mt={5}
                  color="dimmed"
                  size={'xs'}
                >
                  Recipients can view project data, quotes, and download data.
                </Text>
                <Text
                  ml="xl"
                  style={{ lineHeight: 1.2 }}
                  mt={5}
                  color="dimmed"
                  size={'xs'}
                >
                  They cannot delete the project and the may not modify any
                  anlyses or create new ones.
                </Text>
              </Card>
            </Grid.Col>
            <Grid.Col span={12}>
              <Card
                withBorder
                p={5}
                pb="xs"
                style={{
                  height: '100%',
                  cursor: 'pointer',
                  borderColor: permissions === 'custom' ? '#228be6' : undefined,
                }}
                bg="#f6f6f6"
                onClick={() => setPermissions('custom')}
              >
                <Group spacing={8}>
                  <Radio size="xs" checked={permissions === 'custom'} />
                  <Title style={{ lineHeight: '20px' }} order={5}>
                    Custom
                  </Title>
                  <Text
                    style={{ lineHeight: 1.2 }}
                    color="dimmed"
                    pt={3}
                    size={'xs'}
                  >
                    Apply custom permissions. E.g. disallow quote downloads.
                  </Text>
                </Group>
                <Grid ml="md" grow mt={0}>
                  <Grid.Col span={4}>
                    <Text
                      size={'sm'}
                      c={permissions !== 'custom' ? 'dimmed' : undefined}
                      weight={600}
                    >
                      Read
                    </Text>
                    <Checkbox
                      mt={5}
                      size="xs"
                      disabled={permissions !== 'custom'}
                      label="Project Data"
                      checked={readData}
                      onChange={(event) =>
                        setReadData(event.currentTarget.checked)
                      }
                    />
                    <Checkbox
                      mt={5}
                      size="xs"
                      disabled={permissions !== 'custom'}
                      label="Quotes"
                      checked={readQuotes}
                      onChange={(event) =>
                        setReadQuotes(event.currentTarget.checked)
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Text
                      size={'sm'}
                      c={permissions !== 'custom' ? 'dimmed' : undefined}
                      weight={600}
                    >
                      Write
                    </Text>
                    <Checkbox
                      mt={5}
                      size="xs"
                      disabled={permissions !== 'custom'}
                      label="Analyses"
                      checked={writeAnalyses}
                      onChange={(event) =>
                        setWriteAnalyses(event.currentTarget.checked)
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Text
                      size={'sm'}
                      c={permissions !== 'custom' ? 'dimmed' : undefined}
                      weight={600}
                    >
                      Download
                    </Text>
                    <Checkbox
                      mt={5}
                      size="xs"
                      disabled={permissions !== 'custom'}
                      label="Project Data"
                      checked={downloadData}
                      onChange={(event) =>
                        setDownloadData(event.currentTarget.checked)
                      }
                    />
                    <Checkbox
                      mt={5}
                      size="xs"
                      disabled={permissions !== 'custom'}
                      label="Quotes"
                      checked={downloadQuotes}
                      onChange={(event) =>
                        setDownloadQuotes(event.currentTarget.checked)
                      }
                    />
                  </Grid.Col>
                </Grid>
              </Card>
            </Grid.Col>
          </Grid>
        </Stack>
        <Divider />
        <Flex gap={0}>
          <Stack mr="xs" spacing={0}>
            <Title order={4}>Expiration Date</Title>
            <Text color="dimmed" size="sm">
              Set an automatic expiration date for this link. After the selected
              date, the link becomes unusable.
            </Text>
            <Checkbox
              mt="md"
              label="This link does not expire"
              checked={linkDontExpire}
              onChange={(v) => setLinkDontExpire(v.target.checked)}
            />
          </Stack>
          <div style={{ position: 'relative', width: 217 }}>
            {linkDontExpire && (
              <div style={{ position: 'absolute', inset: 0 }} />
            )}
            <MantineProvider
              theme={
                !linkDontExpire
                  ? undefined
                  : {
                      components: {
                        DatePicker: {
                          styles: {
                            day: { color: '#ced4da !important' },
                            weekday: { color: '#ced4da !important' },
                            yearsListCell: {
                              '& button': { color: '#ced4da !important' },
                            },
                            calendarHeaderLevel: {
                              color: '#ced4da !important',
                            },
                            calendarHeaderControl: {
                              color: '#ced4da !important',
                            },
                            monthsListCell: {
                              '& button': { color: '#ced4da !important' },
                            },
                          },
                        },
                      },
                    }
              }
            >
              <DatePicker
                size="xs"
                value={!linkDontExpire ? expirationDate : new Date(0)}
                onChange={setExpirationDate}
                minDate={new Date()}
              />
            </MantineProvider>
          </div>
        </Flex>
        <Divider />
        <Group position="apart">
          <Stack spacing={0}>
            <Text size="xs">
              Generating this link will give any recipients access to:
            </Text>
            {datasets.map((ds, i) => (
              <Text key={ds.alias + i} weight={600} size="xs">{`${i + 1}. ${ds.alias}`}</Text>
            ))}
          </Stack>
          <Group styles={{ flexGrow: 1 }}>
            <Button variant="subtle" color="dark" onClick={onClose}>
              Cancel
            </Button>
            <Button loading={loading} onClick={createLink}>
              Create Link
            </Button>
          </Group>
        </Group>
      </Stack>
    </Modal>
  );
};
