import React, {
    useCallback,
  } from 'react';
  import { useCurrentProject } from '@hooks/useCurrentProject';
  import { useCurrentSummary } from '@hooks/useCurrentSummary';
  import { InlineEditableText } from '@components/InlineEditableText';
  import { useUpdateProject } from '@apis/hooks/useUpdateProject';
  import { useQueryClient } from '@tanstack/react-query';
import { Flex } from '@mantine/core';
  
export const SummaryTitle: React.FC = () => {
    const project = useCurrentProject();
    const summary = useCurrentSummary();
    const queryClient = useQueryClient();

    const updateProject = useUpdateProject({
      onSuccess(data) {
        queryClient.setQueryData(['project', data.projectId], data);
      },
    });
  
    const setSummaryName = useCallback(
      (value: string) => {
        if (value !== summary.name && !updateProject.isPending) {
            summary.name = value;

          const updatedProject = {
            ...project,
            updatedSummaries: [{
              Id: summary.id,
              Name: value,
              Analyses: summary.analyses.map(a => ({
                AnalysisId: a.id,
                FocalPopulation: a.focalPopulation,
              })),
            }],
          };  
          updateProject.mutate(updatedProject);
        }
      },
      [project, summary, updateProject.isPending]
    );
  
    return (
      <Flex>
        <InlineEditableText
          editWithDoubleClick={false}
          textStyle={{
            fontWeight: 700,
            fontSize: '1.125rem',
            lineHeight: 1.55,
          }}
          tooltipIcon="Rename summary"
          loading={updateProject.isPending}
          value={summary.name}
          onChange={setSummaryName}
        />
      </Flex>
    );
    
};