import { Checkbox, Group } from '@mantine/core';
import React, { forwardRef } from 'react';

export interface SelectItemProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'value'> {
  label: React.ReactNode;
  value?: string;
  selected?: boolean;
  disabled: boolean;
}

export const DefaultItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ label, value, selected, disabled, ...others }: SelectItemProps, ref) => {
    // Remove the data-selected attribute from others to avoid a blue selected background
    delete others['data-selected'];
    return (
      <div ref={ref} {...others}>
        <Group>
          <Checkbox checked={selected} disabled={disabled} readOnly />
          {label || value}
        </Group>
      </div>
    );
  }
);

DefaultItem.displayName = '@mantine/core/DefaultItem';
