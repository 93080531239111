import React, { memo, useMemo } from 'react';
import {
  Text,
  Title,
  Group,
  Stack,
  ActionIcon,
  Tooltip,
  Popover,
  SimpleGrid,
  Anchor,
  Skeleton,
} from '@mantine/core';
import { MultiSelectCheckbox } from './MultiSelectCheckbox';
import { Quote, useAnalysisStore } from '@stores/AnalysisStore';
import { useProjectDatasets } from '@apis/hooks/useProjectDatasets';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { getAliasFromColumn } from '@utils/MetadataUtils';
import { useQuoteStore } from '@stores/QuoteStore';

export const QuoteReaderDemographicSelect: React.FC = () => {
  const currentProject = useCurrentProject();
  const { data: datasetsMetadata } = useProjectDatasets(currentProject);
  const activeQuoteDemographics = useQuoteStore(s => s.activeQuoteDemographics);
  const setActiveQuoteDemographics = useQuoteStore(s => s.setActiveQuoteDemographics);

  const options = useMemo(() => {
    const ops: any[] = [];
    if (datasetsMetadata === undefined) return null;

    datasetsMetadata.forEach((value: any, key: any) => {
      const selectOptions: any[] = [];

      const metadata = value;

      const columnsObjects =
        metadata.Content.Properties.ImmutableAttributes.Columns;
      const columns = columnsObjects.map(
        (colObject: any) => Object.keys(colObject)[0]
      );

      columns.forEach((col: any) => {
        const alias = getAliasFromColumn(col, metadata);
        var isFreeText = false;

        if (
          metadata.Content.Properties.MutableAttributes.Columns[col] ===
          undefined
        ) {
          isFreeText = false;
        } else {
          isFreeText =
            metadata.Content.Properties.MutableAttributes.Columns[col][
              'Variable Type'
            ] === 'Text';
        }

        if (!isFreeText) {
          selectOptions.push({ value: col, label: alias });
        }
      });

      const selectedItems = activeQuoteDemographics
        ? activeQuoteDemographics[key]
        : [];

      const selector = (
        <MultiSelectCheckbox
          label={value['DatasetAlias']}
          data={selectOptions}
          searchable
          defaultValue={selectedItems}
          onChange={(selected: any) => {
            const newDemographics = { ...activeQuoteDemographics };
            newDemographics[key] = selected;
            setActiveQuoteDemographics(newDemographics);
          }}
          maxSelectedValues={4}
        />
      );

      ops.push(selector);
    });
    return ops;
  }, [datasetsMetadata, activeQuoteDemographics]);

  return <Stack>{options}</Stack>;
};

interface QuoteReaderProfileComponentProps {
  quote?: Quote;
  loading: boolean;
}

const QuoteReaderProfileComponent: React.FC<
  QuoteReaderProfileComponentProps
> = ({ quote, loading }: QuoteReaderProfileComponentProps) => {
  const currentProject = useCurrentProject();
  const { data: datasetsMetadata } = useProjectDatasets(currentProject);
  const activeQuoteDemographics = useQuoteStore(s => s.activeQuoteDemographics);

  const gridCols = useMemo(() => {
    const cols: any[] = [];

    if (quote === undefined) return cols;

    for (const [key, value] of Object.entries(quote.demographics)) {
      var usableValue: any = value;
      if (value === undefined || value === null) {
        usableValue = <i>-</i>;
      } else if (value === '') {
        usableValue = <i>-</i>;
      }

      var alias = key;

      for (const [key2, value2] of datasetsMetadata.entries()) {
        const metadata = value2;

        try {
          const tryAlias = getAliasFromColumn(alias, metadata);
          if (tryAlias !== alias) {
            alias = tryAlias;
            break;
          }
        } catch {
          continue;
        }
      }

      cols.push(
        <Stack spacing={'xxs'}>
          <Text size="sm" color="dimmed">
            {alias}
          </Text>
          {loading ? (
            <Skeleton height={15} />
          ) : (
            <Text size="sm">{usableValue}</Text>
          )}
        </Stack>
      );
    }
    return cols;
  }, [quote, activeQuoteDemographics, datasetsMetadata, loading]);

  return (
    <>
      <Stack>
        <Group position="apart">
          <Stack spacing={'xxs'}>
            <Title order={5}>Respondent Profile</Title>
            <Text color="dimmed" size="xs">
              Information about the quote's author.{' '}
              {Object.keys(activeQuoteDemographics).length === 0 && (
                <>
                  <Popover>
                    <Popover.Target>
                      <Anchor>Select fields&nbsp;</Anchor>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <QuoteReaderDemographicSelect />
                    </Popover.Dropdown>
                  </Popover>
                  you'd like to see
                </>
              )}
            </Text>
          </Stack>
          {Object.keys(activeQuoteDemographics).length > 0 && (
            <Popover position="left" width={350}>
              <Popover.Target>
                <Tooltip label="Click to change info retrieved" openDelay={400}>
                  <Anchor size="xs">Change fields</Anchor>
                </Tooltip>
              </Popover.Target>
              <Popover.Dropdown>
                <QuoteReaderDemographicSelect />
              </Popover.Dropdown>
            </Popover>
          )}
        </Group>
        <SimpleGrid cols={2}>{gridCols}</SimpleGrid>
      </Stack>
    </>
  );
};

export default memo(QuoteReaderProfileComponent);
