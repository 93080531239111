import CRN from './Crn';
import APIDecodable from './APIDecodable';

class ResourceModel implements APIDecodable {
  alias: string;
  crn: CRN;
  dateAdded: number;
  structureType: string;
  structureVersion: string;

  constructor(
    alias: string,
    crn: CRN,
    dateAdded: number,
    structureType: string,
    structureVersion: string
  ) {
    this.alias = alias;
    this.crn = crn;
    this.dateAdded = dateAdded;
    this.structureType = structureType;
    this.structureVersion = structureVersion;
  }

  static fromAPIResponse(apiResponse: any): ResourceModel {
    const alias = apiResponse.Alias;
    const crn = CRN.fromString(apiResponse.Crn);
    const dateAdded = Date.parse(apiResponse.DateAdded);
    const structureType = apiResponse.StructureType;
    const structureVersion = apiResponse.StructureVersion;

    return new ResourceModel(
      alias,
      crn,
      dateAdded,
      structureType,
      structureVersion
    );
  }
}

class MetadataBody implements APIDecodable {
  version: string;
  properties: any;

  constructor(version: string, properties: any) {
    this.version = version;
    this.properties = properties;
  }

  static fromAPIResponse(apiResponse: any): MetadataBody {
    const version = apiResponse.Version;
    const properties = apiResponse.Properties;

    return new MetadataBody(version, properties);
  }
}

class ResourceMetadataModel implements APIDecodable {
  crn: CRN;
  dateAdded: number;
  parentCrn: CRN;
  structureType: string;
  structureVersion: string;
  content: MetadataBody;

  constructor(
    crn: CRN,
    dateAdded: number,
    parentCrn: CRN,
    structureType: string,
    structureVersion: string,
    content: MetadataBody
  ) {
    this.crn = crn;
    this.dateAdded = dateAdded;
    this.parentCrn = parentCrn;
    this.structureType = structureType;
    this.structureVersion = structureVersion;
    this.content = content;
  }

  static fromAPIResponse(apiResponse: any): ResourceMetadataModel {
    const crn = CRN.fromString(apiResponse.Crn);
    const dateAdded = Date.parse(apiResponse.DateAdded);
    const parentCrn = CRN.fromString(apiResponse.ParentCrn);
    const structureType = apiResponse.StructureType;
    const structureVersion = apiResponse.StructureVersion;
    const content = MetadataBody.fromAPIResponse(apiResponse.Content);

    return new ResourceMetadataModel(
      crn,
      dateAdded,
      parentCrn,
      structureType,
      structureVersion,
      content
    );
  }
}

export { ResourceModel, ResourceMetadataModel };
