import { ResourceMetadataModel, ResourceModel } from '../models/ResourceModel';
import APIRequesterBase from './APIBase';
import axios from 'axios';
import CRN from '../models/Crn';
import { ExternalBenchmarkDescribedModel } from '@models/ExternalBenchmarkModel';
import { ProjectMetricType } from './ProjectAPI';
import { BenchmarkDataPoint } from '@components/charts/datamodels/BenchmarkDataPoint';

class ResourceAPI extends APIRequesterBase {
  static async getResource(resourceCrn: CRN): Promise<ResourceModel> {
    // Let's go ahead and get the resource
    const url = `${this.getAPIUrl()}/resources/?ResourceCRN=${
      resourceCrn.identifier
    }`;

    const headers = await this.getHeaders();

    return axios.get(url, { headers: headers }).then((response) => {
      const dashboard: ResourceModel = ResourceModel.fromAPIResponse(
        response.data
      );
      return dashboard;
    });
  }

  static async getResourceMetadata(
    resource: ResourceModel
  ): Promise<ResourceMetadataModel> {
    // Let's go ahead and get the resource metadata.
    const url = `${this.getAPIUrl()}/resources/metadata/?ResourceCRN=${
      resource.crn.identifier
    }`;

    const headers = await this.getHeaders();

    return axios.get(url, { headers: headers }).then((response) => {
      const dashboard: ResourceMetadataModel =
        ResourceMetadataModel.fromAPIResponse(response.data);
      return dashboard;
    });
  }

  static async getResourceMetadataFromCrn(
    resourceCrn: CRN
  ): Promise<ResourceMetadataModel> {
    // Let's go ahead and get the resource metadata.
    const url = `${this.getAPIUrl()}/resources/metadata/?ResourceCRN=${
      resourceCrn.identifier
    }`;

    const headers = await this.getHeaders();

    return axios.get(url, { headers: headers }).then((response) => {
      const dashboard: ResourceMetadataModel =
        ResourceMetadataModel.fromAPIResponse(response.data);
      return dashboard;
    });
  }

  static async describeSharedBenchmarks(
    organizationId?: number
  ): Promise<ExternalBenchmarkDescribedModel[]> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    const url = `${this.getAPIUrl()}/shared-benchmarks/describe/${organizationId}`;

    const headers = await this.getHeaders();

    return axios.get(url, { headers: headers }).then((response) => {
      const benchmarks: ExternalBenchmarkDescribedModel[] = response.data.map(
        (benchmark: any) => {
          return ExternalBenchmarkDescribedModel.fromAPIResponse(benchmark);
        }
      );
      return benchmarks;
    });
  }

  static async describeSharedBenchmark(
    benchmarkId: string,
    organizationId?: number
  ): Promise<ExternalBenchmarkDescribedModel> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    const url = `${this.getAPIUrl()}/shared-benchmarks/${organizationId}/${benchmarkId}/describe`;

    const headers = await this.getHeaders();

    return axios.get(url, { headers: headers }).then((response) => {
      const benchmark: ExternalBenchmarkDescribedModel =
        ExternalBenchmarkDescribedModel.fromAPIResponse(response.data);
      return benchmark;
    });
  }

  static async getSharedBenchmarkData(
    benchmarkId: string,
    benchmarkName: string,
    populationName: string,
    topic: string,
    organizationId?: number,
    metricType?: ProjectMetricType,
    focalTopic?: string
  ): Promise<BenchmarkDataPoint[]> {
    if (organizationId === undefined) {
      organizationId = import.meta.env.VITE_SERVICE_ACCOUNT_ID;
    }

    const url = `${this.getAPIUrl()}/visualizations/${organizationId}/shared-benchmark`;
    var body = {
      BenchmarkId: benchmarkId,
      OrganizationId: organizationId,
      Benchmark: benchmarkName,
      Population: populationName,
      Topic: topic,
      MetricType: metricType,
    };

    if (focalTopic !== undefined) {
      body['FocalTopic'] = focalTopic;
    }

    const headers = await this.getHeaders();

    return axios.post(url, body, { headers: headers }).then((response) => {
      // In our response.data we'll have two keys:
      // 1. "columns"
      // 2. "records"
      const columns = response.data.columns;
      const records = response.data.records;

      return BenchmarkDataPoint.fromResponse(columns, records);
    });
  }
}

export default ResourceAPI;
