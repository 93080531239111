import {
  ActionIcon,
  Card,
  Flex,
  Group,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { FC, forwardRef, useMemo, useState } from 'react';
import Fuse from 'fuse.js';
import { IconGripVertical, IconX } from '@tabler/icons-react';
import { DatasetField } from '@hooks/useDatasetFields';
import { useDraggable } from '@dnd-kit/core';

interface FieldItemProps {
  value: DatasetField;
  isDraggedItem?: boolean;
  canDrag?: boolean;
  matches?: any[] | readonly Fuse.FuseResultMatch[];
  onRemove?: () => void;
}

const FieldItem: FC<FieldItemProps> = ({
  value,
  isDraggedItem,
  matches,
  onRemove,
  canDrag = true,
}) => {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: value.id,
    data: {
      type: 'column',
    },
  });

  const match = useMemo(() => {
    if (matches && matches.length > 0 && matches[0].key === 'values')
      return matches[0].value;
  }, [matches]);

  const style = {
    cursor: 'pointer',
    zIndex: 3,
    opacity: isDragging ? 0.5 : undefined,
  };

  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const card = (
    <Card
      key="as"
      p={0}
      pl={16}
      radius="sm"
      withBorder
      style={{
        overflow: 'visible',
        cursor: canDrag ? 'grab' : 'default',
        height: 40,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Group p={0} spacing={0}>
        <Flex
          justify="center"
          align="center"
          style={{
            position: 'absolute',
            top: -1,
            left: -1,
            borderStartStartRadius: '0.25rem',
            borderEndStartRadius: '0.25rem',
            height: 40,
          }}
          bg={
            !canDrag
              ? ''
              : isHover || isDragging || isDraggedItem
              ? 'blue'
              : 'rgb(231, 245, 255)'
          }
        >
          <IconGripVertical
            size={20}
            color={
              !canDrag
                ? ''
                : isHover || isDragging || isDraggedItem
                ? 'white'
                : 'grey'
            }
          />
        </Flex>

        <Stack m={0} ml="xs" spacing={0} style={{ flexGrow: 1 }}>
          <Tooltip openDelay={400} label={value.alias ?? value.field}>
            <Text
              style={{
                maxWidth: 200,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              size="sm"
              weight={500}
            >
              {value.alias ?? value.field}
            </Text>
          </Tooltip>
          <Text
            style={{
              maxWidth: 200,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            size="xs"
            mt={-5}
            color="dimmed"
          >
            {match ? `Matched value: ${match}` : value.description}
          </Text>
        </Stack>

        {onRemove && (
          <ActionIcon color="dark" mr="xs" onClick={onRemove}>
            <IconX size="1.125rem" />
          </ActionIcon>
        )}
      </Group>
    </Card>
  );

  if (isDraggedItem || !canDrag) {
    return card;
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {card}
    </div>
  );
};

export default FieldItem;
