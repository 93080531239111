import axios from 'axios';
import APIRequesterBase from './APIBase';
import DatasetModel from '../models/DatasetModel';

class DatasetAPI extends APIRequesterBase {
  static async getDataset(dataset: DatasetModel): Promise<DatasetModel> {
    return DatasetAPI.getDatasetWithIds(
      dataset.organizationId,
      dataset.datasetId
    );
  }

  static async getDatasetWithIds(
    organizationId: number,
    datasetId: string
  ): Promise<DatasetModel> {
    const crnString: string = `crn:${organizationId}:resource:dataset/${datasetId}`;

    const url = `${this.getAPIUrl()}/datasets/?DatasetCRN=${crnString}`;
    const headers = await this.getHeaders();

    return axios
      .get(url, { headers: headers })
      .then((response) => DatasetModel.fromAPIResponse(response.data));
  }

  static async describeDataset(dataset: DatasetModel): Promise<DatasetModel> {
    return DatasetAPI.describeDatasetWithIds(
      dataset.organizationId,
      dataset.datasetId
    );
  }

  static async describeDatasetTopics(
    dataset: DatasetModel
  ): Promise<DatasetModel> {
    return DatasetAPI.describeDatasetTopicsWithIds(
      dataset.organizationId,
      dataset.datasetId
    );
  }

  static async describeDatasetWithIds(
    organizationId: number,
    datasetId: string
  ): Promise<DatasetModel> {
    const crnString: string = `crn:${organizationId}:resource:dataset/${datasetId}`;

    const url = `${this.getAPIUrl()}/datasets/describe/?DatasetCRN=${crnString}`;
    const headers = await this.getHeaders();

    return axios
      .get(url, { headers: headers })
      .then((response) => DatasetModel.fromAPIResponse(response.data));
  }

  static async describeDatasetTopicsWithIds(
    organizationId: number,
    datasetId: string
  ): Promise<DatasetModel> {
    const crnString: string = `crn:${organizationId}:resource:dataset/${datasetId}`;

    const url = `${this.getAPIUrl()}/datasets/describe/topics?DatasetCRN=${crnString}`;
    const headers = await this.getHeaders();

    return axios
      .get(url, { headers: headers })
      .then((response) => DatasetModel.fromAPIResponse(response.data));
  }

  static async listDatasets(organizationId?: number): Promise<DatasetModel[]> {
    const url = `${this.getAPIUrl()}/datasets/${organizationId}`;
    const headers = await this.getHeaders();

    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    return axios.get(url, { headers: headers }).then((response) => {
      const datasets: DatasetModel[] = response.data.map((dataset: any) =>
        DatasetModel.fromAPIResponse(dataset)
      );
      return datasets;
    });
  }
  // datasets
  static async describeDatasets(
    organizationId?: number
  ): Promise<DatasetModel[]> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    const url = `${this.getAPIUrl()}/datasets/describe/${organizationId}`;
    const headers = await this.getHeaders();

    return axios.get(url, { headers: headers }).then((response) => {
      const datasets: DatasetModel[] = response.data.map((dataset: any) =>
        DatasetModel.fromAPIResponse(dataset)
      );
      return datasets;
    });
  }
  // metadata
  static async getDatasetMetadata(dataset: DatasetModel): Promise<any> {
    return DatasetAPI.getDatasetMetadataWithIds(
      dataset.organizationId,
      dataset.datasetId
    );
  }

  static async getDatasetMetadataWithIds(
    organizationId: number,
    datasetId: string
  ): Promise<any> {
    const crnString: string = `crn:${organizationId}:resource:dataset/${datasetId}`;
    const url = `${this.getAPIUrl()}/datasets/metadata/?DatasetCRN=${crnString}`;
    const headers = await this.getHeaders();

    return axios
      .get(url, { headers: headers })
      .then((response) => response.data);
  }
}

export default DatasetAPI;
