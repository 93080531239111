import { Text, Title, Image, Group } from '@mantine/core';

import { BaseHelpSectionProps } from './BaseHelpSection';
import QuoteReaderSingleTopicExampleImg from './QuoteReaderSingleTopicExample.png';
import { IconAlertCircle, IconProgress } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
// import { IconCheck } from "@tabler/icons-react";

export const QuotesHelpSection: React.FC<BaseHelpSectionProps> = ({
  linkedContext,
}) => {
  return (
    <>
      <Text color="dimmed">Drill into the voice of your employees</Text>

      <Title order={4}>Quotes</Title>
      <Text>
        The Quote Reader helps you interpret your employees language — what
        they're saying and feeling. You can view quotes related to a specific
        data point by choosing a topic on a relevant chart. It will show any
        matching quotes for your choice. For each choice, we provide up to 25
        random quotes.
      </Text>
      <Text>
        In the Quote Reader, you'll find a focal sentence containing your chosen
        topic. Additionally, you'll see the full response from the person,
        referred to as the Full Text. This focal sentence will be highlighted
        within the context text.
      </Text>

      <Image
        maw={'auto'}
        mx="auto"
        style={{ border: '1px dashed', borderColor: 'lightgray' }}
        radius="md"
        src={QuoteReaderSingleTopicExampleImg}
        alt="Random image"
      />

      <Text>
        The{' '}
        <b>
          <i>In Same Breath</i>
        </b>{' '}
        view, available for most analyses, allows you to see what your employees
        talk about in combination with the focal topic you've chosen. e.g. when
        employees speak negatively about <i>management</i>, and they speak about{' '}
        <i>workload</i> in the same breath, what do they say?
      </Text>
      <Group>
        <Title order={4}>Reporting Inaccuracies</Title>
        <IconAlertCircle size={18} color="red" />
      </Group>

      <Text>
        Our system isn't perfect, but we strive to make it as accurate as
        possible. Sometimes it happens that sentences are not properly
        classified into the right topic, sentiment or both. If you believe a
        sentence to be mis-classified, please report it. Our team will review
        any sentences you report and make the necessary changes to improve our
        system for future use.
      </Text>
    </>
  );
};
