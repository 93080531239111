import React from 'react';
import { NumberInput } from '@mantine/core';

interface MantineNumberInputProps {
  listValues: any[];
  value: any;
  setValue: (value: any) => void;
  allowCustomValues: boolean;
  readonly: boolean;
  placeholder: string;
  customProps: any;
}

export const MantineNumberInput: React.FC<MantineNumberInputProps> = (
  props: MantineNumberInputProps
) => {
  const formattedData = props?.listValues?.map((item: any) => {
    return {
      label: item.title,
      value: item.value,
    };
  });

  return (
    <NumberInput
      size="xs"
      value={props.value}
      onChange={props.setValue}
      placeholder="Select"
    />
  );
};
