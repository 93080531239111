import { Analysis } from '@hooks/useProjectAnalyses';
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Card,
  Text,
  Flex,
  Group,
  SimpleGrid,
  Stack,
  Tooltip,
  List,
  Popover,
  MantineProvider,
} from '@mantine/core';
import Fuse from 'fuse.js';
import { FC, useCallback, useMemo, useState } from 'react';
import {
  IconArrowRight,
  IconCheck,
  IconDownload,
  IconEdit,
  IconTrash,
} from '@tabler/icons';
import Link from '@components/Link';
import { dateToString } from '@utils/FormatUtils';
import AnalysisStatusIcon from './AnalysisStatusIcon';
import AnalysisTypeIcon from './AnalysisTypeIcon';
import { Highlight } from '@mantine/core';
import { useHighlights } from '@hooks/useHighlights';
import { useNavigate } from '@hooks/useNavigate';
import { DeleteAnalysisModal } from './DeleteAnalysisModal';
import ProjectModel from '@models/ProjectModel';
import { notifications } from '@mantine/notifications';
import { Summary } from '@hooks/useProjectSummaries';
import { DeleteSummaryModal } from './DeleteSummaryModal';

interface SummaryCardProps {
  project: ProjectModel;
  summary: Summary;
  matches: readonly Fuse.FuseResultMatch[];
}

export const SummaryCard: FC<SummaryCardProps> = ({
  project,
  summary,
  matches,
}) => {
  const navigate = useNavigate();
  const highlightName = useHighlights('name', matches);
  const highlightPopulations = useHighlights('populations', matches);
  const highlightOutcome = useHighlights('outcome', matches);
  const [showDeleteAnalysisModal, setShowDeleteAnalysisModal] = useState(false);

  return (
    <>
      <DeleteSummaryModal
        project={project}
        summary={summary}
        opened={showDeleteAnalysisModal}
        onClose={() => setShowDeleteAnalysisModal(false)}
        // onDeleted={onAnalysisDeleted}
      />
      <Card
        shadow="sm"
        radius="md"
        withBorder
        key={summary.id}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Card.Section withBorder inheritPadding p="xs">
          <Group position="apart" noWrap>
            <Group noWrap>
              <Anchor
                component={Link}
                to={`/projects/${project.projectId}/summaries/${summary.id}`}
                weight={500}
              >
                <Highlight highlight={highlightName}>{summary.name}</Highlight>
              </Anchor>
            </Group>
            <Group spacing={0}>
              <Tooltip label="Edit analysis" openDelay={500}>
                <ActionIcon onClick={() => navigate(`/projects/${project.projectId}/summaries/${summary.id}/edit`)}>
                  <IconEdit size={14} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Delete analysis" openDelay={500}>
                <ActionIcon onClick={() => setShowDeleteAnalysisModal(true)}>
                  <IconTrash color="red" size={14} />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Group>
        </Card.Section>

        

        <Card.Section>
          <Group position="apart" align="end" p="xs">
            <Stack spacing={0}>
              <Text size="xs" color="dimmed">
                {summary.createdAt.getTime() === summary.updatedAt.getTime()
                  ? 'Created At'
                  : 'Last update'}
              </Text>
              <Text size="xs" color="dimmed">
                {summary.createdAt.getTime() === summary.updatedAt.getTime()
                  ? dateToString(summary.createdAt)
                  : dateToString(summary.updatedAt)}
              </Text>
            </Stack>
            <Button
              size="xs"
              component={Link}
              to={`/projects/${project.projectId}/summaries/${summary.id}`}
              rightIcon={<IconArrowRight size={12} />}
              variant="light"
            >
              View
            </Button>
          </Group>
        </Card.Section>
      </Card>
    </>
  );
};