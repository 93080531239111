import { create } from 'zustand';

export const FOCAL_POPULATIONS_RESERVED_KEYWORD = '<FOCAL_POPULATIONS>';
export type ModelAggregation = 'Topic' | 'Theme';

interface SummaryStore {
  activeTopics: string[];
  setActiveTopics: (value: string[]) => void;

  activeThemes: string[];
  setActiveThemes: (value: string[]) => void;
}

export const useSummaryStore = create<SummaryStore>()((set, get) => ({
  activeTopics: [],
  setActiveTopics: (value) => set({ activeTopics: value }),

  activeThemes: [],
  setActiveThemes: (value) => set({ activeThemes: value }),
}));
