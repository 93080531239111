import DashboardModel from '../models/DashboardModel';
import APIRequesterBase from './APIBase';
import axios from 'axios';

class DashboardAPI extends APIRequesterBase {
  static async getTrustedTicket(dashboard: DashboardModel) {
    return this.getTrustedTicketWithIds(
      dashboard.organizationId,
      dashboard.dashboardId
    );
  }

  static async getTrustedTicketWithIds(
    organizationId: number,
    dashboardId: string
  ): Promise<string> {
    // Function which calls the endpoint to get the trusted ticket for the dashboard
    const url = `${this.getAPIUrl()}/dashboards/${organizationId}/${dashboardId}/token`;

    const headers = await this.getHeaders();

    return axios.post(url, {}, { headers: headers }).then((response) => {
      return response.data.AuthenticationToken;
    });
  }

  static async getDashboard(
    organizationId: number,
    dashboardId: string
  ): Promise<DashboardModel> {
    // Let's go ahead and get the dashboards for the organization...
    const url = `${this.getAPIUrl()}/dashboards/${organizationId}/${dashboardId}`;

    const headers = await this.getHeaders();

    return axios.get(url, { headers: headers }).then((response) => {
      const dashboard: DashboardModel = DashboardModel.fromAPIResponse(
        response.data
      );
      return dashboard;
    });
  }

  static async listDashboards(
    organizationId?: number
  ): Promise<DashboardModel[]> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    // Let's go ahead and get the dashboards for the organization...
    const url = `${this.getAPIUrl()}/dashboards/${organizationId}`;

    const headers = await this.getHeaders();

    // Make a post url to the endpoint and handle the response from the server...
    return axios.get(url, { headers: headers }).then((response) => {
      const dashboards: DashboardModel[] = response.data.map((dashboard: any) =>
        DashboardModel.fromAPIResponse(dashboard)
      );
      return dashboards;
    });
  }
}

export default DashboardAPI;
