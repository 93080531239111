import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import DashboardController from './controllers/DashboardController';
import NotFoundView from './components/NotFoundView';
import NewProjectController from './controllers/NewProjectController';
import QuoteReaderController from './controllers/QuoteReaderController';

import { AppShell, Header, useMantineColorScheme, Flex } from '@mantine/core';
import { Group } from '@mantine/core';

import culturexLogoBlack from './images/CultureX-Voice-Text-Logo-Black.png';
import culturexLogoWhite from './images/CultureX-Voice-Text-Logo-White.png';
import ListDashboardsController from './controllers/ListDashboardsController';
import OrganizationSelectionComponent from '@components/OrganizationSelectionComponent';
import { Provider as ReduxProvider } from 'react-redux';
import { TestMultiSelectCheckbox } from '@controllers/TestMultiSelectCheckbox';
import { ProjectPage } from '@pages/ProjectPage';
import ColorSchemeToggle from '@components/ColorSchemeToggle';
import SettingsButton from '@components/SettingsButton';
import AnalysisPage from '@pages/AnalysisPage';
import CreateAnalysisPage from '@pages/CreateAnalysisPage';
import EditAnalysisPage from '@pages/EditAnalysisPage';
import { AnalysisType } from '@stores/AnalysisEditionStore';
import ProjectsPage from '@pages/ProjectsPage';
import { UserFeedbackButton } from '@components/UserFeedbackButton';
import AnalysisNotifications from '@components/AnalysisNotifications';
import Link from '@components/Link';
import { store } from '@redux/store';
import { SettingsPage } from '@pages/SettingsPage';
import { CreateSummaryPage } from '@pages/CreateSummaryPage';
import { SummaryPage } from '@pages/SummaryPage';
import { EditSummaryPage } from '@pages/EditSummaryPage';

const App: FC = () => {
  const { colorScheme } = useMantineColorScheme();

  return (
    <div className="App">
      <AnalysisNotifications />
      <AppShell
        padding={0}
        header={
          <Header height={60} p="xs">
            <Group position="apart">
              <Link to="/projects/">
                <img
                  src={
                    colorScheme === 'dark'
                      ? culturexLogoWhite
                      : culturexLogoBlack
                  }
                  height={30}
                  style={{ marginTop: '0px' }}
                ></img>
              </Link>
              <Group position="apart" align="center">
                <UserFeedbackButton />
                <OrganizationSelectionComponent />
                <ColorSchemeToggle />
                <SettingsButton />
              </Group>
            </Group>
          </Header>
        }
        styles={(theme) => ({
          main: {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        })}
      >
        <ReduxProvider store={store}>
          <Routes>
            <Route path="/" element={<Navigate to="/projects" replace />} />
            <Route path="dashboards" element={<ListDashboardsController />} />
            <Route
              path="dashboards/:organizationId/:dashboardId"
              element={<DashboardController />}
            />
            <Route
              path="dashboards/:dashboardId"
              element={<DashboardController />}
            />
            <Route path="quotes" element={<QuoteReaderController />} />

            <Route path="test-select" element={<TestMultiSelectCheckbox />} />

            <Route path="projects/new" element={<NewProjectController />} />

            <Route path="projects" element={<ProjectsPage />} />
            <Route path="projects/:projectId" element={<ProjectPage />} />
            <Route
              path="projects/:projectId/analyses/:analysisId"
              element={<AnalysisPage />}
            />
            <Route
              path="projects/:projectId/new-benchmark"
              element={
                <CreateAnalysisPage
                  analysisType={AnalysisType.INTERNAL_BENCHMARK}
                />
              }
            />
            <Route
              path="projects/:projectId/new-key-drivers"
              element={
                <CreateAnalysisPage
                  analysisType={AnalysisType.DRIVERS_OF_OUTCOME}
                />
              }
            />

            <Route
              path="projects/:projectId/new-raw-voice"
              element={
                <CreateAnalysisPage
                  analysisType={AnalysisType.RAW_VOICE}
                />
              }
            />

            <Route
              path="projects/:projectId/new-progress-over-time"
              element={
                <CreateAnalysisPage
                  analysisType={AnalysisType.PROGRESS_OVER_TIME}
                />
              }
            />

            <Route
              path="projects/:projectId/analyses/:analysisId/edit"
              element={<EditAnalysisPage />}
            />

          <Route
              path="projects/:projectId/new-summary"
              element={<CreateSummaryPage />}
            />
            <Route
              path="projects/:projectId/summaries/:summaryId"
              element={<SummaryPage />}
            />
            <Route
              path="projects/:projectId/summaries/:summaryId/edit"
              element={<EditSummaryPage />}
            />

            <Route
              path="projects/:projectId/summaries/:summaryId/analyses/:analysisId"
              element={<AnalysisPage />}
            />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        </ReduxProvider>
      </AppShell>
    </div>
  );
};

export default App;
