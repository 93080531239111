import {
  ActionIcon,
  Card,
  Flex,
  Group,
  Slider,
  Stack,
  Text,
  TextInput,
  TypographyStylesProvider,
} from '@mantine/core';
import { FC, forwardRef, useCallback, useId, useMemo, useState } from 'react';

import { IconGripVertical, IconSearch, IconX } from '@tabler/icons-react';
import { DatasetField } from '@hooks/useDatasetFields';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import {
  AnalysisType,
  useAnalysisEditionStore,
} from '@stores/AnalysisEditionStore';
import FieldItem from '@components/AnalysisEdition/FieldItem';

const PopulationsDroppableArea: FC = () => {
  const analysisType = useAnalysisEditionStore((s) => s.analysisType);
  const dragging = useAnalysisEditionStore((s) => s.dragging);
  const selectedDistributionRows = useAnalysisEditionStore(
    (s) => s.selectedDistributionRows
  );

  if (dragging !== 'row') {
    return null;
  }

  const rows = [];
  if (selectedDistributionRows.length === 1) {
    if (analysisType !== AnalysisType.DRIVERS_OF_OUTCOME) {
      rows.push(
        <PopulationsDroppableRow
          key="KEY_A0"
          action="add"
          text="Drop here to add a new population"
        />
      );
    } else {
      rows.push(
        <PopulationsDroppableRow
          key="KEY_A1"
          action="set"
          text="Drop here to set a population"
        />
      );
    }
  } else if (selectedDistributionRows.length > 1) {
    if (analysisType !== AnalysisType.DRIVERS_OF_OUTCOME) {
      rows.push(
        <PopulationsDroppableRow
          key="KEY_B0"
          action="add"
          text={`Drop here to add ${selectedDistributionRows.length} new populations`}
        />
      );
      rows.push(
        <PopulationsDroppableRow
          key="KEY_B1"
          action="group"
          text={`Drop here to add a new combined population`}
        />
      );
    } else {
      rows.push(
        <PopulationsDroppableRow
          key="KEY_B2"
          action="set"
          text="Drop here to set a combined population"
        />
      );
    }
  }

  return <Stack style={{ position: 'absolute', inset: 0 }}>{rows}</Stack>;
};

interface DistributionTableDroppableColumnProps {
  text: string;
  action: 'add' | 'group' | 'set';
}

const PopulationsDroppableRow: FC<DistributionTableDroppableColumnProps> = ({
  text,
  action,
}) => {
  const { setNodeRef, isOver } = useDroppable({
    id: useId(),
    data: {
      action,
      accepts: ['row'],
    },
  });

  return (
    <Stack
      ref={setNodeRef}
      p="xl"
      style={{
        height: '100%',
        border: 'dashed 1px grey',
        borderRadius: '10px',
      }}
    >
      <Text align="center" color="grey" weight={isOver ? 600 : 300}>
        {text}
      </Text>
    </Stack>
  );
};

export default PopulationsDroppableArea;
