import { Button, Popover, Text } from '@mantine/core';
import React from 'react';
import { UserFeedbackForm } from './UserFeedbackForm';
import { IconLifebuoy } from '@tabler/icons';
import { useDisclosure } from '@mantine/hooks';

export const UserFeedbackButton: React.FC = () => {
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Popover
      width={400}
      position="bottom"
      withArrow
      shadow="md"
      closeOnClickOutside={false}
      opened={opened}
    >
      <Popover.Target>
        <Button
          style={{ border: '1px solid transparent' }}
          variant="subtle"
          color="dark"
          leftIcon={<IconLifebuoy />}
          onClick={() => {
            if (opened) {
              close();
            } else {
              open();
            }
          }}
        >
          Provide Feedback
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <UserFeedbackForm onCancel={close} onSubmitted={close} />
      </Popover.Dropdown>
    </Popover>
  );
};
