import { MantineNumberSize } from '@mantine/core';
import { VirtualItem, useVirtualizer } from '@tanstack/react-virtual';
import React, { LegacyRef } from 'react';
import { CSSProperties, FC, ReactNode, useRef } from 'react';

interface VirtualizedListProps {
  data: object[];
  itemHeight: number;
  children: (virtualItem: VirtualItem) => JSX.Element;
  spacing?: number;
  style?: CSSProperties;
}

const VirtualizedList = React.forwardRef(
  (
    { data, itemHeight, spacing = 0, children, style }: VirtualizedListProps,
    ref: React.MutableRefObject<HTMLDivElement>
  ) => {
    const localRef = useRef<HTMLDivElement>(null);
    const parentRef = ref || localRef;

    const rowVirtualizer = useVirtualizer({
      count: data.length,
      getScrollElement: () => parentRef.current,
      estimateSize: () => itemHeight + spacing,
    });

    return (
      <div ref={parentRef} style={{ overflow: 'auto', ...style }}>
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            minHeight: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualItem) => (
            <div
              key={virtualItem.key}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              {children(virtualItem)}
            </div>
          ))}
        </div>
      </div>
    );
  }
);

export default VirtualizedList;
