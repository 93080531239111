import React, { useEffect, useState } from 'react';
import NewProjectConfigFlowStep from '../components/new-project-flow/NewProjectConfigFlowStep';
import DatasetAPI from '../apis/DatasetAPI';
import DatasetModel from '../models/DatasetModel';
import SectionWrapper from '../components/SectionWrapper';
import { useDispatch } from 'react-redux';
import { updateItems } from '../redux/breadcrumbsSlice';
import APIErrorMessage from '../components/APIErrorMessage';

const NewProjectController: React.FC = () => {
  const [datasets, setDatasets] = useState<DatasetModel[]>([]);
  const [datasetMetadata, setDatasetMetadata] = useState<string[]>([]);
  const [selectedDatasets, setSelectedDatasets] = useState<string[]>([]);
  const [isLoadingDatasets, setIsLoadingDatasets] = useState<boolean>(true);
  const [newProjectTitle, setNewProjectTitle] = useState<string>('');
  const [errorFetchDatasets, setErrorFetchDatasets] = useState<
    object | undefined
  >(undefined);
  const [isProjectLoading, setIsProjectLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    updateBreadcrumbs();
  }, [newProjectTitle]);

  const updateBreadcrumbs = () => {
    dispatch(
      updateItems([
        {
          to: '/projects',
          label: 'Projects',
        },
        {
          to: '/projects/new',
          label: `New Project: ${newProjectTitle}`,
        },
      ])
    );
  };

  async function fetchData() {
    try {
      const data: DatasetModel[] = await DatasetAPI.describeDatasets();
      setDatasets(data);
      setIsLoadingDatasets(false);
    } catch (error) {
      // setErrorFetchDatasets(error.message);
      setErrorFetchDatasets(
        <APIErrorMessage response={error} dismissable={false} />
      );
      setIsLoadingDatasets(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchMetadata(datasetId: string, organizationId: number) {
    try {
      const data = await DatasetAPI.getDatasetMetadataWithIds(
        organizationId,
        datasetId
      );
      return data;
    } catch (error) {
      console.log('Error:', error);
    }
  }

  async function fetchDatasetMetadata() {
    setIsProjectLoading(true);

    var selectedDatasetsObjects = [];
    datasets.forEach((dataset) => {
      if (selectedDatasets.includes(dataset.datasetId)) {
        selectedDatasetsObjects.push(dataset);
      }
    });

    Promise.all(
      selectedDatasetsObjects.map(async (dataset: DatasetModel) => {
        var metadata: any = await fetchMetadata(
          dataset.datasetId,
          dataset.organizationId
        );

        metadata['DatasetCrn'] = dataset.crnObject.identifier;

        return metadata;
      })
    )
      .then((results) => {
        setIsProjectLoading(false);
        setDatasetMetadata([...results]);
      })
      .catch((error) => {
        setIsProjectLoading(false);
        console.log('Error:', error);
      });
  }

  useEffect(() => {
    fetchDatasetMetadata();
  }, [selectedDatasets]);

  return (
    <>
      <SectionWrapper showBreadcrumbs>
        <NewProjectConfigFlowStep
          isLoadingDatasets={isLoadingDatasets}
          datasetData={datasets}
          selectedDatasets={selectedDatasets}
          setSelectedDatasets={setSelectedDatasets}
          datasetMetadata={datasetMetadata}
          setNewProjectTitle={setNewProjectTitle}
          errorFetchDatasets={errorFetchDatasets}
          isProjectLoading={isProjectLoading}
        />
      </SectionWrapper>
    </>
  );
};

export default NewProjectController;
