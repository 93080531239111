import {
  ActionIcon,
  Group,
  Table,
  UnstyledButton,
  Text,
  Center,
  rem,
  Tooltip,
  Anchor,
} from '@mantine/core';
import ProjectModel, { ProjectStatus } from '@models/ProjectModel';
import {
  IconChevronDown,
  IconChevronUp,
  IconDownload,
  IconSelector,
} from '@tabler/icons-react';
import React, { useMemo } from 'react';
import Link from '@components/Link';

import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { IconTrash } from '@tabler/icons';
import { DeleteProjectModal } from '@components/DeleteProjectModal';
import { ProjectDownloadDataModal } from '@components/ProjectDownloadDataModal';
import ProjectStatusIcon from '@components/ProjectStatusIcon';
import { dateToString } from '@utils/FormatUtils';

interface ProjectListTableComponentProps {
  isLoading: boolean;
  hasError: boolean;
  error?: JSX.Element;
  projects?: ProjectModel[];
  onProjectDeleted: () => void;
}

interface ProjectRow {
  projectId: string;
  title: string;
  description: string;
  dateAdded: Date;
  status: ProjectStatus;
}

const columnHelper = createColumnHelper<ProjectRow>();

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onClick: (event: any) => void;
  sortable: boolean;
}

const Th = ({ children, reversed, sorted, onClick, sortable }: ThProps) => {
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <th>
      <UnstyledButton onClick={onClick}>
        <Group position="apart">
          <Text fw={500} fz="sm">
            {children}
          </Text>
          {sortable && (
            <Center>
              <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
            </Center>
          )}
        </Group>
      </UnstyledButton>
    </th>
  );
};

export const ProjectList = (
  props: ProjectListTableComponentProps
) => {
  const [deleteProjectModalOpened, setDeleteProjectModalOpened] =
    React.useState<boolean>(false);
  const [downloadProjectModalOpened, setDownloadProjectModalOpened] =
    React.useState<boolean>(false);
  const [projectToDelete, setProjectToDelete] = React.useState<
    ProjectModel | undefined
  >(undefined);
  const [projectToDownload, setProjectToDownload] = React.useState<
    ProjectModel | undefined
  >(undefined);

  const showDeleteProjectModal = (projectId: string) => {
    // Get the project from the list of projects
    const project = props.projects?.find(
      (project) => project.projectId === projectId
    );

    if (project) {
      setProjectToDelete(project);
      setDeleteProjectModalOpened(true);
    }
  };

  const showDownloadProjectModal = (projectId: string) => {
    // Get the project from the list of projects
    const project = props.projects?.find(
      (project) => project.projectId === projectId
    );

    if (project) {
      setProjectToDownload(project);
      setDownloadProjectModalOpened(true);
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: 'Title',
        cell: (info) => {
          const title = info.getValue();
          const projectId = info.row.original.projectId; // Get the project id for redirecting...
          return <Anchor truncate component={Link} to={`/projects/${projectId}`}>{title}</Anchor>;
        },
      }),

      columnHelper.accessor('description', {
        header: 'Description',
        cell: (info) => <Text size="sm">{info.getValue()}</Text>,
      }),

      columnHelper.accessor('dateAdded', {
        header: 'Date Added',
        cell: (info) => <Text truncate size="sm">{dateToString(info.getValue())}</Text>,
      }),
      
      columnHelper.accessor('status', {
        header: 'Status',
        cell: (info) => <ProjectStatusIcon projectStatus={info.getValue()} showLabel textSize="sm" />,
      }),

      columnHelper.display({
        header: 'Actions',
        cell: (info) => {
          return (
            <Group noWrap>
              <Tooltip label="Download data" openDelay={500}>
                <ActionIcon onClick={() =>
                      showDownloadProjectModal(info.row.original.projectId)
                    }>
                  <IconDownload size={18}/>
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Delete project" openDelay={500}>
                <ActionIcon color="red"
                  onClick={() =>
                    showDeleteProjectModal(info.row.original.projectId)
                  }>
                    <IconTrash size={18}/>
                </ActionIcon>
              </Tooltip>
            </Group>
          );
        },
      }),
    ],
    []
  );

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const data: ProjectRow[] = React.useMemo(() => {
    if (props.projects) {
      return props.projects.map((project: ProjectModel) => {
        return {
          projectId: project.projectId,
          title: project.title,
          description: project.description,
          dateAdded: project.dateAdded,
          status: project.status,
        };
      });
    } else {
      return [];
    }
  }, [props.projects]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <>
      {projectToDelete && (
        <DeleteProjectModal
          opened={deleteProjectModalOpened && projectToDelete !== undefined}
          onClose={() => setDeleteProjectModalOpened(false)}
          onProjectDeleted={() => {
            setDeleteProjectModalOpened(false);
            setProjectToDelete(undefined);
            props.onProjectDeleted();
          }}
          project={projectToDelete}
        />
      )}
      {projectToDownload && downloadProjectModalOpened && (
        <ProjectDownloadDataModal
          project={projectToDownload}
          onCancel={() => setDownloadProjectModalOpened(false)}
          opened={downloadProjectModalOpened}
        />
      )}
      <Table fontSize={'md'}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    key={header.id}
                    sortable={header.column.id !== 'Actions'}
                    sorted={header.column.getIsSorted() !== false}
                    reversed={header.column.getIsSorted() === 'asc'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
