import CRN from './Crn';
import APIDecodable from './APIDecodable';

class OrganizationModel implements APIDecodable {
  organizationId: number;
  name: string;
  dateAdded: Date;

  constructor(organizationId: number, name: string, dateAdded: Date) {
    this.organizationId = organizationId;
    this.name = name;
    this.dateAdded = dateAdded;
  }

  static fromAPIResponse(apiResponse: any): OrganizationModel {
    const organizationId = apiResponse.OrganizationId;
    const name = apiResponse.Name;
    const dateAdded = apiResponse.DateAdded;

    return new OrganizationModel(organizationId, name, dateAdded);
  }
}

export default OrganizationModel;
