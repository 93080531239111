import { AnalysisType } from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import { IExternalBenchmarkFocalPopulation } from '@models/ProjectAnalysisModel/ExternalBenchmarkAnalysisModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Population } from './benchmarksSlice';

export interface IAnalysisConfigBase {
  title: string;
  analysisType: AnalysisType;
  id?: string;
}

// Interfaces for each analysis type...
export interface IOutcomeMeasure {
  dataset: string;
  column: string;
}

export interface IDriversOfOutcomeAnalysisConfig extends IAnalysisConfigBase {
  focalPopulation?: string | Population;
  outcomes?: IOutcomeMeasure[];
  features?: string[];

  // Errors
  populationError?: React.ReactNode;
  outcomeError?: React.ReactNode;
}

export interface IOvertimeComparisonAnalysisConfig extends IAnalysisConfigBase {
  overtimeBenchmark?: string;
  populationOrder?: string[];

  // Errors:
  focalPopulationError?: React.ReactNode;
  comparisonError?: React.ReactNode;
}

export interface IInternalBenchmarkAnalysisConfig extends IAnalysisConfigBase {
  focalPopulation?: string;
  benchmark?: string;

  // Errors
  populationError?: React.ReactNode;
  benchmarkError?: React.ReactNode;
}

export interface IRawVoiceAnalysisConfig extends IAnalysisConfigBase {
  focalPopulation?: string;
  benchmark?: string;

  // Errors
  populationError?: React.ReactNode;
  benchmarkError?: React.ReactNode;
}

export interface IExternalBenchmarkAnalysisConfig extends IAnalysisConfigBase {
  focalPopulation?: IExternalBenchmarkFocalPopulation;
  benchmark?: string;

  // Errors
  populationError?: React.ReactNode;
  benchmarkError?: React.ReactNode;
}

export interface ICompareTwoPopulationsAnalysisConfig
  extends IAnalysisConfigBase {
  focalPopulation?: string;
  comparisonPopulation?: string;

  // Errors
  populationError?: React.ReactNode;
  comparisonPopulationError?: React.ReactNode;
}

export interface ISynthesisAnalysisConfig extends IAnalysisConfigBase {
  focalPopulation?: string;
  outcomes?: IOutcomeMeasure[];
  internalBenchmark?: string;
  externalBenchmark?: string;
  comparisonPopulation?: string;
  timePeriodPopulations?: string[];

  // Errors
  populationError?: React.ReactNode;
  outcomeError?: React.ReactNode;
  internalBenchmarkError?: React.ReactNode;
  externalBenchmarkError?: React.ReactNode;
  comparisonPopulationError?: React.ReactNode;
}

export interface AnalysisState {
  analyses: IAnalysisConfigBase[];
}

export const initialState: AnalysisState = {
  analyses: [],
};

const analysesSlice = createSlice({
  name: 'analyses',
  initialState,
  reducers: {
    addAnalysis: (state, action: PayloadAction<IAnalysisConfigBase>) => {
      // Push if the title doesn't exist in our analyses
      const providedAnalysis = action.payload;
      const analysisIndex = state.analyses.findIndex(
        (analysis: IAnalysisConfigBase) =>
          analysis.title === providedAnalysis.title
      );
      if (analysisIndex === -1) {
        state.analyses.push(providedAnalysis);
      }
    },

    updateAnalysisName: (
      state,
      action: PayloadAction<{
        oldAnalysisName: string;
        newAnalysisName: string;
      }>
    ) => {
      const { oldAnalysisName, newAnalysisName } = action.payload;
      const analysesIndex = state.analyses.findIndex(
        (benchmark) => benchmark.title === oldAnalysisName
      );
      if (analysesIndex !== -1) {
        state.analyses[analysesIndex].title = newAnalysisName;
      }
    },

    updateAnalysis: (
      state,
      action: PayloadAction<{ newAnalysis: IAnalysisConfigBase }>
    ) => {
      const { newAnalysis } = action.payload;

      if (newAnalysis.id) {
        const analysesIndex = state.analyses.findIndex(
          (analysis: IAnalysisConfigBase) => analysis.id === newAnalysis.id
        );
        if (analysesIndex !== -1) {
          state.analyses[analysesIndex] = newAnalysis;
        }
      } else {
        const analysesIndex = state.analyses.findIndex(
          (analysis: IAnalysisConfigBase) =>
            analysis.title === newAnalysis.title
        );
        if (analysesIndex !== -1) {
          state.analyses[analysesIndex] = newAnalysis;
        }
      }
    },

    removeAnalysis: (state, action: PayloadAction<string>) => {
      const analysesName = action.payload;
      state.analyses = state.analyses.filter(
        (analysis) => analysis.title !== analysesName
      );
    },

    resetAnalyses: (state) => {
      state.analyses = [];
    },
  },
});

export const {
  addAnalysis,
  updateAnalysisName,
  updateAnalysis,
  removeAnalysis,
  resetAnalyses,
} = analysesSlice.actions;
export default analysesSlice.reducer;
