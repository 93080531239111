import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Group,
  Text,
  Box,
  ActionIcon,
  Flex,
  Popover,
  Stack,
  ScrollArea,
  Divider,
  Tooltip,
  Input,
  Anchor,
} from '@mantine/core';
import { useNavigate } from '@hooks/useNavigate';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { useCurrentAnalysis } from '@hooks/useCurrentAnalysis';
import { InlineEditableText } from '@components/InlineEditableText';
import { IconPlus, IconSearch, IconSelector, IconX } from '@tabler/icons-react';
import { NewAnalysisModal } from '@components/AnalysisEdition/NewAnalysisModal';
import { useHover } from '@mantine/hooks';
import { useUpdateProject } from '@apis/hooks/useUpdateProject';
import { AnalysisType } from '@stores/AnalysisEditionStore';
import { InternalBenchmarkAnalysisModel } from '@models/ProjectAnalysisModel/InternalBenchmarkAnalysisModel';
import { useQueryClient } from '@tanstack/react-query';
import { DriversOfOutcomeAnalysisModel } from '@models/ProjectAnalysisModel/DriversOfOutcomeAnalysisModel';
import { IProjectAnalysisModel } from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import Fuse from 'fuse.js';

const FuseOptions = {
  includeScore: false,
  includeMatches: true,
  minMatchCharLength: 1,
  threshold: 0.2,
  keys: [
    {
      name: 'name',
      weight: 2,
    },
  ],
};

const AnalysisSelector: React.FC = () => {
  const project = useCurrentProject();
  const analysis = useCurrentAnalysis();
  const [showMenu, setShowMenu] = useState(false);
  const queryClient = useQueryClient();
  const updateProject = useUpdateProject({
    onSuccess(data) {
      queryClient.setQueryData(['project', data.projectId], data);
    },
  });

  const setAnalysisName = useCallback(
    (value: string) => {
      if (value !== analysis.name && !updateProject.isPending) {
        analysis.name = value;
        let newAnalysis = undefined;
        if (analysis.type === AnalysisType.INTERNAL_BENCHMARK) {
          newAnalysis = new InternalBenchmarkAnalysisModel(
            analysis.id,
            value,
            analysis.type,
            analysis.version,
            analysis.status,
            analysis.created_at,
            analysis.updated_at,
            (analysis as InternalBenchmarkAnalysisModel).focalPopulation,
            (analysis as InternalBenchmarkAnalysisModel).internalBenchmark
          );
        } else if (analysis.type === AnalysisType.DRIVERS_OF_OUTCOME) {
          newAnalysis = new DriversOfOutcomeAnalysisModel(
            analysis.id,
            value,
            analysis.type,
            analysis.version,
            analysis.status,
            analysis.created_at,
            analysis.updated_at,
            (analysis as DriversOfOutcomeAnalysisModel).focalPopulation,
            (analysis as DriversOfOutcomeAnalysisModel).outcome,
            (analysis as DriversOfOutcomeAnalysisModel).features,
            (analysis as DriversOfOutcomeAnalysisModel).internalBenchmark,
            (analysis as DriversOfOutcomeAnalysisModel).externalBenchmark,
            (analysis as DriversOfOutcomeAnalysisModel).overtimeComparison
          );
        }

        const updatedProject = {
          ...project,
          updatedAnalyses: [newAnalysis],
          newAnalyses: [],
          deletedAnalyses: [],
        };
        updateProject.mutate(updatedProject);
      }
    },
    [project, analysis, updateProject.isPending]
  );

  return (
    <Flex gap={5} align="center">
      <InlineEditableText
        onClick={() => setShowMenu((o) => !o)}
        editWithDoubleClick={false}
        textStyle={{
          fontWeight: 700,
          fontSize: '1.125rem',
          lineHeight: 1.55,
        }}
        tooltipIcon="Rename analysis"
        loading={updateProject.isPending}
        value={analysis.name}
        onChange={setAnalysisName}
      />
      <Popover
        opened={showMenu}
        onChange={setShowMenu}
        position="bottom-end"
        keepMounted
        shadow=""
      >
        <Popover.Target>
          <ActionIcon size={'sm'} onClick={() => setShowMenu((o) => !o)}>
            {!updateProject.isPending && (
              <Tooltip label="Switch analysis" openDelay={200} withinPortal>
                <IconSelector />
              </Tooltip>
            )}
          </ActionIcon>
        </Popover.Target>

        <Popover.Dropdown p={0}>
          <AnalysisSelectorMenu
            currentAnalysisId={analysis?.id}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
          />
        </Popover.Dropdown>
      </Popover>
    </Flex>
  );
};

interface AnalysisSelectorMenuProps {
  currentAnalysisId?: string;
  showMenu: boolean;
  setShowMenu: (value: boolean) => void;
}

const AnalysisSelectorMenu: React.FC<AnalysisSelectorMenuProps> = ({
  currentAnalysisId,
  showMenu,
  setShowMenu,
}) => {
  const project = useCurrentProject();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [newAnalysisModalIsOpen, setNewAnalysisModalIsOpen] = useState(false);
  const [fuse, setFuse] = useState<Fuse<IProjectAnalysisModel>>(
    new Fuse(project?.analyses ?? [], FuseOptions)
  );

  useEffect(() => {
    if (showMenu) {
      setSearchQuery('');
      if (inputRef.current) {
        setTimeout(() => {
          inputRef.current.focus();
        }, 200);
      }
    }
  }, [showMenu, inputRef.current]);

  useEffect(() => {
    setFuse(new Fuse(project?.analyses ?? [], FuseOptions));
  }, [project?.analyses]);

  const handleClick = useCallback(
    (analysisId: string) => {
      setShowMenu(false);
      navigate(`/projects/${project.projectId}/analyses/${analysisId}`);
    },
    [project?.projectId]
  );

  const SearchIcon =
    searchQuery === '' ? (
      <IconSearch size={14} color="lightgray" />
    ) : (
      <ActionIcon variant="transparent" onClick={() => setSearchQuery('')}>
        <IconX size={14} color="gray" />
      </ActionIcon>
    );

  const searchResult = useMemo(() => {
    return searchQuery
      ? fuse.search(searchQuery).map((r) => r.item)
      : project?.analyses ?? [];
  }, [fuse, searchQuery, project?.analyses]);

  return (
    <Stack spacing={0}>
      <NewAnalysisModal
        opened={newAnalysisModalIsOpen}
        onClose={() => setNewAnalysisModalIsOpen(false)}
      />
      <Group p="xs" py={5}>
        <Input
          ref={inputRef}
          style={{ width: 300 }}
          rightSection={SearchIcon}
          placeholder="Search analysis"
          size="sm"
          onChange={(e) => setSearchQuery(e.currentTarget.value)}
          value={searchQuery}
        />
      </Group>
      <Divider m={0} />
      <Group p={'xs'}>
        <ScrollArea type="scroll" h={200} maw={300}>
          <Stack spacing={0}>
            {searchResult.map((a) => (
              <AnalysisItem
                key={a.id}
                analysis={a}
                currentAnalysisId={currentAnalysisId}
                handleClick={handleClick}
              />
            ))}
          </Stack>
          {searchResult.length === 0 && (
            <Stack align="center" style={{ width: 300 }} p="xl">
              <Text>No results</Text>
              <Anchor
                style={{ height: 36 }}
                c="red"
                onClick={() => setSearchQuery('')}
              >
                Remove filters
              </Anchor>
            </Stack>
          )}
        </ScrollArea>
      </Group>
      <Divider m={0} />
      <Group p="xs">
        <Button
          style={{ flexGrow: 1 }}
          leftIcon={<IconPlus size={18} />}
          onClick={() => {
            setShowMenu(false);
            setNewAnalysisModalIsOpen(true);
          }}
        >
          Create new analysis
        </Button>
      </Group>
    </Stack>
  );
};

const AnalysisItem: React.FC<{
  analysis: IProjectAnalysisModel;
  currentAnalysisId: string;
  handleClick: (id: string) => void;
}> = ({ analysis, currentAnalysisId, handleClick }) => {
  const { hovered, ref } = useHover();
  return (
    <Box
      ref={ref}
      style={{
        width: 300,
        borderRadius: '0.25rem',
        cursor: 'pointer',
      }}
      onClick={() => handleClick(analysis.id)}
      px="xs"
      py={5}
      bg={
        currentAnalysisId === analysis.id
          ? 'blue'
          : hovered
          ? '#f1f3f5'
          : 'white'
      }
      c={currentAnalysisId === analysis.id ? 'white' : 'black'}
    >
      <Text>{analysis.name}</Text>
    </Box>
  );
};

export default AnalysisSelector;
