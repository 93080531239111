import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
    Center,
    useMantineTheme,
    Text,
    Popover,
    Anchor,
    List,
    Tooltip,
} from '@mantine/core';
import * as echarts from 'echarts';
import {
    useAnalysisStore,
} from '@stores/AnalysisStore';
import {
    BenchmarkDataPoint,
    BenchmarkDataPointChartType,
} from './datamodels/BenchmarkDataPoint';

import darkTheme from './themes/dark-theme.json';
import APIErrorMessage from '@components/APIErrorMessage';
import { useCurrentAnalysis } from '@hooks/useCurrentAnalysis';
import { useTopicStore } from '@stores/TopicStore';
import { SumaryData, useSummaryData } from '@apis/hooks/useSummaryData';
import { useCurrentSummary } from '@hooks/useCurrentSummary';
import { useElementSize } from '@mantine/hooks';

echarts.registerTheme('dark', darkTheme);

const formatTitleText = (
    focalPopulation: string,
    additionalPopulations: string[]
) => {
    const MAX_DISPLAY = 3; // Maximum number of populations to display
    const additionalCount = additionalPopulations.length;

    if (additionalCount <= MAX_DISPLAY) {
        return `${focalPopulation} compared against ${additionalPopulations.join(
            ', '
        )}`;
    } else {
        const displayedPopulations = additionalPopulations
            .slice(0, MAX_DISPLAY)
            .join(', ');
        const remainingCount = additionalCount - MAX_DISPLAY;
        return `${focalPopulation} compared against ${displayedPopulations} and ${remainingCount} others...`;
    }
};

const formatInteractiveTitle = (
    focalPopulation: string,
    additionalPopulations: string[],
    theme
): React.ReactNode => {
    const MAX_DISPLAY = 3; // Maximum number of populations to display
    const additionalCount = additionalPopulations.length;

    if (additionalCount <= MAX_DISPLAY) {
        return (
            <Text>
                {focalPopulation} compared against {additionalPopulations.join(', ')}
            </Text>
        );
    } else {
        const displayedPopulations = additionalPopulations
            .slice(0, MAX_DISPLAY)
            .join(', ');
        const remainingCount = additionalCount - MAX_DISPLAY;

        const popoverDetail = (
            <>
                <Popover withArrow>
                    <Popover.Target>
                        <Tooltip
                            label="Click to see all populations"
                            position="bottom"
                            openDelay={300}
                        >
                            <Anchor
                                underline
                                weight={600}
                                color={theme === 'dark' ? 'dimmed' : 'dark'}
                            >
                                {remainingCount} others...
                            </Anchor>
                        </Tooltip>
                    </Popover.Target>
                    <Popover.Dropdown mah={400} style={{ overflow: 'scroll' }}>
                        <Text weight={500}>{focalPopulation} against:</Text>
                        <List withPadding>
                            {additionalPopulations.map((population: string) => {
                                return <List.Item key={population}>{population}</List.Item>;
                            })}
                        </List>
                    </Popover.Dropdown>
                </Popover>
            </>
        );

        return (
            <Text weight={400}>
                {focalPopulation} compared against {displayedPopulations} and{' '}
                {popoverDetail}
            </Text>
        );
    }
};

const ProgressOverTimeChart: FC = () => {
    const theme = useMantineTheme();
    const rawData = useAnalysisStore((s) => s.data);
    const isLoadingData = useAnalysisStore((s) => s.isLoadingData);
    const activeTopics = useAnalysisStore((s) => s.activeTopics);
    const activeThemes = useAnalysisStore((s) => s.activeThemes);
    const allPopulations = useAnalysisStore((s) => s.allPopulations);
    const focalPopulation = useAnalysisStore((s) => s.focalPopulation);
    const numberOfMentions = useAnalysisStore((s) => s.numberOfMentions);
    const currentAnalysis = useCurrentAnalysis();
    const currentSummary = useCurrentSummary();

    // const [firstPopulation, secondPopulation] = allPopulations;

    const [firstPopulation, setFirstPopulation] = useState(focalPopulation)
    const [secondPopulation, setSecondPopulation] = useState(allPopulations.filter(((d: string) => d !== focalPopulation))[0])


    useEffect(() => {
        if (focalPopulation) {
            setFirstPopulation(focalPopulation)
            setSecondPopulation(
                allPopulations.filter(((d: string) => d !== focalPopulation))[0]
            )
        }
    }, [focalPopulation])

    const { data: _summaryData } = useSummaryData(currentSummary);
    const summaryData = useMemo(() => {
        const analisys = (currentSummary?.analyses ?? []).find(a => a.id === currentAnalysis.id);
        if (!currentSummary || !analisys || analisys.focalPopulation !== focalPopulation) {
            return null
        }
        return _summaryData;
    }, [currentAnalysis?.id, currentSummary, focalPopulation, _summaryData])
    const [chartInstance, setChartInstance] = useState<echarts.EChartsType | undefined>();
    const { ref: referenceComponent, width, height } = useElementSize();
    useEffect(() => chartInstance?.resize(), [width, height])
    const targetComponent = useRef(null);

    // ERRORS -
    const isBenchmarkError = useAnalysisStore((s) => s.dataIsError);
    const benchmarkError = useAnalysisStore((s) => s.dataError);

    const setFocalTopic = useTopicStore((s) => s.setFocalTopic);

    const data: BenchmarkDataPoint[] = useMemo(() => {
        if (!firstPopulation) {
            return [];
        }
        return rawData
            .filter((dataPoint: BenchmarkDataPoint) => {
                return (
                    (activeTopics.includes(dataPoint.topic) ||
                        activeThemes.includes(dataPoint.topic)) &&
                    // dataPoint.vopicCount >= numberOfMentions &&
                    allPopulations.includes(dataPoint.populationName)
                );
            })
            .filter(
                (point: BenchmarkDataPoint) => point.populationName === firstPopulation
            );
    }, [
        rawData,
        activeTopics,
        activeThemes,
        allPopulations,
        numberOfMentions,
        firstPopulation
    ]);

    const data2: BenchmarkDataPoint[] = useMemo(() => {
        if (!secondPopulation) {
            return [];
        }
        return rawData
            .filter((dataPoint: BenchmarkDataPoint) => {
                return (
                    (activeTopics.includes(dataPoint.topic) ||
                        activeThemes.includes(dataPoint.topic)) &&
                    // dataPoint.vopicCount >= numberOfMentions &&
                    allPopulations.includes(dataPoint.populationName)
                );
            })
            .filter(
                (point: BenchmarkDataPoint) => point.populationName === secondPopulation
            );
    }, [
        rawData,
        activeTopics,
        activeThemes,
        allPopulations,
        numberOfMentions,
        secondPopulation
    ]);


    const IsEmptyData = data.length === 0;
    // const title = `${focalPopulation} Benchmark Score against ${(currentProject && currentProject.version === "1.0.0") ? (currentAnalysis as InternalBenchmarkAnalysisModel).internalBenchmark : "Benchmark"}`;

    const title = formatTitleText(
        focalPopulation,
        Array.from(
            new Set(
                rawData
                    .map((d: BenchmarkDataPoint) => d.populationName)
                    .filter((d: string) => d !== focalPopulation)
            )
        ).sort()
    );
    const interactiveTitle = formatInteractiveTitle(
        focalPopulation,
        Array.from(
            new Set(
                rawData
                    .map((d: BenchmarkDataPoint) => d.populationName)
                    .filter((d: string) => d !== focalPopulation)
            )
        ).sort(),
        theme.colorScheme
    );

    const addExcludeKeepGraphic = (
        chart: any,
        seriesIndex: number,
        selectedPoint: BenchmarkDataPoint
    ) => {
        // Create a custom graphic element to show the box and buttons
        var boxGroup = {
            type: 'group',
            left:
                chart.convertToPixel({ seriesIndex: seriesIndex }, [
                    selectedPoint.benchmarkScore,
                    selectedPoint.topicUserFriendlyName,
                ])[0] - 75,
            top:
                chart.convertToPixel({ seriesIndex: seriesIndex }, [
                    selectedPoint.benchmarkScore,
                    selectedPoint.topicUserFriendlyName,
                ])[1] - 40,
            children: [
                {
                    type: 'rect',
                    left: '-50',
                    top: '-15',
                    z: 100,
                    shape: {
                        width: 125,
                        height: 25,
                        r: 2,
                    },
                    style: {
                        fill: '#fff',
                        stroke: '#999',
                        lineWidth: 1,
                    },
                },
                {
                    type: 'text',
                    left: '-35',
                    top: '-10',
                    z: 100,
                    style: {
                        text: 'Exclude',
                        textAlign: 'center',
                        textFill: '#333',
                        fontSize: 14,
                    },
                    onclick: function () {
                        // Add your code here to exclude the selected point
                        // TODO add
                        //props.onPointExcluded && props.onPointExcluded(selectedPoint);
                        removeExcludeKeepGraphic(chart);
                    },
                },
                {
                    type: 'text',
                    left: '20',
                    top: '-10',
                    z: 100,
                    style: {
                        text: 'Focus',
                        textAlign: 'center',
                        textFill: '#333',
                        fontSize: 14,
                    },
                    onclick: function () {
                        // Add your code here to keep only the selected point
                        // TODO add
                        //props.onPointFocus && props.onPointFocus(selectedPoint);
                        removeExcludeKeepGraphic(chart);
                    },
                },
            ],
        };

        // Add the custom graphic element to the chart
        chart.setOption({
            graphic: selectedPoint ? boxGroup : null,
        });
    };

    const addExcludeKeepGraphicToRange = (
        chart: any,
        selectedRange: any[],
        selectedPoints: BenchmarkDataPoint[]
    ) => {
        // Create a custom graphic element to show the box and buttons
        const [leftMostX, rightMostX] = selectedRange[0];
        const [leftMostY, rightMostY] = selectedRange[1];
        var boxGroup = {
            type: 'group',
            x: rightMostX - 100, // Adjusted to center the box group horizontally
            y: leftMostY - 12,
            children: [
                {
                    type: 'rect',
                    left: '-50',
                    top: '-15',
                    z: 100000,
                    shape: {
                        width: 150,
                        height: 25,
                        r: 2,
                    },
                    style: {
                        fill: '#fff',
                        stroke: '#999',
                        lineWidth: 1,
                    },
                },
                {
                    type: 'text',
                    left: '-35',
                    top: '-10',
                    z: 100000,
                    style: {
                        text: 'Exclude',
                        textAlign: 'center',
                        textFill: '#333',
                        fontSize: 14,
                        cursor: 'pointer',
                    },
                    onclick: function () {
                        // Add your code here to exclude the selected range
                        const formattedSelectedRange = selectedPoints.map((point: any) => {
                            return BenchmarkDataPoint.fromChartDataPoint(
                                point,
                                BenchmarkDataPointChartType.BenchmarkPopulationTopic
                            );
                        });
                        // TODO add
                        //props.onPointsExcluded && props.onPointsExcluded(formattedSelectedRange);
                        removeExcludeKeepGraphic(chart);
                    },
                },
                {
                    type: 'text',
                    left: '20',
                    top: '-10',
                    z: 1000000,
                    style: {
                        text: 'Keep Only',
                        textAlign: 'center',
                        textFill: '#333',
                        fontSize: 14,
                        cursor: 'pointer !important',
                    },
                    onclick: function () {
                        // Add your code here to keep only the selected range
                        const formattedSelectedRange = selectedPoints.map((point: any) => {
                            return BenchmarkDataPoint.fromChartDataPoint(
                                point,
                                BenchmarkDataPointChartType.BenchmarkPopulationTopic
                            );
                        });
                        // TODO add
                        //props.onPointsKeepOnly && props.onPointsKeepOnly(formattedSelectedRange);
                        removeExcludeKeepGraphic(chart);
                    },
                },
            ],
        };

        // Add the custom graphic element to the chart
        chart.setOption({
            graphic: boxGroup,
        });
    };

    const removeExcludeKeepGraphic = (chart: any) => {
        // Remove the custom graphic element from the chart
        const currentOptions = chart.getOption();

        currentOptions.graphic = null;

        chart.setOption(currentOptions, true);
    };

    const getColorForBenchmarkScore = (score: number): string => {
        const DARK_RED = 'rgb(190,42,62)';
        const ORANGE = 'rgb(239,119,74)';
        const YELLOW = 'rgb(244,209,102)';
        const LIGHT_GREEN = 'rgb(105,172,102)';
        const DARK_GREEN = 'rgb(35,119,63)';

        if (score > 2) {
            return DARK_GREEN
        }
        if (score > 1 && score <= 2) {
            return LIGHT_GREEN
        }
        if (score > -1 && score <= 1) {
            return YELLOW
        }
        if (score > -2 && score <= -1) {
            return ORANGE
        }
        return DARK_RED


        // if (score > 2) {
        //     return DARK_GREEN;
        // } else if (score > 1 && score <= 2) {
        //     return LIGHT_GREEN;
        // } else if (score > -1 && score <= 1) {
        //     return YELLOW;
        // } else if (score > -2 && score <= -1) {
        //     return ORANGE;
        // } else {
        //     return DARK_RED;
        // }
    };

    const getChartOptions = (firstPopulationData: BenchmarkDataPoint[], secondPopulationData: BenchmarkDataPoint[]) => {

        if (firstPopulationData.length === 0 || secondPopulationData.length === 0) {
            return {};
        }


        if (firstPopulationData.length !== secondPopulationData.length) {
            console.error("The populations are not the same length", firstPopulationData.length, secondPopulationData.length)
        }

        const firstPopLength = firstPopulationData.length;
        const secondPopLength = secondPopulationData.length;
        const populationLength = Math.max(firstPopLength, secondPopLength);

        const xAxisOptions = [];
        const yAxisOptions = [];
        const seriesOptions = [];
        const gridOptions = [];
        const titleOptions = [];

        let mixedArr: {
            firstPopulationElement: BenchmarkDataPoint,
            secondPopulationElement: BenchmarkDataPoint,
            theCalculation: number
        }[] = [];

        for (let index = 0; index < populationLength; index++) {
            const firstPopulationElement = firstPopulationData[index];
            const secondPopulationElement = secondPopulationData[index];
            if (!firstPopulationElement || !secondPopulationElement) {
                continue
            }
            let theCalculation = (10 / 3) * (((firstPopulationElement.positiveIncidence - secondPopulationElement.positiveIncidence) - (firstPopulationElement.negativeIncidence - secondPopulationElement.negativeIncidence)) / ((firstPopulationElement.incidence + secondPopulationElement.incidence) / 2))

            if (Number.isNaN(theCalculation)) {
                theCalculation = 0
            }

            mixedArr.push({
                firstPopulationElement,
                secondPopulationElement,
                theCalculation
            })
        }
        mixedArr = mixedArr.sort((a, b) => a.theCalculation - b.theCalculation)
        // console.log(JSON.stringify(mixedArr),"MIXED")

        mixedArr = mixedArr.filter((d) => d.firstPopulationElement.vopicCount >= numberOfMentions && d.secondPopulationElement.vopicCount >= numberOfMentions)

        if (mixedArr.length === 0) {
            return {}
        }

        const lowestCalc = Math.min(
            mixedArr[mixedArr.length - 1].theCalculation
        );
        const highestCalc = Math.max(
            mixedArr[0].theCalculation
        );


        xAxisOptions.push({
            type: 'value',
            gridIndex: 0,
            min: lowestCalc * 1.1,
            max: highestCalc * 1.1,
            axisLabel: {
                color: 'black',
                formatter: function (value) {
                    return value.toFixed(1);
                },
            },
        })

        yAxisOptions.push({
            type: 'category',
            gridIndex: 0,
            data: mixedArr.map(
                (d) => d.firstPopulationElement.topicUserFriendlyName
            ),
            // show: index == 0 ? true : false,
            axisLine: {
                show: 0 === 0 ? true : false,
            },
            axisTick: {
                show: 0 === 0 ? true : false,
            },
            axisLabel: {
                color: 'black',
                show: 0 === 0 ? true : false, // Only show the axis labels for the first grid
            },
            splitLine: {
                show: true,
                lineStyle: {
                    // type: 'dashed',
                    // color: 'lightgray'
                },
            },
        });

        seriesOptions.push({
            type: 'bar',
            colorBy: 'data',
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: mixedArr.map((d, i: number) => {
                return {
                    value: d.theCalculation,
                    itemStyle: {
                        color: getColorForBenchmarkScore(d.theCalculation),
                        borderRadius: d.theCalculation > 0 ? [0, 5, 5, 0] : [5, 0, 0, 5],
                    },
                    basePoint: focalPopulation === firstPopulation ? d.firstPopulationElement : d.secondPopulationElement,
                };
            }),
            label: {
                show: true,
                position: 'outside',
                formatter: (params: any) => {
                    // Round value to 1 decimal places...
                    return `${Math.round(params.value * 10) / 10}`;
                },
            },
        });
        const gridWidth = 100;

        gridOptions.push({
            "left": "0%",
            "right": "20%",
            "width": "95%",
            "height": "90%",
            "containLabel": true
        })

        const titleOption = {
            text: "",
            left: `${gridWidth * 0 + gridWidth / 2}%`,
            top: '2%',
            textAlign: 'center',
            textStyle: {
                fontSize: 16,
            },
        };
        titleOptions.push(titleOption);
        return {
            xAxis: xAxisOptions,
            yAxis: yAxisOptions,
            series: seriesOptions,
            grid: gridOptions,
            title: titleOptions,
            dataZoom: [
                {
                    type: 'inside',
                    id: 'insideY',
                    yAxisIndex: 0, // This targets the yAxis for vertical scrolling
                    filterMode: 'none',
                    zoomLock: false,
                    start: 100,
                    end: 100 - (15 / mixedArr.length) * 100,
                    preventDefaultMouseMove: false,
                    zoomOnMouseWheel: false,
                    moveOnMouseMove: true,
                    moveOnMouseWheel: true,
                }
            ]
        }
    }
    useEffect(() => {
        const chartElement = document.getElementById('myChart');
        if (!chartElement) {
            return;
        }
        const chart = echarts.init(chartElement, theme.colorScheme);

        // TODO: - Format the data such that we are showing all of the topics + populations in the order we want...
        // TODO: - Also add coloring, etc. to the data points and make the scroll unified across the two grids...

        const { xAxis, yAxis, series, grid, title, dataZoom } =
            getChartOptions(data,
                data2
            );

        const option: any = {
            legend: {},
            tooltip: {
                trigger: 'item',
                formatter: (params: any) => {
                    // Format the tooltip to have the following:
                    // 1. The name of the population
                    // 2. The percentage of employees mentioning the population
                    // 3. The percentage of mentions favorable

                    // Get the data
                    const data = params.data;
                    const formattedPoint = BenchmarkDataPoint.fromChartDataPoint(
                        data,
                        BenchmarkDataPointChartType.BenchmarkPopulationTopic
                    );
                    // Return as a HTML box with the data inside

                    // Round to 1 decimal place... But make sure there is always a decimal place...
                    const roundedBenchmarkScore =
                        formattedPoint.benchmarkScore.toFixed(1);

                    let summaryText = ""
                    const summaryTopicData: SumaryData | undefined = (summaryData ?? []).find(d => d.topic === formattedPoint.topic.toLowerCase())
                    if (summaryTopicData) {
                        summaryText = `
            <div>
                <br/>
                <div><b>Values in component analyses</b></div>
                ${Object.keys(summaryTopicData).map(k => {
                            if (k !== "topic" && k !== "prioritize") {
                                const result = `<div>${(summaryTopicData[k] as any).name}: <b>${(summaryTopicData[k] as any).value?.toFixed(1)}</b></div>`
                                if (k === currentAnalysis?.id) {
                                    return `<b>${result}</b>`
                                }
                                return result
                            }
                            return null
                        }).filter(a => a !== null).join("")
                            }
            </div>`
                    }

                    return `
                        <div>
                            <div><b>${formattedPoint.topicUserFriendlyName
                        }</b></div>
                            <div>Benchmark: <b>${roundedBenchmarkScore}</b></div>
                            <div>Population: <b>${formattedPoint.populationName
                        }</b></div>
                            <div># Responses: <b>${formattedPoint.rowCount.toLocaleString()}</b></div>
                            <div># Mentions of Topic: <b>${formattedPoint.vopicCount.toLocaleString()}</b></div>
                            <div>Incidence: <b>${formattedPoint.incidence.toLocaleString(
                            undefined,
                            {
                                style: 'percent',
                                maximumFractionDigits: 1,
                            }
                        )}</b></div>
                            <div>Sentiment: <b>${formattedPoint.sentiment.toLocaleString(
                            undefined,
                            {
                                style: 'percent',
                                maximumFractionDigits: 1,
                            }
                        )}</b></div>
                        </div>
                    ` + summaryText;
                },
                borderColor: '#ffffff',
                padding: 4,
                extraCssText: `
                    box-shadow: 0 0 3px rgba(0, 0, 0, 0.10);
                    border-radius: 2px;
                `,
            },
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {
                        pixelRatio: 4,
                    },
                },
            },
            // brush: {
            //     toolbox: ["rect", "clear"],
            //     transformable: false,
            //     saveAsImage: {},
            //     inBrush: {
            //         opacity: 1,
            //         borderRadius: 3,
            //         borderColor: "black"
            //     },
            //     outOfBrush: {
            //         color: "gray",
            //         opacity: 0.2
            //     }
            // },
            title: title,
            xAxis: xAxis,
            yAxis: yAxis,
            grid: grid,
            series: series,
            dataZoom: dataZoom,
            interactive: true,
            animationEasing: 'elasticOut',
            animationDelay: 50,
        };

        // Get the chart element
        chart.setOption(option);

        // // Add an onClick event to the chart...
        chart.on('click', (params: any) => {
            if (params.componentType === 'series') {
                const selectedPoint = params.data;
                const formattedSelectedPoint = BenchmarkDataPoint.fromChartDataPoint(
                    selectedPoint,
                    BenchmarkDataPointChartType.BenchmarkPopulationTopic
                );

                setFocalTopic(formattedSelectedPoint.topic);
                // addExcludeKeepGraphic(chart, params.seriesIndex, formattedSelectedPoint);
                // TODO add
                //props.onPointSelected && props.onPointSelected(formattedSelectedPoint);
            }
        });

        chart.on('brushEnd', (params: any) => {
            // FIXME: - There's a bug here that causes the chart not to return partially selected points even though they are technically inside the selected range
            // This is to do with the fact that the coordPoint that is returned is not the bounding box, but just a single point...
            // To fix this, a good way might be to look at the seriesIndex + dataPointIndex and figure out if the data point is within the selected range...
            if (params.areas.length === 0) {
                // removeExcludeKeepGraphic(chart);
                // TODO add
                //props.onPointsDeselected && props.onPointsDeselected();
                return;
            }

            const selectedRange = params.areas[0].range;
            const chartOptions = chart.getOption();

            if (chartOptions && chartOptions.series) {
                const selectedData: BenchmarkDataPoint[] = [];
                const selectedDataPoints: any = [];
                chartOptions.series.forEach(
                    (series: echarts.EChartOption.Series, seriesIndex: number) => {
                        const dataPoints: any[] | undefined = series.data;

                        // Go through each data point and get it's pixel coordinate...
                        // Then check if the pixel coordinate is within the selected range...
                        // If it is, then add it to the selected data points...
                        if (dataPoints) {
                            dataPoints.forEach((dataPoint: any) => {
                                const pixelCoord = chart.convertToPixel(
                                    { seriesIndex: seriesIndex },
                                    [dataPoint.value, dataPoint.basePoint.topicUserFriendlyName]
                                );
                                const xCoord = pixelCoord[0];
                                const yCoord = pixelCoord[1];
                                if (
                                    xCoord >= selectedRange[0][0] &&
                                    xCoord <= selectedRange[0][1] &&
                                    yCoord >= selectedRange[1][0] &&
                                    yCoord <= selectedRange[1][1]
                                ) {
                                    const formattedDataPoint =
                                        BenchmarkDataPoint.fromChartDataPoint(
                                            dataPoint,
                                            BenchmarkDataPointChartType.BenchmarkPopulationTopic
                                        );
                                    selectedData.push(formattedDataPoint);
                                    selectedDataPoints.push(dataPoint);
                                }
                            });
                        }

                        addExcludeKeepGraphicToRange(chart, selectedRange, selectedData);
                    }
                );

                // Return the points to the caller
                // TODO add
                //props.onPointsSelected && props.onPointsSelected(selectedData);
            }
        });

        chart.getZr().on('click', (event: any) => {
            if (!event.target && event.componentType !== 'graphic') {
                // Call internal method to update the chart such that there are no selected points
                // Remove from ALL series, just just the first one...
                // Get the number of unique population name.s..
                const uniquePopulations = new Set(
                    Object.keys(data).map((key: string) => data[key].populationName)
                );
                for (let i = 0; i < uniquePopulations.size; i++) {
                    chart.dispatchAction({
                        type: 'unselect',
                        seriesIndex: i,
                        dataIndex: Array.from({ length: data.length }, (v, k) => k),
                    });
                }

                // Remove any graphic from the chart
                removeExcludeKeepGraphic(chart);

                // Call function to update that no points are selected
                // TODO add
                //props.onPointsDeselected && props.onPointsDeselected();
            }
        });

        if (isLoadingData === true) {
            chart.showLoading('default', {
                text: 'Loading...',
                fontSize: 16,
                color: '#4589df',
                textColor: '#000',
                zlevel: 0,
            });
        // } else if (IsEmptyData && !isLoadingData) {
        } else if (IsEmptyData && !isLoadingData) {
            chart.setOption({
                title: {
                    text: 'No Data Available',
                    subtext:
                        'This may be due to a filter being applied, or because the data is not available yet',
                    top: 'center',
                    textStyle: {
                        fontSize: 20,
                    },
                    subtextStyle: {
                        fontSize: 16,
                    },
                },
            });
        } else {
            chart.hideLoading();
        }

        setChartInstance(chart);

        return () => {
            setChartInstance(undefined);
            chart.dispose();
        };
    }, [data, data2, summaryData, currentAnalysis?.id, theme.colorScheme, IsEmptyData, isLoadingData, focalPopulation, numberOfMentions]);

    if (isLoadingData === false && isBenchmarkError && benchmarkError) {
        return (
            <Center style={{ height: '100%' }}>
                <APIErrorMessage response={benchmarkError} />
            </Center>
        );
    }

    return (
        <div style={{ position: "relative", height: '100%', width: "100%" }}>
            <div
                ref={targetComponent}
                style={{
                    position: 'absolute',
                    top: '0px',
                    height: '45px',
                    width: "calc(100% - 30px)",
                    backgroundColor:
                        theme.colorScheme === 'dark' ? 'rgb(26,27,30)' : 'white',
                    zIndex: 20,
                }}
            >
                <Center style={{ display: "flex", flexDirection: "column" }}>
                    <p style={{ marginBottom: "0px" }}>
                        {interactiveTitle}
                    </p>
                    <p
                        style={{ marginTop: "3px", fontSize: "11px", color: "gray" }}
                    >Green is better in {firstPopulation}, red is better in {secondPopulation}, yellow is similar</p>
                </Center>

            </div>
            <div
                ref={referenceComponent}
                id="myChart"
                style={{ width: '100%', height: '100%', overflow: 'hidden', marginTop: "20px" }}
            ></div>
        </div>
    );
};

export default ProgressOverTimeChart;
