import { Analysis } from '@hooks/useProjectAnalyses';
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Card,
  Collapse,
  Flex,
  Group,
  ScrollArea,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core';
import Fuse from 'fuse.js';
import { FC, ReactNode, useCallback, useState } from 'react';
import { SummaryCard } from './SummaryCard';
import { IProjectAnalysisModel } from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { ProjectDownloadDataModal } from './ProjectDownloadDataModal';
import { Summary } from '@hooks/useProjectSummaries';

interface SummaryTabProps {
  data: Fuse.FuseResult<Summary>[];
}

export const SummaryTab: FC<SummaryTabProps> = ({
  data,
}) => {
  const project = useCurrentProject();

  return (
    <>
      <SimpleGrid
        breakpoints={[
          { minWidth: 'sm', cols: 2 },
          { minWidth: 'md', cols: 3 },
          { minWidth: 1200, cols: 4 },
        ]}
      >
        {data.map((i) => (
          <SummaryCard
            key={i.item.id}
            project={project}
            summary={i.item}
            matches={i.matches}
          />
        ))}
      </SimpleGrid>  
    </>
  );
};
