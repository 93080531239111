import { useQuery } from '@tanstack/react-query';
import ProjectAPI from '@apis/ProjectAPI';

export const useProjects = () => {
  return useQuery({
    queryKey: ['projects'],
    queryFn: () => ProjectAPI.describeProjects(),
    staleTime: 1000 * 60 * 10, // 10 minutes
  });
};
