import {
  ActionIcon,
  Card,
  Checkbox,
  Flex,
  Group,
  MantineProvider,
  Progress,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';

import { IconGripHorizontal, IconX } from '@tabler/icons-react';
import { DatasetField } from '@hooks/useDatasetFields';
import { useDraggable } from '@dnd-kit/core';
import { DistributionRow } from '@apis/hooks/useDistributionPoints';
import { useAnalysisEditionStore } from '@stores/AnalysisEditionStore';

interface ExplorationTableItemProps {
  data: DistributionRow;
  prevValue?: DistributionRow;
  nextValue?: DistributionRow;
  index: number;
  maxCountValue: number;
}

const ExplorationTableItem: FC<ExplorationTableItemProps> = ({
  data,
  index,
  prevValue,
  nextValue,
  maxCountValue,
}) => {
  const selectedDistributionRows = useAnalysisEditionStore(
    (s) => s.selectedDistributionRows
  );
  const isSelected = useMemo(
    () => selectedDistributionRows.includes(data.id),
    [data.id, selectedDistributionRows]
  );
  const isPrevSelected = useMemo(
    () => (prevValue ? selectedDistributionRows.includes(prevValue.id) : false),
    [prevValue, selectedDistributionRows]
  );
  const isNextSelected = useMemo(
    () => (nextValue ? selectedDistributionRows.includes(nextValue.id) : false),
    [nextValue, selectedDistributionRows]
  );
  const hideTopBorderRadious = useMemo(
    () => isSelected && isPrevSelected,
    [isSelected, isPrevSelected]
  );
  const hideBttomBorderRadious = useMemo(
    () => isSelected && isNextSelected,
    [isSelected, isNextSelected]
  );

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: data.id,
    data: {
      type: 'row',
    },
  });

  const style = {
    cursor: 'pointer',
    zIndex: 3,
    opacity: isDragging ? 0.5 : undefined,
  };

  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Card
        id={data.id}
        className="target"
        p={2}
        radius="sm"
        style={{
          height: 40,
          display: 'flex',
          alignItems: 'center',
          overflow: 'visible',
          cursor: 'grab',
          borderTop: '0.5px solid white',
          borderTopLeftRadius: hideTopBorderRadious ? 0 : undefined,
          borderTopRightRadius: hideTopBorderRadious ? 0 : undefined,
          borderBottomLeftRadius: hideBttomBorderRadious ? 0 : undefined,
          borderBottomRightRadius: hideBttomBorderRadious ? 0 : undefined,
        }}
        bg={
          isSelected
            ? 'blue'
            : isHover
            ? '#e9ecef'
            : index % 2 === 0
            ? '#f4f5f5'
            : 'white'
        }
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Group
          align="start"
          style={{ position: 'relative', width: '100%' }}
          grow
          p={0}
        >
          {data.columns.map((v, i) => {
            return (
              <div key={v.column + i}>
                <Tooltip openDelay={400} label={v.value ?? 'BLANK'}>
                  <Text
                    color={
                      !!v.value
                        ? isSelected
                          ? 'white'
                          : 'black'
                        : isSelected
                        ? 'white'
                        : 'grey'
                    }
                    size="sm"
                    pl="xl"
                    fs={!!v.value ? '' : 'italic'}
                    style={{
                      maxWidth: '80%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {v.value ?? 'BLANK'}
                  </Text>
                </Tooltip>
              </div>
            );
          })}
          <Flex pl="xl" align="center" gap="xs">
            <MantineProvider
              theme={{
                fontFamily: 'Verdana, sans-serif',
                fontFamilyMonospace: 'Monaco, Courier, monospace',
                headings: { fontFamily: 'Greycliff CF, sans-serif' },
              }}
            >
              <Progress
                style={{ width: 'calc(100% - 100px)', height: 25 }}
                bg="transparent"
                color={isSelected ? 'white' : 'blue'}
                size="xl"
                value={(data.responses * 100) / maxCountValue}
              />
              <Text
                size="sm"
                align="right"
                color={isSelected ? 'white' : 'black'}
                style={{
                  width: '80px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {data.responses.toLocaleString()}
              </Text>
            </MantineProvider>
          </Flex>
        </Group>
      </Card>
    </div>
  );
};

export default ExplorationTableItem;
