import { useQuery } from '@tanstack/react-query';
import DatasetAPI from '@apis/DatasetAPI';
import CRN from '@models/Crn';
import ProjectModel from '@models/ProjectModel';

export const useProjectDatasets = (project?: ProjectModel) => {
  return useQuery({
    queryKey: ['project-datasets', project?.projectId, project?.organizationId],
    queryFn: async () => {
      // Get the CRNs for the datasets to get the metadata...
      var datasetCrns = {};

      for (const [datasetAlias, datasetInfo] of Object.entries(
        project.datasets
      )) {
        datasetCrns[datasetAlias] = CRN.fromString(datasetInfo.DatasetCRN);
      }

      const metadataPromises = Object.values(datasetCrns).map((crn: CRN) =>
        DatasetAPI.getDatasetMetadataWithIds(
          crn.organizationId,
          crn.resourceId
        ).then((metadata) => ({ crn, metadata }))
      );

      const aliasPromises = Object.values(datasetCrns).map((crn: CRN) =>
        DatasetAPI.describeDatasetWithIds(
          crn.organizationId,
          crn.resourceId
        ).then((dataset) => ({ crn, dataset }))
      );

      return Promise.all([
        Promise.all(metadataPromises),
        Promise.all(aliasPromises),
      ]).then(([metadataResults, aliasResults]) => {
        const metadataByAlias = {};
        const datasetMetadataMapping = new Map<string, object>();

        // Create a map for easy lookup of aliases by CRN
        const aliasMap = new Map();
        aliasResults.forEach(({ crn, dataset }) => {
          aliasMap.set(crn, dataset.alias);
        });

        // Merge metadata and aliases
        metadataResults.forEach(({ crn, metadata }) => {
          const datasetAlias = Object.keys(datasetCrns).find(
            (alias) => datasetCrns[alias] === crn
          );
          if (datasetAlias) {
            // Add dataset alias to metadata
            const alias = aliasMap.get(crn);
            if (alias) {
              metadata['DatasetAlias'] = alias;
            }

            metadata['DatasetCrn'] = crn.identifier;
            metadataByAlias[datasetAlias] = metadata;
            datasetMetadataMapping.set(datasetAlias, metadata);
          }
        });

        return datasetMetadataMapping;
      });
    },
    enabled: !!project,
    staleTime: 1000 * 60 * 60, // 60 minutes...
  });
};
