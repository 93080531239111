import APIRequesterBase from './APIBase';
import axios from 'axios';
import UserModel from '../models/UserModel';
import UserInviteModel from '../models/UserInviteModel';

class UserAPI extends APIRequesterBase {
  static async listUsers(organizationId?: number): Promise<UserModel[]> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    const url = `${this.getAPIUrl()}/user/organization/${organizationId}`;

    const headers = await this.getHeaders();

    return axios.get(url, { headers: headers }).then((response) => {
      const users: UserModel[] = response.data.map((user: any) =>
        UserModel.fromAPIResponse(user)
      );
      return users;
    });
  }

  static async listUserInvites(
    organizationId?: number
  ): Promise<UserInviteModel[]> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    const url = `${this.getAPIUrl()}/user/organization/${organizationId}/invite`;

    const headers = await this.getHeaders();

    return axios.get(url, { headers: headers }).then((response) => {
      const users: UserInviteModel[] = response.data.map((user: any) =>
        UserInviteModel.fromAPIResponse(user)
      );
      return users;
    });
  }

  static async deleteUserInvite(
    organizationId: number,
    inviteId: string
  ): Promise<void> {
    const url = `${this.getAPIUrl()}/user/organization/${organizationId}/invite/${inviteId}`;

    const headers = await this.getHeaders();

    return axios.delete(url, { headers: headers }).then((response) => {
      return;
    });
  }

  static async inviteUser(
    organizationId: number,
    email: string,
    userName: string,
    firstName: string,
    lastName: string,
  ): Promise<UserInviteModel> {
    const url = `${this.getAPIUrl()}/user/invite/`;

    const headers = await this.getHeaders();

    const inviteObject = {
      OrganizationId: organizationId,
      Email: email,
      Username: userName,
      FirstName: firstName,
      LastName: lastName,
    };

    return axios.post(url, inviteObject, { headers: headers }).then((response) => {
      return UserInviteModel.fromAPIResponse(response.data);
    });
  }
}

export default UserAPI;
