import React, { createContext, forwardRef, useCallback, useContext, useMemo, useState } from 'react';
import { Anchor, Checkbox, Group, MultiSelect, ScrollArea, SelectItem, Stack, Text } from '@mantine/core';
import { useHover } from '@mantine/hooks';

const MultiselectContext = createContext(null);

interface MantineMultiselectProps {
  id: string,
  listValues: any[];
  value: any;
  setValue: (value: any) => void;
  allowCustomValues: boolean;
  readonly: boolean;
  placeholder: string;
  customProps: any;
}

export const MantineMultiselect: React.FC<MantineMultiselectProps> = (
  props: MantineMultiselectProps
) => {
  const formattedData = useMemo(() => props?.listValues?.map((item: any) => {
    return {
      label: item.title,
      value: item.value,
    };
  }), [props?.listValues]);
  const [lastSelected, setLastSelected] = useState<string | null>(null)
  const [filteredValues, setFilteredValues] = useState<string[]>(formattedData.map(i => i.value))

  const filter = useCallback((value: string, selected: boolean, item: SelectItem) => {
    if(item.value.toLowerCase().trim().includes(value.toLowerCase().trim())) {
      setFilteredValues(fv => {
        if(!fv.includes(item.value)) {
          return [...fv, item.value]
        }
        return fv
      })
      return true;
    }
    setFilteredValues(fv => {
      if(fv.includes(item.value)) {
        return fv.filter(v => v !== item.value)
      }
      return fv
    })
    return false;
  }, [filteredValues])

  const runMultipleSelection = useCallback((value) => {
    let from = formattedData.findIndex(i => i.value === value)
    let to = formattedData.findIndex(i => i.value === lastSelected)
    if(from === -1 || to === -1) {
      return
    }

    if(from > to) {
      const aux = from;
      from = to;
      to = aux;
    }

    const newValues = props.value ? [...props.value] : []
    for(let i=from; i<=to; i++) {
      if(filteredValues.includes(formattedData[i].value) && !newValues.includes(formattedData[i].value)) {
        newValues.push(formattedData[i].value)
      }
    }
    setTimeout(() => props.setValue(newValues), 10)
    setLastSelected(null)
  }, [formattedData, filteredValues, lastSelected, props.value, props.setValue])

  const onItemClick = useCallback((value: string, selected: boolean, shiftKey: boolean) => {
    if(!shiftKey && !selected) {
      setLastSelected(value)
    }
    else if(!shiftKey && selected) {
      setLastSelected(null)
    }
    else if(shiftKey && !selected) {
      if(lastSelected) {
        runMultipleSelection(value)
      }
      else {
        setLastSelected(value)
      }
    }
    else if(shiftKey && selected) {
      setLastSelected(null)
    }
  }, [lastSelected]);

  const selectAll = useCallback(() => {
    const newValues = props.value ? [...props.value] : []
    filteredValues.forEach((value, i) => {
      if(!newValues.includes(value)) {
        newValues.push(value)
      }
    })
    props.setValue(newValues);
  }, [filteredValues, props.value, props.setValue]);

  const unSelectAll = useCallback(() => {
    const newValues = (props.value ? [...props.value] : []).filter(i => !filteredValues.includes(i))
    props.setValue(newValues);
  }, [filteredValues, props.value, props.setValue]);

  return (
    <MultiselectContext.Provider value={{ selectAll, unSelectAll, onItemClick }}>
      <MultiSelect
        size="xs"
        value={props.value}
        onChange={props.setValue}
        placeholder="Select"
        data={formattedData}
        searchable
        filter={filter}
        clearSearchOnChange={false}
        clearSearchOnBlur={false}
        disableSelectedItemFiltering={true}
        dropdownComponent={MantineMultiselectDropdown}
        itemComponent={MantineMultiselectItem}
      />
    </MultiselectContext.Provider>
  );
};

const MantineMultiselectDropdown = forwardRef<
  HTMLDivElement
>(({ children, ...props }: any, ref) => {
  const { selectAll, unSelectAll } = useContext(MultiselectContext);

  return <Stack spacing={0} {...props}><ScrollArea ref={ref}>
      {children}
    </ScrollArea>
    <Group p="xs">
      <Anchor size="xs" onClick={selectAll}>Select All</Anchor>
      <Anchor size="xs" onClick={unSelectAll}>Un select All</Anchor>
    </Group>
  </Stack>
});

interface MantineMultiselectItemProps extends React.ComponentPropsWithoutRef<'div'> {
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
  value: string;
  label: string;
  selected: boolean
}

const MantineMultiselectItem = forwardRef<
  HTMLDivElement,
  MantineMultiselectItemProps
>(({ onMouseDown, selected, value, label, ...props }: MantineMultiselectItemProps, ref) => {
  const { onItemClick } = useContext(MultiselectContext);
  const { hovered, ref: refDiv } = useHover();
  
  const _onMouseDown = useCallback((e) => {
    onItemClick(value, selected, e.shiftKey)
    onMouseDown(e)
  }, [onMouseDown, onItemClick])

  return <div ref={refDiv}><Group spacing={"xs"} ref={ref} onMouseDown={_onMouseDown} bg={hovered ? "#f1f3f5" : undefined} style={{cursor: "pointer"}}>
    <Checkbox readOnly checked={selected} style={{cursor: "pointer"}}/>
    <Text>{label}</Text>
  </Group></div>
});