import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import { BrowserRouter, useLocation } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
  useMantineTheme,
} from '@mantine/core';
import './index.css';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { createRoot } from 'react-dom/client';
import global_en from './translations/en/global.json';
import global_es from './translations/es/global.json';
import settings_page_en from './translations/en/settings_page.json';
import settings_page_es from './translations/es/settings_page.json';
import dashboards_en from './translations/en/dashboards.json';
import dashboards_es from './translations/es/dashboards.json';
import projects_en from './translations/en/projects.json';
import projects_es from './translations/es/projects.json';
import quote_reader_en from './translations/en/quote_reader.json';
import quote_reader_es from './translations/es/quote_reader.json';
import React, { useEffect } from 'react';
import { ModalsProvider } from '@mantine/modals';
import { Auth } from '@aws-amplify/auth';
import { useAuthenticatedUser } from './hooks/useAuthenticatedUser';
import { useAuthenticatedRoleToken } from './hooks/useAuthenticatedRoleToken';
import { useLocalStorage } from '@mantine/hooks';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Notifications } from '@mantine/notifications';
import { useRouteStore } from '@stores/RouteStore';

const queryClient = new QueryClient();

// Language configuration
i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      global: global_en,
      settings_page: settings_page_en,
      dashboards: dashboards_en,
      quote_reader: quote_reader_en,
      projects: projects_en,
    },
    es: {
      global: global_es,
      settings_page: settings_page_es,
      dashboards: dashboards_es,
      quote_reader: quote_reader_es,
      projects: projects_es,
    },
  },
});

// if (process.env.NODE_ENV === 'production') {
Sentry.init({
  dsn: 'https://cd701e63d5d14afebd095309108604ac@o1374395.ingest.sentry.io/6681673',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllInputs: false,
      maskAllText: false,
    }),
    Sentry.replayCanvasIntegration(),
    Sentry.feedbackIntegration({ autoInject: false }), // This is because we're using a custom component for feedback
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0, // We'll keep this at 100% for now, until we have more user traffic in which case we'll lower it.

  replaysSessionSampleRate: 0.01,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});
// }

Auth.configure({
  region: import.meta.env.VITE_REGION,
  userPoolId: import.meta.env.VITE_USER_POOL_ID,
  userPoolWebClientId: import.meta.env.VITE_CLIENT_ID,
  cookieStorage: {
    domain: import.meta.env.VITE_COOKIE_DOMAIN,
    secure: process.env.NODE_ENV !== 'development-local',
  },
});

// ...and if it exists, set it as the default theme
const AuthenticatedApp = () => {
  const hasRoleToken = useAuthenticatedRoleToken();
  const isLogged = useAuthenticatedUser(hasRoleToken);
  const location = useLocation();
  const setParams = useRouteStore((s) => s.setParams);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const organizationId = parseInt(urlParams.get('organizationId') || '');
    setParams(organizationId || undefined, urlParams.get('rtId') || undefined);
  }, [location, setParams]);

  if (!hasRoleToken) {
    if (isLogged) {
      return <App />;
    } else {
      return <></>;
    }
  } else {
    return <App />;
  }
};

const MantineAppWrapper = () => {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{ colorScheme }}
        withGlobalStyles
        withNormalizeCSS
      >
        <Notifications position="top-right" />
        <ModalsProvider>
          <BrowserRouter>
            <AuthenticatedApp />
          </BrowserRouter>
        </ModalsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};


createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <QueryClientProvider client={queryClient}>
        <MantineAppWrapper />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
