import ProjectModel from '@models/ProjectModel';
import { useMemo } from 'react';
import { Analysis } from './useProjectAnalyses';

export const useCustomAnalyses = (project: ProjectModel, analyses: Analysis[]) => {
  return useMemo(() => {
    return analyses.filter(
      (a) => {
        var analysisTime = a.created_at.getTime()
        var projectTime = project.dateAdded.getTime()

        // Let's round the time to the nearest 5 second window...
        analysisTime = Math.round(analysisTime / 5000) * 5000
        projectTime = Math.round(projectTime / 5000) * 5000
        return analysisTime !== projectTime
      }
    )
  }, [project, analyses]);
};
