import { useQuery } from '@tanstack/react-query';
import ProjectAPI, { ProjectMetricType } from '@apis/ProjectAPI';
import ProjectModel from '@models/ProjectModel';
import {
  AnalysisType,
  IProjectAnalysisModel,
} from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import { InternalBenchmarkAnalysisModel } from '@models/ProjectAnalysisModel/InternalBenchmarkAnalysisModel';
import { DriversOfOutcomeAnalysisModel } from '@models/ProjectAnalysisModel/DriversOfOutcomeAnalysisModel';
import { BenchmarkDataPoint } from '@components/charts/datamodels/BenchmarkDataPoint';
import { RawVoiceAnalysisModel } from '@models/ProjectAnalysisModel/RawVoiceAnalysisModel';
import { recomputeScores } from './useBenchmarkData';
import { Population } from '@redux/benchmarksSlice';
import { OvertimeComparisonAnalysisModel } from '@models/ProjectAnalysisModel/OvertimeComparisonAnalysisModel';

export const useSegmentsData = (
  project?: ProjectModel,
  currentAnalysis?: IProjectAnalysisModel,
  selectedPopulation?: string,
  topic?: string
) => {
  return useQuery({
    queryKey: [
      'segments-data',
      project?.projectId,
      project?.organizationId,
      currentAnalysis?.id,
      selectedPopulation,
      topic
    ],
    queryFn: async () => {
      // TODO - Handle drivers analysis data...
      // Load all of the component data we'll use...
      let analysis:
        | InternalBenchmarkAnalysisModel
        | DriversOfOutcomeAnalysisModel
        | RawVoiceAnalysisModel
        | OvertimeComparisonAnalysisModel;
        
    let focalPopulation: string | Population = selectedPopulation;
    if (currentAnalysis.type === AnalysisType.DRIVERS_OF_OUTCOME) {
        analysis = currentAnalysis as DriversOfOutcomeAnalysisModel;
        focalPopulation = analysis.focalPopulation;
    } else {
        focalPopulation = selectedPopulation;
    }

      if (currentAnalysis.type === AnalysisType.DRIVERS_OF_OUTCOME) {
        analysis = currentAnalysis as DriversOfOutcomeAnalysisModel;
        return ProjectAPI.getBenchmarkData(
          project.projectId,
          null,
          analysis.focalPopulation,
          topic,
          project?.organizationId,
          ProjectMetricType.Segments
        );
      } else if (currentAnalysis.type === AnalysisType.INTERNAL_BENCHMARK) {
        analysis = currentAnalysis as InternalBenchmarkAnalysisModel;
        const data = await ProjectAPI.getBenchmarkData(
          project.projectId,
          analysis.internalBenchmark,
          focalPopulation,
          topic,
          project?.organizationId,
          ProjectMetricType.Segments
        );

        return recomputeScores(data);
      } else if (currentAnalysis.type === AnalysisType.RAW_VOICE) {
        analysis = currentAnalysis as RawVoiceAnalysisModel;
        const data = await ProjectAPI.getBenchmarkData(
          project.projectId,
          analysis.populationBenchmark,
          '*',
          topic,
          project?.organizationId,
          ProjectMetricType.Segments
        );

        return recomputeScores(data);
      } else if (currentAnalysis.type === AnalysisType.PROGRESS_OVER_TIME) {
        analysis = currentAnalysis as OvertimeComparisonAnalysisModel;
        const data = await ProjectAPI.getBenchmarkData(
          project.projectId,
          analysis.overtimeBenchmark,
          focalPopulation,
          topic,
          project?.organizationId,
          ProjectMetricType.Segments
        );

        return recomputeScores(data);
      }
    },
    enabled: !!project && !!currentAnalysis,
    staleTime: 1000 * 60 * 90, // 90 minutes...
  });
};
