import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import fs from 'fs';

export interface Population {
  title: string;
  definition?: object;
}

export function isPopulation(obj: any): obj is Population {
  return typeof obj === 'object' && 'title' in obj;
}

export interface Benchmark {
  title: string;
  populations: Population[];
  globalFilterDefinition?: any;
}

interface BenchmarksState {
  benchmarks: Benchmark[];
}

export const initialState: BenchmarksState = {
  benchmarks: [],
};

const benchmarksSlice = createSlice({
  name: 'benchmarks',
  initialState,
  reducers: {
    addBenchmark: (state, action: PayloadAction<Benchmark>) => {
      state.benchmarks.push(action.payload);
    },

    updateBenchmarkName: (
      state,
      action: PayloadAction<{
        oldBenchmarkName: string;
        newBenchmarkName: string;
      }>
    ) => {
      const { oldBenchmarkName, newBenchmarkName } = action.payload;
      const benchmarkIndex = state.benchmarks.findIndex(
        (benchmark) => benchmark.title === oldBenchmarkName
      );
      if (benchmarkIndex !== -1) {
        state.benchmarks[benchmarkIndex].title = newBenchmarkName;
      }
    },

    updateBenchmarkGlobalFilter: (
      state,
      action: PayloadAction<{
        benchmarkName: string;
        globalFilterDefinition: any;
      }>
    ) => {
      const { benchmarkName, globalFilterDefinition } = action.payload;
      const benchmarkIndex = state.benchmarks.findIndex(
        (benchmark) => benchmark.title === benchmarkName
      );
      if (benchmarkIndex !== -1) {
        state.benchmarks[benchmarkIndex].globalFilterDefinition =
          globalFilterDefinition;
      }
    },

    removeBenchmark: (state, action: PayloadAction<string>) => {
      const benchmarkName = action.payload;
      console.log(
        'removeBenchmark',
        state.benchmarks.filter(
          (benchmark) => benchmark.title !== benchmarkName
        )
      );
      state.benchmarks = state.benchmarks.filter(
        (benchmark) => benchmark.title !== benchmarkName
      );
    },

    resetBenchmarks: (state) => {
      state.benchmarks = [];
    },

    addPopulation: (
      state,
      action: PayloadAction<{ benchmarkName: string; population: Population }>
    ) => {
      const { benchmarkName, population } = action.payload;
      const benchmarkIndex = state.benchmarks.findIndex(
        (benchmark) => benchmark.title === benchmarkName
      );
      if (benchmarkIndex !== -1) {
        state.benchmarks[benchmarkIndex].populations.push(population);
      }
    },

    removePopulation: (
      state,
      action: PayloadAction<{ benchmarkName: string; populationName: string }>
    ) => {
      const { benchmarkName, populationName } = action.payload;
      const benchmarkIndex = state.benchmarks.findIndex(
        (benchmark) => benchmark.title === benchmarkName
      );
      if (benchmarkIndex !== -1) {
        state.benchmarks[benchmarkIndex].populations = state.benchmarks[
          benchmarkIndex
        ].populations.filter(
          (population) => population.title !== populationName
        );
      }
    },

    updatePopulationDefinition: (
      state,
      action: PayloadAction<{
        benchmarkName: string;
        populationName: string;
        populationDefinition: object;
      }>
    ) => {
      const { benchmarkName, populationName, populationDefinition } =
        action.payload;
      const benchmarkIndex = state.benchmarks.findIndex(
        (benchmark) => benchmark.title === benchmarkName
      );
      if (benchmarkIndex !== -1) {
        const populationIndex = state.benchmarks[
          benchmarkIndex
        ].populations.findIndex(
          (population) => population.title === populationName
        );
        if (populationIndex !== -1) {
          state.benchmarks[benchmarkIndex].populations[
            populationIndex
          ].definition = populationDefinition;
        }
      }
    },

    updatePopulationName: (
      state,
      action: PayloadAction<{
        benchmarkName: string;
        oldPopulationName: string;
        newPopulationName: string;
      }>
    ) => {
      const { benchmarkName, oldPopulationName, newPopulationName } =
        action.payload;
      const benchmarkIndex = state.benchmarks.findIndex(
        (benchmark) => benchmark.title === benchmarkName
      );
      if (benchmarkIndex !== -1) {
        const populationIndex = state.benchmarks[
          benchmarkIndex
        ].populations.findIndex(
          (population) => population.title === oldPopulationName
        );
        if (populationIndex !== -1) {
          state.benchmarks[benchmarkIndex].populations[populationIndex].title =
            newPopulationName;
        }
      }
    },
  },
});

export const {
  addBenchmark,
  removeBenchmark,
  updateBenchmarkName,
  addPopulation,
  resetBenchmarks,
  removePopulation,
  updatePopulationDefinition,
  updateBenchmarkGlobalFilter,
  updatePopulationName,
} = benchmarksSlice.actions;
export default benchmarksSlice.reducer;
