import { Population } from '@redux/benchmarksSlice';
import {
  IProjectAnalysisModel,
  IProjectAnalysisRequestModel,
  AnalysisType,
  AnalysisStatus,
  isAnalysisType,
} from './ProjectAnalysisModelBase';
import { IDriversOfOutcomeAnalysisConfig } from '@redux/analysesSlice';

interface IOutcomeMeasureItem {
  dataset: string;
  column: string;
}

export class OutcomeConfiguration {
  standardizedOutcome: string | null;
  measure: IOutcomeMeasureItem[];

  constructor(
    standardizedOutcome: string | null,
    measure: IOutcomeMeasureItem[]
  ) {
    this.standardizedOutcome = standardizedOutcome;
    this.measure = measure;
  }

  static fromAPIResponse(response: object): OutcomeConfiguration {
    const standardizedOutcome = response['StandardizedOutcome'];
    const measure: IOutcomeMeasureItem[] = response['Measure'].map(
      (item: object): IOutcomeMeasureItem => {
        return {
          dataset: item['Dataset'],
          column: item['Column'],
        };
      }
    );

    return new OutcomeConfiguration(standardizedOutcome, measure);
  }

  toJSON(): object {
    // Format the measure...
    const formattedMeasure: object[] = this.measure.map(
      (item: IOutcomeMeasureItem) => {
        return {
          Dataset: item.dataset,
          Column: item.column,
        };
      }
    );

    return {
      StandardizedOutcome: this.standardizedOutcome,
      Measure: formattedMeasure,
    };
  }
}

export class DriversOfOutcomeAnalysisModel implements IProjectAnalysisModel {
  id: string;
  name: string;
  type: AnalysisType;
  version: string;
  status: AnalysisStatus;
  created_at: Date;
  updated_at: Date;

  // MARK: - Drivers Specific Properties / Options
  focalPopulation: Population;
  outcome: OutcomeConfiguration;
  features: string[] | null;
  internalBenchmark: string | null;
  externalBenchmark: string | null;
  overtimeComparison: string | null;

  constructor(
    id: string,
    name: string,
    type: AnalysisType,
    version: string,
    status: AnalysisStatus,
    created_at: Date,
    updated_at: Date,
    focalPopulation: string | Population,
    outcome: OutcomeConfiguration,
    features: string[] | null,
    internalBenchmark: string | null,
    externalBenchmark: string | null,
    overtimeComparison: string | null
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.version = version;
    this.status = status;
    this.created_at = created_at;
    this.updated_at = updated_at;

    this.focalPopulation = focalPopulation;
    this.outcome = outcome;
    this.features = features;
    this.internalBenchmark = internalBenchmark;
    this.externalBenchmark = externalBenchmark;
    this.overtimeComparison = overtimeComparison;
  }

  static fromAPIResponse(response: object): DriversOfOutcomeAnalysisModel {
    // Our response will be an object that will conform to our class structure
    const analysisId = response['Id'];
    const name = response['Name'];
    const type: AnalysisType = AnalysisType.DRIVERS_OF_OUTCOME;
    const version: string = response['Version'];
    const status: AnalysisStatus = AnalysisStatus[response['Status']]; // TODO: - Fix this issue. Status isn't returned from the server. We need to compute...
    const created_at = new Date(response['CreatedAt'] + "Z");
    const updated_at = new Date(response['UpdatedAt'] + "Z");

    let focalPopulation: string | Population;

    if (version === '1.0.0') {
      focalPopulation = response['FocalPopulation'];
    } else {
      focalPopulation = {
        title: response['FocalPopulation']['Title'],
        definition: response['FocalPopulation']['Definition'],
      };
    }

    // MARK: - Handle the Outcome Configuration
    const measure = OutcomeConfiguration.fromAPIResponse(response['Outcome']);

    const internalBenchmark = response['InternalBenchmark']
      ? response['InternalBenchmark']
      : null;
    const externalBenchmark = response['ExternalBenchmark']
      ? response['ExternalBenchmark']
      : null;
    const overtimeComparison = response['OvertimeComparison']
      ? response['OvertimeComparison']
      : null;

    const features = response['Features'] ? response['Features'] : null;

    return new DriversOfOutcomeAnalysisModel(
      analysisId,
      name,
      type,
      version,
      status,
      created_at,
      updated_at,
      focalPopulation,
      measure,
      features,
      internalBenchmark,
      externalBenchmark,
      overtimeComparison
    );
  }

  toJSON = (): object => {
    // Check to see if the focal population is a string or a population object...
    let formattedFocalPopulation: string | object;
    if (typeof this.focalPopulation === 'string') {
      formattedFocalPopulation = this.focalPopulation;
    } else {
      formattedFocalPopulation = {
        Title: this.focalPopulation.title,
        Definition: this.focalPopulation.definition,
      };
    }

    return {
      Id: this.id,
      Name: this.name,
      Type: this.type,
      Version: this.version,
      Status: this.status,
      DateAdded: this.created_at,
      DateUpdated: this.updated_at,
      FocalPopulation: formattedFocalPopulation,
      Outcome: this.outcome.toJSON(),
      Features: this.features,
      InternalBenchmark: this.internalBenchmark,
      ExternalBenchmark: this.externalBenchmark,
      OvertimeComparison: this.overtimeComparison,
    };
  };

  toConfig = (): IDriversOfOutcomeAnalysisConfig => {
    return {
      id: this.id,
      title: this.name,
      analysisType: this.type,
      focalPopulation: this.focalPopulation,
      outcomes: this.outcome.measure,
      features: this.features,
    };
  };
}

// MARK: - Interface to make request to API with...
export class DriversOfOutcomeAnalysisRequestModel
  implements IProjectAnalysisRequestModel
{
  name: string;
  type: AnalysisType;
  version: string;

  focalPopulation: string | Population;
  outcome: OutcomeConfiguration;
  features: string[] | null;
  internalBenchmark: string | null;
  externalBenchmark: string | null;
  overtimeComparison: string | null;

  constructor(
    name: string,
    version: string,
    focalPopulation: string | Population,
    outcome: OutcomeConfiguration,
    features: string[] | null,
    internalBenchmark: string | null,
    externalBenchmark: string | null,
    overtimeComparison: string | null
  ) {
    this.name = name;
    this.type = AnalysisType.DRIVERS_OF_OUTCOME;
    this.version = version;
    this.focalPopulation = focalPopulation;
    this.outcome = outcome;
    this.features = features;
    this.internalBenchmark = internalBenchmark;
    this.externalBenchmark = externalBenchmark;
    this.overtimeComparison = overtimeComparison;
  }

  toJSON(): object {
    let formattedFocalPopulation: string | object;
    if (typeof this.focalPopulation === 'string') {
      formattedFocalPopulation = this.focalPopulation;
    } else {
      formattedFocalPopulation = {
        Title: this.focalPopulation.title,
        Definition: this.focalPopulation.definition,
      };
    }
    return {
      Name: this.name,
      Type: this.type,
      Version: this.version,
      FocalPopulation: formattedFocalPopulation,
      Outcome: this.outcome.toJSON(),
      Features: this.features,
      InternalBenchmark: this.internalBenchmark,
      ExternalBenchmark: this.externalBenchmark,
      OvertimeComparison: this.overtimeComparison,
    };
  }
}
