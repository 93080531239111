import { Avatar, Tooltip, TooltipProps } from '@mantine/core';
import { AnalysisStatus } from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import { IconCheck, IconProgress, IconX } from '@tabler/icons-react';
import { FC } from 'react';

interface AnalysisStatusIconProps {
  status: AnalysisStatus;
  tooltipProps?: Omit<TooltipProps, "label" | "children">
}

const AnalysisStatusIcon: FC<AnalysisStatusIconProps> = ({ status, tooltipProps }) => {
  switch (status) {
    case AnalysisStatus.READY:
      return (
        <Tooltip color="green" label="Ready" {...tooltipProps}>
          <Avatar color="green" size={15} radius="xl" variant="filled">
            <IconCheck size={10} />
          </Avatar>
        </Tooltip>
      );
    case AnalysisStatus.READY_WITH_RESTRICTIONS:
      return (
        <Tooltip color="yellow" label="Ready with restrictions" {...tooltipProps}>
          <Avatar color="yellow" size={15} radius="xl" variant="filled">
            <IconCheck size={10} />
          </Avatar>
        </Tooltip>
      );
    case AnalysisStatus.PROCESSING:
      return (
        <Tooltip color="blue" label="Processing" {...tooltipProps}>
          <Avatar color="blue" size={15} radius="xl" variant="filled">
            <IconProgress size={10} />
          </Avatar>
        </Tooltip>
      );
    case AnalysisStatus.ERROR:
      return (
        <Tooltip color="red" label="Error" {...tooltipProps}>
          <Avatar color="red" size={15} radius="xl" variant="filled">
            <IconX size={10} />
          </Avatar>
        </Tooltip>
      );
  }
};

export default AnalysisStatusIcon;
