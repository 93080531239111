import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import fs from 'fs';

interface DatasetsArray {
  datasetName: string;
  crn: string;
  description: string;
}

interface Dataset {
  title: string;
  description?: string;
  datasetsArray: DatasetsArray;
}

interface DatasetsState {
  datasets: Dataset[];
}

const initialState: DatasetsState = {
  datasets: [],
};

const datasetSlice = createSlice({
  name: 'datasets',
  initialState,
  reducers: {
    addDataset: (state, action: PayloadAction<Dataset>) => {
      state.datasets = [action.payload];
    },

    updateDatasetName: (
      state,
      action: PayloadAction<{ oldDatasetName: string; newDatasetName: string }>
    ) => {
      const { oldDatasetName, newDatasetName } = action.payload;
      const datasetIndex = state.datasets.findIndex(
        (dataset) => dataset.title === oldDatasetName
      );
      if (datasetIndex !== -1) {
        state.datasets[datasetIndex].title = newDatasetName;
      }
    },
    removeDataset: (state, action: PayloadAction<string>) => {
      const datasetName = action.payload;
      state.datasets = state.datasets.filter(
        (dataset) => dataset.title !== datasetName
      );
    },
  },
});

export const { addDataset, removeDataset, updateDatasetName } =
  datasetSlice.actions;
export default datasetSlice.reducer;
