import { FC } from 'react';
import {
  ActionIcon,
  ScrollArea,
  Select,
  Stack,
  Table,
  Text,
} from '@mantine/core';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { useSumaryEditionStore } from '@stores/SummaryEditionStore';
import { IconTrash } from '@tabler/icons-react';

export const AnalysesInSummaryColumn: FC = () => {
    const project = useCurrentProject();
    const analysesInSummary = useSumaryEditionStore(s => s.analysesInSummary)
    const switchAnalysis = useSumaryEditionStore(s => s.switchAnalysis)
    const setPopulation = useSumaryEditionStore(s => s.setPopulation)
  
    
    if(!project) {
        return null
    }
  return (
    <Stack style={{ flexGrow: 1 }}>
      <Text>
        2. Pick a focal population per each analysis
      </Text>
      <Stack style={{ flexGrow: 1, position: 'relative' }}>
        <ScrollArea style={{ position: 'absolute', inset: 0 }} offsetScrollbars>
        <Table>
            <thead>
                <tr>
                    <th>Analysis</th>
                    <th>Focal populations</th>
                    <th/>
                </tr>
            </thead>
            <tbody>
                {analysesInSummary.map(a => (
                    <tr key={a.id}>
                    <td>{a.name}</td>
                    <td><Select
                        value={a.focalPopulation}
                        onChange={(value) => setPopulation(a.id, value)}
                        data={a.populations}
                    /></td>
                    <td>
                    <ActionIcon variant="transparent" color="red" onClick={() => switchAnalysis(a)}>
                      <IconTrash style={{ width: '70%', height: '70%' }} stroke={1.5} />
                    </ActionIcon>
                    </td>
                  </tr>  
                ))}</tbody>
            </Table>
        </ScrollArea>
      </Stack>
    </Stack>
  );
};