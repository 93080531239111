import React, { useState } from 'react';
import { Anchor, Divider, Drawer, Title, Stack } from '@mantine/core';

import { TopicExplorationHelpSection } from './HelpSections/TopicExplorationHelpSection';
import { AnalysesHelpSection } from './HelpSections/AnalysesHelpSection';
import { QuotesHelpSection } from './HelpSections/QuotesHelpSection';
import { ListAnalysesHelpSection } from './HelpSections/ListAnalysesHelpSection';
import { NewProjectHelpSection } from './HelpSections/NewProjectHelpSection';

export enum HelpGuide {
  Benchmark = 'Benchmark',
  TopicExploration = 'TopicExploration',
  Quotes = 'Quotes',
  Analyses = 'Analyses',
  CreateAnalyses = 'CreateAnalyses',
  CreateProject = 'CreateProject',
}

interface InfoHelperButtonProps {
  linkedGuide?: HelpGuide;
  linkedContext?: any;
}

const HelpGuideTitles: { [key in HelpGuide]: string } = {
  [HelpGuide.TopicExploration]: 'Topic Exploration',
  [HelpGuide.Benchmark]: 'Benchmarking',
  [HelpGuide.Quotes]: 'Quotes',
  [HelpGuide.Analyses]: 'Analyses',
  [HelpGuide.CreateAnalyses]: 'Analyses',
  [HelpGuide.CreateProject]: 'New Project',
};

export const InfoHelperButton: React.FC<InfoHelperButtonProps> = ({
  linkedGuide,
  linkedContext,
}) => {
  const [drawerOpened, setDrawerOpened] = useState(false);

  return (
    <>
      <Drawer
        opened={drawerOpened}
        onClose={() => setDrawerOpened(false)}
        position="right"
      >
        <Stack>
          <Title order={2}>{HelpGuideTitles[linkedGuide]}</Title>
          <Divider />
          {linkedGuide === HelpGuide.TopicExploration && (
            <TopicExplorationHelpSection linkedContext={linkedContext} />
          )}
          {linkedGuide === HelpGuide.Analyses && (
            <AnalysesHelpSection linkedContext={linkedContext} />
          )}
          {linkedGuide === HelpGuide.Quotes && (
            <QuotesHelpSection linkedContext={linkedContext} />
          )}
          {linkedGuide === HelpGuide.CreateAnalyses && (
            <ListAnalysesHelpSection linkedContext={linkedContext} />
          )}
          {linkedGuide === HelpGuide.CreateProject && (
            <NewProjectHelpSection linkedContext={linkedContext} />
          )}
        </Stack>
      </Drawer>

      <Anchor
        size="xs"
        style={{ marginLeft: 7, marginBottom: 2, fontWeight: 'bold' }}
        onClick={() => setDrawerOpened(true)}
      >
        Info
      </Anchor>
    </>
  );
};
