import React, { forwardRef, useEffect } from 'react';
import {
  MantineColor,
  MantineTheme,
  CSSObject,
  useComponentDefaultProps,
} from '@mantine/styles';
import { createPolymorphicComponent } from '@mantine/utils';
import { Text, TextProps } from '@mantine/core';
import { Mark } from '@mantine/core';
import { highlighter } from './highlighter/highlighter';

// import hash library...
import hash from 'object-hash';

export interface QuoteHighlightProps extends TextProps {
  /** Substring or an array of substrings to highlight in children */
  highlight: string | string[];

  /** Color from theme that is used for highlighting */
  highlightColor?: MantineColor;

  /** Styles applied to highlighted part */
  highlightStyles?: CSSObject | ((theme: MantineTheme) => CSSObject);

  /** Full string part of which will be highlighted */
  children: string;
}

const defaultProps: Partial<QuoteHighlightProps> = {
  highlightColor: 'yellow',
};

export const _QuoteHighlight = forwardRef<HTMLDivElement, QuoteHighlightProps>(
  (props, ref) => {
    const {
      children,
      highlight,
      highlightColor,
      highlightStyles,
      unstyled,
      ...others
    } = useComponentDefaultProps('QuoteHighlight', defaultProps, props);
    const highlightChunks = highlighter(children, highlight);

    const quoteRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (quoteRef.current) {
        quoteRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, [highlightChunks]);

    return (
      <Text
        unstyled={unstyled}
        ref={ref}
        __staticSelector="QuoteHighlight"
        {...others}
      >
        {highlightChunks.map(({ chunk, highlighted }, i) => {
          const hashedChunk = hash(chunk);

          return highlighted ? (
            <Mark
              ref={quoteRef}
              unstyled={unstyled}
              key={i}
              color={highlightColor}
              sx={highlightStyles}
              data-highlight={chunk}
              data-chunk-hash={hashedChunk}
            >
              <b>{chunk}</b>
            </Mark>
          ) : (
            <span key={i}>{chunk}</span>
          );
        })}
      </Text>
    );
  }
);

_QuoteHighlight.displayName = '@mantine/core/QuoteHighlight';

export const QuoteHighlight = createPolymorphicComponent<
  'div',
  QuoteHighlightProps
>(_QuoteHighlight);
