class CRN {
  static fromString(crnString: string): CRN {
    // convert a string such as: crn:90210:resource:classified-sentences/3676826825
    // to a CRN object.
    // CRN's are structured as such:
    // crn:<orgId>:<service>:<resourceType>/<resourceId>

    // split the string into an array of strings
    const crnArray = crnString.split(':');

    // get the organizationId
    const organizationId = parseInt(crnArray[1]);

    // get the service
    const service = crnArray[2];

    // get the resourceType / resourceId pair
    const resourceTypeAndId = crnArray[3].split('/');

    // get the resourceType
    const resourceType = resourceTypeAndId[0];

    // get the resourceId
    const resourceId = resourceTypeAndId[1];

    return new CRN(organizationId, service, resourceType, resourceId);
  }

  organizationId: number;
  service: string;
  resourceType: string;
  resourceId: string;

  constructor(
    organizationId: number,
    service: string,
    resourceType: string,
    resourceId: string
  ) {
    this.organizationId = organizationId;
    this.service = service;
    this.resourceType = resourceType;
    this.resourceId = resourceId;
  }

  get identifier(): string {
    return `crn:${this.organizationId}:${this.service}:${this.resourceType}/${this.resourceId}`;
  }
}

export default CRN;
