import { useQuery } from '@tanstack/react-query';
import ProjectAPI from '@apis/ProjectAPI';

export const useProject = (projectId: string) => {
  return useQuery({
    queryKey: ['project', projectId],
    queryFn: () => ProjectAPI.getProject(projectId),
    enabled: !!projectId,
    staleTime: 1000 * 60 * 10, // 10 minutes
  });
};
