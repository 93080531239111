import { ActionIcon, Group } from '@mantine/core';
import ProjectModel, { ProjectStatus } from '../models/ProjectModel';
import { IconCheck, IconX } from '@tabler/icons';
import { IconProgress } from '@tabler/icons-react';
import {
  AnalysisStatus,
  IProjectAnalysisModel,
} from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';

interface AnalysisStatusIndicatorProps {
  analysis?: IProjectAnalysisModel;
}

export const AnalysisStatusIndicator: React.FC<AnalysisStatusIndicatorProps> = (
  props: AnalysisStatusIndicatorProps
) => {
  const getIconFromProjectStatus = (
    projectStatus: AnalysisStatus
  ): JSX.Element => {
    switch (projectStatus) {
      case AnalysisStatus.ERROR:
        return (
          <ActionIcon color="red" size={14} radius="xl" variant="filled">
            <IconX size={10} />
          </ActionIcon>
        );
      case AnalysisStatus.PROCESSING:
        return (
          <ActionIcon color="blue" size={14} radius="xl" variant="filled">
            <IconProgress size={10} />
          </ActionIcon>
        );
      case AnalysisStatus.READY_WITH_RESTRICTIONS:
        return (
          <ActionIcon color="yellow" size={14} radius="xl" variant="filled">
            <IconCheck size={10} />
          </ActionIcon>
        );
      case AnalysisStatus.READY:
        return (
          <ActionIcon color="green" size={14} radius="xl" variant="filled">
            <IconCheck size={10} />
          </ActionIcon>
        );
    }
  };

  const getStatusMessage = (projectStatus: AnalysisStatus): string => {
    switch (projectStatus) {
      case AnalysisStatus.ERROR:
        return 'Issues';
      case AnalysisStatus.PROCESSING:
        return 'Processing';
      case AnalysisStatus.READY_WITH_RESTRICTIONS:
        return 'Core data ready';
      case AnalysisStatus.READY:
        return 'Ready';
    }
  };

  if (props.analysis && props.analysis.status !== AnalysisStatus.READY) {
    return (
      <Group spacing={5}>
        {getIconFromProjectStatus(props.analysis.status)}
        {getStatusMessage(props.analysis.status)}
      </Group>
    );
  } else {
    return undefined;
  }
};
