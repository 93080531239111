import {
  IProjectAnalysisModel,
  IProjectAnalysisRequestModel,
  AnalysisType,
  AnalysisStatus,
} from './ProjectAnalysisModelBase';

export class CompareTwoPopulationsAnalysisModel
  implements IProjectAnalysisModel
{
  id: string;
  name: string;
  type: AnalysisType;
  version: string;
  status: AnalysisStatus;
  created_at: Date;
  updated_at: Date;

  // MARK: - Drivers Specific Properties / Options
  focalPopulation: string;
  comparisonPopulation: string;

  constructor(
    id: string,
    name: string,
    type: AnalysisType,
    version: string,
    status: AnalysisStatus,
    created_at: Date,
    updated_at: Date,
    focalPopulation: string,
    comparisonPopulation: string
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.version = version;
    this.status = status;
    this.created_at = created_at;
    this.updated_at = updated_at;

    this.focalPopulation = focalPopulation;
    this.comparisonPopulation = comparisonPopulation;
  }

  static fromAPIResponse(response: object): CompareTwoPopulationsAnalysisModel {
    // Our response will be an object that will conform to our class structure
    const analysisId = response['Id'];
    const name = response['Name'];
    const type: AnalysisType = AnalysisType.COMPARE_TWO_POPULATIONS;
    const version: string = response['Version'];
    const status: AnalysisStatus = AnalysisStatus[response['Status']]; // TODO: - Fix this issue. Status isn't returned from the server. We need to compute...
    const created_at = new Date(response['CreatedAt']);
    const updated_at = new Date(response['UpdatedAt']);

    const focalPopulation = response['FocalPopulation'];

    const comparisonPopulation = response['ComparisonPopulation'];

    return new CompareTwoPopulationsAnalysisModel(
      analysisId,
      name,
      type,
      version,
      status,
      created_at,
      updated_at,
      focalPopulation,
      comparisonPopulation
    );
  }

  toJSON = (): object => {
    return {
      Id: this.id,
      Name: this.name,
      Type: this.type,
      Version: this.version,
      Status: this.status,
      DateAdded: this.created_at,
      DateUpdated: this.updated_at,
      FocalPopulation: this.focalPopulation,
      ComparisonPopulation: this.comparisonPopulation,
    };
  };
}

// MARK: - Interface to make request to API with...
export class CompareTwoPopulationsAnalysisRequestModel
  implements IProjectAnalysisRequestModel
{
  name: string;
  type: AnalysisType;
  version: string;

  focalPopulation: string;
  comparisonPopulation: string | null;

  constructor(
    name: string,
    version: string,
    focalPopulation: string,
    comparisonPopulation: string | null
  ) {
    this.name = name;
    this.type = AnalysisType.COMPARE_TWO_POPULATIONS;
    this.version = version;
    this.focalPopulation = focalPopulation;
    this.comparisonPopulation = comparisonPopulation;
  }

  toJSON(): object {
    return {
      Name: this.name,
      Type: this.type,
      Version: this.version,
      FocalPopulation: this.focalPopulation,
      ComparisonPopulation: this.comparisonPopulation,
    };
  }
}
