import {
    Modal,
    Stack,
    Title,
    Text,
    TextInput,
    Group,
    Button,
    Grid,
    Flex,
    CopyButton,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useCallback, useEffect, useState } from 'react';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons';
import UserAPI from '@apis/UserAPI';
import { useRouteStore } from '@stores/RouteStore';
import ProjectAPI from '@apis/ProjectAPI';

interface InviteUserModalProps {
    opened: boolean;
    onClose: () => void;
    onInvitationCreated?: () => void;
}

export const InviteUserModal = ({
    opened,
    onClose,
    onInvitationCreated,
}: InviteUserModalProps) => {
    const form = useForm({
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
    });

    const organizationId = useRouteStore(s => s.organizationId);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (opened) {
            setLoading(false)
            form.reset();
        }
    }, [opened]);

    const inviteUser = useCallback(async ({ email, firstName, lastName }: { email: string, firstName: string, lastName: string }) => {
        try {
            setLoading(true);
            const userName = email.split('@')[0].replace(/\./g, "_")
            const response = await UserAPI.inviteUser(organizationId, email, userName, firstName, lastName)
            const tokens = (await ProjectAPI.getTokens(response.associatedRole.resourceId, organizationId)).filter(t => t.Status === "ENABLED");
            if (tokens.length === 0) {
                throw "Server error: No token created for new invitation."
            }

            const link = `https://auth.culturex.com/signup/?organizationId=${organizationId}&inviteId=${response.inviteId}&rtId=${tokens[0].Token}`
            notifications.show({
                id: 'invite-user-created',
                color: 'green',
                title: 'Link created',
                message: (
                    <Flex style={{ overflow: 'hidden', alignItems: 'center' }}>
                        <Text weight={600} size={'xs'} truncate="end" pr="md">{link}</Text>
                        <CopyButton value={link} timeout={2000}>
                            {({ copied, copy }) => (
                                <Button size="xs" color={copied ? 'teal' : 'blue'} onClick={copy}>
                                    {copied ? 'Copied url' : 'Copy url'}
                                </Button>
                            )}
                        </CopyButton>
                    </Flex>
                ),
                icon: <IconCheck size="1rem" />,
                autoClose: 10000,
            });
            if (onInvitationCreated)
                onInvitationCreated();
        } catch (error) {
            form.setErrors({ root: error.response.data.Message })
            return;
        } finally {
            setLoading(false);
            onClose();
        }
    }, [organizationId]);

    return (
        <Modal
            title={
                <Stack spacing={0}>
                    <Title order={3}>{`Invite user`}</Title>
                    <Text color="dimmed" size="sm">
                        Create a link to invite a new user to your organization
                    </Text>
                </Stack>
            }
            opened={opened}
            onClose={onClose}
            withCloseButton={false}
            size="lg"
        >
            <form onSubmit={form.onSubmit(inviteUser)}>
                <Grid grow mt={0}>
                    <Grid.Col span={12}>
                        <TextInput
                            type="email"
                            label="Email"
                            withAsterisk
                            error={form.errors.email}
                            {...form.getInputProps('email')}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <TextInput
                            label="First name"
                            withAsterisk
                            error={form.errors.firstName}
                            {...form.getInputProps('firstName')}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <TextInput
                            label="Last name"
                            withAsterisk
                            error={form.errors.lastName}
                            {...form.getInputProps('lastName')}
                        />
                    </Grid.Col>
                </Grid>
                <Group position="apart" mt="xl" >
                    <Text size="xs" c="red">{form.errors.root}</Text>
                    <Group position="right" mt="xl" >
                        <Button variant="subtle" color="dark" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button loading={loading} type='submit'>
                            Invite user
                        </Button>
                    </Group>
                </Group>
            </form>
        </Modal>
    );
};
