import Link from '@components/Link';
import { useCurrentProject } from '@hooks/useCurrentProject';
import {
  Box,
  Group,
  Stack,
  Title,
  Text,
  Modal,
  Card,
} from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconTimeline } from '@tabler/icons';
import { IconChartArrows, IconMathFunction, IconSpeakerphone } from '@tabler/icons-react';
import React, { ReactNode } from 'react';

interface NewAnalysisModalProps {
  opened: boolean;
  onClose: () => void;
}

export const NewAnalysisModal: React.FC<NewAnalysisModalProps> = ({
  opened,
  onClose,
}) => {
  const project = useCurrentProject();

  if (!project) {
    return null;
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        <Stack spacing={1}>
          <Title order={3}>New Analysis</Title>
          <Text size="sm" color="dimmed">
            Select the type of analysis you would like to create
          </Text>
        </Stack>
      }
      size="xl"
    >
      <Stack spacing="md" mt="md">
        <ModalItem
          title="Benchmark"
          text="See how multiple populations (more than two) compare. Example: compare different business units or companies to one another."
          link={`/projects/${project.projectId}/new-benchmark`}
          icon={<IconChartArrows size={20} />}
        />
        <ModalItem
          title="Drivers"
          text="Using a Nobel Prize-cited machine learning explainability model, see which free text topics have the most powerful impact on outcomes of interest. Example: what are the most powerful drivers of retention?"
          link={`/projects/${project.projectId}/new-key-drivers`}
          icon={<IconMathFunction size={20} />}
        />
        <ModalItem
          title="Blank Sheet"
          text="When given an open-ended “blank sheet” of paper, what do employees say? Example: what are employees the most vocal and favorable about in the last survey? What are their biggest pain points?"
          link={`/projects/${project.projectId}/new-raw-voice`}
          icon={<img alt="blank sheet" src="/blankSheet.png" style={{height:"20px"}} />}
        />
        <ModalItem
          title="Direct Compare"
          text="Compare two (and only two) populations to see over time trends or other insights. Example: How did the culture change in July compared to January? How does our culture compare to our biggest competitor?"
          link={`/projects/${project.projectId}/new-progress-over-time`}
          icon={<img alt="blank sheet" src="/directCompare.png" style={{width:"25px"}} />}
        />
      </Stack>
    </Modal>
  );
};

interface ModalItemProps {
  title: string;
  text: string;
  link: string;
  icon: ReactNode;
}

const ModalItem: React.FC<ModalItemProps> = ({ title, text, link, icon }) => {
  const { hovered, ref } = useHover();
  return (
    <div ref={ref}>
      <Card
        withBorder
        component={Link}
        to={link}
        style={{ background: hovered ? '#efefef' : 'white' }}
      >
        <Stack spacing={'md'}>
          <Group align="center" spacing="xs">
            {icon}
            <Title size="h6">{title}</Title>
          </Group>
          <Box style={{ maxWidth: '100%' }}>
            <Text color="dimmed" size="sm" style={{ whiteSpace: 'normal' }}>
              {text}
            </Text>
          </Box>
        </Stack>
      </Card>
    </div>
  );
};
