import { Text, Title, Image, List, ActionIcon, Anchor } from '@mantine/core';
import {
  IconDots,
  IconEye,
  IconTrash,
  IconArrowRight,
  IconEdit,
  IconX,
  IconCheck,
  IconFlag,
} from '@tabler/icons';

import { BaseHelpSectionProps } from './BaseHelpSection';
import { IconProgress } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
// import { IconCheck } from "@tabler/icons-react";

export const NewProjectHelpSection: React.FC<BaseHelpSectionProps> = ({
  linkedContext,
}) => {
  return (
    <>
      <Text color="dimmed">Unlock your employee data</Text>

      <Title order={4}>New Project</Title>
      <Text>
        A project is a grouping of datasets and analyses that you can use to
        gain cutting-edge insights out of your employee data.{' '}
      </Text>
      <Text>
        To start, you'll need provide a title, description and one or more
        datasets to use. You can find your datasets in the <i>Dataset(s)</i>{' '}
        selector.
      </Text>
      <Title order={5}>Dataset Statuses</Title>
      <Text>
        Each dataset will come with a status associated with it. This status
        tells you if a dataset is ready for use.
      </Text>

      <List spacing={'xl'} size="sm">
        <List.Item
          icon={
            <ActionIcon color="green" size={18} radius="xl" variant="filled">
              <IconCheck size={12} />
            </ActionIcon>
          }
        >
          <b>Ready</b>: All checks passed. Your dataset has passed all internal
          checks and is ready to use.
        </List.Item>

        <List.Item
          icon={
            <ActionIcon color="orange" size={18} radius="xl" variant="filled">
              <IconFlag size={12} />
            </ActionIcon>
          }
        >
          <b>Dataset is not ready yet</b>: This may occur for a variety of
          reasons. Please contact us for assistance.
        </List.Item>
      </List>

      <Title order={4}>Dataset Logic</Title>
      <Text>
        When you select multiple datasets, those datasets are joined in what's
        called an "full outer" join. This means that you'll have access to all
        the columns in both datasets, but you won't be able perform AND queries
        between columns in the two datasets.
      </Text>
    </>
  );
};
