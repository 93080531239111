import { useEffect, useState } from 'react';
import { useDescribeDatasets } from '@apis/hooks/useDescribeDatasets';
import DatasetModel from '@models/DatasetModel';
import { useCurrentProject } from './useCurrentProject';

export const useCurrentDatasets = (): DatasetModel[] => {
  const project = useCurrentProject();
  const { data: describeDatasets } = useDescribeDatasets();
  const [datasets, setDatasets] = useState<DatasetModel[]>([]);

  useEffect(() => {
    const datasets = [];
    if (project && describeDatasets) {
      Object.keys(project.datasets).forEach((key) => {
        const datasetCRN = project.datasets[key].DatasetCRN;
        const dsModel = describeDatasets.find(
          (ds) => ds.crnObject.identifier === datasetCRN
        );
        if (dsModel) {
          datasets.push(dsModel);
        }
      });
    }
    setDatasets(datasets);
  }, [describeDatasets, project]);

  return datasets;
};
