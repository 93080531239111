import CRN from './Crn';
import APIDecodable from './APIDecodable';

class UserModel implements APIDecodable {
  organizationId: number;
  username: string;
  email: string;
  firstName?: string;
  lastName?: string;
  dateAdded?: Date;

  constructor(
    organizationId: number,
    username: string,
    email: string,
    firstName?: string,
    lastName?: string,
    dateAdded?: Date
  ) {
    this.organizationId = organizationId;
    this.username = username;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.dateAdded = dateAdded;
  }

  static fromAPIResponse(apiResponse: any): UserModel {
    const organizationId = apiResponse.OrganizationId;
    const username = apiResponse.Username;
    const email = apiResponse.Email;
    const firstName = apiResponse.FirstName;
    const lastName = apiResponse.LastName;
    const dateAdded = apiResponse.DateAdded;

    return new UserModel(
      organizationId,
      username,
      email,
      firstName,
      lastName,
      dateAdded
    );
  }

  get crn(): CRN {
    return new CRN(this.organizationId, 'governance', 'users', this.username);
  }
}

export default UserModel;
