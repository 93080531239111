import React, { useEffect, useState, forwardRef } from 'react';
import {
  Loader,
  Center,
  Grid,
  Title,
  TextInput,
  Group,
  Text,
  MultiSelect,
  ActionIcon,
} from '@mantine/core';
import { IconFlag, IconCheck } from '@tabler/icons';
import { useDispatch } from 'react-redux';
import { addDataset } from '../../redux/datasetSlice';
import DatasetModel, { CheckDetail } from '../../models/DatasetModel';

interface DatasetSelectionStepProps {
  datasetData: DatasetModel[];
  isLoadingDatasets: boolean;
  valueTitle: string;
  setValueTitle: (newVal: string) => void;
  valueDescription: string;
  setValueDescription: (newVal: string) => void;
  selectedDatasets: string[];
  setValueDataset: (newVal: string[]) => void;
  errorSelectTitleDataset: boolean;
  setErrorSelectTitleDataset: (newbool: boolean) => void;
  errorSelectDataset: boolean;
  setErrorSelectDataset: (newbool: boolean) => void;

  availableDatasets: any[];
  onUpdateAvailableDatasets: (datasets: any[]) => void;
}

interface ItemProps {
  value: string;
  label: string;
  title: string;
  description: string;
  checksPass: boolean;
  checksDetails?: string;
  checksDetailsMessages?: any; // React fragment normally...
}

const DatasetLoadingComponent: React.FC = () => {
  return (
    <Center>
      <Group>
        <Loader size="sm" />
        <Text size={18}>Loading datasets...</Text>
      </Group>
    </Center>
  );
};

export const formatCheckDetails = (checkDetails?: CheckDetail[]) => {
  if (checkDetails) {
    // Check to see if we have NoDemosTextsVersions...
    if (
      checkDetails.includes(CheckDetail.NoDemosTextsVersions) ||
      checkDetails.includes(CheckDetail.NoProcessedVersions) ||
      checkDetails.includes(CheckDetail.NoTopicThemeMapping)
    ) {
      return <Text size={12}>Dataset is not ready yet. Processing...</Text>;
    }

    return checkDetails.map((obj: CheckDetail) => {
      // Check to see if the check is a warning or an error
      switch (obj) {
        case CheckDetail.NoMetadataAvailable:
          return (
            <Text size={12}>
              Missing metadata. Please add metadata to this dataset.
            </Text>
          );
        case CheckDetail.NoTextColumns:
          return (
            <Text size={12}>
              Missing text columns. Please designate at least one column as
              text.
            </Text>
          );
      }
    });
  }
};

export const DatasetSelectionStep: React.FC<DatasetSelectionStepProps> = (
  props
) => {
  const [datasetDataArray, setDatasetDataArray] = useState(
    props.availableDatasets
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.selectedDatasets.length > 0) {
      props.setErrorSelectDataset(false);
    }
    if (props.valueTitle !== '') {
      props.setErrorSelectTitleDataset(false);
    }
  }, [props.selectedDatasets, props.valueTitle]);

  useEffect(() => {
    let datasetArray: any = [];
    if (props.datasetData.length > 0) {
      datasetArray = datasetArray.concat(
        props.datasetData
          ?.filter((obj: { [key: string]: any }) => obj.checksPassed)
          .map((obj: { [key: string]: any }) => {
            return {
              value: obj.datasetId,
              title: obj.alias,
              label: obj.alias,
              group: obj.checksPassed ? 'Available' : 'Unavailable',
              description: obj.description,
              checksPass: obj.checksPassed,
              disabled: !obj.checksPassed,
              checksDetails: obj.checkDetails,
              checksDetailsMessages: formatCheckDetails(obj.checkDetails),
              crn: obj?.crnObject?.identifier,
            };
          })
      );

      datasetArray = datasetArray.concat(
        props.datasetData
          ?.filter((obj: { [key: string]: any }) => !obj.checksPassed)
          .map((obj: { [key: string]: any }) => {
            return {
              value: obj.datasetId,
              title: obj.alias,
              label: obj.alias,
              group: obj.checksPassed ? 'Available' : 'Unavailable',
              description: obj.description,
              checksPass: obj.checksPassed,
              disabled: !obj.checksPassed,
              checksDetails: obj.checkDetails,
              checksDetailsMessages: formatCheckDetails(obj.checkDetails),
              crn: obj?.crnObject?.identifier,
            };
          })
      );
    }
    setDatasetDataArray(datasetArray);
    props.onUpdateAvailableDatasets(datasetArray);
  }, [props.datasetData]);

  const handleDatsetsAPI = () => {
    let newDatasets: any = [];
    datasetDataArray.forEach((datasetItem: { [key: string]: any }) => {
      if (props.selectedDatasets.includes(datasetItem.value)) {
        newDatasets.push({
          datasetName: datasetItem.value,
          crn: datasetItem.crn,
          description: datasetItem.description,
        });
      }
    });
    return newDatasets;
  };

  useEffect(() => {
    console.log('Use effect being called here...');
    console.log(
      props.valueTitle,
      props.valueDescription,
      props.selectedDatasets
    );
    if (props.valueTitle !== '' && props.selectedDatasets.length !== 0) {
      dispatch(
        addDataset({
          title: props.valueTitle,
          description: props.valueDescription,
          datasetsArray: handleDatsetsAPI(),
        })
      );
    }
  }, [props.valueTitle, props.valueDescription, props.selectedDatasets]);

  const handleOnChangeDataset = (value: string[]) => {
    if (!props.selectedDatasets || props.selectedDatasets.length === 0) {
      props.setValueDataset(value);
    } else {
      const newValues = [...props.selectedDatasets];

      // Add new items to the list
      value.forEach((val) => {
        if (!newValues.includes(val)) {
          newValues.push(val);
        }
      });

      // Remove items that are no longer present in the value
      const itemsToDelete = props.selectedDatasets.filter(
        (oldVal) => !value.includes(oldVal)
      );
      itemsToDelete.forEach((itemToDelete) => {
        const index = newValues.indexOf(itemToDelete);
        if (index > -1) {
          newValues.splice(index, 1);
        }
      });

      props.setValueDataset(newValues);
    }
  };

  const CustomDatasetSelectItem = forwardRef<HTMLDivElement, ItemProps>(
    (
      {
        value,
        label,
        title,
        description,
        checksPass,
        checksDetails,
        checksDetailsMessages,
        ...others
      }: ItemProps,
      ref
    ) => {
      var detailsSection = (
        <Group
          style={{
            marginTop: '10px',
            cursor: !checksPass ? 'not-allowed' : 'default',
          }}
          spacing={'xs'}
        >
          <ActionIcon
            color="orange"
            size="xs"
            radius="xl"
            variant="filled"
            style={{ cursor: !checksPass ? 'not-allowed' : 'default' }}
          >
            <IconFlag size={10} />
          </ActionIcon>
          <Text size="xs" opacity={1}>
            {checksDetailsMessages}
          </Text>
        </Group>
      );

      if (checksPass) {
        detailsSection = (
          <Group style={{ marginTop: '10px' }} spacing={'xs'}>
            <ActionIcon color="green" size="xs" radius="xl" variant="filled">
              <IconCheck size={10} />
            </ActionIcon>
            <Text size="xs" opacity={0.4}>
              Ready to use
            </Text>
          </Group>
        );
      }

      return (
        <div ref={ref} {...others}>
          <Group
            noWrap
            style={{ cursor: !checksPass ? 'not-allowed' : 'default' }}
          >
            <div>
              <Title order={5}>{title}</Title>
              <Text size="sm">{description}</Text>
              {detailsSection}
            </div>
          </Group>
        </div>
      );
    }
  );

  return (
    <Grid>
      <Grid.Col>
        <TextInput
          value={props.valueTitle}
          onChange={(event) => props.setValueTitle(event.currentTarget.value)}
          placeholder="Enter a title for your project"
          label="Title"
          error={
            props.errorSelectTitleDataset === true
              ? 'You must enter a title for your project'
              : null
          }
          withAsterisk
        />
      </Grid.Col>
      <Grid.Col>
        <TextInput
          value={props.valueDescription}
          onChange={(event) =>
            props.setValueDescription(event.currentTarget.value)
          }
          placeholder="Describe what this project attempts to achieve"
          label="Description"
        />
      </Grid.Col>
      <Grid.Col>
        <MultiSelect
          searchable
          value={props.selectedDatasets}
          onChange={(value) => handleOnChangeDataset(value)}
          label="Dataset(s)"
          placeholder="Select one or more datasets"
          maxDropdownHeight={400}
          itemComponent={CustomDatasetSelectItem}
          withAsterisk
          data={datasetDataArray}
          nothingFound={
            props.isLoadingDatasets ? (
              <DatasetLoadingComponent />
            ) : (
              'No datasets found'
            )
          }
          error={props.errorSelectDataset}
        />
        {props.errorSelectDataset && (
          <Text color="red" mt="xs" size={12}>
            Please select at least one dataset
          </Text>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default DatasetSelectionStep;
