import { useAnalysisStore } from '@stores/AnalysisStore';
import React, { useMemo, useState } from 'react';
import { BenchmarkDataPoint } from './charts/datamodels/BenchmarkDataPoint';

export const useOverTimeAnalysisData = () => {
  const rawData = useAnalysisStore((s) => s.data);
  const activeTopics = useAnalysisStore((s) => s.activeTopics);
  const activeThemes = useAnalysisStore((s) => s.activeThemes);
  const allPopulations = useAnalysisStore((s) => s.allPopulations);
  const numberOfMentions = useAnalysisStore((s) => s.numberOfMentions);
  const [firstPopulation, secondPopulation] = allPopulations;

  const data: BenchmarkDataPoint[] = useMemo(() => {
    if (!firstPopulation) {
      return [];
    }
    return rawData
      .filter((dataPoint: BenchmarkDataPoint) => {
        return (
          (activeTopics.includes(dataPoint.topic) ||
            activeThemes.includes(dataPoint.topic)) &&
          // dataPoint.vopicCount >= numberOfMentions &&
          allPopulations.includes(dataPoint.populationName)
        );
      })
      .filter(
        (point: BenchmarkDataPoint) => point.populationName === firstPopulation
      );
  }, [
    rawData,
    activeTopics,
    activeThemes,
    allPopulations,
    numberOfMentions,
    firstPopulation,
  ]);

  const data2: BenchmarkDataPoint[] = useMemo(() => {
    if (!secondPopulation) {
      return [];
    }
    return rawData
      .filter((dataPoint: BenchmarkDataPoint) => {
        return (
          (activeTopics.includes(dataPoint.topic) ||
            activeThemes.includes(dataPoint.topic)) &&
          // dataPoint.vopicCount >= numberOfMentions &&
          allPopulations.includes(dataPoint.populationName)
        );
      })
      .filter(
        (point: BenchmarkDataPoint) => point.populationName === secondPopulation
      );
  }, [
    rawData,
    activeTopics,
    activeThemes,
    allPopulations,
    numberOfMentions,
    secondPopulation,
  ]);

  const generateData = ():
    | {
        firstPopulationElement: BenchmarkDataPoint;
        secondPopulationElement: BenchmarkDataPoint;
        theCalculation: number;
      }[]
    | null => {
    const firstPopulationData = data;
    const secondPopulationData = data2;

    if (firstPopulationData.length === 0 || secondPopulationData.length === 0) {
      return null;
    }

    if (firstPopulationData.length !== secondPopulationData.length) {
      console.error(
        'The populations are not the same length',
        firstPopulationData.length,
        secondPopulationData.length
      );
    }

    const firstPopLength = firstPopulationData.length;
    const secondPopLength = secondPopulationData.length;
    const populationLength = Math.max(firstPopLength, secondPopLength);

    let mixedArr: {
      firstPopulationElement: BenchmarkDataPoint;
      secondPopulationElement: BenchmarkDataPoint;
      theCalculation: number;
    }[] = [];

    for (let index = 0; index < populationLength; index++) {
      const firstPopulationElement = firstPopulationData[index];
      const secondPopulationElement = secondPopulationData[index];

      let theCalculation =
        (10 / 3) *
        ((firstPopulationElement.positiveIncidence -
          secondPopulationElement.positiveIncidence -
          (firstPopulationElement.negativeIncidence -
            secondPopulationElement.negativeIncidence)) /
          ((firstPopulationElement.incidence +
            secondPopulationElement.incidence) /
            2));

      if (Number.isNaN(theCalculation)) {
        theCalculation = 0;
      }

      mixedArr.push({
        firstPopulationElement,
        secondPopulationElement,
        theCalculation,
      });
    }
    mixedArr = mixedArr.sort((a, b) => a.theCalculation - b.theCalculation);
    // console.log(JSON.stringify(mixedArr),"MIXED")

    // mixedArr = mixedArr.filter(
    //   (d) =>
    //     d.firstPopulationElement.vopicCount >= numberOfMentions &&
    //     d.secondPopulationElement.vopicCount >= numberOfMentions
    // );

    if (mixedArr.length === 0) {
      return null;
    }

    return mixedArr;
  };

  return generateData;
};
