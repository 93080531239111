import { DistributionRow } from '@apis/hooks/useDistributionPoints';
import { Active, Over, UniqueIdentifier } from '@dnd-kit/core';
import { DatasetField } from '@hooks/useDatasetFields';
import { DriversOfOutcomeAnalysisModel } from '@models/ProjectAnalysisModel/DriversOfOutcomeAnalysisModel';
import { IProjectAnalysisModel } from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import ProjectModel from '@models/ProjectModel';
import { create } from 'zustand';

export interface Population {
  id: string;
  title: string;
  definition?: object;
}

export enum AnalysisType {
  DRIVERS_OF_OUTCOME = 'CultureX::API::Analysis::DriversOfOutcomeInPopulation',
  INTERNAL_BENCHMARK = 'CultureX::API::Analysis::InternalBenchmark',
  RAW_VOICE = 'CultureX::API::Analysis::RawVoice',
  PROGRESS_OVER_TIME = 'CultureX::API::Analysis::ProgressOvertime'
}

interface AnalysisEditionStore {
  analysisType: AnalysisType;
  setAnalysisType: (type: AnalysisType) => void;
  setAnalysisData: (
    project: ProjectModel,
    analysis: IProjectAnalysisModel
  ) => void;
  dataset: string | undefined;
  setDataset: (value: string | undefined) => void;
  fields: DatasetField[];
  setFields: (value: DatasetField[]) => void;
  activeFields: DatasetField[];
  setActiveFields: (value: DatasetField[]) => void;
  distributionData: DistributionRow[];
  setDistributionData: (value: DistributionRow[]) => void;
  globalFilter: object | undefined;
  setGlobalFilter: (value: object | undefined) => void;
  selectedDistributionRows: string[];
  setSelectedDistributionRows: (value: string[]) => void;
  populations: Population[];
  setPopulations: (value: Population[]) => void;
  populationsToFix: Population[];
  setPopulationsToFix: (value: Population[]) => void;
  buildPopulation: (group: boolean, isSet?: boolean) => void;
  dragging: false | 'column' | 'row';
  dragField: DatasetField | undefined;
  onDragStart: (active: Active) => void;
  onDragCancel: () => void;
  onDragEnd: (over: Over) => void;
}

export const useAnalysisEditionStore = create<AnalysisEditionStore>()(
  (set, get) => ({
    analysisType: AnalysisType.INTERNAL_BENCHMARK,
    setAnalysisType: (value) => set({ analysisType: value }),
    setAnalysisData: (
      project: ProjectModel,
      analysis: IProjectAnalysisModel
    ) => {
      let populations = [];
      if (analysis.type === AnalysisType.INTERNAL_BENCHMARK) {
        const becnhmark = (analysis as any).internalBenchmark;
        if (!project.benchmarks[becnhmark]) {
          set({ analysisType: analysis.type as AnalysisType, populations: [] });
          return;
        }
        for (const [populationName, population] of Object.entries(
          project.benchmarks[becnhmark].Populations
        )) {
          populations.push({
            id: Math.random().toString(16).slice(2),
            title: populationName,
            definition: population,
          });
        }
      } else if (analysis.type === AnalysisType.RAW_VOICE) {
        const becnhmark = (analysis as any).populationBenchmark;
        if (!project.benchmarks[becnhmark]) {
          set({ analysisType: analysis.type as AnalysisType, populations: [] });
          return;
        }
        for (const [populationName, population] of Object.entries(
          project.benchmarks[becnhmark].Populations
        )) {
          populations.push({
            id: Math.random().toString(16).slice(2),
            title: populationName,
            definition: population,
          });
        }
      } else if (analysis.type === AnalysisType.DRIVERS_OF_OUTCOME) {
        populations.push({
          ...(analysis as DriversOfOutcomeAnalysisModel).focalPopulation,
          id: Math.random().toString(16).slice(2),
        });
      } else if (analysis.type === AnalysisType.PROGRESS_OVER_TIME) {
        const benchmark = (analysis as any).overtimeBenchmark;
        if (!project.benchmarks[benchmark]) {
          set({ analysisType: analysis.type as AnalysisType, populations: [] });
          return;
        }
        for (const [populationName, population] of Object.entries(
          project.benchmarks[benchmark].Populations
        )) {
          populations.push({
            id: Math.random().toString(16).slice(2),
            title: populationName,
            definition: population,
          });
        }
      }

      set({ analysisType: analysis.type as AnalysisType, populations });
    },
    dataset: undefined,
    setDataset: (value) =>
      set({
        dataset: value,
        activeFields: [],
        globalFilter: undefined,
        selectedDistributionRows: [],
      }),
    fields: [],
    setFields: (value) => set({ fields: value }),
    activeFields: [],
    setActiveFields: (value) =>
      set({ activeFields: value, selectedDistributionRows: [] }),
    distributionData: [],
    setDistributionData: (value) => set({ distributionData: value }),
    globalFilter: undefined,
    setGlobalFilter: (value) => set({ globalFilter: value }),
    selectedDistributionRows: [],
    setSelectedDistributionRows: (value) =>
      set({ selectedDistributionRows: value }),
    populations: [],
    setPopulations: (value) => set({ populations: value }),
    populationsToFix: [],
    setPopulationsToFix: (value) => set({ populationsToFix: value }),
    buildPopulation: (group: boolean, isSet?: boolean) => {
      const {
        distributionData,
        selectedDistributionRows,
        populations,
        globalFilter,
      } = get();
      const selectedValues = distributionData.filter((d) =>
        selectedDistributionRows.includes(d.id)
      );
      const addedPopulations: Population[] = group
        ? [constructMergedPopulation(selectedValues)]
        : constructPopulations(selectedValues);
      const populationsWithGlobalFilter = globalFilter
        ? addedPopulations.map((population) => {
            return {
              ...population,
              definition: {
                and: [population.definition, globalFilter],
              },
            };
          })
        : addedPopulations;

      if (isSet) {
        set({
          populations: populationsWithGlobalFilter,
          populationsToFix: [],
          selectedDistributionRows: [],
        });
      } else {
        const populationsToAdd = populationsWithGlobalFilter.filter(
          (p) => !populations.map((p) => p.title).includes(p.title)
        );
        const populationsToFix = populationsWithGlobalFilter.filter((p) =>
          populations.map((p) => p.title).includes(p.title)
        );

        set({
          populations: [...populations, ...populationsToAdd],
          populationsToFix,
          selectedDistributionRows: [],
        });
      }
    },
    dragging: false,
    dragField: undefined,
    onDragStart: (active: Active) => {
      if (active.data.current.type === 'column') {
        const field = get().fields.find((f) => f.id === active.id);
        if (field) {
          set({ dragging: 'column', dragField: field });
        }
      } else if (active.data.current.type === 'row') {
        const { selectedDistributionRows } = get();
        if (!selectedDistributionRows.includes(active.id as string)) {
          set({
            dragging: 'row',
            selectedDistributionRows: [
              ...selectedDistributionRows,
              active.id as string,
            ],
            dragField: undefined,
          });
        } else {
          set({ dragging: 'row', dragField: undefined });
        }
      }
    },
    onDragCancel: () => {
      set({ dragging: false, dragField: undefined });
    },
    onDragEnd: (over: Over) => {
      const { dragging, selectedDistributionRows } = get();
      if (over && dragging === 'column') {
        let activeFields = [...get().activeFields];
        const dragField = get().dragField;

        if (over.data.current.action === 'push') {
          activeFields = [
            ...activeFields.slice(0, over.data.current.position),
            dragField,
            ...activeFields.slice(over.data.current.position),
          ];
        } else if (over.data.current.action === 'replace') {
          activeFields[over.data.current.position] = dragField;
        } else if (over.data.current.action === 'move') {
          const indexOfSource = activeFields.findIndex(
            (f) => f.id === dragField.id
          );
          if (indexOfSource !== -1) {
            activeFields.splice(indexOfSource, 1);
            activeFields = [
              ...activeFields.slice(0, over.data.current.position),
              dragField,
              ...activeFields.slice(over.data.current.position),
            ];
          }
        }
        set({ activeFields });
      }
      if (over && dragging === 'row') {
        if (over.data.current.action === 'add') {
          get().buildPopulation(false);
        } else if (over.data.current.action === 'group') {
          get().buildPopulation(true);
        } else if (over.data.current.action === 'set') {
          get().buildPopulation(selectedDistributionRows.length > 1, true);
        }
      }
      set({ dragging: false, dragField: undefined });
    },
  })
);

//HELPERS
const constructPopulationDefinition = (
  datasetAlias: string,
  distributionPoint: DistributionRow
): object => {
  var individualSubdefinitions = [];
  distributionPoint.columns.forEach((fieldValue, index) => {
    if (fieldValue.column !== '<COUNT>') {
      const subDefinition = {
        '==': [
          {
            Dataset: datasetAlias,
            Column: fieldValue.column.replace(`${datasetAlias}@`, ''),
          },
          fieldValue.value,
        ],
      };

      individualSubdefinitions.push(subDefinition);
    }
  });

  if (individualSubdefinitions.length === 1) {
    return individualSubdefinitions[0];
  } else {
    return {
      and: [...individualSubdefinitions],
    };
  }
};

function createDynamicName(distributionPoint: DistributionRow): string {
  return distributionPoint.columns
    .filter((e) => e.column !== '<COUNT>')
    .map((e) => e.value ?? 'BLANK')
    .join(' - ');
}

const constructPopulations = (
  distributionPoints: DistributionRow[]
): Population[] => {
  return distributionPoints.map((d) => {
    const datasetAlias = d.columns[0].column.split('@').shift();
    return {
      id: Math.random().toString(16).slice(2),
      title: createDynamicName(d),
      definition: constructPopulationDefinition(datasetAlias, d),
    };
  });
};

const constructMergedPopulation = (
  distributionPoints: DistributionRow[]
): Population => {
  var subDefinitions = distributionPoints.map((d) => {
    const datasetAlias = d.columns[0].column.split('@').shift();
    return constructPopulationDefinition(datasetAlias, d);
  });

  const populationDefinition = {
    or: [...subDefinitions],
  };

  return {
    id: Math.random().toString(16).slice(2),
    title: 'My Composite Population',
    definition: populationDefinition,
  };
};
