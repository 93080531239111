import {
    IRawVoiceAnalysisConfig,
  } from '@redux/analysesSlice';
  import {
    IProjectAnalysisModel,
    IProjectAnalysisRequestModel,
    AnalysisType,
    AnalysisStatus,
    isAnalysisType,
  } from './ProjectAnalysisModelBase';
  
  export class RawVoiceAnalysisModel implements IProjectAnalysisModel {
    id: string;
    name: string;
    type: AnalysisType;
    version: string;
    status: AnalysisStatus;
    created_at: Date;
    updated_at: Date;
  
    // MARK: - Drivers Specific Properties / Options
    focalPopulation: string;
    populationBenchmark: string;
  
    constructor(
      id: string,
      name: string,
      type: AnalysisType,
      version: string,
      status: AnalysisStatus,
      created_at: Date,
      updated_at: Date,
      focalPopulation: string,
      populationBenchmark: string
    ) {
      this.id = id;
      this.name = name;
      this.type = type;
      this.version = version;
      this.status = status;
      this.created_at = created_at;
      this.updated_at = updated_at;
  
      this.focalPopulation = focalPopulation;
      this.populationBenchmark = populationBenchmark;
    }
  
    static fromAPIResponse(response: object): RawVoiceAnalysisModel {
      // Our response will be an object that will conform to our class structure
      const analysisId = response['Id'];
      const name = response['Name'];
      const type: AnalysisType = AnalysisType.RAW_VOICE;
      const version: string = response['Version'];
      const status: AnalysisStatus = AnalysisStatus[response['Status']]; // TODO: - Fix this issue. Status isn't returned from the server. We need to compute...
      const created_at = new Date(response['CreatedAt'] + "Z");
      const updated_at = new Date(response['UpdatedAt'] + "Z");
  
      const focalPopulation = response['FocalPopulation'];
  
      const populationBenchmark = response['PopulationBenchmark'];
  
      return new RawVoiceAnalysisModel(
        analysisId,
        name,
        type,
        version,
        status,
        created_at,
        updated_at,
        focalPopulation,
        populationBenchmark
      );
    }
  
    toJSON = (): object => {
      return {
        Id: this.id,
        Name: this.name,
        Type: this.type,
        Version: this.version,
        Status: this.status,
        DateAdded: this.created_at,
        DateUpdated: this.updated_at,
        FocalPopulation: this.focalPopulation,
        PopulationBenchmark: this.populationBenchmark,
      };
    };
  
    toConfig = (): IRawVoiceAnalysisConfig => {
      return {
        id: this.id,
        title: this.name,
        analysisType: this.type,
        focalPopulation: this.focalPopulation,
        benchmark: this.populationBenchmark,
      };
    };
  }
  
  // MARK: - Interface to make request to API with...
  export class RawVoiceAnalysisRequestModel
    implements IProjectAnalysisRequestModel
  {
    name: string;
    type: AnalysisType;
    version: string;
  
    focalPopulation: string;
    populationBenchmark: string;
  
    constructor(
      name: string,
      version: string,
      focalPopulation: string,
      populationBenchmark: string
    ) {
      this.name = name;
      this.type = AnalysisType.RAW_VOICE;
      this.version = version;
      this.focalPopulation = focalPopulation;
      this.populationBenchmark = populationBenchmark;
    }
  
    toJSON(): object {
      return {
        Name: this.name,
        Type: this.type,
        Version: this.version,
        FocalPopulation: this.focalPopulation,
        PopulationBenchmark: this.populationBenchmark,
      };
    }
  }
  