import { Analysis } from '@hooks/useProjectAnalyses';
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Card,
  Collapse,
  Flex,
  Group,
  ScrollArea,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core';
import Fuse from 'fuse.js';
import { FC, ReactNode, useCallback, useState } from 'react';
import AnalysisCard from './AnalysisCard';
import { IProjectAnalysisModel } from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { ProjectDownloadDataModal } from './ProjectDownloadDataModal';

interface AnalysesTabProps {
  data: Fuse.FuseResult<Analysis>[];
}

export const AnalysesTab: FC<AnalysesTabProps> = ({
  data,
}) => {
  const project = useCurrentProject();
  const [analysisToDownload, setAnalysisToDownload] =
    useState<IProjectAnalysisModel>(undefined);

  const _setAnalysisToDownload = useCallback(
    (analysisId) => {
      const analysis = project.analyses.find((a) => a.id === analysisId);
      setAnalysisToDownload(analysis);
    },
    [project]
  );

  return (
    <>
      {analysisToDownload !== undefined && (
        <ProjectDownloadDataModal
          project={project}
          defaultSelectedAnalysis={analysisToDownload}
          onCancel={() => setAnalysisToDownload(undefined)}
          opened={true}
        />
      )}
      <SimpleGrid
        breakpoints={[
          { minWidth: 'sm', cols: 2 },
          { minWidth: 'md', cols: 3 },
          { minWidth: 1200, cols: 4 },
        ]}
      >
        {data.map((i) => (
          <AnalysisCard
            key={i.item.id}
            project={project}
            analysis={i.item}
            matches={i.matches}
            onDownloadData={_setAnalysisToDownload}
          />
        ))}
      </SimpleGrid>  
    </>
  );
};
