import { Auth } from '@aws-amplify/auth';
import { useEffect, useState } from 'react';

var CURRENT_ORGANIZATION_ID: number | null = null;
var CURRENT_ROLE_TOKEN: string | null = null;

class APIRequesterBase {
  static configure(configObject: any): void {
    if (configObject.organizationId) {
      console.log(
        `Configuring APIRequesterBase with organizationId: ${configObject.organizationId}`
      );
      CURRENT_ORGANIZATION_ID = configObject.organizationId;
    }
    if (configObject.roleToken) {
      CURRENT_ROLE_TOKEN = configObject.roleToken;
    }
  }

  static getConfig(): any {
    return {
      organizationId: CURRENT_ORGANIZATION_ID,
      roleToken: CURRENT_ROLE_TOKEN,
    };
  }

  static getAPIUrl(): string {
    return import.meta.env.VITE_CULTUREX_API_ENDPOINT!;
  }

  static async getJWT(): Promise<string> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession?.idToken?.jwtToken;
      return token;
    } catch (err) {
      console.error(err);
      return '';
    }
  }

  static async getHeaders(): Promise<any> {
    if (CURRENT_ROLE_TOKEN) {
      return {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `RT-${CURRENT_ROLE_TOKEN}`,
      };
    } else {
      const token = await this.getJWT();

      return {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token,
      };
    }
  }

  static async getCurrentOrganizationId(): Promise<number> {
    if (CURRENT_ORGANIZATION_ID) {
      return CURRENT_ORGANIZATION_ID;
    }

    try {
      const user = await Auth.currentAuthenticatedUser();
      const organizationId = parseInt(
        user.attributes['custom:organization_id']
      );
      return organizationId;
    } catch (err) {
      console.error(err);
      return 0;
    }
  }
}

export default APIRequesterBase;


export function useOrganizationId(): [number | null, boolean] {
  const [organizationId, setOrganizationId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true; // Flag to check mount status

    const fetchOrganizationId = async () => {
      setIsLoading(true);
      try {
        const id = await APIRequesterBase.getCurrentOrganizationId();
        if (isMounted) { // Only update if the component is still mounted
          setOrganizationId(id);
        }
      } catch (error) {
        console.error('Failed to fetch organization ID', error);
        if (isMounted) {
          setOrganizationId(null);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchOrganizationId();

    return () => {
      isMounted = false; // Set the flag to false when the component unmounts
    };
  }, []); // Empty dependency array ensures this runs only once on mount.

  return [organizationId, isLoading];
}