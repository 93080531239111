import { useProjectTopics } from '@apis/hooks/useProjectTopics';
import { MultiSelectCheckbox } from '@components/MultiSelectCheckbox';
import { useCurrentProject } from '@hooks/useCurrentProject';
import {
  Button,
  Checkbox,
  Indicator,
  Popover,
  SelectItem,
  Stack,
} from '@mantine/core';
import { useAnalysisStore } from '@stores/AnalysisStore';
import { useTopicStore } from '@stores/TopicStore';
import { IconFilter } from '@tabler/icons';
import {
  userFriendlyTopicName,
  userFriendlyTopicThemeName,
} from '@utils/TopicUtils';
import { useMemo } from 'react';

export const TopicThemeFilter: React.FC = () => {
  const allTopics = useTopicStore((s) => s.allTopics);
  const allThemes = useTopicStore((s) => s.allThemes);
  const activeTopics = useAnalysisStore((s) => s.activeTopics);
  const setActiveTopics = useAnalysisStore((s) => s.setActiveTopics);

  const activeThemes = useAnalysisStore((s) => s.activeThemes);
  const setActiveThemes = useAnalysisStore((s) => s.setActiveThemes);

  const onlyUseThemeAssociatedTopics = useAnalysisStore(
    (s) => s.onlyUseThemeAssociatedTopics
  );
  const setOnlyUseThemeAssociatedTopics = useAnalysisStore(
    (s) => s.setOnlyUseThemeAssociatedTopics
  );

  const project = useCurrentProject();

  const { data } = useProjectTopics(project);

  const topicThemeMapping =
    data?.topicThemeMapping || new Map<string, string[]>();

  const filterIsApplied =
    (activeTopics.length === 0 && activeThemes.length === 0) ||
    activeTopics.length !== allTopics.length ||
    activeThemes.length !== allThemes.length;

  const topicOptions = useMemo(() => {
    return allTopics
      .map((topic) => {
        return {
          value: topic,
          label: userFriendlyTopicName(topic),
        } as SelectItem;
      })
      .sort((a, b) =>
        a.label.localeCompare(b.label)
      ) as unknown as SelectItem[];
  }, [allTopics]);

  const themeOptions = useMemo(() => {
    return allThemes
      .map((theme) => {
        return {
          value: theme,
          label: userFriendlyTopicThemeName(theme),
        } as SelectItem;
      })
      .sort((a, b) =>
        a.label.localeCompare(b.label)
      ) as unknown as SelectItem[];
  }, [allThemes]);

  const setActiveThemesAndTopics = (themes: string[]) => {
    setActiveThemes(themes);

    // Now, check to see if user wants to apply filter to topics as well...
    if (onlyUseThemeAssociatedTopics) {
      const selectedTopics = new Set<string>();
      // If so, then we need to set the active topics to be only those that are associated with the selected themes...
      themes.forEach((theme) => {
        const topics = topicThemeMapping.get(theme) || [];
        topics.forEach((topic) => {
          selectedTopics.add(topic);
        });
      });

      setActiveTopics(Array.from(selectedTopics));
    }
  };

  const updateThemeAssociatedTopicsCheckbox = (checked: boolean) => {
    setOnlyUseThemeAssociatedTopics(checked);

    if (checked) {
      // If the user wants to only use theme associated topics, then we need to set the active topics to be only those that are associated with the selected themes...
      const selectedTopics = new Set<string>();
      activeThemes.forEach((theme) => {
        const topics = topicThemeMapping.get(theme) || [];
        topics.forEach((topic) => {
          selectedTopics.add(topic);
        });
      });

      setActiveTopics(Array.from(selectedTopics));
    }
  };

  return (
    <Popover withArrow>
      <Popover.Target>
        <Indicator disabled={!filterIsApplied}>
          <Button
            variant="default"
            leftIcon={<IconFilter size="1rem" />}
            size="xs"
          >
            Filters
          </Button>
        </Indicator>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          <MultiSelectCheckbox
            label="Themes"
            placeholder="(Various)"
            searchable
            data={themeOptions}
            value={activeThemes}
            onChange={setActiveThemesAndTopics}
          />
          {/* COMBAK: - Fix this and make sure it works and is super intuitive... */}
          <Checkbox
            size="xs"
            label="Show only topics from selected themes"
            defaultChecked={onlyUseThemeAssociatedTopics}
            onChange={(event) =>
              updateThemeAssociatedTopicsCheckbox(event.currentTarget.checked)
            }
          />
          <MultiSelectCheckbox
            label="Topics"
            placeholder="(Various)"
            searchable
            data={topicOptions}
            value={activeTopics}
            onChange={setActiveTopics}
          />
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};

export const TopicThemeFilterSkeleton: React.FC = () => {
  return (
    <Popover withArrow>
      <Popover.Target>
        <Button
          variant="default"
          leftIcon={<IconFilter size="1rem" />}
          size="xs"
          disabled
        >
          Filters
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          <MultiSelectCheckbox
            label="Themes"
            placeholder="(Various)"
            searchable
            data={[]}
            value={[]}
            onChange={() => {}}
            disabled
          />
          <MultiSelectCheckbox
            label="Topics"
            placeholder="(Various)"
            searchable
            data={[]}
            value={[]}
            onChange={() => {}}
            disabled
          />
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};
