import { AnalysisType } from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { useEffect, useMemo, useState } from 'react';
import { useCurrentAnalysis } from './useCurrentAnalysis';
import { InternalBenchmarkAnalysisModel } from '@models/ProjectAnalysisModel/InternalBenchmarkAnalysisModel';
import { DriversOfOutcomeAnalysisModel } from '@models/ProjectAnalysisModel/DriversOfOutcomeAnalysisModel';
import { Population } from '@stores/AnalysisEditionStore';
import { RawVoiceAnalysisModel } from '@models/ProjectAnalysisModel/RawVoiceAnalysisModel';
import { OvertimeComparisonAnalysisModel } from '@models/ProjectAnalysisModel/OvertimeComparisonAnalysisModel';

export const useCurrentPopulations = (): Population[] => {
  const project = useCurrentProject();
  const analysis = useCurrentAnalysis();
  const [populations, setPopulations] = useState([]);

  const _populations = useMemo(() => {
    let allPopulations = [];
    if (analysis) {
      if (analysis.type === AnalysisType.INTERNAL_BENCHMARK) {
        const benchmarks = project.benchmarks;
        const populations = Object.keys(
          benchmarks[
            (analysis as InternalBenchmarkAnalysisModel).internalBenchmark
          ].Populations
        );

        allPopulations = populations.sort().map((k,i) => ({ 
          id: k.replace(/\W/g,'_').toLowerCase(),
          title: k,
          definition: benchmarks[(analysis as InternalBenchmarkAnalysisModel).internalBenchmark].Populations[k],
        }));
      } else if (analysis.type === AnalysisType.PROGRESS_OVER_TIME) {
        const benchmarks = project.benchmarks;
        const populations = Object.keys(
          benchmarks[
            (analysis as OvertimeComparisonAnalysisModel).overtimeBenchmark
          ].Populations
        );

        allPopulations = populations.sort().map((k,i) => ({ 
          id: k.replace(/\W/g,'_').toLowerCase(),
          title: k,
          definition: benchmarks[(analysis as OvertimeComparisonAnalysisModel).overtimeBenchmark].Populations[k],
        }));
      } else if (analysis.type === AnalysisType.RAW_VOICE) {
        const benchmarks = project.benchmarks;
        const populations = Object.keys(
          benchmarks[
            (analysis as RawVoiceAnalysisModel).populationBenchmark
          ].Populations
        );

        allPopulations = populations.sort().map((k,i) => ({ 
          id: k.replace(/\W/g,'_').toLowerCase(),
          title: k,
          definition: benchmarks[(analysis as RawVoiceAnalysisModel).populationBenchmark].Populations[k],
        }));
      } else {
        const p = (analysis as DriversOfOutcomeAnalysisModel).focalPopulation
        const populations = [{
          id: p.title.replace(/\W/g,'_').toLowerCase(),
          ...p
        }];
        allPopulations = populations;
      }
    }
    return allPopulations;
  }, [project, analysis]);

  useEffect(() => {
    if (JSON.stringify(populations) !== JSON.stringify(_populations)) {
      setPopulations(_populations);
    }
  }, [populations, _populations]);

  return populations;
};
