import Fuse from 'fuse.js';
import { useEffect, useMemo, useState } from 'react';

export const useFuse = <T,>(
    list: readonly T[],
    options: Fuse.IFuseOptions<T>,
    searchQuery: string
) => {
    const [fuse] = useState<Fuse<T>>(new Fuse(list, options));

    useEffect(() => {
      fuse.setCollection(list)
    }, [list]);

    return useMemo(() => {
        return searchQuery
          ? fuse.search(searchQuery)
          : list.map(
            (a) =>
            ({
              item: a,
              matches: [],
              refIndex: -1,
            } as Fuse.FuseResult<T>)
          );
    }, [fuse, searchQuery, list]);   
};
