import { number } from 'echarts';
import { DateTime } from 'luxon';

export function dateToString(date: Date) {
  const userTimezone = DateTime.local().zoneName;

  // Adjust the date to the user's timezone
  const adjustedDate = DateTime.fromJSDate(date, {
    zone: 'UTC'
  }).setZone(userTimezone);
  return adjustedDate.toLocaleString(DateTime.DATETIME_MED);
}

export function populationToString(name: string, population: Object) {
  if (isNaN(Number(name))) {
    return name;
  }
  return Object.keys(population)
    .map((key) => `${population[key][0].Column} ${key} ${population[key][1]}`)
    .join('-');
}

export function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
