import { useQuery } from '@tanstack/react-query';
import ProjectAPI, { ProjectMetricType } from '@apis/ProjectAPI';
import { userFriendlyTopicThemeName } from '@utils/TopicUtils';
import { Population } from '@redux/benchmarksSlice';

export const useQuotes = (
  projectId: string,
  organizationId: number,
  population: Population,
  topics?: string[],
  sentiments?: string[],
  demographics?: object,
  coMentionedTopic?: string,
  coMentionType?: ProjectMetricType
) => {
  var formattedTopics: string[] = [];
  if (
    topics.filter((topic: string | undefined) => topic !== undefined).length > 0
  ) {
    formattedTopics = topics.concat(
      topics.map((topic: string) => userFriendlyTopicThemeName(topic))
    );
  }

  if (coMentionedTopic !== undefined) {
    const formattedCoMentionedTopic =
      userFriendlyTopicThemeName(coMentionedTopic);
    return useQuery({
      queryKey: [
        'co-mention-quotes',
        projectId,
        organizationId,
        null,
        population,
        formattedTopics,
        coMentionedTopic,
        coMentionType,
        demographics,
      ],
      queryFn: () =>
        ProjectAPI.getCoMentionQuotes(
          projectId,
          organizationId,
          null,
          population,
          formattedTopics,
          formattedCoMentionedTopic,
          coMentionType,
          demographics
        ),
      staleTime: 1000 * 60 * 30, // 30 minutes... Remember, when we update an analysis or something, we will invalidate this cache
      enabled: !(
        !projectId ||
        !organizationId ||
        !topics ||
        !population ||
        !sentiments ||
        !demographics ||
        !coMentionedTopic ||
        !coMentionType
      ),
    });
  } else {
    return useQuery({
      queryKey: [
        'quotes',
        projectId,
        organizationId,
        null,
        population,
        topics,
        sentiments,
        demographics,
      ],
      queryFn: async () => {
        return ProjectAPI.getQuotes(
          projectId,
          organizationId,
          null,
          population,
          formattedTopics,
          sentiments,
          demographics
        );
      },
      staleTime: 1000 * 60 * 30, // 30 minutes... Remember, when we update an analysis or something, we will invalidate this cache
      enabled: !(
        !projectId ||
        !organizationId ||
        !topics ||
        !population ||
        !sentiments ||
        !demographics
      ),
    });
  }
};
