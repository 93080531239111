import React, { ReactComponentElement } from 'react';
import { Center, Container, Title } from '@mantine/core';
import QuoteReader from '../components/QuoteReader';
import SectionWrapper from '../components/SectionWrapper';
import DashboardAPI from '../apis/DashboardAPI';
import DashboardModel from '../models/DashboardModel';
import ViewModel from '../models/ViewModel';
import CRN from '../models/Crn';
import ResourceAPI from '../apis/ResourceAPI';
import { ResourceMetadataModel, ResourceModel } from '../models/ResourceModel';
import { withTranslation, WithTranslation } from 'react-i18next';

interface QuoteReaderControllerState {
  quoteDatasetOptions: any[];
  demographicOptions: any[];
  demographicValueMapping: any;
}

// Class that allows users to make requests to the API to fetch quotes
class QuoteReaderController extends React.Component<WithTranslation> {
  state = {
    quoteDatasetOptions: [],
    demographicOptions: [],
    demographicValueMapping: {},
  };

  componentDidMount(): void {
    // Load the dashboards and fetch information about the quotes to use...
    this.loadQuoteInformation();
  }

  loadQuoteInformation = async () => {
    // Load all the dashboards...

    try {
      const dashboards: DashboardModel[] = await DashboardAPI.listDashboards();
      var crns: { [key: string]: CRN[] } = {};

      // TODO: - Make a mapping of dashboard to quote dataset with alias

      dashboards.forEach((dashboard: DashboardModel) => {
        // Loop through all of the views and get the quote dataset options...
        var usedCrns: Set<String> = new Set();
        dashboard.views.forEach((view: ViewModel) => {
          // Get the CRNs for the view...
          if (view.filterMapping) {
            // Get the quotes...
            const quotes = view.filterMapping.quoteDatasets;
            for (const [key, crn] of Object.entries(quotes)) {
              if (dashboard.title in crns) {
                if (!usedCrns.has(crn.identifier)) {
                  crns[dashboard.title].push(crn);
                  usedCrns.add(crn.identifier);
                }
              } else {
                crns[dashboard.title] = [crn];
                usedCrns.add(crn.identifier);
              }
            }
          }
        });
      });

      // Now let's go get additional information such as the name of the quote dataset and metadata...
      // Loop through the dashboard titles
      let quoteDatasetOptions: any[] = [];
      let demographicOptions: any[] = [];
      let demographicValueMapping: any = {};

      var usedDemographics: Set<String> = new Set();
      for (const [dashboardTitle, crnList] of Object.entries(crns)) {
        // Loop through the CRNs
        for (const crn of crnList) {
          // Get the resource
          const resource: ResourceModel = await ResourceAPI.getResource(crn);
          // Get the metadata
          const metadata: ResourceMetadataModel =
            await ResourceAPI.getResourceMetadata(resource);
          // Add to the quote dataset options
          quoteDatasetOptions.push({
            value: resource.crn.identifier,
            label: resource.alias ? resource.alias : resource.crn.identifier,
            group: dashboardTitle,
          });

          // Add the demographic options
          const columns =
            metadata.content.properties.ImmutableAttributes.Columns;

          for (const column of columns) {
            const columnName: string = Object.keys(column)[0];
            const columnObject = column[Object.keys(column)[0]];

            if (
              !usedDemographics.has(columnName) &&
              !columnName.startsWith('CX_')
            ) {
              demographicOptions.push({
                value: columnName,
                label: columnName,
                group: resource.alias
                  ? resource.alias
                  : resource.crn.identifier,
              });
              usedDemographics.add(columnName);
            }

            const values: any[] | undefined | null = columnObject.Values;
            if (values) {
              // Add the values to the mapping
              demographicValueMapping[columnName] = values;
            }
          }
        }
      }

      this.setState({
        quoteDatasetOptions: quoteDatasetOptions,
        demographicOptions: demographicOptions,
        demographicValueMapping: demographicValueMapping,
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  render() {
    return (
      <SectionWrapper>
        <Title order={3}>Quotes</Title>
        <br />
        <QuoteReader
          filtersEnabled={true}
          coreProps={{
            quotes: [],
            allowFlagging: true,
            allowReporting: true,
            profileEnabled: true,
            focalSentence: '',
            showTopics: true,
            showInfo: true,

            topicToValueMapping: {},
            topicDescriptions: {},
            valueDescriptions: {},
          }}
          filterProps={{
            datasetFilterEnabled: true,
            datasetOptions: this.state.quoteDatasetOptions,

            sentimentFilterEnabled: true,
            sentimentOptions: [
              { value: 'positive', label: 'Positive' },
              { value: 'negative', label: 'Negative' },
              { value: 'indeterminate', label: 'Neutral' },
            ],

            demographicFilterEnabled: true,
            demographicOptions: this.state.demographicOptions,

            demographicValueMapping: this.state.demographicValueMapping,

            valuesFilterEnabled: true,
            valuesOptions: [],

            topicsFilterEnabled: true,
            topicsOptions: [],
          }}
        />
      </SectionWrapper>
    );
  }
}

export default withTranslation('dashboards')(QuoteReaderController);
