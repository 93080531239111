import { FC, useCallback, useEffect, useState } from 'react';
import SectionWrapper from '@components/SectionWrapper';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { ChartType, useAnalysisStore } from '@stores/AnalysisStore';
import PopulationAgainstBenchmarkChart from '@components/charts/PopulationAgainstBenchmarkChart';
import BreakdownByTopicChart from '@components/charts/BreakdownByTopicChart';
import ChartSelector from '@components/ChartSelector';
import { Col, Grid, Stack, Title, Center, Group, Tooltip, ActionIcon, Flex, Transition } from '@mantine/core';
import BreakdownByPopulationPlot from '@components/charts/BreakdownByPopulationPlot';
import {
  AnalysisDetailToolbar,
  AnalysisDetailToolbarSkeleton,
} from '@components/AnalysisDetailToolbar';
import { TopicPanel } from '@components/TopicPanel';
import CoMentionsBarPlot from '@components/charts/CoMentionsBarPlot';
import { FocalTopicFilter } from '@components/FocalTopicFilter';
import { useBenchmarkData } from '@apis/hooks/useBenchmarkData';
import { useDriversData } from '@apis/hooks/useDriversData';
import { AnalysisType } from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import { useProjectTopics } from '@apis/hooks/useProjectTopics';
import DriversOverviewPlot from '@components/charts/DriversOverviewPlot';
import ShapDriversPowerBarPlot from '@components/charts/ShapDriversPowerBarPlot';
import { useCurrentAnalysis } from '@hooks/useCurrentAnalysis';
import CheckAnalysisStatus from '@components/charts/CheckAnalysisStatus';
import { useCurrentPopulations } from '@hooks/useCurrentPopulations';
import { IconCheck, IconDownload, IconEdit, IconTrash } from '@tabler/icons';
import { useNavigate } from '@hooks/useNavigate';
import { ProjectDownloadDataModal } from '@components/ProjectDownloadDataModal';
import { notifications } from '@mantine/notifications';
import { DeleteAnalysisModal } from '@components/DeleteAnalysisModal';
import { useQuoteStore } from '@stores/QuoteStore';
import { useTopicStore } from '@stores/TopicStore';
import RawVoiceChart from '@components/charts/RawVoice';
import { useAppStore } from '@stores/AppStore';
import SegmentsBarPlot from '@components/charts/SegmentsBarPlot';
import ProgressOverTimeChart from '@components/charts/ProgressOverTimeChart';

const NoFocalTopicMessage: React.FC = () => {
  return (
    <Center h={'100%'} mx="auto">
      <Stack>
        <Title order={3}>Please select a topic below to see this chart</Title>
        <FocalTopicFilter />
      </Stack>
    </Center>
  );
};

const AnalysisPage: FC = () => {
  const currentProject = useCurrentProject();
  const currentAnalysis = useCurrentAnalysis();
  const currentPopulations = useCurrentPopulations();
  const selectedChart = useAnalysisStore((s) => s.selectedChart);
  const setSelectedChart = useAnalysisStore((s) => s.setSelectedChart);
  const focalPopulation = useAnalysisStore((s) => s.focalPopulation);
  const setFocalPopulation = useAnalysisStore((s) => s.setFocalPopulation);
  const setBenchmarkData = useAnalysisStore((s) => s.setBenchmarkData);
  const setBenchmarkError = useAnalysisStore((s) => s.setDataError);
  const setBenchmarkIsError = useAnalysisStore((s) => s.setDataIsError);
  const setIsDataLoading = useAnalysisStore((s) => s.setIsDataLoading);
  const setDriversData = useAnalysisStore((s) => s.setDriversData);
  const setDriversDataLoading = useAnalysisStore(
    (s) => s.setDriversDataLoading
  );
  const setDriversDataError = useAnalysisStore((s) => s.setDriversDataError);
  const setDriversDataIsError = useAnalysisStore(
    (s) => s.setDriversDataIsError
  );
  const showTopicPanel = useAppStore((s) => s.showTopicPanel);

  const setAllPopulations = useAnalysisStore((s) => s.setAllPopulations);
  const setActiveTopics = useAnalysisStore((s) => s.setActiveTopics);
  const setActiveThemes = useAnalysisStore((s) => s.setActiveThemes);

  // Topic store
  const focalTopic = useTopicStore((s) => s.focalTopic);
  const setAllTopics = useTopicStore((s) => s.setAllTopics);
  const setAllThemes = useTopicStore((s) => s.setAllThemes);
  const setTopicDefinitions = useTopicStore((s) => s.setTopicDefinitions);

  //Quote Store
  const setQuotesPopulation = useQuoteStore(s => s.setPopulation)

  const [showDataDownload, setShowDataDownload] = useState<boolean>(false);
  const [showDeleteAnalysisModal, setShowDeleteAnalysisModal] = useState(false);
  const navigate = useNavigate();
  const {
    data: benchmarkData,
    isLoading: isBenchmarkDataLoading,
    isError: isBenchmarkError,
    error: benchmarkError,
  } = useBenchmarkData(currentProject, currentAnalysis);
  const { data: topicInformation, isLoading: isTopicInformationLoading } =
    useProjectTopics(currentProject);
  const {
    data: driversData,
    isLoading: isDriversDataLoading,
    isError: isDriversDataError,
    error: driversDataError,
  } = useDriversData(currentProject, currentAnalysis);

  useEffect(() => {
    const _currentPopulations = currentPopulations.map(p => p.title);
    setAllPopulations(_currentPopulations);
    if (!_currentPopulations.includes(focalPopulation) && currentPopulations.length > 0) {
      if(currentAnalysis.type === AnalysisType.PROGRESS_OVER_TIME){
        setFocalPopulation(currentAnalysis.populationOrder?.[0] || _currentPopulations[0])
      }else{
        setFocalPopulation(_currentPopulations[0]);

      }
    }
  }, [currentPopulations, focalPopulation]);

  useEffect(() => {
    if (!currentAnalysis) {
      return;
    }

    setQuotesPopulation(focalPopulation ? {
      id: focalPopulation.replace(/\W/g, '_').toLowerCase(),
      title: focalPopulation,
      definition: currentAnalysis.populationDefinitions[focalPopulation],
    } : undefined)
  }, [currentAnalysis, focalPopulation]);

  useEffect(() => {
    if (benchmarkData) {
      setBenchmarkData(benchmarkData);
    }

    setBenchmarkError(benchmarkError);
    setBenchmarkIsError(isBenchmarkError);
  }, [benchmarkData, isBenchmarkError, benchmarkError]);

  useEffect(() => {
    if (driversData) {
      setDriversData(driversData);
    }

    setDriversDataError(driversDataError);
    setDriversDataIsError(isDriversDataError);
  }, [driversData, isDriversDataError, driversDataError]);

  useEffect(() => {
    if (topicInformation) {
      const { topics, themes, topicDefinitions } = topicInformation;
      setAllTopics(topics);
      setAllThemes(themes);
      setTopicDefinitions(topicDefinitions);

      setActiveTopics(topics);
      setActiveThemes(themes);
    }
  }, [topicInformation]);

  useEffect(() => {
    if (currentAnalysis?.type === AnalysisType.DRIVERS_OF_OUTCOME) {
      setIsDataLoading(
        isDriversDataLoading ||
        isTopicInformationLoading ||
        isBenchmarkDataLoading
      );
    } else {
      setIsDataLoading(isBenchmarkDataLoading || isTopicInformationLoading);
    }

    setDriversDataLoading(isDriversDataLoading);
  }, [isDriversDataLoading, isBenchmarkDataLoading, isTopicInformationLoading]);

  useEffect(() => {
    if (!currentAnalysis) return;
    console.log({ currentAnalysis, type: currentAnalysis.type ,selectedChart},"MY_LOG5")
    if (currentAnalysis.type === AnalysisType.DRIVERS_OF_OUTCOME) {
      if (
        selectedChart === ChartType.POPULATION_AGAINST_BENCHMARK ||
        selectedChart === ChartType.BREAKDOWN_BY_POPULATION
      ) {
        setSelectedChart(ChartType.OVERVIEW_OF_IMPORTANCE);
      }
    } else if (currentAnalysis.type === AnalysisType.INTERNAL_BENCHMARK) {
      if (
        selectedChart === ChartType.OVERVIEW_OF_IMPORTANCE ||
        selectedChart === ChartType.DRIVES_OUTCOME
      ) {
        setSelectedChart(ChartType.POPULATION_AGAINST_BENCHMARK);
      }
    } else if (currentAnalysis.type === AnalysisType.RAW_VOICE) {
      setSelectedChart(ChartType.RAW_VOICE);
    } else if (currentAnalysis.type === AnalysisType.PROGRESS_OVER_TIME) {
      setSelectedChart(ChartType.PROGRESS_OVER_TIME);
    }
  }, [currentAnalysis]);

  const onAnalysisDeleted = useCallback(() => {
    navigate(`/projects/${currentProject.projectId}`)
    setShowDeleteAnalysisModal(false);
    notifications.show({
      id: 'analysis-deleted',
      color: 'green',
      title: 'Analysis deleted',
      message: `Analysis ${currentAnalysis.name} was deleted`,
      icon: <IconCheck size="1rem" />,
      autoClose: 15000,
    });
  }, [currentProject?.projectId, currentAnalysis]);

  if (!currentProject || !currentAnalysis) {
    // TODO: - Add skeleton to represent loading state...
    return (
      <SectionWrapper isFullHeight showBreadcrumbs>
        <AnalysisDetailToolbarSkeleton />
      </SectionWrapper>
    );
  }

  return (
    <>
      <DeleteAnalysisModal
        project={currentProject}
        analysis={currentAnalysis}
        opened={showDeleteAnalysisModal}
        onClose={() => setShowDeleteAnalysisModal(false)}
        onAnalysisDeleted={onAnalysisDeleted}
      />
      <ProjectDownloadDataModal
        project={currentProject}
        defaultSelectedAnalysis={currentAnalysis}
        onCancel={() => setShowDataDownload(false)}
        opened={showDataDownload}
      />
      <SectionWrapper isFullHeight showBreadcrumbs
        rightComponent={
          <Group spacing="xs">
            <Tooltip label="Edit analysis" openDelay={500}>
              <ActionIcon onClick={() => navigate(`/projects/${currentProject.projectId}/analyses/${currentAnalysis.id}/edit`)}>
                <IconEdit size={18} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Download data" openDelay={500}>
              <ActionIcon onClick={() => setShowDataDownload(true)}>
                <IconDownload size={18} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Delete analysis" openDelay={500}>
              <ActionIcon onClick={() => setShowDeleteAnalysisModal(true)}>
                <IconTrash color="red" size={18} />
              </ActionIcon>
            </Tooltip>
          </Group>
        }
      >
        <AnalysisDetailToolbar />
        <Flex style={{ width: "100%", height: "100%" }}>
          <div style={{ width: showTopicPanel ? "calc(100% - 450px)" : "100%", height: "100%", transition: "width ease 0.4s", transitionDelay: showTopicPanel ? "0s" : "0.4s" }}>
            <Stack style={{ height: '100%' }}>
              {selectedChart === ChartType.RAW_VOICE && (
                <RawVoiceChart />
              )}
              {selectedChart === ChartType.OVERVIEW_OF_IMPORTANCE && (
                <CheckAnalysisStatus>
                  <DriversOverviewPlot />
                </CheckAnalysisStatus>
              )}
              {selectedChart === ChartType.DRIVES_OUTCOME && (
                <CheckAnalysisStatus>
                  <ShapDriversPowerBarPlot />
                </CheckAnalysisStatus>
              )}
              {selectedChart === ChartType.POPULATION_AGAINST_BENCHMARK && (
                <PopulationAgainstBenchmarkChart />
              )}
              {selectedChart === ChartType.PROGRESS_OVER_TIME && (
                <ProgressOverTimeChart />
              )}
              {selectedChart === ChartType.BREAKDOWN_BY_TOPIC && (
                <BreakdownByTopicChart />
              )}
              {selectedChart === ChartType.BREAKDOWN_BY_POPULATION && (
                <>
                  {focalTopic ? (
                    <BreakdownByPopulationPlot />
                  ) : (
                    <NoFocalTopicMessage />
                  )}
                </>
              )}
              {selectedChart === ChartType.IN_SAME_BREATH && (
                <>
                  {focalTopic ? (
                    <CoMentionsBarPlot />
                  ) : (
                    <NoFocalTopicMessage />
                  )}
                </>
              )}

              {selectedChart === ChartType.SUB_CULTURES && (
                <>
                  {focalTopic ? (
                    <SegmentsBarPlot />
                  ) : (
                    <NoFocalTopicMessage />
                  )}
                </>
              )}
              <ChartSelector />
            </Stack>
          </div>

          <div style={{ width: showTopicPanel ? 450 : 0, height: "100%", transition: "width ease 0.4s", transitionDelay: showTopicPanel ? "0s" : "0.4s" }}>
            <Transition
              mounted={showTopicPanel}
              transition="fade"
              duration={400}
              timingFunction="ease"
            >
              {(styles) => <TopicPanel style={{ ...styles, transitionDelay: !showTopicPanel ? "0s" : "0.4s" }} showCoMentionTopic={selectedChart === ChartType.IN_SAME_BREATH} />}
            </Transition>
          </div>
        </Flex>
      </SectionWrapper>
    </>
  );
};

export default AnalysisPage;
