import { Group, SelectItem, Text } from '@mantine/core';
import { forwardRef } from 'react';

export interface PopulationItemProps extends SelectItem {
  populationSize?: string;
}

export const PopulationSelectItem = forwardRef<
  HTMLDivElement,
  PopulationItemProps
>(
  (
    { image, label, populationSize, selected, ...others }: PopulationItemProps,
    ref
  ) => (
    <div ref={ref} {...others}>
      <Group position="apart">
        <Text size="sm">{label}</Text>
        <Text size="xs" opacity={selected ? 0.8 : 0.3}>
          {populationSize ? `(${populationSize})` : undefined}
        </Text>
      </Group>
    </div>
  )
);
