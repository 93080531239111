import { useQuery } from '@tanstack/react-query';
import UserAPI from '@apis/UserAPI';
import UserModel from '@models/UserModel';
import UserInviteModel from '@models/UserInviteModel';

export interface User {
  userId: string;
  organizationId: number;
  firstName?: string;
  lastName?: string;
  email: string;
  username: string;
  role: "ADMIN" | "VIEWER";
  status: "ACTIVE" | "INVITED";
  inviteId?: string;
  inviteIssuerId?: string;
}

const transformUsers= (data: (UserModel | UserInviteModel)[]): User[] => {
  return data
    .map((model) => ({
          userId: model.crn.identifier,
          organizationId: model.organizationId,
          firstName: model.firstName,
          lastName: model.lastName,
          email: model.email,
          username: model.username,
          role: "ADMIN", //TODO: update the api to check real role
          status: model instanceof UserModel ? "ACTIVE" : "INVITED",
          inviteId: model instanceof UserInviteModel ? model.inviteId : undefined,
          inviteIssuerId: model instanceof UserInviteModel ? model.issuer.identifier : undefined,
      } as User)
    )
    .filter((sl) => sl !== null);
};

export const useUsers = () => {
  return useQuery({
    queryKey: ['users'],
    queryFn: () => Promise.all([UserAPI.listUsers(), UserAPI.listUserInvites()]).then(([users, invited]) => ([...users, ...invited])) ,
    staleTime: 1000 * 60 * 10, // 10 minutes,
    select: transformUsers,
  });
};
