import { Analysis } from '@hooks/useProjectAnalyses';
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Card,
  Text,
  Flex,
  Group,
  SimpleGrid,
  Stack,
  Tooltip,
  List,
  Popover,
  MantineProvider,
} from '@mantine/core';
import Fuse from 'fuse.js';
import { FC, useCallback, useMemo, useState } from 'react';
import {
  IconArrowRight,
  IconCheck,
  IconDownload,
  IconEdit,
  IconTrash,
} from '@tabler/icons';
import Link from '@components/Link';
import { dateToString } from '@utils/FormatUtils';
import AnalysisStatusIcon from './AnalysisStatusIcon';
import AnalysisTypeIcon from './AnalysisTypeIcon';
import { Highlight } from '@mantine/core';
import { useHighlights } from '@hooks/useHighlights';
import { useNavigate } from '@hooks/useNavigate';
import { DeleteAnalysisModal } from './DeleteAnalysisModal';
import ProjectModel from '@models/ProjectModel';
import { notifications } from '@mantine/notifications';
import { AnalysisType, useAnalysisEditionStore } from '@stores/AnalysisEditionStore';

interface AnalysesCardProps {
  project: ProjectModel;
  analysis: Analysis;
  matches: readonly Fuse.FuseResultMatch[];
  onDownloadData: (analysisId: string) => void;
}

const AnalysesCard: FC<AnalysesCardProps> = ({
  project,
  analysis,
  matches,
  onDownloadData,
}) => {
  const navigate = useNavigate();
  const highlightName = useHighlights('name', matches);
  const highlightPopulations = useHighlights('populations', matches);
  const highlightOutcome = useHighlights('outcome', matches);
  const [showDeleteAnalysisModal, setShowDeleteAnalysisModal] = useState(false);

  const allPopulations = useMemo(
    () =>
      analysis.populations.sort().map((population: string) => {
        return (
          <List.Item key={population}>
            <Highlight highlight={highlightPopulations}>{population}</Highlight>
          </List.Item>
        );
      }),
    [analysis.populations, highlightPopulations]
  );

  const onAnalysisDeleted = useCallback(() => {
    setShowDeleteAnalysisModal(false);
    notifications.show({
      id: 'analysis-deleted',
      color: 'green',
      title: 'Analysis project',
      message: `Analysis ${analysis.name} was deleted`,
      icon: <IconCheck size="1rem" />,
      autoClose: 15000,
    });
  }, []);

  const listItems = useMemo(() => {
    if (analysis.populations.length > 4) {
      return (
        <>
          {analysis.populations
            .sort()
            .slice(0, 4)
            .map((pop, i) => (
              <List.Item key={pop}>
                <Highlight highlight={highlightPopulations}>{pop}</Highlight>
              </List.Item>
            ))}
          <List.Item>
            <Popover withinPortal withArrow>
              <Popover.Target>
                <Anchor>{analysis.populations.length - 4} more...</Anchor>
              </Popover.Target>
              <Popover.Dropdown mah={400} style={{ overflow: 'scroll' }}>
                <Text weight={500}>All Populations</Text>
                <List withPadding>{allPopulations}</List>
              </Popover.Dropdown>
            </Popover>
          </List.Item>
        </>
      );
    } else {
      return (
        <>
        {
          analysis.type === AnalysisType.PROGRESS_OVER_TIME ? (
            <>
             {analysis.populationOrder.map((pop, i) => (
            <List.Item key={pop}>
              <Highlight highlight={highlightPopulations}>{pop}</Highlight>
            </List.Item>
          ))}
            </>
          ) : (
            <>
             {analysis.populations.sort().map((pop, i) => (
            <List.Item key={pop}>
              <Highlight highlight={highlightPopulations}>{pop}</Highlight>
            </List.Item>
          ))}
            </>
          )
        }
         
        </>
      );
    }
  }, [analysis.populations, highlightPopulations]);

  return (
    <>
      <DeleteAnalysisModal
        project={project}
        analysis={analysis}
        opened={showDeleteAnalysisModal}
        onClose={() => setShowDeleteAnalysisModal(false)}
        onAnalysisDeleted={onAnalysisDeleted}
      />
      <Card
        shadow="sm"
        radius="md"
        withBorder
        key={analysis.id}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Card.Section withBorder inheritPadding p="xs">
          <Group position="apart" noWrap>
            <Group noWrap>
              <AnalysisStatusIcon status={analysis.status} />
              <AnalysisTypeIcon type={analysis.type} />
              <Anchor
                component={Link}
                to={`/projects/${analysis.projectId}/analyses/${analysis.id}`}
                weight={500}
              >
                <Highlight highlight={highlightName}>{analysis.name}</Highlight>
              </Anchor>
            </Group>
            <Group spacing={0}>
              <Tooltip label="Edit analysis" openDelay={500}>
                <ActionIcon onClick={() => navigate(`/projects/${analysis.projectId}/analyses/${analysis.id}/edit`)}>
                  <IconEdit size={14} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Download data" openDelay={500}>
                <ActionIcon onClick={() => onDownloadData(analysis.id)}>
                  <IconDownload size={14} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Delete analysis" openDelay={500}>
                <ActionIcon onClick={() => setShowDeleteAnalysisModal(true)}>
                  <IconTrash color="red" size={14} />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Group>
        </Card.Section>

        <Stack spacing={0} p="xs" style={{ flexGrow: 1 }}>
          <MantineProvider
            theme={{
              components: {
                List: {
                  styles: {
                    item: {
                      overflow: 'hidden',
                    },
                    itemWrapper: {
                      overflow: 'hidden',
                      width: '90%',
                      '& span': {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    },
                  },
                },
              },
            }}
          >
            {analysis.populations.length > 0 && (
              <Stack spacing={0}>
                <Text color="dimmed">{`Population${
                  analysis.populations.length > 1 ? 's' : ''
                }`}</Text>
                <List size={'sm'} withPadding>
                  {listItems}
                </List>
              </Stack>
            )}

            {analysis.outcome.length > 0 && (
              <Stack spacing={0} mt="xs">
                <Text color="dimmed">Outcome</Text>
                <List size={'sm'} withPadding>
                  <List.Item>
                    <Highlight highlight={highlightOutcome}>
                      {analysis.outcome[0].alias}
                    </Highlight>
                  </List.Item>
                </List>
              </Stack>
            )}
          </MantineProvider>
        </Stack>

        <Card.Section>
          <Group position="apart" align="end" p="xs">
            <Stack spacing={0}>
              <Text size="xs" color="dimmed">
                {analysis.created_at.getTime() === analysis.updated_at.getTime()
                  ? 'Created At'
                  : 'Last update'}
              </Text>
              <Text size="xs" color="dimmed">
                {analysis.created_at.getTime() === analysis.updated_at.getTime()
                  ? dateToString(analysis.created_at)
                  : dateToString(analysis.updated_at)}
              </Text>
            </Stack>
            <Button
              size="xs"
              component={Link}
              to={`/projects/${analysis.projectId}/analyses/${analysis.id}`}
              rightIcon={<IconArrowRight size={12} />}
              variant="light"
            >
              View
            </Button>
          </Group>
        </Card.Section>
      </Card>
    </>
  );
};

export default AnalysesCard;
