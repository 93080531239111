import { Select } from '@mantine/core';
import { useAnalysisStore } from '@stores/AnalysisStore';
import { useEffect, useMemo } from 'react';
import {
  PopulationItemProps,
  PopulationSelectItem,
} from './PopulationSelectItem';
import { BenchmarkDataPoint } from '@components/charts/datamodels/BenchmarkDataPoint';
import { useQuoteStore } from '@stores/QuoteStore';
import { useCurrentPopulations } from '@hooks/useCurrentPopulations';

export const FocalPopulationFilter: React.FC = () => {
  const allPopulations = useAnalysisStore((s) => s.allPopulations);
  const currentPopulations = useCurrentPopulations();
  const data = useAnalysisStore((s) => s.data);
  const focalPopulation = useAnalysisStore((s) => s.focalPopulation);
  const setFocalPopulation = useAnalysisStore((s) => s.setFocalPopulation);
  const setPopulation = useQuoteStore((s) => s.setPopulation);
  
  useEffect(() => {
    setPopulation(currentPopulations.find(p => p.title === focalPopulation))
  }, [focalPopulation,currentPopulations])
  const focalPopulationOptions: PopulationItemProps[] = useMemo(() => {
    // Get the unique population names + rowCount...
    const populationCounts = new Map<string, number>();

    data.forEach((dataPoint: BenchmarkDataPoint) => {
      populationCounts.set(dataPoint.populationName, dataPoint.rowCount);
    });

    return allPopulations.map((population) => {
      return {
        value: population,
        label: population,
        populationSize: populationCounts.get(population)?.toLocaleString(),
      } as PopulationItemProps;
    }) as unknown as PopulationItemProps[];
  }, [allPopulations, data]);

  return (
    <Select
      hoverOnSearchChange
      size="xs"
      style={{ width: '250px' }}
      placeholder="Focal Population"
      searchable
      data={focalPopulationOptions}
      itemComponent={PopulationSelectItem}
      value={focalPopulation}
      onChange={setFocalPopulation}
    />
  );
};

export const FocalPopulationFilterSkeleton: React.FC = () => {
  return (
    <Select
      size="xs"
      style={{ width: '250px' }}
      placeholder="Focal Population"
      searchable
      data={[]}
      itemComponent={PopulationSelectItem}
      value={undefined}
      onChange={() => {}}
      disabled
    />
  );
};
