import {
    ActionIcon,
    Group,
    Table,
    UnstyledButton,
    Text,
    Center,
    rem,
    Tooltip,
    Stack,
    Badge,
    CopyButton,
  } from '@mantine/core';

  import {
    IconChevronDown,
    IconChevronUp,
    IconSelector,
  } from '@tabler/icons-react';
  import React, { useCallback, useMemo } from 'react';
  
  import {
    SortingState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
  } from '@tanstack/react-table';
  
  import { IconCheck, IconCopy, IconTrash, IconUserX } from '@tabler/icons';
import { User } from '@apis/hooks/userUsers';
import { openConfirmModal } from '@mantine/modals';
import UserAPI from '@apis/UserAPI';
import { notifications } from '@mantine/notifications';
import { useQueryClient } from '@tanstack/react-query';
  

  
  const columnHelper = createColumnHelper<User>();
  
  interface ThProps {
    children: React.ReactNode;
    reversed: boolean;
    sorted: boolean;
    onClick: (event: any) => void;
    sortable: boolean;
  }
  
  const Th = ({ children, reversed, sorted, onClick, sortable }: ThProps) => {
    const Icon = sorted
      ? reversed
        ? IconChevronUp
        : IconChevronDown
      : IconSelector;
    return (
      <th>
        <UnstyledButton onClick={onClick}>
          <Group position="apart">
            <Text fw={500} fz="sm">
              {children}
            </Text>
            {sortable && (
              <Center>
                <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
              </Center>
            )}
          </Group>
        </UnstyledButton>
      </th>
    );
  };
  
  interface UserListProps {
    isLoading: boolean;
    hasError: boolean;
    error?: JSX.Element;
    users?: User[];
  }

  export const UserList = ({users}: UserListProps) => {
    const queryClient = useQueryClient();
    const confirmDeleteInvite = useCallback((user: User) => {
        openConfirmModal({
          title: 'Delete user invite',
          centered: true,
          children: (<Text size="sm">Are you sure you want to delete this invite?</Text>),
          labels: {
            confirm: "Delete",
            cancel: "Cancel",
          },
          confirmProps: { color: 'red' },
          onConfirm: async () => {
            UserAPI.deleteUserInvite(user.organizationId, user.inviteId).then(data => {
              queryClient.refetchQueries({ queryKey: ['users'] });
              notifications.show({
                id: 'invitation-deleted',
                color: 'green',
                title: 'Invitation deleted',
                message: `The invitation for ${user.email} was deleted`,
                icon: <IconCheck size="1rem" />,
              })
            }   
            ).catch(error =>    
                notifications.show({
                    id: 'invitation-deleted-error',
                    color: 'red',
                    title: 'Internal server error',
                    message: error.response.data.Message,
                })
            )
          },
        });
    }, [queryClient]);

    const columns = useMemo(
      () => [
        columnHelper.accessor('firstName', {
          header: 'User',
          cell: (info) => {
            return <Stack spacing={0}>
              <Text truncate size="sm" weight={500}>
                {info.row.original.firstName} {info.row.original.lastName}
              </Text>
              <Text truncate size="xs" color="dimmed">
                {info.row.original.email}
              </Text>
            </Stack>
          },
        }),
  
        columnHelper.accessor('role', {
          header: 'Role',
          cell: (info) => <Text truncate size="sm">{info.getValue()}</Text>,
        }),
  
        columnHelper.accessor('status', {
          header: 'Status',
          cell: (info) => <Badge color={info.getValue() === "ACTIVE" ? "blue" : "yellow"}>{info.getValue()}</Badge>,
        }),
  
        columnHelper.display({
          header: 'Actions',
          cell: (info) => {
            return (
              <Group noWrap spacing={"xs"}>
                <CopyButton value={""} timeout={2000}>
                  {({ copied, copy }) => (
                    <Tooltip
                      label={copied ? 'Copied' : 'Copy ID'}
                      withArrow
                      position="top"
                    >
                      <ActionIcon
                        color={copied ? 'teal' : 'gray'}
                        variant="subtle"
                        onClick={copy}
                      >
                        { copied ? <IconCheck size={18} /> : <IconCopy size={18} /> }
                      </ActionIcon>
                    </Tooltip>
                  )}
                </CopyButton>
                { info.row.original.inviteId &&  <Tooltip label="Delete invite" openDelay={500}>
                  <ActionIcon color="red" onClick={() => confirmDeleteInvite(info.row.original)}>
                    <IconTrash color="red" size={18} />
                  </ActionIcon>
                </Tooltip> }
              </Group>
            );
          },
        }),
      ],
      []
    );
  
    const [sorting, setSorting] = React.useState<SortingState>([]);
  
    const table = useReactTable({
      data: users,
      columns,
      state: {
        sorting,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: true,
    });
  
    return (
      <>
        <Table fontSize={'md'}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      sortable={header.column.id !== 'Actions'}
                      sorted={header.column.getIsSorted() !== false}
                      reversed={header.column.getIsSorted() === 'asc'}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  };
  