import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import breadcrumbsReducer from './breadcrumbsSlice';
import benchmarksReducer from './benchmarksSlice';
import datasetReducer from './datasetSlice';
import analysesReducer from './analysesSlice';

export interface RootState {
  breadcrumbs: any;
  benchmarks: any;
  dataset: any;
  analyses: any;
}
const rootReducer = combineReducers({
  breadcrumbs: breadcrumbsReducer,
  benchmarks: benchmarksReducer,
  dataset: datasetReducer,
  analyses: analysesReducer,
});
export { rootReducer };

const store = configureStore({
  reducer: {
    breadcrumbs: breadcrumbsReducer,
    benchmarks: benchmarksReducer,
    dataset: datasetReducer,
    analyses: analysesReducer,
    reducer: rootReducer,
  },
});

export { store };
