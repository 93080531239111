import { Group, Stack, Text, Button, Modal, Checkbox } from '@mantine/core';
import { useAnalysisEditionStore } from '@stores/AnalysisEditionStore';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

export const FixPopulationsModal: React.FC = () => {
  const [applyToAll, setApplyToAll] = useState(false);
  const populationsToFix = useAnalysisEditionStore((s) => s.populationsToFix);
  const setPopulationsToFix = useAnalysisEditionStore(
    (s) => s.setPopulationsToFix
  );
  const populations = useAnalysisEditionStore((s) => s.populations);
  const setPopulations = useAnalysisEditionStore((s) => s.setPopulations);

  useEffect(() => {
    if (populationsToFix.length === 1) {
      setApplyToAll(false);
    }
  }, [populationsToFix.length]);

  const handleSkip = useCallback(() => {
    if (applyToAll) {
      setPopulationsToFix([]);
    } else {
      const newArray = [...populationsToFix];
      newArray.splice(0, 1);
      setPopulationsToFix(newArray);
    }
  }, [applyToAll, populationsToFix]);

  const handleKeepBoth = useCallback(() => {
    if (populationsToFix.length > 0) {
      setPopulations([
        ...populations,
        {
          ...populationsToFix[0],
          title: populationsToFix[0].title + ' (copy)',
        },
      ]);
      const newArray = [...populationsToFix];
      newArray.splice(0, 1);
      setPopulationsToFix(newArray);
    }
  }, [populations, populationsToFix]);

  const handleStop = useCallback(() => {
    setPopulationsToFix([]);
  }, []);

  const handleReplace = useCallback(() => {
    if (populationsToFix.length > 0) {
      if (applyToAll) {
        const old = populations.filter(
          (p) => !populationsToFix.map((i) => i.title).includes(p.title)
        );
        setPopulations([...old, ...populationsToFix]);
        setPopulationsToFix([]);
      } else {
        const old = populations.filter(
          (p) => p.title !== populationsToFix[0].title
        );
        setPopulations([...old, populationsToFix[0]]);
        const newArray = [...populationsToFix];
        newArray.splice(0, 1);
        setPopulationsToFix(newArray);
      }
    }
  }, [applyToAll, populations, populationsToFix]);

  if (populationsToFix.length === 0) {
    return null;
  }

  return (
    <Modal
      opened={true}
      onClose={() => {}}
      size="lg"
      centered
      withCloseButton={false}
      closeOnEscape={false}
      closeOnClickOutside={false}
    >
      <Stack spacing="md">
        <Text>{`The population “${populationsToFix[0].title}” already exists in this analysis. Do you want to replace it with the one you’re creating?`}</Text>
      </Stack>
      <Group position="apart" mt="xl">
        {populationsToFix.length > 1 ? (
          <Checkbox
            label="Apply to all"
            checked={applyToAll}
            onChange={(e) => setApplyToAll(e.currentTarget.checked)}
          ></Checkbox>
        ) : (
          <div />
        )}
        <Group position="right">
          {populationsToFix.length > 1 && (
            <Button color="blue" variant="outline" onClick={handleSkip}>
              Skip
            </Button>
          )}
          {populationsToFix.length === 1 && (
            <Button color="blue" variant="outline" onClick={handleKeepBoth}>
              Keep both
            </Button>
          )}
          <Button color="blue" variant="outline" onClick={handleStop}>
            Stop
          </Button>
          <Button color="blue" variant="outline" onClick={handleReplace}>
            Replace
          </Button>
        </Group>
      </Group>
    </Modal>
  );
};
