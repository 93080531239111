import React from 'react';
import { Popover, Badge, Anchor, Text } from '@mantine/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTruckFast,
  faHandshake,
  faHeadset,
  faBullseye,
  faHandsHoldingCircle,
  faLightbulb,
  faScaleBalanced,
  faStopwatch,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons';
import { userFriendlyTopicThemeName } from '@utils/TopicUtils';

interface VopicDescriptionBadgeProps {
  vopic: string;
  isFocal: boolean;
  isValue: boolean;
  isCustomerSpecificVopic: boolean;
  topicToValueMapping: {
    [key: string]: string;
  };
  topicDescriptions: {
    [key: string]: string;
  };
  valueDescriptions: {
    [key: string]: string;
  };

  valueIconMapping: {
    [key: string]: IconDefinition;
  };
}

const defaultValueIconMapping: { [key: string]: IconDefinition } = {
  AGILITY: faTruckFast,
  COLLABORATION: faHandshake,
  CUSTOMER: faHeadset,
  EXECUTION: faBullseye,
  DIVERSITY: faHandsHoldingCircle,
  INNOVATION: faLightbulb,
  INTEGRITY: faScaleBalanced,
  PERFORMANCE: faStopwatch,
};

const convertCleanVopicToOriginal = (vopic: string): string => {
  // Replace all spaces with underscores, and capitalize the first letter of each word if there are spaces...

  if (vopic.endsWith('_T')) {
    return vopic;
  }

  if (vopic.includes(' ')) {
    var words = vopic.split(' ');
    var newWords = [];
    for (var i = 0; i < words.length; i++) {
      newWords.push(words[i].charAt(0).toUpperCase() + words[i].slice(1));
    }
    return newWords.join('_');
  } else {
    return vopic;
  }
};

class VopicDescriptionBadge extends React.Component<VopicDescriptionBadgeProps> {
  constructor(props: VopicDescriptionBadgeProps) {
    super(props);
  }

  getIcon(vopic: string, isValue: boolean): IconDefinition {
    // If we are a topic, we need to get the associated value...
    var value = vopic;
    if (!isValue) {
      if (this.props.topicToValueMapping[vopic]) {
        value = this.props.topicToValueMapping[vopic];
      }
    }

    if (this.props.valueIconMapping[value]) {
      return this.props.valueIconMapping[value];
    } else {
      if (defaultValueIconMapping[value]) {
        return defaultValueIconMapping[value];
      }
    }

    return faBuilding;
  }

  render() {
    var description = <i style={{ color: 'gray' }}>No description available</i>;
    if (this.props.isValue) {
      if (this.props.valueDescriptions[this.props.vopic]) {
        description = (
          <Text size={15}>
            {this.props.valueDescriptions[this.props.vopic]}
          </Text>
        );
      }
    } else {
      if (
        this.props.topicDescriptions[
          convertCleanVopicToOriginal(this.props.vopic)
        ]
      ) {
        description = (
          <Text size={15}>
            {
              this.props.topicDescriptions[
                convertCleanVopicToOriginal(this.props.vopic)
              ]
            }
          </Text>
        );
      }
    }

    // var learnMoreSection = (
    //   <Anchor size={"xs"}>Learn more about {this.props.vopic} →</Anchor>
    // );
    var learnMoreSection = null;

    // var badgeColor = this.props.isValue ? "grape" : "blue";
    var badgeColor = this.props.isFocal ? 'grape' : 'blue';
    var iconColor = this.props.isValue ? '#b862d8' : '#44CC1D8';
    if (this.props.isCustomerSpecificVopic) {
      badgeColor = 'orange';
      iconColor = '#ed8537';
      learnMoreSection = (
        <Text size={12} color="dimmed">
          <i>
            This {this.props.isValue ? 'value' : 'topic'} is specific to your
            organization
          </i>
        </Text>
      );
    }

    return (
      <Popover width={250} position="bottom" withArrow shadow="md">
        <Popover.Target>
          <Badge
            style={{ cursor: 'pointer' }}
            color={badgeColor}
            key={`vopic-${this.props.vopic}`}
          >
            {userFriendlyTopicThemeName(this.props.vopic)}
          </Badge>
        </Popover.Target>
        <Popover.Dropdown>
          <Badge
            sx={{ paddingLeft: 8 }}
            variant="light"
            color={badgeColor}
            leftSection={
              <>
                <FontAwesomeIcon
                  color={iconColor}
                  icon={this.getIcon(this.props.vopic, this.props.isValue)}
                />
              </>
            }
          >
            {userFriendlyTopicThemeName(this.props.vopic)}
          </Badge>
          <br />
          <Text style={{ marginTop: '10px', fontWeight: 'normal' }} size="xs">
            {description}
          </Text>
          {learnMoreSection}
        </Popover.Dropdown>
      </Popover>
    );
  }
}

export default VopicDescriptionBadge;
