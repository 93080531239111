import { useParams } from 'react-router-dom';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { Analysis, useProjectAnalyses } from './useProjectAnalyses';

export const useCurrentAnalysis = (): Analysis | undefined => {
  const params = useParams();
  const project = useCurrentProject();
  const analyses = useProjectAnalyses(project)

  if (project) {
    return analyses.find((a) => a.id === params.analysisId);
  }
  return undefined;
};
