import ProjectModel from '@models/ProjectModel';
import { IProjectAnalysisModel } from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import { useProjectDatasets } from '@apis/hooks/useProjectDatasets';
import { useMemo } from 'react';
import { AnalysisType } from '@stores/AnalysisEditionStore';
import { DriversOfOutcomeAnalysisModel } from '@models/ProjectAnalysisModel/DriversOfOutcomeAnalysisModel';
import { InternalBenchmarkAnalysisModel } from '@models/ProjectAnalysisModel/InternalBenchmarkAnalysisModel';
import { getAliasFromColumn } from '@utils/MetadataUtils';
import { RawVoiceAnalysisModel } from '@models/ProjectAnalysisModel/RawVoiceAnalysisModel';
import { OvertimeComparisonAnalysisModel } from '@models/ProjectAnalysisModel/OvertimeComparisonAnalysisModel';

export interface Analysis extends IProjectAnalysisModel {
  projectId: string;
  populations: string[];
  populationOrder?: string[];
  populationDefinitions: Record<string, any>;
  outcome: {
    rawName: string,
    alias: string,
  }[];
}
export const useProjectAnalyses = (project: ProjectModel | undefined): Analysis[] => {
  const { data: datasetMetadata } = useProjectDatasets(project);

  return useMemo(() => {
    if (!project) {
      return [];
    }

    return project.analyses.map((analysis) => {
      let populations = [];
      let populationDefinitions = {};
      const outcome = [];
      if (analysis.type === AnalysisType.DRIVERS_OF_OUTCOME) {
        const a = analysis as DriversOfOutcomeAnalysisModel;
        populations.push(a.focalPopulation.title);
        populationDefinitions[a.focalPopulation.title] = a.focalPopulation.definition

        const measures = a.outcome.measure;
        const rawOutcomeName = measures[0].column;
        const outcomeDataset = measures[0].dataset;

        if (datasetMetadata !== undefined) {
          const associatedMetadata = datasetMetadata.get(outcomeDataset);
          outcome.push({
            rawName: rawOutcomeName,
            alias: getAliasFromColumn(rawOutcomeName, associatedMetadata),
          });
        } else {
          outcome.push({
            rawName: rawOutcomeName,
            alias: rawOutcomeName,
          });
        }
      } else if (analysis.type === AnalysisType.INTERNAL_BENCHMARK) {
        const a = analysis as InternalBenchmarkAnalysisModel;
        const benchmark = a.internalBenchmark;
        if(project.benchmarks[benchmark]) {
          populationDefinitions = project.benchmarks[benchmark]['Populations'];
          populations = Array.from(
            Object.keys(project.benchmarks[benchmark]['Populations'])
          );
        }
      } else if (analysis.type === AnalysisType.RAW_VOICE) {
        const a = analysis as RawVoiceAnalysisModel;
        const benchmark = a.populationBenchmark;
        if(project.benchmarks[benchmark]) {
          populationDefinitions = project.benchmarks[benchmark]['Populations'];
          populations = Array.from(
            Object.keys(project.benchmarks[benchmark]['Populations'])
          );
        }
      } else if (analysis.type === AnalysisType.PROGRESS_OVER_TIME) {
        const a = analysis as OvertimeComparisonAnalysisModel;
        const benchmark = a.overtimeBenchmark;
        

        if(project.benchmarks[benchmark]) {
          populationDefinitions = project.benchmarks[benchmark]['Populations'];
          populations = Array.from(
            Object.keys(project.benchmarks[benchmark]['Populations'])
          );
        }
      }

      return {
        ...analysis,
        populations,
        populationDefinitions,
        outcome,
        projectId: project.projectId,
      };
    });
  }, [project, datasetMetadata]);
};