import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from '@hooks/useNavigate';
import { Breadcrumbs as MantineBreadcrumbs, Anchor } from '@mantine/core';
import { useLocation } from 'react-router-dom';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { useCurrentAnalysis } from '@hooks/useCurrentAnalysis';
import { useCurrentSummary } from '@hooks/useCurrentSummary';

const Breadcrumbs: FC = () => {
  const project = useCurrentProject();
  const analysis = useCurrentAnalysis();
  const summary = useCurrentSummary();
  const navigate = useNavigate();
  const location = useLocation();
  const items = useMemo(() => {
    const pages = location.pathname.split('/').filter((p) => !!p);
    if (pages.length === 1) {
      if (pages[0] === 'projects') {
        return [
          <Anchor key={'anchor_0'} onClick={() => navigate('/projects')}>
            All projects
          </Anchor>,
        ];
      } else if (pages[0] === 'settings') {
        return [
          <Anchor key={'anchor_0'} onClick={() => navigate('/projects')}>
            Home
          </Anchor>,
          <Anchor key={'anchor_1'}>Settings</Anchor>,
        ];
      }
    } else if (pages.length === 2) {
      if (pages[0] === 'projects' && pages[1] === 'new') {
        return [
          <Anchor key={'anchor_0'} onClick={() => navigate('/projects')}>
            All projects
          </Anchor>,
          <Anchor key={'anchor_1'}>Create project</Anchor>,
        ];
      } else if (pages[0] === 'projects' && project) {
        return [
          <Anchor key={'anchor_0'} onClick={() => navigate('/projects')}>
            All projects
          </Anchor>,
          <Anchor
            key={'anchor_1'}
            onClick={() => navigate(`/projects/${project.projectId}`)}
          >
            {project.title}
          </Anchor>,
        ];
      }
    } else if (pages.length === 3) {
      if (
        pages[0] === 'projects' &&
        project &&
        (pages[2] === 'new-benchmark' || pages[2] === 'new-key-drivers' || pages[2] === "new-progress-over-time")
      ) {
        return [
          <Anchor key={'anchor_0'} onClick={() => navigate('/projects')}>
            All projects
          </Anchor>,
          <Anchor
            key={'anchor_1'}
            onClick={() => navigate(`/projects/${project.projectId}`)}
          >
            {project.title}
          </Anchor>,
          <Anchor key={'anchor_2'}>New Analysis</Anchor>,
        ];
      } else if (
        pages[0] === 'projects' &&
        project &&
        pages[2] === 'new-summary'
      ) {
        return [
          <Anchor key={'anchor_0'} onClick={() => navigate('/projects')}>
            All projects
          </Anchor>,
          <Anchor
            key={'anchor_1'}
            onClick={() => navigate(`/projects/${project.projectId}`)}
          >
            {project.title}
          </Anchor>,
          <Anchor key={'anchor_2'}>New Plan of Action</Anchor>,
        ];
      }
    }
    else if (pages.length === 4) {
      if (
        pages[0] === 'projects' &&
        project &&
        pages[2] === 'analyses' &&
        analysis
      ) {
        return [
          <Anchor key={'anchor_0'} onClick={() => navigate('/projects')}>
            All projects
          </Anchor>,
          <Anchor
            key={'anchor_1'}
            onClick={() => navigate(`/projects/${project.projectId}`)}
          >
            {project.title}
          </Anchor>,
          <Anchor
            key={'anchor_2'}
            onClick={() =>
              navigate(
                `/projects/${project.projectId}/analyses/${analysis.id}`
              )
            }
          >
            {analysis.name}
          </Anchor>
        ];
      }
      else if (
        pages[0] === 'projects' &&
        project &&
        pages[2] === 'summaries' &&
        summary
      ) {
        return [
          <Anchor key={'anchor_0'} onClick={() => navigate('/projects')}>
            All projects
          </Anchor>,
          <Anchor
            key={'anchor_1'}
            onClick={() => navigate(`/projects/${project.projectId}`)}
          >
            {project.title}
          </Anchor>,
          <Anchor
            key={'anchor_2'}
            onClick={() =>
              navigate(
                `/projects/${project.projectId}/summaries/${summary.id}`
              )
            }
          >
            {summary.name}
          </Anchor>
        ];
      }
    }
    else if (pages.length === 5) {
      if (
        pages[0] === 'projects' &&
        project &&
        pages[2] === 'analyses' &&
        analysis &&
        pages[4] === 'edit'
      ) {
        return [
          <Anchor key={'anchor_0'} onClick={() => navigate('/projects')}>
            All projects
          </Anchor>,
          <Anchor
            key={'anchor_1'}
            onClick={() => navigate(`/projects/${project.projectId}`)}
          >
            {project.title}
          </Anchor>,
          <Anchor
            key={'anchor_2'}
            onClick={() =>
              navigate(
                `/projects/${project.projectId}/analyses/${analysis.id}`
              )
            }
          >
            {analysis.name}
          </Anchor>,
          <Anchor key={'anchor_3'}>Edit analysis</Anchor>,
        ];
      }
      else if (
        pages[0] === 'projects' &&
        project &&
        pages[2] === 'summaries' &&
        summary &&
        pages[4] === 'edit'
      ) {
        return [
          <Anchor key={'anchor_0'} onClick={() => navigate('/projects')}>
            All projects
          </Anchor>,
          <Anchor
            key={'anchor_1'}
            onClick={() => navigate(`/projects/${project.projectId}`)}
          >
            {project.title}
          </Anchor>,
          <Anchor
            key={'anchor_2'}
            onClick={() =>
              navigate(
                `/projects/${project.projectId}/summaries/${summary.id}`
              )
            }
          >
            {summary.name}
          </Anchor>,
          <Anchor key={'anchor_3'}>Edit summary</Anchor>,
        ];
      }
    }
    else if (pages.length === 6) {
      if (
        pages[0] === 'projects' &&
        project &&
        pages[2] === 'summaries' &&
        summary &&
        pages[4] === 'analyses'
      ) {
        return [
          <Anchor key={'anchor_0'} onClick={() => navigate('/projects')}>
            All projects
          </Anchor>,
          <Anchor
            key={'anchor_1'}
            onClick={() => navigate(`/projects/${project.projectId}`)}
          >
            {project.title}
          </Anchor>,
          <Anchor
            key={'anchor_2'}
            onClick={() =>
              navigate(
                `/projects/${project.projectId}/summaries/${summary.id}`
              )
            }
          >
            {summary.name}
          </Anchor>,
          <Anchor
            key={'anchor_3'}
            onClick={() =>
              navigate(
                `/projects/${project.projectId}/analyses/${analysis.id}`
              )
            }
          >
            {analysis.name}
          </Anchor>,
        ];
      }
    }
    return [];
  }, [project, analysis, summary, location]);

  return <MantineBreadcrumbs>{items}</MantineBreadcrumbs>;
};

export default Breadcrumbs;
