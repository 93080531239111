import { useQuery } from '@tanstack/react-query';
import ProjectModel from '@models/ProjectModel';
import ResourceAPI from '@apis/ResourceAPI';

export const useProjectTopics = (project?: ProjectModel) => {
  return useQuery({
    queryKey: [
      'project-topics',
      project?.organizationId,
      project?.projectId,
      project?.topicThemeMappingCRN,
    ],
    queryFn: async () => {
      return ResourceAPI.getResourceMetadataFromCrn(
        project?.topicThemeMappingCRN
      ).then((metadata) => {
        //let retrievedTopics: Set<string>;
        //let retrievedThemes: Set<string>;
        if (metadata?.content?.properties?.ImmutableAttributes?.Columns) {
          // This "Columns" is an array of objects, each of which the first key is the column name.
          // We need to find the object with the key "topic" and then get the "Values" array from that object.
          const topics: string[] =
            metadata?.content?.properties?.ImmutableAttributes?.Columns.find(
              (obj: { [key: string]: any }) => obj.CX_TOPIC
            )?.CX_TOPIC.Values;
          const themes: string[] =
            metadata?.content?.properties?.ImmutableAttributes?.Columns.find(
              (obj: { [key: string]: any }) => obj.CX_THEME
            )?.CX_THEME.Values;
          var topicDefinitions = {};
          var topicThemeMapping: Map<string, string[]> = new Map<
            string,
            string[]
          >();

          const fieldValues =
            metadata?.content?.properties?.MutableAttributes?.FieldValues;

          if (fieldValues?.CX_THEME?.Associated_Topics) {
            const associatedTopicValues = fieldValues.CX_THEME
              .Associated_Topics as object;

            for (const [theme, associatedTopics] of Object.entries(
              associatedTopicValues
            )) {
              topicThemeMapping.set(theme, associatedTopics.split(','));
            }
          }

          if (topics) {
            // Let's go ahead and get the definitions
            topicDefinitions =
              metadata?.content?.properties?.MutableAttributes?.FieldValues
                ?.CX_TOPIC?.Description || {};
          }

          return {
            topics: topics || [],
            themes: themes || [],
            topicDefinitions: topicDefinitions,
            topicThemeMapping: topicThemeMapping || new Map<string, string[]>(),
          };
        }
      });
    },
    enabled: !!project,
    staleTime: 1000 * 60 * 60, // 60 minutes...
  });
};
