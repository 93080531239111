import {
  IProjectAnalysisModel,
  IProjectAnalysisRequestModel,
  AnalysisType,
  AnalysisStatus,
  isAnalysisType,
} from './ProjectAnalysisModelBase';

interface IOutcomeMeasureItem {
  dataset: string;
  column: string;
}

export class OutcomeConfiguration {
  standardizedOutcome: string | null;
  measure: IOutcomeMeasureItem[];

  constructor(
    standardizedOutcome: string | null,
    measure: IOutcomeMeasureItem[]
  ) {
    this.standardizedOutcome = standardizedOutcome;
    this.measure = measure;
  }

  static fromAPIResponse(response: object): OutcomeConfiguration {
    const standardizedOutcome = response['StandardizedOutcome'];
    const measure: IOutcomeMeasureItem[] = response['Measure'].map(
      (item: object): IOutcomeMeasureItem => {
        return {
          dataset: item['Dataset'],
          column: item['Column'],
        };
      }
    );

    return new OutcomeConfiguration(standardizedOutcome, measure);
  }

  toJSON(): object {
    // Format the measure...
    const formattedMeasure: object[] = this.measure.map(
      (item: IOutcomeMeasureItem) => {
        return {
          Dataset: item.dataset,
          Column: item.column,
        };
      }
    );

    return {
      StandardizedOutcome: this.standardizedOutcome,
      Measure: formattedMeasure,
    };
  }
}

export class SynthesisAnalysisModel implements IProjectAnalysisModel {
  id: string;
  name: string;
  type: AnalysisType;
  version: string;
  status: AnalysisStatus;
  created_at: Date;
  updated_at: Date;

  // MARK: - Drivers Specific Properties / Options
  focalPopulation: string;
  outcome?: OutcomeConfiguration;
  internalBenchmark?: string;
  externalBenchmark?: string;
  comparisonPopulation?: string;
  timePeriodPopulations?: string[];

  constructor(
    id: string,
    name: string,
    type: AnalysisType,
    version: string,
    status: AnalysisStatus,
    created_at: Date,
    updated_at: Date,

    focalPopulation: string,
    outcome: OutcomeConfiguration | undefined,
    internalBenchmark: string | undefined,
    externalBenchmark: string | undefined,
    comparisonPopulation: string | undefined,
    timePeriodPopulations: string[] | undefined
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.version = version;
    this.status = status;
    this.created_at = created_at;
    this.updated_at = updated_at;

    this.focalPopulation = focalPopulation;
    this.outcome = outcome;
    this.internalBenchmark = internalBenchmark;
    this.externalBenchmark = externalBenchmark;
    this.comparisonPopulation = comparisonPopulation;
    this.timePeriodPopulations = timePeriodPopulations;
  }

  static fromAPIResponse(response: object): SynthesisAnalysisModel {
    // Our response will be an object that will conform to our class structure
    const analysisId = response['Id'];
    const name = response['Name'];
    const type: AnalysisType = AnalysisType.SYNTHESIS;
    const version: string = response['Version'];
    const status: AnalysisStatus = AnalysisStatus[response['Status']]; // TODO: - Fix this issue. Status isn't returned from the server. We need to compute...
    const created_at = new Date(response['CreatedAt']);
    const updated_at = new Date(response['UpdatedAt']);

    const focalPopulation = response['FocalPopulation'];

    // MARK: - Handle the Outcome Configuration
    const measure = response['Outcome']
      ? OutcomeConfiguration.fromAPIResponse(response['Outcome'])
      : undefined;

    const internalBenchmark = response['InternalBenchmark']
      ? response['InternalBenchmark']
      : undefined;

    const externalBenchmark = response['ExternalBenchmark']
      ? response['ExternalBenchmark']
      : undefined;
    const comparisonPopulation = response['ComparisonPopulation']
      ? response['ComparisonPopulation']
      : undefined;

    const timePeriodPopulations = response['TimePeriodPopulations']
      ? response['TimePeriodPopulations']
      : undefined;

    return new SynthesisAnalysisModel(
      analysisId,
      name,
      type,
      version,
      status,
      created_at,
      updated_at,
      focalPopulation,
      measure,
      internalBenchmark,
      externalBenchmark,
      comparisonPopulation,
      timePeriodPopulations
    );
  }

  toJSON = (): object => {
    return {
      Id: this.id,
      Name: this.name,
      Type: this.type,
      Version: this.version,
      Status: this.status,
      DateAdded: this.created_at,
      DateUpdated: this.updated_at,
      FocalPopulation: this.focalPopulation,
      Outcome: this.outcome ? this.outcome.toJSON() : null,
      InternalBenchmark: this.internalBenchmark ? this.internalBenchmark : null,
      ExternalBenchmark: this.externalBenchmark ? this.externalBenchmark : null,
      ComparisonPopulation: this.comparisonPopulation
        ? this.comparisonPopulation
        : null,
      TimePeriodPopulations: this.timePeriodPopulations
        ? this.timePeriodPopulations
        : null,
    };
  };
}

// MARK: - Interface to make request to API with...
export class SynthesisAnalysisRequestModel
  implements IProjectAnalysisRequestModel
{
  name: string;
  type: AnalysisType;
  version: string;

  focalPopulation: string;
  outcome?: OutcomeConfiguration;
  internalBenchmark?: string;
  externalBenchmark?: string;
  comparisonPopulation?: string;
  timePeriodPopulations?: string[];

  constructor(
    name: string,
    version: string,
    focalPopulation: string,
    outcome: OutcomeConfiguration | undefined,
    internalBenchmark: string | undefined,
    externalBenchmark: string | undefined,
    comparisonPopulation: string | undefined,
    timePeriodPopulations: string[] | undefined
  ) {
    this.name = name;
    this.type = AnalysisType.SYNTHESIS;
    this.version = version;
    this.focalPopulation = focalPopulation;
    this.outcome = outcome;
    this.internalBenchmark = internalBenchmark;
    this.externalBenchmark = externalBenchmark;
    this.comparisonPopulation = comparisonPopulation;
    this.timePeriodPopulations = timePeriodPopulations;
  }

  toJSON(): object {
    return {
      Name: this.name,
      Type: this.type,
      Version: this.version,
      FocalPopulation: this.focalPopulation,
      Outcome: this.outcome ? this.outcome.toJSON() : null,
      InternalBenchmark: this.internalBenchmark ? this.internalBenchmark : null,
      ExternalBenchmark: this.externalBenchmark ? this.externalBenchmark : null,
      ComparisonPopulation: this.comparisonPopulation
        ? this.comparisonPopulation
        : null,
      TimePeriodPopulations: this.timePeriodPopulations
        ? this.timePeriodPopulations
        : null,
    };
  }
}
