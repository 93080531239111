import { SelectItem } from '@mantine/core';
import {
  OutcomeSelectItem,
  OutcomeSelectSection,
} from '@components/OutcomeSelect/OutcomeSelect';
import { userFriendlyTopicName } from '@utils/TopicUtils';
import { useMemo } from 'react';
import { AnalysisType, Population } from '@stores/AnalysisEditionStore';

interface OutcomeData {
  outcomeOptions: OutcomeSelectItem[];
  sections: OutcomeSelectSection[];
  topics: SelectItem[];
}

export const useOutcomeData = (
  analysisType: AnalysisType,
  populations: Population[],
  datasets?: any
): OutcomeData | undefined => {
  return useMemo(() => {
    if (
      analysisType !== AnalysisType.DRIVERS_OF_OUTCOME ||
      populations.length !== 1 ||
      !datasets
    ) {
      return undefined;
    }

    const focalPopulation = populations[0];
    const datasetAliasesInUse = getUniqueDatasets(focalPopulation.definition);
    return formatOutcomeOptions(datasets, datasetAliasesInUse);
  }, [analysisType, populations, datasets]);
};

const getUniqueDatasets = (
  definition: object,
  set: Set<string> = new Set<string>()
): Set<string> => {
  for (const key in definition) {
    const value = definition[key];

    // If the current value is an object, we need to recursively process it
    if (typeof value === 'object' && value !== null) {
      getUniqueDatasets(value, set);
    }

    // If the current key is "Dataset", add it to the set
    if (key === 'Dataset') {
      const usableName = (value as string).slice(3);
      set.add(usableName);
    }
  }

  return set;
};

export const formatOutcomeOptions = (
  datasets: Map<string, any>,
  allowedDatasetAliases: Set<string>
) => {
  console.log({
    datasets,
    allowedDatasetAliases
  },"Hello")
  let formattedSelectItems: OutcomeSelectItem[] = [];

  var allTopics = new Set<string>();

  datasets.forEach((metadata: any, datasetName: string) => {
    // Make sure to remove the DS_ from the dataset name before comparing...
    if (allowedDatasetAliases.has(datasetName.slice(3))) {
      // Go through each column add add it to the list of allowed columns only if it meets the following:
      // - It's an integer type with less than 15 values...
      // - It's a float type...
      // - It's a binary type...
      const potentialOutcomeNames: string[] =
        metadata.Content?.Properties.ImmutableAttributes.Columns.map(
          (item: { [key: string]: any }) => {
            const columnName = Object.keys(item)[0];
            const columnInfo = item[columnName];

            const dataType = columnInfo.DataType;
            const numberOfValues = columnInfo.Values?.length;
            const allowedDataTypes = [
              'IntegerType',
              'LongType',
              'DecimalType',
              'BinaryType',
            ];
            if (allowedDataTypes.includes(dataType) && numberOfValues <= 15) {
              return columnName;
            } else if (dataType === 'FloatType' || dataType === 'DoubleType') {
              return columnName;
            }
          }
        ).filter((name: string) => name !== null && name !== undefined);

      const fieldValueKeys = Object.keys(
        metadata.Content.Properties.MutableAttributes.FieldValues
      );

      Object.entries(
        metadata.Content?.Properties?.MutableAttributes?.Columns
      ).map(([key, item]: [string, { [key: string]: any }]) => {
        const outcome = item.CX_Outcome || 'Custom';
        const title = item.Alias || key;
        const subTitle = item['Question Text'] || null;
        // We'll want to make sure we include values for:
        // - IntegerType
        // - FloatType
        // - Anything with Ordinal Rank attached to metadata...
        // - Also anything attached with CX_Outcome
        // This way we show only relevant results and not all columns as results...

        if (potentialOutcomeNames.includes(key)) {
          const outcomeSelectItem: OutcomeSelectItem = {
            value: `${datasetName}.${key}`,
            label: title,
            subTitle: subTitle,
            outcome: outcome,
            section: datasetName,
          };

          formattedSelectItems.push(outcomeSelectItem);
        } else {
          // Figure out if the column has an Ordinal Rank assignment...
          const hasOrdinalRank =
            fieldValueKeys.includes(key) &&
            metadata.Content.Properties.MutableAttributes.FieldValues[key][
              'Ordinal Rank'
            ] !== undefined;
          if (hasOrdinalRank) {
            const outcomeSelectItem: OutcomeSelectItem = {
              value: `${datasetName}.${key}`,
              label: title,
              subTitle: subTitle,
              outcome: outcome,
              section: datasetName,
            };

            formattedSelectItems.push(outcomeSelectItem);
          }
        }
      });

      // Get the list of available feature topics...
      const featureTopics =
        metadata.Topics?.Content?.Properties.ImmutableAttributes.Columns.map(
          (item: { [key: string]: any }) => {
            const columnName = Object.keys(item)[0];
            const columnInfo = item[columnName];

            if (columnName === 'CX_TOPIC') {
              return columnInfo.Values;
            }
          }
        ).filter((topic: string) => topic !== null && topic !== undefined);

      // Flatten the array of arrays...
      const flattenedFeatureTopics = featureTopics.flat();
      allTopics = new Set([...allTopics, ...flattenedFeatureTopics]);
    }
  });

  return {
    outcomeOptions: formattedSelectItems,
    sections: Array.from(allowedDatasetAliases).map((datasetName: string) => ({
      title: `Select an outcome for ${datasetName}`,
      value: `DS_${datasetName}`,
      required: true,
    })),
    topics: Array.from(allTopics)
      .sort()
      .map((topic: string) => {
        return {
          value: topic,
          label: userFriendlyTopicName(topic),
        } as SelectItem;
      }),
  };
};
