import { useMutation } from '@tanstack/react-query';
import ProjectAPI from '@apis/ProjectAPI';
import ProjectModel, {
  ProjectBenchmarkUpdateRequestModel,
} from '@models/ProjectModel';

interface Events {
  onSuccess?: (
    data: ProjectModel,
    variables: ProjectBenchmarkUpdateRequestModel,
    context: unknown
  ) => unknown;
  onError?: (
    error: Error,
    variables: ProjectBenchmarkUpdateRequestModel,
    context: unknown
  ) => unknown;
  onSettled?: (
    data: ProjectModel,
    error: Error,
    variables: ProjectBenchmarkUpdateRequestModel,
    context: unknown
  ) => unknown;
}
export const useUpdateProject = (events?: Events) => {
  return useMutation({
    mutationFn: async (project: ProjectBenchmarkUpdateRequestModel) => {
      return ProjectAPI.updateProject(project);
    },
    onSuccess: events?.onSuccess,
    onError: events?.onError,
    onSettled: events?.onSettled,
  });
};
