import { FC } from 'react';
import {
  ActionIcon,
  Group,
  MantineNumberSize,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { IconCheck, IconProgress, IconX } from '@tabler/icons-react';
import { ProjectStatus } from '@models/ProjectModel';

interface ProjectStatusIconProps {
  projectStatus: ProjectStatus;
  showLabel?: boolean;
  iconSize?: MantineNumberSize;
  textSize?: MantineNumberSize;
}

const getIconFromProjectStatus = (
  projectStatus: ProjectStatus,
  size?: MantineNumberSize
): JSX.Element => {
  switch (projectStatus) {
    case ProjectStatus.ERROR:
      return (
        <ActionIcon
          color="red"
          size={size}
          radius="xl"
          variant="filled"
          style={{ cursor: 'default' }}
        >
          <IconX size={10} />
        </ActionIcon>
      );
    case ProjectStatus.PROCESSING:
      return (
        <ActionIcon
          color="blue"
          size={size}
          radius="xl"
          variant="filled"
          style={{ cursor: 'default' }}
        >
          <IconProgress size={10} />
        </ActionIcon>
      );
    case ProjectStatus.READY_WITH_RESTRICTIONS:
      return (
        <ActionIcon
          color="yellow"
          size={size}
          radius="xl"
          variant="filled"
          style={{ cursor: 'default' }}
        >
          <IconCheck size={10} />
        </ActionIcon>
      );
    case ProjectStatus.READY:
      return (
        <ActionIcon
          color="green"
          size={size}
          radius="xl"
          variant="filled"
          style={{ cursor: 'default' }}
        >
          <IconCheck size={10} />
        </ActionIcon>
      );
  }
};

const getStatusMessage = (projectStatus: ProjectStatus): string => {
  switch (projectStatus) {
    case ProjectStatus.ERROR:
      return 'Issues';
    case ProjectStatus.PROCESSING:
      return 'Processing';
    case ProjectStatus.READY_WITH_RESTRICTIONS:
      return 'Core data ready';
    case ProjectStatus.READY:
      return 'Ready';
  }
};

const ProjectStatusIcon: FC<ProjectStatusIconProps> = ({
  projectStatus,
  showLabel,
  iconSize = 14,
  textSize = 'md',
}) => {
  return (
    <Group spacing={'xs'} noWrap>
      <Tooltip label={getStatusMessage(projectStatus)}>
        {getIconFromProjectStatus(projectStatus, iconSize)}
      </Tooltip>
      {showLabel ? (
        <Text size={textSize}>{getStatusMessage(projectStatus)}</Text>
      ) : null}
    </Group>
  );
};

export default ProjectStatusIcon;
