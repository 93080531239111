

import { useAppStore } from '@stores/AppStore';
import { Center, Group, Text, SegmentedControl } from '@mantine/core';

export const TopicPanelSwitch: React.FC = () => {
    const showTopicPanel = useAppStore((s) => s.showTopicPanel);
    const setShowTopicPanel = useAppStore((s) => s.setShowTopicPanel);

    return (
        <Group spacing={0}>
        <Center onClick={() => setShowTopicPanel(!showTopicPanel)} bg="#f1f3f5" p="xs" style={{cursor: "pointer", height: 32.59, borderStartStartRadius: "0.25rem", borderEndStartRadius: "0.25rem"}}><Text c="#495057" weight={700} size="0.75rem">Topic panel:</Text></Center>
        <SegmentedControl
            style={{borderStartStartRadius: 0, borderEndStartRadius: 0}}
            value={showTopicPanel ? "show" : "hide"}
            onChange={v => setShowTopicPanel(!showTopicPanel)}
            size='xs'
            data={[
                { label: 'Show', value: 'show' },
                { label: 'Hide', value: 'hide' },
            ]}
        />
        </Group>
    );
};