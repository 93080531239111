import { ActionIcon, Group, SelectItem } from '@mantine/core';
import {
  AnalysisStatus,
  AnalysisType,
} from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import {
  IconArrowsDiff,
  IconChartArrows,
  IconCheck,
  IconCompass,
  IconMathFunction,
  IconProgress,
  IconTrendingUp,
  IconWorld,
  IconX,
} from '@tabler/icons-react';
import { forwardRef } from 'react';

export const MiddleEllipsis = ({ text, maxDisplay }) => {
  if (!text) {
    return <span></span>;
  }
  if (text.length > maxDisplay) {
    const start = text.substring(0, maxDisplay / 2);
    const end = text.substring(text.length - maxDisplay / 2);
    return (
      <span title={text}>
        {start}...{end}
      </span>
    );
  }
  return text;
};

export interface SelectAnalysisItemProps extends SelectItem {
  value: string;
  label: string;
  analysisType: AnalysisType;
  status: AnalysisStatus;
}

export const SelectAnalysisItem = forwardRef<
  HTMLDivElement,
  SelectAnalysisItemProps
>(({ value, label, analysisType, ...others }: SelectAnalysisItemProps, ref) => {
  const analysisIcon = () => {
    switch (analysisType) {
      case AnalysisType.DRIVERS_OF_OUTCOME:
        return <IconMathFunction size={18} />;
      case AnalysisType.INTERNAL_BENCHMARK:
        return <IconChartArrows size={18} />;
      case AnalysisType.COMPARE_TWO_POPULATIONS:
        return <IconArrowsDiff size={18} />;
      case AnalysisType.PROGRESS_OVER_TIME:
        return <IconTrendingUp size={18} />;
      case AnalysisType.EXTERNAL_BENCHMARK:
        return <IconWorld size={18} />;
      case AnalysisType.SYNTHESIS:
        return <IconCompass size={18} />;
    }
  };

  var statusIcon: React.ReactNode = undefined;

  switch (others.status) {
    case AnalysisStatus.READY:
      statusIcon = (
        <ActionIcon color="green" size={14} radius="xl" variant="filled">
          <IconCheck size={10} />
        </ActionIcon>
      );
      break;
    case AnalysisStatus.READY_WITH_RESTRICTIONS:
      statusIcon = (
        <ActionIcon color="yellow" size={14} radius="xl" variant="filled">
          <IconCheck size={10} />
        </ActionIcon>
      );
      break;
    case AnalysisStatus.PROCESSING:
      statusIcon = (
        <ActionIcon color="blue" size={14} radius="xl" variant="filled">
          <IconProgress size={10} />
        </ActionIcon>
      );
      break;
    case AnalysisStatus.ERROR:
      statusIcon = (
        <ActionIcon color="red" size={14} radius="xl" variant="filled">
          <IconX size={10} />
        </ActionIcon>
      );
      break;
  }

  return (
    <div ref={ref} {...others}>
      <Group noWrap position="apart">
        {label && <MiddleEllipsis text={label} maxDisplay={40} />}
        <Group noWrap spacing={'xs'}>
          {statusIcon}
          {analysisIcon()}
        </Group>
      </Group>
    </div>
  );
});
