import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  TextInput,
  Textarea,
  Title,
  Text,
  Center,
} from '@mantine/core';
import { use } from 'echarts';
import { useAuthenticatedUser } from '@hooks/useAuthenticatedUser';
import * as Sentry from '@sentry/browser';
import { Auth } from 'aws-amplify';
import { useTimeout } from '@mantine/hooks';

interface UserFeedbackFormProps {
  onSubmitted?: () => void;
  onCancel?: () => void;
}

export const UserFeedbackForm: React.FC<UserFeedbackFormProps> = ({
  onSubmitted,
  onCancel,
}: UserFeedbackFormProps) => {
  const shouldShowEmailAndName = !useAuthenticatedUser(false);

  const [name, setName] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [feedback, setFeedback] = useState<string | undefined>(undefined);

  const [reported, setReported] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const username = user.attributes['custom:username'];
        const organizationId = user.attributes['custom:organization_id'];
        const finalUserName = `${organizationId}/${username}`;
        const email = user.attributes['email'];

        setName(finalUserName);
        setEmail(email);
      })
      .catch((err) => {
        // Let's try to fetch the user's email and name from local storage (maybe they have submitted a bug previously)
        const email = localStorage.getItem('feedback-user-email');
        const name = localStorage.getItem('feedback-user-name');

        if (email && name) {
          setName(name);
          setEmail(email);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const canSubmit =
    name !== undefined &&
    name.trim() !== '' &&
    email !== undefined &&
    email.trim() !== '' &&
    feedback !== undefined &&
    feedback.trim() !== '';

  const onSubmit = () => {
    const eventId = Sentry.captureMessage(feedback);
    // const eventId = Sentry.lastEventId();

    const userFeedback = {
      event_id: eventId,
      name: name,
      email: email,
      message: feedback,
    };

    Sentry.sendFeedback(userFeedback, {
      includeReplay: true, // optional
    })
      .then((feedbackId) => {
        localStorage.setItem('feedback-user-email', email);
        localStorage.setItem('feedback-user-name', name);

        setReported(true);
        setTimeout(() => {
          onSubmitted && onSubmitted();
        }, 1500);
      })
      .catch((err) => {
        console.error('Error sending feedback: ', err);
      });
  };

  return (
    <>
      {reported && (
        <Center>
          <Stack spacing={0}>
            <Title order={4}>Thanks for your feedback!</Title>
            <Text color="dimmed" size="xs">
              Your feedback has been submitted.
            </Text>
          </Stack>
        </Center>
      )}

      {!reported && (
        <Stack spacing={'xs'}>
          <Stack spacing={0}>
            <Title order={4}>Provide feedback to our team</Title>
            <Text color="dimmed" size="xs">
              Your feedback will be sent directly to our CTO for review.
            </Text>
          </Stack>
          {shouldShowEmailAndName && (
            <TextInput
              required
              size="xs"
              defaultValue={name}
              label="Name"
              placeholder="Your name"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          )}
          {shouldShowEmailAndName && (
            <TextInput
              required
              size="xs"
              defaultValue={email}
              label="Email"
              placeholder="Your email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          )}
          <Textarea
            required
            autoFocus
            size="xs"
            label="Feedback"
            placeholder="Tell us what's going on. Is there a bug? Or general feedback?"
            minRows={5}
            onChange={(e) => {
              setFeedback(e.target.value);
            }}
          />
          <Button
            size="xs"
            disabled={!canSubmit}
            onClick={onSubmit}
            loading={loading}
          >
            Submit Feedback
          </Button>
          <Button
            variant="subtle"
            color="dark"
            size="xs"
            onClick={() => {
              onCancel && onCancel();
            }}
          >
            Cancel
          </Button>
        </Stack>
      )}
    </>
  );
};
