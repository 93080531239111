import React from 'react';
import { forwardRef } from 'react';
import APIRequesterBase from '../apis/APIBase';
import { Select, Group, Text } from '@mantine/core';
import queryString from 'query-string';
import { Auth } from 'aws-amplify';
import OrganizationAPI from '@apis/OrganizationAPI';

interface OrganizationSelectItemProps
  extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  organizationId: number;
}

const OrganizationSelectItem = forwardRef<
  HTMLDivElement,
  OrganizationSelectItemProps
>(({ label, organizationId, ...others }: OrganizationSelectItemProps, ref) => (
  <div ref={ref} {...others}>
    <Group noWrap>
      <div>
        <Text size="xs">{label}</Text>
        <Text size={10} opacity={0.65}>
          {organizationId}
        </Text>
      </div>
    </Group>
  </div>
));

interface OrganizationSelectionComponentState {
  userOrganizationId: number;
  organizationId?: number;
  organizationOptions: any[];
  error?: string;
}

class OrganizationSelectionComponent extends React.Component<
  {},
  OrganizationSelectionComponentState
> {
  state: OrganizationSelectionComponentState = {
    userOrganizationId: -1,
    organizationOptions: [],
  };

  constructor(props: any) {
    super(props);
    this.changeCurrentActiveOrganizationId =
      this.changeCurrentActiveOrganizationId.bind(this);
    this.getCurrentUserOrganizationId =
      this.getCurrentUserOrganizationId.bind(this);
    this.getOrganizationOptions = this.getOrganizationOptions.bind(this);
  }

  componentDidMount(): void {
    this.getCurrentOrganizationId().then((result) => {
      this.setState({ organizationId: result });
    });

    this.getCurrentUserOrganizationId((organizationId) => {
      if (organizationId === 90210 || organizationId === 100001) {
        this.getOrganizationOptions();
      }
    });
  }

  updateUrl = (organizationId: number) => {
    // Get the current path and search string
    const pathname = window.location.pathname;
    const search = window.location.search;

    // Parse the search string into an object
    const searchParams = queryString.parse(search);

    // Update the organizationId parameter in the searchParams object
    searchParams.organizationId = organizationId.toString();

    // Use the replaceState method to update the URL with the new search string and current path and title
    window.history.replaceState(
      null,
      document.title,
      `${pathname}?${queryString.stringify(searchParams)}`
    );

    // reload the page.
    window.location.reload();
  };

  async getCurrentUserOrganizationId(
    callback: (organizationId: number) => void
  ): Promise<void> {
    // Get the current organizationId from the user or
    try {
      const user = await Auth.currentAuthenticatedUser();
      const organizationId = parseInt(
        user.attributes['custom:organization_id']
      );

      this.setState(
        {
          userOrganizationId: organizationId,
        },
        () => {
          callback(organizationId);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  async getCurrentOrganizationId(): Promise<number> {
    const organizationId = await APIRequesterBase.getCurrentOrganizationId();
    return organizationId;
  }

  getOrganizationOptions(): void {
    OrganizationAPI.listOrganizations(this.state.userOrganizationId)
      .then((organizations) => {
        // sort organizations by name
        organizations.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        var organizationOptions: any[] = [];
        organizations.forEach((organization) => {
          organizationOptions.push({
            label: organization.name,
            value: organization.organizationId.toString(),
            organizationId: organization.organizationId,
          });
        });

        this.setState({
          organizationOptions: organizationOptions,
          error: undefined,
        });
      })
      .catch((error) => {
        // Check to see if the error is a 403
        if (error.response.status === 403) {
          // If it is a 403, then the user doesn't have permission to view the organization
          // so we need to redirect them to the home page.
          this.setState({
            error: 'Insufficient permissions',
          });
        }
      });
  }

  changeCurrentActiveOrganizationId = (value: string): void => {
    const organizationId: number = parseInt(value);

    APIRequesterBase.configure({
      organizationId: organizationId,
    });

    this.setState(
      {
        organizationId: organizationId,
      },
      () => {
        this.updateUrl(organizationId);
      }
    );
  };

  render() {
    if (
      this.state.userOrganizationId !== 90210 &&
      this.state.userOrganizationId !== 100001
    ) {
      return null;
    }

    return (
      <>
        <Select
          size="xs"
          placeholder="Select organization"
          data={this.state.organizationOptions}
          value={this.state.organizationId?.toString()}
          itemComponent={OrganizationSelectItem}
          error={this.state.error}
          searchable
          onChange={this.changeCurrentActiveOrganizationId}
        />
      </>
    );
  }
}

export default OrganizationSelectionComponent;
