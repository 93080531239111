// TODO: - Add the correct types for different charts...
export enum ShapDriversDataPointChartType {
  DriversBarChart = 'DriversBarChart',
}

export class ShapDriversDataPoint {
  /**
   * `feature` is the name of the feature that was used to predict the outcome...
   */
  public feature: string;

  /**
   * `featureUserFriendlyName` is the user friendly name of the feature the datapoint belongs to.
   * This is used to display the feature name in the chart. Will remove special characters and replace with spaces.
   * Will also maintain the capitalization of the first letter of each word.
   * Example: "Collaboration_General" will be displayed as "Collaboration General"
   * Example: "manage_complexity" will be displayed as "Manage Complexity"
   * NOTE: - Not to be used with the API or anywhere else other than the chart.
   */
  public featureUserFriendlyName: string;

  /**
   * `outcome` is outcome alias that was predicted (additional code will have to match this to the actual outcome)
   */
  public outcome: string;

  /**
   * `population` is the focal population name which represents the rows that were used in the prediction
   */
  public population: string;

  /**
   * `populationCount` is the number of records that were present in the focal population and used at runtime.
   */
  public populationCount: number;

  /**
   * `adjustedR2` is the adjusted R2 value for the model.
   */
  public adjustedR2: number;

  /**
   * `r2` is the R2 value for the model.
   */
  public r2: number;

  /**
   * `incidenceShap` is the SHAP value for the feature's incidence component
   */
  public incidenceShap: number;

  /**
   * `sentimentShap` is the SHAP value for the feature's sentiment component
   */
  public sentimentShap: number;

  /**
   * `combinedShap` is the SHAP value for the feature as a whole (incidence + sentiment)
   */
  public combinedShap: number;

  /**
   * `incidenceInModel` represents if the incidence component of the feature was used in the model.
   * The removal of this feature may occur due to SHAP importance feature selection...
   */
  public incidenceInModel: boolean;

  /**
   * `sentimentInModel` represents if the sentiment component of the feature was used in the model.
   * The removal of this feature may occur due to SHAP importance feature selection...
   */
  public sentimentInModel: boolean;

  /**
   * `featureInModel` represents if the feature was used in the model. If this value is 0, it's because the auto-feature selection deemed this feature to be unimportant and / or hurt performance of the final model...
   */
  public featureInModel: boolean;

  /**
   * `combinedShapStdDev` is the variance of the SHAP value across several model experiments.. This gives us an idea of the "error bar" for the SHAP value.
   * It also can indicate to the user how "trustworthy" the SHAP value is.
   */
  public combinedShapStdDev: number | undefined;

  /**
   * `combinedPercentTotalShap` is the percentage of the total SHAP value that this feature represents.
   * This is calculated by dividing the combined SHAP value by the sum of all the combined SHAP values for the features in the model.
   * This is used to display the percentage of the total SHAP value that this feature represents.
   */
  public combinedPercentTotalShap: number | undefined;

  /**
   * `combinedPercentTotalShapStdDev` is the variance of the combinedPercentTotalShap value across several model experiments.. This gives us an idea of the "error bar" for the combinedPercentTotalShap value.
   * It also can indicate to the user how "trustworthy" the combinedPercentTotalShap value is.
   */
  public combinedPercentTotalShapStdDev: number | undefined;

  constructor(
    feature: string,
    outcome: string,
    population: string,
    populationCount: number,
    adjustedR2: number,
    r2: number,
    incidenceShap: number,
    sentimentShap: number,
    combinedShap: number,
    incidenceInModel: boolean,
    sentimentInModel: boolean,
    featureInModel: boolean = true,
    combinedShapStdDev?: number,
    combinedPercentTotalShap?: number,
    combinedPercentTotalShapStdDev?: number
  ) {
    this.feature = feature;
    this.featureUserFriendlyName =
      ShapDriversDataPoint.userFriendlyFeatureName(feature);
    this.outcome = outcome;
    this.population = population;
    this.populationCount = populationCount;
    this.adjustedR2 = adjustedR2;
    this.r2 = r2;
    this.incidenceShap = incidenceShap;
    this.sentimentShap = sentimentShap;
    this.combinedShap = combinedShap;
    this.incidenceInModel = incidenceInModel;
    this.sentimentInModel = sentimentInModel;
    this.featureInModel = featureInModel;
    this.combinedShapStdDev = combinedShapStdDev;
    this.combinedPercentTotalShap = combinedPercentTotalShap;
    this.combinedPercentTotalShapStdDev = combinedPercentTotalShapStdDev;
  }

  // MARK: - Static helper functions...

  /**
   * Creates a user friendly name for the feature.
   * Replaces special characters like "_" with spaces and capitalizes the first letter of each word.
   * @param featureName
   * @returns userFriendlyName
   */
  public static userFriendlyFeatureName(featureName: string): string {
    let userFriendlyName = featureName.replace(/_/g, ' ');
    userFriendlyName = userFriendlyName.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    return userFriendlyName;
  }

  // MARK: - Static initializers for common use cases...
  /**
   * Creates a ShapDriversDataPoint object from a JSON object.
   * @param columns array of strings that represent the column names of the data.
   * @param data array of strings, numbers or nulls that represent the data.
   */
  public static fromResponse(
    columns: string[],
    data: any[][]
  ): ShapDriversDataPoint[] {
    let shapDriversDataPoints: ShapDriversDataPoint[] = [];
    data.forEach((dataPointList: any[]) => {
      let feature: string = '';
      let outcome: string = '';
      let population: string = '';
      let populationCount: number = 0;
      let adjustedR2: number = 0;
      let r2: number = 0;
      let incidenceShap: number = 0;
      let sentimentShap: number = 0;
      let combinedShap: number = 0;
      let incidenceInModel: boolean = false;
      let sentimentInModel: boolean = false;

      let featureInModel: boolean = true;
      let combinedShapStdDev: number | undefined = undefined;
      let combinedPercentTotalShap: number;
      let combinedPercentTotalShapStdDev: number | undefined = undefined;

      dataPointList.forEach((dataPoint: any, index: number) => {
        const column = columns[index];
        switch (column) {
          case 'Feature':
            feature = dataPoint;
            break;
          case 'Outcome':
            outcome = dataPoint;
            break;
          case 'Population':
            population = dataPoint;
            break;
          case 'Population_Count':
            populationCount = dataPoint;
            break;
          case 'Population_count':
            populationCount = dataPoint;
            break;
          case 'Adj_R2':
            adjustedR2 = dataPoint;
            break;
          case 'Adj R2':
            adjustedR2 = dataPoint;
            break;
          case 'R2':
            r2 = dataPoint;
            break;
          case 'incidenceSHAP':
            incidenceShap = dataPoint;
            break;
          case 'sentimentSHAP':
            sentimentShap = dataPoint;
            break;
          case 'combinedSHAP':
            combinedShap = dataPoint;
            break;
          case 'I_in_model':
            incidenceInModel = true ? dataPoint === 1 : false;
            break;
          case 'S_in_model':
            sentimentInModel = true ? dataPoint === 1 : false;
            break;
          case 'feature_in_model':
            featureInModel = true ? dataPoint === 1 : false;
            break;
          case 'combinedSHAP_stdev':
            combinedShapStdDev = dataPoint;
            break;
          case 'combined%TotalSHAP':
            combinedPercentTotalShap = dataPoint;
            break;
          case 'combined%TotalSHAP_stdev':
            combinedPercentTotalShapStdDev = dataPoint;
            break;
        }
      });

      const shapDriversDataPoint = new ShapDriversDataPoint(
        feature,
        outcome,
        population,
        populationCount,
        adjustedR2,
        r2,
        incidenceShap,
        sentimentShap,
        combinedShap,
        incidenceInModel,
        sentimentInModel,
        featureInModel,
        combinedShapStdDev,
        combinedPercentTotalShap,
        combinedPercentTotalShapStdDev
      );
      shapDriversDataPoints.push(shapDriversDataPoint);
    });

    return shapDriversDataPoints;
  }

  /**
   * Creates a ShapDriversDataPoint object from a chart data point.
   * @param chartDataPoint
   * @param type
   * @returns ShapDriversDataPoint
   */
  public static fromChartDataPoint(
    chartDataPoint: any,
    type: ShapDriversDataPointChartType
  ): ShapDriversDataPoint {
    switch (type) {
      case ShapDriversDataPointChartType.DriversBarChart:
        // chartDataPoint is an array where 0 is incidence and 1 is sentiment, and the rest of the array is the other data point information...
        return new ShapDriversDataPoint(
          chartDataPoint[2],
          chartDataPoint[3],
          chartDataPoint[4],
          chartDataPoint[5],
          chartDataPoint[0],
          chartDataPoint[1],
          chartDataPoint[6],
          chartDataPoint[7],
          chartDataPoint[8],
          chartDataPoint[9],
          chartDataPoint[10],
          chartDataPoint[11],
          chartDataPoint[12],
          chartDataPoint[13],
          chartDataPoint[14]
        );
    }
  }

  /**
   * Converts a list of chart data points to a list of ShapDriversDataPoints provided with the type of the chart data points.
   * @param chartDataPoints
   * @param type
   * @returns
   */
  public static fromChartDataPoints(
    chartDataPoints: any,
    type: ShapDriversDataPointChartType
  ): ShapDriversDataPoint[] {
    let dataPoints: ShapDriversDataPoint[] = [];
    for (let i = 0; i < chartDataPoints.length; i++) {
      dataPoints.push(this.fromChartDataPoint(chartDataPoints[i], type));
    }
    return dataPoints;
  }

  /**
   * Converts a list of ShapDriversDataPoints to a list of chart data points provided with the type of the chart data points.
   */
  // public static toChartDataPoints(dataPoints: ShapDriversDataPoint[], type: ShapDriversDataPointChartType): any[] {
  //     let chartDataPoints: any[] = [];
  //     for (let i = 0; i < dataPoints.length; i++) {
  //         chartDataPoints.push(dataPoints[i].toChartDataPoint(type));
  //     }
  //     return chartDataPoints;
  // }

  // MARK: - Instance Methods
  /**
   *
   * @param type the type of chart data point to return
   * @returns data point for the chart
   */
  // public toChartDataPoint(type: ShapDriversDataPointChartType): any[] {
  //     switch (type) {
  //         case ShapDriversDataPointChartType.DriversBarChart:
  //             // Return an array where 0 is incidence and 1 is sentiment, and the rest of the array is the other data point information...
  //             return [this.incidence, this.sentiment, this.benchmarkName, this.populationName, this.topic, this.topicUserFriendlyName, this.rowCount, this.vopicCount, this.positiveIncidence, this.negativeIncidence, this.incidenceZ, this.sentimentZ, this.positiveIncidenceZ, this.negativeIncidenceZ, this.benchmarkScore];
  //     }

  // }

  public valueOf(): string {
    return `${this.feature}::${this.outcome}::${this.population}`;
  }
}
