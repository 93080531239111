import React from 'react';
import { IconTrash, IconPlus, IconCirclePlus } from '@tabler/icons';
import { Button, ActionIcon, useMantineTheme } from '@mantine/core';

interface MantineButtonProps {
  type: string;
  label: string;
  onClick: (e: any) => void;
  readonly: boolean;
  config: any;
}

export const MantineButton: React.FC<MantineButtonProps> = ({
  type,
  label,
  onClick,
  readonly,
  config,
}: MantineButtonProps) => {
  const theme = useMantineTheme();

  const hideLabelsFor: any = {
    addRuleGroup: true,
    addRuleGroupExt: true,
  };
  const typeToIcon: any = {
    delGroup: <IconTrash />,
    delRuleGroup: <IconTrash />,
    delRule: <IconTrash />,
    addRule: <IconPlus />,
    addGroup: <IconCirclePlus />,
    addRuleGroupExt: <IconCirclePlus />,
    addRuleGroup: <IconCirclePlus />,
  };
  const typeToColor: any = {
    addRule: 'neutral',
    addGroup: 'primary',
    delGroup: 'red',
    delRuleGroup: 'red',
    delRule: 'red',
  };
  if (!label || hideLabelsFor[type]) {
    return (
      <ActionIcon
        size="xs"
        disabled={readonly}
        onClick={onClick}
        color={typeToColor[type]}
      >
        {typeToIcon[type]}
      </ActionIcon>
    );
  } else {
    return (
      <Button
        size="xs"
        variant={theme.colorScheme === 'light' ? 'white' : 'light'}
        disabled={readonly}
        onClick={onClick}
        color={typeToColor[type]}
        leftIcon={typeToIcon[type]}
      >
        {label}
      </Button>
    );
  }
};
