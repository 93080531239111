import { Select, SelectItem, SelectProps } from '@mantine/core';
import { useMemo } from 'react';
import {
  userFriendlyTopicName,
  userFriendlyTopicThemeName,
} from '@utils/TopicUtils';
import { useTopicStore } from '@stores/TopicStore';

export const TopicSelect: React.FC<SelectProps> = (porps) => {
  const allTopics = useTopicStore((s) => s.allTopics);
  const allThemes = useTopicStore((s) => s.allThemes);
  
  const topicOptions = useMemo(() => {
    return allTopics
      .map((topic) => {
        return {
          value: topic,
          label: userFriendlyTopicName(topic),
        } as SelectItem;
      })
      .sort((a, b) =>
        a.label.localeCompare(b.label)
      ) as unknown as SelectItem[];
  }, [allTopics]);

  const themeOptions = useMemo(() => {
    return allThemes
      .map((theme) => {
        return {
          value: theme,
          label: userFriendlyTopicThemeName(theme),
        } as SelectItem;
      })
      .sort((a, b) =>
        a.label.localeCompare(b.label)
      ) as unknown as SelectItem[];
  }, [allThemes]);

  return (
    <Select
        {...porps}
        hoverOnSearchChange
        placeholder="Select a topic"
        searchable
        data={topicOptions
            .map((value: any) => {
            return {
                ...value,
                group: 'Topics',
            };
            })
            .concat(
            themeOptions.map((value: any) => {
                return {
                ...value,
                group: 'Themes',
                };
            })
        )}
    />
  );
};