import {
  Tooltip,
  ThemeIcon,
  useMantineColorScheme,
  ActionIcon,
} from '@mantine/core';
import { IconSun, IconMoonStars } from '@tabler/icons';
import { FC } from 'react';

const ColorSchemeToggle: FC = () => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const isDark = colorScheme === 'dark';

  return (
    <Tooltip label={isDark ? 'Light mode' : 'Dark mode'} openDelay={250}>
      <ActionIcon
        color="blue"
        size="lg"
        variant="light"
        onClick={() => toggleColorScheme(isDark ? 'light' : 'dark')}
      >
        {isDark ? <IconSun size="1.1rem" /> : <IconMoonStars size="1.1rem" />}
      </ActionIcon>
    </Tooltip>
  );
};

export default ColorSchemeToggle;
