import React from 'react';
import QuoteReaderFiltersComponent from './QuoteReaderFiltersComponent';
import QuoteReaderCore from './QuoteReaderCore';
import QuoteModel from '../models/QuoteModel';
import QuoteAPI from '../apis/QuoteAPI';
import {
  Grid,
  Title,
  Button,
  Group,
  LoadingOverlay,
  Loader,
  useMantineTheme,
} from '@mantine/core';

import QuoteReaderCoreProps from './QuoteReaderCore';
import CRN from '../models/Crn';
import QuoteReaderFilterObject from '../models/ViewFilterMappingModel';
import { QuoteReaderProjectFilterObject } from '../models/ViewFilterMappingModel';

import topicDescriptions from '../topic-descriptions.json';
import valueDescriptions from '../value-descriptions.json';
import ProjectAPI from '@apis/ProjectAPI';

interface QuoteReaderProps {
  filtersEnabled: boolean;
  coreProps: any;
  filterProps: any;
  filters?: QuoteReaderProjectFilterObject;
  crn?: CRN;
}

interface QuoteReaderState {
  updateButtonEnabled: boolean;
  updateButtonLoading: boolean;
  flagButtonLoading: boolean;
  quotes?: QuoteModel[];
  filters?: QuoteReaderProjectFilterObject;
  crn?: CRN;
}

class QuoteReader extends React.Component<QuoteReaderProps, QuoteReaderState> {
  state: QuoteReaderState = {
    updateButtonEnabled: true,
    updateButtonLoading: false,
    flagButtonLoading: false,
  };

  filtersRef: React.RefObject<QuoteReaderFiltersComponent>;

  constructor(props: QuoteReaderProps) {
    super(props);

    this.filtersRef = React.createRef();

    this.getNewQuotes = this.getNewQuotes.bind(this);
  }

  componentDidMount(): void {
    console.log('QuoteReader mounted...');
    if (this.props.filters !== undefined) {
      this.setState(
        {
          filters: this.props.filters,
          crn: this.props.crn,
        },
        () => {
          this.getNewQuotes();
        }
      );
    }
  }

  componentDidUpdate(
    prevProps: Readonly<QuoteReaderProps>,
    prevState: Readonly<QuoteReaderState>,
    snapshot?: any
  ): void {
    if (this.props.filters !== undefined) {
      if (this.props.filters !== prevProps.filters) {
        console.log('Filters have changed...');
        this.setState(
          {
            filters: this.props.filters,
            crn: this.props.crn,
          },
          () => {
            this.getNewQuotes();
          }
        );
      }
    }
  }

  getNewQuotes(): void {
    // Use the state from the filters to get new quotes...
    // Update the view when the quotes are returned...
    const filters = this.filtersRef.current?.getFilters();

    this.setState({
      updateButtonEnabled: false,
      updateButtonLoading: true,
    });

    if (
      this.state.crn !== undefined &&
      this.state.filters !== undefined &&
      this.state.filters.benchmark !== undefined &&
      this.state.filters.population !== undefined &&
      this.state.filters.topics !== undefined
    ) {
      ProjectAPI.getQuotes(
        this.props.crn.resourceId,
        this.props.crn.organizationId,
        this.state.filters.benchmark,
        this.state.filters.population,
        this.state.filters.topics
      ).then((quotes: QuoteModel[]) => {
        console.log(quotes);
      });
    }
  }

  flagQuote = (quote?: QuoteModel) => {
    if (quote !== undefined) {
      this.setState({
        flagButtonLoading: true,
      });
      QuoteAPI.flagQuote(quote)
        .then((quote) => {
          console.log('Quote flagged...');

          this.setState({
            flagButtonLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            flagButtonLoading: false,
          });
        });
    }
  };

  render() {
    var coreProps = { ...this.props.coreProps };
    if (this.state.quotes !== undefined) {
      if (this.state.quotes !== this.props.coreProps.quotes) {
        // Update the core props with the new quotes...
        coreProps.quotes = this.state.quotes;
      }
    }

    // We want to pass the topic descriptions to the core component...
    coreProps.topicDescriptions = topicDescriptions;
    coreProps.valueDescriptions = valueDescriptions;

    if (this.props.filtersEnabled) {
      return (
        <Grid>
          <Grid.Col span={2}>
            <Title order={4}>Filters</Title>
            <QuoteReaderFiltersComponent
              ref={this.filtersRef}
              {...this.props.filterProps}
            />
            <Button
              size="xs"
              fullWidth
              onClick={this.getNewQuotes}
              loading={this.state.updateButtonLoading}
            >
              Update
            </Button>
          </Grid.Col>

          <Grid.Col span={10}>
            <QuoteReaderCore
              {...coreProps}
              onFlag={this.flagQuote}
              flagLoading={this.state.flagButtonLoading}
            />
          </Grid.Col>
        </Grid>
      );
    } else {
      return (
        <Grid>
          <Grid.Col span={12}>
            <div
              style={{
                position: 'relative',
                borderRadius: '15px',
                padding: '8px',
              }}
            >
              <LoadingOverlay
                visible={this.state.updateButtonLoading}
                overlayBlur={2}
              />
              <QuoteReaderCore
                {...coreProps}
                onFlag={this.flagQuote}
                flagLoading={this.state.flagButtonLoading}
              />
            </div>
          </Grid.Col>
        </Grid>
      );
    }
  }
}

export default QuoteReader;
