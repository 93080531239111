import { useProjectDatasets } from '@apis/hooks/useProjectDatasets';
import { MultiSelectCheckbox } from '@components/MultiSelectCheckbox';
import { OutcomeSelect } from '@components/OutcomeSelect/OutcomeSelect';
import { useCurrentAnalysis } from '@hooks/useCurrentAnalysis';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { useOutcomeData } from '@hooks/useOutcomeData';
import { Group, Stack, Title, Text, Button, Modal } from '@mantine/core';
import {
  AnalysisType,
  useAnalysisEditionStore,
} from '@stores/AnalysisEditionStore';
import React, { useEffect } from 'react';

interface DriversOutcomeModalProps {
  opened: boolean;
  onConfirm: () => void;
  onClose: () => void;
  outcomeValues: string[];
  setOutcomeValues: (values: string[]) => void;
  featureValues: string[];
  setFeatureValues: (values: string[]) => void;
}

export const DriversOutcomeModal: React.FC<DriversOutcomeModalProps> = ({
  opened,
  onConfirm,
  onClose,
  outcomeValues,
  setOutcomeValues,
  featureValues,
  setFeatureValues,
}) => {
  const project = useCurrentProject();
  const analysis = useCurrentAnalysis();
  const { data: datasets } = useProjectDatasets(project);
  const analysisType = useAnalysisEditionStore((s) => s.analysisType);
  const populations = useAnalysisEditionStore((s) => s.populations);
  const outcomeData = useOutcomeData(analysisType, populations, datasets);
  useEffect(() => {
    if (!analysis) {
      setFeatureValues((outcomeData?.topics ?? []).map((t) => t.value));
    }
  }, [analysis, outcomeData?.topics]);

  if (!project) {
    return null;
  }

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        <Stack spacing={1}>
          <Title order={3}>
            {analysis ? 'Save Analysis' : 'Create Analysis'}
          </Title>
          <Text size="sm" color="dimmed">
            {analysis
              ? 'Please confirm your outcome and features values'
              : 'Before comfirm operation, you need to provide an outcome and features values'}
          </Text>
        </Stack>
      }
      size="xl"
    >
      <Stack spacing="md" mt="md">
        {analysisType === AnalysisType.DRIVERS_OF_OUTCOME && outcomeData && (
          <OutcomeSelect
            nothingFound="No outcomes found"
            searchable={true}
            value={outcomeValues}
            onChange={setOutcomeValues}
            label="Outcome"
            description={
              populations.length !== 0
                ? "Choose what outcome you're interested in modeling"
                : 'Create a new population first'
            }
            withAsterisk={true}
            data={outcomeData.outcomeOptions}
            disabled={populations.length === 0}
            sections={outcomeData.sections}
          />
        )}
        {analysisType === AnalysisType.DRIVERS_OF_OUTCOME && outcomeData && (
          <MultiSelectCheckbox
            label="Features"
            required
            searchable
            description={
              populations.length !== 0
                ? "Choose which features you'd like to use in your model"
                : 'Please select a focal population first'
            }
            nothingFound="No features found"
            data={outcomeData.topics}
            value={featureValues}
            onChange={setFeatureValues}
            disabled={populations.length === 0}
          />
        )}
      </Stack>
      <Group position="right" mt="xl">
        <Button color="blue" variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="blue"
          disabled={outcomeValues.length === 0 || featureValues.length === 0}
          onClick={onConfirm}
        >
          {analysis ? 'Save Analysis' : 'Create Analysis'}
        </Button>
      </Group>
    </Modal>
  );
};
