import { ProjectMetricType } from '@apis/ProjectAPI';
import { Button, Indicator, Tooltip } from '@mantine/core';
import { useAnalysisStore } from '@stores/AnalysisStore';
import { useQuoteStore } from '@stores/QuoteStore';

export const CoMentionTypeFilter: React.FC = () => {
  const activeCoMentionType = useQuoteStore((q) => q.activeCoMentionType);
  const setActiveCoMentionType = useQuoteStore(
    (q) => q.setActiveCoMentionType
  );

  return activeCoMentionType === ProjectMetricType.NegativeCoMention ? (
    <>
      <Tooltip label="Change to Positive Co-Mentions" openDelay={400}>
        <Indicator size={6} offset={7} position="middle-start" color="red">
          <Button
            size="xs"
            variant="default"
            color="dark"
            onClick={() =>
              setActiveCoMentionType(ProjectMetricType.PositiveCoMention)
            }
          >
            Negative Co-Mentions
          </Button>
        </Indicator>
      </Tooltip>
    </>
  ) : (
    <>
      <Tooltip label="Change to Negative Co-Mentions" openDelay={400}>
        <Indicator size={6} offset={7} position="middle-start" color="green">
          <Button
            size="xs"
            variant="default"
            color="dark"
            onClick={() =>
              setActiveCoMentionType(ProjectMetricType.NegativeCoMention)
            }
          >
            Positive Co-Mentions
          </Button>
        </Indicator>
      </Tooltip>
    </>
  );
};
