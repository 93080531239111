import {
  Button,
  Center,
  Group,
  HoverCard,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from 'mantine-react-table';
import { FC, useEffect, useMemo, useState } from 'react';
import { useCurrentSummary } from '@hooks/useCurrentSummary';
import { useSummaryStore } from '@stores/SummaryStore';
import { userFriendlyTopicThemeName } from '@utils/TopicUtils';
import { SumaryData, useSummaryData } from '@apis/hooks/useSummaryData';
import AnalysisTypeIcon, { analysisTypeToString } from './AnalysisTypeIcon';
import { useElementSize } from '@mantine/hooks';
import Link from './Link';
import { AnalysisType } from '@stores/AnalysisEditionStore';
import { useQuoteStore } from '@stores/QuoteStore';
import { useTopicStore } from '@stores/TopicStore';
import { useAnalysisStore } from '@stores/AnalysisStore';
import { useAppStore } from '@stores/AppStore';
import { SummaryAnalysis } from '@hooks/useProjectSummaries';
import ProjectAPI from '@apis/ProjectAPI';
import { recomputeScores } from '@apis/hooks/useBenchmarkData';


interface TopicAndVopic {
  topic: string;
  vopic: number;
}

export const SummaryChart: FC<{}> = ({ }) => {
  const summary = useCurrentSummary()
  const numberOfMentions = useAnalysisStore((s) => s.numberOfMentions);
  const { data: summaryData, isLoading } = useSummaryData(summary);
  const { ref, height } = useElementSize();
  const activeTopics = useSummaryStore(s => s.activeTopics)
  const activeThemes = useSummaryStore(s => s.activeThemes)
  const showSummaryValues = useAppStore(s => s.showSummaryValues);
  const setAnalysisFocalPopulation = useAnalysisStore(s => s.setFocalPopulation);
  const setSelectedTopic = useTopicStore((s) => s.setFocalTopic);
  //Quotes store
  const setQuotePopulation = useQuoteStore(s => s.setPopulation)
  const [allTopicsAndVopics, setAllTopicsAndVopic] = useState<TopicAndVopic[]>([])
  const [columnsToRemove, setColumnsToRemove] = useState<string[]>([])
  const [isPlanOfActionLoading, setIsPlanOfActionLoading] = useState(true)
 
  // console.log({summaryData})
  useEffect(() => {
    if (allTopicsAndVopics.length > 0) {
      const columnsToRemove = allTopicsAndVopics.filter((t) => t.vopic < numberOfMentions).map((t) => (t.topic.toLowerCase()))
      let uniqueArray = Array.from(new Set(columnsToRemove));

      setColumnsToRemove(uniqueArray)
    }
  }, [numberOfMentions, allTopicsAndVopics])


  async function getCalculationForMentions(analyses: SummaryAnalysis[]) {

    if (analyses.length === 1) {
      if (analyses[0].type === AnalysisType.DRIVERS_OF_OUTCOME) {
        setIsPlanOfActionLoading(false)
        return
      }
    }

    const promises = analyses.filter(a => a.type !== AnalysisType.DRIVERS_OF_OUTCOME).map((analysis) => {
      return ProjectAPI.getBenchmarkData(
        summary.projectId,
        analysis.benchmark,
        '*',
        '*'
      )
        .then(recomputeScores)
        .then((data) => {
          return {
            data: data,
            type: analysis.type,
            id: analysis.id,
            count: data.reduce((acc, obj) => {
              acc += obj.rowCount;
              return acc;
            }, 0)
          }
        })

    })
    const results = await Promise.all(promises)

    const objectWithHighestCount = results.reduce((max, obj) => {
      return (obj.count > max.count) ? obj : max;
    }, results[0]);


    const topicAndVopic = objectWithHighestCount.data.map((d) => {
      return {
        topic: d.topic,
        vopic: d.vopicCount
      }
    })

    setAllTopicsAndVopic(topicAndVopic)
    setIsPlanOfActionLoading(false)
  }


  useEffect(() => {
    if (summary && !isLoading && summaryData && summaryData.length > 0) {
      if (summary.analyses) {
        getCalculationForMentions(summary.analyses);
      }
    }
  }, [summary, isLoading, summaryData])




  const data = useMemo(() => {
    return (summaryData ?? []).filter(d => activeTopics.map(v => v.toLowerCase()).includes(d.topic) || activeThemes.map(v => v.toLowerCase()).includes(d.topic))
  }, [summaryData, activeTopics, activeThemes])


  const columns: MRT_ColumnDef<SumaryData>[] = useMemo(() => [
    {
      accessorKey: 'topic',
      header: 'Topic',
      Header: () => <Center style={{ height: 50 }}><Text truncate>Topic</Text></Center>,
      Cell: ({ cell }) => <Text truncate>{userFriendlyTopicThemeName(cell.getValue() as string)}</Text>,
      Footer: () => <Text size="xs" color='dimmed' truncate>Focal Populations:</Text>,
    },
    {
      accessorKey: 'prioritize',
      header: 'Prioritize',
      Header: () => <Center style={{ height: 50 }}><Text truncate>Prioritize</Text></Center>,
      Cell: ({ row, cell }) => <ColorItem id="prioritize" showSummaryValues={showSummaryValues} value={cell.getValue() as number} />,
      footer: '',
      size: 140,
    },
    ...summary.analyses.map((a, i) => {
      return {
        accessorKey: a.id,
        header: a.name,
        Header: () => <Link
          style={{ color: "#495057", maxWidth: "100%", textDecoration: "none" }}
          to={`/projects/${summary.projectId}/summaries/${summary.id}/analyses/${a.id}`}>
          <Tooltip label={"Click to open analysis"} >
            <Stack
              onClick={() => setAnalysisFocalPopulation(a.focalPopulation)}
              justify='center'
              align='center'
              spacing={5}
              style={{ width: "100%" }}
            >
              <Group
                noWrap
                spacing={"xs"}
                style={{ maxWidth: "100%" }}
              >
                <AnalysisTypeIcon
                  type={a.type} />
                <Text
                  truncate
                  style={{ maxWidth: "calc(100% - 28px)" }}
                >
                  {analysisTypeToString(a.type)}
                </Text>
              </Group>
              <Text
                truncate
                style={{ maxWidth: "100%" }}
              >
                {a.name}
              </Text>
            </Stack>
          </Tooltip>
        </Link>,
        Cell: ({ cell }) => {
          return (
            <ColorItem
              id={a.id}
              showSummaryValues={showSummaryValues}
              type={a.type}
              value={cell.getValue().value as number}
              opacity={0.6}
            />
          )
        },
        Footer: () => (
          <Text
            size="xs"
            color='dimmed'
            truncate>
            {"(" + a.focalPopulation + ")"}
          </Text>
        ),
        size: 140,
      };
    }),
  ], [data, showSummaryValues, summary.analyses]);

  const table = useMantineReactTable({
    columns,
    // data,
    data: data.filter(d => !columnsToRemove.includes(d.topic)),
    initialState: {
      columnPinning: {
        left: ['topic', 'prioritize'], //pin email column to left by default
      },
    },
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: false,
    enablePagination: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    mantinePaperProps: {
      style: {
        border: "none",
        boxShadow: "none",
      }
    },
    mantineTableHeadCellProps: ({ column, table }) => ({
      align: column.id === 'topic' ? 'left' : 'center',
      style: {
        padding: 5,
      },
    }),
    mantineTableBodyCellProps: ({ column, row }) => ({
      onClick: () => {
        if (column.id !== 'topic' && column.id !== "prioritize") {
          setSelectedTopic(row.original.topic);
          const analysis = summary.analyses.find(a => a.id === column.id);
          if (analysis) {
            setQuotePopulation({
              id: analysis.focalPopulation,
              title: analysis.focalPopulation,
              definition: analysis.focalPopulationDefinition,
            })
          }
        }
      },
      align: column.id === 'topic' ? 'left' : 'center',
      style: {
        padding: 5,
        border: "none",
        cursor: column.id !== 'topic' && column.id !== "prioritize" ? "pointer" : undefined
      },
    }),
    mantineTableFooterCellProps: ({ column }) => ({
      display: 'flex',
      style: {
        padding: 5,
        justifyContent: column.id !== 'topic' ? "center" : undefined
      }
    }),
    enableToolbarInternalActions: false,
    enableColumnVirtualization: true,
    enableRowVirtualization: true,

    mantineTableContainerProps: {
      sx: {
        maxHeight: height,
        border: "none",
      }
    },

    state: { isLoading: isLoading || isPlanOfActionLoading },
    rowVirtualizerProps: { overscan: 5 }, //optionally customize the row virtualizer
    columnVirtualizerProps: { overscan: 2 }, //optionally customize the column virtualizer
  });

  return <div ref={ref} style={{ width: "100%", height: "100%", position: "relative" }}>
    <div style={{ position: "absolute", inset: 0, height: "100%" }}>
      <MantineReactTable table={table} />
    </div>
  </div>
};

interface ColorItemProps {
  id: string,
  showSummaryValues: boolean;
  type?: AnalysisType,
  value: number,
  opacity?: number,
}

const ColorItem: FC<ColorItemProps> = ({ id, showSummaryValues, type, value, opacity = 1 }) => {
  const isPrioritize = id === 'prioritize'
  const { color, textColor } = useMemo(() => {
    if (type === AnalysisType.DRIVERS_OF_OUTCOME) {
      if (value >= 2) {
        return { color: "#cdddff", textColor: "black" };
      } else if (value >= 0.5) {
        return { color: "#89bbff", textColor: "black" };
      } else if (value >= -0.5) {
        return { color: "#79aaff", textColor: "black" };
      } else if (value > -2) {
        return { color: "#6697ff", textColor: "black" };
      } else if (value <= -2) {
        return { color: "#4a8dff", textColor: "black" };
      }
    }

    if (value >= 2) {
      return { color: "#00783a", textColor: "black" };
    } else if (value >= 0.5) {
      return { color: "#51ad5c", textColor: "black" };
    } else if (value >= -0.5) {
      return { color: "#fbcf4f", textColor: "black" };
    } else if (value > -2) {
      return { color: "#ff6e3b", textColor: "white" };
    } else if (value <= -2) {
      return { color: "#cf0839", textColor: "white" };
    }

    return { color: "transparent", textColor: "transparent" }
  }, [value, type])

  return <div style={{ padding: 4, width: 90, height: 30, backgroundColor: color, borderRadius: "0.125rem", opacity: isPrioritize ? 1 : opacity }}>
    <Text c={textColor} className='data-value' style={{ display: showSummaryValues ? undefined : "none" }}>{value !== undefined ? value.toFixed(1) : ""}</Text>
  </div>
}

