// MARK: - Helper function to make the topic name pretty...
export const userFriendlyTopicName = (topic: string): string => {
  // Replace each _ with a space and capitalize the first letter of each word
  return topic
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const userFriendlyThemeName = (theme: string): string => {
  // Replace each _ with a space and capitalize the first letter of each word
  // Remove trailing _T or _V if present
  // Split on _ and make all caps for each word
  return theme
    .replace(/_T$|_V$/, '')
    .replace(/_t$|_v$/, '')
    .split('_')
    .map((word) => word.toUpperCase())
    .join(' ');
};

export const userFriendlyTopicThemeName = (topicOrTheme: string): string => {
  if (topicOrTheme.endsWith('_T') || topicOrTheme.endsWith('_t')) {
    return userFriendlyThemeName(topicOrTheme);
  } else {
    return userFriendlyTopicName(topicOrTheme);
  }
};
