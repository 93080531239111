import { recomputeScores } from '@apis/hooks/useBenchmarkData';
import { useProjectDatasets } from '@apis/hooks/useProjectDatasets';
import { useProjectTopics } from '@apis/hooks/useProjectTopics';
import { generateCombinedPercentTotalShap, mergeByTopicForOverTime } from '@apis/hooks/useSummaryData';
import ProjectAPI from '@apis/ProjectAPI';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { useCurrentSummary } from '@hooks/useCurrentSummary';
import { useCustomAnalyses } from '@hooks/useCustomAnalyses';
import { useDefaultAnalyses } from '@hooks/useDefaultAnalyses';
import { useFuse } from '@hooks/useFuse';
import { formatOutcomeOptions } from '@hooks/useOutcomeData';
import { Analysis, useProjectAnalyses } from '@hooks/useProjectAnalyses';
import { SummaryAnalysis } from '@hooks/useProjectSummaries';
import { Parser } from '@json2csv/plainjs';
import { ActionIcon, Button, Group, Modal, Stack, Text, TextInput, Title, Tooltip } from '@mantine/core'
import { FuseAnalysesOptions } from '@pages/ProjectPage';
import { AnalysisType } from '@stores/AnalysisEditionStore';
import { IconDownload } from '@tabler/icons'
import React, { useState } from 'react'
import { BenchmarkDataPoint } from './charts/datamodels/BenchmarkDataPoint';
import { ShapDriversDataPoint } from './charts/datamodels/ShapDriversDataPoint';
import { getKeysWithValueFromMap } from './ProjectDownloadDataModal';


type OverTimeDataWithCalc = {
    firstPop: BenchmarkDataPoint;
    secondPop: BenchmarkDataPoint;
    theCalculation?: number;
}

type RawVoiceDataWithCalc = BenchmarkDataPoint & {
    positiveIncidenceMinusNegativeIncidence?: number;
    theCalculation?: number;
}

type DriverWithZ = ShapDriversDataPoint & {
    combinedPercentTotalShapZ?: number;
}

type AllResults = {
    analysis: SummaryAnalysis;
    data: OverTimeDataWithCalc[];
    type: AnalysisType.PROGRESS_OVER_TIME;
    benchmarkName: string;
    analysisId: string;
    focalPopulation: string;
} | {
    analysis: SummaryAnalysis;
    data: BenchmarkDataPoint[];
    type: AnalysisType.INTERNAL_BENCHMARK;
    benchmarkName: string;
    analysisId: string;
    focalPopulation: string;
} | {
    analysis: SummaryAnalysis;
    data: RawVoiceDataWithCalc[];
    type: AnalysisType.RAW_VOICE;
    benchmarkName: string;
    analysisId: string;
    focalPopulation: string;
} | {
    analysis: SummaryAnalysis;
    data: DriverWithZ[];
    type: AnalysisType.DRIVERS_OF_OUTCOME;
    benchmarkName: string;
    analysisId: string;
    focalPopulation: string;
    outcome: string
}


const parseOverTimeData = (data: BenchmarkDataPoint[], populations: string[]): OverTimeDataWithCalc[] => {
    let mergedData: OverTimeDataWithCalc[] = mergeByTopicForOverTime(data);

    let obj = {};

    for (let index = 0; index < mergedData.length; index++) {
        const firstPopulationElement = mergedData[index].firstPop;
        const secondPopulationElement = mergedData[index].secondPop;
        let theCalculation = 0;
        if (!firstPopulationElement || !secondPopulationElement) {
            continue;
        }
        theCalculation =
            (10 / 3) *
            ((firstPopulationElement.positiveIncidence -
                secondPopulationElement.positiveIncidence -
                (firstPopulationElement.negativeIncidence -
                    secondPopulationElement.negativeIncidence)) /
                ((firstPopulationElement.incidence +
                    secondPopulationElement.incidence) /
                    2));

        mergedData[index].theCalculation = theCalculation

        obj[firstPopulationElement.topic.toLowerCase()] = theCalculation;

    }

    return mergedData.filter((dp) => !!dp.firstPop && !!dp.secondPop)
}

const parseRawVoiceData = (
    data: BenchmarkDataPoint[],
    focalPopulation: string
): RawVoiceDataWithCalc[] => {
    const mean = (values: number[]): number =>
        values.reduce((acc, val) => acc + val, 0) / values.length;

    const stdDev = (values: number[]): number => {
        const mu = mean(values);
        return Math.sqrt(
            values.reduce((acc, val) => acc + Math.pow(val - mu, 2), 0) /
            values.length
        );
    };

    const values = data.map(
        (d) => d.positiveIncidence - d.negativeIncidence ?? 0
    );
    const mu = mean(values);
    const sigma = stdDev(values);

    data.forEach((group) => {
        group[`positiveIncidenceMinusNegativeIncidence`] =
            ((group.positiveIncidence - group.negativeIncidence ?? 0) - mu) / sigma;

        group[`theCalculation`] =
            ((group.positiveIncidence - group.negativeIncidence ?? 0) - mu) / sigma;

    });
    return data.filter((dp => dp.populationName === focalPopulation))
}

const parseBenchmarkData = (
    data: BenchmarkDataPoint[],
    focalPopulation: string
) => {

    return data.filter((dp) => dp.populationName === focalPopulation)
}

const parseDrivenData = (
    data: ShapDriversDataPoint[],
    focalPopulation: string
) => {
    const mean = (values: number[]): number =>
        values.reduce((acc, val) => acc + val, 0) / values.length;

    // Helper function to calculate standard deviation
    const stdDev = (values: number[]): number => {
        const mu = mean(values);
        return Math.sqrt(
            values.reduce((acc, val) => acc + Math.pow(val - mu, 2), 0) /
            values.length
        );
    };

    const values = data.map((d) => d['combinedPercentTotalShap'] ?? 0);
    const mu = mean(values);
    const sigma = stdDev(values);

   
    data.forEach((group) => {
        group[`combinedPercentTotalShapZ`] =
            (((group['combinedPercentTotalShap'] ?? 0) - mu) / sigma) * -1;
    });

    return data.filter((dp) => dp.population === focalPopulation)

    // return Object.values(data.map((dp) => {
    //     if (dp.population === focalPopulation) {
    //         return dp
    //     }
    // }))
}


const checkNan = (num: number) => {
    if (isNaN(num)) {
        return ""
    }
    return num

}

const SummaryDownloadButton = () => {


    const summary = useCurrentSummary();
    const currentProject = useCurrentProject();
    const { data: datasets } = useProjectDatasets(currentProject);
    const projectData = useProjectTopics(currentProject);
    const analyses = useProjectAnalyses(currentProject);
    const customAnalyses = useCustomAnalyses(currentProject, analyses);
    const defaultAnalyses = useDefaultAnalyses(currentProject, analyses);


    const [showDownloadModal, setShowDownloadModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [fileName, setFileName] = useState(summary.name)

    const themeTopicMapping = projectData?.data?.topicThemeMapping || new Map<string, string[]>();


    async function downloadData() {
        try {

            setIsLoading(true)

            const analyses = summary.analyses;

            const promises = analyses.map((analysis) => {

                if (analysis.type === AnalysisType.PROGRESS_OVER_TIME) {

                    return ProjectAPI.getBenchmarkData(
                        summary.projectId,
                        analysis.benchmark,
                        '*',
                        '*'
                    ).then(recomputeScores)
                        .then((data) => {
                            return {
                                analysis,
                                data: parseOverTimeData(data, analysis.populations),
                                type: AnalysisType.PROGRESS_OVER_TIME,
                                focalPopulation: analysis.focalPopulation,
                                benchmarkName: analysis.name,
                                analysisId: analysis.id,
                            }
                        })
                }

                if (analysis.type === AnalysisType.RAW_VOICE) {
                    return ProjectAPI.getBenchmarkData(
                        summary.projectId,
                        analysis.benchmark,
                        '*',
                        '*'
                    ).then(recomputeScores).then((data) => {
                        return {
                            analysis,
                            data: parseRawVoiceData(data, analysis.focalPopulation),
                            type: AnalysisType.RAW_VOICE,
                            focalPopulation: analysis.focalPopulation,
                            benchmarkName: analysis.name,
                            analysisId: analysis.id,
                        }
                    })
                }
                if (analysis.type === AnalysisType.INTERNAL_BENCHMARK) {
                    return ProjectAPI.getBenchmarkData(
                        summary.projectId,
                        analysis.benchmark,
                        '*',
                        '*'
                    ).then(recomputeScores).then((data) => ({
                        analysis,
                        data: parseBenchmarkData(data, analysis.focalPopulation),
                        type: AnalysisType.INTERNAL_BENCHMARK,
                        focalPopulation: analysis.focalPopulation,
                        benchmarkName: analysis.name,
                        analysisId: analysis.id,
                    }))
                }
                if (analysis.type === AnalysisType.DRIVERS_OF_OUTCOME) {
                    let findingAnalysis = customAnalyses.find(a => a.id === analysis.id);

                    if (!findingAnalysis) {
                        findingAnalysis = defaultAnalyses.find(a => a.id === analysis.id)
                    }
                    return Promise.all([
                        ProjectAPI.getAnalysisData(
                            summary.projectId,
                            analysis.id,
                            'Topic',
                            summary.organizationId
                        ),
                        ProjectAPI.getAnalysisData(
                            summary.projectId,
                            analysis.id,
                            'Theme',
                            summary.organizationId
                        ),
                    ]).then(([topicData, themeData]) => {
                        console.log({
                            topicData,
                            themeData,
                            fp:analysis.focalPopulation
                        })
                        return ({
                            analysis,
                            data: [
                                ...parseDrivenData(topicData, analysis.focalPopulation),
                                ...parseDrivenData(
                                    generateCombinedPercentTotalShap(themeData),
                                    analysis.focalPopulation
                                ),
                            ],
                            type: AnalysisType.DRIVERS_OF_OUTCOME,
                            focalPopulation: analysis.focalPopulation,
                            benchmarkName: analysis.name,
                            analysisId: analysis.id,
                            outcome: findingAnalysis?.outcome[0]?.alias || ""
                        })
                    })
                }


            })

            const allResults: AllResults[] = await Promise.all(promises) as any
            let rawTopicToFriendlyTopicMap: Record<string, string> = {}
            let mappingExtra: Record<string, string[]> = {}
            let _prioritize = {};


            allResults.forEach((result) => {
                if (result.type === AnalysisType.PROGRESS_OVER_TIME) {
                    result.data.forEach((data) => {
                        const topicName = data.firstPop.topic.toLowerCase()
                        const topicNameNormalCase = data.firstPop.topic
                        rawTopicToFriendlyTopicMap[topicName] = data.firstPop.topicUserFriendlyName
                        if (mappingExtra[topicName] === undefined) {
                            mappingExtra[topicName] = [topicNameNormalCase]
                        } else {
                            if (!mappingExtra[topicName].includes(topicNameNormalCase)) {
                                mappingExtra[topicName].push(topicNameNormalCase)
                            }
                        }
                        if (!isNaN(data.theCalculation)) {
                            if (_prioritize[topicName] === undefined) {
                                _prioritize[topicName] = data.theCalculation
                            } else {
                                _prioritize[topicName] += data.theCalculation
                            }
                        }
                    })
                }
                if (result.type === AnalysisType.INTERNAL_BENCHMARK) {
                    result.data.forEach((data) => {
                        const topicName = data.topic.toLowerCase()
                        const topicNameNormalCase = data.topic
                        rawTopicToFriendlyTopicMap[topicName] = data.topicUserFriendlyName
                        if (mappingExtra[topicName] === undefined) {
                            mappingExtra[topicName] = [topicNameNormalCase]
                        } else {
                            if (!mappingExtra[topicName].includes(topicNameNormalCase)) {
                                mappingExtra[topicName].push(topicNameNormalCase)
                            }
                        }
                        if (!isNaN(data.benchmarkScore)) {
                            if (_prioritize[topicName] === undefined) {
                                _prioritize[topicName] = data.benchmarkScore
                            } else {
                                _prioritize[topicName] += data.benchmarkScore
                            }
                        }
                    })
                }
                if (result.type === AnalysisType.RAW_VOICE) {
                    result.data.forEach((data) => {
                        const topicName = data.topic.toLowerCase()
                        const topicNameNormalCase = data.topic
                        rawTopicToFriendlyTopicMap[topicName] = data.topicUserFriendlyName
                        if (mappingExtra[topicName] === undefined) {
                            mappingExtra[topicName] = [topicNameNormalCase]
                        } else {
                            if (!mappingExtra[topicName].includes(topicNameNormalCase)) {
                                mappingExtra[topicName].push(topicNameNormalCase)
                            }
                        }

                        if (!isNaN(data.theCalculation)) {
                            if (_prioritize[topicName] === undefined) {
                                _prioritize[topicName] = data.theCalculation
                            } else {
                                _prioritize[topicName] += data.theCalculation
                            }
                        }
                    })
                }
                if (result.type === AnalysisType.DRIVERS_OF_OUTCOME) {
                    result.data.forEach((data) => {
                        console.log(data)
                        const topicName = data.feature.toLowerCase()
                        const topicNameNormalCase = data.feature
                        rawTopicToFriendlyTopicMap[topicName] = data.featureUserFriendlyName
                        if (mappingExtra[topicName] === undefined) {
                            mappingExtra[topicName] = [topicNameNormalCase]
                        } else {
                            if (!mappingExtra[topicName].includes(topicNameNormalCase)) {
                                mappingExtra[topicName].push(topicNameNormalCase)
                            }
                        }

                        if (!isNaN(data.combinedPercentTotalShap)) {
                            if (_prioritize[topicName] === undefined) {
                                _prioritize[topicName] = data.combinedPercentTotalShapZ
                            } else {
                                _prioritize[topicName] += data.combinedPercentTotalShapZ
                            }
                        }
                    })
                }

            })

            let allTopics: string[] = [];
            themeTopicMapping.forEach((value) => {
                allTopics = [...allTopics, ...value]
            })
            const finalData = Object.keys(_prioritize).map((key) => {
                const mappingKeysCheck = mappingExtra[key]
                const hasMatch = mappingKeysCheck.some(element => allTopics.includes(element));

                if (!hasMatch && !(key.endsWith("_t") || key.endsWith("_T"))) {
                    return undefined
                }

                const keys = getKeysWithValueFromMap(themeTopicMapping, mappingKeysCheck[0])
                // console.log(key,key.endsWith("_t") || key.endsWith("_T"))
                return {
                    topic: key,
                    keys: keys.length > 0 ? keys : mappingExtra[key],
                    isTheme: key.endsWith("_t") || key.endsWith("_T"),
                    priority: _prioritize[key] / allResults.length,
                    analyses: allResults.map((value) => {

                        if (value.type === AnalysisType.PROGRESS_OVER_TIME) {
                            return {
                                data: value.data.filter(dp => dp.firstPop.topic.toLowerCase() === key),
                                type: value.type,
                                benchmark: value.benchmarkName,
                                analysisId: value.analysisId
                            }
                        }
                        if (value.type === AnalysisType.RAW_VOICE) {
                            return {
                                data: value.data.filter(dp => dp.topic.toLowerCase() === key),
                                type: value.type,
                                benchmark: value.benchmarkName,
                                analysisId: value.analysisId
                            }
                        }
                        if (value.type === AnalysisType.INTERNAL_BENCHMARK) {
                            return {
                                data: value.data.filter(dp => dp.topic.toLowerCase() === key),
                                type: value.type,
                                benchmark: value.benchmarkName,
                                analysisId: value.analysisId
                            }
                        }
                        if (value.type === AnalysisType.DRIVERS_OF_OUTCOME) {
                            return {
                                data: value.data.filter(dp => dp.feature.toLowerCase() === key),
                                type: value.type,
                                benchmark: value.benchmarkName,
                                analysisId: value.analysisId,
                                outcome: value.outcome
                            }
                        }
                    }).flat()
                }
            }).filter((value) => !!value).sort((a, b) => a.priority - b.priority)



            let dataForExcel: Record<string, any>[] = [];

            for (let index = 0; index < finalData.length; index++) {
                const data = finalData[index];


                for (let index2 = 0; index2 < data.keys.length; index2++) {
                    const theme = data.keys[index2];

                    const obj = {
                        "Raw Topic": data.topic,
                        "Topic": rawTopicToFriendlyTopicMap[data.topic],
                        Theme: theme,
                        Is_Theme: data.isTheme,
                    }

                    for (let index3 = 0; index3 < data.analyses.length; index3++) {
                        const theAnalysis = data.analyses[index3];
                        if (theAnalysis.type === AnalysisType.PROGRESS_OVER_TIME) {
                            const theData = theAnalysis.data[0]
                            if (theData) {
                                obj[`${theAnalysis.benchmark} - Analysis Type`] = "Direct Compare"
                                obj[`${theAnalysis.benchmark} - Population 1 Name`] = theData.firstPop.populationName
                                obj[`${theAnalysis.benchmark} - Population 2 Name`] = theData.secondPop.populationName

                                obj[`${theAnalysis.benchmark} - ${theData.firstPop.populationName} - Incidence Percent`] = checkNan(theData.firstPop.incidence)
                                obj[`${theAnalysis.benchmark} - ${theData.firstPop.populationName} - Sentiment Percent`] = checkNan(theData.firstPop.sentiment)
                                obj[`${theAnalysis.benchmark} - ${theData.firstPop.populationName} - Negative Incidence`] = checkNan(theData.firstPop.negativeIncidence)
                                obj[`${theAnalysis.benchmark} - ${theData.firstPop.populationName} - Positive Incidence`] = checkNan(theData.firstPop.positiveIncidence)
                                obj[`${theAnalysis.benchmark} - ${theData.firstPop.populationName} - Vopic Count`] = checkNan(theData.firstPop.vopicCount)
                                obj[`${theAnalysis.benchmark} - ${theData.firstPop.populationName} - Row Count`] = checkNan(theData.firstPop.rowCount)

                                obj[`${theAnalysis.benchmark} - ${theData.secondPop.populationName} - Incidence Percent`] = checkNan(theData.secondPop.incidence)
                                obj[`${theAnalysis.benchmark} - ${theData.secondPop.populationName} - Sentiment Percent`] = checkNan(theData.secondPop.sentiment)
                                obj[`${theAnalysis.benchmark} - ${theData.secondPop.populationName} - Negative Incidence`] = checkNan(theData.secondPop.negativeIncidence)
                                obj[`${theAnalysis.benchmark} - ${theData.secondPop.populationName} - Positive Incidence`] = checkNan(theData.secondPop.positiveIncidence)
                                obj[`${theAnalysis.benchmark} - ${theData.secondPop.populationName} - Vopic Count`] = checkNan(theData.secondPop.vopicCount)
                                obj[`${theAnalysis.benchmark} - ${theData.secondPop.populationName} - Row Count`] = checkNan(theData.secondPop.rowCount)

                                obj[`${theAnalysis.benchmark} - ${theData.firstPop.populationName} VS ${theData.secondPop.populationName} - Row Count`] = checkNan(theData.theCalculation)
                            }

                        }

                        if (theAnalysis.type === AnalysisType.RAW_VOICE) {
                            const theData = theAnalysis.data[0]
                            if (theData) {
                                obj[`${theAnalysis.benchmark} - Analysis Type`] = "Blank Sheet"
                                obj[`${theAnalysis.benchmark} - Population Name`] = theData.populationName

                                obj[`${theAnalysis.benchmark} - Incidence Percent`] = checkNan(theData.incidence)
                                obj[`${theAnalysis.benchmark} - Sentiment Percent`] = checkNan(theData.sentiment)
                                obj[`${theAnalysis.benchmark} - Negative Incidence`] = checkNan(theData.negativeIncidence)
                                obj[`${theAnalysis.benchmark} - Positive Incidence`] = checkNan(theData.positiveIncidence)
                                obj[`${theAnalysis.benchmark} - Vopic Count`] = checkNan(theData.vopicCount)
                                obj[`${theAnalysis.benchmark} - Row Count`] = checkNan(theData.rowCount)
                                obj[`${theAnalysis.benchmark} - Vopic Count`] = checkNan(theData.vopicCount)
                                obj[`${theAnalysis.benchmark} - Positive Incidence MINUS ${theAnalysis.benchmark} - Negative Incidence`] = (checkNan(theData.positiveIncidence) || 0) - (checkNan(theData.negativeIncidence) || 0)
                                obj[`${theAnalysis.benchmark} - Blank Sheet Z-Score`] = checkNan(theData.theCalculation)
                            }
                        }

                        if (theAnalysis.type === AnalysisType.INTERNAL_BENCHMARK) {
                            const theData = theAnalysis.data[0]
                            if (theData) {
                                obj[`${theAnalysis.benchmark} - Analysis Type`] = "Benchmark"
                                obj[`${theAnalysis.benchmark} - Population Name`] = theData.populationName

                                obj[`${theAnalysis.benchmark} - Incidence Percent`] = checkNan(theData.incidence)
                                obj[`${theAnalysis.benchmark} - Sentiment Percent`] = checkNan(theData.sentiment)
                                obj[`${theAnalysis.benchmark} - Negative Incidence`] = checkNan(theData.negativeIncidence)
                                obj[`${theAnalysis.benchmark} - Positive Incidence`] = checkNan(theData.positiveIncidence)
                                obj[`${theAnalysis.benchmark} - Vopic Count`] = checkNan(theData.vopicCount)
                                obj[`${theAnalysis.benchmark} - Row Count`] = checkNan(theData.rowCount)
                                obj[`${theAnalysis.benchmark} - Incidence_Z`] = checkNan(theData.incidenceZ)
                                obj[`${theAnalysis.benchmark} - Sentiment_Z`] = checkNan(theData.sentimentZ)
                                obj[`${theAnalysis.benchmark} - Negative Incidence_Z`] = checkNan(theData.negativeIncidenceZ)
                                obj[`${theAnalysis.benchmark} - Positive Incidence_Z`] = checkNan(theData.positiveIncidenceZ)
                                obj[`${theAnalysis.benchmark} - Benchmark`] = checkNan(theData.benchmarkScore)
                            }
                        }

                        if (theAnalysis.type === AnalysisType.DRIVERS_OF_OUTCOME) {
                            const theData = theAnalysis.data[0]
                            if (theData) {
                                obj[`${theAnalysis.benchmark} - Analysis Type`] = "Drivers"
                                obj[`${theAnalysis.benchmark} - Population Name`] = theData.population

                                obj[`${theAnalysis.benchmark} - Row Count`] = theData.populationCount
                                obj[`${theAnalysis.benchmark} - Outcome Name`] = theAnalysis.outcome
                                obj[`${theAnalysis.benchmark} - Percent Outcome Topic Drives`] = theData.combinedPercentTotalShap || 0
                                obj[`${theAnalysis.benchmark} - Drivers Z-Score`] = theData.combinedPercentTotalShapZ
                            }
                        }

                    }

                    dataForExcel.push(obj)
                }
            }

            const parser = new Parser();
            const csv = parser.parse(dataForExcel);

            const theFileName = `${fileName}`
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${theFileName}.csv`;
            link.click();
            setIsLoading(false)
            // setShowDownloadModal(false)

        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }


    return (
        <>
            <Tooltip label="Download data" openDelay={500}>
                <ActionIcon onClick={() => setShowDownloadModal(true)}>
                    <IconDownload size={18} />
                </ActionIcon>
            </Tooltip>

            <Modal
                opened={showDownloadModal}
                onClose={() => {
                    setShowDownloadModal(false)
                }}
                withCloseButton={false}
                size="xl"
            >
                <Stack spacing={'xs'}>
                    <Title size="h2">Download Summary Data</Title>
                    <Text size="sm">
                        Use the raw project data in either a spreadsheet application or
                        another BI analytics tool, such as Tableau or Power BI.
                    </Text>
                </Stack>
                <br />
                <Stack>
                    <TextInput
                        label="File Name"
                        maxLength={200}
                        value={fileName}
                        onChange={(e) => {
                            setFileName(e.target.value)
                        }}
                    />
                </Stack>
                <br /> <br />
                <Group position="right">
                    <Button variant="subtle" color="dark" onClick={() => setShowDownloadModal(false)}>
                        Cancel
                    </Button>
                    <Button loading={isLoading} onClick={() => downloadData()}>
                        {isLoading ? 'Preparing...' : 'Download'}
                    </Button>
                </Group>
            </Modal>
        </>
    )
}

export default SummaryDownloadButton