import { ActionIcon, Button, Tooltip } from '@mantine/core';
import { useAnalysisStore } from '@stores/AnalysisStore';
import { IconStackPop, IconStackPush } from '@tabler/icons';

export const ModelAggregationButton: React.FC = () => {
  const activeModelAggregation = useAnalysisStore(
    (s) => s.activeModelAggregation
  );
  const setActiveModelAggregation = useAnalysisStore(
    (s) => s.setActiveModelAggregation
  );

  return (
    <Tooltip
      label={
        activeModelAggregation === 'Topic'
          ? 'Aggregate to Themes'
          : 'Show as Topics'
      }
      position="bottom"
      openDelay={400}
    >
      <Button
        variant="default"
        size="xs"
        onClick={() => {
          if (activeModelAggregation !== 'Theme') {
            setActiveModelAggregation('Theme');
          } else {
            setActiveModelAggregation('Topic');
          }
        }}
      >
        {activeModelAggregation == 'Topic' ? (
          <ActionIcon color="dark" variant="transparent">
            <IconStackPop size="1.125rem" />
          </ActionIcon>
        ) : (
          <ActionIcon color="dark" variant="transparent">
            <IconStackPush size="1.125rem" />
          </ActionIcon>
        )}
      </Button>
    </Tooltip>
  );
};
