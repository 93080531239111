import ProjectAPI from '@apis/ProjectAPI';
import CRN from '@models/Crn';
import { DateTimeFieldSettings } from '@react-awesome-query-builder/core';
import moment from 'moment';

interface ColumnDataInterface {
  [name: string]: {
    Nullable: boolean;
    DataType: string;
    Values: null | any[];
  };
}

type columnDataArrType = ColumnDataInterface[];

interface validResultsDataInterface {
  Column: string;
  Value: string;
}

type validResultsArrType = validResultsDataInterface[][][];

type columnNameAndIndexType = Record<string, number>;

export const getLabelFromColumn = (
  column: string,
  label: string,
  metadata: any
): string | undefined => {
  // Get the alias from the metadata...
  const columnInfo = metadata.Content.Properties.MutableAttributes.Columns;
  if (columnInfo[column] === undefined) return undefined;
  return columnInfo[column][label] ? columnInfo[column][label] : column;
};

export const getOrdinalRankFromColumn = (
  column: string,
  metadata: any
): number | undefined => {
  const associatedResponse = getLabelFromColumn(
    column,
    'Ordinal Rank',
    metadata
  );
  if (associatedResponse === undefined) return undefined;

  // try to convert any non-number to number...
  const rank = Number(associatedResponse);
  return isNaN(rank) ? undefined : rank;
};

export const getAliasFromColumn = (column: any, metadata: any): string => {
  const label = getLabelFromColumn(column, 'Alias', metadata);

  return label || column;
};

// MARK: - Field Label Helpers

export const getFieldLabelFromColumnValue = (
  column: string,
  value: string,
  label: string,
  metadata: any
): string | undefined => {
  const fieldLabelInfo =
    metadata.Content.Properties.MutableAttributes.FieldValues[column];
  if (fieldLabelInfo === undefined) return undefined;

  const labelSpecificValues = fieldLabelInfo[label];
  if (labelSpecificValues === undefined) return undefined;

  return labelSpecificValues[value];
};

export const getOrdinalRankValueFromColumnValue = (
  column: string,
  value: string,
  metadata: any
): number | undefined => {
  const associatedResponse = getFieldLabelFromColumnValue(
    column,
    value,
    'Ordinal Rank',
    metadata
  );
  if (associatedResponse === undefined) return undefined;

  // try to convert any non-number to number...
  const rank = Number(associatedResponse);
  return isNaN(rank) ? undefined : rank;
};

/**
 * Returns an object with all selectable fields and their associated metadata info such as column alias, etc
 * @param metadata
 *  The metadata object from the API. This actually is a mapping of datasetAlias to metadata object
 * @returns object
 * @example
 */
export const formatAvailableFields = (
  metadata: Map<string, any>,
  projectId?: string,
  datasetAlias?: string
): object => {
  let columnsArray: any = [];
  var columnToMetadataInfo = {};

  metadata.forEach((metadataObject, key) => {
    const groupName = CRN.fromString(metadataObject.DatasetCrn).resourceId;
    columnsArray.push(
      ...metadataObject.Content?.Properties?.ImmutableAttributes?.Columns.map(
        (obj: { [key: string]: any }) => {
          // const key = `${groupName}.${Object.keys(obj)[0]}`; //Object.keys(obj)[0];
          const columnName = Object.keys(obj)[0];
          const dataType = obj[columnName].DataType;
          let values = obj[columnName].Values;

          // Modify the column name to include the dataset alias too
          const modifiedColumnName = `${groupName}@${columnName}`;
          columnToMetadataInfo[modifiedColumnName] = {
            dataType: dataType,
            nValues: values ? values.length : undefined,
          };

          // if(values === null){
          //   if(projectId && datasetAlias){
          //     console.log({projectId,datasetAlias,columnName})
          //    const newColumns = await ProjectAPI.getDistributionForColumns(
          //         projectId,
          //         datasetAlias,
          //         [columnName],
          //         undefined
          //     )

          //     for (let index = 0; index < newColumns.length; index++) {
          //       const element = newColumns[index];

          //       if(element && element[0] ){
          //         values.push(element[0].Value)
          //       }
          //     }
          //   }
          // }

          if (
            values === null &&
            dataType !== 'DateType' &&
            dataType !== 'TimestampType'
          ) {
            // Make sure we don't include columns that have more than 1000 values or that have no values...
            // return null;
          }

          // Sort values...
          if (values && values.length > 0) {
            (values as any[]).sort();
          }

          let typeComparison = '';
          let MinfieldSetting = 0;
          let MaxfieldSetting = 0;
          let returnFormat = {};

          let operatorsArray: string[] = [];
          let listValues: { value: string; title: string }[] = [];

          if (
            values &&
            values.some((value: { [key: string]: any }) => value === null)
          ) {
            const nonNullValues = values.filter(
              (value: { [key: string]: any }) => value !== null
            );
            if (
              dataType === 'IntegerType' ||
              dataType === 'FloatType' ||
              dataType === 'DoubleType' ||
              dataType === 'LongType'
            ) {
              MinfieldSetting = Math.min(...nonNullValues);
              MaxfieldSetting = Math.max(...nonNullValues);
            }
            listValues = nonNullValues.map((value: { [key: string]: any }) => ({
              value,
              title: String(value),
            }));
            listValues.push({ value: 'null', title: 'blank' });
          } else if (values) {
            listValues = values.map((value: { [key: string]: any }) => ({
              value,
              title: String(value),
            }));
            listValues.push({ value: 'null', title: 'blank' });
          }

          if (dataType === 'StringType') {
            operatorsArray = [
              'select_equals',
              'select_not_equals',
              'select_any_in',
              'select_not_any_in',
            ];
            typeComparison = 'select';
          } else if (
            dataType === 'IntegerType' ||
            dataType === 'FloatType' ||
            dataType === 'DoubleType' ||
            dataType === 'LongType'
          ) {
            operatorsArray = [
              'equal',
              'not_equal',
              'less',
              'less_or_equal',
              'greater',
              'greater_or_equal',
              'any_in',
              'not_any_in',
            ];
            typeComparison = 'number';
          } else if (dataType === 'DateType' || dataType === 'TimestampType') {
            operatorsArray = [
              'between',
              'equal',
              'not_equal',
              'less',
              'less_or_equal',
              'greater',
              'greater_or_equal',
              'any_in',
            ];
            typeComparison = 'date';
          } else {
            console.log('We have other types here');
          }

          if (dataType === 'StringType' && obj.Values !== null) {
            returnFormat = {
              [modifiedColumnName]: {
                label: groupName,
                type: typeComparison,
                operators: operatorsArray,
                valueSources: ['value'],
                fieldSettings: {
                  listValues,
                  min: MinfieldSetting,
                  max: MaxfieldSetting,
                },
              },
            };
          } else if (
            new Set([
              'IntegerType',
              'FloatType',
              'DoubleType',
              'LongType',
              'DecimalType',
            ]).has(dataType) &&
            obj.Values !== null
          ) {
            returnFormat = {
              [modifiedColumnName]: {
                label: groupName,
                type: typeComparison,
                operators: operatorsArray,
                valueSources: ['value'],
                fieldSettings: {
                  listValues,
                  min: MinfieldSetting,
                  max: MaxfieldSetting,
                },
              },
            };
          } else if (new Set(['DateType', 'TimestampType']).has(dataType)) {
            returnFormat = {
              [modifiedColumnName]: {
                label: groupName,
                type: typeComparison,
                operators: operatorsArray,
                valueSources: ['value'],
                fieldSettings: {
                  dateFormat: 'YYYY-MM-DDTHH:mm:ss.sssZ',
                  validateValue: (
                    val: string,
                    fieldSettings: DateTimeFieldSettings
                  ) => {
                    const dateVal = moment(val, fieldSettings.valueFormat);
                    return dateVal.isValid() ? null : 'Invalid date';
                  },
                },
              },
            };
          }
          return returnFormat;
        }
      ).filter((item: { [key: string]: any }) => item !== null)
    );
  });

  // Add another set of labels where we'll process and remove them from the actual display list...
  // Go through each dataset, get the Aliases (if there are any, and add the alias for use).
  const columnAliases: any[] = [];

  metadata.forEach((metadataInfo: any) => {
    const datasetCrnString = metadataInfo.DatasetCrn;
    const datasetId = CRN.fromString(datasetCrnString).resourceId;

    for (const [columnName, labelEntries] of Object.entries(
      metadataInfo.Content.Properties.MutableAttributes.Columns
    )) {
      const formattedLabelEntries = labelEntries as { [key: string]: string };
      // console.log({formattedLabelEntries})
      const hasAlias = 'Alias' in formattedLabelEntries;

      const customKey = `@${datasetId}@${columnName}`;

      if (hasAlias) {
        const alias = formattedLabelEntries['Alias'];

        columnAliases.push({
          [customKey]: {
            label: alias,
            dataType: columnToMetadataInfo[`${datasetId}@${columnName}`]
              ? columnToMetadataInfo[`${datasetId}@${columnName}`]['dataType']
              : 'unknown',
            numberOfUniqueValues: columnToMetadataInfo[
              `${datasetId}@${columnName}`
            ]
              ? columnToMetadataInfo[`${datasetId}@${columnName}`]['nValues'] ||
                0
              : 0,
            type: 'select',
            operators: ['select_equals'],
            valueSources: ['value'],
          },
        });
      } else {
        // console.log({ columnName, labelEntries,hasAlias,customKey });
        columnAliases.push({
          [customKey]: {
            label: undefined,
            dataType: columnToMetadataInfo[`${datasetId}@${columnName}`]
              ? columnToMetadataInfo[`${datasetId}@${columnName}`]['dataType']
              : 'unknown',
            numberOfUniqueValues: columnToMetadataInfo[
              `${datasetId}@${columnName}`
            ]
              ? columnToMetadataInfo[`${datasetId}@${columnName}`]['nValues'] ||
                0
              : 0,
            type: 'select',
            operators: ['select_equals'],
            valueSources: ['value'],
          },
        });
      }
    }
  });

  let columnAliasesAsObject = columnAliases.reduce((acc, item) => {
    let key = Object.keys(item)[0];
    acc[key] = item[key];
    return acc;
  }, {});

  for (let index = 0; index < columnsArray.length; index++) {
    let element = columnsArray[index];
    let data = element[Object.keys(element)[0]];
    if (data?.fieldSettings?.listValues) {
      let dataType = columnAliasesAsObject['@' + Object.keys(element)[0]];

      if (
        dataType &&
        dataType.numberOfUniqueValues === 0 &&
        dataType.dataType === 'StringType'
      ) {
        if (data?.fieldSettings?.listValues?.length === 0) {
          data = {
            type: 'text',
            label: data.label,
            valueSources: 'value',
            operators: ['equal', 'not_equal', 'select_any_in'],
          };

          element[Object.keys(element)[0]] = data;
          columnsArray[index] = element;
        }
      }
    }
  }

  // console.log(columnsArray)
  // Merge columns array with columnAliases..
  const mergedColumns = [...columnsArray, ...columnAliases];

  const columnsObject: { [key: string]: any } = mergedColumns?.reduce(
    (acc: { [key: string]: any }, curr: { [key: string]: any }) => {
      const columnName = Object.keys(curr)[0];
      acc[columnName] = curr[columnName];
      return acc;
    },
    {}
  );

  const sortedKeys = Object.keys(columnsObject).sort();

  const sortedObject: { [key: string]: any } = {};

  sortedKeys.forEach((key) => {
    if (
      key !== undefined &&
      key !== null &&
      key !== '' &&
      key !== 'null' &&
      key !== 'undefined'
    ) {
      sortedObject[key] = columnsObject[key];
    }
  });

  return sortedObject;
};

export const addMissingColumns = async (
  metadata: Map<string, any>,
  projectId?: string,
  datasetAlias?: string
): Promise<Map<string, any>> => {
 
  const newObj: object = {};
  const key = datasetAlias;
  metadata.forEach((metadataObject, key) => {
    newObj[key] = metadataObject;
  });

  let columns: columnDataArrType =
    newObj[key].Content.Properties.ImmutableAttributes.Columns;

  let ApiCalls = [];

  for (let index = 0; index < columns.length; index++) {
    let element = columns[index];
    if (element[Object.keys(element)[0]].Values === null) {
      const columnName = Object.keys(element)[0];
      if (
        projectId && 
        datasetAlias && 
        !columnName.endsWith("ID") &&
        !columnName.endsWith("Id") && 
        !columnName.endsWith("DateTime")
      ) {
        const newColumns = ProjectAPI.getDistributionForColumns(
          projectId,
          datasetAlias,
          [columnName],
          undefined
        );
        ApiCalls.push(newColumns);
      }
    }
  }

  const results = await Promise.all(
    ApiCalls.map((p) => p.catch((e: any) => e))
  );
  const validResults: validResultsArrType = results.filter(
    (result) => !(result instanceof Error)
  );

  const columnNameAndIndex: columnNameAndIndexType = columns.reduce(
    (acc: Record<string, number>, crr, index) => {
      acc[Object.keys(crr)[0]] = index;

      return acc;
    },
    {}
  );


  for (let index = 0; index < validResults.length; index++) {
    const result = validResults[index];

    const currentColumnName = result[0][0].Column.replace(datasetAlias + '_', '')

    const allColumnsValuesArr = result.map((item)=> item[0].Value);

    columns[columnNameAndIndex[currentColumnName]][currentColumnName].Values = allColumnsValuesArr;
  }

  newObj[key].Content.Properties.ImmutableAttributes.Columns = columns;

  const newMap = new Map();
  newMap.set(key,newObj[key])


  return newMap
};
