import React, { useEffect, useMemo, useState } from 'react';
import * as echarts from 'echarts';
import {
  BenchmarkDataPoint,
  BenchmarkDataPointChartType,
} from './datamodels/BenchmarkDataPoint';
import { ChartBaseProps } from './ChartBase';

import { Center, useMantineTheme } from '@mantine/core';
import darkTheme from './themes/dark-theme.json';
import { useSegmentsData } from '@apis/hooks/useSegmentsData';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { useAnalysisStore } from '@stores/AnalysisStore';
import { useCurrentAnalysis } from '@hooks/useCurrentAnalysis';
import { useTopicStore } from '@stores/TopicStore';
import { useQuoteStore } from '@stores/QuoteStore';
import { useCurrentSummary } from '@hooks/useCurrentSummary';
import { useSummaryData } from '@apis/hooks/useSummaryData';
import { useElementSize } from '@mantine/hooks';
import APIErrorMessage from '@components/APIErrorMessage';

echarts.registerTheme('dark', darkTheme);

/**
 * Shap Drivers Power Bar Plot is a simple bar plot designed to show the breakdown of drivers by feature, with a limit on the number of values shown. Will also include a tooltip for nX times more powerful than average driver.
 * @param props
 * @returns
 */
const SegmentsBarPlot = (props) => {
  const theme = useMantineTheme();
  
  const currentProject = useCurrentProject();
  const currentAnalysis = useCurrentAnalysis();

  const activeTopics = useAnalysisStore((s) => s.activeTopics);
  const activeThemes = useAnalysisStore((s) => s.activeThemes);
  const focalPopulation = useAnalysisStore((s) => s.focalPopulation);
  
  //Topic Store
  const focalTopic = useTopicStore((s) => s.focalTopic);

  const segmentsData = useAnalysisStore(
    (s) => s.segmentsData
  );
  const setSegmentsData = useAnalysisStore((s) => s.setSegmentsData);

  const currentSummary = useCurrentSummary();
  const { data: _summaryData } = useSummaryData(currentSummary);
  const summaryData = useMemo(() => {
    const analisys = (currentSummary?.analyses ?? []).find(a => a.id === currentAnalysis.id);
    if(!currentSummary || !analisys || analisys.focalPopulation !== focalPopulation) {
      return null
    }
    return _summaryData;
  }, [currentAnalysis?.id, currentSummary, focalPopulation, focalTopic, _summaryData])

  const [ chartInstance, setChartInstance ] = useState<echarts.EChartsType | undefined>();
  const { ref: referenceComponent, width, height } = useElementSize();
  useEffect(() => chartInstance?.resize(), [width, height])

  const {
    data: loadedSegmentsData,
    isLoading,
    error,
    isError
  } = useSegmentsData(
    currentProject,
    currentAnalysis,
    focalPopulation,
    focalTopic
  );

  useEffect(() => {
    if (loadedSegmentsData) {

      let sortedData = loadedSegmentsData.sort((a, b) => {
        if (a.incidence < b.incidence) {
            return -1;
        }
        if (a.incidence > b.incidence) {
            return 1;
        }
        return 0;
    })
      
    setSegmentsData(sortedData);
    }
  }, [loadedSegmentsData]);

  // const props.data: BenchmarkDataPoint[] = useMemo(() => {
      // return segmentsData.sort((a, b) => {
      //     if (a.incidence < b.incidence) {
      //         return -1;
      //     }
      //     if (a.incidence > b.incidence) {
      //         return 1;
      //     }
      //     return 0;
      // })
  // }, [props.data]);

  // MARK: - State to manage intermediate brush selection...
  var selectedRangeDatapoints: BenchmarkDataPoint[] = [];

  const addExcludeKeepGraphic = (
    chart: any,
    x: number,
    y: number,
    formattedDataPoint: BenchmarkDataPoint
  ) => {
    // Create a custom graphic element to show the box and buttons

    var boxGroup = {
      type: 'group',
      left: x - 75,
      top: y - 40,
      children: [
        {
          type: 'rect',
          left: '-50',
          top: '-15',
          z: 100,
          shape: {
            width: 125,
            height: 25,
            r: 2,
          },
          style: {
            fill: '#fff',
            stroke: '#999',
            lineWidth: 1,
          },
        },
        {
          type: 'text',
          left: '-35',
          top: '-10',
          z: 100,
          style: {
            text: 'Exclude',
            textAlign: 'center',
            textFill: '#333',
            fontSize: 14,
          },
          onclick: function () {
            // Add your code here to exclude the selected point
            props.onPointExcluded && props.onPointExcluded(formattedDataPoint);
          },
        },
      ],
    };

    // Add the custom graphic element to the chart
    chart.setOption({
      graphic: formattedDataPoint ? boxGroup : null,
    });
  };

  const addExcludeKeepGraphicToRange = (
    chart: any,
    selectedRange: any[],
    selectedPoints: any[]
  ) => {
    // Create a custom graphic element to show the box and buttons
    const [leftMostX, rightMostX] = selectedRange[0];
    const [leftMostY, rightMostY] = selectedRange[1];
    const width = rightMostY - leftMostX;
    var boxGroup = {
      type: 'group',
      x: rightMostX - 100, // Adjusted to center the box group horizontally
      y: leftMostY - 12,
      children: [
        {
          type: 'rect',
          left: '-50',
          top: '-15',
          z: 100000,
          shape: {
            width: 150,
            height: 25,
            r: 2,
          },
          style: {
            fill: '#fff',
            stroke: '#999',
            lineWidth: 1,
          },
        },
        {
          type: 'text',
          left: '-35',
          top: '-10',
          z: 100000,
          style: {
            text: 'Exclude',
            textAlign: 'center',
            textFill: '#333',
            fontSize: 14,
            cursor: 'pointer',
          },
          onclick: function () {
            props.onPointsExcluded && props.onPointsExcluded(selectedPoints);
          },
        },
        {
          type: 'text',
          left: '20',
          top: '-10',
          z: 1000000,
          style: {
            text: 'Keep Only',
            textAlign: 'center',
            textFill: '#333',
            fontSize: 14,
            cursor: 'pointer !important',
          },
          onclick: function () {
            props.onPointsKeepOnly && props.onPointsKeepOnly(selectedPoints);
          },
        },
      ],
    };

    // Add the custom graphic element to the chart
    chart.setOption({
      graphic: boxGroup,
    });
  };

  const removeExcludeKeepGraphic = (chart: any) => {
    // Remove the custom graphic element from the chart
    const currentOptions = chart.getOption();

    currentOptions.graphic = null;

    chart.setOption(currentOptions, true);
  };

  useEffect(() => {
    const chartElement = document.getElementById('myChart');
    if (!chartElement) {
      return;
    }
    const chart = echarts.init(chartElement, theme.colorScheme);

    if (props.dataIsEmpty && !props.dataIsLoading) {
      chart.setOption({
        title: {
          text: 'No Data Available',
          subtext:
            'This may be due to a filter being applied, or because the data is not available yet',
          top: 'center',
          textStyle: {
            fontSize: 20,
          },
          subtextStyle: {
            fontSize: 16,
          },
        },
      });
    } else {
      const option: any = {
        xAxis: {
          type: 'value',
          name: 'Incidence',
          nameTextStyle: {
            fontWeight: 'bold',
          },
          nameGap: 40,
          nameLocation: 'middle',
          axisLabel: {
            color: 'black',
            formatter: (value: number) => {
              return Math.floor(value) + '%';
            },
          },
        },
        yAxis: {
          type: 'category',
          data: segmentsData.map((d: BenchmarkDataPoint) => d.populationName),
          nameTextStyle: {
            fontWeight: 'bold',
          },
          nameGap: 40,
          nameLocation: 'middle',
          axisLabel: {
            color: 'black',
            formatter: function (value) {
              // Truncate the label to 10 characters
              var truncatedLabel =
                value.length > 15 ? value.substr(0, 15) + '...' : value;
              return truncatedLabel;
            },
          },
        },
        series: [
          {
            data: segmentsData.map((d: BenchmarkDataPoint) => d.incidence * 100),
            type: 'bar',
            itemStyle: {
              borderRadius: [0, 5, 5, 0],
              // color: function (params: any) {
              //     return colorInterpolation(params.data / 100, maxNegativeIncidence);
              // }
            },
            label: {
              show: true,
              position: 'right',
              formatter: (params: any) => {
                const formattedPoint: BenchmarkDataPoint = segmentsData[params.dataIndex as number];
                const dataPoint = formattedPoint.incidence;
                return `${(100 * dataPoint).toFixed(1)}%`
              },
            },
          },
        ],

        tooltip: {
          trigger: 'item',
          formatter: (params: any) => {
            // Format the tooltip to have the following:
            // 1. The name of the population
            // 2. The percentage of employees mentioning the population
            // 3. The percentage of mentions favorable

            // Get the data
            const dataIndex: number = params.dataIndex;
            const formattedPoint: BenchmarkDataPoint = segmentsData[dataIndex];
            // Return as a HTML box with the data inside
            return `
                        <div>
                            <div>Segment: <b>${
                              formattedPoint.populationName
                            }</b></div
                            <div>Incidence: <b>${(
                              100 * formattedPoint.incidence
                            ).toFixed(1)}%</b></div>   
                            <div>Sentiment: <b>${(
                              100 * formattedPoint.sentiment
                            ).toFixed(1)}%</b></div>   
                            <div>Topic: <b>${
                              formattedPoint.topicUserFriendlyName
                            }</b></div>
                            <div># Mentions: <b>${formattedPoint.vopicCount.toLocaleString()}</b></div>
                            <div># Responses: <b>${formattedPoint.rowCount.toLocaleString()}</b></div>
                        </div>
                    `;
          },
        },

        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              pixelRatio: 4,
            },
          },
        },
        
        title: [
          {
            text: props.title ? props.title : 'Sub-Culture Mentions',
            x: 'center',
            textStyle: {
              fontSize: 16,
            },
          },
        ],

        brush: {
          toolbox: ['rect', 'clear'],
          transformable: false,
          saveAsImage: {},
          inBrush: {
            opacity: 1,
            borderRadius: 3,
            borderColor: 'black',
          },
          outOfBrush: {
            color: 'gray',
            opacity: 0.2,
          },
        },

        interactive: true,
        animationEasing: 'elasticOut',
        animationDelay: 150,

        dataZoom: [
          {
            type: 'inside',
            id: 'insideY',
            yAxisIndex: 0, // This targets the yAxis for vertical scrolling
            filterMode: 'none',
            zoomLock: false,
            start: 100,
            end: 100 - (15 / segmentsData.length) * 100,
            preventDefaultMouseMove: false,
            zoomOnMouseWheel: false,
            moveOnMouseMove: true,
            moveOnMouseWheel: true,
          },
        ],

        grid: {
          left: '15%',
        },
      };

      // Get the chart element
      chart.setOption(option);

      // Add an onClick event to the chart...
      chart.on('click', (params: any) => {
        if (params.componentType === 'series') {
          console.log(params);

          const x = params.event.offsetX;
          const y = params.event.offsetY;
          console.log(`x: ${x}, y: ${y}`);

          const selectedPoint = params.value;
          const dataIndex = params.dataIndex;

          const formattedSelectedPoint: BenchmarkDataPoint =
            segmentsData[dataIndex];

          // addExcludeKeepGraphic(chart, x, y, formattedSelectedPoint);

          props.onPointSelected &&
            props.onPointSelected(formattedSelectedPoint);
        }
      });

      chart.on('brushSelected', (params: any) => {
        if (params.batch === null) {
          return;
        }

        var selectedDatapoints: BenchmarkDataPoint[] = [];
        var brushComponent = params.batch[0];
        for (var sIdx = 0; sIdx < brushComponent.selected.length; sIdx++) {
          var dataIndexes = brushComponent.selected[sIdx].dataIndex;
          console.log(dataIndexes);
          dataIndexes.forEach((dataIndex: number) => {
            var dataPoint = segmentsData[dataIndex];
            selectedDatapoints.push(dataPoint);
          });
        }
        selectedRangeDatapoints = selectedDatapoints;
      });

      chart.on('brushEnd', (params: any) => {
        if (params.areas.length === 0) {
          props.onPointsDeselected && props.onPointsDeselected();
          selectedRangeDatapoints = [];
          return;
        }

        const selectedRange = params.areas[0].range;
        const seriesIndex = 0;
        const chartOptions = chart.getOption();
        if (chartOptions && chartOptions.series) {
          // addExcludeKeepGraphicToRange(
          //   chart,
          //   selectedRange,
          //   selectedRangeDatapoints
          // );

          // Return the points to the caller
          props.onPointsSelected &&
            props.onPointsSelected(selectedRangeDatapoints);
        }
      });

      chart.getZr().on('click', (event: any) => {
        if (!event.target && event.componentType !== 'graphic') {
          // Call internal method to update the chart such that there are no selected points
          chart.dispatchAction({
            type: 'unselect',
            seriesIndex: 0,
            dataIndex: Array.from({ length: segmentsData.length }, (v, i) => i),
          });

          // Remove any graphic from the chart
          removeExcludeKeepGraphic(chart);

          // Call function to update that no points are selected
          props.onPointsDeselected && props.onPointsDeselected();
        }
      });

      if (isLoading) {
        chart.showLoading('default', {
          text: 'Loading...',
          fontSize: 16,
          color: '#4589df',
          textColor: '#000',
          zlevel: 0,
        });
      } else {
        chart.hideLoading();
      }
    }
    window.addEventListener('resize', () => {
      chart.resize();
    });

    return () => {
      chart.dispose();
    };
  }, [
    segmentsData,
    theme.colorScheme,
    isLoading
  ]);


  if (isLoading === false && isError && error) {
    return (
      <Center style={{ height: '100%' }}>
        <APIErrorMessage response={error} />
      </Center>
    );
  }

  return <div id="myChart" style={{ width: '100%', height: '100%' }}></div>;
};

export default SegmentsBarPlot;
