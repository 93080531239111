import React, { useCallback, useMemo } from 'react';
import { TextInput } from '@mantine/core';
import debounce from 'lodash/debounce';

interface MantineTextInputProps {
  listValues: any[];
  value: any;
  setValue: (value: any) => void;
  allowCustomValues: boolean;
  readonly: boolean;
  placeholder: string;
  customProps: any;
}

export const MantineTextInput: React.FC<MantineTextInputProps> = (
  props: MantineTextInputProps
) => {
  // Memoize the debounced function to prevent it from being recreated on every render
  // const debouncedOnChange = useCallback(() => 
  //   debounce((e: React.ChangeEvent<HTMLInputElement>) => {
  //     props.setValue(e.target.value);
  //   }, 300), [props.setValue]
  // );

  return (
    <TextInput
      size="xs"
      value={props.value}
      onChange={(e)=>{
        props.setValue(e.target.value)
      }}
      placeholder="Enter Value"
    />
  );
};