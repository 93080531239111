import { create } from 'zustand';

interface RouteStore {
  organizationId?: number;
  rtId?: string;
  setParams: (
    organizationId: number | undefined,
    rtId: string | undefined
  ) => void;
}

export const useRouteStore = create<RouteStore>()((set, get) => ({
  organizationId: undefined,
  rtId: undefined,
  setParams: (organizationId: number | undefined, rtId: string | undefined) =>
    set({ organizationId, rtId }),
}));
