import { FC, useMemo } from 'react';
import {
  Card,
  Checkbox,
  Group,
  HoverCard,
  Text,
} from '@mantine/core';
import Fuse from 'fuse.js';
import { Analysis } from '@hooks/useProjectAnalyses';
import AnalysisStatusIcon from '@components/AnalysisStatusIcon';
import AnalysisTypeIcon from '@components/AnalysisTypeIcon';
import { Highlight } from '@mantine/core';
import { useHighlights } from '@hooks/useHighlights';
import { useSumaryEditionStore } from '@stores/SummaryEditionStore';
import { AnalysisType } from '@stores/AnalysisEditionStore';

interface AnalysisItemProps {
    analysis: Analysis
    matches?: readonly Fuse.FuseResultMatch[]
}

export const AnalysisItem: FC<AnalysisItemProps> = ({ analysis, matches }) => {
  const analysesInSummary = useSumaryEditionStore(s => s.analysesInSummary)
  const switchAnalysis = useSumaryEditionStore(s => s.switchAnalysis)
  const highlightName = useHighlights('name', matches);
  const highlightPopulations = useHighlights('populations', matches);

  const isSelected = useMemo(() => !!analysesInSummary.find(a => a.id === analysis.id), [analysesInSummary, analysis.id])
  return (
    <Card
      py={0}
      radius="sm"
      withBorder
      style={{
        height: 40,
        overflow: "hidden",
        cursor: analysis.populations.length > 0 ? "pointer" : "not-allowed"
      }}
      onClick={analysis.populations.length > 0 ? () => switchAnalysis({
        id: analysis.id,
        name: analysis.name,
        type: analysis.type as AnalysisType,
        populations: analysis.populations,
        focalPopulation: analysis.populations[0],
        focalPopulationDefinition: analysis.populationDefinitions[analysis.populations[0]],
        benchmark: (analysis as any).internalBenchmark || (analysis as any).populationBenchmark, 
      }) : undefined}
    >
      <Group spacing={4} style={{height: "100%"}} noWrap>
        <AnalysisStatusIcon status={analysis.status} tooltipProps={{position: "right"}} />
        <AnalysisTypeIcon type={analysis.type} tooltipProps={{position: "right"}} />
        <Text truncate style={{flexGrow: 1}}>
          <Highlight highlight={highlightName}>{analysis.name}</Highlight>
        </Text>
        { analysis.populations.length > 0 && <HoverCard shadow="md" withinPortal openDelay={400}>
          <HoverCard.Target>
            <Text style={{ whiteSpace: "nowrap"}} mr="xs" size="xs" c="blue">{`${analysis.populations.length} populations`}</Text>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            { analysis.populations.sort((a , b) => a.localeCompare(b)).map((p,i) => <Text key={p + i} size="sm"><Highlight highlight={highlightPopulations}>{p}</Highlight></Text>)}
          </HoverCard.Dropdown>
        </HoverCard> }
        
        <Checkbox checked={isSelected} readOnly/>
      </Group>
    </Card>
  );
};