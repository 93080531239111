import React, { forwardRef } from 'react';
import { Group, Avatar, Text, Select } from '@mantine/core';
import { Utils } from '@react-awesome-query-builder/ui';
import { group } from 'console';

interface MantineSelectProps {
  listValues: any[];
  value: any;
  setValue: (value: any) => void;
  allowCustomValues: boolean;
  readonly: boolean;
  placeholder: string;
  customProps: any;
}

interface ItemProps {
  value: any;
  label: any;
  group?: any;
}

export const MantineSelect: React.FC<MantineSelectProps> = (
  props: MantineSelectProps
) => {
  const formattedData = props?.listValues?.map((item: any) => {
    return {
      label: item.title,
      value: item.value,
      group: item.group,
    };
  });
  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ value, label, ...others }: ItemProps, ref) => {
      if (value === 'null') {
        return (
          <div
            ref={ref}
            {...others}
            style={{ background: '#cbebff', color: '#000' }}
          >
            <Text size="sm">{label}</Text>
          </div>
        );
      } else {
        return (
          <div ref={ref} {...others}>
            <Text size="sm">{label}</Text>
          </div>
        );
      }
    }
  );
  const onChange = (value: string) => {
    if (value === null) return;
    props.setValue(value);
  };

  return (
    <Select
      size="xs"
      value={props.value}
      onChange={onChange}
      itemComponent={SelectItem}
      hoverOnSearchChange
      data={formattedData}
      placeholder="Select"
      searchable
      styles={{
        dropdown: {
          zIndex: 100000,
        },
      }}
    />
  );
};
