import { FC, ReactNode } from 'react';
import { Container, Flex, Paper } from '@mantine/core';
import Breadcrumbs from '@components/Breadcrumbs';

interface SectionWrapperProps {
  children: ReactNode;
  isFullHeight?: boolean;
  showBreadcrumbs?: boolean;
  rightComponent?: ReactNode;
}

const SectionWrapper: FC<SectionWrapperProps> = ({
  children,
  isFullHeight,
  showBreadcrumbs,
  rightComponent,
}) => {
  var containerStyle = {};
  var paperStyle = {};
  if (isFullHeight) {
    containerStyle = {
      height: '100%',
      display: 'flex', // Add display flex
      flexDirection: 'column', // Add flex direction
    };

    paperStyle = {
      flex: 1, // Add flex 1
      display: 'flex', // Add display flex to paper
      flexDirection: 'column', // Add flex direction to paper
      alignItems: 'stretch',
    };
  }

  return (
    <Container fluid style={containerStyle} p="lg">
      {(showBreadcrumbs || rightComponent) && (
        <Flex mb="md" justify="space-between" align="center">
          {showBreadcrumbs ? <Breadcrumbs /> : <div></div>}
          <Flex style={{ height: 16 }} align="center">
            {rightComponent}
          </Flex>
        </Flex>
      )}
      <Paper shadow="sm" p="md" style={paperStyle}>
        {children}
      </Paper>
    </Container>
  );
};

export default SectionWrapper;
