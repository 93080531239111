import React, { Component, useCallback, useMemo, useState } from 'react';

// >>>
import type {
  JsonGroup,
  Config,
  ImmutableTree,
  BuilderProps,
  Fields,
} from '@react-awesome-query-builder/ui';
import {
  Utils as QbUtils,
  Query,
  Builder,
  BasicConfig,
} from '@react-awesome-query-builder/ui';
import { MantineWidgets, MantineConfig } from './mantineQueryBuilder';
import { useMantineTheme } from '@mantine/core';
import '@react-awesome-query-builder/ui/css/styles.css';
import '../components/mantineQueryBuilder/style.light.css';
const InitialConfig = MantineConfig;

export interface AvailableFields {
  [key: string]: {
    label: string;
    type: string;
    valueSources: string[];
    fieldSettings: {
      listValues: { value: string; title: string }[];
      min?: number;
      max?: number;
      dateFormat?: string;
      validateValue?: any;
    };
    operators: string[];
    customWidgets: any;
  };
}

export interface QueryBuilderProps {
  focalPopulation: string;
  initialDefinition?: { [key: string]: any };
  onDefinitionUpdate: (populationName: string, definition: any) => void;
  metadata: { [key: string]: any };
  datasets: { [key: string]: any };
  availableFields: { [key: string]: any };
  themeClass?: string;
  show?: boolean;
}

interface QueryBuilderState {
  tree?: ImmutableTree;
  config: Config;
}

function deepEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 !== 'object' ||
    obj1 === null ||
    typeof obj2 !== 'object' ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key)) {
      return false;
    }
    if (!deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

// You can load query value from your backend storage (for saving see `Query.onChange()`)

function isPlainObject(obj: any): boolean {
  if (typeof obj !== 'object' || obj === null) return false;
  let proto = obj;
  while (Object.getPrototypeOf(proto) !== null) {
    proto = Object.getPrototypeOf(proto);
  }
  return Object.getPrototypeOf(obj) === proto;
}

function replaceColumnWithVar(logic: any): object {
  if (isPlainObject(logic)) {
    const newLogic: any = {}; // Create a new object that is extensible
    for (let key in logic) {
      if (key === 'Column') {
        const columnName = logic[key];
        const datasetAlias = logic['Dataset'].replace('DS_', '');

        newLogic['var'] = `${datasetAlias}@${columnName}`;
      } else if (key !== 'Dataset') {
        newLogic[key] = replaceColumnWithVar(logic[key]); // Recursively copy the properties to the new object, excluding "Dataset"
      }
    }
    return newLogic;
  } else if (Array.isArray(logic)) {
    return logic.map((item) => replaceColumnWithVar(item));
  }

  return logic;
}

const withMantineTheme = (WrappedComponent: any) => {
  return (props: any) => {
    const themeClass = useMantineTheme().colorScheme;
    return <WrappedComponent themeClass={themeClass} {...props} />;
  };
};

class QueryBuilder extends Component<
  QueryBuilderProps,
  QueryBuilderState
> {
  formatDefinition(rawDefinition?: any): Object | undefined {
    if (
      rawDefinition === undefined ||
      Object.keys(rawDefinition).length === 0
    ) {
      return undefined;
    }
    const definition = replaceColumnWithVar(rawDefinition);
    return definition;
  }

  config = {
    ...InitialConfig,
  };

  constructor(props: QueryBuilderProps) {
    super(props);

    // console.log("FROM CONSTRUCTOR: ", this.props.availableFields)
    this.config = {
      ...InitialConfig,
      fields: this.props.availableFields,
    };
    const formattedDefinition = this.formatDefinition(this.props.initialDefinition);
    if (
      this.props.initialDefinition &&
      Object.keys(this.props.initialDefinition).length > 0
    ) {
      this.state = {
        tree: QbUtils.loadFromJsonLogic(formattedDefinition, this.config),
        config: this.config,
      };
    } else {
      this.state = {
        tree: QbUtils.loadTree({
          id: QbUtils.uuid(),
          type: 'group',
        }),
        config: this.config,
      };
    }
  }

  componentDidMount(): void {
    // console.log("FROM componentDidMount: ", this.props.availableFields)
    this.config = {
      ...InitialConfig,
      fields: this.props.availableFields,
    };
    this.setState({ config: this.config });
  }

  componentDidUpdate(prevProps: Readonly<QueryBuilderProps>,): void {
    // console.log("FROM componentDidUpdate: ", this.props.availableFields)
    if (!deepEqual(prevProps.availableFields, this.props.availableFields)) {
      this.config = {
        ...InitialConfig,
        fields: this.props.availableFields,
      };
      this.setState({ config: this.config });
    }
  }

  onChange = (immutableTree: ImmutableTree, config: Config) => {
    this.setState({
      tree: immutableTree,
      config: config,
    });

    const jsonLogicOutput = QbUtils.jsonLogicFormat(immutableTree, config);
    // Replace "var" with "Column" in the logic
    const replaceVarWithColumn = (logic: any) => {
      if (typeof logic === 'object' && logic !== null) {
        for (let key in logic) {
          if (logic[0] && logic[0] instanceof Date) {
            const dateString = logic[0]?.toISOString();
            logic[0] = dateString;
          }

          if (logic[1] && logic[1] instanceof Date) {
            const dateString = logic[1]?.toISOString();
            logic[1] = dateString;
          }

          if (logic[2] && logic[2] instanceof Date) {
            const dateString = logic[2]?.toISOString();
            logic[2] = dateString;
          }

          if (key === 'var') {
            const columnName = logic[key].split('@').pop();
            const datasetAlias = logic[key].split('@')[0];

            logic['Column'] = columnName;
            delete logic[key];
            logic['Dataset'] = `DS_${datasetAlias}`;
          } else {
            replaceVarWithColumn(logic[key]);
          }
        }
      }

      delete jsonLogicOutput.errors;
      delete jsonLogicOutput.data;
    };

    const logicWithoutKey = jsonLogicOutput.logic;
    delete jsonLogicOutput.logic;

    replaceVarWithColumn(logicWithoutKey);

    const reformattedLogic = logicWithoutKey;
    this.props.onDefinitionUpdate(this.props.focalPopulation, reformattedLogic);
  };

  render = () => {
    // console.log("THIS CONFIG: ", this.config.fields)
    document.body.className = this.props.themeClass;
    if (this.props.show !== false) {
      return (
        <div>
          <Query
            {...this.config}
            value={this.state.tree}
            onChange={this.onChange}
            renderBuilder={this.renderBuilder}
          />
        </div>
      );
    } else {
      return <></>;
    }
  };

  renderBuilder = (props: any) => {
    return (
      (
        <div className="query-builder-container">
          <div className="qb-lite" style={{ whiteSpace: 'nowrap' }}>
            <Builder {...props} />
          </div>
        </div>
      )
    )
  };
}


export default QueryBuilder;