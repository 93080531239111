import React from 'react';
import { Anchor, Box, Group, Text } from '@mantine/core';

interface MultiSelectCheckboxFooterProps {
  onDeselectAll: () => void;
  onSelectAll: () => void;
  maxItems?: number;
}

export const MultiSelectCheckboxFooter: React.FC<
  MultiSelectCheckboxFooterProps
> = ({ onDeselectAll, onSelectAll, maxItems }) => {
  return (
    <Box p={'xs'}>
      <Group position="apart">
        <Group>
          <Anchor size="xs" color="red" onClick={onDeselectAll}>
            Deselect all
          </Anchor>
          <Anchor size="xs" onClick={onSelectAll}>
            Select all
          </Anchor>
        </Group>
        {maxItems && (
          <Text size="sm" color="dimmed">
            You may select a maximum of {maxItems} items
          </Text>
        )}
      </Group>
    </Box>
  );
};
