import React from 'react';
import {
  Accordion,
  Alert,
  MantineTheme,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons';
interface APIErrorMessageProps {
  dismissable: boolean;
  onDismiss?: () => void;
  simple?: boolean;
  response: any;
  theme: MantineTheme;
}

class APIErrorMessage extends React.PureComponent<APIErrorMessageProps> {
  isResponseSupported(response: any): boolean {
    // do logic to figure out if we can actually handle the response data...
    if (response !== null && response !== undefined) {
      if (response.hasOwnProperty('response')) {
        if (response.response.hasOwnProperty('data')) {
          if (response.response.data.hasOwnProperty('ExceptionHeader')) {
            if (response.response.data.hasOwnProperty('Message')) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  dismiss() {
    if (this.props.dismissable) {
      if (this.props.onDismiss !== null) {
        this.props.onDismiss!();
      }
    }
  }

  render() {
    if (this.isResponseSupported(this.props.response)) {
      const responseData = this.props.response.response.data;
      if (this.props.simple) {
        var simpleHeader = responseData.ExceptionHeader;
        var simpleMessage = responseData.Message;
        if (responseData.ExceptionHeader === 'Not Authorized Exception') {
          simpleHeader = 'Insufficient Permissions';
          simpleMessage =
            "You're not allowed to perform this action. If you believe this is an error, please contact your administrator.";
        }

        return (
          <>
            <Alert
              icon={<IconAlertCircle size={16} />}
              title={simpleHeader}
              color="red"
              withCloseButton={this.props.dismissable}
              onClose={this.dismiss}
            >
              {simpleMessage}
              <Accordion
                styles={{
                  chevron: {
                    color: 'red',
                  },
                  control: {
                    '&:hover': {
                      backgroundColor: this.props.theme.colors.red[0],
                    },
                    backgroundColor: this.props.theme.colors.red[0],
                  },
                }}
              >
                <Accordion.Item value="details">
                  <Accordion.Control>
                    <Text size={14} color="red">
                      Details
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>{responseData.Message}</Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </Alert>
          </>
        );
      } else {
        return (
          <>
            <Alert
              icon={<IconAlertCircle size={16} />}
              title={responseData.ExceptionHeader}
              color="red"
              withCloseButton={this.props.dismissable}
              onClose={this.dismiss}
            >
              {responseData.Message}
            </Alert>
          </>
        );
      }
    } else if (
      this.props.response.response !== null &&
      this.props.response.response !== undefined
    ) {
      if (this.props.response.response.status === 403) {
        return (
          <>
            <Alert
              icon={<IconAlertCircle size={16} />}
              title="403 Not Authorized"
              color="red"
              withCloseButton={this.props.dismissable}
              onClose={this.dismiss}
            >
              You're not authorized to view the contents of this page, or the
              link you have been provided may have expired.
            </Alert>
          </>
        );
      } else {
        return (
          <Alert
            icon={<IconAlertCircle size={16} />}
            title="Unexpected Error"
            color="red"
            withCloseButton={this.props.dismissable}
            onClose={this.dismiss}
          >
            An unexpected error occured while trying to make your request... If
            you continue to see this error, please contact us.
          </Alert>
        );
      }
    } else if (this.props.response.message === 'Network Error') {
      return (
        <>
          <Alert
            icon={<IconAlertCircle size={16} />}
            title="Network Error"
            color="red"
            withCloseButton={this.props.dismissable}
            onClose={this.dismiss}
          >
            We can't access our services at this time. Please check to ensure
            you're connected to the internet and try again. If you continue to
            see this error, please contact us.
          </Alert>
        </>
      );
    } else {
      return (
        <Alert
          icon={<IconAlertCircle size={16} />}
          title="Unexpected Error"
          color="red"
          withCloseButton={this.props.dismissable}
          onClose={this.dismiss}
        >
          An unexpected error occured while trying to make your request... If
          you continue to see this error, please contact us.
        </Alert>
      );
    }
  }
}

// Higher order component to provide theme
function withMantineTheme(Component) {
  return function WrappedComponent(props) {
    const theme = useMantineTheme();
    return <Component {...props} theme={theme} />;
  };
}

// Wrap your component with the HOC
export default withMantineTheme(APIErrorMessage);
