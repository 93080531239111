import ProjectAPI from '@apis/ProjectAPI';
import {
  Divider,
  Modal,
  Stack,
  Title,
  Text,
  Group,
  Button,
  Grid,
  Checkbox,
  Flex,
  ActionIcon,
  CopyButton,
  Tooltip,
  rem,
  HoverCard,
} from '@mantine/core';
import ProjectModel from '@models/ProjectModel';
import { useCallback, useMemo, useState } from 'react';
import { IconCheck } from '@tabler/icons';
import {
  SharedLink,
  useProjectSharedLinks,
} from '@apis/hooks/useProjectSharedLinks';
import { dateToString } from '@utils/FormatUtils';
import { CreateLinkModal } from './CreateLinkModal';
import { useQueryClient } from '@tanstack/react-query';
import { IconCopy } from '@tabler/icons-react';

interface ShareProjectModalProps {
  opened: boolean;
  onClose: () => void;
  onProjectShared?: () => void;
  project: ProjectModel;
}

export const ShareProjectModal = ({
  opened,
  onClose,
  onProjectShared,
  project,
}: ShareProjectModalProps) => {
  const { data: sharedLinks } = useProjectSharedLinks(project?.projectId);
  const [showCreateLinkModal, setShowCreateLinkModal] = useState(false);

  const onLinkCreated = useCallback(() => {
    setShowCreateLinkModal(false);
  }, []);

  const links = useMemo(
    () => (sharedLinks ?? []).filter((sl) => sl.status === 'ENABLED'),
    [sharedLinks]
  );

  return (
    <Modal
      title={
        <Stack spacing={0}>
          <Title order={3}>{`Shared Links for ${project.title}`}</Title>
          <Text color="dimmed" size="sm">
            Share project data with others outside of your organization
          </Text>
        </Stack>
      }
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      size="lg"
    >
      <CreateLinkModal
        opened={showCreateLinkModal}
        onClose={() => setShowCreateLinkModal(false)}
        onLinkCreated={onLinkCreated}
        project={project}
      />
      <Stack spacing={'xs'}>
        <Group position="apart">
          <Title order={4}>Links</Title>
          {links.length > 0 && (
            <Button size="xs" onClick={() => setShowCreateLinkModal(true)}>
              Create new link
            </Button>
          )}
        </Group>
        <Divider />
        {links.map((sl) => (
          <SharedLinkItem key={sl.id} sharedLink={sl} />
        ))}
        {links.length === 0 && (
          <Stack align="center" mt="md">
            <Text size="sm">This project dont have any share link yet</Text>
            <Button onClick={() => setShowCreateLinkModal(true)}>
              Create a new link
            </Button>
          </Stack>
        )}
        <Group position="right">
          <Button variant="subtle" color="dark" onClick={onClose}>
            Close
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

const SharedLinkItem = ({ sharedLink }: { sharedLink: SharedLink }) => {
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const revokeToken = useCallback(async () => {
    setLoading(true);
    await ProjectAPI.disableToken(sharedLink.model.Tokens[0]);
    await queryClient.refetchQueries({
      queryKey: ['project-shared-links', sharedLink.projectId],
    });
    setLoading(false);
  }, [sharedLink]);

  return (
    <Grid grow>
      <Grid.Col span={12} pb={0}>
        <Text weight={600} size={'sm'}>{`Created ${dateToString(
          sharedLink.createdAt
        )}`}</Text>
      </Grid.Col>
      <Grid.Col span={5} style={{ overflow: 'hidden' }}>
        <Flex style={{ overflow: 'hidden', alignItems: 'center' }}>
          <CopyButton value={sharedLink.link} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? 'Copied' : 'Copy URL'}
                withArrow
                position="top"
              >
                <ActionIcon
                  color={copied ? 'teal' : 'gray'}
                  variant="subtle"
                  onClick={copy}
                  mr={4}
                >
                  {copied ? (
                    <IconCheck style={{ width: rem(16) }} />
                  ) : (
                    <IconCopy style={{ width: rem(16) }} />
                  )}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
          <Stack style={{ overflow: 'hidden' }} spacing={0}>
            <Text size={'xs'}>Link</Text>
            <Text weight={600} size={'xs'} truncate="end">
              {sharedLink.link}
            </Text>
          </Stack>
        </Flex>
      </Grid.Col>
      <Grid.Col span={3}>
        <Text size={'xs'}>Permissions</Text>
        <HoverCard width={400} shadow="md">
          <HoverCard.Target>
            <Text weight={600} size={'xs'}>
              {sharedLink.permissionsResume}
            </Text>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Grid grow mt={0} style={{ whiteSpace: 'nowrap' }}>
              <Grid.Col span={4}>
                <Text size={'sm'} weight={600}>
                  Read
                </Text>
                <Checkbox
                  readOnly
                  mt={5}
                  size="xs"
                  label="Project Data"
                  checked={sharedLink.permissions.read.includes('Data')}
                />
                <Checkbox
                  readOnly
                  mt={5}
                  size="xs"
                  label="Quotes"
                  checked={sharedLink.permissions.read.includes('Quotes')}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Text size={'sm'} weight={600}>
                  Write
                </Text>
                <Checkbox
                  readOnly
                  mt={5}
                  size="xs"
                  label="Analyses"
                  checked={sharedLink.permissions.write.includes('Analyses')}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Text size={'sm'} weight={600}>
                  Download
                </Text>
                <Checkbox
                  readOnly
                  mt={5}
                  size="xs"
                  label="Project Data"
                  checked={sharedLink.permissions.download.includes('Data')}
                />
                <Checkbox
                  readOnly
                  mt={5}
                  size="xs"
                  label="Quotes"
                  checked={sharedLink.permissions.download.includes('Quotes')}
                />
              </Grid.Col>
            </Grid>
          </HoverCard.Dropdown>
        </HoverCard>
      </Grid.Col>
      <Grid.Col span={2}>
        <Text size={'xs'}>Expires</Text>
        <Text weight={600} size={'xs'}>
          {sharedLink.expiration
            ? dateToString(sharedLink.expiration)
            : 'Never'}
        </Text>
      </Grid.Col>
      <Grid.Col
        span={2}
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <Button color="red" size="xs" loading={loading} onClick={revokeToken}>
          Revoke
        </Button>
      </Grid.Col>
      <Grid.Col pt={0} span={12}>
        <Divider />
      </Grid.Col>
    </Grid>
  );
};
