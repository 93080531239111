import { useRouteStore } from '@stores/RouteStore';
import { useNavigate as useOriginalNavigate } from 'react-router-dom';

export const useNavigate = () => {
  const navigate = useOriginalNavigate();
  const organizationId = useRouteStore((s) => s.organizationId);
  const rtId = useRouteStore((s) => s.rtId);

  return (to, options?: any) => {
    // Append query parameters
    if (organizationId && rtId) {
      to = `${to}?organizationId=${organizationId}&rtId=${rtId}`;
    } else if (organizationId) {
      to = `${to}?organizationId=${organizationId}`;
    } else if (rtId) {
      to = `${to}?rtId=${rtId}`;
    }

    navigate(to, options);
  };
};
