import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ActionIcon,
  Anchor,
  Flex,
  Group,
  Indicator,
  Loader,
  Popover,
  ScrollArea,
  Select,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  useMantineColorScheme,
} from '@mantine/core';
import { IconSearch, IconX, IconFilter } from '@tabler/icons';
import FieldItem from '@components/AnalysisEdition/FieldItem';
import { useProjectDatasets } from '@apis/hooks/useProjectDatasets';
import {
  FieldSection,
  DatasetField,
  useDatasetFields,
} from '@hooks/useDatasetFields';
import { SimpleDataType } from '@components/mantineQueryBuilder/widgets/core/MantineFieldSelect';
import { useAnalysisEditionStore } from '@stores/AnalysisEditionStore';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { useCurrentAnalysis } from '@hooks/useCurrentAnalysis';
import Fuse from 'fuse.js';
import VirtualizedList from '@components/VirtualizedList';
import { VirtualItem } from '@tanstack/react-virtual';

const FuseOptions = {
  includeScore: true,
  includeMatches: true,
  minMatchCharLength: 2,
  threshold: 0.2,
  keys: [
    {
      name: 'alias',
      weight: 2,
    },
    {
      name: 'values',
      weight: 0.2,
    },
  ],
};

const FieldsColumn: FC = () => {
  const project = useCurrentProject();
  const analysis = useCurrentAnalysis();
  const { data: datasets } = useProjectDatasets(project);
  const dataset = useAnalysisEditionStore((s) => s.dataset);
  const setAnalysisData = useAnalysisEditionStore((s) => s.setAnalysisData);
  const setDataset = useAnalysisEditionStore((s) => s.setDataset);
  const formattedFields = useDatasetFields(datasets, dataset);
  const fields = useAnalysisEditionStore((s) => s.fields);
  const activeFields = useAnalysisEditionStore((s) => s.activeFields);
  const setFields = useAnalysisEditionStore((s) => s.setFields);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterDataType, setFilterDataType] = useState('all');
  const [filterSection, setFilterSection] = useState('all');
  const colorScheme = useMantineColorScheme().colorScheme;
  const [fuse, setFuse] = useState<Fuse<DatasetField>>(
    new Fuse(fields, FuseOptions)
  );

  const onChangeDataset = useCallback(
    (value: string) => {
      setDataset(value);
      if (project && analysis) {
        setAnalysisData(project, analysis);
      }
    },
    [project, analysis]
  );

  useEffect(() => {
    setFuse(
      new Fuse(
        fields.filter(
          (f) => activeFields.find((u) => u.id === f.id) === undefined
        ),
        FuseOptions
      )
    );
  }, [fields, activeFields]);

  useEffect(() => {
    onChangeDataset(datasets ? datasets.keys().next().value : undefined);
  }, [datasets]);

  useEffect(() => {
    setFields(formattedFields);
  }, [formattedFields]);

  const { dataD, dataR, dataO } = useMemo(() => {
    const sortByAliasOrField = (a, b) => {
      const aValue = a.item.alias || a.item.field;
      const bValue = b.item.alias || b.item.field;

      return aValue.localeCompare(bValue);
    };

    const fdata = searchQuery
      ? fuse.search(searchQuery)
      : fields
          .filter((f) => activeFields.find((u) => u.id === f.id) === undefined)
          .map((i) => ({ item: i, matches: [] }))
          .sort(sortByAliasOrField);
    return {
      dataD:
        filterSection === 'all' || filterSection === FieldSection.DEMOGRAPHIC
          ? fdata.filter((i) => i.item.section === FieldSection.DEMOGRAPHIC)
          : [],
      dataR:
        filterSection === 'all' || filterSection === FieldSection.RESPONSE
          ? fdata.filter((i) => i.item.section === FieldSection.RESPONSE)
          : [],
      dataO:
        filterSection === 'all' || filterSection === FieldSection.OTHER
          ? fdata.filter((i) => i.item.section === FieldSection.OTHER)
          : [],
    };
  }, [fuse, fields, activeFields, searchQuery, filterDataType, filterSection]);

  const SearchIcon =
    searchQuery === '' ? (
      <IconSearch size={14} color="lightgray" />
    ) : (
      <ActionIcon variant="transparent" onClick={() => setSearchQuery('')}>
        <IconX size={14} color="gray" />
      </ActionIcon>
    );

  if (!dataset) {
    return (
      <Stack style={{ flexGrow: 1 }}>
        <Text style={{ minHeight: 50 }}>
          1. Drag fields into the exploration chart and create new columns.
        </Text>
        <Flex mt="xl" justify="center">
          <Loader color="blue" />
        </Flex>
      </Stack>
    );
  }

  return (
    <Stack style={{ flexGrow: 1 }}>
      <Text style={{ minHeight: 50 }}>
        1. Drag fields into the exploration chart and create new columns.
      </Text>

      <Group spacing="xs" mr={10}>
        <TextInput
          size="xs"
          placeholder="Search fields..."
          rightSection={SearchIcon}
          onChange={(e) => setSearchQuery(e.currentTarget.value)}
          value={searchQuery}
          style={{ width: 'calc(100% - 40px)' }}
        />
        <Popover width={280} withArrow shadow="md">
          <Popover.Target>
            <Indicator
              disabled={filterSection === 'all' && filterDataType === 'all'}
            >
              <ThemeIcon
                size={30}
                variant="light"
                color="gray"
                aria-label="Filters"
                style={{ cursor: 'pointer' }}
              >
                <IconFilter
                  style={{ width: '70%', height: '70%' }}
                  stroke={1.5}
                />
              </ThemeIcon>
            </Indicator>
          </Popover.Target>
          <Popover.Dropdown>
            <Select
              size="sm"
              label="Section"
              data={[
                { value: 'all', label: 'All sections' },
                { value: FieldSection.DEMOGRAPHIC, label: 'Demographic' },
                { value: FieldSection.RESPONSE, label: 'Response' },
                { value: FieldSection.OTHER, label: 'Other' },
              ]}
              value={filterSection}
              onChange={(v) => setFilterSection(v)}
            />
          </Popover.Dropdown>
        </Popover>
      </Group>
      {datasets && datasets.size > 1 && (
        <Select
          size="xs"
          mr={12}
          label="Dataset"
          data={Array.from(datasets).map((ds) => ({
            value: ds[0],
            label: ds[1]['DatasetAlias'],
          }))}
          value={dataset}
          onChange={onChangeDataset}
        />
      )}
      <Stack style={{ flexGrow: 1, position: 'relative' }}>
        <ScrollArea style={{ position: 'absolute', inset: 0 }} offsetScrollbars>
          <Stack spacing="xl">
            {dataD.length > 0 && (
              <Stack spacing="sm">
                <Text
                  style={{ position: 'sticky', top: 0, zIndex: 10 }}
                  bg={colorScheme === 'light' ? 'white' : '#1A1B1E'}
                  weight={500}
                  color="grey"
                >
                  DESCRIBE EMPLOYEE
                </Text>
                <VirtualizedList data={dataD} spacing={10} itemHeight={40}>
                  {(vi: VirtualItem) => (
                    <FieldItem
                      key={vi.key}
                      value={dataD[vi.index].item}
                      matches={dataD[vi.index].matches}
                    />
                  )}
                </VirtualizedList>
              </Stack>
            )}

            {dataR.length > 0 && (
              <Stack spacing="sm">
                <Text
                  style={{ position: 'sticky', top: 0, zIndex: 10 }}
                  bg={colorScheme === 'light' ? 'white' : '#1A1B1E'}
                  weight={500}
                  color="grey"
                >
                  EMPLOYEE RESPONSE
                </Text>
                <VirtualizedList data={dataR} spacing={10} itemHeight={40}>
                  {(vi: VirtualItem) => (
                    <FieldItem
                      key={vi.key}
                      value={dataR[vi.index].item}
                      matches={dataR[vi.index].matches}
                    />
                  )}
                </VirtualizedList>
              </Stack>
            )}

            {dataO.length > 0 && (
              <Stack spacing="sm">
                <Text
                  style={{ position: 'sticky', top: 0, zIndex: 10 }}
                  bg={colorScheme === 'light' ? 'white' : '#1A1B1E'}
                  weight={500}
                  color="grey"
                >
                  OTHER
                </Text>
                <VirtualizedList data={dataO} spacing={10} itemHeight={40}>
                  {(vi: VirtualItem) => (
                    <FieldItem
                      key={vi.key}
                      value={dataO[vi.index].item}
                      matches={dataO[vi.index].matches}
                    />
                  )}
                </VirtualizedList>
              </Stack>
            )}
            {dataD.length === 0 && dataR.length === 0 && dataO.length === 0 && (
              <Stack align="center" mt="xl" spacing="xs">
                <Text color="grey">No data</Text>
                <Anchor
                  c="red"
                  onClick={() => {
                    setSearchQuery('');
                    setFilterSection('all');
                    setFilterDataType('all');
                  }}
                >
                  Remove filters
                </Anchor>
              </Stack>
            )}
          </Stack>
        </ScrollArea>
      </Stack>
    </Stack>
  );
};
export default FieldsColumn;
