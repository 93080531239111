import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import SectionWrapper from '../components/SectionWrapper';
import {
  ActionIcon,
  Anchor,
  Button,
  Flex,
  Group,
  Input,
  Skeleton,
  Stack,
  Tabs,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import {
  IconArrowLeft,
  IconDeviceAnalytics,
  IconPlus,
  IconReportSearch,
  IconSearch,
  IconShare2,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import ProjectStatusIcon from '../components/ProjectStatusIcon';
import { dateToString } from '@utils/FormatUtils';
import { DeleteProjectModal } from '../components/DeleteProjectModal';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { useFuse } from '@hooks/useFuse';
import { Analysis, useProjectAnalyses } from '@hooks/useProjectAnalyses';
import { AnalysesTab } from '@components/AnalysisTab';
import { useAppStore } from '@stores/AppStore';
import { NewAnalysisModal } from '@components/AnalysisEdition/NewAnalysisModal';
import { CreateLinkModal } from '@components/CreateLinkModal';
import { useCurrentDatasets } from '@hooks/useCurrentDatasets';
import { useProjectSharedLinks } from '@apis/hooks/useProjectSharedLinks';
import { ShareProjectModal } from '@components/ShareProjectModal';
import { useNavigate } from '@hooks/useNavigate';
import Link from '@components/Link';
import { Summary, useProjectSummaries } from '@hooks/useProjectSummaries';
import { SummaryTab } from '@components/SummaryTab';
import { useCustomAnalyses } from '@hooks/useCustomAnalyses';
import { useDefaultAnalyses } from '@hooks/useDefaultAnalyses';
import { QuotesModal } from '@components/QuotesModal';
import { useQuoteStore } from '@stores/QuoteStore';
import { useTopicStore } from '@stores/TopicStore';

export const FuseAnalysesOptions = {
  includeScore: false,
  includeMatches: true,
  minMatchCharLength: 2,
  threshold: 0.2,
  keys: [
    {
      name: 'name',
      weight: 2,
    },
    {
      name: 'populations',
      weight: 0.2,
    },
    {
      name: 'outcome',
      weight: 0.2,
    },
  ],
};

const FuseSummaryOptions = {
  includeScore: false,
  includeMatches: true,
  minMatchCharLength: 2,
  threshold: 0.2,
  keys: [
    {
      name: 'name',
      weight: 2,
    },
    {
      name: 'analises.name',
      weight: 0.2,
    },
  ],
};

export const ProjectPage: FC = () => {
  const navigate = useNavigate();
  const datasets = useCurrentDatasets();
  
  const [showQuotesModal, setShowQuotesModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCreateLinkModal, setShowCreateLinkModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const currentProject = useCurrentProject();
  const analyses = useProjectAnalyses(currentProject);
  const customAnalyses = useCustomAnalyses(currentProject, analyses);
  const defaultAnalyses = useDefaultAnalyses(currentProject, analyses);
  const summaries = useProjectSummaries(currentProject);
  
  const projectTab = useAppStore(s => s.projectTab);
  const setProjectTab = useAppStore(s => s.setProjectTab);

  const setFocalTopic = useTopicStore(s => s.setFocalTopic);
  const setPopulation = useQuoteStore(s => s.setPopulation);

  const customAnalysesResults = useFuse<Analysis>(customAnalyses, FuseAnalysesOptions, searchQuery);
  const defaultAnalysesResults = useFuse<Analysis>(defaultAnalyses, FuseAnalysesOptions, searchQuery);
  const summariesResults = useFuse<Summary>(summaries, FuseSummaryOptions, searchQuery);

  const [newAnalysisModalIsOpen, setNewAnalysisModalIsOpen] = useState(false);
  const { data: sharedLinks } = useProjectSharedLinks(
    currentProject?.projectId
  );
  
  const openQuotesModal = useCallback(() => {
    setShowQuotesModal(true)
  }, [])

  const shareProject = useCallback(() => {
    if (
      sharedLinks &&
      sharedLinks.filter((sl) => sl.status === 'ENABLED').length > 0
    ) {
      setShowShareModal(true);
    } else {
      setShowCreateLinkModal(true);
    }
  }, [sharedLinks]);

  const onLinkCreated = useCallback(() => {
    setShowCreateLinkModal(false);
  }, [currentProject]);

  const SearchIcon =
    searchQuery === '' ? (
      <IconSearch size={14} color="lightgray" />
    ) : (
      <ActionIcon variant="transparent" onClick={() => setSearchQuery('')}>
        <IconX size={14} color="gray" />
      </ActionIcon>
    );


  const onDeleteProject = useCallback(() => {
    setShowDeleteModal(false);
    navigate('/projects');
  }, [currentProject]);

  if (!currentProject) {
    return (
      <SectionWrapper isFullHeight>
        <Stack spacing={0}>
          <Flex justify="space-between">
            <Skeleton mt="md" height={20} width={100} radius="xl" />
            <Group spacing="xs">
              <Skeleton mt="md" height={20} width={100} radius="xl" />
            </Group>
          </Flex>
        </Stack>
        <Skeleton mt="md" height={20} radius="xl" />
        <Skeleton mt="md" height={400} radius="md" />
      </SectionWrapper>
    );
  }

  return (
    <>
      <NewAnalysisModal
        opened={newAnalysisModalIsOpen}
        onClose={() => setNewAnalysisModalIsOpen(false)}
      />
      <DeleteProjectModal
        opened={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onProjectDeleted={onDeleteProject}
        project={currentProject}
      />
      <CreateLinkModal
        opened={showCreateLinkModal}
        onClose={() => setShowCreateLinkModal(false)}
        onLinkCreated={onLinkCreated}
        project={currentProject}
      />
      <ShareProjectModal
        opened={showShareModal}
        onClose={() => setShowShareModal(false)}
        project={currentProject}
      />
      <QuotesModal
        opened={showQuotesModal}
        onClose={() => setShowQuotesModal(false)}
      />
      <SectionWrapper
        isFullHeight
        showBreadcrumbs
        rightComponent={
          <Group spacing="xs">
            <Button size='xs' onClick={openQuotesModal}>
              Read quotes
            </Button>
            <Tooltip label="Share project" openDelay={500}>
              <ActionIcon onClick={() => shareProject()}>
                <IconShare2 size={18} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Delete project" openDelay={500}>
              <ActionIcon color="red" onClick={() => setShowDeleteModal(true)}>
                <IconTrash size={18} />
              </ActionIcon>
            </Tooltip>
          </Group>
        }
      >
        <Stack spacing="md">
          <ProjectHeader project={currentProject} datasets={datasets} />
          <Tabs value={projectTab} onTabChange={setProjectTab}>
            <Tabs.List>
              <Tabs.Tab icon={<IconReportSearch size={18} />} value="SUMMARIES">
                {`Plan of Action (${summariesResults.length})`}
              </Tabs.Tab>
              <Tabs.Tab icon={<IconDeviceAnalytics size={18} />} value="CUSTOM">
                {`Custom analyses (${customAnalysesResults.length})`}
              </Tabs.Tab>
              <Tabs.Tab icon={<IconDeviceAnalytics size={18} />} value="DEFAULT">
                {`Default analyses (${defaultAnalysesResults.length})`}
              </Tabs.Tab>
              <div style={{ marginLeft: "auto", position: "relative"}}>
              <Group spacing={"xs"} position='right' style={{position: "absolute", width: 580, right:0, bottom: 0}} ml="auto" pb="xs">
                <Input
                  autoFocus
                  style={{ minWidth: 200 }}
                  rightSection={SearchIcon}
                  placeholder={"Search for plans of action, analyses or populations"}
                  size="sm"
                  onChange={(e) => setSearchQuery(e.currentTarget.value)}
                  value={searchQuery}
                />
                <Button
                  leftIcon={projectTab === "SUMMARIES" ? <IconReportSearch size={18} /> : <IconDeviceAnalytics size={18} />}
                  onClick={projectTab === "SUMMARIES" ? undefined : () => setNewAnalysisModalIsOpen(true)}
                  component={projectTab === "SUMMARIES" ? Link : undefined}
                  to={`/projects/${currentProject.projectId}/new-summary`}
                >
                  { projectTab === "SUMMARIES" ? "New plan of action" :"New analysis" }
                </Button>
              </Group>  
              </div>
            </Tabs.List>
            <Tabs.Panel value="SUMMARIES" pt="xs">
              { summaries.length === 0 ? <Stack align="center" p="xl">
                <Text>You have not created any summary yet</Text>
                <Button
                  leftIcon={<IconPlus size={18} />}
                  component={Link}
                  to={`/projects/${currentProject.projectId}/new-summary`}
                >
                  New plan of action
                </Button>
              </Stack> 
              :
              summariesResults.length > 0 ?
                  <SummaryTab data={summariesResults}/>
                :
                  <NoResults label="No summary matches your search" setSearchQuery={setSearchQuery} />
              }
            </Tabs.Panel>
            <Tabs.Panel value="CUSTOM" pt="xs">
              { customAnalyses.length === 0 ? <Stack align="center" p="xl">
                <Text>You have not created any custom analyses yet</Text>
                <Button
                  leftIcon={<IconPlus size={18} />}
                  onClick={() => setNewAnalysisModalIsOpen(true)}
                >
                  New custom analysis
                </Button>
              </Stack> 
              :
              customAnalysesResults.length > 0 ?
                  <AnalysesTab data={customAnalysesResults}/>
                :
                  <NoResults label="No custom analysis matches your search" setSearchQuery={setSearchQuery} />
              }
            </Tabs.Panel>
            <Tabs.Panel value="DEFAULT" pt="xs">
              { defaultAnalyses.length === 0 ? <Stack align="center" p="xl">
                <Text>This project does not have any default analysis, but you can still create a custom one</Text>
                <Button
                  leftIcon={<IconPlus size={18} />}
                  onClick={() => setNewAnalysisModalIsOpen(true)}
                >
                  New custom analysis
                </Button>
              </Stack> 
              :
              defaultAnalysesResults.length > 0 ?
                  <AnalysesTab data={defaultAnalysesResults}/>
                :
                  <NoResults label="No default analysis matches your search" setSearchQuery={setSearchQuery} />
              }
            </Tabs.Panel>
          </Tabs>
        </Stack>
      </SectionWrapper>
    </>
  );
};

const ProjectHeader: FC<{project, datasets}> = ({project, datasets}) => (
  <Stack spacing={0}>
    <Flex justify="space-between" align="center">
      <Group spacing="xs">
        <Link
          to={`/projects/`}
          style={{ textDecoration: 'none', display: 'flex' }}
        >
          <IconArrowLeft size={18} color="black" />
        </Link>
        <Title order={2}>{project.title}</Title>
      </Group>
      <Flex gap={'xl'}>
        <Stack spacing={0}>
          <Text color="dimmed" size="xs">
            Last update
          </Text>
          <Text size="xs">
            {dateToString(project.dateUpdated)}
          </Text>
        </Stack>
        <Stack spacing={0}>
          <Text color="dimmed" size="xs">
            Status
          </Text>
          <Flex gap={2}>
            <ProjectStatusIcon
              projectStatus={project.status}
              showLabel
              textSize={'xs'}
            />
          </Flex>
        </Stack>
        <Stack spacing={0}>
          <Text color="dimmed" size="xs">
            Datasets
          </Text>
          <Text size="xs">
            {datasets.map((ds) => ds.alias).join(' | ')}
          </Text>
        </Stack>
      </Flex>
    </Flex>
    <Text color="dimmed" size="sm">
      {project.description}
    </Text>
  </Stack>
)

const NoResults: FC<{label, setSearchQuery}> = ({label, setSearchQuery}) => (
  <Stack align="center" p="xl">
    <Text>{label}</Text>
    <Anchor
      style={{ height: 36 }}
      c="red"
      onClick={() => setSearchQuery('')}
    >
      Remove filters
    </Anchor>
  </Stack>
)