import CRN from './Crn';
import { CompareTwoPopulationsAnalysisModel } from './ProjectAnalysisModel/CompareTwoPopulationsAnalysisModel';
import { DriversOfOutcomeAnalysisModel } from './ProjectAnalysisModel/DriversOfOutcomeAnalysisModel';
import { ExternalBenchmarkAnalysisModel } from './ProjectAnalysisModel/ExternalBenchmarkAnalysisModel';
import { InternalBenchmarkAnalysisModel } from './ProjectAnalysisModel/InternalBenchmarkAnalysisModel';
import { OvertimeComparisonAnalysisModel } from './ProjectAnalysisModel/OvertimeComparisonAnalysisModel';
import {
  IProjectAnalysisModel,
  IProjectAnalysisRequestModel,
  AnalysisType,
  isAnalysisType,
  getAnalysisType,
  AnalysisStatus,
} from './ProjectAnalysisModel/ProjectAnalysisModelBase';
import { RawVoiceAnalysisModel } from './ProjectAnalysisModel/RawVoiceAnalysisModel';
import { SynthesisAnalysisModel } from './ProjectAnalysisModel/SynthesisAnalysisModel';
import { SummaryModel } from './SummaryModel';

export enum ProjectStatus {
  ERROR = 'ERROR',
  PROCESSING = 'PROCESSING',
  READY_WITH_RESTRICTIONS = 'READY_WITH_RESTRICTIONS',
  READY = 'READY',
}

interface ProjectDataset {
  DatasetCRN: string;
  LongCSICRN: string;
  QuotesCRN: string;
  DemoTextsCRN: string;
  AutocorrectedSentencesCRN?: string;
  TopicThemeMappingCRN?: string;
  TextHeaders: string[];
}

class ProjectModel {
  organizationId: number;
  projectId: string;
  title: string;
  version: string;
  description?: string;
  projectType: string;
  topicThemeMappingCRN: CRN;
  datasets: { [key: string]: ProjectDataset };
  benchmarks: { [key: string]: any }; // COMBAK: - Need to define this type better
  analyses: IProjectAnalysisModel[];
  summaries: SummaryModel[];
  dateAdded: Date;
  dateUpdated: Date;

  constructor(
    organizationId: number,
    projectId: string,
    title: string,
    version: string,
    projectType: string,
    topicThemeMappingCRN: CRN | undefined,
    datasets: { [key: string]: ProjectDataset },
    benchmarks: { [key: string]: any },
    analyses: IProjectAnalysisModel[],
    summaries: SummaryModel[],
    dateAdded: Date,
    dateUpdated: Date,
    description?: string
  ) {
    this.organizationId = organizationId;
    this.projectId = projectId;
    this.title = title;
    this.version = version;
    this.description = description;
    this.projectType = projectType;
    this.topicThemeMappingCRN = topicThemeMappingCRN;
    this.datasets = datasets;
    this.benchmarks = benchmarks;
    this.analyses = analyses;
    this.summaries = summaries;
    this.dateAdded = dateAdded;
    this.dateUpdated = dateUpdated;
  }

  static fromAPIResponse(apiResponse: any): ProjectModel {
    const organizationId = parseInt(apiResponse.OrganizationId);
    const projectId = apiResponse.ProjectId;
    const title = apiResponse.Title;
    const description = apiResponse.Description;
    const projectType = apiResponse.ProjectType;
    const datasets = apiResponse.Datasets;
    const benchmarks = apiResponse.Benchmarks;
    const responseAnalyses = apiResponse.Analyses;
    const summaries = apiResponse.Summaries;
    const version = apiResponse.ProjectVersion;

    const topicThemeMappingString = apiResponse.TopicThemeMappingCRN;
    var topicThemeMappingCRN: CRN | undefined = undefined;
    if (topicThemeMappingString) {
      topicThemeMappingCRN = CRN.fromString(topicThemeMappingString);
    }

    const dateAdded = new Date(`${apiResponse.DateAdded}Z`);
    const dateUpdated = new Date(`${apiResponse.DateUpdated}Z`);

    // We need to format the analyses to be the correct type...
    const formattedAnalyses: IProjectAnalysisModel[] = responseAnalyses
      .map((analysis: object) => {
        if (isAnalysisType(analysis['Type'])) {
          // Figure out what type (AnalysisType) the analysis is
          const analysisType = getAnalysisType(analysis['Type']);
          // Create a new analysis object based on that type...
          switch (analysisType) {
            case AnalysisType.DRIVERS_OF_OUTCOME:
              return DriversOfOutcomeAnalysisModel.fromAPIResponse(analysis);
            case AnalysisType.INTERNAL_BENCHMARK:
              return InternalBenchmarkAnalysisModel.fromAPIResponse(analysis);
            case AnalysisType.COMPARE_TWO_POPULATIONS:
              return CompareTwoPopulationsAnalysisModel.fromAPIResponse(
                analysis
              );
            case AnalysisType.SYNTHESIS:
              return SynthesisAnalysisModel.fromAPIResponse(analysis);
            case AnalysisType.EXTERNAL_BENCHMARK:
              return ExternalBenchmarkAnalysisModel.fromAPIResponse(analysis);
            case AnalysisType.PROGRESS_OVER_TIME:
              return OvertimeComparisonAnalysisModel.fromAPIResponse(analysis);
            case AnalysisType.RAW_VOICE:
              return RawVoiceAnalysisModel.fromAPIResponse(analysis);
            default:
              break;
          }
        } else {
          return undefined;
        }
      })
      .filter(
        (analysis: IProjectAnalysisModel | undefined) => analysis !== undefined
      );

    return new ProjectModel(
      organizationId,
      projectId,
      title,
      version,
      projectType,
      topicThemeMappingCRN,
      datasets,
      benchmarks,
      formattedAnalyses,
      summaries,
      dateAdded,
      dateUpdated,
      description
    );
  }

  get crn(): CRN {
    return new CRN(
      this.organizationId,
      'project',
      this.projectType,
      this.projectId
    );
  }

  get status(): ProjectStatus {
    const uniqueAnalysisStatuses = new Set<string>();
    for (const analysis of this.analyses) {
      uniqueAnalysisStatuses.add(analysis.status);
    }

    // If any of the analyses reports error, then the project is in error
    if (uniqueAnalysisStatuses.has(AnalysisStatus.ERROR)) {
      return ProjectStatus.ERROR;
    }

    // If any of the analyses report processing, then the project is processing
    if (uniqueAnalysisStatuses.has(AnalysisStatus.PROCESSING)) {
      return ProjectStatus.PROCESSING;
    }

    // Otherwise, the project is ready, but we need to await the other data...

    return ProjectStatus.READY;
  }

  get statusMessage(): string {
    return 'X';
  }
}

export interface CreateProjectRequestModel {
  organizationId?: number;
  title: string;
  description?: string;
  version?: string;
  topicThemeMapping: CRN | 'LATEST';
  datasets: { [key: string]: ProjectDataset };
  benchmarks: { [key: string]: any }; // COMBAK: - Need to define this type better
  analyses: IProjectAnalysisRequestModel[];
  summaries: SummaryModel[]
}

export interface ProjectBenchmarkUpdateRequestModel {
  topicThemeMapping?: CRN | 'LATEST';
  benchmarks: { [key: string]: any }; // COMBAK: - Need to define this type better

  newAnalyses?: IProjectAnalysisRequestModel[];
  updatedAnalyses?: IProjectAnalysisModel[];
  deletedAnalyses?: IProjectAnalysisModel[];

  newSummaries?: (Omit<SummaryModel, 'Id' | 'CreatedAt' | 'UpdatedAt'>)[];
  updatedSummaries?: (Omit<SummaryModel, 'CreatedAt' | 'UpdatedAt'>)[];
  deletedSummaries?: (Omit<SummaryModel, 'CreatedAt' | 'UpdatedAt'>)[];

  organizationId: number;
  projectId: string;
}

export default ProjectModel;
