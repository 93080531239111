import {
  ActionIcon,
  CopyButton,
  Stack,
  Title,
  useMantineTheme,
  Text,
  Group,
  Button,
  Tooltip,
  Skeleton,
  Divider,
  Anchor,
  Center,
  Popover,
} from '@mantine/core';
import { Quote } from '@stores/AnalysisStore';
import { IconChevronLeft, IconChevronRight, IconCopy } from '@tabler/icons';
import { useEffect, useMemo, useState } from 'react';
import { QuoteHighlight } from '../QuoteHighlight';

import Fuse from 'fuse.js';
import hash from 'object-hash';
import { QuoteCoMentionModel } from '@models/QuoteModel';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { BadgeSelect } from '@components/BadgeSelect/BadgeSelect';
import { FocalTopicFilter } from '@components/FocalTopicFilter';
import QuoteReaderProfileComponent from '@components/QuoteReaderProfileComponent';
import { useQuotes } from '@apis/hooks/useQuotes';
import { userFriendlyTopicThemeName } from '@utils/TopicUtils';
import { ProjectMetricType } from '@apis/ProjectAPI';
import APIErrorMessage from '@components/APIErrorMessage';
import { useProjectDatasets } from '@apis/hooks/useProjectDatasets';
import { getAliasFromColumn } from '@utils/MetadataUtils';
import { QuoteReportButton } from './QuoteReportButton';
import { useQuoteStore } from '@stores/QuoteStore';
import { useTopicStore } from '@stores/TopicStore';

interface QuoteCopyButtonProps {
  value: string;
  style: any;
}

const QuoteCopyButton = ({ value, ...others }: QuoteCopyButtonProps) => {
  return (
    <CopyButton value={value} {...others}>
      {({ copied, copy }) => (
        <ActionIcon onClick={copy} color={copied === false ? 'dark' : 'green'}>
          <IconCopy size="1.25rem" />
        </ActionIcon>
      )}
    </CopyButton>
  );
};

interface QuoteBoxProps {
  currentQuote: Quote | undefined;
}

const QuoteBox: React.FC<QuoteBoxProps> = ({ currentQuote }: QuoteBoxProps) => {
  const theme = useMantineTheme();

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: '1fr auto',
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[6]
            : theme.colors.gray[1],
        borderRadius: '5px',
        fontFamily:
          "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
        lineHeight: 1.5,
        minHeight: '250px',
        maxHeight: '250px',
        overflow: 'hidden',
      }}
    >
      <>
        <div
          style={{
            overflowY: 'auto',
            padding: '2%',
          }}
        >
          {currentQuote ? (
            formatTextInstance(currentQuote)
          ) : (
            <i>No quote selected</i>
          )}
        </div>
        <QuoteCopyButton
          style={{ alignSelf: 'end', justifySelf: 'start', padding: '2%' }}
          value={currentQuote ? currentQuote.textInstance : ''}
        />
      </>
    </div>
  );
};

const QuoteBoxEmptyState: React.FC<{focalTopic: string | undefined}> = ({focalTopic}) => {
  const theme = useMantineTheme();

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: '1fr auto',
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[6]
            : theme.colors.gray[1],
        borderRadius: '5px',
        fontFamily:
          "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
        lineHeight: 1.5,
        minHeight: '250px',
        maxHeight: '250px',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Center>
        <Stack spacing={0}>
          <Text>{focalTopic ? "Define a population to see quotes" : "Click a topic on the chart to see quotes"}</Text>
          {!focalTopic && <Divider my="sm" label="or" labelPosition="center" />}
          {!focalTopic && <Center>
            <Popover withArrow>
              <Popover.Target>
                <Anchor size="sm" onClick={() => {}}>
                  Select a topic here
                </Anchor>
              </Popover.Target>
              <Popover.Dropdown>
                <div style={{ width: '250px' }}>
                  <FocalTopicFilter/>
                </div>
              </Popover.Dropdown>
            </Popover>
          </Center> }
        </Stack>
      </Center>
    </div>
  );
};

const formatTextInstance = (quote: Quote) => {
  const textInstance = quote.textInstance;
  const sentence = quote.sentence;

  const sentenceNumber = quote.sentenceNumber;
  const sentences = textInstance.split(/\.|\!|\?|\r\n|\r|\n|\t/);

  // Initialize Fuse and perform search
  const fuse = new Fuse(sentences, { includeScore: true, threshold: 0.2 });
  const result = fuse.search(sentence);
  const sentenceToHighlight = result[0]?.item || '';
  const bestMatchIndex = sentences.indexOf(sentenceToHighlight);

  if (bestMatchIndex !== sentenceNumber - 1) {
    // Handle the discrepancy here, if needed...
    // This may occur if the sentence is not found in the text instance...
  }

  const escapedSentence = sentenceToHighlight.replace(/"/g, '&quot;');

  var sentencesToHighlight: string[] = [sentenceToHighlight];
  let style: any;

  if (quote instanceof QuoteCoMentionModel) {
    const focalSentenceNumber = (quote as QuoteCoMentionModel)
      .focalSentenceNumber;
    const focalResult = fuse.search(
      (quote as QuoteCoMentionModel).focalSentence
    );
    const focalSentenceToHighlight = focalResult[0]?.item || '';

    const escapedFocalSentence = focalSentenceToHighlight.replace(
      /"/g,
      '&quot;'
    );
    sentencesToHighlight.push(escapedFocalSentence);

    style = (theme: any) => {
      const focusColor =
        quote.textInstanceSentiment === 'positive'
          ? theme.colors.green[2]
          : theme.colors.red[2];

      return {
        [`& [data-chunk-hash="${hash(sentenceToHighlight.trim())}"]`]: {
          backgroundColor: theme.colors.blue[2],
        },
        [`& [data-chunk-hash="${hash(escapedFocalSentence.trim())}"]`]: {
          backgroundColor: focusColor,
        },
      };
    };

    return (
      <pre
        style={{
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
        }}
      >
        <QuoteHighlight sx={style} highlight={sentencesToHighlight}>
          {textInstance}
        </QuoteHighlight>
      </pre>
    );
  } else {
    return (
      <pre
        style={{
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
        }}
      >
        <QuoteHighlight highlightColor="blue" highlight={sentencesToHighlight}>
          {textInstance}
        </QuoteHighlight>
      </pre>
    );
  }
};

function MiddleTruncatedText({ text, maxLength }) {
  if (text.length <= maxLength) {
    return <span>{text}</span>;
  }

  const start = text.substring(0, Math.floor(maxLength / 2) - 1);
  const end = text.substring(
    text.length - Math.floor(maxLength / 2) + 2,
    text.length
  );
  const displayText = `${start}...${end}`;

  return <span>{displayText}</span>;
}

interface QuoteReaderProps {
  showCoMentionTopic: boolean;
}

export const QuoteReader: React.FC<QuoteReaderProps> = ({showCoMentionTopic}) => {
  const theme = useMantineTheme();
  const currentProject = useCurrentProject();
  const { data: datasetMetadata } = useProjectDatasets(currentProject);
  const focalTopic = useTopicStore((s) => s.focalTopic);
  const coMentionedTopic = useQuoteStore((s) => s.coMentionedTopic);
  const quoteSentiments = useQuoteStore((s) => s.quoteSentiments);
  const setQuoteSentiments = useQuoteStore((s) => s.setQuoteSentiments);
  const activeCoMentionType = useQuoteStore((s) => s.activeCoMentionType);
  const focalPopulation = useQuoteStore((s) => s.population);
  const activeQuoteDemographics = useQuoteStore(s => s.activeQuoteDemographics);
  const currentIndex = useQuoteStore(s => s.currentIndex);
  const setCurrentIndex = useQuoteStore(s => s.setCurrentIndex);
  const currentQuote = useQuoteStore(s => s.currentQuote);
  const setCurrentQuote = useQuoteStore(s => s.setCurrentQuote);
  
  const {
    data: fetchedQuotes,
    isLoading,
    isError,
    error,
  } = useQuotes(
    currentProject.projectId,
    currentProject.organizationId,
    focalPopulation,
    [focalTopic],
    quoteSentiments,
    activeQuoteDemographics,
    showCoMentionTopic ? coMentionedTopic : undefined,
    activeCoMentionType
  );

  const quotes = fetchedQuotes || [];
  const nextButtonDisabled = currentIndex === quotes.length - 1;
  const previousButtonDisabled = currentIndex === 0;

  // MARK: - Local functions for controlling current sentence...
  const nextSentence = () => {
    // Check to make sure we're not at the end...
    if (currentIndex === quotes.length - 1) {
      return;
    }

    const nextIndex = currentIndex + 1;
    const nextQuote = quotes[nextIndex];

    setCurrentIndex(nextIndex);
    setCurrentQuote(nextQuote);
  };

  const previousSentence = () => {
    // Check to make sure we're not at the beginning...
    if (currentIndex === 0) {
      return;
    }

    const previousIndex = currentIndex - 1;
    const previousQuote = quotes[previousIndex];

    setCurrentIndex(previousIndex);
    setCurrentQuote(previousQuote);
  };

  useEffect(() => {
    if (quotes.length > 0 && (!quotes[currentIndex] || quotes[currentIndex].sentence !== currentQuote?.sentence)) {
      setCurrentIndex(0);
      setCurrentQuote(quotes[0]);
    }
  }, [quotes, currentIndex, currentQuote]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        nextSentence();
      } else if (event.key === 'ArrowLeft') {
        previousSentence();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts or currentIndex changes
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentIndex]);

  const cannotLoadQuotes =
    !focalTopic ||
    !focalPopulation ||
    !currentProject ||
    !quoteSentiments;

  var headerColor = 'gray';
  if (currentQuote?.textInstanceSentiment === 'positive') {
    headerColor = 'teal';
  } else if (currentQuote?.textInstanceSentiment === 'negative') {
    headerColor = 'red';
  }

  const showError = isError && !isLoading && error;

  const quoteNiceHeader = useMemo(() => {
    if (datasetMetadata === undefined) {
      return currentQuote?.textHeader;
    }

    for (const [key, metadata] of datasetMetadata) {
      // Let's get the text header to look for the alias..
      const textHeader = currentQuote?.textHeader;
      if (textHeader === undefined) {
        return textHeader;
      }

      const alias = getAliasFromColumn(textHeader, metadata);
      if (alias !== undefined) {
        return alias;
      }
    }

    return currentQuote?.textHeader;
  }, [currentQuote, datasetMetadata]);

  return (
    <>
      <Stack spacing={'xs'}>
        <Stack spacing={0}>
          <Title order={5}>Quote</Title>
          {showCoMentionTopic && coMentionedTopic && (
            <Text size="xs" color="dimmed">
              {activeCoMentionType === ProjectMetricType.NegativeCoMention
                ? 'negative'
                : 'positive'}{' '}
              co-mention of{' '}
              <b
                style={{
                  color:
                    activeCoMentionType === ProjectMetricType.NegativeCoMention
                      ? theme.colors.red[7]
                      : theme.colors.green[7],
                }}
              >
                {userFriendlyTopicThemeName(coMentionedTopic)}
              </b>
            </Text>
          )}
        </Stack>

        {showError ? (
          <Center style={{ height: '250px' }}>
            <APIErrorMessage response={error} simple />
          </Center>
        ) : (
          <>
            {cannotLoadQuotes ? (
              <QuoteBoxEmptyState focalTopic={focalTopic}/>
            ) : (
              <>
                {isLoading && !cannotLoadQuotes ? (
                  <Skeleton height={250} width={"100%"} />
                ) : (
                  <QuoteBox currentQuote={currentQuote} />
                )}
              </>
            )}
          </>
        )}

        <Group position="apart">
          {isLoading && !cannotLoadQuotes ? (
            <Text size="xs" color="dimmed">
              Loading quotes...
            </Text>
          ) : (
            <Text size="xs" color="dimmed">
              Quote from <b>{focalPopulation?.title}</b>
            </Text>
          )}

          {isLoading && !cannotLoadQuotes ? (
            <Skeleton height={20} width={'50px'} radius="xl" />
          ) : (
            <Tooltip label="Click to change sentiment" openDelay={400}>
              <BadgeSelect
                onChange={setQuoteSentiments}
                defaultValue={quoteSentiments}
                data={[
                  { label: 'Positive', value: 'positive' },
                  { label: 'Negative', value: 'negative' },
                  { label: 'Indeterminate', value: 'indeterminate' },
                ]}
                color={headerColor}
              >
                {quoteNiceHeader || ''}
              </BadgeSelect>
            </Tooltip>
          )}
        </Group>
        <Group position="apart">
          <Group>
            <QuoteReportButton quote={currentQuote} />
          </Group>
          <Group>
            <Button
              size="xs"
              leftIcon={<IconChevronLeft />}
              disabled={
                (isLoading && !cannotLoadQuotes) || previousButtonDisabled
              }
              onClick={previousSentence}
            >
              Previous
            </Button>
            <Button
              size="xs"
              rightIcon={<IconChevronRight />}
              disabled={(isLoading && !cannotLoadQuotes) || nextButtonDisabled}
              onClick={nextSentence}
            >
              Next
            </Button>
          </Group>
        </Group>
        {!cannotLoadQuotes && (
          <QuoteReaderProfileComponent
            quote={currentQuote}
            loading={isLoading}
          />
        )}
      </Stack>
    </>
  );
};
