import React from 'react';
import ViewModel from '../models/ViewModel';
import {
  ConnectionType,
  CXMetricType,
  UsableValue,
} from '../models/ViewFilterMappingModel';
import QuoteReaderFilterObject from '../models/ViewFilterMappingModel';
import { FilterType } from 'tableau-js-api';
import { FilterEvent, MarksEvent } from 'tableau-js-api/viz.event';
import { CategoricalFilter, Filter, DataValue } from 'tableau-js-api/filtering';
import { Mark, Pair } from 'tableau-js-api/marks';
import { Worksheet } from 'tableau-js-api/sheet';
import CRN from '../models/Crn';

interface TableauEmbeddedViewProps {
  view: ViewModel;

  trustedTicket?: string;

  onQuoteReaderFilterChange(
    focalCRN: CRN,
    filters: QuoteReaderFilterObject
  ): void;
}

interface TableauEmbeddedViewState {
  width: number;
  viz?: any;
  useTrustedTicket: boolean;
}

// View that handles the rendering of a Tableau View by calling all relevant functions to handle the rendering of the view.
// We pass the tableau view ID and optionally the token to authenticate with tableau server
class TableauEmbeddedView extends React.Component<
  TableauEmbeddedViewProps,
  TableauEmbeddedViewState
> {
  state: TableauEmbeddedViewState = {
    width: window.innerWidth,
    useTrustedTicket: true,
  };

  tableau = (window as any).tableau;

  constructor(props: TableauEmbeddedViewProps) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    this.initViz();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  shouldComponentUpdate(
    nextProps: TableauEmbeddedViewProps,
    nextState: TableauEmbeddedViewState
  ) {
    if (this.props.view.viewId !== nextProps.view.viewId) {
      // incase we changed the props for the viz
      if (this.state.viz !== null) {
        this.setState({ useTrustedTicket: false }, () => {
          this.updateViz();
        });
      }
    }
    return true;
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  // This function is called when we want to forcably use the filters from the

  queryQuotesUsingFilters = (worksheet: Worksheet): void => {
    const sheetName: string = worksheet.getName();

    // If the view doesn't have a filter mapping, we can't do anything.
    if (
      this.props.view.filterMapping === undefined ||
      this.props.view.filterMapping === null
    ) {
      return;
    }

    // Get the following filter mapping properties:
    // underlyingFilters - The filters that are applied to the underlying data in every case...
    // filterMapping - The mapping of filters to the view...

    var baseFilters =
      this.props.view.filterMapping.underlyingFilters[sheetName];
    if (baseFilters === undefined || baseFilters === null) {
      baseFilters = {
        demographics: {},
        vopics: [],
        metric: CXMetricType.Vopic,
        sentiment: ['positive', 'negative', 'neutral'],
      };
    }

    const filterMapping =
      this.props.view.filterMapping.filterMappings[sheetName];
    if (filterMapping === undefined || filterMapping === null) {
      // If we don't have a filter mapping, we can't continue...
      console.warn('OH NO, no filter mapping for this view!');
      return;
    }

    // We'll also need to get the associated CRN for the active sheet
    const currentCRN: CRN =
      this.props.view.filterMapping.quoteDatasets[sheetName];

    // Get all of the currently active filters on the worksheet.
    worksheet.getFiltersAsync().then((filters: Filter[]) => {
      var userFilters = { ...baseFilters };

      // Let's figure out if we have any requested demographics to retrieve...
      const demographicRetrieval =
        this.props.view.filterMapping?.demographicRetrieval[sheetName];
      if (demographicRetrieval) {
        userFilters.requestedDemographics = demographicRetrieval;
      }

      filters.forEach((filter) => {
        const filterName: string = filter.getFieldName();

        // Check to see if we have a filter mapping for this filter based on it's name...
        const filterMappingForFilter = filterMapping[filterName];
        if (filterMappingForFilter) {
          if (filter.getFilterType() === this.tableau.FilterType.CATEGORICAL) {
            const categoricalFilter: CategoricalFilter =
              filter as CategoricalFilter;
            const selectedValues: DataValue[] =
              categoricalFilter.getAppliedValues();

            if (categoricalFilter.getIsExcludeMode()) {
              console.warn(
                `Exclude mode is not supported for filter ${filterName} on worksheet ${sheetName}`
              );
            }

            if (
              filterMappingForFilter.connectionType ===
              ConnectionType.DemographicValue
            ) {
              if (filterMappingForFilter.associatedDemographic) {
                // We have a demographic filter, so we need to add it to the userFilters object...
                if (selectedValues.length > 0) {
                  var usableFilterValues: (string | boolean | number)[] = [];

                  selectedValues.forEach((value: DataValue) => {
                    if (
                      filterMappingForFilter.usableValue ===
                      UsableValue.FormattedValue
                    ) {
                      usableFilterValues.push(value.formattedValue);
                    } else {
                      if (!(value.value instanceof Date)) {
                        // we don't allow dates as of right now...
                        usableFilterValues.push(value.value);
                      }
                    }
                  });

                  if (
                    !(
                      filterMappingForFilter.associatedDemographic in
                      userFilters.demographics
                    )
                  ) {
                    userFilters.demographics[
                      filterMappingForFilter.associatedDemographic
                    ] = [];
                  }

                  userFilters.demographics[
                    filterMappingForFilter.associatedDemographic
                  ] =
                    userFilters.demographics[
                      filterMappingForFilter.associatedDemographic
                    ].concat(usableFilterValues);
                }
              }
            } else if (
              filterMappingForFilter.connectionType === ConnectionType.Topic
            ) {
              if (selectedValues.length > 0) {
                var usableTopics: string[] = [];

                selectedValues.forEach((value: DataValue) => {
                  if (
                    filterMappingForFilter.usableValue ===
                    UsableValue.FormattedValue
                  ) {
                    usableTopics.push(value.formattedValue);
                  } else {
                    // we don't allow dates as of right now...
                    usableTopics.push(value.value as string);
                  }
                });

                userFilters.vopics = userFilters.vopics.concat(usableTopics);
                userFilters.metric = CXMetricType.Topic;
              }
            } else if (
              filterMappingForFilter.connectionType ===
              ConnectionType.Demographic
            ) {
              console.warn(
                `Demographic filters are not supported for filter ${filterName} on worksheet ${sheetName}`
              );
            } else if (
              filterMappingForFilter.connectionType === ConnectionType.Value
            ) {
              if (selectedValues.length > 0) {
                var usableValues: string[] = [];

                selectedValues.forEach((value: DataValue) => {
                  if (
                    filterMappingForFilter.usableValue ===
                    UsableValue.FormattedValue
                  ) {
                    usableValues.push(value.formattedValue);
                  } else {
                    // we don't allow dates as of right now...
                    usableValues.push(value.value as string);
                  }
                });

                userFilters.vopics = userFilters.vopics.concat(usableValues);
                userFilters.metric = CXMetricType.Value;
              }
            } else if (
              filterMappingForFilter.connectionType === ConnectionType.Vopic
            ) {
              if (selectedValues.length > 0) {
                var usableValues: string[] = [];

                selectedValues.forEach((value: DataValue) => {
                  if (
                    filterMappingForFilter.usableValue ===
                    UsableValue.FormattedValue
                  ) {
                    usableValues.push(value.formattedValue);
                  } else {
                    // we don't allow dates as of right now...
                    usableValues.push(value.value as string);
                  }
                });

                userFilters.vopics = userFilters.vopics.concat(usableValues);
                userFilters.metric = CXMetricType.Vopic;
              }
            }
          } else {
            console.warn(
              '[Not Implemented] Unsupported filter type: ' +
                filter.getFilterType()
            );
          }
        }
      });

      // Now let's call the delegate callback to update the quote reader with new quotes...
      this.props.onQuoteReaderFilterChange(currentCRN, userFilters);
    });
  };

  handleFilterValueChange = (event: FilterEvent): void => {
    // First, get the active worksheet from the event.
    // We'll use this to fetch the mappings from this.props.view...
    const worksheet: Worksheet = event.getWorksheet();

    this.queryQuotesUsingFilters(worksheet);
  };

  handleMarkSelectionChange = (event: MarksEvent): void => {
    // Get the selected marks as well as the filters...
    const worksheet: Worksheet = event.getWorksheet();
    const sheetName = worksheet.getName();

    // If the view doesn't have a filter mapping, we can't do anything.
    if (
      this.props.view.filterMapping === undefined ||
      this.props.view.filterMapping === null
    ) {
      return;
    }

    // Get the following filter mapping properties:
    // underlyingFilters - The filters that are applied to the underlying data in every case...
    // filterMapping - The mapping of filters to the view...

    var baseFilters =
      this.props.view.filterMapping.underlyingFilters[sheetName];
    if (baseFilters === undefined || baseFilters === null) {
      baseFilters = {
        demographics: {},
        vopics: [],
        metric: CXMetricType.Vopic,
        sentiment: ['positive', 'negative', 'neutral'],
      };
    }

    const marksMappings = this.props.view.filterMapping.markMappings[sheetName];
    if (marksMappings === undefined || marksMappings === null) {
      // If we don't have a filter mapping, we can't continue...
      return;
    }

    // We'll also need to get the associated CRN for the active sheet
    const currentCRN: CRN =
      this.props.view.filterMapping.quoteDatasets[sheetName];

    // Get all the selected marks...
    event.getMarksAsync().then((marks: Mark[]) => {
      // If the marks are 0, aka we've deselected everything, we'll just use the base filters...
      // Aka using the active filter values on the dashboard...
      if (marks.length === 0) {
        this.queryQuotesUsingFilters(worksheet);
        return;
      }

      var userFilters = { ...baseFilters };
      // Let's figure out if we have any requested demographics to retrieve...
      const demographicRetrieval =
        this.props.view.filterMapping?.demographicRetrieval[sheetName];
      if (demographicRetrieval) {
        userFilters.requestedDemographics = demographicRetrieval;
      }

      marks.forEach((mark: Mark) => {
        const pairs: Pair[] = mark.getPairs();
        pairs.forEach((pair: Pair) => {
          // Get the sheet name and see if we have a mapping for it...
          const pairName = pair.fieldName;
          const markMapping = marksMappings[pairName];

          if (markMapping) {
            // We have a mapping for this mark...
            // Figure out what type of connection the mark-value is...
            if (
              markMapping.connectionType === ConnectionType.DemographicValue
            ) {
              // We have a demographic value, so we need to add it to the userFilters object...
              if (markMapping.associatedDemographic) {
                // We have a demographic filter, so we need to add it to the userFilters object...
                if (
                  !(
                    markMapping.associatedDemographic in
                    userFilters.demographics
                  )
                ) {
                  userFilters.demographics[markMapping.associatedDemographic] =
                    [];
                }

                if (markMapping.usableValue === UsableValue.FormattedValue) {
                  userFilters.demographics[
                    markMapping.associatedDemographic
                  ].push(pair.formattedValue);
                } else {
                  if (!(pair.value instanceof Date)) {
                    // we don't allow dates as of right now...
                    userFilters.demographics[
                      markMapping.associatedDemographic
                    ].push(pair.value);
                  }
                }
              }
            } else if (markMapping.connectionType === ConnectionType.Topic) {
              if (markMapping.usableValue === UsableValue.FormattedValue) {
                userFilters.vopics.push(pair.formattedValue);
              } else {
                // we don't allow dates as of right now...
                userFilters.vopics.push(pair.value as string);
              }

              userFilters.metric = CXMetricType.Vopic;
            } else if (markMapping.connectionType === ConnectionType.Value) {
              console.log(
                `Sending value for ${pairName} on worksheet ${sheetName}`
              );
              if (
                userFilters.vopics === undefined ||
                userFilters.vopics === null
              ) {
                userFilters.vopics = [];
              }
              if (markMapping.usableValue === UsableValue.FormattedValue) {
                userFilters.vopics.push(pair.formattedValue);
              } else {
                // we don't allow dates as of right now...
                userFilters.vopics.push(pair.value as string);
              }

              userFilters.metric = CXMetricType.Value;
            } else if (markMapping.connectionType === ConnectionType.Vopic) {
              console.log(
                `Sending vopic for ${pairName} on worksheet ${sheetName}`
              );
              if (
                userFilters.vopics === undefined ||
                userFilters.vopics === null
              ) {
                userFilters.vopics = [];
              }
              if (markMapping.usableValue === UsableValue.FormattedValue) {
                userFilters.vopics.push(pair.formattedValue);
              } else {
                // we don't allow dates as of right now...
                userFilters.vopics.push(pair.value as string);
              }

              userFilters.metric = CXMetricType.Vopic;
            } else {
              console.warn(
                `Unsupported connection type for mark ${pairName} on worksheet ${sheetName}`
              );
            }
          }
        });
      });

      // Now let's call the delegate callback to update the quote reader with new quotes...
      this.props.onQuoteReaderFilterChange(currentCRN, userFilters);
    });
  };

  initViz() {
    var containerDiv = document.getElementById('vizContainer');
    var url = `${import.meta.env.VITE_CULTUREX_TABLEAU_ENDPOINT}/views/${
      this.props.view.tableauView.pathPrefix
    }`;
    if (
      this.props.trustedTicket !== undefined &&
      this.props.trustedTicket !== null &&
      this.state.useTrustedTicket === true
    ) {
      url = `${import.meta.env.VITE_CULTUREX_TABLEAU_ENDPOINT}/trusted/${
        this.props.trustedTicket
      }/views/${this.props.view.tableauView.pathPrefix}`;
    }

    var options = {
      hideTabs: true,
      hideToolbar: false,
      customViews: 'no',
      onFirstInteractive: function () {
        console.log('Run this code when the viz has finished loading.');
      },
    };
    console.log(this.tableau);
    console.log(this.tableau.Viz);
    var viz = new this.tableau.Viz(containerDiv, url, options);

    // Add the event handler to call when the filter values change.
    viz.addEventListener(
      this.tableau.TableauEventName.FILTER_CHANGE,
      this.handleFilterValueChange
    );

    // Add the event handler to call when the mark selection changes.
    viz.addEventListener(
      this.tableau.TableauEventName.MARKS_SELECTION,
      this.handleMarkSelectionChange
    );

    this.setState({
      viz: viz,
    });
  }

  updateViz() {
    // Dispose of the old viz and create a new one
    if (this.state.viz !== null) {
      this.state.viz.dispose();
      setTimeout(() => {
        this.initViz();
      }, 200);
    }
  }

  render() {
    return (
      <>
        <div
          id="vizContainer"
          style={{
            width: '100%',
            height: '900px', // TODO: Make this dynamic or something...
          }}
        ></div>
      </>
    );
  }
}

export default TableauEmbeddedView;
