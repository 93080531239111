import { Summary, SummaryAnalysis } from '@hooks/useProjectSummaries';
import { create } from 'zustand';

interface SumaryEditionStore {
  analysesInSummary: SummaryAnalysis[]
  setSummaryData: (value?: Summary) => void
  switchAnalysis: (value: SummaryAnalysis) => void
  setPopulation: (id: string, focalPopulation: string) => void
}

export const useSumaryEditionStore = create<SumaryEditionStore>()(
  (set, get) => ({
    analysesInSummary: [],
    setSummaryData: (value?: Summary) => {
      if(!value) {
        set({analysesInSummary: []})
      } else {
        set({analysesInSummary: value.analyses})
      }
    },
    switchAnalysis: (value: SummaryAnalysis) => {
        const analysesInSummary = [...get().analysesInSummary];
        if(analysesInSummary.find(a => a.id === value.id)) {
            set({analysesInSummary: analysesInSummary.filter(a => a.id !== value.id)})
        } else {
            set({analysesInSummary: [...analysesInSummary, value]})
        }
    },
    setPopulation:  (id: string, focalPopulation: string) => {
      const analysesInSummary = [...get().analysesInSummary];
      const index = analysesInSummary.findIndex(a => a.id === id)
      if(index !== -1) {
        analysesInSummary[index].focalPopulation = focalPopulation;
        set({analysesInSummary})
      }
    }
  })
);
