import { FC, useEffect, useMemo, useState } from 'react';
import SectionWrapper from '../components/SectionWrapper';
import {
  ActionIcon,
  Button,
  Flex,
  Group,
  Input,
  Skeleton,
  Stack,
  Title,
} from '@mantine/core';
import Link from '@components/Link';
import { IconPlus, IconReload, IconSearch, IconX } from '@tabler/icons-react';
import { useProjects } from '@apis/hooks/useProjects';
import { ProjectList } from '@components/ProjectList';
import Fuse from 'fuse.js';
import ProjectModel from '@models/ProjectModel';

const FuseOptions = {
  includeScore: false,
  includeMatches: true,
  minMatchCharLength: 2,
  threshold: 0.2,
  keys: [
    {
      name: 'title',
      weight: 2,
    },
    {
      name: 'description',
      weight: 1,
    },
  ],
};

const ProjectsPage: FC = () => {
  const { data: projects, isLoading, isFetching, refetch } = useProjects();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [fuse, setFuse] = useState<Fuse<ProjectModel>>(
    new Fuse(projects, FuseOptions)
  );

  useEffect(() => {
    setFuse(new Fuse(projects, FuseOptions));
  }, [projects]);

  const SearchIcon =
    searchQuery === '' ? (
      <IconSearch size={14} color="lightgray" />
    ) : (
      <ActionIcon variant="transparent" onClick={() => setSearchQuery('')}>
        <IconX size={14} color="gray" />
      </ActionIcon>
    );

  const searchResult = useMemo(() => {
    if (!projects) {
      return []
    }

    return searchQuery
      ? fuse.search(searchQuery)
      : projects.map(
        (a) =>
        ({
          item: a,
          matches: [],
          refIndex: -1,
        } as Fuse.FuseResult<ProjectModel>)
      );
  }, [fuse, searchQuery, projects]);

  if (!projects) {
    return (
      <SectionWrapper isFullHeight>
        <Stack spacing={0}>
          <Flex justify="space-between">
            <Skeleton mt="md" height={20} width={100} radius="xl" />
            <Group spacing="xs">
              <Skeleton mt="md" height={20} width={100} radius="xl" />
            </Group>
          </Flex>
        </Stack>
        <Skeleton mt="md" height={20} radius="xl" />
        <Skeleton mt="md" height={400} radius="md" />
      </SectionWrapper>
    );
  }

  return (
    <SectionWrapper isFullHeight showBreadcrumbs>
      <Stack spacing={'xl'}>
        <Title order={2}>Projects</Title>
        <Group position="apart">
          <Input
            autoFocus
            style={{ minWidth: 300 }}
            rightSection={SearchIcon}
            placeholder="Search projects"
            size="sm"
            onChange={(e) => setSearchQuery(e.currentTarget.value)}
            value={searchQuery}
          />
          <Group>
            <ActionIcon
              size={36}
              variant="light"
              color="dimmed"
              loading={isLoading || isFetching}
              onClick={() => refetch()}
            >
              {' '}
              <IconReload size="1rem" />{' '}
            </ActionIcon>
            <Button
              leftIcon={<IconPlus size={18} />}
              component={Link}
              to="/projects/new"
            >
              New Project
            </Button>
          </Group>
        </Group>

        <ProjectList
          isLoading={false}
          hasError={false}
          projects={searchResult.map(r => r.item)}
          onProjectDeleted={() => refetch()}
        />{' '}
      </Stack>
    </SectionWrapper>
  );
};

export default ProjectsPage;
