import { FC, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Flex,
  Grid,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import SectionWrapper from '@components/SectionWrapper';
import { useParams } from 'react-router-dom';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  AnalysisType,
  useAnalysisEditionStore,
} from '@stores/AnalysisEditionStore';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import FieldItem from '@components/AnalysisEdition/FieldItem';
import FieldsColumn from '@components/AnalysisEdition/FieldsColumn';
import ExplorationColumn from '@components/AnalysisEdition/ExplorationColumn';
import PopulationsColumn from '@components/AnalysisEdition/PopulationsColumn';
import { useNavigate } from '@hooks/useNavigate';
import { DriversOutcomeModal } from '@components/AnalysisEdition/DriversOutcomeModal';
import { useCurrentAnalysis } from '@hooks/useCurrentAnalysis';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { InlineEditableText } from '@components/InlineEditableText';
import { IOutcomeMeasure } from '@redux/analysesSlice';
import { useUpdateProject } from '@apis/hooks/useUpdateProject';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { InternalBenchmarkAnalysisModel } from '@models/ProjectAnalysisModel/InternalBenchmarkAnalysisModel';
import { AnalysisStatus } from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import {
  DriversOfOutcomeAnalysisModel,
  OutcomeConfiguration,
} from '@models/ProjectAnalysisModel/DriversOfOutcomeAnalysisModel';
import { useProject } from '@apis/hooks/useProject';
import { notifications } from '@mantine/notifications';
import { useAppStore } from '@stores/AppStore';
import { RawVoiceAnalysisModel } from '@models/ProjectAnalysisModel/RawVoiceAnalysisModel';
import { OvertimeComparisonAnalysisModel } from '@models/ProjectAnalysisModel/OvertimeComparisonAnalysisModel';

const EditAnalysisPage: FC = () => {
  const project = useCurrentProject();
  const analysis = useCurrentAnalysis();
  const setAnalysisData = useAnalysisEditionStore((s) => s.setAnalysisData);
  const populations = useAnalysisEditionStore((s) => s.populations);
  const onDragStart = useAnalysisEditionStore((s) => s.onDragStart);
  const onDragCancel = useAnalysisEditionStore((s) => s.onDragCancel);
  const onDragEnd = useAnalysisEditionStore((s) => s.onDragEnd);
  const dragging = useAnalysisEditionStore((s) => s.dragging);
  const dragField = useAnalysisEditionStore((s) => s.dragField);
  const selectedDistributionRows = useAnalysisEditionStore(
    (s) => s.selectedDistributionRows
  );
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 15 },
  });
  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
  });
  const sensors = useSensors(pointerSensor, keyboardSensor);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showDriversOutcomeModal, setShowDriversOutcomeModal] = useState(false);
  const [analysisName, setAnalysisName] = useState(analysis?.name ?? '');
  const [nameError, setNameError] = useState('');
  const [outcomeValues, setOutcomeValues] = useState<string[]>([]);
  const [featureValues, setFeatureValues] = useState<string[]>([]);
  const { refetch: refetchProject } = useProject(project?.projectId);
  const addAnalysisInProcess = useAppStore((s) => s.addAnalysisInProcess);

  const updateProject = useUpdateProject({
    onSuccess(data, variables) {
      queryClient.setQueryData(['project', data.projectId], data);
      const newAnalysis = data.analyses.find(
        (a) =>
          a.name ===
          [...variables.newAnalyses, ...variables.updatedAnalyses][0].name
      );
      navigate(`/projects/${data.projectId}/analyses/${newAnalysis.id}`);
      refetchProject();
      if (newAnalysis.type === AnalysisType.DRIVERS_OF_OUTCOME && newAnalysis.status !== AnalysisStatus.READY) {
        addAnalysisInProcess(data.projectId, newAnalysis.id);
        notifications.show({
          id: `analysis-created-${data.projectId}-${newAnalysis.id}`,
          color: 'blue',
          title: 'Analysis in process',
          loading: true,
          message: `The analysis ${newAnalysis.name} is processing. We will let you know when is ready.`,
          autoClose: false,
        });
      }
    },
  });

  useEffect(() => {
    if (analysis) {
      setAnalysisName(analysis?.name ?? '');
      setOutcomeValues(
        (analysis as any)?.outcome?.measure
          ? (analysis as any).outcome.measure.map(
              (outcome: IOutcomeMeasure) =>
                `${outcome.dataset}.${outcome.column}`
            )
          : []
      );
      setFeatureValues((analysis as any)?.features ?? []);
    }
  }, [analysis]);

  useEffect(() => {
    if (project && analysis) setAnalysisData(project, analysis);
  }, [project, analysis]);

  useEffect(() => {
    const analyses = !analysis
      ? project?.analyses ?? []
      : (project?.analyses ?? []).filter((a) => a.id !== analysis.id);
    if (
      analyses
        .map((a) => a.name.toLowerCase())
        .includes(analysisName.toLowerCase())
    ) {
      setNameError('This name is already used.');
    } else {
      setNameError('');
    }
  }, [project?.analyses, analysis, analysisName]);

  const saveAnalysis = useCallback(() => {
    if (!project) {
      console.error('Project not loaded. Please refresh.');
      return;
    }

    let updatedProject;

    if (analysis.type === AnalysisType.INTERNAL_BENCHMARK) {
      const benchmarkName = (analysis as any).internalBenchmark || '';

      updatedProject = {
        ...project,
        updatedAnalyses: [
          new InternalBenchmarkAnalysisModel(
            analysis.id,
            analysisName,
            analysis.type,
            '1.1.0',
            AnalysisStatus.READY,
            new Date(),
            new Date(),
            null,
            benchmarkName
          ),
        ],
        newAnalyses: [],
        deletedAnalyses: [],
      };

      const updatedPopulations = {};
      populations.forEach((population) => {
        updatedPopulations[population.title] = population.definition;
      });
      updatedProject.benchmarks[benchmarkName] = {
        Populations: updatedPopulations,
        GlobalFilter: {},
      };
    } else if (analysis.type === AnalysisType.RAW_VOICE) {
      const benchmarkName = (analysis as any).populationBenchmark || '';

      updatedProject = {
        ...project,
        updatedAnalyses: [
          new RawVoiceAnalysisModel(
            analysis.id,
            analysisName,
            analysis.type,
            '1.0.0',
            AnalysisStatus.READY,
            new Date(),
            new Date(),
            null,
            benchmarkName
          ),
        ],
        newAnalyses: [],
        deletedAnalyses: [],
      };

      const updatedPopulations = {};
      populations.forEach((population) => {
        updatedPopulations[population.title] = population.definition;
      });
      updatedProject.benchmarks[benchmarkName] = {
        Populations: updatedPopulations,
        GlobalFilter: {},
      };
    } else if (analysis.type === AnalysisType.DRIVERS_OF_OUTCOME) {
      const formattedOutcomes = outcomeValues.map((outcome: string) => {
        const [dataset, column] = outcome.split('.');
        return {
          dataset,
          column,
        };
      });

      updatedProject = {
        ...project,
        updatedAnalyses: [
          new DriversOfOutcomeAnalysisModel(
            analysis.id,
            analysisName,
            analysis.type,
            '1.1.0',
            AnalysisStatus.READY,
            new Date(),
            new Date(),
            populations[0],
            new OutcomeConfiguration(null, formattedOutcomes),
            featureValues,
            '',
            '',
            ''
          ),
        ],
        newAnalyses: [],
        deletedAnalyses: [],
      };
    } else if (analysis.type === AnalysisType.PROGRESS_OVER_TIME) {
      const benchmarkName = (analysis as any).overtimeBenchmark || '';

      updatedProject = {
        ...project,
        updatedAnalyses: [
          new OvertimeComparisonAnalysisModel(
            analysis.id,
            analysisName,
            analysis.type,
            '1.0.0',
            AnalysisStatus.READY,
            new Date(),
            new Date(),
            populations.map((p) => p.title),
            benchmarkName
          ),
        ],
        newAnalyses: [],
        deletedAnalyses: [],
      };

      const updatedPopulations = {};
      populations.forEach((population) => {
        updatedPopulations[population.title] = population.definition;
      });
      updatedProject.benchmarks[benchmarkName] = {
        Populations: updatedPopulations,
        GlobalFilter: {},
      };
    }
    updateProject.mutate(updatedProject);
  }, [
    project,
    analysis,
    analysisName,
    populations,
    outcomeValues,
    featureValues,
  ]);

  if (!analysis) {
    return null;
  }

  return (
    <SectionWrapper isFullHeight showBreadcrumbs>
      <DriversOutcomeModal
        opened={showDriversOutcomeModal}
        onConfirm={() => {
          setShowDriversOutcomeModal(false);
          saveAnalysis();
        }}
        onClose={() => setShowDriversOutcomeModal(false)}
        outcomeValues={outcomeValues}
        setOutcomeValues={setOutcomeValues}
        featureValues={featureValues}
        setFeatureValues={setFeatureValues}
      />
      <DndContext
        autoScroll={false}
        onDragStart={(e) => onDragStart(e.active)}
        onDragCancel={onDragCancel}
        onDragEnd={(e) => onDragEnd(e.over)}
        sensors={sensors}
        collisionDetection={rectIntersection}
        modifiers={[snapCenterToCursor]}
      >
        <Stack style={{ flexGrow: 1 }}>
          <Stack spacing={0}>
            <InlineEditableText
              textStyle={{
                fontWeight: 700,
                fontSize: '1.375rem',
                lineHeight: 1.4,
              }}
              value={analysisName}
              onChange={setAnalysisName}
            />
            {nameError && (
              <Text color="red" size="sm">
                {nameError}
              </Text>
            )}
          </Stack>
          <Stack mt="md" style={{ flexGrow: 1 }}>
            <DragOverlay>
              {dragging === 'column' && dragField && (
                <FieldItem value={dragField} isDraggedItem />
              )}
              {dragging === 'row' && (
                <Stack spacing={2} style={{ width: 200 }}>
                  {selectedDistributionRows.length === 1 ? (
                    <RowOverlay text="Item 1" />
                  ) : selectedDistributionRows.length === 2 ? (
                    <>
                      <RowOverlay text="Item 1" />
                      <RowOverlay text="Item 2" />
                    </>
                  ) : (
                    <>
                      <RowOverlay text="Item 1" />
                      <RowOverlay text="Item 2" />
                      <RowOverlay text="Item ..." />
                    </>
                  )}
                </Stack>
              )}
            </DragOverlay>

            <Grid style={{ flexGrow: 1 }} gutter="xl">
              <Grid.Col
                span={2}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <FieldsColumn />
              </Grid.Col>

              <Grid.Col
                span={8}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <ExplorationColumn />
              </Grid.Col>

              <Grid.Col
                span={2}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <PopulationsColumn />
              </Grid.Col>
            </Grid>
          </Stack>

          <Group mt="lg" position="apart">
            <Text c="red" size="xs">
              {
                ((updateProject.error as AxiosError)?.response?.data as any)
                  ?.Message
              }
            </Text>
            <Group>
              <Button
                onClick={() =>
                  navigate(`/projects/${project.projectId}/analyses/${analysis.id}`)
                }
                variant="subtle"
                color="dark"
              >
                Cancel
              </Button>
              <Button
                onClick={() =>
                  analysis.type === AnalysisType.INTERNAL_BENCHMARK
                    ? saveAnalysis()
                    : setShowDriversOutcomeModal(true)
                }
                loading={updateProject.isPending}
                disabled={
                  populations.length === 0 ||
                  analysisName.length <= 3 ||
                  !!nameError
                }
              >
                Save analysis
              </Button>
            </Group>
          </Group>
        </Stack>
      </DndContext>
    </SectionWrapper>
  );
};

const RowOverlay: FC<{ text: string }> = ({ text }) => (
  <Card
    bg="#e7f5ff"
    p="xs"
    py={2}
    radius="sm"
    style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}
  >
    <Text color="#228be6" size="sm" weight={700}>
      {text}
    </Text>
  </Card>
);

export default EditAnalysisPage;
