import {
  ActionIcon,
  Anchor,
  Divider,
  Group,
  Popover,
  Spoiler,
  Stack,
  Title,
  Tooltip,
} from '@mantine/core';
import { userFriendlyTopicThemeName } from '@utils/TopicUtils';
import { QuoteReader } from '@components/QuoteReader/QuoteReader';
import { FocalTopicFilter } from '@components/FocalTopicFilter';
import { CSSProperties, useState } from 'react';
import { useTopicStore } from '@stores/TopicStore';
import { IconWindowMaximize } from '@tabler/icons';
import { QuotesModal } from '@components/QuotesModal';

interface TopicPanelProps {
  style?: CSSProperties;
  showCoMentionTopic: boolean;
}

export const TopicPanel: React.FC<TopicPanelProps> = ({style, showCoMentionTopic}: TopicPanelProps) => {
  const topicDefinitions = useTopicStore((s) => s.topicDefinitions);
  const focalTopic = useTopicStore((s) => s.focalTopic);
  const setFocalTopic = useTopicStore((s) => s.setFocalTopic);
  const [showQuotesModal, setShowQuotesModal] = useState(false);

  const displayTopic = focalTopic
    ? userFriendlyTopicThemeName(focalTopic)
    : undefined;
  const topicDescription = focalTopic
    ? topicDefinitions[focalTopic]
    : undefined;

  return (
    <>
      <QuotesModal
        opened={showQuotesModal}
        onClose={() => setShowQuotesModal(false)}
      />
      <div style={{...style, width: '100%'}}>
        <Stack p="md" spacing={'xs'}>
          <Stack spacing={'xxs'}>
            <Group position="apart">
                <Title order={4}>
                  {!focalTopic ? <i>No Topic Selected</i> : displayTopic}
                </Title>
                <Popover withArrow>
                  <Popover.Target>
                    <Anchor size="xs" onClick={() => setFocalTopic(undefined)}>
                      Change topic
                    </Anchor>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <div style={{ width: '250px' }}>
                      <FocalTopicFilter/>
                    </div>
                  </Popover.Dropdown>
                </Popover>
            </Group>
            <Spoiler
              style={{ fontSize: 12, color: 'gray' }}
              maxHeight={20}
              showLabel="Show more"
              hideLabel="Hide"
            >
              {!focalTopic ? (
                <>
                  Select a topic in the chart to see more information and quotes
                </>
              ) : (
                topicDescription
              )}
            </Spoiler>
          </Stack>
          <Divider />
          <div style={{position: 'relative'}}>
            <div style={{position: 'absolute', right: 0}}>
              <Tooltip
                label={"Open in full screen"}
                openDelay={200}
                withinPortal
              >
                <ActionIcon
                  size={'sm'}
                  onClick={() => setShowQuotesModal(true)}
                >
                  <IconWindowMaximize />
                </ActionIcon>
              </Tooltip>
            </div>
            <QuoteReader showCoMentionTopic={showCoMentionTopic}/>
          </div>
        </Stack>
      </div>
    </>
  );
};
