import { ViewFilterMappingModel } from './ViewFilterMappingModel';

interface TableauViewInfo {
  viewId: string;
  pathPrefix: string;
}

class ViewModel {
  organizationId: number;
  viewId: string;
  viewType: 'embedded-data-view';
  title: string;
  tableauView: TableauViewInfo;
  datasources: any[];
  createdAt: Date;
  updatedAt: Date;
  filterMapping?: ViewFilterMappingModel;

  constructor(
    organizationId: number,
    viewId: string,
    viewType: 'embedded-data-view',
    title: string,
    tableauView: TableauViewInfo,
    datasources: any[],
    createdAt: Date,
    updatedAt: Date,
    filterMapping?: any
  ) {
    this.organizationId = organizationId;
    this.viewId = viewId;
    this.viewType = viewType;
    this.title = title;
    this.tableauView = tableauView;
    this.datasources = datasources;
    this.filterMapping = filterMapping;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  static fromAPIResponse(apiResponse: any): ViewModel {
    const organizationId = parseInt(apiResponse.OrganizationId);
    const viewId = apiResponse.ViewId;
    const viewType = apiResponse.ViewType;
    const title = apiResponse.Title;
    const tableauView: TableauViewInfo = {
      viewId: apiResponse.TableauInfo.ViewId,
      pathPrefix: apiResponse.TableauInfo.PathPrefix,
    };

    const datasources = apiResponse.Datasources;
    const createdAt = new Date(apiResponse.CreatedAt);
    const updatedAt = new Date(apiResponse.UpdatedAt);

    var filterMapping: any = undefined;
    if (
      apiResponse.FilterMapping !== undefined &&
      apiResponse.FilterMapping !== null &&
      Object.entries(apiResponse.FilterMapping).length !== 0
    ) {
      filterMapping = ViewFilterMappingModel.fromAPIResponse(
        apiResponse.FilterMapping
      );
    }

    return new ViewModel(
      organizationId,
      viewId,
      viewType,
      title,
      tableauView,
      datasources,
      createdAt,
      updatedAt,
      filterMapping
    );
  }
}

export default ViewModel;
