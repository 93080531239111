import { AnalysisType } from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import React from 'react';

// MARK: - Mantine Imports
import { Button, Group, Box, Skeleton } from '@mantine/core';
import { IconArrowLeft, IconDownload, IconEdit } from '@tabler/icons';
import { ChartType, useAnalysisStore } from '@stores/AnalysisStore';

// Local imports...
import {
  FocalPopulationFilter,
  FocalPopulationFilterSkeleton,
} from './FocalPopulationFilter';
import { CoMentionTypeFilter } from './CoMentionTypeFilter';
import { TopicThemeFilter, TopicThemeFilterSkeleton } from './TopicThemeFilter';
import { ModelAggregationButton } from './ModelAggregationButton';
import {
  NumberOfMentionsFilter,
  NumberOfMentionsFilterSkeleton,
} from './NumberOfMentionsFilter';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { useCurrentAnalysis } from '@hooks/useCurrentAnalysis';
import AnalysisSelector from './AnalysisSelector';
import Link from '@components/Link';
import { useCurrentSummary } from '@hooks/useCurrentSummary';
import { TopicPanelSwitch } from '@components/TopicPanelSwitch';

export const AnalysisDetailToolbar: React.FC = () => {
  const currentProject = useCurrentProject();
  const currentAnalysis = useCurrentAnalysis();
  const currentSummary = useCurrentSummary();
  const selectedChart = useAnalysisStore((s) => s.selectedChart);

  return (
    <Box
      pb={10}
      sx={(theme) => {
        return {
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
        };
      }}
    >
      <Group position="apart" h="100%">
        <Group spacing="xs">
          <Link
            to={currentSummary ? `/projects/${currentProject.projectId}/summaries/${currentSummary.id}` : `/projects/${currentProject.projectId}`}
            style={{ textDecoration: 'none', display: 'flex' }}
          >
            <IconArrowLeft size={18} color="black" />
          </Link>
          <AnalysisSelector />
        </Group>
        <Group spacing="xs">
          <Group>
            <FocalPopulationFilter />
            {selectedChart === ChartType.IN_SAME_BREATH && (
              <CoMentionTypeFilter />
            )}
            <Button.Group>
              {selectedChart !== ChartType.SUB_CULTURES &&
                selectedChart !== ChartType.BREAKDOWN_BY_POPULATION && (
                  <>
                    <TopicThemeFilter />
                  </>
                )}
              {currentAnalysis &&
                currentAnalysis.type === AnalysisType.DRIVERS_OF_OUTCOME &&
                (selectedChart === ChartType.DRIVES_OUTCOME ||
                  selectedChart === ChartType.OVERVIEW_OF_IMPORTANCE) && (
                  <ModelAggregationButton />
                )}
            </Button.Group>
            {selectedChart !== ChartType.BREAKDOWN_BY_POPULATION && (
              <NumberOfMentionsFilter />
            )}
            <TopicPanelSwitch />
          </Group>
        </Group>
      </Group>
    </Box>
  );
};

export const AnalysisDetailToolbarSkeleton: React.FC = () => {
  return (
    <>
      <Box
        pb={10}
        sx={(theme) => {
          return {
            borderBottom: `1px solid ${theme.colors.gray[2]}`,
          };
        }}
      >
        <Group position="apart" h="100%">
          <Group spacing="xs">
            <Link
              to={`/projects/`}
              style={{ textDecoration: 'none', display: 'flex' }}
            >
              <IconArrowLeft size={18} color="black" />
            </Link>
            <Skeleton height={30} width={150} animate />
          </Group>
          <Group spacing="xs">
            <Group>
              <FocalPopulationFilterSkeleton />
              <TopicThemeFilterSkeleton />
              <NumberOfMentionsFilterSkeleton />
            </Group>
            <Button.Group>
              <Button
                ml="lg"
                size="xs"
                leftIcon={<IconEdit size={18} />}
                disabled
                variant="default"
                color="dark"
                onClick={() => {}}
              >
                Edit
              </Button>
              <Button
                size="xs"
                leftIcon={<IconDownload size={18} />}
                disabled
                variant="default"
                color="dark"
                onClick={() => {}}
              >
                Download Data
              </Button>
            </Button.Group>
          </Group>
        </Group>
      </Box>
    </>
  );
};
