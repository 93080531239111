import React from 'react';
import DashboardAPI from '../apis/DashboardAPI';
import DashboardModel from '../models/DashboardModel';
import APIErrorMessage from '../components/APIErrorMessage';
import DashboardListItemComponent from '../components/DashboardListItemComponent';
import { DashboardListItemComponentSkeleton } from '../components/DashboardListItemComponent';
import { Container, SimpleGrid, Title } from '@mantine/core';
import SectionWrapper from '../components/SectionWrapper';
import { withTranslation, WithTranslation } from 'react-i18next';

interface ListDashboardsControllerState {
  dashboards?: DashboardModel[];
  listDashboardsError?: JSX.Element;
}

class ListDashboardsController extends React.Component<WithTranslation> {
  state: ListDashboardsControllerState = {};

  componentDidMount(): void {
    // Fetch the dashboards on load...
    this.getDashboards();
  }

  getDashboards(): void {
    DashboardAPI.listDashboards()
      .then((dashboards: DashboardModel[]) => {
        this.setState({
          dashboards: dashboards,
        });
      })
      .catch((error) => {
        this.setState({
          listDashboardsError: (
            <APIErrorMessage response={error} dismissable={false} />
          ),
        });
      });
  }

  render() {
    var dashboardObjects: JSX.Element;

    if (this.state.dashboards !== undefined) {
      // We have dashboards, so let's render them...
      dashboardObjects = (
        <SimpleGrid
          breakpoints={[
            { minWidth: 'sm', cols: 1 },
            { minWidth: 'md', cols: 2 },
            { minWidth: 1200, cols: 3 },
          ]}
        >
          {this.state.dashboards.map((dashboard: DashboardModel) => {
            return <DashboardListItemComponent dashboard={dashboard} />;
          })}
        </SimpleGrid>
      );
    } else {
      // We don't have dashboards, we need to render a loading screen...
      dashboardObjects = (
        <SimpleGrid
          breakpoints={[
            { minWidth: 'sm', cols: 1 },
            { minWidth: 'md', cols: 2 },
            { minWidth: 1200, cols: 3 },
          ]}
        >
          <DashboardListItemComponentSkeleton />
          <DashboardListItemComponentSkeleton />
        </SimpleGrid>
      );
    }

    if (
      this.state.listDashboardsError !== undefined &&
      this.state.listDashboardsError !== null
    ) {
      dashboardObjects = (
        <Container>{this.state.listDashboardsError}</Container>
      );
    }

    // Return a container that has the name of what we're looking at, etc...
    return (
      <SectionWrapper>
        <Title order={3}>
          {this.props.t('dashboards-list.dashboards-title')}
        </Title>
        <br />
        {dashboardObjects}
      </SectionWrapper>
    );
  }
}

export default withTranslation('dashboards')(ListDashboardsController);
