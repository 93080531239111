import { FC, useCallback, useEffect, useState } from 'react';
import { Button, Card, Grid, Group, Stack, Text } from '@mantine/core';
import SectionWrapper from '@components/SectionWrapper';
import { useNavigate } from '@hooks/useNavigate';
import { InlineEditableText } from '@components/InlineEditableText';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { useUpdateProject } from '@apis/hooks/useUpdateProject';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { AnalysesColumn } from '@components/SummaryEdition/AnalysesColumn';
import { AnalysesInSummaryColumn } from '@components/SummaryEdition/AnalysesInSummaryColumn';
import { useSumaryEditionStore } from '@stores/SummaryEditionStore';

export const CreateSummaryPage: FC = () => {
  const project = useCurrentProject();
  const [summaryName, setSummaryName] = useState('Plan of Action #1');
  const [nameError, setNameError] = useState('');
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const analysesInSummary = useSumaryEditionStore(s => s.analysesInSummary)
  const setSummaryData = useSumaryEditionStore(s => s.setSummaryData)
  const updateProject = useUpdateProject({
    onSuccess(data, variables) {
      queryClient.setQueryData(['project', data.projectId], data);
      const newSummary = data.summaries?.find(
        (a) =>
          a.Name ===
          [...variables.newSummaries, ...variables.newSummaries][0].Name
      );
      navigate(`/projects/${data.projectId}/summaries/${newSummary.Id}`);
    },
  });

  useEffect(() => setSummaryData(), [])

  const createSummary = useCallback(() => {
    if (!project) {
      console.error('Project not loaded. Please refresh.');
      return;
    }

    const updatedProject = {
      ...project,
      newSummaries: [{
        Name: summaryName,
        Analyses: analysesInSummary.map(a => ({
          AnalysisId: a.id,
          FocalPopulation: a.focalPopulation,
        })),
      }],
    };  
    updateProject.mutate(updatedProject);
  }, [project, summaryName, analysesInSummary]);

  return (
    <SectionWrapper isFullHeight showBreadcrumbs>
      <Stack style={{ flexGrow: 1 }}>
          <Stack spacing={0}>
            <InlineEditableText
              textStyle={{
                fontWeight: 700,
                fontSize: '1.375rem',
                lineHeight: 1.4,
              }}
              value={summaryName}
              onChange={setSummaryName}
            />
            {/*<Text color="dimmed" size="sm">{`Define population${ analysisType === AnalysisType.INTERNAL_BENCHMARK ? "s" : ""}`}</Text> */}
            {nameError && (
              <Text color="red" size="sm">
                {nameError}
              </Text>
            )}
          </Stack>
          <Stack mt="md" style={{ flexGrow: 1 }}>
            <Grid style={{ flexGrow: 1 }} gutter="xl">
              <Grid.Col
                span={4}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <AnalysesColumn/>
              </Grid.Col>

              <Grid.Col
                span={8}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <AnalysesInSummaryColumn />
              </Grid.Col>
            </Grid>
          </Stack>

          <Group mt="lg" position="apart">
            <Text c="red" size="xs">
              {
                ((updateProject.error as AxiosError)?.response?.data as any)
                  ?.Message
              }
            </Text>
            <Group>
              <Button
                onClick={() => navigate(`/projects/${project.projectId}`)}
                variant="subtle"
                color="dark"
              >
                Cancel
              </Button>
              <Button
                onClick={createSummary}
                loading={updateProject.isPending}
                disabled={
                  analysesInSummary.length === 0 ||
                  !!nameError
                }
              >
                Create summary
              </Button>
            </Group>
          </Group>
        </Stack>
    </SectionWrapper>
  );
};