import CRN from './Crn';
import APIDecodable from './APIDecodable';

export enum CheckDetail {
  NoMetadataAvailable = 'NoMetadataAvailable',
  NoTextColumns = 'NoTextColumns',
  NoProcessedVersions = 'NoProcessedVersions',
  NoDemosTextsVersions = 'NoDemosTextsVersions',
  NoTopicThemeMapping = 'NoTopicThemeMapping',
  NoLongCSI = 'NoLongCSI',
}

function isCheckDetail(key: string): key is keyof typeof CheckDetail {
  return Object.keys(CheckDetail).includes(key);
}

class DatasetModel implements APIDecodable {
  alias: string;
  description: string;
  organizationId: number;
  datasetId: string;
  genesisResourceCRN: string;
  metadataResourceCRN: string | null;
  demosTextsVersions: any[]; // Change to appropriate type if known
  processedVersions: any[]; // Change to appropriate type if known
  dateAdded: Date;
  dateUpdated: Date;
  principalCRN: string;
  checksPassed: boolean;
  checkDetails: CheckDetail[];

  constructor(
    alias: string,
    description: string,
    organizationId: number,
    datasetId: string,
    genesisResourceCRN: string,
    metadataResourceCRN: string | null,
    demosTextsVersions: any[],
    processedVersions: any[],
    dateAdded: Date,
    dateUpdated: Date,
    principalCRN: string,
    checksPassed: boolean,
    checkDetails: CheckDetail[]
  ) {
    this.alias = alias;
    this.description = description;
    this.organizationId = organizationId;
    this.datasetId = datasetId;
    this.genesisResourceCRN = genesisResourceCRN;
    this.metadataResourceCRN = metadataResourceCRN;
    this.demosTextsVersions = demosTextsVersions;
    this.processedVersions = processedVersions;
    this.dateAdded = dateAdded;
    this.dateUpdated = dateUpdated;
    this.principalCRN = principalCRN;
    this.checksPassed = checksPassed;
    this.checkDetails = checkDetails;
  }

  /**
   * Converts a JSON API response to a DatasetModel object.
   * @param apiResponse JSON API response object
   * @returns DatasetModel object
   */
  static fromAPIResponse(apiResponse: any): DatasetModel {
    const crn = apiResponse.CRN;
    const alias = apiResponse.Alias;
    const description = apiResponse.Description;
    const organizationId = apiResponse.OrganizationId;
    const datasetId = apiResponse.DatasetId;
    const genesisResourceCRN = apiResponse.GenesisResourceCRN;
    const metadataResourceCRN = apiResponse.MetadataResourceCRN;
    const demosTextsVersions = apiResponse.DemosTextsVersions;
    const processedVersions = apiResponse.ProcessedVersions;
    const dateAdded = new Date(apiResponse.DateAdded);
    const dateUpdated = new Date(apiResponse.DateUpdated);
    const principalCRN = apiResponse.PrincipalCRN;
    const checksPassed = apiResponse.ChecksPassed;
    const checkDetails = apiResponse.CheckDetails.map((detail: string) => {
      if (isCheckDetail(detail)) {
        return CheckDetail[detail];
      } else {
        // Handle the case when the provided string is not a valid key of the CheckDetail enum
        console.error(`Invalid CheckDetail: ${detail}`);
        return null;
      }
    }).filter((detail: CheckDetail | null) => detail !== null);

    return new DatasetModel(
      alias,
      description,
      organizationId,
      datasetId,
      genesisResourceCRN,
      metadataResourceCRN,
      demosTextsVersions,
      processedVersions,
      dateAdded,
      dateUpdated,
      principalCRN,
      checksPassed,
      checkDetails
    );
  }

  /**
   * Returns the CRN object for this dataset.
   * @returns CRN object
   */
  get crnObject(): CRN {
    return new CRN(this.organizationId, 'resource', 'dataset', this.datasetId);
  }
}

export default DatasetModel;
