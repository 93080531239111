import CRN from './Crn';
import ViewModel from './ViewModel';

class DashboardModel {
  crn: CRN;
  organizationId: number;
  dashboardId: string;
  title: string;
  description?: string;
  dashboardType: string;
  createdAt: Date;
  updatedAt: Date;
  views: ViewModel[];

  constructor(
    crn: CRN,
    organizationId: number,
    dashboardId: string,
    title: string,
    dashboardType: string,
    createdAt: Date,
    updatedAt: Date,
    views: ViewModel[],
    description?: string
  ) {
    this.crn = crn;
    this.organizationId = organizationId;
    this.dashboardId = dashboardId;
    this.title = title;
    this.description = description;
    this.dashboardType = dashboardType;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.views = views;
  }

  static fromAPIResponse(apiResponse: any): DashboardModel {
    const crn = CRN.fromString(apiResponse.Crn);
    const organizationId = parseInt(apiResponse.OrganizationId);
    const dashboardId = apiResponse.DashboardId;
    const title = apiResponse.Title;
    const description = apiResponse.Description;
    const dashboardType = apiResponse.DashboardType;
    const createdAt = new Date(apiResponse.CreatedAt);
    const updatedAt = new Date(apiResponse.UpdatedAt);
    const views: ViewModel[] = apiResponse.Views.map((view: any) =>
      ViewModel.fromAPIResponse(view)
    );

    return new DashboardModel(
      crn,
      organizationId,
      dashboardId,
      title,
      dashboardType,
      createdAt,
      updatedAt,
      views,
      description
    );
  }
}

export default DashboardModel;
