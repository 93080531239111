import { FC, useEffect, useMemo, useState } from 'react';
import {
  ActionIcon,
  Anchor,
  Button,
  Flex,
  Group,
  Input,
  SegmentedControl,
  Skeleton,
  Stack,
  Text,
} from '@mantine/core';
import { IconPlus, IconSearch, IconX } from '@tabler/icons-react';
import Fuse from 'fuse.js';
import { User, useUsers } from '@apis/hooks/userUsers';
import { UserList } from './UserList';
import { InviteUserModal } from './InviteUserModal';

const FuseOptions = {
  includeScore: false,
  includeMatches: true,
  minMatchCharLength: 2,
  threshold: 0.2,
  keys: [
    {
      name: 'username',
      weight: 2,
    },
    {
        name: 'firstName',
        weight: 1.5,
    },
    {
        name: 'lastName',
        weight: 1.5,
    },
    {
      name: 'email',
      weight: 2,
    },
  ],
};

export const SettingsTabUsers: FC = () => {
  const { data: users, isLoading } = useUsers();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [userType, setUserType] = useState('all')
  const [ showInviteUserModal, setShowInviteUserModal ] = useState(false);
  const [fuse, setFuse] = useState<Fuse<User>>(
    new Fuse(users, FuseOptions)
  );
  
  useEffect(() => {
    setFuse(new Fuse(users, FuseOptions));
  }, [users]);

  const SearchIcon =
    searchQuery === '' ? (
      <IconSearch size={14} color="lightgray" />
    ) : (
      <ActionIcon variant="transparent" onClick={() => setSearchQuery('')}>
        <IconX size={14} color="gray" />
      </ActionIcon>
    );

  const searchResult = useMemo(() => {
    if(!users) {
      return []
    }

    const result = searchQuery
      ? fuse.search(searchQuery)
      : users.map(
          (a) =>
            ({
              item: a,
              matches: [],
              refIndex: -1,
            } as Fuse.FuseResult<User>)
        );

        if(userType === "all") {
          return result
        }

        return result.filter(user => userType === "active" ? user.item.status === "ACTIVE" : user.item.status === "INVITED")
  }, [fuse, searchQuery, userType, users]);
  
  if (!users) {
    return (
        <Stack spacing={0}>
            <Flex justify="space-between">
                <Skeleton mt="md" height={20} width={100} radius="xl" />
                <Group spacing="xs">
                <Skeleton mt="md" height={20} width={100} radius="xl" />
                </Group>
            </Flex>
            <Skeleton mt="md" height={20} radius="xl" />
            <Skeleton mt="md" height={400} radius="md" />
        </Stack>
    );
  }

  return (    
    <Stack>
        <InviteUserModal opened={showInviteUserModal} onClose={() => setShowInviteUserModal(false)}/>
        <Flex justify="end" gap="md">
            <Input
                autoFocus
                style={{ minWidth: 300 }}
                rightSection={SearchIcon}
                placeholder="Search user"
                size="sm"
                onChange={(e) => setSearchQuery(e.currentTarget.value)}
                value={searchQuery}
            />
            <SegmentedControl 
                size='xs'
                value={userType}
                onChange={setUserType}
                data={[
                { label: 'All users', value: 'all' },
                { label: 'Active', value: 'active' },
                { label: 'Invited', value: 'invited' },
                ]}
            />
            <Button
                leftIcon={<IconPlus size={18} />}
                onClick={() => setShowInviteUserModal(true)}
            >
                Invite user
            </Button>
        </Flex>
        
        <UserList
          isLoading={isLoading}
          hasError={false}
          users={searchResult.map(r => r.item)}
        />

        {searchResult.length === 0 && (
          <Stack align="center" mt="xl" spacing="xs">
            <Text color="grey">No data</Text>
            {(!!searchQuery || userType !== "all") && <Anchor
              c="red"
              onClick={() => {
                setSearchQuery('');
                setUserType('all')
              }}
            >
              Remove filters
            </Anchor>}
          </Stack>
        )}
      </Stack>
  );
};