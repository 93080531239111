import { FC, useState } from 'react';
import SectionWrapper from '../components/SectionWrapper';
import {
  Stack,
  Tabs,
  Title,
  Text,
  Group,
  Button,
} from '@mantine/core';
import { IconNewSection, IconPlus, IconUser } from '@tabler/icons-react';
import { SettingsTabUsers } from '@components/SettingsTabUsers'
import { useRouteStore } from '@stores/RouteStore';
import { NewOrganizationModal } from '@components/NewOrganizationModal';
import { useOrganizationId } from '@apis/APIBase';

export const SettingsPage: FC = () => {
  const [showNewOrganizationModal, setShowNewOrganizationModal ] = useState(false)
  const [organizationId, isLoading] = useOrganizationId();
  const isAdmin = organizationId === 90210 || organizationId === 100001

  return <>
    <NewOrganizationModal opened={showNewOrganizationModal} onClose={() => setShowNewOrganizationModal(false)}/>
    <SectionWrapper isFullHeight showBreadcrumbs>
      <Stack>
        <Group position="apart" align="start">
          <Stack spacing={0}>
            <Title order={2}>Settings</Title>
            <Text color="dimmed" size="sm">Make changes to your organization's settings here.</Text>
          </Stack>
          { isAdmin && <Button
                leftIcon={<IconNewSection size={18} />}
                onClick={() => setShowNewOrganizationModal(true)}
            >
              Create organization
          </Button> }
        </Group>
        <Tabs defaultValue="users">
            <Tabs.List>
                <Tabs.Tab value="users" icon={<IconUser size={14} />}>Users</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="users" pt="xs">
                <SettingsTabUsers />
            </Tabs.Panel>
            </Tabs>
      </Stack>
    </SectionWrapper>
  </>;
};
