import React, { ReactComponentElement } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import TableauEmbeddedView from '../components/TableauEmbeddedView';
import DashboardModel from '../models/DashboardModel';
import DashboardAPI from '../apis/DashboardAPI';

import ViewModel from '../models/ViewModel';
import APIErrorMessage from '../components/APIErrorMessage';
import {
  Center,
  Grid,
  Container,
  Skeleton,
  Title,
  Select,
} from '@mantine/core';
import SectionWrapper from '../components/SectionWrapper';
import QuoteReader from '../components/QuoteReader';
import QuoteReaderFilterObject from '../models/ViewFilterMappingModel';
import CRN from '../models/Crn';
import { withTranslation, WithTranslation } from 'react-i18next';
import APIRequesterBase from '../apis/APIBase';

// Import dispatch code to update the breadcrumbs...
import { updateItems } from '../redux/breadcrumbsSlice';
import { connect } from 'react-redux';

interface DashboardControllerProps extends WithTranslation {
  organizationId?: number;
  dashboardId?: string;
  roleToken: string | null;
}

type DashboardControllerState = {
  dashboard?: DashboardModel;
  trustedTicket?: string;
  activeViewId?: string;
  error: any | null;

  quoteCRN?: CRN;
  quoteFilters?: QuoteReaderFilterObject;
};

class DashboardController extends React.Component<DashboardControllerProps> {
  state: DashboardControllerState = {
    error: null,
  };

  headerRef: any = React.createRef();

  componentDidMount() {
    this.getDashboard();
  }

  getTrustedTicket() {
    if (this.state.dashboard !== undefined && this.state.dashboard !== null) {
      DashboardAPI.getTrustedTicket(this.state.dashboard)
        .then((trustedTicket: string) => {
          this.setState({
            trustedTicket: trustedTicket,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            error: (
              <Center>
                <APIErrorMessage dismissable={false} response={error} />
              </Center>
            ),
          });
        });
    }
  }

  queryInitialQuotes() {
    // get the current view
    const view: ViewModel = this.getViewFromId(this.state.activeViewId!);
    if (view.filterMapping) {
      console.log('Querying initial quotes..');
      // get the initial query...
      const initialFilters = view.filterMapping.initialFilters;
      if (initialFilters) {
        // get the CRN from the sheet we're taking demographics from...
        const crn: CRN =
          view.filterMapping.quoteDatasets[initialFilters.sheetName];
        var filterObject = initialFilters.filterObject;

        // Use the demographic mapping to populate the requested demographics...
        if (view.filterMapping.demographicRetrieval) {
          const demographicRetrieval =
            view.filterMapping.demographicRetrieval[initialFilters.sheetName];
          if (demographicRetrieval) {
            filterObject.requestedDemographics = demographicRetrieval;
          }
        }

        this.setState({
          quoteCRN: crn,
          quoteFilters: filterObject,
        });
      }
    }
  }

  async getDashboard() {
    var organizationId: number;
    if (
      this.props.organizationId !== null &&
      this.props.organizationId !== undefined
    ) {
      organizationId = this.props.organizationId;
    } else {
      organizationId = await APIRequesterBase.getCurrentOrganizationId();
    }

    DashboardAPI.getDashboard(organizationId, this.props.dashboardId!)
      .then((dashboard: DashboardModel) => {
        // populate the dashboard by converting the response to a DashboardModel
        const currentViewId =
          dashboard.views.length > 0 ? dashboard.views[0].viewId : undefined;
        this.setState(
          {
            dashboard: dashboard,
            activeViewId: currentViewId, // Set the initial view to the first view in the dashboard
          },
          () => {
            this.getTrustedTicket();
            this.queryInitialQuotes();
          }
        );
      })
      .catch((error: any) => {
        this.setState({
          error: (
            <Center>
              <APIErrorMessage dismissable={false} response={error} />
            </Center>
          ),
        });
      });
  }

  formatViewSelector() {
    if (this.state.dashboard!.views.length > 0) {
      var viewSelectorOptions: any[] = [];
      this.state.dashboard!.views.forEach((view: ViewModel, index) => {
        const viewID = view.viewId;
        const option = {
          value: viewID,
          label: view.title,
        };
        viewSelectorOptions.push(option);
      });
      return (
        <Select
          description="Select an option below to change the active view"
          searchable
          nothingFound={this.props.t(
            'dashboards-detail.search-no-views-found-text'
          )}
          value={this.state.activeViewId}
          data={viewSelectorOptions}
          onChange={(value: string) => {
            this.setState(
              {
                activeViewId: value,
              },
              () => {
                this.getTrustedTicket();
                this.queryInitialQuotes();
              }
            );
          }}
          styles={{
            input: {
              // border: "none",
              // borderRadius: 0,
              fontWeight: 'bold',
              fontSize: '1.2rem',
              // paddingLeft: "0px",
              cursor: 'pointer',
            },
          }}
        ></Select>
      );
    } else {
      return [];
    }
  }

  getViewFromId(viewId: string): ViewModel {
    // Look for the view in the list of views by the viewId
    if (this.state.dashboard !== undefined) {
      for (const view of this.state.dashboard.views) {
        if (view.viewId === viewId) {
          return view;
        }
      }

      return this.state.dashboard!.views[0];
    } else {
      return this.state.dashboard!.views[0];
    }
  }

  render() {
    const clientHeight = this.headerRef.current
      ? this.headerRef.current.clientHeight
      : 82;

    var dashboardView: any;
    const dashboardTitle = this.state.dashboard ? (
      <Title order={3}>{this.state.dashboard.title}</Title>
    ) : (
      <Skeleton height={8} mt={6} radius="xl" width="35%" />
    );
    const dashboardDescription = this.state.dashboard ? (
      <p>{this.state.dashboard.description}</p>
    ) : (
      <div>
        <Skeleton height={8} mt={6} radius="xl" width="50%" />
        <Skeleton height={8} mt={6} radius="xl" width="50%" />
      </div>
    );
    if (this.state.dashboard !== undefined) {
      var embeddedView: any = null;

      if (this.state.trustedTicket !== undefined) {
        const view: ViewModel = this.getViewFromId(this.state.activeViewId!);
        if (view) {
          embeddedView = (
            <div
              style={{ position: 'relative', width: '100%', height: '100%' }}
            >
              <TableauEmbeddedView
                view={view}
                trustedTicket={this.state.trustedTicket}
                onQuoteReaderFilterChange={(
                  crn: CRN,
                  filters: QuoteReaderFilterObject
                ) =>
                  this.setState({
                    quoteCRN: crn,
                    quoteFilters: filters,
                  })
                }
              />
            </div>
          );
        }

        if (
          view.filterMapping &&
          Object.entries(view.filterMapping).length > 0
        ) {
          dashboardView = (
            <Grid>
              <Grid.Col span={9}>
                {this.formatViewSelector()}
                {embeddedView}
              </Grid.Col>
              <Grid.Col span={3}>
                <QuoteReader
                  crn={this.state.quoteCRN}
                  filtersEnabled={false}
                  filters={this.state.quoteFilters}
                  coreProps={{
                    quotes: [],
                    allowFlagging: true,
                    allowReporting: true,
                    profileEnabled: true,
                    focalSentence: '',
                    showTopics: true,
                    showInfo: true,

                    topicToValueMapping: {},
                    topicDescriptions: {},
                    valueDescriptions: {},
                  }}
                  filterProps={{
                    datasetFilterEnabled: true,
                    datasetOptions: [],

                    sentimentFilterEnabled: true,
                    sentimentOptions: [],

                    demographicFilterEnabled: true,
                    demographicOptions: [],

                    demographicValueMapping: {},

                    valuesFilterEnabled: true,
                    valuesOptions: [],

                    topicsFilterEnabled: true,
                    topicsOptions: [],
                  }}
                />
              </Grid.Col>
            </Grid>
          );
        } else {
          dashboardView = (
            <Grid>
              <Grid.Col span={12}>
                {this.formatViewSelector()}
                {embeddedView}
              </Grid.Col>
            </Grid>
          );
        }
      } else {
        dashboardView = (
          <Grid>
            <Grid.Col span={12}>
              {this.formatViewSelector()}
              <Skeleton height={500} mt={6} radius="sm" />
            </Grid.Col>
          </Grid>
        );
      }
    } else {
      dashboardView = (
        <div>
          <Skeleton height={500} mt={6} radius="sm" />
        </div>
      );
    }

    return (
      <SectionWrapper>
        {dashboardTitle}
        {dashboardDescription}
        {dashboardView}
      </SectionWrapper>
    );
  }
}

const mapDispatchToProps = {
  updateBreadcrumbs: updateItems,
};

export default connect(
  null,
  mapDispatchToProps
)(
  withTranslation('dashboards')((props: any) => {
    const [searchParams] = useSearchParams();

    // get the organizationId,
    // get the dashboardId
    // get the roleToken.

    const { organizationId, dashboardId } = useParams();

    var organizationIdFormatted = 0;

    if (organizationId === undefined) {
      // We need to look for the provided organizationId in the search params...
      const paramOrganizationId = searchParams.get('orgId');
      if (organizationId !== undefined && organizationId !== null) {
        organizationIdFormatted = parseInt(organizationId);
      } else if (
        paramOrganizationId !== undefined &&
        paramOrganizationId !== null
      ) {
        organizationIdFormatted = parseInt(paramOrganizationId);
      } else {
        organizationIdFormatted = APIRequesterBase.getConfig().organizationId;
      }
    } else {
      if (organizationId !== undefined && organizationId !== null) {
        organizationIdFormatted = parseInt(organizationId);
      }
    }

    const roleToken = searchParams.get('rtid');

    return (
      <DashboardController
        organizationId={organizationIdFormatted}
        dashboardId={dashboardId}
        roleToken={roleToken}
        {...props}
      />
    );
  })
);
