import { useQuery } from '@tanstack/react-query';
import ProjectAPI from '@apis/ProjectAPI';
import SharedLinkModel from '@models/SharedLinkModel';

export interface SharedLink {
  id: string;
  model: SharedLinkModel;
  projectId: string;
  createdAt: Date;
  link: string;
  permissions: {
    download: string[];
    write: string[];
    read: string[];
  };
  permissionsResume: string;
  expiration: Date | null;
  status: 'ENABLED' | 'REVOKED';
}

const transformSharedLinks = ({
  data,
  projectId,
}: {
  data: SharedLinkModel[];
  projectId: string;
}): SharedLink[] => {
  return data
    .map((model) => {
      if (model.Tokens.length === 0) {
        return null;
      }

      let permissionsResume = 'Custom';
      if (
        model.Permissions.Read.length === 2 &&
        model.Permissions.Write.length === 1 &&
        model.Permissions.Download.length === 2
      ) {
        permissionsResume = 'Read and Write';
      } else if (
        model.Permissions.Read.length === 2 &&
        model.Permissions.Write.length === 0 &&
        model.Permissions.Download.length === 2
      ) {
        permissionsResume = 'Read';
      }

      return {
        id: model.Tokens[0].Token,
        model,
        projectId,
        createdAt: new Date(model.Tokens[0]?.DateAdded),
        link: `https://app.culturex.com/projects/${projectId}?organizationId=${model.Tokens[0].OrganizationID}&rtId=${model.Tokens[0].Token}`,
        permissions: {
          download: model.Permissions.Download,
          write: model.Permissions.Write,
          read: model.Permissions.Read,
        },
        permissionsResume,
        expiration: model.Expiration,
        status: model.Tokens[0].Status,
      };
    })
    .filter((sl) => sl !== null);
};

export const useProjectSharedLinks = (projectId: string) => {
  return useQuery({
    queryKey: ['project-shared-links', projectId],
    queryFn: () =>
      ProjectAPI.getProjectSharedLinks(projectId).then((data) => ({
        data,
        projectId,
      })),
    select: transformSharedLinks,
    enabled: !!projectId,
    staleTime: 1000 * 60 * 10, // 10 minutes
  });
};
