import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface AnalysisInProcess {
  projectId: string;
  analysisId: string;
}

type ProjectTab = "SUMMARIES" | "CUSTOM"  | "DEFAULT";

interface AppStore {
  projectTab: ProjectTab,
  setProjectTab: (value: ProjectTab) => void,
  showTopicPanel: boolean;
  setShowTopicPanel: (value: boolean) => void;
  showSummaryValues: boolean;
  setSummaryShowValues:(value: boolean) => void;
  analysesInProcess: AnalysisInProcess[];
  setAnalysesInProcess: (value: AnalysisInProcess[]) => void;
  addAnalysisInProcess: (projectId: string, analysisId: string) => void;
}

export const useAppStore = create<AppStore>()(
  persist(
    (set, get) => ({
      projectTab: "CUSTOM",
      setProjectTab: (projectTab: ProjectTab) => set({projectTab}),
      showTopicPanel: true,
      setShowTopicPanel: (value) => set({ showTopicPanel: value }),
      showSummaryValues: false,
      setSummaryShowValues: (value) => set({ showSummaryValues: value }),
      analysesInProcess: [],
      setAnalysesInProcess: (value) => set({ analysesInProcess: value }),
      addAnalysisInProcess: (projectId: string, analysisId: string) =>
        set({
          analysesInProcess: [
            ...get().analysesInProcess,
            { projectId, analysisId },
          ],
        }),
    }),
    {
      name: 'culturex-app-store',
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) => ({
        showSummaryValuesOnHover: state.showSummaryValues,
        showTopicPanel: state.showTopicPanel,
      }),
    }
  )
);
