import { createSlice } from '@reduxjs/toolkit';

interface BreadcrumbsItem {
  to: string;
  label: string;
}

interface BreadcrumbsState {
  items: BreadcrumbsItem[];
}

const initialState: BreadcrumbsState = {
  items: [
    {
      to: '/dashboards',
      label: 'Dashboards',
    },
  ],
};

export const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    updateItems: (state, action) => {
      state.items = action.payload;
    },
  },
});

export const { updateItems } = breadcrumbsSlice.actions;

export default breadcrumbsSlice.reducer;
