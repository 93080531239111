import type { SelectItem } from '@mantine/core';

interface FilterData {
  data: SelectItem[];
  limit: number;
  searchable: boolean;
  searchValue: string;
  filter(value: string, selected: boolean, item: SelectItem): boolean;
  value: string[];
  maxSelectedValues?: number;
  disableSelectedItemFiltering?: boolean;
}

export function filterData({
  data,
  searchable,
  limit,
  searchValue,
  maxSelectedValues,
  filter,
  value,
  disableSelectedItemFiltering,
}: FilterData) {
  if (!searchable && value.length === 0) {
    return data;
  }

  // MARK: - Searchable is enabled...
  if (!searchable) {
    const result = [];
    for (let i = 0; i < data.length; i += 1) {
      const isSelected = value.some(
        (val) => val === data[i].value && !data[i].disabled
      );
      if (maxSelectedValues) {
        result.push({
          ...data[i],
          selected: isSelected,
          disabled: !isSelected && value.length === maxSelectedValues,
        });
      } else {
        result.push({ ...data[i], selected: isSelected, disabled: false });
      }
    }

    return result;
  }

  // MARK: - Searchable is disabled...
  const result = [];
  for (let i = 0; i < data.length; i += 1) {
    const isSelected = value.some(
      (val) => val === data[i].value && !data[i].disabled
    );
    if (
      filter(searchValue, !disableSelectedItemFiltering && isSelected, data[i])
    ) {
      if (maxSelectedValues) {
        result.push({
          ...data[i],
          selected: isSelected,
          disabled: !isSelected && value.length === maxSelectedValues,
        });
      } else {
        result.push({ ...data[i], selected: isSelected, disabled: false });
      }
    }

    if (result.length >= limit) {
      break;
    }
  }

  return result;
}
