import Link from '@components/Link';
import { Tooltip, ThemeIcon, ActionIcon } from '@mantine/core';
import { IconSettings } from '@tabler/icons';
import { FC } from 'react';

const SettingsButton: FC = () => {
  return (
    <Tooltip label={'Settings'} openDelay={250}>
      <div>
        <ActionIcon
          size="lg"
          component={Link}
          to="/settings"
          color="gray"
          variant="light"
        >
          <IconSettings size={20} />
        </ActionIcon>
      </div>
    </Tooltip>
  );
};

export default SettingsButton;
