import {
  ActionIcon,
  Card,
  Flex,
  Group,
  Slider,
  Stack,
  Text,
  TextInput,
  TypographyStylesProvider,
} from '@mantine/core';
import { FC, forwardRef, useCallback, useId, useMemo, useState } from 'react';

import { IconGripVertical, IconSearch, IconX } from '@tabler/icons-react';
import { DatasetField } from '@hooks/useDatasetFields';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { useAnalysisEditionStore } from '@stores/AnalysisEditionStore';
import FieldItem from '@components/AnalysisEdition/FieldItem';

const ExplorationDroppableArea: FC = () => {
  const activeFields = useAnalysisEditionStore((s) => s.activeFields);
  const dragField = useAnalysisEditionStore((s) => s.dragField);

  const columns = useMemo(() => {
    if (dragField !== undefined) {
      const indexOfDrag = activeFields.findIndex((f) => f.id === dragField.id);

      // draggingfrom field list
      if (indexOfDrag === -1) {
        if (activeFields.length === 0) {
          return [
            <DistributionTableDroppableColumn
              key="empt_0"
              action="push"
              position={0}
              dragFieldAlias={dragField.alias}
            />,
          ];
        } else if (activeFields.length === 1) {
          return [
            <DistributionTableDroppableColumn
              key="empt_0"
              action="push"
              position={0}
              dragFieldAlias={dragField.alias}
            />,
            <DistributionTableDroppableColumn
              key="used_0"
              action="replace"
              position={0}
              field={activeFields[0]}
              dragFieldAlias={dragField.alias}
            />,
            <DistributionTableDroppableColumn
              key="empt_1"
              action="push"
              position={1}
              dragFieldAlias={dragField.alias}
            />,
          ];
        } else if (activeFields.length === 2) {
          return [
            <DistributionTableDroppableColumn
              key="empt_0"
              action="push"
              position={0}
              dragFieldAlias={dragField.alias}
            />,
            <DistributionTableDroppableColumn
              key="used_0"
              action="replace"
              position={0}
              field={activeFields[0]}
              dragFieldAlias={dragField.alias}
            />,
            <DistributionTableDroppableColumn
              key="empt_1"
              action="push"
              position={1}
              dragFieldAlias={dragField.alias}
            />,
            <DistributionTableDroppableColumn
              key="used_1"
              action="replace"
              position={0}
              field={activeFields[1]}
              dragFieldAlias={dragField.alias}
            />,
            <DistributionTableDroppableColumn
              key="empt_2"
              action="push"
              position={2}
              dragFieldAlias={dragField.alias}
            />,
          ];
        } else if (activeFields.length === 3) {
          return [
            <DistributionTableDroppableColumn
              key="used_0"
              action="replace"
              position={0}
              field={activeFields[0]}
              dragFieldAlias={dragField.alias}
            />,
            <DistributionTableDroppableColumn
              key="used_1"
              action="replace"
              position={1}
              field={activeFields[1]}
              dragFieldAlias={dragField.alias}
            />,
            <DistributionTableDroppableColumn
              key="used_2"
              action="replace"
              position={2}
              field={activeFields[2]}
              dragFieldAlias={dragField.alias}
            />,
          ];
        }
      }
      // draggingfrom field colum
      else {
        if (activeFields.length === 1) {
          return [
            <DistributionTableDroppableColumn
              key="used_0"
              action="forbidden"
              position={0}
              field={activeFields[0]}
              dragFieldAlias={dragField.alias}
            />,
          ];
        } else if (activeFields.length === 2) {
          return [
            indexOfDrag !== 0 ? (
              <DistributionTableDroppableColumn
                key="empt_0"
                action={'move'}
                position={0}
                dragFieldAlias={dragField.alias}
              />
            ) : null,
            <DistributionTableDroppableColumn
              key="used_0"
              action="forbidden"
              position={0}
              field={activeFields[0]}
              dragFieldAlias={dragField.alias}
            />,
            null,
            <DistributionTableDroppableColumn
              key="used_1"
              action="forbidden"
              position={0}
              field={activeFields[1]}
              dragFieldAlias={dragField.alias}
            />,
            indexOfDrag !== 1 ? (
              <DistributionTableDroppableColumn
                key="empt_2"
                action={'move'}
                position={1}
                dragFieldAlias={dragField.alias}
              />
            ) : null,
          ];
        } else if (activeFields.length === 3) {
          return [
            indexOfDrag !== 0 ? (
              <DistributionTableDroppableColumn
                key="empt_0"
                action={'move'}
                position={0}
                dragFieldAlias={dragField.alias}
              />
            ) : null,
            <DistributionTableDroppableColumn
              key="used_0"
              action="forbidden"
              position={0}
              field={activeFields[0]}
              dragFieldAlias={dragField.alias}
            />,
            indexOfDrag !== 0 && indexOfDrag !== 1 ? (
              <DistributionTableDroppableColumn
                key="empt_1"
                action={'move'}
                position={1}
                dragFieldAlias={dragField.alias}
              />
            ) : null,
            <DistributionTableDroppableColumn
              key="used_1"
              action="forbidden"
              position={0}
              field={activeFields[1]}
              dragFieldAlias={dragField.alias}
            />,
            indexOfDrag !== 1 && indexOfDrag !== 2 ? (
              <DistributionTableDroppableColumn
                key="empt_2"
                action={'move'}
                position={1}
                dragFieldAlias={dragField.alias}
              />
            ) : null,
            <DistributionTableDroppableColumn
              key="used_2"
              action="forbidden"
              position={0}
              field={activeFields[2]}
              dragFieldAlias={dragField.alias}
            />,
            indexOfDrag !== 2 ? (
              <DistributionTableDroppableColumn
                key="empt_3"
                action={'move'}
                position={2}
                dragFieldAlias={dragField.alias}
              />
            ) : null,
          ];
        }

        if (indexOfDrag === 0) {
          return [
            <DistributionTableDroppableColumn
              key="empt_0"
              action="forbidden"
              position={0}
              dragFieldAlias={dragField.alias}
            />,
            activeFields
              .slice(1)
              .map((f, i) => (
                <DistributionTableDroppableColumn
                  key={'empt_' + i + 1}
                  action="push"
                  position={0}
                  dragFieldAlias={dragField.alias}
                />
              )),
          ];
        }
      }
    } else {
      return [
        ...activeFields.map((usedField) => (
          <DistributionTableColumnn
            key={`DistributionTableColumnn_${usedField.id}`}
            field={usedField}
            canDrag={activeFields.length > 1}
          />
        )),
        activeFields.length > 0 ? (
          <Card
            key="distribution_card"
            py={8}
            pl={16}
            radius="sm"
            withBorder
            style={{ height: 40, verticalAlign: 'center' }}
            padding="xs"
          >
            <Text
              style={{
                maxWidth: 200,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              size="sm"
              weight={500}
            >
              # Responses{' '}
              <Text span color="dimmed">
                (with text)
              </Text>
            </Text>
          </Card>
        ) : null,
      ];
    }
  }, [activeFields, dragField]);

  return (
    <Group
      align="start"
      style={{
        position: 'absolute',
        inset: 0,
        height: dragField ? '100%' : '50px',
      }}
      grow
    >
      {columns.filter((c) => c !== null)}
    </Group>
  );
};

interface DistributionTableDroppableColumnProps {
  field?: DatasetField;
  dragFieldAlias: string;
  action: 'push' | 'replace' | 'forbidden' | 'hidden' | 'move';
  position: number;
}

const DistributionTableDroppableColumn: FC<
  DistributionTableDroppableColumnProps
> = ({ field, action, position, dragFieldAlias }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: useId(),
    disabled: action === 'forbidden' || action === 'hidden',
    data: {
      action,
      position,
      accepts: ['column'],
    },
  });

  if (action === 'hidden') {
    return null;
  }

  return (
    <Stack
      ref={setNodeRef}
      style={{
        height: '100%',
        border: action !== 'forbidden' ? 'dashed 1px grey' : '',
        borderRadius: '10px',
      }}
    >
      {field ? <FieldItem value={field} /> : <div style={{ height: 49 }} />}
      <Stack justify="center" style={{ height: '100%', paddingBottom: 49 }}>
        {isOver && action === 'replace' && (
          <TypographyStylesProvider>
            <div
              style={{ textAlign: 'center', padding: 20, color: 'grey' }}
              dangerouslySetInnerHTML={{
                __html: `<p>Drop here to replace <b>${
                  field.alias ?? field.field
                }</b> column by <b>${dragFieldAlias}</b></p>`,
              }}
            />
          </TypographyStylesProvider>
        )}
        {isOver && action === 'push' && (
          <TypographyStylesProvider>
            <div
              style={{ textAlign: 'center', padding: 20, color: 'gray' }}
              dangerouslySetInnerHTML={{
                __html: `<p>Drop here to add a new <b>${dragFieldAlias}</b> column</p>`,
              }}
            />
          </TypographyStylesProvider>
        )}
        {isOver && action === 'move' && (
          <TypographyStylesProvider>
            <div
              style={{ textAlign: 'center', padding: 20, color: 'gray' }}
              dangerouslySetInnerHTML={{
                __html: `<p>Move <b>${dragFieldAlias}</b> column here</p>`,
              }}
            />
          </TypographyStylesProvider>
        )}
      </Stack>
    </Stack>
  );
};

interface DistributionTableColumnnProps {
  field: DatasetField;
  canDrag: boolean;
}

const DistributionTableColumnn: FC<DistributionTableColumnnProps> = ({
  field,
  canDrag,
}) => {
  const activeFields = useAnalysisEditionStore((s) => s.activeFields);
  const setActiveFields = useAnalysisEditionStore((s) => s.setActiveFields);

  const onRemove = useCallback(() => {
    setActiveFields(activeFields.filter((f) => f.id !== field.id));
  }, [activeFields, setActiveFields, field.id]);

  return (
    <div style={{}}>
      {field && (
        <FieldItem value={field} onRemove={onRemove} canDrag={canDrag} />
      )}
    </div>
  );
};

export default ExplorationDroppableArea;
