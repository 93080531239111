import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ActionIcon,
  Anchor,
  Flex,
  Group,
  Indicator,
  Loader,
  Popover,
  ScrollArea,
  Select,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  useMantineColorScheme,
} from '@mantine/core';
import { IconSearch, IconX, IconFilter } from '@tabler/icons';
import FieldItem from '@components/AnalysisEdition/FieldItem';
import { useProjectDatasets } from '@apis/hooks/useProjectDatasets';
import {
  FieldSection,
  DatasetField,
  useDatasetFields,
} from '@hooks/useDatasetFields';
import { SimpleDataType } from '@components/mantineQueryBuilder/widgets/core/MantineFieldSelect';
import { useAnalysisEditionStore } from '@stores/AnalysisEditionStore';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { useCurrentAnalysis } from '@hooks/useCurrentAnalysis';
import Fuse from 'fuse.js';
import VirtualizedList from '@components/VirtualizedList';
import { VirtualItem } from '@tanstack/react-virtual';
import { Analysis, useProjectAnalyses } from '@hooks/useProjectAnalyses';
import { useFuse } from '@hooks/useFuse';
import { AnalysisItem } from './AnalysisItem';
import { useCustomAnalyses } from '@hooks/useCustomAnalyses';
import { useDefaultAnalyses } from '@hooks/useDefaultAnalyses';

const FuseOptions = {
  includeScore: true,
  includeMatches: true,
  minMatchCharLength: 2,
  threshold: 0.2,
  keys: [
    {
      name: 'name',
      weight: 2,
    },
    {
      name: 'populations',
      weight: 0.2,
    },
  ],
};

export const AnalysesColumn: FC = () => {
  const project = useCurrentProject();
  const analyses = useProjectAnalyses(project);
  const customAnalyses = useCustomAnalyses(project, analyses)
  const deafultAnalyses = useDefaultAnalyses(project, analyses)
  const colorScheme = useMantineColorScheme().colorScheme;
  const [searchQuery, setSearchQuery] = useState<string>('');
  const customAnalysesResult = useFuse<Analysis>(customAnalyses ?? [], FuseOptions, searchQuery);
  const defaultAnalysesResult = useFuse<Analysis>(deafultAnalyses ?? [], FuseOptions, searchQuery);


  const SearchIcon =
    searchQuery === '' ? (
      <IconSearch size={14} color="lightgray" />
    ) : (
      <ActionIcon variant="transparent" onClick={() => setSearchQuery('')}>
        <IconX size={14} color="gray" />
      </ActionIcon>
    );

        if(!project) {
            return null
        }
  return (
    <Stack style={{ flexGrow: 1 }}>
      <Text>
        1. Select which analysis do you want to include in your plan of action.
      </Text>

        <TextInput
          size="xs"
          mr={12}
          placeholder="Search analyses..."
          rightSection={SearchIcon}
          onChange={(e) => setSearchQuery(e.currentTarget.value)}
          value={searchQuery}
          
        />
      
      <Stack style={{ flexGrow: 1, position: 'relative' }}>
        <ScrollArea style={{ position: 'absolute', inset: 0 }} offsetScrollbars>
          <Stack spacing="xl">
          {customAnalysesResult.length > 0 && (
              <Stack spacing="sm">
                <Text
                  style={{ position: 'sticky', top: 0, zIndex: 10 }}
                  bg={colorScheme === 'light' ? 'white' : '#1A1B1E'}
                  weight={500}
                  color="grey"
                >
                  CUSTOM ANALYSES
                </Text>
                <VirtualizedList data={customAnalysesResult} spacing={10} itemHeight={40}>
                  {(vi: VirtualItem) => (
                      <AnalysisItem analysis={customAnalysesResult[vi.index].item} matches={customAnalysesResult[vi.index].matches} />
                  )}
              </VirtualizedList>
              </Stack>
            )}
            {defaultAnalysesResult.length > 0 && (
              <Stack spacing="sm">
                <Text
                  style={{ position: 'sticky', top: 0, zIndex: 10 }}
                  bg={colorScheme === 'light' ? 'white' : '#1A1B1E'}
                  weight={500}
                  color="grey"
                >
                  DEFAULT ANALYSES
                </Text>
                <VirtualizedList data={defaultAnalysesResult} spacing={10} itemHeight={40}>
                  {(vi: VirtualItem) => (
                      <AnalysisItem analysis={defaultAnalysesResult[vi.index].item} matches={defaultAnalysesResult[vi.index].matches} />
                  )}
              </VirtualizedList>
              </Stack>
            )}

            
          </Stack>
        </ScrollArea>
      </Stack>
    </Stack>
  );
};