import React from 'react';
import {
  Group,
  ActionIcon,
  Button,
  Grid,
  Title,
  Highlight,
  Checkbox,
  Tooltip,
  LoadingOverlay,
  Box,
  Alert,
  Badge,
  useMantineTheme,
  MantineTheme,
  CopyButton,
  CopyButtonProps,
  Skeleton,
  Text,
  Space,
  Divider,
} from '@mantine/core';
import {
  IconChevronRight,
  IconChevronLeft,
  IconAlertCircle,
  IconFlag,
  IconCopy,
} from '@tabler/icons';

import VopicDescriptionBadge from './VopicDescriptionBadge';
import QuoteReaderErrorReportModal from './QuoteReaderErrorReportModal';
import QuoteReaderProfileComponent from './QuoteReaderProfileComponent';
import QuoteModel, { QuoteCoMentionModel } from '../models/QuoteModel';
import './css/QuoteReaderCore.css';
import QuoteAPI from '../apis/QuoteAPI';
import { withTranslation, WithTranslation } from 'react-i18next';
import { BadgeSelect } from './BadgeSelect/BadgeSelect';
import ProjectModel from '@models/ProjectModel';
import ProjectAPI from '@apis/ProjectAPI';

import { QuoteHighlight } from './QuoteHighlight';

import Fuse from 'fuse.js';
import hash from 'object-hash';

const withMantineTheme = (Component: any) => (props: any) => {
  const theme = useMantineTheme();
  return <Component {...props} theme={theme} />;
};

interface QuoteCopyButtonProps {
  value: string;
  style: any;
}

const QuoteCopyButton = ({ value, ...others }: QuoteCopyButtonProps) => {
  return (
    <CopyButton value={value} {...others}>
      {({ copied, copy }) => (
        <ActionIcon onClick={copy} color={copied === false ? 'dark' : 'green'}>
          <IconCopy size="1.25rem" />
        </ActionIcon>
      )}
    </CopyButton>
  );
};

interface QuoteReaderCoreProps extends WithTranslation {
  project?: ProjectModel;
  quotes: QuoteModel[];
  allowFlagging: boolean;
  allowReporting: boolean;
  profileEnabled: boolean;
  focalSentence: string; // COMBAK: - Make it so that we can pass in the information that identifies a sentence...
  showTopics: boolean;
  showInfo: boolean;

  topicToValueMapping: {
    [key: string]: string;
  };

  topicDescriptions: {
    [key: string]: string;
  };

  valueDescriptions: {
    [key: string]: string;
  };

  flagLoading: boolean;

  // MARK: - Delegate callbacks
  onFlag: (quote?: QuoteModel) => void;
  onChangeFocalSentence: (sentence: string) => void;
  onChangeFocalSentiments?: (sentiments: string[]) => void;
  onChangeDemographics?: (demographics: any) => void;

  focalSentiments: string[];

  theme: MantineTheme;

  loading: boolean;

  population?: string;

  selectedDemographics?: any;
  datasetMetadata?: any;

  onChangeFocalQuote: (quote?: QuoteModel) => void;

  boxSizing: 'compact' | 'large';
}

interface QuoteReaderCoreState {
  currentQuote?: QuoteModel;
  currentIndex: number;
  highlightSentenceInText: boolean;

  showReportButtonReported: boolean;
  reportButtonLoading: boolean;

  reportModal?: JSX.Element;
}

class QuoteReaderCore extends React.Component<QuoteReaderCoreProps> {
  state: QuoteReaderCoreState = {
    currentQuote: undefined,
    currentIndex: 0,
    highlightSentenceInText: true,
    showReportButtonReported: false,
    reportButtonLoading: false,
  };

  constructor(props: QuoteReaderCoreProps) {
    super(props);

    // Enable the use of right and left arrow to switch between previous and next sentences...
    document.addEventListener('keydown', (event) => {
      if (event.key === 'ArrowRight') {
        this.nextSentence();
      } else if (event.key === 'ArrowLeft') {
        this.previousSentence();
      }
    });
  }

  componentDidMount() {
    // Update the state so that we have a mapping we can work with and we can assign the currentQuote...
    // create a mapping of quote_index to quote

    // Set the state's current quote to our index of 0 to start...
    const currentQuote: QuoteModel = this.props.quotes[0];
    this.setState({
      currentQuote: currentQuote,
      currentIndex: 0,
    });

    this.props.onChangeFocalQuote &&
      this.props.onChangeFocalQuote(currentQuote);
  }

  componentDidUpdate(
    prevProps: Readonly<QuoteReaderCoreProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    // Only update the state if the quotes have changed...
    if (prevProps.quotes !== this.props.quotes) {
      const currentQuote: QuoteModel = this.props.quotes[0];
      this.setState({
        currentQuote: currentQuote,
        currentIndex: 0,
      });
      this.props.onChangeFocalQuote &&
        this.props.onChangeFocalQuote(currentQuote);
    }
  }

  // MARK: - Methods for controlling the current sentence...
  nextSentence = () => {
    // Check to make sure we're not at the end...
    if (this.state.currentIndex === this.props.quotes.length - 1) {
      return;
    }
    const nextIndex = this.state.currentIndex + 1;
    const nextQuote = this.props.quotes[nextIndex];
    this.setState({
      currentQuote: nextQuote,
      currentIndex: nextIndex,
    });
    this.props.onChangeFocalQuote && this.props.onChangeFocalQuote(nextQuote);
  };

  previousSentence = () => {
    // Check to make sure we're not at the beginning...
    if (this.state.currentIndex === 0) {
      return;
    }
    const previousIndex = this.state.currentIndex - 1;
    const previousQuote = this.props.quotes[previousIndex];
    this.setState({
      currentQuote: previousQuote,
      currentIndex: previousIndex,
    });
    this.props.onChangeFocalQuote &&
      this.props.onChangeFocalQuote(previousQuote);
  };

  handleHighlightSentenceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked: boolean = event.currentTarget.checked;

    this.setState({
      highlightSentenceInText: checked,
    });
  };

  reportIncorrectQuote = (quote?: QuoteModel) => {
    if (quote !== undefined) {
      this.setState({
        reportButtonLoading: true,
      });

      if (this.props.project) {
        ProjectAPI.reportQuote(
          this.props.project.projectId,
          this.props.project.organizationId,
          quote
        )
          .then((response) => {
            this.setState(
              {
                showReportButtonReported: true,
                reportButtonLoading: false,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    showReportButtonReported: false,
                  });
                }, 1000);
              }
            );
          })
          .catch((error) => {
            this.setState({
              reportButtonLoading: false,
            });
          });
      }
    }
  };

  formatTextInstance = (quote: QuoteModel | QuoteCoMentionModel) => {
    const textInstance = quote.textInstance;
    const sentence = quote.sentence;

    if (this.state.highlightSentenceInText) {
      const sentenceNumber = quote.sentenceNumber;
      const sentences = textInstance.split(/\.|\!|\?|\r\n|\r|\n|\t/);

      // Initialize Fuse and perform search
      const fuse = new Fuse(sentences, { includeScore: true, threshold: 0.2 });
      const result = fuse.search(sentence);
      const sentenceToHighlight = result[0]?.item || '';
      const bestMatchIndex = sentences.indexOf(sentenceToHighlight);

      if (bestMatchIndex !== sentenceNumber - 1) {
        // Handle the discrepancy here, if needed
      }

      const escapedSentence = sentenceToHighlight.replace(/"/g, '&quot;');

      var sentencesToHighlight: string[] = [sentenceToHighlight];
      let style: any;

      if (quote instanceof QuoteCoMentionModel) {
        const focalSentenceNumber = (quote as QuoteCoMentionModel)
          .focalSentenceNumber;
        const focalResult = fuse.search(
          (quote as QuoteCoMentionModel).focalSentence
        );
        const focalSentenceToHighlight = focalResult[0]?.item || '';

        const escapedFocalSentence = focalSentenceToHighlight.replace(
          /"/g,
          '&quot;'
        );
        sentencesToHighlight.push(escapedFocalSentence);

        style = (theme: any) => {
          return {
            [`& [data-chunk-hash="${hash(sentenceToHighlight)}"]`]: {
              backgroundColor: theme.colors.blue[2],
            },
            [`& [data-chunk-hash="${hash(focalSentenceToHighlight)}"]`]: {
              backgroundColor: theme.colors.grape[2],
            },
          };
        };

        return (
          <pre
            style={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
            }}
          >
            <QuoteHighlight sx={style} highlight={sentencesToHighlight}>
              {textInstance}
            </QuoteHighlight>
          </pre>
        );
      } else {
        return (
          <pre
            style={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
            }}
          >
            <QuoteHighlight
              highlightColor="blue"
              highlight={sentencesToHighlight}
            >
              {textInstance}
            </QuoteHighlight>
          </pre>
        );
      }
    } else {
      return (
        <pre
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
          }}
        >
          <QuoteHighlight highlightColor={'blue'} highlight={''}>
            {textInstance}
          </QuoteHighlight>
        </pre>
      );
    }
  };

  openReportWindow = () => {
    const reportModal = (
      <QuoteReaderErrorReportModal
        availableTopics={[]}
        onCancel={() => {
          this.setState({
            reportModal: undefined,
          });
        }}
        onSubmit={() => {
          this.setState({
            reportModal: undefined,
          });
        }}
      />
    );

    this.setState({
      reportModal: reportModal,
    });
  };

  render = () => {
    // Adjust grid sizing based on whether we are showing topics or not...

    var textViewGridSize = 18;
    var topicViewGridSize = 6;
    if (this.props.showTopics === false && this.props.showInfo === false) {
      topicViewGridSize = 0;
      textViewGridSize = 24;
    }

    const nextButtonDisabled =
      this.state.currentIndex === this.props.quotes.length - 1;
    const previousButtonDisabled = this.state.currentIndex === 0;

    var reportButton = null;
    if (this.props.allowReporting) {
      if (this.state.showReportButtonReported) {
        reportButton = (
          <Tooltip
            label={this.props.t('core.mark-as-incorrect-detail-text')}
            openDelay={400}
          >
            <Button
              disabled={this.props.loading}
              color="blue"
              variant="light"
              size="xs"
              leftIcon={<IconAlertCircle size={18} />}
            >
              {this.props.t('core.mark-as-incorrect-success-text')}
            </Button>
          </Tooltip>
        );
      } else {
        reportButton = (
          <Tooltip
            label={this.props.t('core.mark-as-incorrect-detail-text')}
            openDelay={400}
          >
            <Button
              disabled={this.props.loading}
              color="red"
              variant="light"
              size="xs"
              leftIcon={<IconAlertCircle size={18} />}
              loading={this.state.reportButtonLoading}
              onClick={() => {
                this.reportIncorrectQuote(this.state.currentQuote);
              }}
            >
              {this.props.t('core.mark-as-incorrect-text')}
            </Button>
          </Tooltip>
        );
      }
    }

    var flagButton = null;
    if (this.props.allowFlagging) {
      // TODO: Implement flagging...
      flagButton = import.meta.env.VITE_CX_ENV !== 'production' && (
        <Tooltip label={this.props.t('core.flag-quote-text')} openDelay={400}>
          <ActionIcon
            variant="light"
            color="blue"
            loading={this.props.flagLoading}
            onClick={() => this.props.onFlag(this.state.currentQuote)}
          >
            <IconFlag />
          </ActionIcon>
        </Tooltip>
      );
    }

    var formattedTopicBadge = null;
    if (this.state.currentQuote !== undefined) {
      formattedTopicBadge = (
        <VopicDescriptionBadge
          isFocal={false}
          vopic={this.state.currentQuote.vopic}
          isValue={this.state.currentQuote.isValue}
          isCustomerSpecificVopic={false}
          topicDescriptions={this.props.topicDescriptions}
          valueDescriptions={this.props.valueDescriptions}
          valueIconMapping={{}}
          topicToValueMapping={this.props.topicToValueMapping}
        />
      );
    }

    var focalFormattedTopicBadge = null;
    if (this.state.currentQuote !== undefined) {
      // Check to see if the quote has a focal topic...
      if (this.state.currentQuote instanceof QuoteCoMentionModel) {
        const currentQuote = this.state.currentQuote as QuoteCoMentionModel;

        focalFormattedTopicBadge = (
          <VopicDescriptionBadge
            isFocal={true}
            vopic={currentQuote.focalTopic}
            isValue={false}
            isCustomerSpecificVopic={false}
            topicDescriptions={this.props.topicDescriptions}
            valueDescriptions={this.props.valueDescriptions}
            valueIconMapping={{}}
            topicToValueMapping={this.props.topicToValueMapping}
          />
        );
      }
    }

    if (this.props.loading) {
      focalFormattedTopicBadge = (
        <Skeleton style={{ display: 'inline-block' }} width={75} height={20} />
      );
      formattedTopicBadge = (
        <Skeleton style={{ display: 'inline-block' }} width={75} height={20} />
      );
    }

    var headerColor = 'gray';
    if (this.state.currentQuote?.textInstanceSentiment === 'positive') {
      headerColor = 'teal';
    } else if (this.state.currentQuote?.textInstanceSentiment === 'negative') {
      headerColor = 'red';
    }

    return (
      <>
        <Grid columns={24}>
          <Grid.Col span={24}>
            <Box sx={{ height: 'auto', position: 'relative', padding: '10px' }}>
              {this.state.currentQuote &&
                this.state.currentQuote instanceof QuoteCoMentionModel && (
                  <>
                    {this.props.loading ? (
                      <Group spacing={'xs'}>
                        <Title order={5}>
                          {this.props.t('core.focal-sentence')}
                        </Title>
                        {focalFormattedTopicBadge}
                      </Group>
                    ) : (
                      <Title order={5}>
                        {this.props.t('core.focal-sentence')}{' '}
                        {focalFormattedTopicBadge}{' '}
                      </Title>
                    )}

                    <br />
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateRows: '1fr auto',
                        backgroundColor:
                          this.props.theme.colorScheme === 'dark'
                            ? this.props.theme.colors.dark[6]
                            : this.props.theme.colors.gray[1],
                        borderRadius: '5px',
                        fontFamily:
                          "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
                        lineHeight: 1.5,
                        minHeight:
                          this.props.boxSizing === 'compact' ? '90px' : '150px',
                        maxHeight:
                          this.props.boxSizing === 'compact' ? '90px' : '150px',
                        overflow: 'hidden',
                      }}
                    >
                      {this.props.loading ? (
                        <>
                          <Skeleton></Skeleton>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              overflowY: 'auto',
                              padding: '2%',
                            }}
                          >
                            {this.state.currentQuote ? (
                              <>"{this.state.currentQuote.focalSentence}"</>
                            ) : (
                              <i>{this.props.t('core.no-sentence-selected')}</i>
                            )}
                          </div>
                          <QuoteCopyButton
                            style={{
                              alignSelf: 'end',
                              justifySelf: 'start',
                              padding: '2%',
                            }}
                            value={
                              this.state.currentQuote
                                ? this.state.currentQuote.sentence
                                : ''
                            }
                          />
                        </>
                      )}
                    </div>
                    <br />
                  </>
                )}

              {this.props.loading ? (
                <Group spacing={'xs'}>
                  <Title order={5}>{this.props.t('core.sentence')}</Title>
                  {formattedTopicBadge}
                </Group>
              ) : (
                <Title order={5}>
                  {this.props.t('core.sentence')} {formattedTopicBadge}{' '}
                </Title>
              )}
              <br />
              <div
                style={{
                  display: 'grid',
                  gridTemplateRows: '1fr auto',
                  backgroundColor:
                    this.props.theme.colorScheme === 'dark'
                      ? this.props.theme.colors.dark[6]
                      : this.props.theme.colors.gray[1],
                  borderRadius: '5px',
                  fontFamily:
                    "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
                  lineHeight: 1.5,
                  minHeight:
                    this.props.boxSizing === 'compact' ? '90px' : '150px',
                  maxHeight:
                    this.props.boxSizing === 'compact' ? '90px' : '150px',
                  overflow: 'hidden',
                }}
              >
                {this.props.loading ? (
                  <>
                    <Skeleton></Skeleton>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        overflowY: 'auto',
                        padding: '2%',
                      }}
                    >
                      {this.state.currentQuote ? (
                        <>"{this.state.currentQuote.sentence}"</>
                      ) : (
                        <i>{this.props.t('core.no-sentence-selected')}</i>
                      )}
                    </div>
                    <QuoteCopyButton
                      style={{
                        alignSelf: 'end',
                        justifySelf: 'start',
                        padding: '2%',
                      }}
                      value={
                        this.state.currentQuote
                          ? this.state.currentQuote.sentence
                          : ''
                      }
                    />
                  </>
                )}
              </div>

              <br />
              <br />

              <Group position="apart">
                {this.props.loading ? (
                  <Group>
                    <Title order={5}>{this.props.t('core.full-text')}</Title>
                    <Skeleton width={40} height={20} />
                  </Group>
                ) : (
                  <Group>
                    <Title order={5}>{this.props.t('core.full-text')}</Title>
                    <Tooltip label="Click to change sentiment" openDelay={400}>
                      <BadgeSelect
                        onChange={(values: string[]) => {
                          if (this.props.onChangeFocalSentiments) {
                            this.props.onChangeFocalSentiments(values);
                          }
                        }}
                        defaultValue={this.props.focalSentiments}
                        data={[
                          { label: 'Positive', value: 'positive' },
                          { label: 'Negative', value: 'negative' },
                          { label: 'Indeterminate', value: 'indeterminate' },
                        ]}
                        color={headerColor}
                      >
                        {this.state.currentQuote?.textHeader}
                      </BadgeSelect>
                    </Tooltip>
                  </Group>
                )}

                <Checkbox
                  label={this.props.t('core.highlight-sentence-text')}
                  onChange={this.handleHighlightSentenceChange}
                  defaultChecked={this.state.highlightSentenceInText}
                />
              </Group>
              <br />
              <div
                style={{
                  display: 'grid',
                  gridTemplateRows: '1fr auto',
                  backgroundColor:
                    this.props.theme.colorScheme === 'dark'
                      ? this.props.theme.colors.dark[6]
                      : this.props.theme.colors.gray[1],
                  borderRadius: '5px',
                  fontFamily:
                    "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
                  lineHeight: 1.5,
                  minHeight:
                    this.props.boxSizing === 'compact' ? '150px' : '250px',
                  maxHeight:
                    this.props.boxSizing === 'compact' ? '150px' : '250px',
                  overflow: 'hidden',
                }}
              >
                {this.props.loading ? (
                  <>
                    <Skeleton></Skeleton>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        overflowY: 'auto',
                        padding: '2%',
                      }}
                    >
                      {this.state.currentQuote ? (
                        this.formatTextInstance(this.state.currentQuote)
                      ) : (
                        <i>{this.props.t('core.no-text-selected-text')}</i>
                      )}
                    </div>
                    <QuoteCopyButton
                      style={{
                        alignSelf: 'end',
                        justifySelf: 'start',
                        padding: '2%',
                      }}
                      value={
                        this.state.currentQuote
                          ? this.state.currentQuote.textInstance
                          : ''
                      }
                    />
                  </>
                )}
              </div>
              <Text size="xs" color="dimmed">
                {this.props.population}
              </Text>

              <br />
              <LoadingOverlay
                zIndex={1}
                overlayBlur={5}
                visible={!this.state.currentQuote && !this.props.loading}
                loader={
                  <>
                    <Alert
                      icon={<IconAlertCircle size={16} />}
                      title={this.props.t('core.no-quotes-available-header')}
                      color="orange"
                    >
                      {this.props.t('core.no-quotes-available-detail-text')}
                    </Alert>
                  </>
                }
              />
              <Group position="apart">
                <Group>
                  {flagButton}
                  {reportButton}
                </Group>
                <Group>
                  <Button
                    size="xs"
                    leftIcon={<IconChevronLeft />}
                    disabled={this.props.loading || previousButtonDisabled}
                    onClick={this.previousSentence}
                  >
                    {this.props.t('core.previous-button-text')}
                  </Button>
                  <Button
                    size="xs"
                    rightIcon={<IconChevronRight />}
                    disabled={this.props.loading || nextButtonDisabled}
                    onClick={this.nextSentence}
                  >
                    {this.props.t('core.next-button-text')}
                  </Button>
                </Group>
              </Group>
              <Space h={20} />
              {this.state.currentQuote && this.props.profileEnabled && (
                <QuoteReaderProfileComponent
                  demographics={this.state.currentQuote.demographics}
                  onDemographicsChange={
                    this.props.onChangeDemographics
                      ? this.props.onChangeDemographics
                      : () => {}
                  }
                  selectedDemographics={this.props.selectedDemographics}
                  datasetMetadata={this.props.datasetMetadata}
                />
              )}
            </Box>
          </Grid.Col>
        </Grid>
        {this.state.reportModal}
      </>
    );
  };
}

export default withTranslation('quote_reader')(
  withMantineTheme(QuoteReaderCore)
);
