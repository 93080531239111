import { useParams } from 'react-router-dom';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { Summary, useProjectSummaries } from './useProjectSummaries';

export const useCurrentSummary = (): Summary | undefined => {
  const params = useParams();
  const project = useCurrentProject();
  const summaries = useProjectSummaries(project)
  return summaries.find(s => s.id === params.summaryId)
};
