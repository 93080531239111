import React from 'react';
import { DateInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons';

interface MantineDateProps {
  value: any;
  setValue: (value: any) => void;
  readonly?: boolean;
  dateFormat?: any;
  valueFormat?: any;
  placeholder?: string;
  customProps?: any;
}

// export class MantineDate extends React.PureComponent<MantineDateProps> {

//   render() {
//     return <DateInput
//       value={new Date()}
//       // onChange={handleChange}
//       placeholder="Choose date..."
//       icon={<IconCalendar size={18} />}
//       maw={400}
//       mx="auto"
//       size="xs"
//     />
//   }

// }

export const MantineDate: React.FunctionComponent<MantineDateProps> = (
  props: MantineDateProps
) => {
  const formatDate = (date: Date) => {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  const formatSingleValue = (value: any | null) => {
    if (!value) {
      return null;
    }

    const date = new Date(value);
    if (isNaN(date.getTime())) {
      return null; // invalid date
    }

    console.log('formatSingleValue: ', date);
    console.log('OG', value);

    return date;
  };

  const handleChange = (value: Date | null) => {
    props.setValue(formatDate(value));
  };

  return (
    <DateInput
      defaultValue={formatSingleValue(props.value)}
      onChange={handleChange}
      placeholder="Choose date..."
      icon={<IconCalendar size={18} />}
      maw={400}
      mx="auto"
      size="xs"
    />
  );
};
