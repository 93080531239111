import ProjectAPI from '@apis/ProjectAPI';
import {
  Divider,
  Modal,
  Stack,
  Title,
  Text,
  TextInput,
  Group,
  Button,
} from '@mantine/core';
import ProjectModel from '@models/ProjectModel';
import { useState } from 'react';
import APIErrorMessage from './APIErrorMessage';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons';

interface DeleteProjectModalProps {
  opened: boolean;
  onClose: () => void;
  onProjectDeleted: () => void;
  project: ProjectModel;
}

export const DeleteProjectModal = ({
  opened,
  onClose,
  onProjectDeleted,
  project,
}: DeleteProjectModalProps) => {
  const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
  const [deleteError, setDeleteError] = useState<React.ReactNode | undefined>(
    undefined
  );
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const deleteProject = () => {
    setDeleteInProgress(true);

    ProjectAPI.deleteProject(project.projectId, project.organizationId)
      .then((response) => {
        setDeleteError(undefined);
        setDeleteInProgress(false);
        setDeleteConfirmationText('');
        notifications.show({
          id: 'project-deleted',
          color: 'green',
          title: 'Delete project',
          message: `Project ${project.title} was deleted`,
          icon: <IconCheck size="1rem" />,
          autoClose: 15000,
        });
        onProjectDeleted();
      })
      .catch((error) => {
        setDeleteInProgress(false);
        setDeleteError(
          <APIErrorMessage
            response={error}
            dismissable={true}
            simple
            onDismiss={() => setDeleteError(undefined)}
          />
        );
      });
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        setDeleteConfirmationText('');
        onClose();
      }}
      withCloseButton={false}
      size="lg"
    >
      <Stack>
        <Title order={3}>Delete Project</Title>
        <Divider />
      </Stack>
      <Stack style={{ marginTop: '10px' }}>
        <Text>
          You are about to delete the project <b>{project.title}</b>
        </Text>
        <TextInput
          label={
            <Text>
              To confirm the deletion of this project, type <i>delete</i> in the
              box below
            </Text>
          }
          description={<Text color="orange">This action cannot be undone</Text>}
          placeholder="delete"
          inputWrapperOrder={['label', 'input', 'description', 'error']}
          onChange={(event) =>
            setDeleteConfirmationText(event.currentTarget.value)
          }
        />
        {deleteError}
        <Group position="right">
          <Button
            variant="subtle"
            color="dark"
            onClick={() => {
              setDeleteConfirmationText('');
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            color="red"
            disabled={deleteConfirmationText !== 'delete'}
            loading={deleteInProgress}
            onClick={deleteProject}
          >
            Delete
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
