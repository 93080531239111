import { useQuery } from '@tanstack/react-query';
import ProjectAPI from '@apis/ProjectAPI';
import ProjectModel from '@models/ProjectModel';
import {
  AnalysisType,
  IProjectAnalysisModel,
} from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import { ModelAggregation } from '@stores/AnalysisStore';

export const useDriversData = (
  project?: ProjectModel,
  currentAnalysis?: IProjectAnalysisModel,
  aggregationLevel?: ModelAggregation
) => {
  return useQuery({
    queryKey: [
      'drivers-data',
      project?.projectId,
      project?.organizationId,
      currentAnalysis?.id,
      aggregationLevel || 'Topic',
    ],
    queryFn: async () => {
      const aggLevel = aggregationLevel || 'Topic';

      return ProjectAPI.getAnalysisData(
        project.projectId,
        currentAnalysis.id,
        aggLevel,
        project.organizationId
      );
    },
    enabled:
      !!project &&
      !!currentAnalysis &&
      !(currentAnalysis?.type === AnalysisType.INTERNAL_BENCHMARK),
    staleTime: 1000 * 60 * 10, // 10 minutes...
  });
};
