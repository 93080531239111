import { useProjectDatasets } from '@apis/hooks/useProjectDatasets';
import { MultiSelectCheckbox } from '@components/MultiSelectCheckbox';
import { OutcomeSelect } from '@components/OutcomeSelect/OutcomeSelect';
import { useCurrentAnalysis } from '@hooks/useCurrentAnalysis';
import { useCurrentProject } from '@hooks/useCurrentProject';
import { useOutcomeData } from '@hooks/useOutcomeData';
import {
  Group,
  Stack,
  Title,
  Text,
  Button,
  Modal,
  SegmentedControl,
  Flex,
  Input,
  Tabs,
  Select,
  NumberInput,
} from '@mantine/core';
import {
  AnalysisType,
  useAnalysisEditionStore,
} from '@stores/AnalysisEditionStore';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

interface RenamePopulationsModalProps {
  opened: boolean;
  onClose: () => void;
  selectedPopulations: string[];
}

interface RenameOptions {
  type: 'replace' | 'add' | 'format';
  replaceFind: string;
  replaceWith: string;
  addText: string;
  addWhere: 'before' | 'after';
  formatType: 'index' | 'counter';
  formatWhere: 'before' | 'after';
  formatText: string;
  formatStart: number;
}

const defaultOptions: RenameOptions = {
  type: 'replace',
  replaceFind: '',
  replaceWith: '',
  addText: '',
  addWhere: 'before',
  formatType: 'index',
  formatWhere: 'after',
  formatText: '',
  formatStart: 1,
};

const zeroPad = (num, places) => String(num).padStart(places, '0');

const rename = (values: string[], options: RenameOptions) => {
  if (options.type === 'replace') {
    return values.map((v) =>
      v.replaceAll(options.replaceFind, options.replaceWith)
    );
  } else if (options.type === 'add') {
    return values.map((v) =>
      options.addWhere === 'before' ? options.addText + v : v + options.addText
    );
  } else if (options.type === 'format') {
    return values.map((v, i) => {
      const numericValue =
        options.formatType === 'index'
          ? (i + options.formatStart).toString()
          : zeroPad(i + options.formatStart, 5);
      if (options.formatText.trim() === '') {
        return options.formatWhere === 'before'
          ? numericValue + v
          : v + numericValue;
      } else {
        return options.formatWhere === 'before'
          ? numericValue + options.formatText
          : options.formatText + numericValue;
      }
    });
  }
  return values;
};

export const RenamePopulationsModal: React.FC<RenamePopulationsModalProps> = ({
  opened,
  onClose,
  selectedPopulations,
}) => {
  const [options, setOptions] = useState<RenameOptions>(defaultOptions);
  useEffect(() => setOptions(defaultOptions), [opened]);
  const populations = useAnalysisEditionStore((s) => s.populations);
  const setPopulations = useAnalysisEditionStore((s) => s.setPopulations);
  const example = useMemo(
    () =>
      rename(
        [
          populations
            .filter((p) => selectedPopulations.includes(p.id))
            .map((p) => p.title)[0] ?? '',
        ],
        options
      )[0] ?? '',
    [populations, options, selectedPopulations]
  );

  const handleRename = useCallback(() => {
    const populationsToUpdate = populations.filter((p) =>
      selectedPopulations.includes(p.id)
    );
    const titles = rename(
      populationsToUpdate.map((p) => p.title),
      options
    );
    setPopulations(
      [...populations].map((p) => {
        const indexOf = populationsToUpdate.findIndex(
          (pupdate) => p.id === pupdate.id
        );
        if (indexOf !== -1) {
          return { ...p, title: titles[indexOf] };
        }
        return p;
      })
    );
    onClose();
  }, [populations, options, selectedPopulations]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        <Stack spacing={1}>
          <Title order={3}>{`Rename ${selectedPopulations.length} population${
            selectedPopulations.length > 1 ? 's' : ''
          }`}</Title>
        </Stack>
      }
      size="xl"
    >
      <Stack spacing="md">
        <Tabs
          defaultValue="replace"
          value={options.type}
          onTabChange={(v) =>
            setOptions({ ...options, type: v as 'replace' | 'add' | 'format' })
          }
        >
          <Tabs.List>
            <Tabs.Tab value="replace">Replace text</Tabs.Tab>
            <Tabs.Tab value="add">Add text</Tabs.Tab>
            <Tabs.Tab value="format">Format</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="replace">
            <Flex mt="md" gap="xl" style={{ flexGrow: 1 }}>
              <Input.Wrapper
                id={'replace_find'}
                label="Find"
                style={{ flexGrow: 1 }}
              >
                <Input
                  id={'replace_find'}
                  value={options.replaceFind}
                  onChange={(v) =>
                    setOptions({ ...options, replaceFind: v.target.value })
                  }
                />
              </Input.Wrapper>
              <Input.Wrapper
                id={'replace_with'}
                label="Replace with"
                style={{ flexGrow: 1 }}
              >
                <Input
                  id={'replace_with'}
                  value={options.replaceWith}
                  onChange={(v) =>
                    setOptions({ ...options, replaceWith: v.target.value })
                  }
                />
              </Input.Wrapper>
            </Flex>
          </Tabs.Panel>

          <Tabs.Panel value="add">
            <Flex mt="md" gap="xl" style={{ flexGrow: 1 }}>
              <Input.Wrapper
                id={'add_text'}
                label="Text to add"
                style={{ flexGrow: 1 }}
              >
                <Input
                  id={'add_text'}
                  value={options.addText}
                  onChange={(v) =>
                    setOptions({ ...options, addText: v.target.value })
                  }
                />
              </Input.Wrapper>
              <Input.Wrapper
                id={'add_where'}
                label="Where"
                style={{ flexGrow: 1 }}
              >
                <Select
                  id="add_where"
                  value={options.addWhere}
                  onChange={(v) =>
                    setOptions({
                      ...options,
                      addWhere: v as 'before' | 'after',
                    })
                  }
                  data={[
                    { value: 'before', label: 'Before name' },
                    { value: 'after', label: 'After name' },
                  ]}
                />
              </Input.Wrapper>
            </Flex>
          </Tabs.Panel>

          <Tabs.Panel value="format">
            <Flex mt="md" gap="xl" style={{ flexGrow: 1 }}>
              <Input.Wrapper
                id={'format_format'}
                label="Name format"
                style={{ flexGrow: 1 }}
              >
                <Select
                  id="format_format"
                  value={options.formatType}
                  onChange={(v) =>
                    setOptions({
                      ...options,
                      formatType: v as 'index' | 'counter',
                    })
                  }
                  data={[
                    { value: 'index', label: 'Name and index' },
                    { value: 'counter', label: 'Name and counter' },
                  ]}
                />
              </Input.Wrapper>
              <Input.Wrapper
                id={'add_where'}
                label="Where"
                style={{ flexGrow: 1 }}
              >
                <Select
                  id="add_where"
                  value={options.formatWhere}
                  onChange={(v) =>
                    setOptions({
                      ...options,
                      formatWhere: v as 'before' | 'after',
                    })
                  }
                  data={[
                    { value: 'before', label: 'Before name' },
                    { value: 'after', label: 'After name' },
                  ]}
                />
              </Input.Wrapper>
            </Flex>
            <Flex mt="md" gap="xl" style={{ flexGrow: 1 }}>
              <Input.Wrapper
                id={'format_text'}
                label="Custom format"
                style={{ flexGrow: 1 }}
              >
                <Input
                  id={'format_text'}
                  value={options.formatText}
                  onChange={(v) =>
                    setOptions({ ...options, formatText: v.target.value })
                  }
                />
              </Input.Wrapper>

              <NumberInput
                defaultValue={1}
                min={0}
                value={options.formatStart}
                onChange={(v) =>
                  setOptions({
                    ...options,
                    formatStart: parseInt(v.toString()),
                  })
                }
                label="Start numbers at"
              />
            </Flex>
          </Tabs.Panel>
        </Tabs>
      </Stack>
      <Group position="apart" mt="xl">
        <Text size="sm" color="dimmed">
          {'Example: ' + example}
        </Text>
        <Group position="right">
          <Button color="blue" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button color="blue" onClick={handleRename}>
            {selectedPopulations.length > 1 ? 'Rename all' : 'Rename'}
          </Button>
        </Group>
      </Group>
    </Modal>
  );
};
