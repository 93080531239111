import React from 'react';
import {
  Popover,
  MultiSelect,
  Select,
  Text,
  ActionIcon,
  Tooltip,
} from '@mantine/core';
import { IconX } from '@tabler/icons';

// import Crn from '../models/Crn';

type SelectionOptionType = {
  value: string;
  label: string;
  group?: string;
};

interface QuoteReaderFiltersComponentProps {
  // MARK: - Enabling filter types...
  datasetFilterEnabled: boolean;
  sentimentFilterEnabled: boolean;
  demographicFilterEnabled: boolean;
  topicsFilterEnabled: boolean;
  valuesFilterEnabled: boolean;

  // MARK: - Options to pass to Select components...
  datasetOptions: SelectionOptionType[];
  sentimentOptions: SelectionOptionType[];
  demographicOptions: SelectionOptionType[];
  topicsOptions: SelectionOptionType[];
  valuesOptions: SelectionOptionType[];

  demographicValueMapping: { [key: string]: (string | any)[] };
}

interface QuoteReaderFiltersComponentState {
  selectedDataset?: string;
  selectedSentiment?: string;

  activeDemographicSelectors?: string[];
  showDemographicSelector: boolean;

  selectedTopics?: string[];
  selectedValues?: string[];

  // Mapping of demographic field to values the user selected...
  selectedDemographicValues: { [key: string]: string[] };
}

interface QuoteFilter {
  Dataset: string[];
  Sentiment: string[];
  Demographic: { [key: string]: string[] };
  Topics: string[];
}

class QuoteReaderFiltersComponent extends React.Component<QuoteReaderFiltersComponentProps> {
  constructor(props: QuoteReaderFiltersComponentProps) {
    super(props);
  }

  state: QuoteReaderFiltersComponentState = {
    showDemographicSelector: false,
    selectedDemographicValues: {},
  };

  getFilters(): QuoteFilter {
    // Use the current state to format our filters to send to the API...
    var filters: any = {};

    if (this.state.selectedDataset !== undefined) {
      filters['Dataset'] = this.state.selectedDataset;
    } else {
      filters['Dataset'] = [];
    }

    if (this.state.selectedSentiment !== undefined) {
      filters['Sentiment'] = this.state.selectedSentiment;
    } else {
      filters['Sentiment'] = [];
    }

    if (this.state.selectedTopics !== undefined) {
      filters['Topics'] = this.state.selectedTopics;
    } else {
      filters['Topics'] = [];
    }

    if (this.state.selectedDemographicValues !== undefined) {
      filters['Demographic'] = this.state.selectedDemographicValues;
    } else {
      filters['Demographic'] = {};
    }

    return filters;
  }

  updateSelectedDemographicValues = (
    demographicField: string,
    selectedValues: string[]
  ) => {
    const newSelectedDemographicValues = {
      ...this.state.selectedDemographicValues,
    };

    newSelectedDemographicValues[demographicField] = selectedValues;

    this.setState({ selectedDemographicValues: newSelectedDemographicValues });
  };

  getSelectOptionsForSubDemographic = (
    demographic: string
  ): SelectionOptionType[] => {
    var options: SelectionOptionType[] = [];

    if (demographic !== undefined) {
      if (this.props.demographicValueMapping[demographic] !== undefined) {
        options = this.props.demographicValueMapping[demographic]
          .sort()
          .map((value: string | any) => {
            if (value === null) {
              return { value: null, label: 'N/A' };
            } else {
              return {
                value: value,
                label: value,
              };
            }
          });
      }
    }

    return options;
  };

  formatDemographicFilterSection = (): JSX.Element[] => {
    // Loop through our state and create a Select component for each one...
    var demographicSelectors: JSX.Element[] = [];

    if (this.state.activeDemographicSelectors !== undefined) {
      for (var i = 0; i < this.state.activeDemographicSelectors.length; i++) {
        const demographic = this.state.activeDemographicSelectors[i];

        const options: SelectionOptionType[] =
          this.getSelectOptionsForSubDemographic(demographic);
        const prettyDemographicName =
          this.getPrettyDemographicOptionName(demographic);

        var connectorDiv: JSX.Element = <></>;
        if (
          this.state.activeDemographicSelectors.length > 1 &&
          i !== this.state.activeDemographicSelectors.length - 1
        ) {
          connectorDiv = (
            <div style={{ marginBottom: '5px', marginTop: '5px' }}>
              <Text size="xs" color="dimmed">
                AND
              </Text>
            </div>
          );
        }
        demographicSelectors.push(
          <div key={demographic}>
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'left',
              }}
            >
              <MultiSelect
                size="xs"
                label={prettyDemographicName}
                placeholder="Select a value"
                data={options}
                clearable
                searchable
                defaultValue={this.state.selectedDemographicValues[demographic]}
                onChange={(values: string[]) => {
                  this.updateSelectedDemographicValues(demographic, values);
                }}
              />
              <Tooltip label="Remove Demographic" openDelay={300}>
                <ActionIcon
                  style={{ marginBottom: '2.5px' }}
                  onClick={() => {
                    this.removeDemographicFilter(demographic);
                  }}
                  size="lg"
                >
                  <IconX size={18} />
                </ActionIcon>
              </Tooltip>
            </div>
            {connectorDiv}
          </div>
        );
      }
    }

    return demographicSelectors;
  };

  showDemographicSelector = (): void => {
    this.setState({
      showDemographicSelector: true,
    });
  };

  hideDemographicSelector = (): void => {
    this.setState({
      showDemographicSelector: false,
    });
  };

  addDemographicFilter = (demographic: string): void => {
    var activeDemographicSelectors = this.state.activeDemographicSelectors;
    if (activeDemographicSelectors === undefined) {
      activeDemographicSelectors = [];
    }

    // Make sure we don't already have this demographic...
    if (activeDemographicSelectors.indexOf(demographic) === -1) {
      activeDemographicSelectors.push(demographic);
    }

    this.setState({
      activeDemographicSelectors: activeDemographicSelectors,
      showDemographicSelector: false,
    });
  };

  removeDemographicFilter = (demographic: string): void => {
    var activeDemographicSelectors = this.state.activeDemographicSelectors;
    if (activeDemographicSelectors === undefined) {
      activeDemographicSelectors = [];
    }

    var newActiveDemographicSelectors: string[] = [];
    for (var i = 0; i < activeDemographicSelectors.length; i++) {
      if (activeDemographicSelectors[i] !== demographic) {
        newActiveDemographicSelectors.push(activeDemographicSelectors[i]);
      }
    }

    this.setState({
      activeDemographicSelectors: newActiveDemographicSelectors,
    });
  };

  getPrettyDemographicOptionName = (demographic: string): string => {
    // loop through demographicOptions and find the one that matches the demographic string...
    var prettyDemographicName: string = demographic;

    for (var i = 0; i < this.props.demographicOptions.length; i++) {
      var option = this.props.demographicOptions[i];
      if (option.value === demographic) {
        prettyDemographicName = option.label;
        break;
      }
    }

    return prettyDemographicName;
  };

  render = () => {
    const subDemographicFilter = this.formatDemographicFilterSection();

    var demographicSelector;
    if (this.state.showDemographicSelector) {
      // Ensure we're only showing demographics that are not in use...
      const adjustedDemographicOptions = this.props.demographicOptions.filter(
        (option: SelectionOptionType) => {
          if (this.state.activeDemographicSelectors !== undefined) {
            return (
              this.state.activeDemographicSelectors.indexOf(option.value) === -1
            );
          } else {
            return true;
          }
        }
      );

      demographicSelector = (
        <div
          style={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'center',
            width: '200px',
          }}
        >
          <Select
            label="Add Demographic"
            placeholder="Select demographic"
            searchable
            data={adjustedDemographicOptions}
            onChange={(value: string) => {
              this.addDemographicFilter(value);
            }}
          />
          <ActionIcon
            style={{ marginBottom: '2.5px' }}
            onClick={this.hideDemographicSelector}
            size="lg"
          >
            <IconX size={18} />
          </ActionIcon>
        </div>
      );
    }

    return (
      <>
        <MultiSelect
          label="Dataset"
          placeholder="Select a dataset"
          description="Select a dataset to filter by"
          multiple={true}
          searchable
          required
          clearable
          disabled={!this.props.datasetFilterEnabled}
          data={this.props.datasetOptions}
          onChange={(value: string[]) => {
            this.setState({
              selectedDataset: value,
            });
          }}
        />
        <br />
        <MultiSelect
          label="Sentiment"
          placeholder="(Using all sentiments)"
          description="Select sentiment to filter by"
          multiple={true}
          searchable
          clearable
          disabled={!this.props.sentimentFilterEnabled}
          data={this.props.sentimentOptions}
          onChange={(value: string[]) => {
            this.setState({
              selectedSentiment: value,
            });
          }}
        />
        <br />
        <Text size="sm" weight={500}>
          Demographics
        </Text>
        <Text size="xs" color="dimmed">
          Filter by one or more demographic fields
        </Text>
        <div style={{ marginLeft: '10px', width: '100%' }}>
          {subDemographicFilter}
        </div>
        <br />
        {demographicSelector}
        <Text
          size="xs"
          variant="link"
          style={{ cursor: 'pointer', width: '100px' }}
          onClick={this.showDemographicSelector}
        >
          + Add a Field
        </Text>
        <br />

        <MultiSelect
          label="Topics"
          placeholder="(Using all topics)"
          description="Select topics to filter by"
          required
          multiple={true}
          searchable
          clearable
          disabled={!this.props.topicsFilterEnabled}
          data={this.props.topicsOptions}
          onChange={(topics: string[]) => {
            this.setState({
              selectedTopics: topics,
            });
          }}
        />
        <br />
      </>
    );
  };
}

export default QuoteReaderFiltersComponent;
