import ProjectModel, {
  CreateProjectRequestModel,
  ProjectBenchmarkUpdateRequestModel,
} from '../models/ProjectModel';
import APIRequesterBase from './APIBase';
import { BenchmarkDataPoint } from '../components/charts/datamodels/BenchmarkDataPoint';
import axios from 'axios';
import {
  IProjectAnalysisModel,
  IProjectAnalysisRequestModel,
} from '@models/ProjectAnalysisModel/ProjectAnalysisModelBase';
import { ShapDriversDataPoint } from '@components/charts/datamodels/ShapDriversDataPoint';
import QuoteModel, { QuoteCoMentionModel } from '@models/QuoteModel';
import { IOutcomeMeasure } from '@redux/analysesSlice';
import { Population, isPopulation } from '@redux/benchmarksSlice';
import SharedLinkModel, { Token } from '@models/SharedLinkModel';

export enum ProjectMetricType {
  Base = 'Base',
  NegativeCoMention = 'NegativeCoMention',
  PositiveCoMention = 'PositiveCoMention',
  NotMentioned = 'NotMentioned',
  Segments = 'Segments',
}

export enum ProjectAnalysisDataType {
  DRIVERS_OF_OUTCOME = 'DRIVERS_OF_OUTCOME',
}

class ProjectAPI extends APIRequesterBase {
  static async getProject(
    projectId: string,
    organizationId?: number
  ): Promise<ProjectModel> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    const url = `${this.getAPIUrl()}/projects/${organizationId}/${projectId}`;

    const headers = await this.getHeaders();

    return axios.get(url, { headers: headers }).then((response) => {
      const project: ProjectModel = ProjectModel.fromAPIResponse(response.data);
      return project;
    });
  }

  static async deleteProject(
    projectId: string,
    organizationId?: number
  ): Promise<void> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    const url = `${this.getAPIUrl()}/projects/${organizationId}/${projectId}`;

    const headers = await this.getHeaders();

    return axios.delete(url, { headers: headers }).then((response) => {
      return;
    });
  }

  static async describeProjects(
    organizationId?: number
  ): Promise<ProjectModel[]> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    const url = `${this.getAPIUrl()}/projects/${organizationId}/describe`;

    const headers = await this.getHeaders();

    return axios.get(url, { headers: headers }).then((response) => {
      const projects: ProjectModel[] = response.data.map((project: any) =>
        ProjectModel.fromAPIResponse(project)
      );
      return projects;
    });
  }

  static async listProjects(organizationId?: number): Promise<ProjectModel[]> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    const url = `${this.getAPIUrl()}/projects/${organizationId}`;

    const headers = await this.getHeaders();

    return axios.get(url, { headers: headers }).then((response) => {
      const projects: ProjectModel[] = response.data.map((project: any) =>
        ProjectModel.fromAPIResponse(project)
      );
      return projects;
    });
  }

  static async createProject(
    project: CreateProjectRequestModel
  ): Promise<ProjectModel> {
    if (project.organizationId === undefined) {
      project.organizationId = await this.getCurrentOrganizationId();
    }
    const projectObject = {
      OrganizationId: project.organizationId,
      TopicThemeMapping:
        project.topicThemeMapping === 'LATEST'
          ? 'LATEST'
          : project.topicThemeMapping.identifier,
      Title: project.title,
      Version: project.version,
      Description: project.description,
      Datasets: project.datasets,
      Benchmarks: project.benchmarks,
      Analyses: project.analyses.map((analysis: IProjectAnalysisRequestModel) =>
        analysis.toJSON()
      ),
    };

    const url = `${this.getAPIUrl()}/projects`;

    const headers = await this.getHeaders();

    return axios
      .post(url, projectObject, { headers: headers })
      .then((response) => {
        const project: ProjectModel = ProjectModel.fromAPIResponse(
          response.data
        );
        return project;
      });
  }

  static async updateProject(
    project: ProjectBenchmarkUpdateRequestModel
  ): Promise<ProjectModel> {
    const url = `${this.getAPIUrl()}/projects/${project.organizationId}/${project.projectId}/update`;

    const headers = await this.getHeaders();
    const body = {
      Analyses: {
        New: project.newAnalyses?.map((analysis: IProjectAnalysisRequestModel) =>
          analysis.toJSON()
        ) ?? [],
        Updated: project.updatedAnalyses?.map(
          (analysis: IProjectAnalysisModel) => analysis.toJSON()
        ) ?? [],
        Deleted: project.deletedAnalyses?.map(
          (analysis: IProjectAnalysisModel) => analysis.toJSON()
        ) ?? [],
      },
      Summaries: {
        New: project.newSummaries ?? [],
        Updated: project.updatedSummaries ?? [],
        Deleted: project.deletedSummaries ?? [],
      },
      Benchmarks: project.benchmarks,
    };

    return axios.put(url, body, { headers: headers }).then((response) => {
      const project: ProjectModel = ProjectModel.fromAPIResponse(response.data);
      return project;
    });
  }

  static async calculateSampleSizeOfPopulation(
    datasets: object,
    populationDefinition: object
  ): Promise<number> {
    // Datasets is an object where each key is the dataset alias, and it has exactly one key inside: DatasetCRN, which is the CRN string of the dataset.
    // PopulationDefinition is a json logic object that defines the population. It's the same format we'd use if we were creating a project...

    const organizationId = await this.getCurrentOrganizationId();

    const url = `${this.getAPIUrl()}/datasets/${organizationId}/sample-size`;

    const body = {
      Datasets: datasets,
      PopulationDefinition: populationDefinition,
    };

    const headers = await this.getHeaders();

    return axios.post(url, body, { headers: headers }).then((response) => {
      return response.data.SampleSize;
    });
  }

  static async calculateOutcomeDistributionOfPopulation(
    datasets: object,
    populationDefinition: object,
    measures: IOutcomeMeasure[]
  ): Promise<any[]> {
    // Passes the outcome of interest to the endpoint for live calculation...
    // We get back a list of objects, each one has a Outcome and nRows...
    // We can then take this information and merge it with additional metadata to create distribution charts, etc...

    const organizationId = await this.getCurrentOrganizationId();

    const url = `${this.getAPIUrl()}/datasets/${organizationId}/sample-size`;

    const body = {
      Datasets: datasets,
      PopulationDefinition: populationDefinition,
      Outcome: {
        Measure: measures.map((measure) => {
          return {
            Dataset: 'DS_' + measure.dataset,
            Column: measure.column,
          };
        }),
      },
      CalculationType: 'OutcomeDistribution',
    };

    const headers = await this.getHeaders();

    return axios.post(url, body, { headers: headers }).then((response) => {
      return response.data.OutcomeDistribution;
    });
  }

  /**
   * This static asynchronous function retrieves benchmark data for a given project, benchmark, population, and topic.
   * NOTE: Does not allow * for population and topic at the same time...
   * @static
   * @param {string} projectId - The ID of the project.
   * @param {string} benchmarkName - The name of the benchmark.
   * @param {string} populationName - The name of the population. If you want all populations, provide "*".
   * @param {string} topic - The topic for which the benchmark data is to be fetched. If you want all topics, provide "*"
   * @param {number} [organizationId] - The ID of the organization. If not provided, the current organization ID will be used.
   * @param {ProjectMetricType} [metricType] - The type of metric to be used in the benchmark data.
   * @param {string} [focalTopic] - An optional focal topic to be included in the request.
   * @returns {Promise<BenchmarkDataPoint[]>} - A promise that resolves to an array of BenchmarkDataPoint objects.
   *
   * @throws {Error} If the server responds with a non-200 status code.
   */

  static async getBenchmarkData(
    projectId: string,
    benchmarkName: string,
    population: string | Population,
    topic: string,
    organizationId?: number,
    metricType?: ProjectMetricType,
    focalTopic?: string
  ): Promise<BenchmarkDataPoint[]> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    const url = `${this.getAPIUrl()}/projects/${organizationId}/${projectId}/si-metrics`;

    // Let's convert the metric type into the format we're looking for...
    var calculationType = 'BasicSI';
    switch (metricType) {
      case ProjectMetricType.Base:
        calculationType = 'BasicSI';
        break;
      case ProjectMetricType.NegativeCoMention:
        calculationType = 'NegativeMentionedSI';
        break;
      case ProjectMetricType.PositiveCoMention:
        calculationType = 'PositiveMentionedSI';
        break;
      case ProjectMetricType.NotMentioned:
        calculationType = 'NotMentionedSI';
        break;

      case ProjectMetricType.Segments:
        calculationType = 'SegmentsSI';
        break;
      default:
        calculationType = 'BasicSI';
        break;
    }

    var populations = null;
    if (population !== '*') {
      populations = [population];
    }

    if (isPopulation(population)) {
      populations = [
        {
          Title: population.title,
          Definition: population.definition,
        },
      ];
    }

    var body = {
      CalculationType: calculationType,
      Benchmark: benchmarkName,
      Populations: populations,
      Topic: topic,
    };

    if (focalTopic !== undefined) {
      body['FocalTopic'] = focalTopic;
    }

    if (metricType === ProjectMetricType.Segments) {
      body['FocalTopic'] = topic;
    }

    const headers = await this.getHeaders();

    // return axios.post(url, body, { headers: headers }).then((response) => {
    //     // In our response.data we'll have two keys:
    //     // 1. "columns"
    //     // 2. "records"
    //     const columns = response.data.columns;
    //     const records = response.data.records;

    //     return BenchmarkDataPoint.fromResponse(columns, records);

    // });

    return this.makeRequestAndHandle413(url, body, headers);
  }

  static _parse413Response = (data: any, initialBody: any): any[] => {
    const shards = data.additional_shards;

    const newBodies = [];

    shards.forEach((shard: any) => {
      const shardBody = { ...initialBody };
      shardBody['Shard'] = shard;
      newBodies.push(shardBody);
    });

    return newBodies;
  };

  static async makeRequestAndHandle413(
    url: string,
    body: any,
    headers: any,
    aggregatedData: BenchmarkDataPoint[] = []
  ): Promise<BenchmarkDataPoint[]> {
    try {
      const response = await axios.post(url, body, { headers: headers });
      const { columns, records } = response.data;
      aggregatedData.push(...BenchmarkDataPoint.fromResponse(columns, records));
      return aggregatedData;
    } catch (error) {
      if (error.response && error.response.status === 413) {
        console.log(error);

        const { columns, records } = error.response.data;
        aggregatedData.push(
          ...BenchmarkDataPoint.fromResponse(columns, records)
        );

        const subRequests = ProjectAPI._parse413Response(
          error.response.data,
          body
        );
        // Create an array of promises for the sub-requests
        const promises = subRequests.map((subRequest) =>
          this.makeRequestAndHandle413(url, subRequest, headers, aggregatedData)
        );
        // Wait for all sub-requests to complete
        await Promise.all(promises);
        return aggregatedData;
      } else {
        // Handle other errors or rethrow
        throw error;
      }
    }
  }

  static async getDistributionForColumns(
    projectId: string,
    datasetAlias: string,
    columns: string[],
    filter: any,
    organizationId?: number
  ): Promise<any[]> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    const url = `${this.getAPIUrl()}/projects/${organizationId}/${projectId}/distribution`;

    const headers = await this.getHeaders();

    const body = {
      DatasetAlias: datasetAlias,
      CalculationType: 'Distribution',
      Fields: columns,
      GlobalFilter: filter,
    };

    return axios.post(url, body, { headers: headers }).then((response) => {
      return response.data;
    });
  }

  /**
   * This static asynchronous function fetches the analysis data for a given project and analysis.
   *
   * @static
   * @param {string} projectId - The ID of the project.
   * @param {string} analysisId - The ID of the analysis.
   * @param {number} [organizationId] - The ID of the organization. If not provided, the current organization ID will be used.
   * @param {ProjectAnalysisDataType} [dataType] - The type of data to be fetched. Currently, if not provided or undefined, the function will log a warning and return undefined.
   * @returns {Promise<ShapDriversDataPoint[] | undefined>} - A promise that resolves to an array of ShapDriversDataPoint objects or undefined.
   *
   * @throws {Error} If the server responds with a non-200 status code.
   * @todo Add support for more analysis data types.
   */
  static async getAnalysisData(
    projectId: string,
    analysisId: string,
    aggregationLevel?: 'Theme' | 'Topic',
    organizationId?: number,
    dataType?: ProjectAnalysisDataType
  ): Promise<ShapDriversDataPoint[]> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    const url = `${this.getAPIUrl()}/visualizations/${organizationId}/analysis`;

    const body = {
      ProjectId: projectId,
      AnalysisId: analysisId,
      AggregationLevel: aggregationLevel ? aggregationLevel : 'Topic',
    };

    const headers = await this.getHeaders();

    return axios.post(url, body, { headers: headers }).then((response: any) => {
      // In our response.data we'll have two keys:
      // 1. "columns"
      // 2. "records"
      const columns = response.data.columns;
      const records = response.data.records;

      return ShapDriversDataPoint.fromResponse(columns, records);
    });
  }

  static async getBulkBenchmarkDataUrl(
    projectId: string,
    benchmarks: string[],
    organizationId?: number,
    fileName?: string,
    metricType?: ProjectMetricType,
    focalTopic?: string
  ): Promise<string> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    if (metricType === undefined) {
      metricType = ProjectMetricType.Base;
    }

    const url = `${this.getAPIUrl()}/visualizations/${organizationId}/benchmark/download`;
    var body = {
      ProjectId: projectId,
      OrganizationId: organizationId,
      Benchmarks: benchmarks,
      PreferredFileName: fileName,
      MetricType: metricType,
    };

    if (focalTopic !== undefined) {
      body['FocalTopic'] = focalTopic;
    }

    const headers = await this.getHeaders();

    return axios.post(url, body, { headers: headers }).then((response) => {
      return response.data;
    });
  }

  static async getBulkAnalysisDataUrl(
    projectId: string,
    analysisId: string,
    dataCategory: string,
    focalTopic?: string,
    organizationId?: number,
    fileName?: string
  ): Promise<string> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    const url = `${this.getAPIUrl()}/projects/${organizationId}/${projectId}/export-analysis`;
    var body = {
      ProjectId: projectId,
      OrganizationId: organizationId,
      AnalysisId: analysisId,
      DataCategory: dataCategory,
      PreferredFileName: fileName,
    };

    if (focalTopic) {
      body['FocalTopic'] = focalTopic;
    }

    const headers = await this.getHeaders();

    return axios.post(url, body, { headers: headers }).then((response) => {
      return response.data;
    });
  }

  static async reportQuote(
    projectId: string,
    organizationId: number,
    quote: QuoteModel | QuoteCoMentionModel
  ): Promise<void> {
    const url = `${this.getAPIUrl()}/projects/${organizationId}/${projectId}/quotes/report`;

    const headers = await this.getHeaders();

    const body = {
      RowId: quote.rowId,
      TextId: quote.textId,
      SentenceNumber: quote.sentenceNumber,
      Sentence: quote.sentence,
      Text: quote.textInstance,
      Topic: quote.vopic,
    };

    return axios.post(url, body, { headers: headers }).then((response) => {
      return;
    });
  }

  static async getQuotes(
    projectId: string,
    organizationId: number,
    benchmark: string | null,
    population: string | Population,
    topics: string[],
    sentiment?: string[],
    requestedDemographics?: any
  ): Promise<QuoteModel[]> {
    const url = `${this.getAPIUrl()}/projects/${organizationId}/${projectId}/quotes`;

    const headers = await this.getHeaders();

    const body = {
      Benchmark: benchmark,
      Population: isPopulation(population)
        ? {
            Title: population.title,
            Definition: population.definition,
          }
        : population,
      Topics: topics,
    };

    if (sentiment) {
      body['Sentiment'] = sentiment;
    }

    if (requestedDemographics) {
      body['RequestedDemographics'] = requestedDemographics;
    }

    return axios.post(url, body, { headers: headers }).then((response) => {
      const quotes: QuoteModel[] = response.data.map((quote: any) => {
        return QuoteModel.fromAPIResponse(quote);
      });

      return quotes;
    });
  }

  static async getCoMentionQuotes(
    projectId: string,
    organizationId: number,
    benchmark: string,
    population: string | Population,
    topics: string[],
    focalTopic: string,
    mentionType: ProjectMetricType,
    requestedDemographics?: any
  ): Promise<QuoteCoMentionModel[]> {
    const url = `${this.getAPIUrl()}/projects/${organizationId}/${projectId}/quotes`;

    const headers = await this.getHeaders();

    const body = {
      Benchmark: benchmark,
      Population: isPopulation(population)
        ? {
            Title: population.title,
            Definition: population.definition,
          }
        : population,
      Topics: topics,
      FocalTopicInfo: {
        FocalTopic: focalTopic,
        MentionType: mentionType,
      },
      Sentiment:
        mentionType === ProjectMetricType.PositiveCoMention
          ? ['positive']
          : ['negative'],
    };

    // if (sentiment) {
    //     body["Sentiment"] = sentiment;
    // }

    if (requestedDemographics) {
      body['RequestedDemographics'] = requestedDemographics;
    }

    return axios.post(url, body, { headers: headers }).then((response) => {
      const quotes: QuoteCoMentionModel[] = response.data.map((quote: any) => {
        return QuoteCoMentionModel.fromAPIResponse(quote);
      });

      return quotes;
    });
  }

  static async getBulkQuotesJobId(
    projectId: string,
    organizationId: number,
    analysisId: string,
    topics: string[],
    sentiment?: string[],
    focalTopic?: string,
    mentionType?: ProjectMetricType,
    requestedDemographics?: Map<string, string[]>
  ): Promise<string> {
    const url = `${this.getAPIUrl()}/projects/${organizationId}/${projectId}/quotes/export`;

    const headers = await this.getHeaders();

    const body = {
      AnalysisId: analysisId,
      Topics: topics,
    };

    if (focalTopic !== undefined && mentionType !== undefined) {
      body['FocalTopicInfo'] = {
        FocalTopic: focalTopic,
        MentionType: mentionType,
      };
    }

    if (sentiment) {
      body['Sentiment'] = sentiment;
    }

    if (requestedDemographics) {
      body['RequestedDemographics'] = requestedDemographics;
    }

    return axios.post(url, body, { headers: headers }).then((response) => {
      return response.data['ExportJobId'];
    });
  }

  static async getBulkQuotesDataUrl(
    projectId: string,
    organizationId: number,
    jobId: string
  ): Promise<string> {
    const url = `${this.getAPIUrl()}/projects/${organizationId}/${projectId}/quotes/export/${jobId}`;

    const headers = await this.getHeaders();

    return axios.get(url, { headers: headers }).then((response) => {
      return response.data['PresignedUrl'];
    });
  }

  static async getProjectSharedLinks(
    projectId: string,
    organizationId?: number
  ): Promise<SharedLinkModel[]> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    const url = `${this.getAPIUrl()}/projects/${organizationId}/${projectId}/shared-link`;

    const headers = await this.getHeaders();

    return axios.get(url, { headers: headers }).then((response) => {
      return (response.data ?? []).map((sl) =>
        SharedLinkModel.fromAPIResponse(sl)
      );
    });
  }

  static async createProjectSharedLink(
    read: string[],
    write: string[],
    download: string[],
    expiration: string | null,
    projectId: string,
    organizationId?: number
  ): Promise<Token> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    const object = {
      Permissions: {
        Download: download,
        Write: write,
        Read: read,
      },
      Expiration: expiration,
    };

    const url = `${this.getAPIUrl()}/projects/${organizationId}/${projectId}/shared-link`;

    const headers = await this.getHeaders();

    return axios.post(url, object, { headers: headers }).then((response) => {
      return response.data;
    });
  }

  static async getTokens(
    roleName: string,
    organizationId?: number
  ): Promise<Token[]> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    const url = `${this.getAPIUrl()}/role/${organizationId}/${roleName}/tokens`;
    const headers = await this.getHeaders();
    return axios.get(url, { headers: headers }).then((response) => {
      return response.data
    });
  }

  static async enableToken(
    token: Token,
    organizationId?: number
  ): Promise<void> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    const url = `${this.getAPIUrl()}/role/${organizationId}/${
      token.RoleName
    }/tokens/enable`;
    const headers = await this.getHeaders();
    return axios.put(url, token, { headers: headers });
  }

  static async disableToken(
    token: Token,
    organizationId?: number
  ): Promise<void> {
    if (organizationId === undefined) {
      organizationId = await this.getCurrentOrganizationId();
    }

    const url = `${this.getAPIUrl()}/role/${organizationId}/${
      token.RoleName
    }/tokens/disable`;
    const headers = await this.getHeaders();
    return axios.put(url, token, { headers: headers });
  }
}

export default ProjectAPI;
