import { useState, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

export const useAuthenticatedUser = (hasRoleToken: boolean) => {
  const [isLogged, setIsLogged] = useState(false);

  let location = useLocation();
  useEffect(() => {
    const checkUser = async () => {
      try {
        await Auth.currentSession();
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          setIsLogged(true);

          console.log(user.attributes);

          // Now let's update sentry to use this information
          Sentry.setUser({
            email: user.attributes.email,
            username: user.username,
            name: user.attributes.name,
          });
        }
      } catch (err) {
        setIsLogged(false);
        console.log(err);
        const urlParams = new URLSearchParams(location.search);
        const rtId = urlParams.get('rtId');
        const rtIdLower = urlParams.get('rtid');
        if (!rtId && !rtIdLower) {
          if(window.location.href === "http://localhost:5173/"){
            return
          }
          window.location.href = `${
            import.meta.env.VITE_LOGIN_URL
          }?redirect_uri=${window.location.href}`;
        }
      }
    };

    checkUser();
  }, []);

  return isLogged;
};
